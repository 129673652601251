@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} 
::-webkit-scrollbar-thumb {
 /* background: #939393; */
 background: #7F7F7F;
 border-radius: 5px;
}
@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  } 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.dashboard-root {
  padding: 2rem;
  color: #131313;
}

.dashboard-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.common-err-refresh-text{
  padding:2rem;
}
.dashboard-card {
  padding: 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
       border-image: initial;

}

.dashboard-card-title {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1E1E1E !important;

}

.dashboard-card-text {
  font-size: x-large;
}

.dashboard-charts{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  
}
.dashboard-card-opacity{
  opacity: 0.5;
}

/* .dashboard-chart,
.dashboard-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.dashboard-chart,
.dashboard-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
} */

/* For mobile : like samsung grand(480 * 800): */
@media screen and (max-width: 480px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }

  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }

  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

/* For iphone: */
@media screen and (max-width: 320px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }

  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

/* For ipad: */
@media screen and (max-width: 768px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }


  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}
/* For ipad: pro */
@media screen and (min-height: 1366px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }


  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

.dashboard-chart-section,.team-work-summary-chart-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.dashboard-website-chart-section {
  height: 300px;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background-color: #f7f9fb;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard-chart-section-top {
    height: 260px;
    overflow: auto;
    color: #4a5568;
    
    /* border-radius: 5px;
    border: 1px solid #d3d3d3; */
    
      display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  
}
.dashboard-chart-section,
.project-progress-section,
.dashboard-table-section,
.recent-screenshot-section,.team-work-summary-chart-section {
  height: 380px;
  overflow: auto;
  color: #4a5568;
  padding: 0 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
       border-image: initial;
}

.recent-screenshot-section {
  height: 380px;
  overflow: auto;
  color: #4a5568;
  padding: 0 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
       border-image: initial;
}

.section-header {
  display: flex;
}

.head-website-list,
.head-website-chart,.head-website-lists {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 0 1rem 0;
}
.head-team-work-summary-list {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
}

.dashboard-website-list,.team-work-summary-name-list {
  width: 100%;
}
.project-progress {
  width: 100%;
}
.website-list {
  border-radius: 1.2rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
}
.website-list.productive {
  border: 1px solid #10b9a9;
  background-color: #30a59f2b;
}
.website-list.unproductive {
  border: 1px solid #e74c3c80;
  background-color: #e74c3c1c;
}
.website-list.neutral {
  border: 1px solid #c0901d;
  background-color: #c0901d45;
}
.website-list.other-website {
  border: 1px solid #29282880;
  background-color: #7373731c;
  height: 1.7rem;
  padding: 0 0.3rem;
}

.website-name.other-website {
  margin-left: 1rem;
}
.website-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  display: initial;
  margin-bottom: -6px;
  margin-top: 2px;
}
.other-website-title{
  line-height: 16px !important;
}
.website-icon {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem;
}
.website-type {
  margin-right: 0.5rem;
  padding: 0.2rem;
  float: right;
  margin-top: 0.1rem;
  font-size: 0.67rem;
  margin-left: auto;
}
.website-type-productive {
  color: #10b9a9;
}
.website-type-unproductive {
  color: #e74c3c80;
}
.website-type-neutral {
  color: #c0901d;
}
@media (min-width: 768px) {
  .section .content {
    padding: 0 15px;
    overflow-x: scroll;
  }

  .head {
    padding-left: 1.5rem;
  }

  .row > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.donutchart-innertext {
  display: none !important;
}
.website-chart,.team-summary-chart {
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 13px;
}
.website-chart{
  margin-top: -12px !important;
}
.project-name-list,.team-name-list {
  font-size: 14px;
  padding: 0.3rem 1rem 0 1rem;
  width: 100%;
  color: #0000009c;
  font-weight: 500;
}
span.project-percentage {
  float: right;
}
span.project-color,.team-color {
  background-color: green;
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
}

.project-color-success,.project-progress .progress .bg-success {
  background-color: #50B9A9 !important;
  }
.project-color-warning,.project-progress .progress .bg-warning {
  background-color: #F5EF93 !important;
 }
.project-color-danger,.project-progress .progress .bg-danger {
  background-color: #F29492 !important;
}
.project-color-primary,.project-progress .progress .bg-primary {
  background-color: #7BD8E6 !important;
}
.project-color-info,.project-progress .progress .bg-info {
  background-color: #42A2B9 !important;
}
.project-color-secondary,.project-progress .progress .bg-secondary {
  background-color: #6C757D !important;
}
.project-name-row {
  margin-top: 0.5rem;
}
.screenshots-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 1rem;
}
.overview-no-data-text {
  font-size: 14px;
  color: #1E1E1E;
  flex: 1 1;
  margin-bottom: 1rem;
  text-align: center;
}
.overview-no-data-grid{
  grid-column: 1/3;
}
.overview-no-data-grid-top-team{
  grid-column: 1/3;
  margin: 1rem 0 1rem 0;
}
.overview-screenshot-link-text {
  font-size: 14px;
  color: #13171d;
  flex: 1 1;
  margin-bottom: 1rem;
  text-decoration: underline;
  font-size: 20px;
    text-align: center;
    margin-top: 15%;
}

.screenshots-list-img {
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  height:70px;
  cursor: default !important;
}
.screenshot-overview-display-name {
  text-align: center;
  font-size: 14px;
  margin-top: 0.4rem;
  margin-bottom: 0;
  color:#1E1E1E;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    
}

.dashboard-hours-table {
  font-size: 14px;
  border-collapse: separate;
  /* width: 38rem; */
  color:#1E1E1E;
}
.hours-worked-td {
  width: 10rem;
  vertical-align: middle !important;
}
.hours-worked-th {
  text-align: center;
}
.hours-worked-table-progressbar {
  height: 0.5rem;
  width: 4rem;
}
.dashboard-hours-table thead tr th {
  background-color: #8080802b;
}
.dashboard-hours-table thead tr th:last-child {
  width: 3%;
}
.dashboard-hours-table thead tr th:first-child {
  width: 30%;
}
.dashboard-hours-table thead tr th:nth-child(3) { 
  width: 14%;
}
.dashboard-hours-table thead tr th:nth-child(2) { 
  width: 2%;
}
.hours-worked-table-progressbar-root {
  display: flex;
  justify-content: space-between;
}
.worked-data-td {
  background-color: #eff17dcc;
}
.hours-worked-table-worked-time {
  margin-top: -5px;
  width: 60px;
}
/* .progress-bar.bg-custom {
  background-color: #eff17dcc !important;
} */

.tableWrap {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.dashboard-hours-table-wrap {
  /* Make sure the inner table is always as wide as needed */
  /* width: 34rem; */
  border-spacing: 0;
  overflow-x: scroll;
}

.dashboard-hours-table th {
  font-weight: 500 !important;
  padding-bottom: 12px !important;
}

.dashboard-hours-table th,
.dashboard-hours-table td {
  margin: 0;
  padding: 1rem;
  text-transform: none !important;
  color: #1E1E1E !important;
}

.sort-icon {
  color: #d4d4d4;
  float: right;
  position: relative;
  float: right;
  bottom: -2px;
}
.sort-icon.sorted {
  color: #9c9c9c;
}
.dashboard-hours-table th {
  width: -moz-fit-content;
  width: fit-content;
}

.hours-worked-table-progressbar-highest .bg-custom,
.worked-data-td-highest {
  background-color: #187936f2 !important;
}
.hours-worked-table-progressbar-medium .bg-custom,
.worked-data-td-medium {
  background-color: #4ca964 !important;
}
.hours-worked-table-progressbar-average .bg-custom,
.worked-data-td-average {
  background-color: #6cd599 !important;
}
.hours-worked-table-progressbar-low .bg-custom,
.worked-data-td-low {
  background-color: #cff1dd !important;
}
.worked-data-hours-worked-td {
  width: 19%;
}
/* .worked-data-td {
  background-color: #eff17dcc;
} */


/* https://stackoverflow.com/a/39775254/10805856 */
.website-chart svg > g > g:last-child,.team-summary-chart svg > g > g:last-child {
  pointer-events: none;
}

.head-screenshot-card{
  display: flex;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: white;
}
.head-screenshot-card-link{
  color: blue;
}
.website-list-selected{
  background-color: red !important;
}
.apps-tootlip{
  width: -moz-max-content;
  width: max-content;
  padding:5px;
  pointer-events: none;
}
.apps-tootlip-time{
  font-weight: bold;
  margin-left:5px;
}
div.google-visualization-tooltip { pointer-events: none } 
.team-work-summary-name-list {
  margin-top: 40px;
}

.count-tooltip .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68 !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  
}
.count-tooltip-stop .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  
}
.count-tooltip-start .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.app-count-tooltip-start .tooltip-inner{
  min-width:150px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

.count-tooltip .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: #535C68 !important;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: #535C68 !important;
}
.count-tooltip-whole{
  text-align: left;
  padding: 10px;
}
.count-tooltip-header{
  display:flex;
}
.count-tooltip-user-content{
  margin-bottom: 2px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-weight: 500;
}
.count-tooltip-user-content-index{
  margin-bottom: 2px;
  margin-right: 4px;
}
.count-tooltip-user-content-index:before {
  content: "•"; 
  color: white;
  width: 1em;
}
.count-tooltip-user-content-img{
  margin-right: 8px;
  margin-top:5px;
  border-radius:10px;
}
.count-tooltip-user-content-profile-img{
  margin-right: 8px;
  margin-top:5px;
  border-radius: 10px;
}
.summary-hover-card:hover{
  box-shadow: 1px -1px 19px rgb(38 38 38 / 20%);
}

/* .tooltip-inner {
  background-color: #b7b4b4 ;
  color: #000;
} */

.recent-apps-card-content {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-template-columns:1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.recent-apps-card {
  background-color: #28a7451a;
  border-radius: 5px;
  padding: 10px;
  color: #34495e !important;
  display: flex;
}
.recent-apps-card-load-more {
  border-radius: 5px;
  padding: 10px;
  color: #34495e !important;
  display: flex;
}
img.recent-apps-card-website-icon {
  width: 30px;
    height: 30px;
    margin-top: 6px;
}
.recent-apps-card-display-name {
  /* margin-top: 6px; */
  margin-left: 10px;
}
.recent-apps-card-display-name a{
  text-decoration: none;
}
.recent-apps-card-display-name a:hover {
  text-decoration: underline;
}
.recent-apps-card-display-name-value {
  color: #34495e !important;
  font-size: 15px;
  font-weight: 600;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recent-apps-card-display-app-name-value {
  font-size: 12px;
  color: #1e1e1eb5 !important;
  width: 160px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-apps-card-display-name-icon{
  margin-right: 11px;
}
.risk-detection-content-card {
  display: flex;
  background-color: #eceff473;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 11px;
}
.risk-detection-content-card-display-name {
  font-size: 12px;
  color: #1c73dc;
}
.risk-detection-content-card-reason {
  font-size: 14px;
}
.team-work-summary-list {
  margin-top: 16px;
}

.head-website-list-risk{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 1rem 5px 0;
}
.head-website-list-heading {
  font-size: 16px;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0;
    display: flex;
    align-items: center;
}
@media only screen and (min-width: 1024px)  {
  .head-website-list-heading {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }
  .head-website-list,
  .head-website-chart,.head-website-lists {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
  .head-team-work-summary-list {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
  }
}
@media only screen and (min-width : 1224px) and (max-width : 1400px) {
  .head-website-list-heading {
    font-size: 1.2rem;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0;
      display: flex;
      align-items: center;
  }
  .head-website-list,
  .head-website-chart,.head-website-lists {
    font-size: 1.2rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
  .head-team-work-summary-list {
    font-size: 1.2rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
  }
}

.head-website-list-hr{
  margin: 0;
  border-top: 2px solid #eceff473;
}
.head-website-list-user-count-header{
  font-size: 22px;
  color:red;
}
.head-website-list-user-new-count-header{
  font-size: 22px;
  color:red;
}
.risk-root-content-data-details-avatar a:hover{
  /* text-decoration: none; */
  color:#212529;
}
.dashboard-hours-table a{
  color: #1E1E1E ;
}
.head-website-list-user-drop-count-header{
  font-size: 22px;
  color:green;
}
.head-website-list-user-small-header{
  font-size: 14px;
}
.overview-risk-root-content-data-details {
  margin-top: 1rem;
  padding: 1rem 0;
  /* border: 1px solid #0000000d; */
  /* box-shadow: 0 0px 0px 0.5px rgb(0 0 0 / 10%), 0 0px 0px 0 rgb(0 0 0 / 6%); */
  font-size: 0.9rem;
}
.risk-user-FaCaretUp{
  font-size: 22px;
  color: green;
}
.risk-user-FaCaretDown{
  font-size: 22px;
  color: red;
}
.risk-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.risk-root-content-data-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.risk-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.risk-root-content-table tbody tr {
  margin-bottom: 10px;
  background-color: #fff;
  /* cursor: pointer; */
}
.risk-root-content-data-details-avatar {
  display: flex;
}
.risk-card-avatar-profile{
  width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: .5rem;
    border-radius: 50%;
    /* margin-top: 8px; */
}
.overview-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.risk-root-content-data-details-name-data-team-name {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 160px;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.risk-root-change{
  text-align: center;
}
.overview-risk-root-content-data-details td{
  padding: .30rem;
}
.risk-root-content-data-details-name-data-team-name-rule-text {
  margin: 0;
  font-size: 12px;
  color: #dc3545;
}

.risk-root-content-more-css-class{
  color: #6c757dc9;
  float: right;
  margin-right: 3px;
  margin-left:2px;
}
.overview-risk-listing-content-text{
  flex:1 1;
}
.risk-count-tooltip-start .tooltip-inner{
  max-width:250px;
  /* max-width:fit-content; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.risk-root-content-data-details-name-data-team-name-rule-text-accordance{
  font-size: 12px;
  display: flex;
  text-align: left;
  margin: 0;
}
.header{
  grid-area: header;
  height:auto;
}
.risk-root-rule-key {margin-right: 4px;}
.accordion button{
  font-size: 11px;
  color: gray;
  margin-top: -2px;
  padding: 0;
}
.risk-root-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: #f4f5f9;
  font-weight: 700;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}

.accordion button:focus{
  box-shadow: none;
}
.overview-risk-root-content-data-details th{
  padding: .10rem .30rem .30rem .30rem;
  width: 235px;
}
.risk-root-change-a{
  color: #0056b3 !important;
  text-decoration: underline !important;
  cursor:pointer !important;
}
.overview-risk-factor-no-data-text{
  text-align: center;
}
.overview-risk-listing{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.overview-risk-listing-content{
  display:flex;
  align-items: center;
  background-color:  #f0958863;
  border-radius: 5px;
  padding: 10px;
}
.overview-risk-listing-content-violation-text-div{
  font-size: 14px;
  display:flex;
}
.overview-risk-listing-content-violation-text-span{
  display:inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 16ch;
  margin:0;
  color: #4a5568;
}
.overview-risk-listing-content-violation-number-span{
  margin-left:4px;
}

.waring-dashboard-card{ 
  /* width:auto !important;   
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
  margin-bottom: 9px; */

  /* height: 380px; */
    overflow: auto;
    color: #4a5568;
    padding:16px;
    /* background: #8ef1760f; */
    background: #d1714236;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
}

.waring-dashboard-card-pro {
  /* height: 380px; */
  overflow: auto;
  color: #4a5568;
  padding: 16px;
  /* background: #8ef1760f; */
  background: #0080002b;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.waring-dashboard-card-pro-maintain{
  overflow: auto;
  color: #4a5568;
  padding: 16px;
  background: #f8aa1b3d;
  /* background: #0080002b; */
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.warning-dashboard-card-title-3rd-text{
  margin-top: 12px;
}
.warning-dashboard-card-title {
  /* font-size: 0.87rem; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-size: 0.98rem;
  color: #1E1E1E !important;
  /* margin-bottom: 20px; */
}
.warning-card-dashboard-link {
  color: #035fcb;
}
.warning-billing-dashboard-card-title-2nd-text-new-font{
  font-size: 0.9rem;
}
.warning-card-dashboard-link-span {
  color: #035fcb;
}
.warning-dashboard-card-title-2nd-text-new-font{
  font-size: 1rem;
}
.warning-dashboard-card-title-2nd-text-new-font-weight{
  font-weight: 600;
}
.warning-dashboard-card-title-2nd-text-new{
  /* margin-bottom: 10px !important; */
}
.warning-dashboard-card-title-2nd-text{
  margin-bottom: 0px !important;
}
.warning-dashboard-card-title-bold{
  margin-bottom: 20px !important;
}
.warning-dashboard-card-title-error{
  color:red !important;
}
.warning-dashboard-card-title-example{
  margin-bottom:38px !important;
}
.waring-dashboard-summary-billing{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
    padding: 2rem 2rem 0rem 2rem;
}
.waring-dashboard-summary{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
}
.warning-dashboard-card-title-next{
  margin-bottom: 10px !important;
  color: green !important;
  font-weight: 700;
}
.warning-dashboard-card-title-next-text{
  margin-bottom: 10px !important;
}
.warning-dashboard-card-title-success{
  font-size: 1.3rem;
    margin-bottom: 0px;
}
.work-load-analysis-list {
  border-radius: 1.2rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
  border: 1px solid #80808052;
  padding: 6px 10px;
}
.work-load-analysis-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  display: initial;
}
.work-load-analysis-member {
  text-align: right;
}
.work-load-analysis-member-list {
  width: 100%;
  margin-top: 50px !important;
}
.work-load-analysis-member-chart-section-top {
  height: 260px;
  overflow: auto;
  color: #4a5568;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 1rem; */
  margin-top: 10px;
}

svg.work-load-analysis-head-title-con {
  color: lightslategray;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}
.warning-dashboard-card-title-text1 {
  /* font-weight: bold; */
}
.warning-dashboard-card-title-status {
  font-weight: 600;
  color: #8e4456;
}
.warning-dashboard-card-title-div {
  margin:9px 0px 0px;
  font-size: 15px;
}
.warning-dashboard-card-title1-div {
  margin: 4px 0px;
  font-size: 15px;
}
.warning-dashboard-card-title-status-link{
  color:#0909a9cf;
}
.warning-dashboard-card-title-text1-span{
  font-weight: bold;
  /* margin-top: 19px; */
}
.warning-dashboard-card-title-text1-value{
  font-weight: 600;
    color: #000000c9;
}
  /* 
  .overview-late-clockin-details-table-thead-tr th{
    border:none !important;
  }
  .overview-late-clockin-details-table-tbody-tr td{
    border:none !important;
  } */
.overview-late-clockin-details-table-thead-tr {
  background-color: #f9f9f9;
}
.overview-late-clockin-details-table-thead-tr {
  background-color: #f9f9f9;
  font-size: 14px;
  /* float: left; */
  /* text-align: center; */
  color: #666;
  font-size: 14.5px;
  line-height: 18px;
  cursor: pointer;
  /* width: 14%; */
  padding-bottom: 4px;
}
.overview-late-clockin-details-table-tbody-tr td {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 14.5px;
  color: #000;
}
.overview-late-clockin-details-display-name-avatar-circle {
  display: block;
  height: 23px;
  width: 23px;
  font-weight: bold;
  line-height: 23px;
  font-size: 11px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
}
.overview-late-clockin-details-table-tbody-display-name-div {
  display: flex;
  width: 165px;
}
/* .overview-late-clockin-details-display-name-text{
  margin-top: 2px;
} */

.overview-late-clockin-details-table-thead-tr:first-child,.overview-late-clockin-details-table-tbody-tr:first-child{
  width: 200px;
}
.overview-late-clockin-details-table-tbody tr td:first-child{
  background-color: white;

}
.overview-late-clockin-details-table-tbody tr td{
  font-size: 14px;
}
.overview-late-clockin-details-table-tbody tr td:first-child{
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 0px solid #dee2e6 !important;
  border-right: 0px solid #dee2e6 !important;
}

/* .overview-late-clockin-details-table thead,.overview-late-clockin-details-table td,.overview-late-clockin-details-table th{
  border-top: 2px solid white !important;
  border-bottom: 2px solid white !important;
  border-right: 2px solid white !important;
  border-left: 2px solid white !important;
} */

/* .overview-late-clockin-details-table-tbody-tr td {
  background-color: #ff000014;
} */
.overview-late-clockin-details-table-thead-tr th{
  /* background-color: hsla(0,0%,50.2%,.16862745098039217); */
  background-color: white !important;
  color: #1e1e1e9c !important;
  margin: 0;
  padding: 0.8rem;
  font-weight: 600 !important;
  font-size: 14px;
}
.overview-content-data-details-avatar-circle{
  height: 25px;
  width: 25px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.overview-absent-details-content {
  display: flex;
  align-items: center;
  background-color: #ff000014;
  border-radius: 5px;
  padding: 10px;
}
.daily-attendance-content th,.daily-attendance-content td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  /* border-right: 1px solid #dee2e6; */
}
.overview-late-clockin-details-table thead th {
  border-bottom: 0px solid #dee2e6 !important;
}
.overview-late-clockin-details-table th {
  border-top: 0px solid #dee2e6 !important;
}
.overview-late-clockin-details-table td {
  padding: 0.8rem !important;
  border-right: 0px solid #dee2e6 !important;
}

.overview-late-clockin-details-display-name-text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  display: block;
}
.overview-late-clockin-details-table-tbody-avatar-profile {
  width: 23px;
  height: 23px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.overview-late-clockin-details-table-tbody-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.overview-absent-details-table-tbody-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.overview-late-clockin-header-details {
  display: flex;
}
.overview-late-clockin-header-details-number-text{
  text-align: right;
  color: red;
  font-size: 16px;
}
.overview-late-clockin-header-details-number-sub-text{
  font-size: 14px;
  color: #ff0000bf;
}
.overview-late-clockin-details-section-top{
  margin-top: 0px !important;
}
.overview-late-clockin-details-table-load-more-btn-div{
  text-align: center;
}
.overview-late-clockin-details-table-load-more-btn {
  font-size: 14px;
  margin-top: 0.4rem;
}
.overview-late-clockin-details-table-load-more-btn-risk {
  font-size: 14px;
  margin-top: 1rem;
}
.overview-work-hour-data-load-more{
  width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.recent-apps-card-load-more-p-div {
  border-radius: 5px;
  padding: 10px;
  color: hsla(0,0%,50.2%,.5215686274509804) !important;
  display: flex;
  justify-content: center;
}
.overview-enable-expected-clockin-track-settings{
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 30px;
  margin: 30px 63px 44px 63px;
  justify-content: center;
  grid: none;
  grid-template-columns: 1fr !important;
  display: block;
}
.overview-enable-expected-clockin-track-settings-text {
  margin-bottom: 14px;
  font-weight: 600;
}
.overview-enable-expected-clockin-track-settings-steps-title {
  margin-bottom: 11px;
}
.overview-enable-expected-clockin-track-settings-steps-title-1 {
  margin-bottom: 1px;
  margin-left: 13px;
}
.overview-enable-expected-clockin-track-settings-steps-title-2 {
  margin-left: 13px;
}
.overview-risk-listing-content-violation-text-span:hover{
  text-decoration: underline;
}
.overview-enable-expected-clockin-track-settings-no-data {
  text-align: center;
}
.overview-enable-expected-clockin-track-settings-no-data-late-clockin{
  margin-top: 16px;
}
span.warning-dashboard-card-title-2nd-span {
  text-decoration: underline;
  color: #0000ffad;
}




.dashboard-tax-modal-title.modal-title.h4 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  width: 90%;
  flex-shrink: 0;
}
.dashboard-tax-modal-content-row.row {
  margin-top: 8px;
}
.dashboard-tax-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.dashboard-tax-modal-content-name-field {
  flex: 1 1;
  /* margin-right: 16px; */
  margin-bottom: 0rem;
  margin-right: 16px;
  margin-bottom: 16px;
}
.dashboard-tax-modal-content-name-field {
  margin-right: 0 !important;
}
.dashboard-tax-modal-label-name {
  color: #535c68fc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  /* display: block; */
}
.dashboard-tax-modal-label-name-required {
  color: red;
}
.dashboard-tax-edit-project-label-input {
  font-size: 0.9rem !important;
}
.dashboard-tax-modal-button-rows {
  margin-bottom: 10px;
}
.dashboard-tax-col {
  text-align: center !important;
}
.dashboard-tax-modal-button {
  margin: auto;
}
.dashboard-tax-modal-save-button {
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
}
.warning-dashboard-card-title-3rd-div {
  margin-top: 20px;
}
.overview-enable-expected-clockin-track-settings-steps-title-3 {
  margin-left: 13px;
}
.overview-enable-expected-clockin-track-settings-know-more-link {
  color: #7a7ae8;
}

.upcoming-holiday-details-section-body{
  margin-top: 0px !important;
  height: 288px;
  overflow: auto;
  /* color: #4a5568; */
 margin-top: 10px;
 /* padding: 0px 10px 10px 10px; */
}
.upcoming-holiday-details-content {
  /* display: flex; */
  align-items: center;
  border-radius: 5px;
  padding: 11px;
  border: 1px solid #00000012;
 color: #1e1e1e !important;
}
.upcoming-holiday-listing-content-text {
  flex: 1 1;
}
.upcoming-holiday-listing-content-violation-text-div {
  font-size: 14px;
  /* display: flex;
  flex-direction: column; */
}
.upcoming-holiday-listing-content-violation-text-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  margin: 0;
  color: #000000b3;
  font-size: 14px;
}
.upcoming-holiday-listing-content-violation-text-span-date {
  text-align: right;
  font-size: 12px;
  margin-bottom: -3px !important;
  color: goldenrod;
}
.upcoming-holiday-listing-content-violation-text-span-day {
  color: #00000063;
  font-size: 12px;
}
.upcoming-holiday-list{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.2rem;
  margin: 0.4rem 0rem 1.3rem 0rem;
}
.upcoming-holiday-listing-content-violation-date-span {
 margin: 0;
  /* color: #4a5568; */
}
.upcoming-holiday-listing-content-violation-date-div {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}



.upcoming-leaves-details-section-body {
  margin-top: 0px !important;
  height: 286px;
  overflow: auto;
  /* color: #4a5568; */
  margin-top: 10px;
}
/* .upcoming-leaves-list {
  display: grid;
   grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
} */
.upcoming-leaves-details-content {
  /* display: flex; */
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #00000012;
  color: #1e1e1e !important;
  /* justify-content: space-between; */
}
.upcoming-leaves-details-content-date {
  font-size: 14px;
  color: #266dfb;
}
.upcoming-leaves-details-content-to-text {
  text-align: center;
  margin: 4px 0px;
  color: gray;
}
.upcoming-leaves-details-content-leave-types {
  font-size: 14px;
}
.upcoming-leaves-details-content-leave-days{
  margin-top: 2px;
  color: #4a5568;
  font-size: 14px;
}
.upcoming-leaves-details-content-display-name {
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* padding: 10px; */
}
.upcoming-leaves-data-details-avatar-circle {
  height: 22px;
  width: 22px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 25px;
  line-height: 22px;
  font-size: 11px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.upcoming-leaves-content-text {
  flex: 1 1;
}
.upcoming-leaves-content-violation-text-div {
  font-size: 14px;
  display: flex;
}
.upcoming-leaves-content-violation-text-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  color: #4a5568;
  margin-top: -2px;
  width: 120px;
}
.upcoming-leave-details-content-violation-text-div {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-details-content-date-details {
  /* margin-top: 3px; */
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-details-content-leave-type {
  color: #00000063;
  font-size: 12px;
}
.upcoming-leaves-details-content-start-date {
  font-size: 12px;
  color: goldenrod;
}

.upcoming-holiday-details-section-date-dot{
  background-color: #5895ff;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 2px;
  margin-top: 6px;
}
.upcoming-holiday-details-section-date-content-section {
  display: flex;
  justify-content: center;
}
.upcoming-holiday-details-section-date-text {
  font-size: 14px;
  font-weight: 600;
  /* color: #00000075; */
  background-color: #266dfb2b;
  padding: 4px 13px;
  color: #266dfbbd;
  border-radius: 7px;
}
.upcoming-holiday-listing-content-holiday-details-icon {
  margin-top: -2px;
}
.upcoming-holiday-listing-content-holiday-details {
  margin-left: 15px;
  display: flex;
  margin: 0.2rem;
}
.upcoming-holiday-listing-content-holiday-details-icon {
  /* margin-top: -2px;
  margin-right: 8px; */
 
}
.upcoming-holiday-listing-content-holiday-details-icon-div {
  display: inline-block;
  margin-right: 10px;
  height: 25px;
  /* height: 39px; */
  width: 25px;
  border-radius: 40px;
  /* background: #e3e3e3; */
  /* border: 1px solid rgba(0,0,0,.1); */
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  /* color: #333; */
  background-color: #40baab;
  color: rgb(244 245 249);
}


.upcoming-leaves-details-section-date-content-section {
  display: flex;
  justify-content: center;
}
.upcoming-leaves-details-section-date-text {
  font-size: 14px;
    font-weight: 500;
    background-color: #266dfb12;
    padding: 4px 8px;
    color: #266dfb8c;
    border-radius: 7px;
}
.upcoming-leaves-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin: 0.5rem 0rem 1.2rem 0rem;
}

.upcoming-leaves-listing-content-holiday-details {
  border: 1px solid #0000002b;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-listing-content-holiday-details-user {
  display: flex;
} 

.upcoming-leaves-listing-content-holiday-details-icon-div {
  display: inline-block;
  margin-right: 10px;
  height: 23px;
  width: 23px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  background-color: #40baab;
  color: rgb(244 245 249);
}



/* .upcoming-leaves-listing-content-holiday-details-icon {
  margin-top: -2px;
} */
.upcoming-leaves-listing-content-violation-text-span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 151px;
  margin: 0;
  color: #000000b3;
  font-size: 15px;
  /* margin-top: -1px; */
  color: #34495e !important;
  font-size: 15px;
  font-weight: 600;
}
.upcoming-leaves-details-content-user-details {
  display: flex;
}
.upcoming-leaves-details-content-leave-status {
  margin-top: 1px;
  font-size: 12px;
  text-transform: capitalize;
}
.upcoming-leaves-details-content-leave-status-pending{
  color: #f1c40f !important;
}
.upcoming-leaves-details-content-leave-status-approved{
  color: #27ae60 !important;
}
.upcoming-list-no-data{
  text-align: center;
}
.warning-dashboard-card-title-div-link {
  margin-top: 9px;
}
span.warning-dashboard-card-title-div-label {
  color: #1e1e1eba !important;
}
.warning-dashboard-card-title-div-label-end{
  margin-left: 15px;
}
.warning-dashboard-card-title-div-link-tag{
  margin-left: 6px;
  color: #125eede0;
}


.waring-dashboard-timezone-summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 25px;
}
.waring-dashboard-timezone-card-pro {
  overflow: auto;
  color: #4a5568;
  padding: 17px;
  background: #DFE6E9;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.warning-dashboard-timezone-card-title {
  font-size: 0.98rem;
  color: #1E1E1E !important;
  
}
.warning-dashboard-timezone-card-title-2nd-text-new-font {
  font-size: 0.89rem;
  /* font-weight: 500; */
}
.warning-dashboard-timezone-card-text-sub{
  font-weight: 600;
}
.warning-dashboard-timezone-card-icon {
  margin-top: -2px;
  margin-right: 6px;
  color: #1e1e1ec7;
}
.warning-dashboard-timezone-card-text-help{
  color:blue
}
@keyframes spin {
    100% { transform:rotate(360deg); }
  }

.loading-wrapper{
    text-align: center;
}
.loading-svg{
    font-size: 3rem;
    color: #266dfb;
    animation: spin 1.0s linear infinite;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #266dfb !important;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: #266dfb !important;
}
.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #0d5cfb !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #266dfb !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #0d5cfb !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #588ffc !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #0d5cfb !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(38, 109, 251, 0.5) !important;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #266dfb !important;
}

.react-datepicker__close-icon::after {
  background-color: #266dfb !important;
}

.root-layout {
    display: grid;
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
    /* grid-template-columns: 0px minmax(0, 1fr); */
    grid-template-columns: 217px minmax(0, 1fr);
}

.root-layout-mobile-with-menu {
    grid-template-columns: 185px minmax(0, 1fr);
}

.root-layout-mobile-without-menu {
    grid-template-columns: 1fr !important;
}

.root-layout-menu {
    height: 100vh;
}

.sidebar-wrapper-user-profile {
    text-align: center;
    background-color: #fcfcfc;
    /* width: 210px;
  border-right: 2px solid #ededed; */
}

.root-layout-page {
    height: 100vh;
    background-color: #fcfcfc;
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    grid-template-rows: 55px minmax(0, 1fr);
}

.root-layout.desktop,
.root-layout.desktop .root-layout-page,
.root-layout.desktop .root-layout-menu {
    height: calc(100vh - 32px);
}

.root-layout-page .nav-link {
    background-color: #fff;
    border: none;
    color: #6c757d;
}

.root-layout-page .nav-link.active {
    color: #007bff;
    border-bottom: 4px solid #007bff;
}

.sidebar-wrapper-content {
    border-right: 2px solid #ededed;
    height: calc(100vh - 55px);
    padding: 8px 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fcfcfc;
}

.sidebar-wrapper-content-trial {
    height: calc(100vh - 100px) !important;
}

.sidebar-wrapper-content.has-footer {
    height: calc(100vh - 55px - 94px);
}

.root-layout.desktop .sidebar-wrapper-content {
    height: calc(100vh - 55px - 32px);
}

.root-layout.desktop .sidebar-wrapper-content.has-footer {
    height: calc(100vh - 55px - 94px - 32px);
}

.sidebar-wrapper-content-parent:not(:last-child) {
    margin-bottom: 8px;
}

.sidebar-wrapper-content-parent-name {
    font-size: smaller;
    padding: 5px 13px 0;
    opacity: 0.5;
}

.sidebar-wrapper-content-navbar-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    transition: background-color 100ms ease;
    cursor: pointer;
    text-decoration: none !important;
    color: #333 !important;
    border-left: 4px solid transparent;
}

.sidebar-wrapper-content-navbar-list:hover {
    background-color: #266dfb10;
}

.sidebar-wrapper-content-navbar-list.selected {
    background-color: #266dfb10;
    border-color: #266dfb;
    padding: 9px 12px;
}

.sidebar-wrapper-content-navbar-list-item-icon {
    margin-left: 0px;
    margin-right: 8px;
    font-size: 18px;
    opacity: 0.7;
}

.sidebar-wrapper-content-navbar-list-item-icon-Videos {
    font-size: 19px !important;
}

.sidebar-wrapper-content-navbar-list-item-name {
    font-size: 15.5px;
    text-decoration: none;
    margin: 0;
    margin-right: 1px;
}

.sidebar-wrapper-content-navbar-list.selected .sidebar-wrapper-content-navbar-list-item-name,
.sidebar-wrapper-content-navbar-list.selected .sidebar-wrapper-content-navbar-list-item-icon {
    opacity: 1;
    color: #266dfb;
    font-weight: 600;
}

.sidebar-wrapper-header>* {
    height: 55px;
    line-height: 55px;
    font-weight: 600;
    padding: 0 16px;
    margin: 0;
}

.sidebar-wrapper-header-logo {
    color: #266dfb !important;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none !important;
}

.organisation-info-profile-images {
    display: inline;
    margin: 0 auto;
    height: 90%;
    width: auto;
    margin-top: -13px;
}

.sidebar-wrapper-header,
.page-content-wrapper-header {
    border-bottom: 1px solid #ededed;
    background-color: #fcfcfc;
    position: sticky;
    top: 0;
    width: 100%;
    height: 55px;
    z-index: 60;
}

.page-content-wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    /* display: grid;
  grid-template-columns: 1fr 1fr; */
}

.page-content-wrapper-header-name-section>*:not(:last-child) {
    margin-right: 15px;
}

.sidemenu-header-comman-div>*:not(:last-child) {
    margin-right: 15px;
}

.page-content-wrapper-header-name {
    flex: 1 1;
    line-height: 55px;
    font-weight: 600;
    margin: 0;
}


/* sidebar-wrapper-user-profile css div start */

.sidebar-wrapper-user-profile .rc-menu {
    /* display: none; */
    box-shadow: none;
    border: none;
}

.user-profile-submenu {
    z-index: 50;
}

.user-profile-submenu.rc-menu-submenu>.rc-menu {
    background-color: #fcfcfc;
}

.user-profile-submenu-item {
    padding: 8px 12px !important;
    position: initial !important;
}

.user-profile-submenu-item a {
    color: #035FCB !important;
}

.user-profile-submenu-item:hover {
    background: #266dfb05;
}

.user-profile-submenu-item>a {
    text-decoration: none !important;
}

.sidebar-wrapper-user-profile .rc-menu-item-active,
.sidebar-wrapper-user-profile .rc-menu-submenu-selected,
.sidebar-wrapper-user-profile .rc-menu-submenu-active>.rc-menu-submenu-title {
    background-color: #fcfcfc;
}

.sidebar-wrapper-user-profile .rc-menu-submenu-title {
    padding: 0px 0px !important;
}

.sidebar-wrapper-user-profile .rc-menu-submenu-arrow {
    display: none !important;
}

.sidebar-wrapper-user-profile-avatar {
    background-color: #266dfb;
    height: 29px;
    width: 29px;
    text-align: center;
    border-radius: 40px;
    margin: 0 auto;
    line-height: 29px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.sidebar-wrapper-user-profile-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #333;
    margin: 6px 0 0;
}

.sidebar-wrapper-user-profile-dropdown-item-button {
    position: absolute;
    z-index: 0;
    top: 25px;
    cursor: pointer;
}

.opacitiy-remove {
    position: unset !important;
    z-index: 0;
    top: 25px !important;
}

.opacitiy-remove>button:first-child {
    opacity: 1 !important;
}

.sidebar-wrapper-user-profile-dropdown-item-button>button:first-child {
    opacity: 0;
}

.sidebar-wrapper-user-profile-dropdown-item-button .dropdown-toggle {
    color: #266dfb;
    box-shadow: none !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.sidebar-wrapper-user-profile-dropdown-item-button .dropdown-toggle:hover {
    color: #266dfb;
}


/* sidebar-wrapper-user-profile css div end */

.page-content-wrapper-menu-filter-overlay-trigger {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-color: #00000017;
    display: block;
}

.page-content-wrapper-menu-filter-overlay-trigger .arrow::before {
    border-bottom-color: #00000017;
}

.page-content-wrapper-menu-filter-overlay-trigger {
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.page-content-wrapper-menu-filter-overlay-trigger .popover-body {
    overflow: hidden;
}

.page-content-wrapper-menu-filter-date~.dropdown-menu .dropdown-item {
    color: rgba(0, 0, 0, 0.75);
    font-weight: 400;
}

.page-content-wrapper-menu-filter-overlay {
    display: flex;
}

.page-content-wrapper-menu-filter-overlay-button {
    margin-right: 1.7rem;
    position: relative;
    float: right;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.page-content-wrapper-menu-filter-overlay-button-cancel {
    font-weight: 700;
    font-size: 14px;
    margin-right: 16px;
    color: #266dfb;
    cursor: pointer;
    padding: 11px 16px;
    line-height: 14px;
    border-radius: 5px;
    border: 1px solid #266dfb;
    transition-property: background;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

.page-content-wrapper-menu-filter-overlay-button-apply {
    font-weight: 700;
    font-size: 14px;
    /* margin-right: 16px; */
    cursor: pointer;
    padding: 11px 16px;
    line-height: 14px;
    border-radius: 5px;
    border: 1px solid #266dfb;
    transition-property: background;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    background: #266dfb;
    color: #fff;
}

.page-content-wrapper-menu-filter-overlay-datepicker {
    display: inline-block;
    width: 275px;
    vertical-align: top;
    border-right: 1px solid #e8ebee;
    margin-left: 20px;
    margin-top: 24px;
}

.page-content-wrapper-menu-filter-overlay-list {
    display: inline-block;
    width: 245px;
    margin-left: 20px;
    margin-top: 24px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-left: 15px;
    margin-top: 5px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier p {
    display: block;
    font-size: 14px;
    line-height: 14px;
    color: #34485e;
    font-weight: 600;
    margin-bottom: 20px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier-listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier-listing-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 29px;
    background: #f0f0f0;
    color: #718093;
    box-shadow: unset;
    border-radius: 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    margin: 4px;
    border: 1px solid #b1ade1;
}

button:focus {
    outline: none !important;
}

.filter-menu-active {
    color: #266dfb;
    border: 1px solid #266dfb;
    background: #e7e5fc;
}

.page-content-wrapper-menu-filter-date-icon {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    height: 15px;
    margin: 0 5px;
    /* opacity: 0.8; */
    font-size: medium !important; 
}

.page-content-wrapper-menu-filter-dropdown-icon {
    font-size: medium;
    margin-left: 8px;
    margin-top: 2px;
}

#date-filter {
    display: none;
}

.page-content-wrapper-menu-filter-date-text {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}

.page-content-wrapper-menu-filter-date-text-desktop {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}

.page-content-wrapper-menu-filter-date:hover {
    background-color: #266dfb10;
    color: #266dfb;
}

.sidemenu-header-comman-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.page-content-wrapper-header-name-section {
    display: flex;
    align-items: center;
}

@media (min-width: 1024px) {
    .root-layout {
        /* grid-template-columns: 217px minmax(0, 1fr); */
    }
    .root-layout.has-second-menu {
        grid-template-columns: 217px 217px minmax(0, 1fr);
    }
    .sidebar-wrapper-user-profile {
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
    .sidebar-wrapper-user-profile .rc-menu {
        display: block;
        padding: 3px 0;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 50;
        margin: 0 auto;
    }
    .sidebar-wrapper-user-profile-avatar:hover {
        border: 1px solid #ededed !important;
        background-color: #dae5f9f0 !important;
        color: #266dfb !important;
        line-height: 26px;
        font-size: 17px;
    }
    .rc-menu-submenu-placement-leftTop {
        top: 45px !important;
        /* left: 1270px !important; 
    right:10px !important; */
        left: auto !important;
        right: 0 !important;
        z-index: 1000;
    }
    #date-filter {
        display: flex;
    }
}

@media screen and (width: 411px) {
    .page-content-wrapper-header-name {
        width: 72px !important;
    }
}

@media screen and (max-width: 480px) {
    .page-content-wrapper-header-name-section>*:not(:last-child) {
        margin-right: 9px;
    }
    #date-filter {
        display: flex;
    }
    .page-content-wrapper-menu-filter-date-text {
        display: none;
    }
    .page-content-wrapper-menu-filter-overlay {
        display: initial;
    }
    .page-content-wrapper-menu-filter-overlay-datepicker {
        border-right: 0px solid #e8ebee;
        margin-left: 35px;
    }
    .page-content-wrapper-menu-filter-overlay-list {
        margin-left: 20px;
        margin-top: 12px;
    }
    .page-content-wrapper-menu-filter-date {
        padding: 4px;
    }
    .page-content-wrapper-menu-filter-overlay-button {
        float: left;
        margin-left: 70px;
        margin-top: 25px;
    }
    .page-content-wrapper-menu-filter-overlay-list-contanier p {
        text-align: center;
    }
    .page-content-wrapper-menu-filter-overlay-list-contanier-listing {
        justify-content: space-around;
    }
    .page-content-wrapper-menu-filter-overlay-trigger .popover-body {
        width: -moz-min-content;
        width: min-content;
    }
    .sidemenu-header-comman-div>*:not(:last-child) {
        margin-right: 9px;
    }
    .page-content-wrapper-header {
        justify-content: space-between;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .sidemenu-header-fixes {
        float: right;
        display: flex;
        align-items: center;
    }
    .page-content-wrapper-header-name {
        flex: 1 1;
        font-size: 15px;
        width: 55px;
        /* min-width:55px; */
        /* max-width:60%; */
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .page-content-wrapper-menu-add-user-icon {
        margin-right: 3px !important;
        margin-left: 1px;
        /* margin-top: 1px !important; */
    }
    .page-content-wrapper-menu-add-user {
        padding-left: 0 !important;
        padding-right: 0!important;
        height: 27px;
        width: 60px;
        /* text-align: center; */
        /* line-height: 19px; */
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-top: 4px; */
    }
    .page-content-wrapper-menu-filter-date-text {
        width: 7ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .page-content-wrapper-menu-search-bar {
        display: none !important;
    }
    .second-sidebar-searchbox-wrapper-root {
        width: 166px !important;
    }
    .second-sidebar-searchbox {
        font-size: 0.8rem !important;
    }
    .page-content-wrapper-menu-add-user {
        font-weight: 700;
        font-size: 10px !important;
    }
}

.page-content-wrapper-menu-search-bar {
    /* width: 32% !important; */
}

.page-content-wrapper-menu-search-bar div input {
    /* height: 10% !important;
  border-radius: 24px;
  border: 1px solid #ededed; */
    height: 32px !important;
    border-radius: 24px;
    border: 1px solid #ededed;
    font-size: 15px !important;
}

.sidebar-wrapper-content-navbar-list-item-name-review-badge {
    background-color: #fa3e3e;
    border-radius: 5px;
    color: white;
    font-weight: bolder;
    padding: 0px 3px;
    font-size: 14px;
    margin-left: 3px;
}

.page-content-wrapper-menu-add-user {
    font-weight: 700;
    font-size: 14px;
}

.page-content-wrapper-menu-add-user-icon {
    margin-top: -2px;
    margin-right: 7px;
}

.workfolio-react-tour {
    border-radius: 3px;
}


/* 
.sidebar-wrapper-content-parent:empty{
  display:none;
}
.sidebar-wrapper-content-parent{
  visibility:hidden;
}
.sidebar-wrapper-content-parent .sidebar-wrapper-content-navbar-list{
  visibility:visible;
} */

.sidebar-wrapper-user-profile-avatar {
    text-transform: capitalize;
}

.header-menu-card-avatar-profile {
    width: 29px;
    height: 29px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
}

.header-menu-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper-content-trial-content {
    font-size: 13px;
    background-color: #f756561a;
    padding: 11px 15px;
    margin: 9px;
    border-radius: 3px;
    text-align: center;
    bottom: 0;
    position: absolute;
}

.sidebar-wrapper-content-trial-content label {
    color: #de0f0f;
    margin-bottom: 0rem;
    font-weight: 500;
    position: relative;
}

.workfolio-release-notes {
    display: flex;
}

.workfolio-release-notes-label {
    margin-top: 5px;
    font-size: 15px;
    cursor: pointer;
}

.workfolio-release-notes-label:hover {
    text-decoration: underline !important;
}

.page-content-wrapper-header-name-sup {
    font-size: 12px;
    font-weight: normal;
}
.page-content-wrapper-header-name-sup-project {
    font-size: smaller;
    font-weight: normal;
    padding-left: 3px;
    opacity: 1;
    color:red;
}

.review-apps-sidemenu-count {
    font-size: 15.5px;
    color: #ff0000b5;
    /* color:red; */
    margin-top: 3px;
}

.workfolio-header-page-content-wrapper-menu-filter-team-menu {
    max-height: 526px;
    overflow-y: scroll;
}

.app-reload-button {
    opacity: 0;
    animation: fadeIn 3s;
    animation-delay: 80s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.filter-menu-disabled {
    cursor: default !important;
    color: #80808099 !important;
}
.add-user-disabled-div{
    display: inline-block;
   color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 700;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
}
.page-content-wrapper-menu-filter-user-group-icon {
    display: flex;
    margin: -2px 5px 0px 5px;
    font-size: small !important;
}
.sidebar-wrapper-content-navbar-list-item-icon-over-time{
    color: gray;
}
.react-tour-header-text{
    font-weight: 600;
    color: #000000c9;
    font-size: 15px;
}
.react-tour-content-text{
    font-size: 14px;
    /* color:#e5e5e5; */
}
/* Employees-root div css start*/
.manage-employee-root {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}


/* Employees-root-header div css start*/
.Employees-root-header {
  /* display: flex; */
  padding: 1rem 0;
  justify-content: space-between;
}

.Employees-root-content-table .Employees-root-content-data-details:hover{
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.employee-timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning-text{
  font-size: .9rem;
  margin-top: 8px;
  opacity: 0.5;
}

.Employees-root-table-email-name {
  width: 233px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Employees-root-header-left-side {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.Employees-root-header-left-side-button {
  /* background: #695eee; */
  /* color: #fff; */
  margin-right: 24px;
  font-size: 1rem;
  font-weight: 600;

  position: relative;
  margin-left: auto;
  margin-right: 0px;
}
.Employees-root-header-left-side-searchbox-wrapper {
  /* display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px; */
  
}


.Employees-root-header-left-side-searchbox {
  /* border: none; */
  float: right;
  font-size: .8rem;
}

.Employees-root-header-left-side-searchbox:focus {
  box-shadow: none;
}

.Employees-root-header-left-side-icon-wrapper {
  /* padding: 0.3rem 0.5rem 0.3rem 0;
  color: #49505752;
  display: inline-flex; */
  position: absolute;
    top: 0;
    bottom: 0;
    right: .4rem;
    padding: .3rem;
    color: #ccc;
    font-size: .9rem;
}
.add-employee-page-create-team-button{
  float:right;
  font-weight: 400;
    color: #007bff;
    text-decoration: none;
}
/* Employees-root-header div css end*/

/* Employees-root-content div css start*/
.Employees-root-content {
  flex: 1 1;
  margin-top: 1rem;
  color: #333;
  border-radius: 5px;
}
.Employees-root-content-table{
  border-collapse: separate;
  border-spacing: 0 8px;
  /* padding: 0 10px 10px 10px; */
}
/* .Employees-root-content-table>tbody>tr:nth-child(even)>td, 
.Employees-root-content-table>tbody>tr:nth-child(even)>th {
   background-color: #eeebeb !important; 
 } */

.Employees-root-content-data-header {
  color: rgba(121,119,119,.8588235294117647);
  font-size: 0.9rem;
}
.Employees-root-content-data-details {
  box-shadow: 0 0 0 0.5px rgba(0,0,0,.1), 0 0 0 0 rgba(0,0,0,.06);
  font-size: 0.9rem;
  font-weight: 400;
  background-color: #fff;
}
.Employees-root-content-data-details > td {
  vertical-align: middle;
}
.Employees-root-content-data-details-avatar {
  display: flex;
  align-items: center;
}
.Employees-root-content-data-details-avatar-circle {
  height: 30px;
  width: 30px;
  font-weight: bold;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
}
.Employees-root-content-data-details-name-data {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Employees-root-content-data-details-name-data-team-name-count {
  margin: 0;
  font-size: 13px;
}
.Employees-root-content-data-name-div-width{
  width:-moz-fit-content;
  width:fit-content;
}
.Employees-root-content-data-details-td-name{
  width:315px;
}
.Employees-root-content-data-details-td-email{
  /* width:155px; */
}
.Employees-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  
  
}
.Employees-root-content-data-details-name-data-team-name-whole{
  width: 214px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Employees-root-content-data-details-td-billable-rate{
  width: 102px;
}
.Employees-root-content-data-details-td-billable-div {
  display: flex;
  width: 102px;
}
.Employees-root-content-data-details-td-billable-div-uparrow {
  font-size: 13px;
  margin-left: 10px;
}
.Employees-root-content-data-details-td-billable {
  display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(73,80,87,.7803921568627451);
    background-color: #f8f9fa;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    text-align: center;
    /* border-radius: 0.25rem; */
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.Employees-root-content-data-details-td-billable-text {
  display: block;
    width: 60%;
    height: calc(1.2em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.7;
    color: gray;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border-left: none;
  text-align: center;
  cursor:pointer;
  text-decoration: underline;
}
.Employees-root-content-data-details-td-billable-text:hover{
  text-decoration: underline;
  color: #0000ffde;
}
.Teams-root-content-data-details-name-data-line {
  border-right: 1px solid gray;
  margin-left: 10px;
}
.employee-page-edit-profile-text-td{
  display: flex;
    font-size: 0.8rem;
}
.employee-page-edit-profile-text{
  cursor: pointer;
  text-decoration: overline;
  margin-right: 5px;
}
.employee-page-edit-profile-text-danger{
  cursor: pointer;
  text-decoration: overline;
}
.employee-page-edit-profile-text-danger:hover{
  color:red;
}
.employee-page-edit-profile-text:hover{
  color:#007bff;
}
.employee-page-accordion-div-collapse-whole-div{
  width:250px;
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
}

.employee-page-edit-profile-text-td-table-data-icon-div {
  display: flex;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  color: #6c757d;
  margin-right: 5px;
}
.employee-page-edit-profile-text-td-data-edit-icon {
  font-size: 15px;
  color: #007bff;
  margin-top: 3px;
  margin-right: 8px;
}
.employee-page-edit-profile-text-td-edit-text {
  margin-bottom: 0;
}

.employee-page-accordion-div-collapse{
    padding: 5px 15px;
}
.employee-teams-list-bullet{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.employee-teams-list-bullet-dot{
  display: inline-block;
    margin-right: 8px;
    height: 5px;
    width: 5px;
    border-radius: 40px;
    border: 1px solid rgba(66,58,58,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    background-color: white;
}
.employee-span {
  font-size: .9rem;
  font-weight: 400;
  color: #212529;
  margin-right: 5px;
}
.employee-span-no-project-team-add-more-div{
display: flex;
}
.employee-span-no-project-team-add-more{
  font-size: .9rem;
    font-weight: 400;
    color: #212529;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.employee-span-no-project-team-add-more-text{
  font-size: .9rem;
    font-weight: 400;
    color: #212529;
}
.employee-team-list-button-no-project .btn-primary {
  border: none;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  width: 230px;
  text-align: left;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).focus{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).focus, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}


.Employees-root-content-data-name-div-width-no-project-team{
  width: 255px;
}
.employee-span-no-project-team {
  font-size: .9rem;
  font-weight: 400;
  color: #212529;
  margin-right: 5px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.employee-team-list-button .dropdown-toggle::after{
  position: relative;
  top: 2px;
}
.billable-rate-modal-heading-span{
  /* width: 233px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
.employee-team-list-button .btn-primary {
  border: none;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  width: 100px;
  text-align: left;
  box-shadow: none;
  padding: 0;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).focus{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).focus, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}


.Employees-root-content-data-details-productive {
  color: #3cd2b8;
}
.Employees-root-content-data-details-unproductive {
  color: #ff4757;
}
/* Employees-root-content div css end*/
.Employees-root-modal-close {
  margin-left: 3px;
  font-size: 21px;
}
.Employees-root-modal-add-text {
  font-size: 14px;
  color: #695eee;
}
.Teams-root-content-data-details-avatar-circle-inside {
  position: absolute;
  right: 0;
  left: 41%;
  top: 70%;
  transform: translate(50%, 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #fdeedd;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #f0932b;
}

/* Employees-root div css end*/

.access-control-tabs {
  color: #000000d4;
  font-size: 0.9rem;
}

.employee-tabs-head.nav-tabs {
  padding-top: 2px;
  margin-bottom: 0.5rem;
}

.employee-tabs-head .nav-link {
  color: gray;
  font-size: 0.9rem;
  border: none;
  font-weight: 600;
}

.employee-tabs-head .nav-link.active {
  color: #007bff;
  margin-bottom: 0px;
  border-bottom: 2px solid #007bff;
  background: none;
}

.employee-tabs-head .nav-link:hover {
  color: #007bff;
}

.employee-edit-save-button {
  font-size: 0.8rem;
  padding: 0.3rem 1.3rem;
  float: right;
  margin-right: 1rem;
}
.employee-edit-close-button {
  font-size: 0.8rem;
  padding: 0.3rem 1.3rem;
  float: right;
}
.employee-edit-modal-header {
  font-size: 1rem;
}
.new-employee-modal .form-label.error {
  font-size: smaller;
  color: red;
  float: right;
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.new-employee-modal .form-error {
  font-size: smaller;
  color: red;
  margin-bottom: 0.25rem;
  text-align: center;
  margin-top: 3px;
}
.new-employee-modal .btn-link:focus,
.new-employee-modal .btn-link.focus {
  box-shadow: none;
}
/* .employee-row{
  border: 1px solid #dee2e6!important;
} */
.Employees-root-header-filter-name {
  margin-left: 15px;
  font-size: 19px;
  font-weight: 600;
  color: #000000ab;
}

.new-user-invite-modal-type-card {
  margin: 1rem 3rem 3rem;
}
.new-user-invite-modal-card-title {
  font-size: 21px;
  text-align: center;
  color: #000000ad;
  font-weight: 600;
}
.new-user-invite-modal-type {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr !important;
  margin-top: 1.4rem;
  text-align: center;
}

.employee-edit-password .nav-tabs > a {
  color: #343a40;
}
.employee-edit-password .nav-tabs > a.active {
  border-bottom: 4px solid #007bff;
  color: #007bff;
}
.personal-edit-settings-password-container {
  background: #fff;
  height: 100%;
}
.personal-edit-settings-password-container > .form-wrapper {
  margin: 11px auto 30px 35px;
  max-width: 500px;
}
.personal-edit-settings-password-container-empty{
  text-align: center;
    height: 240px;
    margin-top: 120px;
}
.new-employee-modal .nav-link {
  background-color: #fff;
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.new-user-invite-modal-type-content {
  border: 1px solid #00000036;
  padding: 0.5rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  border-radius: 5px;
  cursor: pointer;
}
.new-user-invite-modal-organisation-icon, .new-user-invite-modal-team-icon {
  font-size: 2rem;
  color: #0000004f;
  margin: 0.8rem;
}
.new-user-invite-modal-organisation-name, .new-user-invite-modal-team-name {
  font-size: 16.5px;
  font-weight: 700;
  color: #0000ffe0;
  margin-bottom: 0.3rem;
}
.new-user-invite-modal-footer-text-header {
  font-size: 12px;
  font-weight: 600;
  color: #000000bd;
  margin-bottom: -17px;
}
.new-user-invite-modal-footer-text-data {
  font-size: 13px;
  color: #000000ba;
  margin-top: -8px;
}
.new-user-invite-modal-footer-text {
  margin-bottom: 11px;
}
.invite-user-team-filter {
  width: auto;
  margin-right: 20px;
}
.invite-user-team-generate-link {
  display: flex;
  justify-content: center;
}
.invite-user-team-header {
  text-align: center;
}
.invite-user-generate-link-button{
  font-weight: bold;
}
.invite-user-generate-link-text{
  color:#0606bdf2;
  margin-top: 25px;
}
.invite-user-generate-link-text-link{
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
}
.invite-user-root{
  text-align: center;
}
.invite-user-generate-link-what-next {
  margin-top: 5px;
  font-size: 15px;
  color: #000000c4;
}
.invite-user-generate-link-history-table{
  font-size: 14px;
}
.invite-user-generate-link-history-table-content {
  text-align: center;
  padding: 30px 130px;
  margin-top: 14px;
}
.invite-user-generate-link-expire{
  text-align: left;
  font-size: 15px;
  color: #000000c4;
}
.page-content-wrapper-menu-filter-date ~ .dropdown-menu .dropdown-item {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 400;
}
.page-content-wrapper-menu-filter-date-text {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
.page-content-wrapper-menu-filter-date-text-desktop{
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.page-content-wrapper-menu-filter-dropdown-icon {
  font-size: medium;
  margin-left: 8px;
}
.invite-token-data{
  display: flex;
}
.invite-token-copy-button{
  margin-top: -4px;
}
.invite-token-link-text{
  margin-top: 4px;
}
table.invite-user-generate-link-history-table.table.table-bordered.table-hover tbody tr td {
  vertical-align: middle;
}
.manage-employee-resent-invite-link{
  font-size: 0.84rem;
  font-weight: 400;
}
.manage-employee-dollar-text-div{
  display: flex;
}
.manage-employee-dollar-text {
  display: block;
  width: 10%;
  height: calc(1.5em + 0.75rem + 6px);
  padding: 0.375rem 0.35rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057c7;
  border: 1px solid #ced4da;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  background-color: #f8f9fa;
  background-clip: padding-box;
  border-left: none;
  text-align: center;
}
.employee-card-avatar-profile{
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.employee-page-password-status-resend-link {
  font-size: 12px;
  color: #706f6fc4;
  text-decoration: underline;
  cursor: pointer;
}
.employee-page-password-status {
  font-size: 12px;
  color: #F39C12;
}
.employee-page-track-set {
  font-size: 12px;
  color: #706f6fc4;
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: #706f6f73 !important;
          text-decoration-color: #706f6f73 !important;
}
.employee-page-track-set:hover {
  text-decoration: underline;
  color:black;
  -webkit-text-decoration-color: black !important;
          text-decoration-color: black !important;
}
.Employees-root-content-data-details-passwors-status{
  color: #F39C12 !important;
  box-shadow: none;
}
.employee-page-name-password-status{
  color: #F39C12;
}
.add-another-team-button{
  font-size: 0.9rem;
}
.new-employee-modal-footer-help-text {
  font-size: 12px;
}
.new-employee-modal-footer{
  justify-content: space-between !important;
}
.new-employee-modal-footer-button .btn-light{
  margin-right:28px;
}
span.new-employee-modal-footer-help-text-role {
  font-weight: 600;
}
.invite-user-generate-link-what-next {
  margin-top: 19px;
  font-size: 15px;
  color: #000000c4;
  padding: 8px 235px;
  text-align: left !important;
  display: table;
  margin-right: auto;
  margin-left: auto;
}
label.invite-user-generate-link-what-next-title {
  color: #0088ff;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 2px;
  
}

.invite-user-generate-link-what-next-step {
   font-size: 13px;
  color: #808080cf;
  font-weight: 600;
}
.invite-user-generate-link-what-next-step-help {
  font-size: 13px;
  color: #808080cf;
  margin-top: 8px;
  font-weight: 600;
}
.employee-page-mobile-email-id {
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 170px;
}
.upgrade-plan-button-color-employee{
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: #007bffa8;
  border-color: #007bff;
  /* line-height: 1.5; */
  border-radius: .25rem;
}
/* .timesheet-settings-card-employee{
  padding: 1rem 1rem 1rem 1.3rem;
} */
.setting-user{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
    grid-column-gap: 1rem !important;
    column-gap: 1rem !important;
}
/* .new-employee-modal-setting{
  width:auto;
} */
.timesheet-settings-row-employee > * {
  margin-bottom: 1rem;
}
.custom-dialog { max-width: 71%; }
.timesheet-settings-row-grid-employee, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2 {
  /* align-items: start; */
  /* gap: 30px; */
  -moz-columns: 2 calc((100vw - 375px - 8rem) / 2);
  columns: 2 calc((100vw - 375px - 8rem) / 2);
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}
.user-employee-idel-dropdown{
  width: -moz-fit-content !important;
  width: fit-content !important;
  display: inline;
  margin-left: 1px;
}
.timesheet-settings-row-grid-card-employee {
  /* height: auto; */
  display: inline-block;
  margin-bottom: 1rem;
  /* width: calc((100vw - 375px - 6rem) /2); */
  width:33vw;
  color: rgb(74, 85, 104);
  /* overflow: auto; */
  padding: 16px;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(211, 211, 211);
  -o-border-image: initial;
  border-image: initial;
}
.timesheet-settings-row-grid-card h6{
  color:black;
}

@media only screen and (min-width : 1200px) and (max-width : 1300px){
  .Employees-root-table-email-name{
    width:158px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:158px;
  }
}
@media only screen and (min-width : 1024px) and (max-width : 1200px){
  .Employees-root-table-email-name{
    width:148px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:148px;
  }
}

@media screen and (max-width: 480px) {
  .timesheet-settings-row-grid-employee, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2{
    -moz-columns: initial;
         columns: initial;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .timesheet-settings-row-grid-card-employee{
    width: -webkit-fill-available;
  }
  .timesheet-setting-sub-button{
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .timesheet-settings-card {
    padding: 2rem 2rem 0rem 2rem;
  }
  .timesheet-setting-sub-button-div{
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .new-employee-modal-footer-help-title{
    font-size: 12px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .setting-user{
    grid-template-columns: 1fr;
  }
  .new-user-invite-modal-type {
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1.4rem;
    text-align: center;
  }
  .new-user-invite-modal-type-content{
    margin-bottom: 25px;
  }
  .invite-user-generate-link-history-table-content {
    padding: 0px 0px;
  }
  .invite-user-team-filter {
    margin-right: 11px;
  }
  .invite-user-generate-link-expire{
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .invite-user-team-header{
    margin-bottom: 30px;
  }
  .invite-user-generate-link-what-next {
    padding: 0px 0px;
  }
  .invite-token-copy-button{
    font-size: 10px;
  }
  .Employees-root-content-data-details-name-data-team-name{
    width:160px;
  }

  .Employees-root-header-filter-name{
    font-size: 14px;
  }
  .Employees-root-header-left-side-button{
    font-size: 12px;
  }
  .new-employee-modal-footer-button .btn-light {
    margin-right: 20px;
  }
}

@media only screen and (min-width : 767px) and (max-width : 820px){
  .add-email-label{
    font-size: 13px;
  }
  .Employees-root-table-email-name{
    width:125px;
  }
  .invite-user-generate-link-history-table{
     font-size: 10px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:130px;
  }
  .new-user-invite-modal-type {
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1.4rem;
    text-align: center;
  }
  .new-user-invite-modal-type-content{
    margin-bottom: 25px;
  }
  .invite-user-generate-link-history-table-content {
    padding: 0px 0px;
  }
  .invite-user-team-filter {
    margin-right: 11px;
  }
  .invite-user-generate-link-expire{
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .invite-user-team-header{
    margin-bottom: 30px;
  }
  .invite-user-generate-link-what-next {
    padding: 0px 0px;
  }
  .invite-token-copy-button{
    font-size: 10px;
  }
}

.new-user-invite-modal-type-number-of-license {
  font-weight: 500;
    text-align: center;
    font-size: 19px;
    margin-bottom: 14px;
}
.new-user-invite-modal-type-number-of-title{
  font-size: 15px;
  color: #000000bf;
}
.new-user-invite-modal-type-number-of-license-button {
  font-size: 16px;
  font-weight: 600;
  padding: 6px 30px;
}
.new-user-invite-modal-type-number-of-license-label {
  font-size: 17px;
  font-weight: 500;
  color: rgba(0,0,0,.6705882352941176);
}
.new-user-invite-modal-card-title-license-text {
  color: red;
  font-size: 17px;
  font-weight: 600;
  margin-top: 24px;
  text-align: center;
}
.new-user-invite-modal-card-title-license-purchase-button-div{
  text-align: center;
}
.new-user-invite-modal-card-title-license-purchase-button{
  padding: 5px 16px;
  font-weight: 600;
  margin-top: 20px;
}
.new-user-invite-modal-type-number-of-license-header{
  color: red;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.new-user-invite-modal-type-number-of-license-label-error{
  color: red;
  font-size: 14px;
  margin-top: 9px;
}
.new-user-invite-modal-type-number-of-license-help{
  font-size: 14px;
  margin-top: 27px;
}
.new-user-invite-modal-type-number-of-license-input{
  text-align: center;
}
.employee-page-app-version {
  font-size: 12px;
  color: #80808085;
  font-weight: 600;
  margin-left: 5px;
}
.new-user-invite-modal-type-number-of-license-help-warning {
  font-size: 17px;
  color: red;
}

/* DEFAULT FORM CSS OVERWRITE */
.form-check-input {
  /* position: inherit !important; */
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.manage-employee-edit-track-settings-checkbox input {
  margin-right: 8px;
}
.employee-billable-form{
  padding: 10px 10px 0px 10px;
}
.employee-billable-form-whole-div{
  font-size: 13px;
  margin-bottom: 1.5rem;
}


.manage-billable-team-modal-button {
  margin: auto;
  padding: 9px 0;
  display: flex;
  justify-content: center;
}
.manage-billable-form{
  width:30%;
}
.manage-billable-form::-moz-placeholder{
  color:#bdbbbb!important;
}
.manage-billable-form::placeholder{
  color:#bdbbbb!important;
}
.manage-billable-form-bill{
  width:23%;
}
.manage-billable-form-bill::-moz-placeholder{
  color:#bdbbbb!important;
}
.manage-billable-form-bill::placeholder{
  color:#bdbbbb!important;
}
.bilable-rate-time-whole-div{
  display: flex;
  margin-bottom: 0.7rem;
}

.bilable-rate-time-div-datepicker-text{
  margin-left: -12px;
  display: flex;
  align-items: center;
}
.billable-rate-label-div{
  color: rgba(83,92,104,.9882352941176471);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.bilable-rate-time-div-datepicker-inside{
  padding: 19px 17px !important;
}

.bilable-rate-time-div-datepicker .btn-primary {
  border: none;
  margin-right: 1.5rem;
  width: 145px;
  color: rgb(51, 51, 51);
  text-align: left !important;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  margin: 0 10px;
}

.bilable-rate-time-div-datepicker .dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  color: #ccc;
}

.bilable-rate-time-div-datepicker .btn-primary:hover {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}

.bilable-rate-time-div-datepicker .btn-primary:active {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}

.bilable-rate-time-div-datepicker .btn-primary:focus {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}
.employee-page-app-version:hover {
 text-decoration: none !important;
}
a:hover .employee-page-app-version {
  display: inline-block; 
  text-decoration:none !important;
}
.manage-teams-edit-menus #dropdown-basic{
  right: 0px !important;
}
.employee-page-app-version-icon {
  width: 11px;
  color: #80808026;
  margin-top: -3px;
  opacity: 0.3;
}
.employee-page-app-version-icon-hover {
 opacity: 0.7;
}


.timesheet-settings-row-grid-employee-start-time .rc-time-picker-input {
  font-size: 0.9rem !important;
  margin-left: 0% !important;
  width: 100% !important;
}
.new-employee-modal-setting-error {
    height: 83vh;
    overflow: scroll;
}
.new-employee-modal-setting {
    height: -moz-fit-content;
    height: fit-content;
    overflow: scroll;
}
.new-user-invite-modal-type-loading-body{
  height: 300px;
}
.manage-employee-team-details-loading{
  height: 345px !important;
}
.new-user-invite-modal-type-number-of-license-help1 {
  font-size: 16px;
  margin-top: 5px;
}
.new-user-invite-modal-type-number-of-license-help2 {
  font-size: 14px;
  margin-top: 10px;
}
.add-employee-modal-form-employee-id-label{
  color: gray;
  font-size: 15px;
}
.add-employee-modal-form-employee-label-name-required {
  color: #ff0000b0;
}
.Employees-root-content-data-details-td-employee-id-no-data {
  font-size: 12px;
  color: #80808085;
  font-weight: 600;
}
.Employees-root-content-data-details-td-employee-id {
  width: 90px;
}
.Employees-root-content-data-details-td-employee-id-data {
  color: gray;
  font-size: 12px;
  margin-bottom: 2px;
}
.new-user-invite-modal-type-number-of-license-text{
  font-size: 16px;
}
.timesheet-settings-row-grid-card-organisation-timezone {
  margin-bottom: 2rem !important;
}
.timesheet-settings-row-grid-card-employee-timezone-select {
  font-size: 15px;
}
.employee-add-modal-error{
  width: 100%;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px !important;
}
.view-leave-root{
    padding: 2rem;
    color: #131313;
}
.view-leave-chart-div{
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 0.5px solid rgba(0,0,0,.08);
    height: 301px;
}
.leave-listing-filter-div-field-input-apply {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width:260px;
    text-decoration: underline;
}
.leave-listing-filter-date-picker-filter-date-text-apply {
    font-size: 15px;
    margin: 0;
    font-weight: 600 !important;
    color: rgba(0,0,0,.8);
}
.leave-listing-filter-date-picker-filter-date-icon-apply {
    display: inline;
    height: 100%;
    margin-right: 5px;
    color: rgba(0,0,0,.8);
}
.leave-listing-filter-date-picker-filter-date-apply {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    /* transition: all 100ms ease;
    border: 1px solid #ced4da; */
    /* border-radius: 0.25rem; */
}
.leave-type-text-header-apply{
    display: flex;
    align-items: center;
}
.view-leave-chart-div-whole-header{
    display: flex;
    align-items: center;
}
.leave-listing-root{
    border: 0.5px solid rgba(0,0,0,.08);
    border-radius: 5px;
    background-color: #fff;
}
.leave-listing-tab-whole-div{
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    min-height: 450px;
}
.leave-listing-tab-header-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    display: flex;
    /* justify-content: flex-end; */
    margin: 8px 0px;
    margin-right: 2px;
    /* border-bottom: none; */
    border-bottom: 1px solid #dee2e6;
}
.leave-listing-tab-header-tab .nav .nav-tabs .nav-link{  
    border-bottom: none;
}
.leave-listing-tab-header-tab .nav-item:first-child {
    margin-right: auto;
}
.leave-listing-tab-whole-div-span {
    float: left;
  position: relative;
  left: 23px;
  top: 20px;
  z-index: 55;
}
.leave-listing-tab-tab-Content{
    padding: 1rem;
}
.leave-listing-tab-tab-Content-list{
    display: flex;
    align-items: center;
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    font-size: 14px;
    /* box-shadow: -1px 4px 3px -6px #333;
    background-color: #fff!important; */
    margin-bottom: 1rem;
    border: 1px solid rgba(0,0,0,.050980392156862744);
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
}
.leave-listing-tab-tab-Content-list-dates-text {
    color: #212529;
    margin-right: 5px;
    width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.apply-leave-footer-apply-leave-button-text {
    color: #ff00009c;
}
.leave-listing-tab-tab-Content-list-status-badge {
    padding: 6px !important;
    letter-spacing: 0.3px;
    font-size: 87%;
    font-weight: 500;
    /* text-transform: capitalize; */
    width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-listing-tab-tab-Content-list-status-badge-approved-color
{
    background-color: #27ae60 !important;
}
.leave-listing-tab-tab-Content-list-status-badge-pending-color{
    background-color: #f1c40f !important;
}
.leave-listing-tab-tab-Content-list-status-badge-rejected-color{
    background-color: #E67E22 !important;
}
.leave-listing-tab-tab-Content-list-date{
    width: 30%;
    color:#007bff;
}

.leave-listing-tab-tab-Content-list-dates-count{
    width: 15%;
}
.leave-listing-tab-tab-Content-list-type{
    width:20%;
    text-transform: capitalize;
}
.leave-listing-tab-tab-Content-list-type-div-text{
    width: 200px;
    /* cursor:pointer; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-listing-tab-tab-Content-list-status{
    width: 26%;
    display: flex;
    align-items: center;
}

.leave-listing-tab-tab-Content-list-edits{
    width: 20%;
}
.leave-listing-tab-tab-Content-list-dates-text svg{
    margin-top: -2px;
}
/* .view-leave-add-button-div {
    display: flex;
    margin-bottom: 2rem;
} */
.view-leave-add-button {
    position: relative;
    margin-left: auto;
    margin-right: 0;
}
.leave-listing-tab-tab-Content-notext{
    text-align: center;
    margin: 100px auto;
    color:gray;
}
.view-leave-chart-demo-div{
text-align: center;
}
.view-leave-chart-demo-div img{
    width:300px;
    height: 200px;
}
.view-leave-chart-demo-div-text{
    color: gray;
    margin-top: 105px;
}






.apply-leave-modal-body-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;
}
.apply-leave-modal-body-wrapper-form-name {
    display: flex;
}

.apply-leave-modal-body-wrapper-form-name-card-avatar {
    display: inline-block;
    margin-right: 10px;
    height: 35px;
    width: 35px;
    border-radius: 40px;
    background: #48cfd6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 20px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    color: white;
}
.apply-leave-modal-body-wrapper-form-display-name {
    font-size: 21px;
    font-weight: 600;
}
.manage-teams-edit-task-label-name {
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
}
.apply-leave-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
}
.my-projects-modal-content-name-field {
    margin-right: 0 !important;
}
.my-projects-modal-content-name-field {
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 0rem;
    margin-right: 16px;
    margin-bottom: 16px;
}
.apply-leave-modal-body-wrapper-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.apply-leave-modal-body-wrapper-form-div-field-input > div{
    border: 1px solid #ced4da;
    height: calc(1.5em + 0.75rem + 2px);
}
.apply-leave-modal-body-wrapper-form-div-field-date-picker {
    border: 1px solid #ced4da;
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0.25rem;
    width: 100%;
}
.apply-leave-modal-body-wrapper-form-div-field-date-picker-div .react-datepicker-wrapper .react-datepicker__input-container,.apply-leave-modal-body-wrapper-form-div-field-date-picker-div .react-datepicker-wrapper{
    max-width: 100% !important;
}

.apply-leave-modal-body-wrapper-form-div-field-form-group {
   display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.apply-leave-modal-body-wrapper-form-div-field-input::-moz-placeholder{
    color: rgb(212, 209, 209);
}
.apply-leave-modal-body-wrapper-form-div-field-input::placeholder{
    color: rgb(212, 209, 209);
} 
.apply-leave-modal-body-wrapper-summary-content-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #535c68fc;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -.22px;
    margin-bottom: 5px;
}
.apply-leave-modal-body-wrapper-summary-content-row-day-select {
    border: none;
    margin-top: -5px;
    color: #0091ff;
    font-size: 14px;
    padding: 0px;
    background: transparent;
}
.apply-leave-modal-body-wrapper-summary-content-row .form-group{
    margin-bottom: 0rem !important;
}
.apply-leave-modal-body-wrapper-summary-content-non-working {
    margin-bottom: 15px;
}
.apply-leave-modal-body-wrapper-summary-content {
    padding: 3px;
    margin-top: 10px;
    height: 215px;
    overflow: scroll;
}
.permission-modal-body-wrapper-summary-content {
    padding: 3px;
    display: flex;
    justify-content: space-between;
}
.apply-leave-modal-body-wrapper-summary {
    display: flex;
    flex-direction: column;
    background-color: #00a3ff12;
    padding: 20px;
    border-radius: 4px;
    margin: 17px 0px;
}
.apply-leave-modal-body-wrapper-summary-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    color: #535c68fc;
    font-weight: 600;
    font-size: 15px;
}
.apply-leave-modal-body-wrapper-summary-footer > div {
    /* margin-bottom: 10px; */
}
.apply-leave-modal-title {
    margin: 1.5rem 0rem 0rem 1.5rem;
    font-weight: 600;
    color: #000000d4;
    font-size: 17px;
}
.apply-leave-modal-header-title {
    font-size: 18px;
}
.react-datepicker__input-container .apply-leave-modal-body-wrapper-form-div-field-input{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: calc(1.7em + 0.75rem + 2px);
    width: 100%;
}
.apply-leave-modal-body-wrapper-summary-content-non-working-text {
    color: #808080a3;
    font-weight: 500;
    font-size: 13px;
    float: right;
    padding: 8px;
}
.apply-leave-modal-body-wrapper-summary-content-select-dates{
    color: #808080a3;
    font-weight: 500;
    font-size: 13px;
   padding: 8px;
   text-align: center;
}
.apply-leave-footer-apply-leave-button {
    text-align: center;
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 600;
}

.apply-leave-modal-body-wrapper-form-div-field-input-date {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date-icon {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    height: 15px;
    margin: 0 5px;
    opacity: 0.8;
    font-size: smaller;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date-text {
    font-size: 0.86rem;
    margin: 0;
    font-weight: 600 !important;
    color: #495057;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger {
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    border-color: #00000017;
    display: block;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger .arrow::before {
    border-bottom-color: #00000017;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger .popover-body {
    overflow: hidden;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay {
    /* display: flex; */
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-datepicker {
    display: inline-block;
    width: 260px !important;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 35px;
    margin-bottom: 30px;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-button {
    margin-right: 1.7rem;
    position: relative;
    float: right;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.apply-leave-modal-body-wrapper-summary-content-row-day-select div{
    border: none !important;
    color: #0091ff;
    background: transparent;
}

.leave-listing-load-more-button {
    display: flex;
    justify-content: center;
}

.leave-list-text-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    color: #bdbbbb !important;
    margin-bottom: 2rem;
}
.view-leave-chart-demo-div-temp-div{
    margin-top: 5rem;
}

.leave-listing-tab-tab-Content-loader{
    margin: 100px auto;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .leave-listing-tab-whole-div-span {
        float: unset;
    }
    .leave-listing-tab-tab-Content{
        overflow: auto;
        white-space: nowrap;
    flex-wrap: nowrap;
    }

    .leave-listing-tab-tab-Content-list-whole{
        display: grid;
    }
    .leave-listing-tab-tab-Content-list-date {
        width: 205px;
    }
    .leave-listing-tab-tab-Content-list-dates-count {
        width: 65px;
    }
    .leave-listing-tab-tab-Content-list-type {
        width: 125px;
    }
    .leave-listing-tab-tab-Content-list-status {
        width: 65px;
    }
    .leave-listing-tab-tab-Content-list-edits {
        width: 200px;
    }
    .apply-leave-modal-body-wrapper{
        display: grid;
        grid-template-columns: 1fr !important;
        grid-gap: 0rem !important;
    }
}
.error + .apply-leave-modal-body-wrapper-form-date-picker-filter-date, .error + .apply-leave-modal-body-wrapper-form-field-text-area{
    border-color: #F0624D;
}
.apply-leave-modal-error{
    font-size: 13px;
    margin-top: 5px;
}
svg g, svg g:first-of-type path {
    stroke: none !important;
    stroke-width: 0  !important;
}
.apply-leave-modal-body-wrapper-form-field-text-area {
    font-size: 0.86rem;
    margin: 0;
    font-weight: 600 !important;
    color: #495057;
}
.apply-leave-modal-body-wrapper-summary-content-member {
    padding: 3px;
    margin: 2px 0px 10px 0px;
    height: 150px;
    overflow: scroll;
}
.permission-modal-body-wrapper-summary-content{
    display: flex;
    justify-content: space-around;
}
.leave-listing-tab-tab-Content-list-status-badge-permission {
    padding: 6px !important;
    letter-spacing: 0.3px;
    font-size: 87%;
    font-weight: 500;
    /* text-transform: capitalize; */
    width: 90px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    background-color: #BDC3C7 !important;
}

svg g, svg g:first-of-type text {
   font-style: normal !important;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-close-icon-div {
    float: right;
    margin-top: 5px;
    cursor: pointer;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-close-icon {
    font-size: 20px !important;
    color: black !important;
}

.leave-type-card-root-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 20px 0px 20px;
    margin-bottom: -0.5rem;
}
.leave-type-card-root {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 0.5px solid rgba(0,0,0,.08);
    height: 315px;
}
.leave-type-text-header {
    padding: 20px 20px 0px 20px;
}
.leave-type-table-whole-div {
    width: 76vw;
    overflow: auto;
    color: #4a5568;
}
.leave-type-content-data-header {
    color: rgb(121 119 119 / 35%);
    font-size: 0.9rem;
}
.leave-type-card-apply-leave-page-card-p-icon{
    font-size: 35px;
    margin-bottom: 10px;
}
.leave-type-card-root-whole-div{
    background-color: #f8f9fa!important;
    box-shadow: 0px -1px 3px -6px #333;
    font-size: .9rem;
    font-weight: 400;
}
.leave-type-card-root-whole-div-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
    margin-bottom: 10px;
}
.leave-type-card-root-whole-div-availale{
    font-size: .8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
    margin-bottom: 5px;
}
.leave-type-card-root-whole-div-booked{
    font-size: .8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
}
.leave-setting-mute-text{
    color: #7788998c;
}
.view-leave-whole-div {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem; */
}
.leave-type-card-apply-leave-page{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
    grid-gap: 0.8rem;
    margin: 20px;

    grid-auto-flow: column;
    /* grid-auto-columns: minmax(160px,1fr);
    overflow-x: auto; */
}
.leave-type-card-apply-leave-page-card {
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.04);
    text-align: center;
    /* width: 187px; */
    min-width: 186px;
    max-width: 200px;
    height: 210px;
    float: left;
    margin-left: 10px;
    margin-bottom: 0;
    padding: 20px 20px;
    overflow: hidden;
    transition: all 0.3s;
    border-radius: 4px;
}
.leave-type-card-apply-leave-page-card-p{
    margin-bottom: 0px;
    padding-top: 10px;
    font-size: 14px;
}
.apply-leave-footer-apply-leave-button-extra-box {
    width: 95%;
    padding: 15px 15px 15px 15px;
    /* margin-right: 21px; */
    margin: 0px auto 20px 20px;
    background: #fff;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
    /* display: flex;
    flex-direction: column; */
    
}
.leave-type-card-apply-leave-page-card-header-icon{
    color: #96bc42 !important;
    background: transparent !important;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 3px;
    display: inline-block;
/* 
    display: inline-block;
    height: 45px;
    width: 45px;
    border-radius: 70px;
    background: rgba(239,120,14,.5490196078431373);
    border: 1px solid rgba(0,0,0,.1);
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin-right: 13px; */

    /* display: inline-block;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #8394ab;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 22px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    margin-right: 13px; */
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-sub{
    width:545px;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-sub-2{
    border-left: 1px solid #cccccc47;
    padding-left: 25px;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-label {
    color: gray;
}

.apply-leave-footer-apply-leave-button-extra-box-text {
    /* margin-bottom: 5px; */
    font-size: 13px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    grid-gap: 1.5rem;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header {
    /* margin-bottom: 12px; */
    font-size: 14px;
    display: flex;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-span{
    font-weight: 600;
    margin-bottom: 10px;
}
.leave-type-card-apply-leave-page-card-span {
    color: gray;
}
.leave-type-card-apply-leave-page-card-p-header {
    margin-bottom: 10px;
    font-weight: 600;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.leave-settings-no-data-text-view {
    font-size: 14px;
    text-align: center;
    color: gray;
    margin-top: 95px;
}
.apply-leave-modal-body-wrapper-summary-content-row-date-span {
    margin-right: 10;
    margin-left: 7px;
    color: #808080b5;
}
svg.apply-leave-button-icon {
    margin: -2px 2px 0px 0px;
    /* width: 11px;
    height: 10px; */
    color: white !important;
}

.view-leave-balanace-count-tooltip-user-content{
    margin-bottom: 2px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 500;
}
.leave-listing-tab-tab-Content-list-dates-count-span {
    margin-right: 8px;
}
.leave-listing-tab-tab-Content-list-dates-count-span-icon{
    color: gray;
}
.view-leave-balanace-tooltip-start .tooltip-inner {
    min-width: 250px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: #dee2e6;
    position: sticky;
    z-index: 1 !important;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    font-size: 15px;
}
.view-leave-balanace-tooltip-start-span{
    color:white !important;
    font-size: 14px;
    margin-left: 5px;
}
.view-leave-balanace-count-tooltip-user-content-div{
    display: flex;
    justify-content:  left;
    padding: 3px;
}

.leave-listing-filter-new-root {
    margin-bottom: 1rem;
}
.leave-listing-filter-content {
    display: flex;
    justify-content: end;
}
.leave-listing-filter-user {
    width: 220px;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 15px;
}
.leave-listing-filter-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width: 225px;
}
.leave-listing-filter-date-picker-filter-date {
    display: flex !important;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    justify-content: space-between;
}
.leave-listing-filter-date-picker-filter-date-icon {
    display: inline;
    height: 100%;
    /* width: 100%; */
    margin-right: 5px;
}
.leave-listing-filter-date-picker-filter-date-text {
    font-size: 14px;
    margin: 0;
    font-weight: 400 !important;
    color: hsl(0, 0%, 20%);
}

.apply-leave-page-wrapper-form-date-picker-filter-filter {
    display: inline-block;
    width: 226px !important;
    vertical-align: top;
    margin-left: 8px;
    margin-top: 13px;
    margin-bottom: 8px;
}
.leave-listing-filter-date-picker-filter-date-label{
    display: flex;
    align-items: center;
}
.leave-listing-filter-date-picker-filter-date-clear-icon {
    font-size: 17px;
}
.permission-modal-body-wrapper-summary-content-member {
    display: flex;
    justify-content: space-around;
}
.leave-history-filter-new-root {
    margin: 2rem;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.leave-history-filter-content {
    display: flex;
}
.leave-history-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.balance-leave-setting-modal-full-div .modal-dialog {
    max-width: 615px;
}
.add-user-leave-balace-flex{
    display: flex;
    justify-content: space-around;
}
.add-user-leave-balace-flex-label{
    font-size: 14px;
}
/* edit userwise css*/
.edit-user-wise-Leave-type-modal-grid{
    display: inline;
    /* grid-template-columns: 1fr 1fr;
    gap: 1rem; */
    padding: 0.5rem;
}
.edit-user-wise-Leave-type-modal-grid-right-header-p{
    font-size: 18px;
}
.edit-user-wise-leave-settings-modal-title.modal-title {
    font-size: 20px;
    text-align: center;
    width: 90%;
}
.apply-leave-modal .modal-content {
    /* background-color: rgb(252, 252, 252);    ; */
}
.apply-leave-new-modal .modal-dialog {
    max-width: 590px;
}
.edit-user-wise-Leave-type-modal-grid-right{
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px 2px;
    padding: 1rem;
}
.edit-user-wise-Leave-type-modal-grid-left{
    /* background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px 2px; */
    padding: 0 1rem;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p{
    color: rgba(83, 92, 104, 0.64);
    font-size: 14px;
    margin-bottom: 0;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p-days{
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    color: #007bff;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p-days-balance{
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-progess{
    color: rgba(83, 92, 104, 0.64);
    font-size: 14px;
    margin-bottom: 5px;
}
.edit-user-wise-leave-history-tab-leave-root-tab {
    font-size: 16px;
    color: rgba(0, 0, 0, .5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link {
    color: #343a40;
  }
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link.active{
    color: #007bff;
}
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link:hover{
    border: none;
}
.user-wise-leave-balance-month-settings {
    margin-bottom: 0px;
    font-size: 14px;
}
.user-wise-leave-balance-month-settings input{
    margin-right:5px;
}
.user-wise-leave-balance-month-settings label{
   display: flex;
   align-items: center;
}
.edit-user-wise-leave-history-tab-available {
    padding: 15px 0px 0px 0px;
}
.edit-user-wise-leave-history-tab-future-whole-div {
    margin-bottom: 5px;
}
.edit-user-wise-leave-history-tab-future-whole-div {
    margin-bottom:10px;
    margin-top:10px;
}
.user-wise-leave-balance-month-settings-span{
    font-size: 12px;
    color: rgb(140, 148, 161);
    margin: 0px 5px 5px 5px;
}
.user-wise-leave-balance-month-settings-label{
    font-size: 12px;
}
.user-wise-leave-balance-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
/* .edit-user-wise-leave-history-tab-leave-root-tab.a{
    color: #495057;
    background-color: #fff;
}
.edit-user-wise-leave-history-tab-leave-root-tab:active {
    color: rgb(0, 123, 255);
    border-bottom: 4px solid rgb(0, 123, 255);
} */

.user-wise-leave-balance-month-settings-span-p {
    font-size: smaller;
    font-weight: 500;
    color: #656565;
    margin: 15px 0px 5px 0px;
}








.leave-setting-type-wrapper-div-balance {
    border: 1px solid #d3d3d3;
    padding: 17px 0px 3px 0;
    border-radius: 6px;
    background-color: hsla(0, 0%, 50.2%, .050980392156862744);
    margin: 0 8px 8px 8px;
}
.leave-setting-time-option-div-balance {
    display: flex;
    width: 285px;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}
.short-leave-history-time{
    color:gray;
    font-size: 0.8rem;
}
.leave-history-tab-whole-div {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    white-space: nowrap;
    flex-wrap: nowrap;
}
.Leave-edit-balance-modal-notes-header {
    color: grey;
    font-size: 12px;
    padding: 10px;
}
.Leave-edit-balance-modal-notes-header-note-text-span
{
    font-weight: bold;
}
.Leave-edit-balance-modal-notes-header-note-text{
    margin-bottom: 5px;
}
.Leave-edit-balance-modal-notes-header-button
{
    margin:5px;
}
.Leave-edit-balance-modal-notes-header-note-text-p a{
    color:#035fcb;
    font-weight: unset !important;
}
.Leave-edit-balance-modal-notes-header-note{
    font-weight: bold;
    color: #2d3748;
}
.leave-history-tab-leave-root-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}


.leave-summary-leave-balance-content-view {
    /* width: 100vw; */
    display: grid;
    margin: 0 35px 0 35px;
}
.monthly-in-out-content-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}
.leave-summary-leave-balance-table-thead {
    position: sticky;
    top: 0px;
    /* z-index: 1; */
}
.page-content-wrapper-header-name-sup-leave {
    font-size: 10px;
    /* font-weight: normal;
    color: rgba(112, 111, 111, .7686274509803922); */
    margin-left: 2px;
}
.leave-summary-leave-balance-table-thead th{
    border-bottom: 1px solid #dee2e6 !important; 
}
.leave-summary-leave-balance-content-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}

.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.summary-leave-balanace-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width:225px;
}
.leave-history-date-picker-finacial {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date-text {
    font-size: 15px;
    margin: 0;
    font-weight: 500 !important;
    color: rgba(0,0,0,.8);
}
.summary-leave-balanace-modal-avartar-emp{
    display: flex;
    align-items: center;
}
.summary-leave-balanace-modal-header-avatar {
    display: inline-block;
    text-align: center;
    color: #333333;
}
.summary-leave-balanace-modal-avartar{
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #fff;
    background-color: #8394ab;
    margin-left: 4px;
    margin-right: 8px;
}
.summary-leave-balanace-modal-avartar-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.summary-leave-balanace-modal-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-left: 4px;
    margin-right: 8px;
    border-radius: 50%;
}
.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date-icon {
    display: inline;
    height: 100%;
    /* width: 100%; */
    margin-right: 5px;
}
.summary-leave-balanace-modal-avatar-profile-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #212529;
    width: 150px;
}
.summary-leave-balanace-header-summary-card-text-ques {
    font-size: .9rem;
    color: #808080ba;
    float: right;
}

.summary-leave-balanace-tooltip-start .tooltip-inner {
    min-width: 330px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: #dee2e6;
    position: sticky;
    z-index: 1 !important;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    font-size: 15px;
}


.summary-leave-balanace-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}
.summary-leave-balanace-count-tooltip-whole{
    text-align: left;
    padding: 10px;
  }
.summary-leave-balanace-tooltip-start-span{
    color:white !important;
    font-size: 14px;
}
.summary-leave-balanace-count-tooltip-user-content{
    margin-bottom: 2px;
    max-width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 500;
}
.summary-leave-balanace-modal-header-avatar-edit {
    font-size: 12px;
    color: rgba(112,111,111,.7686274509803922);
    cursor: pointer;
    text-decoration: underline;
    -webkit-text-decoration-color: rgba(112,111,111,.45098039215686275)!important;
    text-decoration-color: rgba(112,111,111,.45098039215686275)!important;
}
.summary-leave-balanace-modal-header-label {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-right: 35px;
}

.leave-history-filter-download-icon {
    /* color: #007bff; */
    font-size: 1.1rem;
    font-weight: 700;
}
.leave-history-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin: 2rem;
}
.leave-history-table {
    width: 100%;
    border-spacing: 0;
    /* border: 1px solid #dee2e6; */
    border-collapse: separate;
    border-spacing: 0em 0.7em;
}
.leave-history-table tbody tr td {
    /* font-weight: 600; */
    color: #34495e;
    font-size: 0.9rem;
    background-color: white;
    padding: 0.7rem !important;
}
.leave-history-table thead tr th {
    padding: 0.2rem 0.7rem !important;
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
.leave-history-content td {
    margin: 0;
    border-top:0px solid rgba(0,0,0,.050980392156862744) !important ;
    border-bottom:0px solid rgba(0,0,0,.050980392156862744) !important;
    border-right: 0px;
}
.leave-history-table tbody tr td:first-child {
    border-left: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-table tbody tr td:last-child {
    border-right: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    width: 240px;
}

 .leave-history-filter-root {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706);
    justify-content: space-between;
}
.leave-history-table tbody tr {
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    font-size: .9rem;
}
.leave-history-table-content-data-details-avatar {
    display: flex;
}
.leave-history-table-content-data-details-avatar-circle {
    height: 25px;
    width: 25px;
    background-color: rgb(64, 186, 171);
    color: rgb(244 245 249);
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.75rem;
    border-radius: 50%;
    text-transform: uppercase;
}
.leave-history-table-content-data-details-name-data-team-name {
    margin-bottom: 3px;
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
}
.view-user-leave-modal-header-title {
    font-size: 18px;
}
.view-leave-history-content-new {
    height: 100vh ;
    overflow: auto;
    /* margin: 2rem; */
}
.view-user-leave-history-table-approved-status{
    color: green;
}
.view-user-leave-history-table-rejected-status{
    color: red;
}
.view-user-leave-history-table-pending-status{
    color: orange;
}
.view-user-leave-history-table {
    width: 100%;
    border-spacing: 0;
    /* border: 1px solid #dee2e6; */
    border-collapse: separate;
    border-spacing: 0em 0.7em;
}
.view-user-leave-history-table thead tr th {
    padding: 0.2rem 0.7rem !important;
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
.view-user-leave-history-table tbody tr {
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    font-size: .9rem;
}
.view-user-leave-history-table tbody tr td {
    font-weight: 600;
    color: #34495e;
    font-size: 0.9rem;
    background-color: white;
    padding: 0.7rem !important;
    border-right: 0px;
}
.view-user-leave-history-table tbody tr td:first-child {
    border-left: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.view-user-leave-history-table tbody tr td:last-child {
    border-right: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-table tbody{
    cursor: pointer;
}
.leave-history-table-content-view-leave-button {
    text-decoration: underline;
    color: #363c42b0;
    cursor: pointer;
}
.leave-history-table-content-view-leave-button:hover {
    color: #0000ffb3;
    
}

.leave-summary-leave-balance-content-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    vertical-align: middle;
    /* text-align: left; */
}
.leave-summary-leave-balance-content-table thead > tr th,.leave-summary-leave-balance-content-table tbody > tr td:not(:first-child) {
     text-align: center; 
}
.leave-summary-leave-balance-content-table th, .leave-summary-leave-balance-content-table tbody > tr td:first-child{
    background-color: #F3F7FA!important;
}

.leave-summary-leave-balance-content-table thead tr th{
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
    /* min-width: 6rem;
    max-width: 6rem; */
    /* min-width: 150px;
    max-width: 300px; */
    min-width: 8rem;
    max-width: 11rem;
}
.leave-summary-leave-balance-content-table thead> tr th:nth-last-child(-n+3) {
    vertical-align: middle;
}
.leave-summary-leave-balance-content-table thead > tr th:first-child ,.leave-summary-leave-balance-content-table thead> tr th:nth-child(2) ,.leave-summary-leave-balance-content-table thead> tr th:nth-child(3){
    /* min-width: 10rem;
    max-width: 10rem; */
}
.leave-summary-leave-balance-content-table thead>tr th:nth-child(2) ,.leave-summary-leave-balance-content-table thead>tr th:nth-child(3) {
    vertical-align: middle;
}

.leave-summary-leave-balance-content-table tbody> tr td {
    border-right: 0px !important;
    font-size: 0.9rem;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
    .leave-history-filter-new-root{
        display: inherit;
    }
    .leave-history-filter-content{
        display: inherit;
    }
    .leave-history-content {
        width: 100%;
        display: grid;
    }
    
}
.leave-history-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.leave-history-content-data-details-name-data-team-name-whole {
    width: 214px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 0px;
}

.leave-history-team-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.leave-history-card-table-list-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
    color: #333;
}

.view-user-leave-history-table-leave-reason {
    width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-tracker-load-more-button button {
    font-size: 14px;
    padding: 7px 12px;
    font-weight: 500;
}

.leave-history-table-content-no-data-text {
    text-align: center;
    color: gray;
}
.leave-summary-user-leave-details-leave-type{
    text-transform: capitalize;
}
.view-user-leave-history-table-status{
    text-transform: capitalize;
}
.user-leave-listing-tab-tab-Content-list-dates-count-span {
    margin-right: 4px;
}
.leave-listing-tab-tab-Content-list-dates-count-span-icon{
    cursor: pointer;
}
.user-leave-listing-tab-tab-Content-list-dates-count-wrapper{
    display: flex;
}
.admin-view-leave-root{
    /* margin: 2rem;
    border: 0.5px solid rgba(0,0,0,.08);
    border-radius: 5px; */
    background-color: #fff;
}
.admin-view-leave-root-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}
.admin-view-leave-root-tab .nav .nav-tabs .nav-link{  
    /* border-bottom: none; */
}
.timelapse-warning-text-p-a{
    color: #266dfb;
    cursor: pointer;
}
.admin-view-leave-filter-root {
    display: flex;
    margin: 2rem 2rem 1rem 2rem;
    justify-content: space-between;
}
.admin-view-leave-filter-content {
    display: flex;
}
.admin-view-leave-filter-user-tab-filter {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-filter-user-tab-filter .loading-wrapper .loading-svg {
    font-size: 35px;
}
.admin-view-leave-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 30px;
}
.admin-view-leave-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.admin-view-leave-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    /* margin-top: .5rem; */
}
.admin-view-leave-table thead th {
    border: none;
}

.admin-view-leave-table th,
.admin-view-leave-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem 0.7rem;
}

.admin-view-leave-table-body {
    color: #333;
}
.admin-view-leave-card-table-list-task-name {
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}
.admin-view-leave-card-table-list-task-name-td {
    width: 20%;
}
.admin-view-leave-card-table-list-type-td{
    width: 20%;
}
.admin-view-leave-card-table-list-date-td{
    width: 25%;
}
.admin-view-leave-card-table-list-count-td{
    width: 10%;
}

.admin-view-leave-reviewed-button{
    margin-right: 20px;
    padding: 0.2rem 0.8rem;
}

.admin-view-leave-listing-tab-Content {
    padding: 0rem 2rem 2rem 2rem;
}
.admin-view-leave-listing-tab-Content-list {
    /* display: flex;
    align-items: center; */
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 1rem;
    border: 1px solid rgba(0,0,0,.050980392156862744);
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
}
.admin-view-leave-listing-tab-Content-list-displayName {
    width: 30%;
}
.admin-view-leave-card-table-list-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
    color: #34495e;
}
.admin-view-leave-listing-tab-Content-list-date {
    width: 35%;
    /* color: #007bff; */
}
.admin-view-leave-listing-tab-Content-list-dates-count {
    width: 15%;
}
.admin-view-leave-listing-tab-Content-list-dates-text {
    color: #808080eb;
    cursor: pointer;
}
.admin-view-leave-listing-tab-Content-list-type {
    width: 25%;
}
.admin-view-leave-listing-tab-Content-list-info{
    width:10%;
}
.admin-view-leave-listing-tab-Content-list-on{
    width: 20%;
}
.admin-view-leave-card-table-list-status-td{
    display: flex;
    width: 17%;
}
.admin-view-leave-card-table-list-status-td-text{
    width:10%;
    /* text-transform: capitalize; */
}
.admin-view-leave-ta-contents-header {
    color: gray;
    border: none;
    font-size: 14px;
    /* display: flex;
    align-items: center; */
    padding: 0.6rem 0.8rem;
    margin-bottom: 0.4rem;
}
.admin-view-leave-listing-tab-Content-list-date-header {
    width: 35%;
}

.admin-view-leave-listing-tab-Content-list-dates-count-header {
    width: 15%;
}
.admin-view-leave-listing-tab-Content-list-type-header {
    width: 20%;
}
.admin-view-leave-listing-tab-Content-list-on-header{
    width: 25%;
}
.admin-view-leave-card-table-list-status-td-header{
    width: 20%;
}

.admin-view-leave-listing-tab-whole-div {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    white-space: nowrap;
    flex-wrap: nowrap;
    /* overflow: scroll; */
}

.react-datepicker__close-icon::after{
    background-color: #00000047 !important;
}



@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .admin-view-leave-root-tab{
        flex-wrap: wrap;
    }
    .admin-view-leave-filter-root{
        display: unset;
    }
    .admin-view-leave-filter-content {
        display: unset;
    }
    .admin-view-leave-filter-user{
        margin-bottom: 1rem;
    }
    .admin-view-leave-date-picker{
        margin-bottom: 1rem;
    }
    .admin-view-leave-listing-tab-whole-div{
        overflow-x: auto;
    }
    .admin-view-leave-listing-tab-Content{
        overflow: auto;
    }
}
.admin-view-leave-content-data-details-avatar {
    display: flex;
    align-items: center;
}
.employee-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
}
.admin-view-leave-team-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.admin-view-leave-content-data-details-avatar-circle {
    height: 25px;
    width: 25px;
    background-color: #40baab;
    color: #f4f5f9;
    font-weight: 700;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.75rem;
    border-radius: 50%;
    text-transform: uppercase;
}
.admin-view-leave-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.admin-view-leave-content-data-details-name-data-team-name-whole {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: -3px;
}
.admin-view-leave-content-data-details-name-data-team-name-whole a{
    cursor: pointer;
}
.admin-view-leave-content-data-details-name-data-team-name-whole a:hover{
    text-decoration: underline;
}
.admin-view-leave-card-table-list-status-td-text-pending{
    color: orange !important;
}
.admin-view-leave-card-table-list-status-td-text-approved{
    color: green !important;
}
.admin-view-leave-card-table-list-status-td-text-rejected{
    color: red !important;
}
.admin-view-leave-card-table-all-by-text{
    display: block;
    width: 108px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.admin-view-leave-table-body tr td{
    /* font-weight: 600; */
    color: #34495e;
    font-size: .9rem;
    background-color: #fff;
    padding: 0.7rem!important;
}
.admin-view-listing-tab-tab-Content-list-dates-text {
    display: block;
   width: 70px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.admin-view-leave-card-table-all-by-text-all{
    display: block;
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.view-leave-list-status-approved-modal-body {
    margin: 10px;
}
.form-control-text-area-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 16px;
}
.view-leave-list-status-approved-modal-body p{
    margin-bottom: 0px;
}
.view-leave-list-status-approved-modal-body h6{
    margin-bottom: 11px !important;
}
.leave-listing-tab-tab-Content-notext-free-text {
    margin-top: 19px;
    color: gray;
    text-align: center;
}

.admin-view-listing-tab-tab-Content-table-data-icon-whole-div {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 10px 10px 0px;
}
.admin-view-listing-tab-tab-Content-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
}
.admin-view-listing-tab-tab-Content-table-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.admin-view-listing-tab-tab-Content-table-data-edit-text {
    margin-bottom: 0;
}




.billing-root{
    padding: 2rem;
}
.billing-root-header{
    /* display:flex;
    border: none; */
    /* margin-bottom: 40px; */
}
.billing-root-header-flex{
    display: flex;
    justify-content: space-between;
}
.billing-root-header-expire-date{
    color: #ff4000;
    font-size: 14px;
    line-height: normal;
}
.billing-root-header-add-payment-button{
    /* display: flex;    
    flex-flow: column wrap;
    align-content: space-between; */
}
.billing-root-header-add-payment-button{
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-between; 
}
.billing-root-header-add-new-card-button{
    /* margin-left: auto;
    margin-right: 0; */
    margin-left: auto;
    order: 2;
}  

.billing-root-content{
    display: grid;
    /* grid-template-columns: repeat(auto-fill,minmax(210px,1fr)); */
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 20px;
}
.billing-root-content-card{
    padding: 20px;
    background-color: #f8f9fa;
    border: .5px solid rgba(0,0,0,.08);
    margin-bottom: 20px;
}
.billing-root-content-card-dark-header{
    color: #404751;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 56px;
}
.billing-root-content-card-dark-header-add-margin{
    margin-bottom: 56px;
}
.billing-root-content-card-header{
    color: #252d33;
    font-size: 13px;
    line-height: 16px;
    margin-top: 0;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
}
.billing-root-content-card-muted-header{
    color: #6e747a;
    font-size: 14px;
    line-height: normal;
    margin: 0 0 10px;
}
.billing-root-content-card-price {
    font: 600 32px/normal Geogrotesque, sans-serif;
    color: #404751;
}
.billing-root-content-button {
    border: 2px solid #007bff;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 16px;
    text-transform: uppercase;
}
.change-plan-modal-modal-close{
    position: absolute;
    right: -88px;
    padding: 0 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    color: white;
    margin: 0.5rem;
    opacity: 0.6;
    top: -65px;
}
.change-plan-modal-body {
    /* text-align: center; */
    /* margin: 1.5rem; */
    margin: 1.5rem 0;
}
.change-plan-modal-body-content-current-plan{
    font-size: 15px;
}

.change-plan-modal-body-content-card-groups{
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 40px;
}
.change-plan-modal-body-content-card {
    border-radius: 3px;
    cursor: pointer;
    padding: 37px;
    /* width: 400px; */
    width: 370px;
    display: flex;
    flex-direction: column;
}
.change-plan-modal-body-content-card-selected{
    background-color: #2B405A;
    border: solid 1px #5792e154;
    /* box-shadow: 0 0 12px 0 rgb(0 0 0 / 25%); */
    color: white;
}
.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-header h3 {
    color: white;
}
.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-content .change-plan-modal-body-content-card-content-list .plan-check-icon {
    color: white;
}
.change-plan-modal-body-content-card:hover{
    /* background-color: #5792e105; */
    border: solid 1px #5792e154;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.change-plan-modal-body-content-card-header h3{
    color: #404751;
    margin: 0 0 8px;
    margin-bottom: 18px;
    font-size: 13px;
    font-weight: 700;
}
.change-plan-modal-body-content-card-price-value{
    font-size: 50px;
}
.change-plan-modal-body-content-card-header h2{
    margin-top: 0;
    /* color: #fff; */
    font-size: 17px;
    text-align: left;
}
.free-text{
    color: #266dfb;
    font-size: 1.8rem;
}
.length-text{
    color: #848199;
    font-size: 16px;
}
.change-plan-modal-body-content-card-content{
    /* margin-top: 25px; */
}
.change-plan-modal-body-content-card-content-list {
    display: flex;
    font-size: 0.9rem;
    text-align: left;
    font-weight: 500;
    margin-bottom: 20px;
}
.change-plan-modal-body-content-card-content-list p{
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 17px;
    line-height: 30px;
}
.plan-check-icon{
    margin-top: 4px;
    margin-right: 10px;
    font-size: 19px;
}
.plan-check-icon-cross{
    color: red;
    margin-top: 4px;
    margin-right: 10px;
}
.change-plan-modal-body-footer{
    margin-top: 3%;
}
.change-plan-modal-body-footer-close{
    margin-right: 3%;
    margin-left: 2%;
}
.change-plan-modal-body-footer-submit{
    /* margin-right: 1%; */
}
.add-card-payment-button{
    font-size: 14px;
    font-weight: 600;
}
.change-plan-modal-body-content-card-header {
    /* margin-bottom: 40px; */
}
.change-plan-modal-body-content-card-button {
    margin: 24px 0px;
    margin-top: auto;
}
.billing-root-content-subscribe-button{
    /* border: 2px solid #007bff; */
    font-size: 18px;
    font-weight: bold;
    padding: 8px 23px;
    /* text-transform: uppercase; */
}
.billing-root-content-subscribe-button.btn-danger{
   background-color: #EE5738;
   
}
.billing-add-card-payment-modal-title.modal-title.h4 {
    font-size: 1rem;
    color: black;
}
.billing-add-card-payment-button-icon{
    margin-top: -2px;
    font-size: 11px;
    margin-right: 5px;
}
.billing-add-card-payment-modal-card-details {
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 26px;
    background-color: #3668f3d6;
    color: white;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
}

.billing-root-header-back-nav-icon {
    color: blue;
    font-size: 19px;
    margin-left: 35px;
    cursor: pointer;
}

.change-plan-modal-body-content-card-content-list-strike-out{
    text-decoration:line-through;
    -webkit-text-decoration-color: #fcfcfc8f;
            text-decoration-color: #fcfcfc8f;
}

.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-content .change-plan-modal-body-content-card-content-list-strike-out{
    text-decoration:line-through;
    -webkit-text-decoration-color: #fcfcfc8f;
            text-decoration-color: #fcfcfc8f;
}
.change-plan-modal-body-content-card-content-list-strike-out p{
    margin-left: 22px;
    font-weight: 300;
}
.change-plan-modal-body-content-card-price-details {
    margin-bottom: 30px;
    line-height: 21px;
    margin-top: 19px;
    font-weight: 300;
    font-size: 13px;
}
.change-plan-modal-body-content-card-price-symbol{
    position: relative;
    top: -16px;
    right: 3px;
    font-size: 26px;
}

.change-plan-modal-body-content-card-price-details-current-plan {
    color: #e67511;
    font-size: 20px;
    font-weight: 700;
}
.change-plan-modal-body-content-card-price-details-current-plan-period{
    font-size: 12px;
    margin-top: 5px;
    color: #F1C40F;
    font-weight: 700;
}
.billing-root-header-add-payment-status {
    background-color: #e615153d;
    padding: 15px;
    font-size: 17px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
}
.billing-root-header-add-payment-status div{
    margin-top: 9px;
}
.billing-root-header-add-payment-success{
    background-color: #0080004d;
}
.billing-root-header-add-payment-in_progress{
    background-color: #8047004d;
}
.billing-root-header-add-payment-failure,.billing-root-header-add-payment-timeout{
    background-color: #ef51414d;
    color: #ec7255;
}
@media screen and (max-width: 480px) {
    .change-plan-modal-body-content-card-groups{
        display: inline;
    }
    .change-plan-modal-body{
        margin: 0;
    }
    .change-plan-modal-body-content-card{
        width: auto;
    }
}



.content {
    display: flex;
    justify-content: center;
    padding: 0.7rem 2rem;
    /* width: 400px; */
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #217f94;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(14, 14, 172);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 0 4px #96d8e7;
    outline: none;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:checked + .slider {
    /* box-shadow: 0 0 0 4px rgb(154, 154, 236); */
    outline: none;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  span.pricing-inr,.pricing-usd {
    padding: 5px 13px;
    font-weight: 700;
}
.billing-currency-filter-label {
    text-align: center;
    font-weight: 500;
    color: #656464;
    margin-top: 10px;
}
.pricing-currency-inr-selected{
    color: #217f94;
}

.pricing-currency-usd-selected{
    color:rgb(14, 14, 172);
}


.billing-wrapper,
.billing-wrapper .tab-pane,
.billing-wrapper .tab-content {
  height: 100%;
}
@media screen and (max-width: 480px) {
  .billing-wrapper-root{
  padding: 2rem;
  }
}
.billing-wrapper .nav-tabs > a {
  color: #343a40;
}

.root-layout-page .nav-link {
  background-color: rgb(114, 110, 110);
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.billing-wrapper .nav-tabs > a.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}
.billing-plan-downgrade-confirmation-modal {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #2c3e50;
}
.billing-plan-downgrade-confirmation-modal-text{
    font-size: 15px;
    color:#222223;
    margin-top: 30px;
    font-weight: 500;
}
.billing-plan-downgrade-confirmation-modal-cancel-button, .billing-plan-downgrade-confirmation-modal-delete-button {
    font-size: 0.9rem;
}
.billing-add-card-payment-modal:before{
    opacity:0.8 !important;
 }
 .change-plan-modal-body-content-card-footer {
    margin-top: 40px;
    color: gray;
    text-align: center;
    font-size: 15px;
}
.billing-root-billing-notification {
    margin: 32px;
}
.billing-root-billing-notification-button {
    font-size: 15px;
    font-weight: 500;
}
.billing-root-billing-notification-content{
    color: #4a5568;
    padding: 16px;
    background:white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}
.billing-root-billing-notification-button-div{
    margin-top:10px;
}
.change-plan-modal-body-content-card-footer-notes {
    margin-top: 16px;
    /* color: gray; */
    font-weight: bold;
    /* text-align: center; */
    padding: 15px;
    border: 1px solid #0000001c;
    margin: 2rem;
}
.change-plan-modal-body-content-card-footer-notes-text{
    padding: 0 0 0 20px;
    margin: 25px;
    text-align: center;
}
.change-plan-modal-body-content-card-footer-notes-text-li-tag{
    margin-bottom: 7px;
}
.billing-filter-root {
    display: flex;
    justify-content: space-around;
}
.pricing-currency-monthly-selected {
    color: #16680d;
}
.pricing-currency-anually-selected {
    color: rgb(172 123 14);
}
.plan-type-slider-round{
    background-color: #16680d;
}
input:checked + .plan-type-slider-round {
    background-color: rgb(172 123 14);
}
input:focus + .plan-type-slider-round {
    box-shadow: 0 0 0 4px #a8bcc1;
    outline: none;
}


.pill-button {
    display: inline-block;
    position: relative;
    padding: 3px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    background: #157AF6;
    border-radius: 28px;
    color: #fff;
    z-index: 0;
    margin-left: 114px;
}

.pill-button-input {
    display: none;
    visibility: hidden;
}

.pill-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 38px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}

.pill-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.4em 0.9em;
    cursor: pointer;
    z-index: 2;
}

.pill-button-selection_active {
    color: #157AF6;
}
 
.pill-currency-button {
    display: inline-block;
    position: relative;
    padding: 3px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    background: #8b8d8f7d;
    border-radius: 28px;
    color: white;
    z-index: 0;
}

.pill-currency-button-input {
    display: none;
    visibility: hidden;
}

.pill-currency-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 38px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}

.pill-currency-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.4em 0.9em;
    cursor: pointer;
    z-index: 2;
}

.pill-currency-button-selection_active {
    color: #090a0ac7;
    font-weight: 600;
}
.billing-plan-type {
    width: 79%;
    text-align: center;
}
.billing-currency-type{
    width: 22%;
}
.plan-head-stopped-warning-div {
    width: 700px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 18px;
}
.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-name-text{
    font-weight: 600;
    margin-bottom: 11px;
}
.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-main-text,.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-secondary-text{
    font-weight: 500;
    margin-bottom: 11px;
}
h2.change-plan-modal-body-content-card-price-yearly-summary {
    display: flex;
    font-size: 14px;
}
.change-plan-modal-body-content-card-price-yearly-summary .length-text{
    font-size: 14px;
}
.change-plan-modal-body-content-card-price-root {
    margin-bottom: -2px !important;
}
.billing-help-text {
    text-align: center;
    margin: 0.8rem 1rem 2rem 1rem;
    color: green;
    font-weight: 600;
    font-size: 18px;
}
.billing-plan-downgrade-confirmation-modal-project-selection-error{
    color: red !important;
    font-size:13px !important;
}
.billing-plan-downgrade-confirmation-modal-project-select{
    margin:20px 0 0px 0;
}
.billing-plan-downgrade-confirmation-modal-project-select-label{
    margin-bottom:10px
}
.billing-plan-downgrade-confirmation-modal-project-warning-text {
    margin-top: 25px;
    font-size: 15px;
    line-height: 21px;
    color: #2c3e50;
}
.billing-plan-downgrade-confirmation-modal-project-select div{
    /* font-size: 15px; */
    line-height: 21px;
    /* color: #2c3e50; */
}
.form-control-text-area-form-div-field-textarea-input::-moz-placeholder{
    color: #aaa;
    /* var(--rmsc-gray); */
}
.form-control-text-area-form-div-field-textarea-input::placeholder{
    color: #aaa;
    /* var(--rmsc-gray); */
}
.form-control-text-area-form-div-field-textarea-input{
    font-size: 15.5px;
}
.billing-root-header-failed-extended-status {
    background-color: #e6151512;
    padding: 12px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
    color: red;
    border: 1px solid red;
    display: flex;
    justify-content: space-between;
}
.billing-root-header-failed-extended-status-warning-icon{
    margin: -3px 6px 0px 0px;
}
.billing-root-content-paynow-button {
    font-size: 13px;
    font-weight: bold;
    padding: 8px 17px;
}
.billing-root-header-failed-extended-status-div{
    padding: 6px 5px;
}
.billing-root-header-in-progress-payment-status {
    background-color: #ffa50063;
    padding: 15px;
    font-size: 17px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
}
.change-plan-modal-body-content-card-footer-faq {
    margin: 2rem;
    border: 1px solid #0000001c;
}
.change-plan-modal-body-content-card-footer-faq-text {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: 2rem 0rem 2rem 0rem;
}
.change-plan-modal-body-content-card-footer-faq-question-toggle {
    font-size: 16px !important;
    color: black !important;
    margin-top: -2px;
    padding: 0;
    font-weight: 500  !important;
}
.change-plan-modal-body-content-card-footer-faq-question.card-header {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.25rem !important;
}
.change-plan-modal-body-content-card-footer-faq-card {
    border-top: 0px !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    border-right:0px !important;
    border-left: 0px !important;
    background: none !important;
}
.change-plan-modal-body-content-card-footer-faq-card .card-header{
    background: none !important;
    border: none !important;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body {
    margin-top: -19px;
    color: gray;
    font-size: 14px;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body-div2{
    margin-top: 15px;
}
.change-plan-modal-body-content-card-footer-faq-question-icon{
cursor: pointer;
}
.change-plan-modal-body-content-card-footer-faq-question-toggle {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}
.change-plan-modal-body-content-card-footer-faq-wrapper {
    margin: 2rem;
}
.change-plan-modal-body-content-card-footer-notes-text-li-tag{
    font-weight: 500;
    margin: 14px;
    font-size: 15px;
}
.billing-root-header-storage-completed-link {
    color: #0000ffa8 !important;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body-link{
    color: blue;
}
.billing-count-tooltip-start{
    /* min-width:500px; */
    height:400px !important;
    overflow-y: scroll;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68  !important;
    /* border: 1px solid #535C68; */
    color:white;
    
    position: sticky;
    z-index: 1 !important;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
  }
  .billing-payment-terms-popover{
    max-width: 500px;
    background-color: #535C68  !important;
  }
.billing-payment-terms-popover-pay-now-text{
    /* text-decoration: underline; */
    cursor: pointer;
    font-style: italic !important;
}
.change-plan-modal-body-content-card-billing-text {
    margin-bottom: 8px;
}
.billing-payment-terms-popover .arrow::before {
    border-top-color: #4f5864 !important; /* Change this to your desired color */
  }
  
  .billing-payment-terms-popover .arrow::after {
    border-top-color: #4f5864 !important; /* Match the color with your popover */
  }
  .billing-payment-refund-terms-a-tag{
    color: gray;
    font-style: italic !important;
  }
.create-project-root {
  display: flex;
  justify-content: flex-end;
}
.create-project-root-button {
  background: #695eee;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
}

/* create-project-root-modal start*/

.create-project-root-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
}
.create-project-root-modal-content-name-field {
  flex: 1 1;
  margin-right: 16px;
}
.create-project-root-modal-content-bill-field {
  width: 124px;
  position: relative;
}
.create-project-root-modal-content-bill-field-icon {
  position: absolute;
  top: 30px;
  bottom: 0;
  padding: 0.3rem;
  color: #495057;
  font-size: 0.7rem;
}
.create-project-root-modal-label-name {
  font-size: 0.7rem;
  font-weight: 600;
  color: #718093;
}

.create-project-root-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -16px;
}
/* create-project-root-modal-content-person-name-header start */
.create-project-root-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.create-project-root-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #695eee;
  color: #695eee;
  font-size: 0.8rem;
  /* transition: background .4s; */
}
.create-project-root-modal-content-person-name-header-section {
  flex: 1 1;
}
.create-project-root-modal-content-person-name-header-section-label {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 18px;
  margin-bottom: 6px;
  color: #62839b;
}
.create-project-root-modal-content-person-name-header-section-content {
  display: flex;
}
.create-project-root-modal-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  margin-right: 16px;
}

.create-project-root-modal-searchbox {
  float: right;
  font-size: 0.8rem;
  background-color: #f5f3f6;
}
.create-project-root-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
/* create-project-root-modal-content-person-name-header end */

/* create-project-root-modal-content-person-name-listing start */
.create-project-root-modal-content-person-name-listing {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: -16px; */
}
.create-project-root-modal-content-person-name-listing-card {
  margin-bottom: 20px;
}
.create-project-root-modal-content-person-name-listing-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  margin-bottom: 10px;
}
.create-project-root-modal-content-header-team-name {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 14px;
  color: #62839b;
}
.create-project-root-modal-content-header-team-name-select {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 14px;
  align-items: center;
  color: #695eee;
  cursor: pointer;
}
.create-project-root-modal-content-person-name-listing-card-content {
  margin-bottom: 10px;
  display: flex;
}
.create-project-root-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
}
.create-project-root-modal-content-person-name-listing-card-content-person-icon {
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 0.6rem;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #28a745;
  text-transform: uppercase;
}
.create-project-root-modal-content-person-name-listing-card-content-person-icon-name {
  flex: 1 1;
  font-size: 0.8rem;
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* create-project-root-modal-content-person-name-listing end */

/* create-project-root-modal end */

.my-projects-root {
  padding: 2rem;
}
.project-card-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 0.5rem;
}
.project-card-root a {
  color: #212529;
}
.project-card-root a:hover {
  text-decoration: none;
}
.my-project-center-no-data p{
  display: flex;
  justify-content: center;
  margin-top: 17%;
}
.my-project-center-no-data-div{
  display: flex;
  justify-content: center;
}

/* project-summary div css start */
.project-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.project-summary-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.project-summary-card-text {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-summary-card-count {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
/* project-summary div css end */

/* project-card div css start*/
.project-card {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid lightgray;
  transition: all 0.2s ease-out;
}
.project-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
}
.project-card-white {
  /* background-color: #fdffe0; */
  background-color: "white";
}
.project-card-yellow {
  /* background-color: #fdffe0; */
  background-color: #fdffe0;
}
.project-card-green {
  background-color: #c3e7b7;
}
.project-card-gray {
  background-color: #b0c2c2;
}

/* project-card-header-split div css start*/

.project-card-header-split {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
  justify-content: space-between;
}
.project-card-header {
  display: flex;
  align-items: center;
}
.project-card-avatar {
  text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    height: 40px;
    width: 40px;
    width: 40px;
    border-radius: 37px;
    background: #e3e3e3;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333333d6;
    margin-top: 0px;
}
.project-card-projectname {
  color: #333;
  white-space: nowrap;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;
}
.project-management-miled-color {
  color: #808080c4;
}
.project-card-projectname {
  font-size: 17px;
    font-weight: 700;
    line-height: 25px;
}
.project-card-companyname {
  font-size: 12px;
  margin-top: 3px;
}

.project-card-percentage {
  margin-left: auto;
}
.project-card-percent {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.project-card-percent-text {
  font-size: 10px;
    line-height: 15px;
    color: #009b37;
}

.project-card-percent-text-black {
   color:#000000bf;
}
.project-card-chart {
  position: relative;
  /* margin: 0px 0px 0px 15px; */
}
.project-card-chart-percent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}
.project-card-chart-data {
  font-size: 12px;
  text-align: center;
}

.project-card-content-card {
  background-color: bisque;
  color: #212529;
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}
.project-high-percent{
  color: #009b37;
}
.project-card-content-card p{
  margin-bottom: 0;
  font-size: 15px;
  text-align:center;
}
.project-card-chart-data-edit-menu {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.project-card-chart-data-edit-menu .dropdown-menu {
  font-size: 0.7rem;
}
.project-card-chart-data-edit-menu #dropdown-basic {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  position: initial !important;
}
.project-card-chart-data-edit-menu .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  background-color: transparent;
}
.project-card-delete-task {
  color: red !important;
}

.editmodal {
  position: relative;
}
#dropdown-basic {
  position: absolute;
  top: 31px;
  right: 10px;
}

.project-member-tasks-modal-content-person-name-header {
  margin-bottom: 5px;
}
.project-member-project-task-modals-user-label-section-div {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #edeef2;
  justify-content: space-between;
}
.project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  /* box-shadow: inset 0 -1px 0 0 #edeef2; */
  text-transform: uppercase;
  width: 265px;
}
.create-project-team-selection-error-message-member-modal {
    color: red;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 5px;
}
.project-member-tasks-modal-content-person-name-header-button {
  font-size: 13px;
  margin-bottom: 0;
  color: grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  text-decoration: underline;
}
.project-member-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 280px;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 1.5rem;
}
.project-member-project-task-modals-content-person-name-listing-card-content {
  display: flex;
  margin-top: 10px;
}
.project-member-tasks-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  cursor: pointer;
}
.project-member-task-card-avatar-profile {
    width: 27px;
    height: 27px;
    position: relative;
    overflow: hidden;
    margin-right: 11px;
    border-radius: 50%;
}
.project-member-task-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-member-my-projects-modal-content-header-team-name {
    font-size: .91rem!important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 355px;
    text-align: left;
}

.project-member-my-projects-modal-content-team-name {
  margin-right: 10px;
}
.project-member-project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 265px;
}

/* project-card-header-split div css end*/

/* project-card-content div css start*/
.project-card-content {
  display: flex;
  padding: 20px 0 10px 0;
}

.project-card-split-content-align{
  margin-left: auto;
    margin-right: 0;
}
.project-card-split-content-limits {
  margin: 0;
}
img.project-card-split-content-limits-member-img {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 1.7rem;
}
.project-card-split-content-limits-member-img:nth-child(2),
.project-card-split-content-limits-member-img:nth-child(3) {
  margin-left: -1rem;
}
.project-card-split-content-limits-member {
  display: flex;
  justify-content: right;
}
.project-card-split-content-limits-member-other {
  background-color: #f8f9f9;
  height: 1.7rem;
  width: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  font-size: 0.7rem;
  margin-left: -1rem;
}
.project-card-split-content-limits-member-number {
  font-weight: 600;
  color: #000000b0;
}
/* project-card-content div css end*/

/* project-card div css start*/

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}

.add-project-modal-button-rows {
  margin-bottom: 10px;
}
.projects-create-col {
  text-align: center !important;
}
.project-card-chart-data-total-time {
  font-size: 0.67rem;
}
.project-card-chart-data-total-time-data {
  margin-right: 3px;
  font-weight: 600;
}
span.project-card-chart-data-total-time-title {
  font-size: 0.58rem;
}
.project-card-total-time {
  display: flex;
  margin-right: 32px;
}
.no-data {
  text-align: center;
}
.error{
  color: red;
  font-size: 15px;
  float: right;
}
.error + .form-control {
  border-color: #f0624d;
}
.my-projects-root-header{
  display: flex;
  margin: 0px 0px 20px 0;
}
.add-projects-search-input{
  width:45%;
}
.add-projects-button{
  margin-left: auto;
}
.my-projects-modal-content-person-name-listing-card-content-person{
  cursor: pointer;
}
.my-projects-modal-content-team-name {
  margin-right: 10px;
}
.create-project-team-selection-control-checkbox{
  width: 0.85rem;
height: 0.85rem;
vertical-align: middle;
cursor: pointer;
}



/***** MODAL CSS *****/
.my-projects-modal-root .modal-dialog .modal-content{
  padding: 0 16px;
  border-radius: 12px;
}
.my-projects-modal-header{
  /* border-bottom: none !important; */
}
.my-projects-modal-title.modal-title.h4 {
  /* font-size: 1.2rem; */
  /* font-size: 1.2rem; */
  color: #333333;
  /* font-family: "Helvetica Now Display"; */
  font-size: 20px;
  font-weight: 600;
  /* letter-spacing: .35px; */
  text-align: center;
  /* line-height: 32px; */
  margin: 0;
  /* display: flex; */
  align-items: center;
  text-align: center;
  width: 90%;
  flex-shrink: 0;
}
.my-projects-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.my-projects-modal-content-name-field {
  flex: 1 1;
  /* margin-right: 16px; */
  margin-bottom: 0rem;
  margin-right: 16px;
  margin-bottom: 16px;
}
.my-projects-modal-label-name {
  /* font-size: 1rem;
  color: #718093; */
  color: #535c68fc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  /* display: block; */
}
.my-projects-edit-project-label-input::-moz-placeholder{
  color: rgb(212, 209, 209);
}
.my-projects-edit-project-label-input::placeholder{
  color: rgb(212, 209, 209);
}
.my-projects-edit-project-label-select{
  color: rgb(212, 209, 209);
}
.my-project-add-new-project-hours-text{
  display: block;
    width: 30%;
    height: calc(1.3em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.my-project-add-new-project-option-div{
  display: flex;
}
.my-project-add-new-project-option{
  font-size: 15px;
  float:right;
  color: #718093;
}
.my-projects-edit-project-label-input {
  font-size: 0.9rem !important;
}
.my-projects-modal-content-person-name-headers {
  display: flex;
  padding-right: 16px;
}
.my-projects-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  /* padding-right: 16px; */
  /* margin-right: -16px; */
  margin-bottom: 26px;
}
.my-projects-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.my-projects-modal-content-person-name-header-section {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
}
.my-projects-modal-content-person-name-header-section-label {
  /* font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.5rem;
  color: #62839b; */
  color: #535c68fc;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.my-projects-modal-content-person-name-header-section-content {
  display: flex;
}
.my-projects-modal-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  margin-right: 16px;
}
.my-projects-modal-searchbox {
  float: right;
  font-size: 0.9rem;
  background-color: #f5f3f6;
}
.my-projects-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.my-projects-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #695eee;
  color: #695eee;
  font-size: 0.98rem;
 }
.my-projects-modal-content-person-name-listing {
  overflow: auto;
  height: 250px;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: 16px; */
  border: 1px solid lightgrey;
  padding: 10px 10px 10px 10px;
}
.projects-modal-cols {
  padding: 0 20px !important;
}
.project-card-content-card-info-icon{
  margin-right: 7px;
}
.my-projects-modal-content-person-name-listing-card-content {
  margin-bottom: 2px;
  display: flex;
}
.my-projects-modal-content-name-field .css-1wa3eu0-placeholder{
  font-size: 0.9rem !important;
}
.my-projects-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
  cursor: pointer;
}
.my-projects-modal-content-person-name-listing-card-content-person-icon {
    height: 27px;
    width: 27px;
    line-height: 27px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
}
.my-project-user-selection-user-list{
  margin-left: 2opx;
  display: flex;
}
.my-projects-modal-content-person-name-listing-card-icon{
  height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.6rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #808080c2;
    text-transform: uppercase;
}
.my-projects-selection-task-list-name{
  margin-left: 0px;
  font-size: 0.87rem;
  color: gray;
  width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top:2px;
}
.my-projects-modal-content-person-name-whole-div{
  
  display: flex;
    align-items: center;
    flex: 1 1;
    cursor: pointer;
    margin-left: 40px;
    /* margin-bottom: 5px; */
}
.my-projects-modal-content-header-team-name {
  font-size: 0.97rem !important;
  line-height: 18px;
  color: #0d66a7;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 355px;
    text-align: left;
}
.my-projects-modal-content-header-team-name:hover {
  color: #62839b;
  /* text-decoration: underline !important; */
}
.my-projects-modal-content-header-team-name-acc{
  display: flex;
  margin-top: 4px !important;
}
.my-projects-modal-content-team-name-user {
  margin-right: 10px;
}
.my-project-user-selection-user-list-collapse{
  margin-bottom: 11px;
  /* background-color: aliceblue; */
    padding: 7px 0px;
    border-radius: 5px
}
.my-project-rule-uparrow {
  font-size: 14px;
  color: #0d66a7;
}
.my-projects-modal-content-team-name {
  margin-right: 10px;
}
.create-project-team-selection-control-checkbox {
  width: 0.9rem;
  height: 0.9rem;
  vertical-align: middle;
  cursor: pointer;
}
.my-projects-modal-button {
  margin: auto;
}
.my-projects-modal-save-button {
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
}
.my-projects-modal-searchbox{
  font-size: 12px;
}
.my-projects-root-searchbox-wrapper {
  position: relative;
  margin-right: 0;
  width: 67%;
}
.my-projects-root-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0.5rem;
  color: #000000bd;
  font-size: 0.9rem;
}
.create-project-team-selection-error-message{
  color: red;
  font-size: 0.9rem;
  text-align: center;
}
.disable-button{
  cursor: not-allowed !important;
 }
.project-card-project-name-div{
  width: 100%;
  cursor: pointer;
}
.project-card-created-content {
  display: flex;
  padding: 10px 0;
 }
.project-card-split-content-created-by {
  width: 132px;
}
.project-card-exceed-estimated-time-value{
  color:red;
}
.project-card-worked-time-text {
  text-align: center;
}
.project-card-worked-time-number {
  font-size: 18px;
}
.project-card-exceed-estimated-time-number {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.project-card-exceed-estimated-time-text {
  font-size: 10px;
  text-align: center;
}
.project-card.green{
  background-color: #c3e7b7 !important;
}

.project-card-footer-chart {
  width: 96%;
  margin: 30px 9px 5px 9px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: gray;
  font-size: 14px;
}
.project-card-footer-chart .progress{
  height: 0.5rem !important;
}
.project-card-footer-chart-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 500;
  color: #0000008c;
}
.project-card-footer-add-estimation-time {
  text-align: center;
  margin: 16px 0px 6px 0;
}
.project-card-footer-estimated-time-button {
  font-size: 14px;
  background-color: #3e3d3d0f;
  border-radius: 10px;
  font-weight: 500;
  color: #0000007a;
}
.project-card-footer-estimated-time-button.start{
 background-color: #ffffffb5 !important;
}
.project-card-footer-estimated-time-button-icon {
  margin-top: -3px;
}
.project-card-div-wrapper{
  background-color: white !important;
}
.project-card.start {
  background-color: #c3e7b7  !important;
}
.bg-progress-not-exceed{
  background-color: #40bb4d !important;
}
.bg-progress-exceed{
  background-color: #f1596852;
}

.project-card-total-worked-time {
  color: #00000094;
  font-weight: 700;
  margin-left: 13px;
}
.project-card-total-worked-time-icon {
  margin-right: 2px;
  font-size: 18px;
}
.project-card-footer-add-estimation-time-input{
  margin:0 auto;
  width: 66%;
  display: flex;
}
.project-card-footer-add-estimation-time-input-type{
  font-size: 13px;
}
.my-projects-modal-content-person-name-header-button-select-all{
  color: #195ab1d1;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
}
.project-card-footer-add-estimation-time-input-label{
  padding: 8px;
  font-size: 15px;
}
.my-projects-modal-content-row.row {
  margin-top: 8px;
}

.my-project-status-filter {
  margin-right: 8px;
  font-weight: 500;
  border: 1px solid #ededed;
  font-size: 14px;
  margin-bottom: 9px;
  color: #427ff6;
  background-color: #eef3fc;
}
.my-projects-status-filter-selected {
  background-color: #fff;
  color: #212529;
}
.project-card-footer-add-estimation-time-button {
  width: 20%;
  padding: 2px;
  /* height: 29px; */
  margin-left: 4px;
  height: calc(1.5em + 0.6rem + 0px);
}
.project-card-footer-add-estimation-time-button-icon {
  margin-top: -4px;
}
.project-card-footer-add-estimation-time-button-loader{
  font-size: 1rem;
  color: white;
  animation: spin 0.5s linear infinite;
}

.my-project-card-badges {
  color: #212529;
  text-align: center;
  margin-top: 10px;
}
.my-project-card-badges-no-data-badge {
  vertical-align: middle;
  margin-top: 7px;
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.my-project-card-badges-working {
  background: #d8f6f6;
  border: 1px solid rgba(0,0,0,.1);
    padding: 5px;
    margin-left: 5%;
    margin-bottom: 7px;
    color: #212529;
    text-align: center;
}
.my-project-curently-work{
  width: 225px;
    color: #212529;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-project-herder-whole-div{
  width: 270px;
}
.my-projects-modal-label-name-required{
  color:red;
}
.project-card-div-wrapper-today{
  background-color: #e5df5933 !important;
}

.my-project-summary {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(225px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.my-project-dashboard-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.my-project-dashboard-card-title {
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1E1E1E !important;
}
.myproject-icon-div{
  display: flex;
}
.myproject-icon-h4{
  /* width:211px; */
}
.myproject-icon{
  margin-top: 3px;
  font-size: 1.6rem;
  margin-left: auto;
    margin-right: 0;
}
.myproject-add-projects-enabled-to{
  font-weight: 700!important;
  color: #343a40!important;
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;
}
.my-project-add-member-card-root{
    padding: 13px;
    background: #fff;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 3rem;
    background-color: #e615153d;
    margin-top: -14px;
}

.my-project-add-member-card-redirection-link{
  font-weight: 600!important;
  color: #343a40!important;
  font-size: 0.95rem;
  cursor: pointer;
}
.my-project-add-member-card-redirection-link:hover{
 text-decoration: underline;
  
}

.my-project-task-modals-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  /* margin-right: 16px; */
  /* margin-bottom: 12px; */
}
.my-project-member-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 280px;
  flex-grow: 1;
  padding: 0px 10px 10px 10px;
  margin-bottom: 1.5rem;
}
.my-project-task-modals-searchbox-wrapper div .form-control{
  border: 1px solid #ced4dac2 !important;
  font-size: 15px;
}
.my-project-member-project-task-modals-user-label-section-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-project-member-project-task-modals-user-label-section {
  /* height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px; */
  display: flex;
  align-items: center;
  width: 275px;
}
.my-project-task-modals-searchbox-wrapper div .form-control::-moz-placeholder { /* Most modern browsers support this now. */
  color: rgb(199, 194, 194);
}
.my-project-task-modals-searchbox-wrapper div .form-control::placeholder { /* Most modern browsers support this now. */
  color: rgb(199, 194, 194);
}
.my-project-task-modals-searchbox-wrapper div div{
  color: rgb(199, 194, 194) !important;
}
.add-project-member-modal-loading{
  width: 100%;
  text-align: center;
}
.add-project-modal-button-rows-error{
  color: red;
    font-size: 14px;
}
.project-card-footer-yet-to-start-project {
  text-align: center;
  margin: 30px 9px 5px 9px;
  color: gray;
  font-size: 14px;
}
.project-card-footer-yet-to-start-project{
  cursor: pointer;
}
.user-sidemenu-root {
  display: grid;
  grid-template-columns: 210px minmax(0, 1fr);
  /* height: calc(100vh - 55px); */
  margin: 0;
}
.user-sidemenu-root-mobile-with-menu {
  grid-template-columns: 225px minmax(0, 1fr);
}
.user-sidemenu-root-mobile-without-menu {
  grid-template-columns: 1fr;
}

.user-sidemenu-page {
  height: calc(100vh - 55px);
  overflow: auto;
}

.user-sidemenu {
  border-right: 2px solid #ededed;
  /* height: calc(100vh - 55px); */
  /* padding: 8px 0; */
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* background-color: #fff; */
  background-color: #fcfcfc;
}

/* second-sidebar css div start */
.second-sidebar-searchbox-wrapper {
  height: 32px;
  margin: 0.5rem 0.5rem 0.8rem;
}
.second-sidebar-searchbox-wrapper-root{
  position: fixed;
  padding: 4px 0;
  width: 208px;
  background-color: #FCFCFC;
  z-index:10;
}

.second-sidebar-searchbox {
  font-size: 0.9rem;
  border: none;
  background-color: #f5f3f6;
}

.second-sidebar-icon-wrapper {
  position: absolute;
  top: 13px;
  bottom: 0;
  right: 0.8rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}

.second-sidebar-content-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #718093;
  padding: 8px 16px 9px 20px;
  display: flex;
}

.second-sidebar-content-listing-profile {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: all 100ms ease;
  cursor: pointer;
  text-decoration: none !important;
  color: #333 !important;
  border-left: 4px solid transparent;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.second-sidebar-content-listing-profile:hover {
  background-color: #266dfb05;
}

.second-sidebar-content-listing-profile.active {
  background-color: #266dfb10;
  border-color: #266dfb;
}

.second-sidebar-content-listing-profile-icon {
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  background-color: #8394ab;
  margin-left: 4px;
  margin-right: 8px;
}

.second-sidebar-content-listing-profile.active .second-sidebar-content-listing-profile-icon {
  background-color: #266dfb;
}

.second-sidebar-content-listing-profile-icon > svg {
  height: 13px;
  vertical-align: initial;
}

.second-sidebar-content-listing-profile-name {
  flex: 1 1;
  font-size: 15.5px;
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.second-sidebar-content-listing-profile-name-active {
  color: #266dfb;
  font-weight: 600;
}

.second-sidebar-header {
  display: flex;
  height: 55px;
  border-bottom: 1px solid #e8ebee;
  align-items: center;
  overflow: hidden;
}
.second-sidebar-header h5 {
  /* padding: .5rem 1rem; */
  padding: 0.5rem 1rem;
  margin: 0px;
}
.second-sidebar-box {
  border-right: 1px solid #e8ebee;
}
.second-sidebar-settings {
  height: calc(100vh - 65px);
  border-left: 1px solid #e8ebee;
  border-right: 1px solid #e8ebee;
  overflow-y: auto;
}
/* second-sidebar css div end */
.member-not-found{
  font-size: 14px;
}
.second-sidebar-content {
  margin-top: 62px;
  height: calc(100vh - 137px);
  overflow-x: hidden;
  overflow-y: auto;
}
.usermenu-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.usermenu-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.user-secondmenu-load-more-button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
p.user-secondmenu-load-more-end-text {
    color: #7a7979;
    font-size: 13px;
    font-weight: 400;
}
/* * {
  color:  #1E1E1E;
} */
.screenshots-card {
  margin: 2rem;
}

.screenshot-load-more-button{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.screenshots-card-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 55px);
}
.screenshots-card-note-text {
  font-size: 14px;
  color: #ff00008c;
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
}


.screenshots-card-sort-dropdown {
  text-align: end;
    margin-bottom: -30px;
    display: flex;
    align-items: c;
    justify-content: flex-end;
}
.screenshots-card-sort-dropdown p{
  margin: 0;
    color: #808080d1;
    margin-right: 5px;
    margin-top: 2px;
}
.screenshots-card-sort-dropdown-icon {
  margin-top: -1px;
  font-size: 0.9rem !important;
  margin-left: 4px;
}
.screenshots-card-sort-dropdown-icon-extra {
  font-size: x-small;
  margin-top: -1px;
}
.screenshots-card-sort-dropdown .dropdown-toggle::after {
  display: none;
}
.screenshots-card-sort-dropdown button{
  padding: 2px 13px;
  border: 1px solid #ededed;
  /* background-color: #fff;
  color: #6c757d; */
  background-color: #266dfb10;
  color: #266dfbc2;
}
.screenshots-card-sort-dropdown button:hover{
  background-color: #266dfb10;
  color: #266dfbc2;
  border-color:#ededed;
}
.screenshots-card-sort-dropdown button.active{
  background-color: #266dfb10;
  color: #266dfbc2;
  border-color:#ededed;
}
.no-screenshot-found {
  text-align: center;
  margin-top: 5rem;
}
.ScreenShots-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));
  grid-gap: 0.8rem;
}
.ScreenShots-root-date-divider-label-div{
  display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.ScreenShots-root-date-divider-label{
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 20px;
}
.ScreenShots-root-date-divider-label-icon {
  margin-right: 10px;
  color: #000000ab;
}
.ScreenShots-root-date-divider-label-icon svg {
  margin-top: -4px;
}
/* screenshot-card div css start */
.screenshot-card {
  /* margin: 1rem auto; */
  margin-bottom: -1%;
  /* max-width: 245px; */
  width: 100%;
  padding: 1rem;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid #d3d3d3;
  /* border: 1px solid #17b5ab; */
  border-radius: 3px;
}
.screenshot-card-non-idle{
  border: 1px solid #dc3545;
}
.screenshot-card-modal-non-idle {
  border: 2px solid #dc3545;
}
.screenshot-card-selected {
  background-color: #266dfb10;
  /* border: 1px solid #007bff; */
}
.screenshots-card-export-div {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  /* width: fit-content; */
  border-radius: 5px;
  padding: 0.7rem;
  justify-content: space-between;
}
.screenshots-card-export-div-select-all{
  /* width: 100%;
    height: calc(1.7em + .75rem + 2px); */
    padding: 0.3rem .55rem;
    font-size: .8rem;
    font-weight: 400;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 20px;
    background-color: #fcfcfc;
    cursor: pointer;
}
.disable-screeshots-export {
  padding: .375rem .75rem;
  background-color: #dee2e638 !important;
  border-radius: .25rem;
}
.disable-screeshots-select-all{
  background-color: #dee2e638;
  border-radius: .25rem;
}
.image-heckbox input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: 1px 3px 5px rgb(38 38 38 / 20%);
}
.image-heckbox .form-check-inline{
  margin-right: -2px;
}
.screenshot-download-icon{
  /* font-size: 12px; */
    font-size: 0.8rem;
    margin-right: 20px;
    border: 1px solid #ced4da;
    color: #495057;
    background-color: #fcfcfc;
}
.screenshot-download-delete-icon{
  font-size: 0.8rem;
    /* margin-right: 20px; */
    border: 1px solid #ced4da;
    color: #495057;
    background-color: #fcfcfc;
}

/* screenshot-card-header div css start */
.screenshot-card-header {
  display: flex;
  align-items: center;
}
.screenshot-card-header-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.screenshot-card-header-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color: #34495e; */
  font-weight: 400;
  flex-grow: 1;
  font-size: 14px;
}
.screenshot-card-header-menu {
  flex-shrink: 0;
  /* padding-right: 12px; */
}
/* screenshot-card-header div css end */

/* screenshot-card-image div css start */
.screenshot-card-image {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  margin: 12px 0px;
}
.screenshot-card-thumbnail-image {
  width: 100%;
  height: 120px;
  background-color: #e8ebee;
}
/* screenshot-card-image div css end */

/* screenshot-card-footer div css start */
.screenshot-card-footer {
}
.screenshot-card-footer-information-bar {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  font-weight: 600;
  font-size: 14px;
  /* line-height: 16px; */
  /* color: #718093; */
}
.screenshot-card-footer-information-bar a{
  text-decoration: none;
  color: #34495e;
}
.screenshot-card-footer-information-bar a:hover{
  text-decoration: underline;
}
.screenshot-card-footer-information-bar-timer {
  margin-right: 12px;
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */

  /* color: #718093; */
  font-size: 12px;
  margin-left: auto;
  margin-right: 0px;
}
.screenshot-card-footer-information-bar-timer-time {
  font-size: 12px;
  margin-left: 4px;
}
.screenshot-card-footer-information-bar-app-type {
  display: flex;
  align-items: center;
  overflow: hidden;
  /* flex-shrink: 0; */

  /* color: #718093; */
  font-size: 12px;
  margin-left: auto;
  margin-right: 0px;
}
.screenshot-card-footer-information-bar-app-type-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
}
.screenshot-card-footer-name {
  /* font-weight: 600;
  font-size: 14px; */
  /* color: #718093; */
  font-size: 12px;

  /* line-height: 14px; */
  /* color: #34495e; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  width: 77px;
  margin-bottom: 0px;
}
.screenshot-card-footer-information-bar-timer-key-count{
  font-size: 12px;
  margin-right: 5px;
}
.screenshot-card-footer-information-bar-timer-key-count-icon{
  margin-bottom:2px;
  font-size: 13px;
}
.screenshot-card-footer-information-bar-timer-key-count span{
  font-size: 11px;
  margin-left: 3px;
}
.screenshot-card-footer-teamname {
  float: right;
  font-size: 14px;
  line-height: 22px;
  /* color: #718093; */
  font-weight: 600;
  margin-top: 5px;
  cursor: pointer;
}

/* screenshot-card-footer div css end */

/* screenshot-card div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}

@media screen and (max-width: 480px) {
  .screenshot-card-image-modal .modal-dialog {
    min-width: 75% !important;
    margin-top: 0rem !important;
    margin-left: 3rem;
  }
  .screenshot-card-image-modal-close{
    right: -11px;
  }
  .screenshot-card-image-modal-personal-header {
    display: block !important;
    margin-bottom: 1rem !important;
  }
  .screenshot-card-image-modal-personal-header-details{
    margin-bottom: 1rem !important;
  }
  .screenshot-card-image-modal-site-name {
    font-size: 16px !important;
  }
  .screenshot-card-image-modal-site-icon-content{
    height: 40px !important;
    width: 40px !important;
  }
  .screenshot-card-image-modal-site-icon{
    height: 18px !important;
  }
  .screenshot-card-image-modal-body{
    padding:0;
  }
  .screenshot-card-image-modal-time-details{
    font-size: 12px;
  }
  .screenshot-card-image-modal-time{
    font-size: 12px;
    float: right;
  }
  
  .screenshot-card-image-modal-user-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 143px;
  }
  }

.screenshot-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0 12px 19px rgba(38, 38, 38, 0.2);
  top: -7px;
}

.screenshot-card-image-modal-body {
  text-align: center;
  margin: 1.5rem;
}

.screenshot-card-image-modal .modal-dialog {
  max-width: 64% !important;
  margin-top: 4rem;
}
svg.screenshot-card-image-modal-left-nav {
  position: absolute;
  color: white;
  top: 46%;
  left: -10%;
  bottom: 0;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1;
}
.screenshot-card-image-modal-body .screenshot-card-image-modal-left-nav {
  cursor: pointer !important;
}
.screenshot-card-image-modal-left-nav:hover,
.screenshot-card-image-modal-right-nav:hover {
  opacity: 0.9;
}
svg.screenshot-card-image-modal-right-nav {
  position: absolute;
  right: 0;
  top: 46%;
  bottom: 0;
  color: white;
  right: -10%;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1;
}

.screenshot-card-image-modal-close {
  position: absolute;
  right: -88px;
  padding: 0 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  color: white;
  margin: 0.5rem;
  opacity: 0.6;
  top: -77px;
}
.screenshot-card-image-modal-close:hover {
  opacity: 0.9;
}

.screenshot-card-image-modal .modal-dialog .modal-content {
  background-color: white;
}
.screenshot-card-image-modal {
  background-color: #00000099;
}

.screenshot-card-image-modal-personal-header {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.screenshot-card-image-modal-site-icon-content {
  height: 47px;
  width: 47px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #5b5c5f21;
}
.screenshot-card-image-modal-site-icon {
  height: 23px;
  vertical-align: initial;
  margin-top: 12px;
}
.screenshot-card-image-modal-site-name {
  font-weight: 600;
  font-size: 22px;
  color: #000000a6;
}
.screenshot-card-image-modal-user-name {
  font-size: 14px;
  float: left;
  color: #f1306f;
  margin-top: -3px;
}
.screenshot-card-image-modal-site-icon-details {
  margin-top: -3px;
  text-align: left;
}
.screenshot-card-image-modal-personal-header-details {
  display: flex;
}
.screenshot-card-image-modal-time-details {
  font-size: 15px;
  margin-right: 11px;
}
svg.screenshot-card-image-modal-time-icon {
  font-size: 14px;
  margin-right: 4px;
  margin-top: -2px;
}
svg.screenshot-card-image-modal-time-icon-key-board {
  font-size: 16px;
  margin-right: 4px;
  margin-top: -2px;
}
.screenshot-card-image-modal-screenshot-image img{
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
}

/* Edit Menu */

.screenshot-image-edit-menu #dropdown-basic {
  /* top: -11px !important; */
  top: -8px !important;
  left: -20px;
} 
.screenshot-image-edit-menu #dropdown-basic {
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
}
#dropdown-basic {
    position: absolute;
    top: 31px;
    right: -22px;
}
svg.screenshot-image-edit-menu-icon {
  color: #000000cf;
  float: right;
  font-size: 0.9rem;
  margin-top: -29%;
}
.dropdown-menu{
  font-size: 0.8rem !important;
}

.waring-screenshot-summary{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
}
.waring-screenshot-card{ 
  
    overflow: auto;
    color: #4a5568;
    padding:16px;
    /* background: #8ef1760f; */
    background: #f194763d;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
    margin: 1rem;
}
.warning-screenshot-card-title {
  /* font-size: 0.87rem; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-size: 0.98rem;
  color: #1E1E1E !important;
  /* margin-bottom: 20px; */
}
.screenshot-enble-page {
  /* color: #266dfb;
  text-decoration: underline; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.timelapse-warning-text-p{
  margin-top: 1rem;
  font-size: 18px;
}
.screenshot-enble-page-loading{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.screenshot-card-warning-text {
  text-align: center;
  border: 0.5px solid #0000000f;
  padding: 24px;
}
.screenshot-card-warning-text-whole-div{
  margin: 3rem;
}
.project-details-task-card-delete-task-name-text {
  margin-top: 11px;
  color: #0000007a;
}
.screenshots-card-export-wrapper-div {
  display: flex;
}
.ScreenShots-root-data-loader{
  text-align: center;
  display: flex;
  justify-content: center;
}
.screenshots-card-searchbar {
  font-size: 14px;
}
.screenshots-card-searchbar-div {
  width: 330px;
}
.screenshots-card-searchbar::-moz-placeholder {
  color: rgb(214, 210, 210);
  opacity: 1; 
}
.screenshots-card-searchbar::placeholder {
  color: rgb(214, 210, 210);
  opacity: 1; 
}
.screenshot-page-content-wrapper-menu-filter-date {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #333;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  transition: all 100ms ease;
  width: -moz-fit-content;
  width: fit-content;
}
.screenshot-page-content-wrapper-menu-filter-overlay-trigger {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.screenshot-page-content-wrapper-menu-filter-overlay-trigger {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-color: #00000017;
  display: block;
}
.screenshot-page-content-wrapper-menu-filter-overlay {
  display: flex;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  vertical-align: top;
  margin: 17px 20px 20px 20px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 10%;
  gap: 10%;
  margin-top: 7px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 6px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-label {
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
  line-height: 14px;
  color: #34485e;
  font-weight: 600;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button {
  margin-right: 1.7rem;
  position: relative;
  text-align: center;
  margin-top: 1.8rem;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button-cancel {
  font-weight: 700;
  font-size: 14px;
  margin-right: 16px;
  color: #266dfb;
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #266dfb;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button-apply {
  font-weight: 700;
  font-size: 14px;
  /* margin-right: 16px; */
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #266dfb;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  background: #266dfb;
  color: #fff;
}
.screenshots-card-filter-div {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-header {
  display: flex;
  justify-content: space-between;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-close-icon {
  font-size: 17px;
  margin-top: -11px;
  cursor: pointer;
}
.screenshots-card-filter-screenshot-div {
  width: 160px;
}
.screenshots-card-filter-screenshot-div{
  margin-bottom: -16px !important;
  z-index: 9999 !important;
  margin-right: 16px;
}
.screenshots-card-filter-screenshot-div .form-control {
  cursor: pointer !important;
}
.screenshot-page-content-wrapper-menu-filter-overlay-start-timepicker .rc-time-picker .rc-time-picker-clear,.screenshot-page-content-wrapper-menu-filter-overlay-end-timepicker .rc-time-picker .rc-time-picker-clear{
  display: none;
}
.screenshot-page-toggle-div {
  display: flex;
  margin: 0.2rem 0.6rem 0 0 !important;
  width: 100%;
}
.screenshot-page-setting {
  font-size: .9rem;
  font-weight: 500;
  color: #495057;
  margin-top: -1px;
}
.screenshot-page-rule-uparrow {
  font-size: 14px;
  margin-left: 5px;
  color: #62839b;
}
.screenshot-page-toggle-wrapper-div {
  margin-right: 18px;
}
.screenshot-page-end-time {
  font-size: 13px;
  /* margin-left: 1rem; */
  color: red;
  width: 100%;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker-end-error{
  border: 1.5px solid red;
  border-radius: 5px;
}
.screenshots-card-export-div-select-all-form-check{
  margin-top: 2px !important;
}
.my-team-root {
  padding: 2rem;
}
.team-card-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.team-card-root a:hover {
  text-decoration: none;
}
.my-team-root-filter-button {
  margin: 0 0 25px;
}
.my-team-root-filter-button-dropdown
{
  float:right;
}
/* team-summary div css start */
.team-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.team-summary-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.team-summary-card-text {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.team-summary-card-count {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
/* team-summary div css end */

/* team-card div css start */
.team-card {
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  min-height: 158px;
  max-height: 158px;
}

.team-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
}

.team-card.yet_to_start,.team-status-filter.yet_to_start,.team-card.app_not_installed,.team-status-filter.app_not_installed {
  background-color: #fdffe0;
}
.team-card.stop,.team-status-filter.stop{
  background-color: #dc35452e;
}
.team-card.online,.team-card.start,.team-status-filter.start {
  background-color: #c3e7b7;
}
.team-card.break,.team-status-filter.break {
  background-color: #b0c2c2;
}

/* team-card-header div css start */
.team-card-header {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
}
.team-card-avatar {
  display: inline-block;
  margin-right: 10px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #e3e3e3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  color: #333333;
}
.team-card-avatar-profile {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.team-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.team-card-name {
  width: 60%;
}
.team-card-teammembername {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-top: 3px; */
}
.team-card-teammemberstatus {
  color: #212529;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
  /* margin-top: 3px; */
}
.team-card-teammemberstatus:first-letter {
  text-transform: uppercase;
}
.team-card-percent {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}
.high-percent {
  color: #009b37;
}
.low-percent {
  color: #757575;
}
.team-card-percent-text {
  font-size: 10px;
  line-height: 15px;
  color: #333333;
  text-align: center;
}
/* team-card-header div css end */

/* team-card-badges div css end */
.team-card-badges{
 color: #212529;
  text-align:center;
  margin-top: 10px;
}
.team-card-badges-yet-to-start{
  color: #212529;
   text-align:center;
   /* margin-top: 10px; */
 }
.team-card-badges-projectname {
  background: #d8f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 5px 5px 5px;
  margin-left: 5%;
}
.team-card-badges-yet-to-start {
  /* background: #d8f6f6; */
  background: #ffc107;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 5%;
  /* margin-bottom: 7px; */
}
.team-card-badges-desktop-not-installed{
    background: #b4c5e8;
    /* background: #ff7107bd; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin-left: 5%;
    margin-bottom: 7px;
    color: #212529;
   text-align:center;
}
/* team-card-badges div css end */

/* team-card-footer div css start */
.team-card-footer {
  display: flex;
  margin-top: 10px;
  color: #212529;
  justify-content: space-between;
}
.team-card-footer-checkin-time {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}
.team-card-footer-chart {
  width: 63%;
  /* margin: 0px 9px 0px 9px; */
}

.team-card-footer-stop {
  background: #e5e5e5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.team-card-footer-online {
  background: #b5ff92;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.team-card-footer-break {
  background: #d8f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.team-card-footer-hourworked-time {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}
/* team-card-footer div css end */

/* team-card-footer-split div css start */
.team-card-footer-split {
  font-size: 12px;
  line-height: 15px;
  display: flex;
  color: #212529;
  justify-content: space-between;
}
.team-card-footer-split-paragraph,.team-card-footer-split-paragraph-hours-worked {
  margin-bottom: 3px !important;
}

/* team-card-footer-split div css end */

/* team-card div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}
.team-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
  top: -7px;
}
.team-card-percentage {
  margin-left: auto;
}
.team-card-badges-app-icon{
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-top: -2px;
}
.team-card-badges-app-title{
  margin-top: 2px;
}
.team-card-badges-projectname-badge,.team-card-badges-app-name-badge{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500 !important;
  vertical-align: middle;
}
.team-card-badges-app-name-badge,.team-card-badges-projectname-badge{
  max-width: 17ch;
  margin-top: 7px;
}
/* #tooltip-top > .tooltip-inner {
  background-color: #b7b4b4;
  color: #000;
  /* border: 1px solid #062e56; */
/* }
#tooltip-top > .tooltip-arrow {
  background-color: #b7b4b4;
}  */
.tooltip-inner {
  background-color: #b7b4b4 ;
  color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: #b7b4b4;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color:#b7b4b4 ;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
	border-left-color: #b7b4b4;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: #fcd308;
}
.team-card-badges-no-task-badge,.team-card-badges-no-data-badge{
  vertical-align: middle;
  margin-top: 7px;
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.team-status-filter{
  margin-right: 8px;
  /* background-color: #a5a1a13b;
  color: #000000b0; */
  font-weight: 500;
  border: 1px solid #EDEDED;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 9px;
}
.team-status-filter:last-child{
  margin-right: 0px !important;
}
.team-status-filter-selected{
  color: #427ff6;
  background-color: #eef3fc;
  font-weight: bold;
}
@media screen and (min-width: 1400px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
@media screen and (min-width: 1600px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
@media screen and (min-width: 1900px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
.team-card-badges-no-data-badge-hidden{
  visibility: hidden !important;
}
.team-card.leave {
  background-color: #ffbb5c85;
  /* background-color: #ffbb5ca3; */
  
}
.team-card-badges-leave {
  /* background: #d8f6f6; */
  background: #ff7107bd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 5%;
  /* margin-bottom: 7px; */
  font-weight: 500!important;
}
.team-card-badges-start-leave-data-badge {
  background-color: #c3e7b7;
}
.team-card-badges-break-leave-data-badge {
  background-color: #b0c2c2;
}
.team-card-badges-stop-leave-data-badge {
  background-color: rgba(220,53,69,.1803921568627451);
}
.my-team-members-card-leave-label-date-display {
  text-transform: lowercase;
}
.team-card-badges-leave:first-letter {
  text-transform: capitalize;
}
.team-card-root-leave-details-refresh-root {
  margin-bottom: 14px;
  display: flex;
  justify-content: end;
}
.team-card-root-leave-details-refresh-text {
  margin-right: 10px;
  font-size: 15px;
  color: black;
}
.team-card-root-leave-details-refresh-button {
  padding: 4px 18px;
  font-size: 14px;
}
.team-card-root-leave-details-refresh-icon {
  margin: -1px 7px 0px 0px;
}
.team-card-root-leave-details-refresh-button-no-member{
  margin-top: 17px;
}
/* Project Details Card */
.project-details-root {
  max-width: calc(100vw - 210px);
  overflow-x: auto;
  padding: 2rem;
}

.project-details-summary {
  /* display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 30px; */

  display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.project-details-card {
  /* padding: 1.5rem;
  text-align: center;
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3; */

  background: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.project-details-card > .title {
  /* font-size: 14px; */
  font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 175px;
}
.project-details-total-worked-hours-title{
  width: 190px !important;
}
.project-details-card > .text {
  margin: 0.1rem 0 0;
  font-weight: 500;
}

.project-details-chart-wrapper {
  margin: 1rem 0;
}
.project-details-chart {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.project-details-task-card-wrapper {
  /* margin: 1rem 0; */
}
.project-details-task-card {
  border: 1px solid #d3d3d3;;
  border-radius: 5px;
  background-color: #fff;
}
.project-details-task-card-heading{
  font-size: 17px;
}
.project-detail-badge{
  padding: 5px !important;
}
.project-details-card-estimat-hours-span{
  color: gray;
    /* text-decoration: underline; */
    cursor: pointer;
    /* padding-top: 30px; */
    text-align: center;
    font-size: 0.97rem;
}
.budget-na-text{
  opacity: 0.5;
}
.project-details-task-card-header {
  padding: 0.8rem 1.25rem;
  border-bottom: 1px solid #00000014;
}
.project-details-card-estimate-cardtext{
  color:gray;
}

.project-details-new-task-button {
  font-size: 0.7rem;
  font-weight: 600;
}
.project-details-task-card-details {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, calc(41% - 10rem));
  /* grid-template-rows: minmax(150px, 1fr); */
  grid-template-rows: 100%;
  padding: 0 20px;
  overflow-x: scroll;
  height: 26.5rem;
}
@media (min-width: 320px) {
  .project-details-task-card-details {
    grid-template-columns: 100%;
  }
}
@media (min-width: 481px) {
  .project-details-task-card-details {
    grid-template-columns: 100%;
  }
}
@media (min-width: 641px) {
  .project-details-task-card-details {
    grid-template-columns: repeat(4, calc(41% - 10rem));
  }
}

.project-details-task-card-status {
  background-color: #80808021;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  position: relative;
}
.project-details-task-card-title {
  font-weight: 700;
  color: #0000008c;
  font-size: 0.67rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}
.project-details-task-card-data {
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 12px;
  background-color: white;
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;;
  cursor: pointer;
}

.project-details-task-card-menu {
  float: right;
  font-size: 0.8rem;
  color: gray;
}
span.project-details-task-card-task-name {
  font-size: 0.7rem !important;
  font-weight: 600;
}
.project-details-task-card-task-date {
  font-size: 0.57rem;
  background-color: #e6b608;
  padding: 0.3rem;
  border-radius: 5px;
  font-weight: 600;
  margin-right: 1rem;
}
.project-details-task-card-task-time {
  display: inline-block;
}
.project-details-task-card-calender-icon {
  margin-top: -0.2rem;
}
span.project-details-task-card-task-worked-time {
  font-size: 0.6rem;
  margin-right: 1rem;
  font-weight: 700;
  color: #00000091;
}
span.project-details-task-card-task-arrow-up {
  padding: 0.1rem;
  background-color: rgb(241 122 52 / 30%);
  border-radius: 5px;
  font-size: 0.7rem;
}
.project-details-task-card-task-arrow-up-icon {
  margin-top: -0.2rem;
  color: rgb(218 93 19 / 74%);
}
.project-details-task-card-timer-icon {
  margin-top: -0.1rem;
}
.project-details-task-card-avatar {
  height: 25px;
  width: 25px;
  background-color: #ededed;
  color: #424242;
  font-weight: bold;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 25px;
}
.project-details-task-card-list {
  display: grid;
  grid-gap: 0.5rem;
  height: 19rem;
  overflow-y: scroll;
}

.project-details-user-worked-hours-wrapper {
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: calc(100vw - 210px - 60px);
  /* margin: 30px auto; */
  /* margin: 30px; */
}

.project-details-user-worked-hours-wrapper > *:not(:first-child) {
  margin-left: 30px;
}

.project-details-user-worked-hours-wrapper > .user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  color: #111111a8;
  border: 1px solid #d3d3d3;
  justify-content: center;
  font-size: 1.3rem;
  padding: 1rem;
  cursor: default;
}

.project-details-user-worked-hours-wrapper > .user-card .avatar {
  
  width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 18px;
    margin-right: 0.75rem;
    color: #333333c4;
    background-color: #e3e3e3;
    font-weight: 600;
}

.project-details-user-worked-hours {
  white-space: nowrap;
  font-size: 15px;
}

.project-details-chart-project-progress .progress {
  border-radius: 5px;
  height: 49%;
}
.project-details-chart-title {
  padding: 15px 15px 0;
  display: flex;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
}
.project-details-chart-project-name {
  font-weight: bold;
}
.project-details-chart-project-worked-hours {
  text-align: right;
}
.project-details-chart-project-progress .progress .bg-success {
  background-color: #28a74585 !important;
}
.project-details-chart-loader {
  width: 100;
  height: 300px;
  text-align: center;
  vertical-align: middle;
}
.project-details-user-name {
  font-size: 15px;
    max-width: 12ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-details-user-details {
  flex: 1 1;
  font-weight: 700;
}
.project-details-task-card-edit-menu #dropdown-basic {
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  margin-right: -0.5rem;
}
svg.project-details-task-card-edit-menu-icon {
  color: #00000094;
  float: right;
  font-size: 0.8rem;
  margin-top: -16%;
}
.project-details-task-card-edit-menu .dropdown-menu {
  font-size: 0.7rem;
}
.project-details-task-card-delete-task {
  color: red;
}
.project-details-task-card-edit-menu .dropdown-menu .dropdown-item {
  padding: 0 1.5rem;
}

/* Edit Modal CSS */
.project-details-task-card-edit-task-body.modal-body .react-datepicker-wrapper {
  display: block;
}
.project-details-task-card-edit-task-title {
  color: #000000b3;
  font-size: 1.1rem;
  font-weight: 700;
}
.project-details-task-card-edit-task-header .close {
  font-size: 1.3rem;
  margin-top: -1.3rem;
  font-weight: 400;
}
.project-details-edit-task-label-name.opacity-0 {
  opacity: 0;
}
.project-details-edit-task-label-name {
  font-size: 0.93rem;
  font-weight: 500;
  color: #000000ab;
}

.project-details-edit-task-label-due-date {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.project-details-edit-task-label-input {
  /* font-size: 0.84rem !important;
  font-weight: 600 !important;
  color: #495057d1; */
}
.project-details-edit-task-label-input.search-input {
  background-color: #ededed;
}
.project-details-edit-task-label-input.search-input:focus {
  background-color: #fff;
}
.project-details-edit-task-assing-all-button {
  display: inline-block;
  font-weight: 700;
  color: #6879eff2;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1.3px solid #6879eff2;
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  margin-top: 26%;
}
.project-details-edit-task-assing-all-button:hover {
  background-color: #bbc1e82b;
}
.project-details-edit-task-label-employee {
  float: left;
}
.project-details-edit-task-label-select-all {
  float: right;
  color: blue;
  cursor: pointer;
  font-weight: 600;
}
.project-details-edit-task-employee-list {
  padding: 0.5rem;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.project-details-edit-task-employee-list:hover {
  background-color: #007bff10;
}
.project-details-task-card-user-name {
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-details-task-card-cancel-button,
.project-details-task-card-save-button {
  font-size: 0.89rem;
  font-weight: 700;
}
.project-details-edit-task-employee-content,
.project-details-new-task-employee-content {
  width: 100%;
  height: 10rem;
  overflow-y: scroll;
}

/* Delete Task */

.project-details-task-card-delete-cancel-button,
.project-details-task-card-delete-button {
  font-size: 0.9rem;
}
.project-details-task-card-delete-body.modal-body {
  font-size: 0.96rem;
  font-weight: 500;
}
.project-details-task-card-delete-title.modal-title.h4 {
  color: #000000b3;
  font-size: 1rem;
  width: 100%;
}
.project-details-task-card-delete-task-name {
  font-weight: 700;
}

svg.project-details-task-card-delete-danger-icon {
  color: red;
  margin-top: -1%;
  margin-right: 2%;
  font-size: 0.8rem;
  /* padding: 0.4rem; */
}
.project-details-task-board {
  margin: 2rem;
  border: 1px solid #00000021;
}
.project-details-task-board-view {
  background-color: transparent !important;
}
.project-details-task-card-body .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: visible;
  display: block;
}
.project-details-task-card-body .react-trello-board {
  max-width: calc(100vw - 210px - 60px);
  background-color: #fff;
}
.project-details-task-card-body .react-trello-board .react-trello-card {
  transition: all 100ms ease-in-out;
}
.project-details-task-card-body .react-trello-board .react-trello-card:hover {
  background-color: #fbfbfb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.project-details-task-board-view .react-trello-lane {
  width: 18rem;
  font-size: 10px;
  background-color: rgba(128, 128, 128, 0.13);
  border-radius: 5px;
  position: relative;
}

.project-details-task-board-view .react-trello-lane header span {
  font-weight: 700;
  color: #0000008c;
  font-size: 0.67rem;
  margin-bottom: 1rem;
}

.project-details-task-board-view .smooth-dnd-container > *:last-child {
  margin-bottom: 2rem;
}

.project-details-task-card-body .react-trello-board .smooth-dnd-container.vertical {
  padding-bottom: 45px;
}

.project-details-task-card-add-new-task {
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #e3e3e3;
  padding: 10px;
  transition: all 100ms ease-in-out;
}

.project-details-task-card-add-new-task:hover {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}
.project-details-task-edit-wrapper{
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.project-details-task-edit{
  position: static!important;
}
.project-name-header{
  grid-column: 1/3;
  display: flex;
  align-items: center;
}
.project-name-header-avatar{
  display: inline-block;
  text-align: center;
  color: #333;
}
.project-name-icon{
  /* display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 70px;
  background: rgba(239,120,14,.5490196078431373);
  border: 1px solid rgba(0,0,0,.1);
  font-size: 23px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  margin-right: 13px;
  font-weight: bolder; */
  display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 70px;
    background: rgba(239,120,14,.5490196078431373);
    border: 1px solid rgba(0,0,0,.1);
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    color: #fff;
    margin-right: 13px;
}
.project-detail-tasks-modal-content-header-setting {
  font-size: 0.95rem;
    font-weight: 500;
  color: #000000ab;
}
.project-name-value{
  font-size: 22px;
    font-weight: 700;
    color: #333;

  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-name-edit-value{
  color: gray;
  text-decoration: underline;
  cursor:pointer;
}
.project-name-edit-disable-value {
  color: gray;
  text-decoration: underline;
  cursor: not-allowed;
}
.project-details-task-name{
  width:200px !important;
}
.project-details-task-card-avatar-profile {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: hidden;
  margin-right: 0.25rem;
  border-radius: 50%;
}
.project-details-task-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-worked-hours-avatar-profile {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  margin-right: 0.75rem;
  border-radius: 50%;
}
.project-details-worked-hours-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-task-card-body-filter-section{
  display: flex;
  padding: 1rem;
  border: 1px solid lightgray;
  /* margin: 0rem 1rem 0rem 1rem; */
  margin: 1rem;
  border-radius: 0.25rem;
}
.project-details-task-card-body-filter-section-buttions{
  margin-right: 20px;
  display: flex;
  justify-content: end;
}
.project-details-task-card-body-filter-section-taskDescription {
  margin-bottom: 0 !important;
  width: 283px;
}
.project-details-task-card-body-filter-section-search {
  margin-bottom: 0 !important;
  width: 43%;
  display: flex;
  grid-gap: 3px;
  gap: 3px;
}
.project-detail-task-data-td-task-name{
  width: 50%;
}
.edit-setting-card-table-data-mark-as-completed {
  width: 5%;
  cursor: pointer;
}
.edit-setting-card-table-data-whole-edit {
  width: 4%;
  cursor: pointer;
}
.project-task-tracker-undo-edit-menus-hide{
  
}
.project-detail-task-data-td-task-name-in-Progress{
  width: 45% !important;
}
.project-detail-edit-setting-card-table-data-mark-as-complete {
    width: 20%;
    cursor: pointer;
}
.project-details-task-card-body-table-data-details-hours{
  /* color: #28a745; */
  max-width: 15ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
}
.project-details-task-card-body-filter-section-buttions-filter{
  display: flex;
}
.project-details-task-card-body-filter-section-add-form {
  margin-bottom: 0 !important;
  margin-right: 15px;
}
.project-details-task-card-body-filter-section-buttions-add-button{
  /* margin-left: 15px; */
  font-weight: 500;
  padding: 4px 8px;
    font-size: 14px;
    margin-right: -26px;
}
.project-task-tracker-edit-menus #dropdown-basic{
  top:-17px !important;
}
/* .project-task-tracker-edit-menus-progess #dropdown-basic{
  top:-9px !important;
} */
.project-task-tracker-edit-menus-in-progess #dropdown-basic{
  top:-9px !important;
}

.project-chart-loader-head-div {
  height: 400px !important;
}

.project-details-task-card-body-filter-no-data-div {
  text-align: center;
    margin-top: 135px;
    height: 200px;
}
.project-details-task-card-body-filter-no-data-div-other {
  text-align: center;
  margin-top: 120px;
  color: #8080809e;
}
.project-details-task-card-body-table{
  padding: 1rem;
  max-height: 1315px;
  overflow-y: auto;
}
.project-details-task-card-body-table-member{
  padding: 1rem;
  height: 535px;
  overflow-y: auto;
}
.project-details-task-card-body-table-new{
  /* height: 535px; */
  /* max-height: 1315px;
  overflow-y: auto; */
  padding-top: 0rem;
}
.project-details-task-card-body-table-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
}
.project-details-user-work-body-table-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  padding-top: 0;
}
.employee-worked-name-td-div{
  display: flex;
  align-items: center;
}
.employee-worked-name-td-div p{
  /* width: 90%; */
  cursor:pointer;
  margin: 0;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    color: #333;
}
.employee-worked-name-td{
  width: 38%;
}
.employee-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.employee-card-avatar {
  display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background: #e3e3e3;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
    color: #333333;
    text-transform: uppercase;
}
.employee-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-task-card-body-table-header {
  color: gray;
  border: none;
  font-size: 15px;
}
.emplyees-data-no{
  text-align: center;
}
.project-details-user-work-body-table-header {
  color: gray;
  border: none;
  font-size: 15px;
}
.project-details-user-whole-div {
  height: 315px;
  overflow-y: auto;
}
/* .project-details-task-card-body-table-header tr th {
  font-weight: 100;
} */
.project-details-task-card-body-table-body {
  color: #333;
}
.project-details-user-work-body-table-body {
  color: #333;
}
.project-details-task-card-body-table-data-details {
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
  
}
.project-details-user-work-body-table-data-details {
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
  
}
.project-details-home-project-card-table-display-name {
  /* width: 50%; */
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-home-project-card-table-display-name-completed {
  /* width: 50%; */
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-task-card-body-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-user-work-body-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-task-card-body-table-card-table thead th {
  border: none;
  font-weight: 400;
  color: grey;
  font-size: 15px;
}
.project-details-user-work-body-table-card-table thead th {
  border: none;
  font-weight: 400;
  color: grey;
  font-size: 15px;
}
.project-details-user-work-body-table-card-table th,
.project-details-user-work-body-table-card-table td {
  border: none;
  vertical-align: middle;
  padding: .6rem;
}

.project-details-task-card-body-table-card-table th,
.project-details-task-card-body-table-card-table td {
  border: none;
  vertical-align: middle;
  padding: .6rem;
}
.project-details-task-card-edit-table-data-button {
  color: #1c4dbfc9;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
.project-details-task-card-edit-table-data-icon:hover {
  text-decoration: underline;
}
td.project-details-task-card-edit-table-data-icon {
  width: 15%;
}
.project-details-task-card-body-filter-section-search-input{
  margin-right: 2px;
}
.project-details-task-card-edit-table-data-status{
  color: #1c4dbfc9;
    font-weight: 500;
    padding: 0.25rem 1.5rem;
    cursor: default;
}
.project-details-task-card-body-filter-section-taskDescription-search{
  width:206px !important;
}

.project-name-edit-menu {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}
.project-details-task-card-save-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  margin-bottom: 1rem;
}

.project-details-offline-task-card {
  max-height: 22rem;
  min-height: 10rem;
  padding: 0 1rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.project-details-offline-task-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.project-details-offline-task-card-table-header {
  color: rgba(121, 119, 119, .8588235294117647);
  font-size: 15px;
}
.project-details-offline-task-card-table-body {
  color: #333;
}
.project-details-offline-task-card-table-data-details {
  /* padding: 1rem 0;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: -1px 4px 4px -6px #333333; */
  cursor: pointer;
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
}
.project-details-offline-task-card-table td{
  padding: 0.6rem !important;
  color: #000000c9;
}
.project-details-offline-task-card-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-offline-task-card-table th, .project-details-offline-task-card-table td {
  border: none;
  vertical-align: middle;
  padding: 0.5rem;
}
.project-details-offline-task-card-table-description {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-offline-task-card-table-name {
  font-size: 13px;
  color: gray;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-offline-review-status{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-offline-task-card-table-header tr th{
  border-bottom: none;
}
.project-details-offline-task-card-body {
  height: 535px;
}
.project-details-calender-icon-div{
  display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}
.project-details-calender-icon{
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  height: 15px;
  margin: 0 5px;
  opacity: 0.8;
  font-size: smaller;
}
.project-details-calender-text{
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
.project-details-chart-title-date {
  margin-left: auto;
    margin-right: 0;
}
.project-details-home-project-card-root-list-section-body {
  margin: 1rem 1rem 0 1rem;
  height: 535px;
  overflow-y: auto;
}
.project-details-table-card-table th, .project-details-table-card-table td {
  padding: 0.6rem !important;
}
.project-detail-loader-tab{
  height: 535px;
}
.project-detail-whole-div-for-exports{
  display: flex;
}
.project-detail-filter-download-icon-btn:hover {
  color: #fff !important;
  /* cursor: not-allowed !important; */
}
.disable-timesheet-project-detail {
  padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #007bff;
    border-radius: .25rem;
    border: 1px solid #007bff;
}

.my-projects-ondue-selected{
  color:red !important;
}
.project-detail-cursor{
  cursor: pointer;
}
.project-detail-cursor:hover {
  text-decoration: underline;
}
/* .task-filter-add-new-task-button-tab{
  margin-top: -6px;
}
.task-tracker-header-tab a:nth-last-child(-n+1){
  width: 25%;
  text-align: right;
} */
.desktop-home-project-card-task-tracker-table-description-glass-icon {
  color: #666464d1;
  font-size: 10px;
}

.task-priority-edit-whole-div-data {
  display: flex;
}
.task-edit-add-modal-priority-div {
  width: 100%;
}
.task-edit-modal-content-person-name-header-section {
  flex: 1 1;
  /* display: flex; */
  justify-content: space-between;
}
.project-detail-desktop-home-project-card-table-description {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 4px;
}

.project-detail-desktop-home-project-card-table-description span{
  width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  margin-top: 4px;
}
.desktop-home-project-card-table-description-priority{
  font-size: 12px;
  background-color: #e3e3e7;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  color: gray;
}
.project-bugject-h4{
  font-size: 18px !important;
}
.project-details-budject-span-text{
  text-decoration: underline;
}
.desktop-home-project-card-table-description-priority-root {
  margin-top: 3px;
  margin-bottom: 3px;
}
.desktop-home-project-card-table-description-priority-date-data{
  display: flex;
  grid-gap: 10px;
  margin-top: 2px;
}
.desktop-home-project-card-table-description-priority-low {
  background-color: #57c11542;
}
.desktop-home-project-card-table-description-priority-medium {
  background-color: #e7e7a2;
}
.desktop-home-project-card-table-description-priority-high {
  background-color: #f1baba;
  color: #302f2f;
}
.desktop-home-project-card-table-description-priority {
  text-transform:capitalize;
}
.project-details-task-card-body-table-data-details-ongoing{
  background-color: #dbfbe7 !important;
}


.project-task-tracker-header-tab {
  font-size: 16px;
  color: #00000087;
  font-weight: 500;
  
  flex-wrap: initial;
}
.project-task-tracker-header-tab-whole{
  /* width: 95%; */
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: initial;
}
.project-nav-left {
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  background-color: #007bff;

  float: left;
  position: relative;
  left: 8px;
  top: 6px;
  font-size: 20px;
  cursor: pointer;
  z-index: 55;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.project-nav-left-div{
  /* margin-left:40px; */
}
.project-nav-right {
  
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  background-color: #007bff;
  float: right;
  position: relative;
  right: 10px;
  top: 7px;
  font-size: 20px;
  cursor: pointer;
  z-index: 55;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}

/* .project-detail-badge-ongoing {
  background-color: #8a811b7d;
} */
.project-tooltip-priority,.project-tooltip-start-date{
  width: -moz-fit-content;
  width: fit-content;
}
.project-tooltip-start-tooltip{
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.desktop-home-project-card-table-ongoing-status {
  font-size: 14px;
}

.project-task-edit-modals-root .modal-dialog .modal-content {
  padding: 0 16px;
  border-radius: 12px;
}
.project-task-edit-modals-root-title.modal-title.h4 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  width: 90%;
  flex-shrink: 0;
}

.project-details-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 150px;
  flex-grow: 1;
  border: 1px solid #d3d3d3;
  padding: 10px;
}

.projects-tasks-modal-content-person-name-header-button {
  font-size: .86rem;
    margin-bottom: 0;
    color: grey;
    cursor: pointer;
    margin-top: 6px;
    text-decoration: underline;
}
.projects-tasks-modal-content-person-name-header-button:hover {
  text-decoration: underline;
}
.my-projects-modal-content-name-field{
  margin-right:0 !important;
}
.my-projects-modal-content-person-name-header-section-label-edit{
  font-size: 14px !important;
}
.project-details-card-footer-add-estimation-time-input{
  display: table-cell;
  vertical-align: middle;
}
.project-details-card-footer-add-estimation-time-input-type {
  font-size: 13px;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.project-details-card-footer-add-estimation-time-input-label{
  padding: 8px;
  font-size: 15px;
}
.project-details-card-estimated-time-root{
  display: table;
}
.project-details-card-footer-add-estimation-time-button {
  width: 19%;
  padding: 1px;
  margin-left: 4px;
}
.project-details-card-footer-add-estimation-time-button-icon{
  margin-top:-4px;
}
.project-details-card-footer-add-estimation-time-button-loader{
  font-size: 1rem;
  color: white;
  animation: spin 0.5s linear infinite;
}
.my-projects-edit-modal-body{
  padding:2rem !important;
}
.project-details-task-card-body-filter-section-buttions-add-button-root{
    font-weight: bold;
    padding: 8px;
    font-size: 14px;
}
.project-details-button-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.project-details-button-section-wrapper{
  margin-right: 15px;
}
.project-details-import-task-modal{
  margin-bottom: 1.5rem;
}
.project-details-import-task-modal-example-div{
  margin-bottom: 1.5rem;
  
}
.task-import-filter-download-icon {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: -3px;
}
.task-import-download-icon-btn {
  cursor:pointer;
  color: blue;
  font-size: 0.9rem;
}
.tasks-import-modal-p{
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: gray;
}
.tasks-import-modal-p-export{
  font-size: 0.9rem;
  color: #8c94a1;
  margin-bottom: 0px;
}
.import-tasks-modal-error{
  color:red;
  font-size: 0.8rem;
  margin-bottom: 0.7rem;
  text-align: center;
}
.project-details-import-task-modal .custom-file-label{
  font-size: 0.9rem;
}
.import-tsk-modal-alert-text{
  padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #007bff17;
}
.tasks-import-modal-p-label{
  font-size: 0.9rem;
  font-weight: 600;
    color: rgba(0,0,0,.6705882352941176);
}

.project-detail-task-data-td-task-name-upcomingTasks {
  width: 645px !important;
}
.project-detail-desktop-home-project-card-table-description-upcomingTasks {
  width: 598px !important;
}
.project-detail-task-data-td-task-name-in-Progress {
  width: 515px !important;
}
.project-detail-desktop-home-project-card-table-description-inProgressTasks {
  width: 473px  !important;
}
.project-detail-task-data-td-task-name-onDue {
  width: 480px  !important;
}
.project-detail-desktop-home-project-card-table-description-onDue {
  width: 423px  !important;
}
.project-detail-task-data-td-task-name-completed {
  width: 519px  !important;
}
.project-detail-desktop-home-project-card-table-description-completed {
  width: 452px !important;
}
.project-detail-task-data-td-task-name-hold {
  width: 513px  !important;
}
.project-detail-desktop-home-project-card-table-description-hold {
  width: 491px  !important;
}
.project-detail-desktop-home-project-card-table-description-all-task-td {
  width: 318px !important;
}
.project-detail-desktop-home-project-card-table-description-all-task {
  width: 395px !important;
}
.project-details-button-section-root-toggle{
  display: flex;
  align-items: center;
}


.quick-edid-mode-tasks-rule-uparrow {
  font-size: 14px;
    margin-left: 5px;
    color: #62839b;
    margin-top: 6px;
    margin-right: 5px;
}

.toggle-switch label {
  cursor: pointer;
  margin-bottom: 0 !important;
}

.toggle-switch label .toggle-track {
  display: block;
  height: 20px;
  width: 35px;
  background: #e1e4e8;
  border-radius: 20px;
  position: relative;
  /* margin-bottom:15px; */
  border: 1px solid #ccc;
  transition: .4s;
}

.toggle-track-nav-right-div {
  background: #7dd46e !important;
}

.toggle-track-nav-right-div:before {
  transform: translateX(15px) !important;
}

.toggle-switch .toggle-track:before {
  content: '';
  display: inline-block;
  height: 17px;
  width: 17px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: right .2s ease-in;
  left: 0px;
  bottom: 2px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
  transition: .4s;
}
.quick-edid-mode-tasks-rule-uparrow-text{
  margin-right: 15px;
}

.quick-edit-project-details-td{
  display: flex;
  align-items: center;
}
.quick-edit-project-details-input-div{
  margin-right: 10px;
  margin-top: -21px;
}

.quick-edit-task-buttons-root{
  display: flex;
  align-items: center;
  background-color: #266dfb2b;
  margin-top: 1rem;
  padding: 11px 11px 1px 11px;
  border-radius: 5px;
}
.quick-edit-task-buttons-root-specify-button{
  margin-right: 8px;
  font-weight: 500;
  border: 1px solid #EDEDED;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 9px;
  color:#266dfbd6;
}
.project-details-quick-edit-selected{
  background-color: #266dfb10 !important;
}
.quick-edit-task-buttons-root-count{
  color: #266dfbd6;
    margin-top: -8px;
    margin-right: 8px;
}

.quick-edit-time-div {
  display: flex;
}
.quick-edit-time-div-modal-save-button {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
}

.quick-project-task-due-date {
  margin-bottom: 1.5rem;
}
.quick-project-task-section-whole-input-div {
  /* margin-bottom: 2.5rem; */
}
.quick-delete-text{
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.quick-task-details-due-date-date-picker {
  display: block;
  width: 435px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.quick-project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  /* box-shadow: inset 0 -1px 0 0 #edeef2; */
  text-transform: uppercase;
  width: 300px;
}
.quick-edit-tooltip-button{
  border-radius: 50rem;
  padding: 0.25rem 0.5rem;
padding-left: 1rem;
padding-right: 1rem;
  }
  .quick-edit-check-box-root {
  margin-top: -13px;
}
.project-details-all-task-label{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  color: #bdbbbb;
}
.screenshot-load-more-button {
  color: #bdbbbb !important;
}
.project-details-task-details-card-description-card h4{
  font-size: 16px!important;
  line-height: 22px;
  color: #000000d1!important;
  font-weight: 100 !important;
  margin-top: 8px!important;
}
.my-projects-modal-searchbox-wrapper div .form-control::-moz-placeholder { 
  color: rgb(199, 194, 194);
}
.my-projects-modal-searchbox-wrapper div .form-control::placeholder { 
  color: rgb(199, 194, 194);
}
.my-projects-modal-searchbox-wrapper div .form-control { 
  font-size: 0.9rem;
}
.my-projects-modal-content-person-name-header-section-label-edit{
  margin-bottom: 0px !important;
}
.project-name-value-member{
  font-size: 25px !important;
}
.project-name-card-wrapper {
  display: flex;
}
.project-name-card-details-avatar-circle {
  height: 30px;
  width: 30px;
  font-weight: bold;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
  margin-top: 5px;
}
.project-task-selection-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: initial;
}

.project-and-task-selection-navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    flex-wrap: initial;
}

.project-and-task-selection-navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.project-and-task-selection-navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.project-and-task-selection-navbar .container,
.project-and-task-selection-navbar .project-and-task-selection-container-fluid,
.project-and-task-selection-navbar .container-lg,
.project-and-task-selection-navbar .container-md,
.project-and-task-selection-navbar .container-sm,
.project-and-task-selection-navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.project-and-task-selection-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.project-and-task-selection-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-container-fluid>.project-and-task-selection-navbar-collapse,
.project-and-task-selection-container-fluid>.project-and-task-selection-navbar-header,
.container>.project-and-task-selection-navbar-collapse,
.container>.project-and-task-selection-navbar-header {
    margin-right: -15px;
    margin-left: -15px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-toggle {
    border-color: #ddd;
}

.project-and-task-selection-navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.project-and-task-selection-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar {
    background-color: #888;
}

.project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar+.project-and-task-selection-icon-bar {
    margin-top: 4px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-brand {
    color: #777;
}

.project-and-task-selection-navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-collapse,
.project-and-task-selection-navbar-default .navbar-form {
    border-color: #e7e7e7;
}

.project-and-task-selection-navbar-collapse.in {
    overflow-y: auto;
}

.project-and-task-selection-collapse.in {
    display: block;
}

.project-and-task-selection-navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
}

.project-and-task-selection-navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.project-task-selection-navbar-nav {
    margin: 7.5px -15px;
}

.project-task-selection-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.project-task-selection-nav>li {
    position: relative;
    display: block;
}

.project-task-selection-navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a:focus,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a:hover {
    color: #555;
    background-color: #e7e7e7;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>li>a {
    color: #777;
}

.project-task-selection-navbar-nav>li>a {
    line-height: 20px;
}

.project-task-selection-nav>li>a {
    position: relative;
    display: block;
}

.project-and-task-selection-dropdown,
.dropup {
    position: relative;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a:focus,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a:hover {
    color: #555;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>li>a {
    color: #777;
}

.project-task-selection-nav .project-and-task-selection-open>a,
.project-task-selection-nav .project-and-task-selection-open>a:focus,
.project-task-selection-nav .project-and-task-selection-open>a:hover {
    border-color: #337ab7;
}

.project-task-selection-navbar-nav>li>a {
    line-height: 20px;
}

.project-task-selection-nav>li>a {
    position: relative;
    display: block;
    cursor: pointer;
}

.project-and-task-selection-open>a {
    outline: 0;
}

@media (max-width: 767px) {
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
}

.project-task-selection-navbar-nav>li>.project-and-task-selection-dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


/* .project-and-task-selection-dropdown-menu {
    display: block;
} */

.project-and-task-selection-open>.project-and-task-selection-dropdown-menu {
    display: block !important;
}


/* .project-and-task-selection-open>.project-task-selection-list>.project-and-task-selection-dropdown-menu{
    display: block !important;
} */

.project-and-task-selection-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.project-and-task-selection-dropdown-menu>li {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
}

@media (max-width: 767px) {
    .project-and-task-selection-navbar-default .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        color: #777;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        line-height: 20px;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu .dropdown-header,
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        padding: 5px 15px 5px 25px;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
}

.project-and-task-selection-dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.project-and-task-selection-dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    display: none;
    margin-top: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: #fff;
    box-shadow: none;
}

.project-and-task-selection-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.project-and-task-selection-dropdown-menu .project-and-task-selection-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 5px;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    color: #777;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    line-height: 20px;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu .dropdown-header,
.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    padding: 5px 15px 5px 25px;
}

.project-and-task-root {
    /* width:40%; */
}

.navbar-collapse-project-task {
    background-color: white;
    /* box-shadow: 3px 3px 3px -1px #33333354; */
    font-size: 15px;
    border-radius: 4px;
    width: 100%;
    height: 300px;
    /* height: calc(100vh - 260px); */
    overflow-x: hidden;
    overflow-y: auto;
}

.project-task-selection-list-success {
    color: #333 !important;
    font-weight: 600;
}

.project-task-selection-list-secondary1 {
    color: rgb(160, 209, 25) !important;
    font-weight: 600;
}

.project-task-selection-list-secondary {
    color: orange !important;
    font-weight: 600;
}

.project-task-selection-list-circle {
    /* width: 6px;
    height: 6px;
    border-radius: 70%;
    display: inline-block; */
    margin-right: 10px;
    font-size: 14px;
    display: flex;
    margin-top: 3px;
}

.project-task-list-modal-save-button {
    display: flex;
    justify-content: end;
    /* flex-direction: column; */
    justify-content: center;
    /* margin-bottom: 11px; */
}

.project-task-selection-list-success-circle {
    background-color: #333333c4 !important;
}

.project-task-selection-list-secondary1-circle {
    background-color: rgb(160, 209, 25) !important;
}

.project-task-selection-list-secondary-circle {
    background-color: orange !important;
}

.project-task-selection-list,
.project-task-selection-task-list {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.project-task-selection-task-list {
    align-items: center;
    justify-content: space-between;
    padding: 9px 15px !important;
}

.project-task-selection-list-task-dropdown-menu {
    background-color: #bacdf921 !important;
}

.project-task-selection-list-name-content {
    width: 70%;
    padding: 9px 15px 9px 9px;
    /* margin: 0 0 0 8px; */
}

.project-task-selection-list-name-content:hover,
.project-task-selection-list-name-content-add-project:hover,
.project-task-selection-task-list:hover {
    background-color: #e7e7e782;
    border-radius: 4px;
}

.project-task-selection-list-name-content-selected {
    background-color: #eef3fc;
    border-radius: 4px;
    color: #007bff !important;
}

.task-selection-list-name-content-selected {
    background-color: #e3eaf6;
    border-radius: 4px;
    color: #266dfb !important;
    font-weight: 600 !important;
}

.project-task-selection-nav.project-task-selection-navbar-nav {
    margin: 7.5px 0;
}

.project-task-selection-list-add-project-icon {
    font-size: 17px;
    margin-right: 8px;
}

.project-task-selection-list-name-content-add-project {
    width: 100%;
    padding: 9px 15px 9px 4px;
    /* margin: 0 0 0 8px; */
    font-weight: 400;
    font-size: 14px;
}

.project-task-selection-list-name-content-add-task {
    width: 100%;
    padding: 9px 6px;
    /* margin: 0 0 0 8px; */
    font-weight: 400;
    font-size: 14px;
}

.project-task-selection-list-add-project {
    color: #034aef !important;
}

.project-task-selection-list:hover,
.project-task-selection-task-list:hover {
    text-decoration: none;
    font-weight: bold;
}

.project-task-selection-list-task-content {
    color: gray;
}

.project-task-selection-list-task-content:hover {
    text-decoration: underline;
}

.project-task-selection-task-list-name {
    margin-left: 16px;
}

.project-task-selection-task-list-divider.project-and-task-selection-divider {
    height: 1px;
    margin: 9px 0 0 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.project-task-selection-list-task-content-create-task-button {
    color: gray;
    font-size: 12px;
    text-decoration: underline;
}

.project-task-selection-list-add-task {
    font-size: 13px;
}

.project-task-selection-list-task-content-arrow-down {
    margin-left: 5px;
    vertical-align: middle;
}

.project-task-content-modal-header {
    font-size: 18px;
}

.project-task-content-modal-button {
    font-size: 14px;
    font-weight: 600;
}

.my-projects-modal-form-error {
    text-align: center;
    color: red;
}

.task-add-new-project-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.7;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}

.tasks-modal-content-person-name-header-section-label {
    line-height: 18px;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
}

.tasks-modal-content-person-name {
    overflow: auto;
    /* height: 100%; */
    flex-grow: 1;
}

.tasks-modal-content-person-name-header {
    display: flex;
    margin-bottom: 5px;
}

.tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 10px 10px 10px 10px;
    margin-bottom: 1.5rem;
}

.tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
}

.tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
}

.tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
}

.tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
}

.task-card-avatar-profile {
    width: 22px;
    height: 22px;
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
}

.task-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.task-due-date {
    margin-bottom: 1rem;
    /* width: 200px; */
    margin-left: 1rem;
}

.task-date-whole-div-data {
    display: flex;
}

.my-tasks-add-new-project-option-div {
    display: flex;
    width: 260px;
}

*/ .project-tasks-add-time-option-div {
    display: flex;
    width: 227px;
}

.task-section-edit-task-label-input {
    margin-bottom: 1.5rem;
}

.task-add-placeholder::-moz-placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}

.task-add-placeholder::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}

.task-add-due-date-span {
    opacity: 1;
    color: #abab9ddb !important;
}

.navbar-collapse-project-task-list-card-searchbox-wrapper {
    position: relative;
    width: 95%;
    margin: auto;
    padding: 10px 0;
    /* border-bottom: 1px solid #e5e5e5; */
}

.navbar-collapse-project-task-list-card-searchbox-wrapper-div {
    border-bottom: 1px solid #e5e5e5;
    margin: 0 10px;
}

.task-details-due-date-date-picker {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.task-details-due-date-date-picker::-moz-placeholder {
    /* Most modern browsers support this now. */
    color: #c4c1c1;
}

.task-details-due-date-date-picker::placeholder {
    /* Most modern browsers support this now. */
    color: #c4c1c1;
}

.task-priority-whole-div-data {
    display: flex;
}

.task-add-modal-priority-div {
    width: 100%;
}

.task-modal-content-header-team-name-acc {
    display: flex;
    margin-bottom: 0.5rem !important;
    /* text-decoration: underline !important; */
    justify-content: space-between;
    width: 100%;
}

.task-modal-accordion-whole {
    margin-bottom: 1.5rem !important;
}

.tasks-modal-cols {
    padding: 0 20px !important;
}

.project-tasks-modal-content-header-setting {
    /* font-size: 0.97rem !important;
    line-height: 18px;
    color: #62839b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left; */
    /* font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab; */
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, .6705882352941176);
}

.project-tasks-modal-content-header-setting:hover {
    /* color: #62839b;
    text-decoration: underline !important; */
}

.tasks-rule-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
}

.toggle-switch label {
    cursor: pointer;
    margin-bottom: 0 !important;
}

.toggle-switch label .toggle-track {
    display: block;
    height: 20px;
    width: 35px;
    background: #e1e4e8;
    border-radius: 20px;
    position: relative;
    /* margin-bottom:15px; */
    border: 1px solid #ccc;
    transition: .4s;
}

.toggle-track-nav-right-div {
    background: #7dd46e !important;
}

.toggle-track-nav-right-div:before {
    transform: translateX(15px) !important;
}

.toggle-switch .toggle-track:before {
    content: '';
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: right .2s ease-in;
    left: 0px;
    bottom: 2px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
    transition: .4s;
}

.task-modal-private-toggle-div {
    display: flex;
    margin-bottom: 1.5rem !important;
    justify-content: space-between;
    width: 100%;
}

.task-modal-private-toggle-div-command-line {
    color: #8c94a1;
    font-size: 13px;
    letter-spacing: -.2px;
    line-height: 17px;
    max-width: 385px;
    padding-top: 7px;
}

.project-tasks-modal-content-header-setting-icon {
    font-size: 0.75rem;
    margin-top: -2px;
}

.project-task-modals-root .modal-dialog .modal-content {
    padding: 0 16px;
    border-radius: 12px;
}

.project-task-modals-root-title.modal-title.h4 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    width: 90%;
    flex-shrink: 0;
}

.project-task-modals-edit-task-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    /* letter-spacing: -.22px; */
    line-height: 22px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .6705882352941176);
}

.project-task-modals-edit-task-label-name::-moz-placeholder {
    color: rgb(212, 209, 209);
}

.project-task-modals-edit-task-label-name::placeholder {
    color: rgb(212, 209, 209);
}

.project-task-acc-collapse-whole-div-data {
    border: 1px solid #d3d3d3;
    padding: 20px;
    border-radius: 6px;
    background-color: #8080800d;
}

.project-tasks-modals-add-time-option-div {
    display: flex;
    width: 195px;
}

.project-task-modal-add-new-project-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.7;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}

.project-task-section-whole-input-div {
    margin-bottom: 0.5rem;
}

.project-task-section-whole-input-div {
    margin-bottom: 0.5rem;
}

.project-task-due-date {
    /* margin-bottom: 1rem; */
    width: 200px;
    margin-left: 1rem;
}

.project-task-modals-user-label-section {
    height: 32px;
    color: #8c94a1;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .55px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* box-shadow: inset 0 -1px 0 0 #edeef2; */
    text-transform: uppercase;
    width: 265px;
}

.project-task-modals-searchbox-wrapper {
    position: relative;
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 12px;
}

.project-task-modals-content-person-name-listing-card-content {
    /* margin-bottom: 2px; */
    display: flex;
    margin-top: 10px;
}

.project-task-modals-save-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
}

.project-task-modals-user-label-section-div {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #edeef2;
}

.my-tasks-add-new-project-option-div {
    display: flex;
    width: 210px;
}

.project-task-modals-edit-task-label-name-star {
    font-size: smaller;
    font-weight: 400;
    color: red;
    top: -0.3em;
}
.create-new-task-whole-div-new{
    display: flex;
}
.create-new-task-whole-div-new-textbox{
    width: 390px;
}
.create-new-task-whole-div-new-button{
    margin-left: 15px;
}
.create-new-task-whole-div-add-new-team {
    margin-top: -13px;
    padding-left: 0px;
    margin-bottom: 10px;
}
.create-new-task-whole-div-add-new-team:focus{
    box-shadow: none;
}
.project-task-modals-add-task-label-name-task-description label {
    color: rgba(0, 0, 0, .6705882352941176);
    font-size: 14px;
    font-weight: 600;
    /* letter-spacing: -.22px; */
    line-height: 22px;
    margin-bottom: 8px;
}
.project-task-modals-add-task-label-name-task-description {
    margin-bottom: 1.5rem;
}
/* textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    color: var(--gray-700);
    border-radius: 10px;
    resize: vertical;
    background-color: var(--gray-100);
    box-sizing: border-box;
    padding: 0.65rem 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
      "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
      sans-serif;
  }
  textarea:invalid {
    border: 2px solid #ff7d87;
    box-shadow: none;
  }
  textarea:focus {
    outline: none;
    border: 2px solid var(--focus-ring-color);
  } */

  textarea{
    display: block;
    width: 100%;
    height: calc(4em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.project-detail-task-detail-modal .modal-content{
    /* background-color: rgb(252, 252, 252); */
    height: 680px;
    overflow-x: hidden;
}
.project-detail-task-detail-modal-title.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.project-detail-task-detail-modal-title-project-name {
    font-size: 13px;
    color: gray;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 1rem;
}
.project-details-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.project-details-task-details-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
    /* margin-left: 2rem; */
    /* background-color: white; */
    background-color: rgb(252, 252, 252);
}
.project-details-task-details-employee-list-card-body {
    padding: 0 1.3rem 1rem;
}
.project-details-task-details-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-details-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-details-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-details-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 1px 0px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-details-employee-list-card-details p {
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-detail-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-details-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-detail-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 670px;
    overflow-y: scroll;
}
.project-detail-task-detail-tab .nav-tabs > a {
    color: #212529;
    font-size: 14px;
    padding: 7px 8px;
    font-weight: 500;
}
.project-detail-task-detail-tab .nav-tabs > a.active {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs .nav-link:hover {
    border: none;
    color: #157af6b5;
}
.project-detail-task-detail-tab .nav-tabs .nav-link.active:hover {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs{
    padding: 0 1rem;
}
.project-detail-task-detail-modal-header{
    padding: 1rem;
    /* border-bottom: none !important; */
    margin: 0 0 0.1rem;
}
.project-detail-task-detail-tab{
    /* margin: 0rem 1rem 1rem 1rem; */
}
.project-detail-task-detail-edit-modal-body-container {
    margin: 2rem 1rem;
}



.project-detail-task-detail-edit-modal-body-container-timeline {
    margin: 0rem 1rem;
}
.project-details-task-timeline-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 0.5px solid #00000014; */
    /* margin-left: 2rem; */
    /* background-color: white; */
    /* background-color: rgb(252, 252, 252); */
}
.project-details-task-timeline-employee-list-card-body {
    /* padding: 0 1.3rem 1rem; */
}
.project-details-task-timeline-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-timeline-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-timeline-employee-list-card-data-table th, .project-details-task-timeline-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-timeline-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
    /* margin-bottom: 0.9rem; */
    /* margin-top: 0.5rem; */
}
.project-details-task-timeline-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-timeline-employee-list-card-details-hide {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: none
}
.project-details-task-timeline-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-timeline-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-timeline-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-timeline-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.project-details-task-timeline-date-divider-label-icon {
    margin-right: 10px;
    /* color: #495057eb; */
}
.project-details-task-timeline-employee-list-card-details-avatar p{
    margin-bottom: 0rem;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
    align-items: center;
}

.timeline-container::after {
    background-color: #80808030;
    content: '';
    position: absolute;
    left: calc(23% - (-4px));
    width: 4px;
    height: 100%;
}
.timeline-item {
    /* display: flex;
    justify-content: flex-end; */
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 53%;
}

.timeline-item:nth-child(odd),.timeline-item:nth-child(even) {
    /* align-self: flex-end;
    justify-content: flex-start; */
    padding-left: 30px;
    padding-right: 0;
}
.timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(252, 252, 252);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 13px;
    position: relative;
    width: 430px;
    max-width: 100%;
    text-align: right;
}

.timeline-item-content::after {
    content: ' ';
    background-color: rgb(252, 252, 252);
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}
.timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item:nth-child(even) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item-content time {
    color: #777;
    font-size: 12px;
    font-weight: bold;
}

.timeline-item-content p {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.timeline-item-content a {
    font-size: 14px;
    font-weight: bold;
}

.timeline-item-content a::after {
    content: ' ►';
    font-size: 12px;
}

.timeline-item-content .circle {
    background-color: #fff;
    border: 3px solid #80808030;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timeline-item-content time {
        margin-top: 20px;
    }

    .timeline-item-content a {
        text-decoration: underline;
    }

    .timeline-item-content a::after {
        display: none;
    }
}
p.project-details-task-activity-history-event-text {
    display: flex;
}
.project-details-task-activity-history-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-activity-history-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-activity-history-card-avatar {
    height: 25px;
    width: 25px;
    background-color: #ededed;
    color: #424242;
    font-weight: bold;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 25px;
}
.task-tracker-date-header-tr{
    margin-top: 20px;
    margin-bottom: 10px;
}
.task-tracker-date-header-first-tr{
    margin: 10px 0px;
}
.project-details-task-details-card h4 {
    font-size: 17px;
}


.project-detail-manual-task-detail-modal .modal-content {
    /* background-color: rgb(252, 252, 252); */
    /* height: 680px; */
    overflow-x: hidden;
}
.project-detail-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 300px;
    overflow-y: scroll;
}
/* .project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
   margin: 2rem;
} */
.project-details-manual-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-manual-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project-details-manual-task-details-card h4 {
    font-size: 20px;
}
.project-details-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 250px;
    overflow-y: scroll;
}
.project-details-manual-task-detail-edit-modal-body-container {
    margin: 2rem;
}
.project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-manual-task-detail-edit-modal-body-reviewed{
    height:365px !important;
}
.project-details-task-details-card-description-card{
    margin-bottom:20px;
}
.desktop-home-card {
    margin: 2rem;
    /* max-width: 500px; */
    /* height: calc(100vh - 20%); */
    /* background-color: white; */
}

.desktop-home-project-card-root {
    /* padding: 15px; */
    /* border: 1px solid #d3d3d3;
    border-radius: 3px; */
}

.desktop-home-project-card-root-add-section {
    padding: 20px 5px;
    background: #fff;
    /* border-radius: 5px; */
    border: .5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.edit-task-tracker-time-dropdown {
    margin-top: 2rem;
}

.edit-task-tracker-date-picker {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    /* width: 200px; */
    /* margin-top: 1rem; */
}

.workfolio-edit-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}

.edit-task-whole-div {
    display: flex;
    margin-top: 2rem;
}

.task-edit-home-project-add-card-formik {
    overflow-y: auto;
    overflow-x: hidden;
    /* height: calc(100vh - 280px); */
    padding: 1rem;
}

svg.workfolio-edit-pages-calender-icon {
    font-size: 16px;
    text-align: center;
    color: #87898b;
    margin-top: 3px;
    cursor: pointer;
}

.edit-setting-card-table-data-icon-space {
    display: none;
}

.edit-setting-card-table-data-icon {
    width: 4%;
    cursor: pointer;
}

.desktop-home-project-add-card-formik {
    display: flex;
    justify-content: space-around;
    grid-gap: 1%;
    gap: 1%;
}

.desktop-home-project-add-card-formik-timer {
    display: flex;
    justify-content: space-around;
    grid-gap: 1%;
    gap: 1%;
}

.workfolio-manual-pages-calender-div {
    display: flex;
}

.workfolio-manual-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 7px;
    margin-left: 5px;
    cursor: pointer;
}

.workfolio-manual-pages-toggle-div {
    display: grid;
    color: #868686;
    margin-top: 3px;
}

.desktop-home-project-add-card-formik-comman {
    cursor: pointer;
}

.workfolio-manual-pages-calender-icon-div {
    display: flex;
}

.desktop-home-project-add-card-formik .desktop-home-project-add-card-formik-description {
    margin-bottom: 0rem !important;
    width: 30%;
}

.desktop-home-project-formik-add-form {
    margin-bottom: 0 !important;
}

.desktop-home-project-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}

.error+.desktop-home-project-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.desktop-home-project-formik-add-form .rc-time-picker-clear {
    display: none !important;
}

.edit-task-tracker-date-picker-date {
    margin-left: 1rem;
}

.desktop-home-project-formik-add-form-width input.rc-time-picker-input {
    /* width: 200px !important; */
}

.desktop-home-project-formik-add-form-edit {
    margin-bottom: 0 !important;
}

.desktop-home-project-formik-add-form-edit input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    /* width: 220px; */
    font-size: 15px !important;
}

.error+.desktop-home-project-formik-add-form-edit .rc-time-picker-input {
    border-color: #F0624D !important;
}

.desktop-home-project-formik-add-form-edit .rc-time-picker-clear {
    display: none !important;
}

.rc-time-picker-panel {
    /* width: 140px; */
}

.desktop-home-project-add-card-formik-timer .form-group {
    margin-bottom: 0rem !important;
    width: 70%;
}

.desktop-home-project-add-card-formik .form-control {
    font-size: 15px !important;
}

.desktop-home-project-add-card-formik-timer .form-control {
    font-size: 15px !important;
    /* border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c5c5c5; */
}

.desktop-home-project-add-card-formik-description .form-control {
    font-size: 18px !important;
    /* border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c5c5c5; */
    /* background-color: #f3f3f3; */
    /* padding: 40px;
    padding-top: 10px; */
}

input.desktop-text-input.form-control {
    font-size: 18px !important;
}

.desktop-home-project-formik-add-project-button {
    font-size: 15px !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:hover {
    color: #007bff !important;
    background-color: #f0efff !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:active {
    color: #007bff !important;
    background-color: #f0efff !important;
    border-color: #007bff !important;
    box-shadow: none !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:focus {
    color: #007bff !important;
    background-color: #f0efff !important;
    border-color: #007bff !important;
    box-shadow: none !important;
}

.desktop-home-project-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desktop-home-project-formik-add-project-button-secondary .btn-primary {
    border: 1px solid #cacaca !important;
    color: #545454 !important;
    background-color: #fff !important;
    width: 185px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: none; */
    /* outline: none; */
    /* box-shadow: none; */
    text-align: left;
}

.desktop-home-project-formik-add-project-button-edit .btn-primary {
    /* width: 220px !important; */
    /* margin-top: 1rem; */
    text-align: left;
}

.desktop-home-project-formik-add-project-button .dropdown-toggle::after {
    /* display: none; */
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.desktop-home-project-formik-add-project-button .dropdown-menu.show {
    padding: 0 !important;
    width: 100%;
}

.desktop-home-project-formik-add-project-button-secondary .dropdown-toggle::after {
    /* display: none; */
    float: right;
    margin-top: 10px;
}

.desktop-home-project-formik-add-project-button-error .btn-primary {
    border: 1px solid red !important;
}

.desktop-home-project-formik-add-button {
    font-size: 15px !important;
}
.task-tracker-worktimeline-tracked{
    background-color: #17a2b8;
    padding: 2px 6px 2px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
}
.task-tracker-worktimeline-untracked{
    background-color: #80808075;
    padding: 2px 6px 2px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
}
.desktop-home-project-card-root-list-section-body-worktimeline {
    /* height: 340px;
    overflow-y: scroll; */
    margin: 0rem 0rem 0 0rem;
    min-height: 100vh;
}
.desktop-home-project-card-root-list-section-body {
    /* height: 340px;
    overflow-y: scroll; */
    margin: 2rem 1rem 0 1rem;
    min-height: 100vh;
}

.task-tracker-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}

.desktop-home-project-card-root-list-section {
    margin-top: 1rem;
    border: 0.5px solid rgba(0, 0, 0, .08);
    /* padding: 1rem; */
    background: #fff;
}

.task-tracker-date-td {
    padding-bottom: 0px !important;
}

.desktop-home-project-card-root-list-section-heading {
    color: darkgray;
    margin-bottom: 0;
    font-size: 17px;
}


/* .desktop-home-project-card-root-list-section-header {
    display: flex;
    align-items: center;
} */

.desktop-home-project-list-section-button {
    margin-left: auto;
    margin-right: 0;
    /* border: 1px solid #007bff;
    color:#007bff; */
    font-size: 15px;
}

.desktop-home-project-list-section-button:active {
    background-color: none;
}


/* .desktop-home-project-list-section-button:hover {
    box-shadow: 2px 2px 6px #6c757d1c;
    background-color: #6c757d1c;
    color: #6c757d;
} */

.task-tracker-export-header {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    margin-top: 35px;
    margin-bottom: -70px;
}
.task-tracker-filter-download-icon-btn{
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    margin-top: 35px;
    margin-bottom: -73px;
    align-items: center;
}

.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
    /* margin-bottom: 0.9rem; */
    /* margin-top: 0.5rem; */
}

.desktop-home-project-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    /* table-layout: fixed;
    width: 100%; */
}

.desktop-home-project-card-table-description {
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;

    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    /* cursor: pointer; */
    margin-top: 4px;
}
.task-tracker-data-td-descrption-head{
width:170px !important;
}
.desktop-home-project-card-table-description span {
    cursor: pointer;
    color: #18181885;
    font-size: 12px;
    letter-spacing: -.2px;
    line-height: 16px;
    margin-top: 3px;
    outline: none;
    width: 80%;
    border: none;
    resize: none;
    padding: 2px 4px;
    background-color: #c0c0c045;
    border-radius: 2px;
}

.desktop-home-project-card-table-description span:hover {
    text-decoration: underline;
}

.desktop-home-project-card-table thead th {
    border: none;
}

.desktop-home-project-card-table th,
.desktop-home-project-card-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem 0.7rem;
}

.desktop-home-project-card-table-header {
    color: gray;
    border: none;
    font-size: 15px;
}

.desktop-home-project-add-card-formik-timer-selected {
    color: #007bff;
}

.desktop-home-project-card-table-body {
    color: #333;
}

.desktop-home-project-card-table-data-details {
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 15px;
    /* box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important; */
    box-shadow: -1px 4px 3px -6px #333;
    background-color: #fff!important;
}

.desktop-home-project-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.desktop-home-project-card-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.desktop-home-project-card-table-name span {
    cursor: pointer;
}


/* 
.desktop-home-project-card-table-name span:hover {
    text-decoration: underline;
} */

.desktop-home-project-card-table-display-name {
    width: 15ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.desktop-home-project-card-table-ongoing {
    font-weight: 600;
    color: darkgreen;
}

.desktop-home-project-card-table-ongoing-others {
    color: darkgreen;
}

.desktop-home-project-card-table-data-details-selected-color {
    background-color: #dbfbe7!important;
}

.desktop-home-project-card-table-stop {
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.desktop-home-project-card-modal-edit-header-name {
    font-size: 1rem;
}

.desktop-home-project-card-table-modal-delete {
    color: red;
    margin-top: 9px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
}

.desktop-home-project-card-table-modal-delete:hover {
    text-decoration: underline;
}

.desktop-home-project-card-table-modal-delete:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-split {
    color: #007bff;
    font-size: 14px;
    margin-top: -15px;
    /* width: 93px; */
}

.desktop-home-project-card-table-modal-split-text {
    color: #007bff;
    font-size: 13px;
    margin-top: -23px;
    margin-left: -4px;
}

.desktop-home-project-card-table-modal-split-text:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-split:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-description {
    width: 45%;
    margin-right: 10px;
    font-size: 13px;
}

.desktop-home-project-card-table-modal-description .form-control {
    font-size: 16px;
}

.desktop-home-project-card-table-modal-dropdown {
    width: 16%;
    margin-right: 10px;
    font-size: 13px;
}

.desktop-home-project-card-table-modal-dropdown .form-control {
    font-size: 16px;
}

.desktop-home-project-card-modal-edit-save-button {
    margin-right: 10px;
    font-size: 14px;
}

.desktop-home-project-card-modal-edit-delete-button {
    font-size: 14px;
    /* float: right;
    margin-right: 47px; */
}

.desktop-home-project-card-modal-edit-body-header {
    color: #868686;
    font-size: 15px;
    display: flex;
    margin-bottom: 1rem;
}

.task-tracker-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}

.task-tracker-filter-user-select div {
    /* background-color: #fcfcfc !important; */
}

.task-tracker-filter-div {
    display: flex;
    align-items: center;
}

.task-tracker-edit-label {
    font-size: 14px;
    font-weight: 500;
    color: #656565;
}

.task-tracker-edit-label-date {
    font-size: 14px;
    font-weight: 500;
    color: #656565;
    margin-left: 1rem;
}

.task-tracker-filter-whole-div {
    display: flex;
    align-items: center;
}

.desktop-home-project-card-modal-edit-body-header-description {
    width: 46%;
    margin-right: 8px;
    margin-left: -4px;
}

.desktop-home-project-card-modal-edit-body-header-dropdown {
    width: 16%;
    margin-right: 10px;
}

.desktop-app-edit-task-modal-root .modal-dialog {
    /* max-width: 595px !important; */
}

.desktop-home-manual-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: .5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.desktop-home-manual-add-card-formik {
    display: flex;
    justify-content: space-evenly;
}

.desktop-home-manual-add-card-formik .form-group {
    margin-bottom: 0rem !important;
}

.desktop-home-manual-add-card-formik-text-box {
    width: 35%;
}

.desktop-home-project-formik-add-project-button-manual .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
}

.desktop-home-manual-add-card-formik .form-control {
    font-size: 15px !important;
}

.desktop-home-manual-card-table-whole-div-fixed {
    /* overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 307px); */
}

.manual-err {
    font-size: 10px;
    color: red;
    float: right;
}

.manual-page-content-wrapper-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.desktop-home-manual-card-list-section-header-div {
    display: block;
}

.manual-page-content-wrapper-dropdown-section {
    width: 132px;
}

svg.workfolio-manual-section-calender-icon {
    font-size: 20px;
    text-align: center;
    color: #87898b;
    cursor: pointer;
}

.workfolio-manual-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}

.project-tooltip-start-priority .tooltip-inner {
    width: -moz-fit-content;
    width: fit-content;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: white;
    position: sticky;
    z-index: 1 !important;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    font-size: 14px;
}

.project-tooltip-start-priority .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}

.project-tooltip-start .tooltip-inner {
    min-width: 200px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: white;
    position: sticky;
    z-index: 1 !important;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    font-size: 14px;
}

.project-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}

.manual-page-content-wrapper-dropdown-section .form-control {
    color: #007bff;
}

.desktop-home-manual-card-list-section {
    margin-top: 1rem;
}

.desktop-home-manual-card-list-section-header {
    /* color: darkgray; */
    margin-bottom: 0;
    font-size: 16px;
}

.workfolio-manual-section-tablecalender-text {
    margin-bottom: 0;
    font-size: 14px;
    color: blue;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 2px;
    /* text-decoration: underline;
    font-weight: 600; */
}

.workfolio-manual-section-tablecalender-text:hover {
    text-decoration: underline;
}

.desktop-home-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.desktop-home-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}

.desktop-home-manual-card-table-body {
    color: #333;
}

.desktop-home-manual-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}

.desktop-home-manual-card-table-description {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.manual-table-data-icon {
    color: red;
    /* font-size: 17px; */
    cursor: pointer;
}

.manual-table-data-icon:hover {
    text-decoration: underline;
}

.desktop-home-manual-card-table thead th {
    border: none;
}

.desktop-home-manual-card-table th,
.desktop-home-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem;
}

.desktop-home-manual-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

svg.workfolio-manual-pages-calender-icon {
    font-size: 20px;
    text-align: center;
    margin-top: 8px;
    color: #87898b;
    cursor: pointer;
}

.project-and-task-section-second-menu-filter {
    /* position: relative; */
    /* display: flex; */
    /* flex-direction: column;
    min-width: 0; */
    /* word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;  */
    border: 1px solid #c6d2d9;
    border-radius: 2px;
    padding: 10px;
    margin: 0rem 0rem 0 0rem;
}

.project-and-task-section-second-menu-filter .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #fff;
    color: #266dfb;
}

.project-and-task-section-second-menu-filter-button {
    margin-left: auto;
    margin-right: 0;
    /* margin-top: 3px; */
    /* padding: 5px 5px !important;
    height: 35px; */
}

.project-and-task-section-second-menu-filter-user-select {
    width: 200px;
    /* border-right: 1px solid #c6d2d9; */
}

.project-and-task-section-second-menu-filter-user-select-split-div {
    border-right: 1px solid #c6d2d9;
    margin: 0 10px;
}

.project-and-task-section-second-menu-filter-user-select .dropdown-container {
    /* border: none !important; */
    background-color: #fcfcfc !important;
}

.project-and-task-section-second-menu-filter-user-select .rmsc .dropdown-container:focus-within {
    box-shadow: none !important;
}
/* .task-tracket-task-list-start-end-time{
    width: 172px !important;
} */

@media screen and (min-device-width: 1024px) and (max-device-width: 1350px) and (-webkit-min-device-pixel-ratio: 1) {
    .task-tracker-filter-whole-div {
        display: contents;
    }
    .task-tracker-filter-div {
        display: contents;
    }
    .task-tracker-filter-div-small-screen {
        display: flex;
    }
    .task-tracker-filter-div-small-screen-button {
        padding: 0.375rem 0.75rem 0.375rem 0.75rem;
        line-height: 19px;
        height: 38px;
        /* margin-left: 0; */
        margin-right: 10px;
    }
    .project-and-task-section-second-menu-filter {
        padding: 1rem;
        margin-top: 1rem;
    }
    .task-tracker-filter-div-small-screen-select {
        width: 318px !important;
        margin-bottom: 0px !important;
    }
    .task-tracker-filter-user {
        width: -webkit-fill-available;
        margin-bottom: 15px;
    }
    .desktop-home-project-add-card-formik-timer .form-group {
        margin-bottom: 0rem !important;
        width: 50%;
    }
    .task-tracket-task-list-start-end {
        max-width: 121px;
    }
    .task-tracket-task-list-start-end-time {
        max-width: 120px;
        line-height: 25px;
    }
    
    .desktop-home-project-card-table-name {
        max-width: 150px;
    }
    .desktop-home-project-card-table-description {
        max-width: 150px;
    }
    .desktop-home-manual-add-card-formik {
        display: initial;
        justify-content: space-evenly;
    }
    .desktop-home-manual-add-card-formik-responsive {
        display: flex;
    }
    .desktop-home-manual-add-card-formik-text-box {
        width: 55%;
        margin-right: 15px;
        margin-left: 9px;
    }
    .desktop-home-manual-add-card-formik .form-group {
        margin-bottom: 1rem !important;
    }
    .desktop-home-project-formik-add-project-button {
        margin-right: 15px;
    }
    .manual-page-content-wrapper-filter-date-date-section {
        width: 220px;
        margin-right: 15px;
    }
    .manual-page-end-time-responsive {
        width: 158px;
        margin-left: 9px;
    }
}

.task-tracker-header-tab {
    font-size: 16px;
    color: #00000087;
    font-weight: 500;
}

.edit-setting-card-table-data-mark-as-complete {
    width: 19%;
    cursor: pointer;
}

.edit-setting-card-table-data-mark-as-complete-button {
    color: rgba(28, 77, 191, .788235294117647);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
}

.task-tracker-edit-menus #dropdown-basic {
    background-color: transparent;
    color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    right: 0px;
    /* margin-right: 1.5rem; */
}

.task-tracker-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}

.task-tracker-edit-menus-icon {
    color: #00000096;
    float: right;
    font-size: 1rem;
    margin-top: -29%;
}

.desktop-home-project-card-table-upcoming-tasks-display-name {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desktop-home-project-card-table-display-name-not-assigned {
    opacity: 0.4;
}

.task-tracket-task-list-due-not-added {
    opacity: 0.4;
}

.project-details-task-card-delete-body-name {
    color: black;
}

.project-details-task-card-delete-body-text {
    color: #4e4d4d;
}

.desktop-home-project-card-table-data-details-ongoing {
    background-color: #dbfbe7 !important;
}

.desktop-home-project-card-task-tracker-table-description-priority {
    font-size: 12px;
    /* background-color: #e3e3e7; */
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: gray;
}
.work-timeline-view-detail-color{
    color:#80808094 !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-root {
    /* margin-top: 3px;
    margin-bottom: 3px; */
    margin-top: 1px;
}

.desktop-home-project-card-task-tracker-table-description-priority-date-data {
    display: flex;
    grid-gap: 18px;
}

.desktop-home-project-card-task-tracker-table-description-priority-low {
    color: green !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-medium {
    color: #eca90f !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-high {
    color: #ed210e !important;
}

.desktop-home-project-card-task-tracker-table-description-priority {
    text-transform: capitalize;
}

.btn-outline-info:hover {
    color: #fd7e14;
    background-color: #fd7e142b;
    border-color: #fd7e14;
}

.btn-outline-danger:hover {
    color: #ea2d40;
    background-color: #dc35451f;
    border-color: #dc354585;
}

span.desktop-home-project-card-table-project-name {
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.desktop-home-project-card-table-task-name {
    font-weight: 500;
    font-size: 15px;
    color: #333c;
}

.desktop-home-project-card-task-tracker-table-description-priority-icon {
    color: gray;
    font-size: 13px;
   
}

svg.desktop-home-project-card-task-tracker-table-description-date-icon {
    color: #666464;
    font-size: 12px;
    /* margin-top: -1px; */
}

.task-tracket-task-list-start-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #15a915b0;
    border-color: #41B840;
}

.task-tracket-task-list-stop-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #e92d2ddc;
    border-color: #e92d2ddc;
}

.task-tracket-task-list-start-again-icon {
    font-size: 25px;
    margin-top: -1px;
    margin-right: 5px;
}

.manual-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.desktop-home-project-card-table-list-task-name {
    width: 357px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}

.desktop-home-project-card-table-list-task-name span {
    cursor: pointer;
}

.desktop-home-project-card-modal-edit-button-root {
    margin-top: 2rem;
}

.desktop-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    margin-left: 1rem;
    color: red;
}

.desktop-home-project-formik-edit-end-time .rc-time-picker-input {
    border-color: #dc3545;
}

.desktop-home-project-card-modal-edit-button-root {
    display: flex;
    justify-content: space-evenly;
}

.desktop-home-project-card-modal-edit-save-button-hide,
.desktop-home-project-card-modal-edit-delete-button-hide {
    visibility: hidden;
}

.task-tracker-edit-modal-row.row {
    margin-bottom: 16px;
}

.desktop-home-project-card-modal-edit-save-button-disable {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #17a2b8;
    border-color: #17a2b8;
    margin-right: 10px;
    font-size: 14px;
}

.desktop-home-project-formik-add-form-error-message-row {
    text-align: center;
}

.desktop-home-project-formik-add-form-error-message {
    color: red;
}

.desktop-home-project-card-modal-edit-save-button:hover {
    color: none !important;
    background-color: none !important;
    border-color: none !important;
}

a.edit-task-history.dropdown-item:active {
    background-color: #fff;
    color: #000;
}

.desktop-home-project-formik-add-project-button .dropdown-menu {
    min-width: 20rem !important;
}

.desktop-home-project-task-tracker-content-area {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 307px);
}

.task-tracker-offline-task-delete-modal {
    background-color: #000000a6;
}

.desktop-home-project-card-table-list-task-name span {
    cursor: pointer;
}

.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline-new {
    width: 300px !important;
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 500px !important;
}

.desktop-home-project-card-table-list-timeline-task-name {
    width: 455px !important;
}
.worktimelin-cursor{
    cursor: pointer;
}
td.task-tracker-data-td-task-name.task-tracker-data-td-task-name-upcomingTasks {
    width: 666px !important;
}

.desktop-home-project-card-table-list-task-name-upcomingTasks {
    width: 590px !important;
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 400px !important;
}

.desktop-home-project-card-table-name-upcomingTasks {
    width: 600px !important;
}

.task-tracker-data-td-task-name-inProgressTasks {
    width: 540px !important;
}

.desktop-home-project-card-table-list-task-name-inProgressTasks {
    width: 489px !important;
}
.task-tracker-header-tab-tooltip-name {
    color: #6a6565b8;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 13px;
}
.task-tracker-header-tab-tooltip-name-span{
    margin-right: 4px;
    margin-top: -6px;
    font-size: 13px;
}
.desktop-home-project-card-table-name-inProgressTasks {
    width: 473px !important;
}

.task-tracker-data-td-task-name-onDue {
    width: 606px !important;
}

.desktop-home-project-card-table-list-task-name-onDue {
    width: 533px !important;
}

.desktop-home-project-card-table-name-onDue {
    width: 535px !important;
}

.task-tracker-data-td-task-name-completed {
    width: 552px !important;
}

.desktop-home-project-card-table-list-task-name-completed {
    width: 490px !important;
}

.desktop-home-project-card-table-name-completed {
    width: 500px !important;
}

.task-tracker-data-td-task-name-hold {
    width: 612px !important;
}

.desktop-home-project-card-table-list-task-name-hold {
    width: 548px !important;
}

.desktop-home-project-card-table-name-hold {
    width: 550px !important;
}

.team-activites-root {
    padding: 1rem;
}

.team-activites-root-count-whole-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.team-activites-root-count-sub-div {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    text-align: center;
}

.team-activites-root-count-div-total-time-text {
    font-size: 1.1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #343a40!important;
}

.team-activites-root-table-text {
    margin-top: 1rem;
}

.team-activities-table-root-progress-bar {
    width: 500px;
}

.team-activities-table-root {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background-color: #fff;
    padding: 1rem 1.5rem;
}

.team-activites-root-filter {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
}

.team-activites-root-filter-button .btn-primary {
    border: none;
    margin-right: 0rem;
    width: auto;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}

.team-activites-root-filter-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.team-activites-root-filter-button .btn-primary:hover {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activites-root-filter-button .btn-primary:active {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activites-root-filter-button .btn-primary:focus {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activity-project-details-chart-title {
    padding: 9px 0px 0 8px;
    display: flex;
    grid-gap: 1rem;
    grid-auto-flow: column;
    align-items: center;
}

.team-activity-project-details-chart-root {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background-color: #fff;
    padding: 1rem 1rem;
    margin-top: 1rem;
}

.team-activity-h6 {
    margin-bottom: 0;
}

.team-activites-root-count-div-total-time-text-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30ch;
}
.manual-time-project-formik-add-button{
    height: -moz-fit-content;
    height: fit-content;
}
.task-tracker-filter-user-select .__menu,.task-tracker-filter-user-select .User__menu {
    color: hsl(0,0%,20%);
}
.project-detail-task-detail-modal .modal-content{
    /* background-color: rgb(252, 252, 252); */
    height: 680px;
    overflow-x: hidden;
}
.project-detail-task-detail-modal-title.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.project-detail-task-detail-modal-title-project-name {
    font-size: 13px;
    color: gray;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 1rem;
}
.project-details-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.project-details-task-details-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
    /* margin-left: 2rem; */
    /* background-color: white; */
    background-color: rgb(252, 252, 252);
}
.project-details-task-details-employee-list-card-body {
    padding: 0 1.3rem 1rem;
}
.project-details-task-details-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-details-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-details-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-details-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 1px 0px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-details-employee-list-card-details p {
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-detail-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-details-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-detail-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 670px;
    overflow-y: scroll;
}
.project-detail-task-detail-tab .nav-tabs > a {
    color: #212529;
    font-size: 14px;
    padding: 7px 8px;
    font-weight: 500;
}
.project-detail-task-detail-tab .nav-tabs > a.active {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs .nav-link:hover {
    border: none;
    color: #157af6b5;
}
.project-detail-task-detail-tab .nav-tabs .nav-link.active:hover {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs{
    padding: 0 1rem;
}
.project-detail-task-detail-modal-header{
    padding: 1rem;
    /* border-bottom: none !important; */
    margin: 0 0 0.1rem;
}
.project-detail-task-detail-tab{
    /* margin: 0rem 1rem 1rem 1rem; */
}
.project-detail-task-detail-edit-modal-body-container {
    margin: 2rem 1rem;
}



.project-detail-task-detail-edit-modal-body-container-timeline {
    margin: 0rem 1rem;
}
.project-details-task-timeline-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 0.5px solid #00000014; */
    /* margin-left: 2rem; */
    /* background-color: white; */
    /* background-color: rgb(252, 252, 252); */
}
.project-details-task-timeline-employee-list-card-body {
    /* padding: 0 1.3rem 1rem; */
}
.project-details-task-timeline-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-timeline-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-timeline-employee-list-card-data-table th, .project-details-task-timeline-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-timeline-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
    /* margin-bottom: 0.9rem; */
    /* margin-top: 0.5rem; */
}
.project-details-task-timeline-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-timeline-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 1px 0px 0 rgb(0 0 0 / 6%);
}
.project-details-task-timeline-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-timeline-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-timeline-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-timeline-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.project-details-task-timeline-date-divider-label-icon {
    margin-right: 10px;
    /* color: #495057eb; */
}
.project-details-task-timeline-employee-list-card-details-avatar p{
    margin-bottom: 0rem;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
    align-items: center;
}

.timeline-container::after {
    background-color: #80808030;
    content: '';
    position: absolute;
    left: calc(23% - (-4px));
    width: 4px;
    height: 100%;
}
.timeline-item {
    /* display: flex;
    justify-content: flex-end; */
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 53%;
}

.timeline-item:nth-child(odd),.timeline-item:nth-child(even) {
    /* align-self: flex-end;
    justify-content: flex-start; */
    padding-left: 30px;
    padding-right: 0;
}
.timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(252, 252, 252);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 13px;
    position: relative;
    width: 430px;
    max-width: 100%;
    text-align: right;
}

.timeline-item-content::after {
    content: ' ';
    background-color: rgb(252, 252, 252);
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}
.timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item:nth-child(even) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item-content time {
    color: #777;
    font-size: 12px;
    font-weight: bold;
}

.timeline-item-content p {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.timeline-item-content a {
    font-size: 14px;
    font-weight: bold;
}

.timeline-item-content a::after {
    content: ' ►';
    font-size: 12px;
}

.timeline-item-content .circle {
    background-color: #fff;
    border: 3px solid #80808030;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timeline-item-content time {
        margin-top: 20px;
    }

    .timeline-item-content a {
        text-decoration: underline;
    }

    .timeline-item-content a::after {
        display: none;
    }
}
p.project-details-task-activity-history-event-text {
    display: flex;
}
.project-details-task-activity-history-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-activity-history-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-activity-history-card-avatar {
    height: 25px;
    width: 25px;
    background-color: #ededed;
    color: #424242;
    font-weight: bold;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 25px;
}
.task-tracker-date-header-tr{
    margin-top: 20px;
    margin-bottom: 10px;
}
.task-tracker-date-header-first-tr{
    margin: 10px 0px;
}
.project-details-task-details-card h4 {
    font-size: 17px;
}


.project-detail-manual-task-detail-modal .modal-content {
    /* background-color: rgb(252, 252, 252); */
    /* height: 680px; */
    overflow-x: hidden;
}
.project-detail-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 300px;
    overflow-y: scroll;
}
/* .project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
   margin: 2rem;
} */
.project-details-manual-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-manual-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project-details-manual-task-details-card h4 {
    font-size: 20px;
}
.project-details-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 250px;
    overflow-y: scroll;
}
.project-details-manual-task-detail-edit-modal-body-container {
    margin: 2rem;
}
.project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-manual-task-detail-edit-modal-body-reviewed{
    height:365px !important;
}
.project-task-detail-track-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 600px;
    overflow-y: scroll;
}
.add-task-task-details-load-more-button{
    text-align: center;
}

.project-task-time-mapping-modal-app-website-usage {
    padding: 25px 30px 0;
    height: 500px;
    /* overflow: hidden; */
    overflow-y: scroll;
}
.project-task-time-mapping-modal-app-website-usage-header {
    height: 38px;
    min-width: 100%;
    overflow-x: hidden;
    background: 0 0;
    border-bottom: 0px solid #e8ebee;
    border-top: 0px solid #e8ebee;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 300px;
    max-width: 300px;
}
.project-task-time-mapping-modal-app-website-usage table td, .project-task-time-mapping-modal-app-website-usage table th {
    vertical-align: middle !important;
}
.project-task-time-mapping-modal-app-website-usage-table-content {
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    color: #34495e;
    padding-top: 1px;
    border-left: 1px solid #e8ebee;
    border-top: 1px solid #e8ebee;
    border-bottom: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgb(53 59 72 / 5%);
}
.project-task-time-mapping-modal-app-website-usage-card-icon {
    height: 24px;
    width: 24px;
}

.project-task-time-mapping-modal-ScreenShots-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    grid-gap: 0.8rem;
}
.project-task-time-mapping-modal-screenshot-card {
    /* margin: 1rem auto; */
    margin-bottom: -1%;
    /* max-width: 245px; */
    width: 100%;
    padding: 1rem;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    border: 1px solid #d3d3d3;
    /* border: 1px solid #17b5ab; */
    border-radius: 3px;
}
.project-task-time-mapping-modal-screenshot-card-header {
    display: flex;
    align-items: center;
}
.project-task-time-mapping-modal-screenshot-card-header-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}
.project-task-time-mapping-modal-screenshot-card-header-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* color: #34495e; */
    font-weight: 400;
    flex-grow: 1;
    font-size: 12px;
}
.project-task-time-mapping-modal-screenshot-card-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    margin: 12px 0px;
}
.project-task-time-mapping-modal-screenshot-card-thumbnail-image {
    width: 100%;
    height: 73px;
    background-color: #e8ebee;
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
    font-weight: 600;
    font-size: 14px;
    /* line-height: 16px; */
    /* color: #718093; */
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar-timer {
    margin-right: 12px;
    display: flex;
    align-items: center;
    /* flex-shrink: 0; */
    /* color: #718093; */
    font-size: 10px;
    /* margin-left: auto; */
    margin-right: 0px;
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar-timer-time {
    font-size: 10px;
    margin-left: 4px;
}

.project-task-time-mapping-modal-screenshot {
    background-color: #00000099;
}
.project-task-time-mapping-modal-screenshot .modal-dialog {
    max-width: 50% !important;
    margin-top: 4rem;
}
.project-task-time-mapping-modal-screenshot .modal-dialog .modal-content {
    background-color: white;
}
.project-task-time-mapping-modal-screenshot-close {
    position: absolute;
    right: -88px;
    padding: 0 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    color: white;
    margin: 0.2rem;
    opacity: 0.6;
    top: -77px;
}
svg.project-task-time-mapping-modal-screenshot-right-nav {
    position: absolute;
    right: 0;
    top: 46%;
    bottom: 0;
    color: white;
    right: -10%;
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0.4;
    cursor: pointer;
    z-index: 1;
}
.project-task-time-mapping-modal-screenshot-body {
    text-align: center;
    margin: 1.5rem;
}
.project-task-time-mapping-modal-screenshot-personal-header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}
.project-task-time-mapping-modal-screenshot-personal-header-details {
    display: flex;
}
.project-task-time-mapping-modal-screenshot-site-icon-content {
    height: 30px;
    width:30px;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #5b5c5f21;
}
.project-task-time-mapping-modal-screenshot-site-icon {
    height: 14px;
    vertical-align: initial;
    margin-top: 8px;
}
.project-task-time-mapping-modal-screenshot-site-icon-details {
    margin-top: 2px;
    text-align: left;
}
.project-task-time-mapping-modal-screenshot-site-name {
    font-weight: 600;
    font-size: 15px;
    color: #000000a6;
}
.project-task-time-mapping-modal-screenshot-user-name {
    font-size: 14px;
    float: left;
    color: #f1306f;
    margin-top: -3px;
}
.project-task-time-mapping-modal-screenshot-time-details {
    font-size: 15px;
    margin-right: 11px;
}
svg.project-task-time-mapping-modal-screenshot-time-icon {
    font-size: 14px;
    margin-right: 4px;
    margin-top: -2px;
}

.project-task-time-mapping-modal-screenshot-screenshot-image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
}
svg.project-task-time-mapping-modal-screenshot-left-nav {
    position: absolute;
    color: #fff;
    top: 46%;
    left: -10%;
    bottom: 0;
    width: 1.2rem;
    height: 1.2rem;
    opacity: .4;
    cursor: pointer;
    z-index: 1;
}
.project-task-time-mapping-modal-screenshot-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.project-task-time-mapping-modal-screenshot-load-more-button button {
    font-size: 13px;
}
.task-tracker-task-details-card-description{
    margin-bottom: 1.5rem;
}
.task-tracker-task-detail-modal-header {
    padding: 1rem;
    /* border-bottom: none !important; */
    margin: 0 0 0.1rem;
}
.task-tracker-task-detail-modal-title.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.task-tracker-task-detail-modal-title-new.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.task-tracker-task-detail-modal-title-project-name {
    font-size: 13px;
    color: gray;
}
.task-tracker-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 360px;
    overflow-y: scroll;
}
.task-tracker-task-detail-edit-modal-body-completed{
    height: 360px !important;
}
.task-tracker-task-detail-tab {
    /* margin: 0rem 1rem 1rem 1rem; */
}
.task-tracker-task-detail-edit-modal-body-container {
    margin: 2rem;
}
.task-tracker-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.task-tracker-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.task-tracker-task-details-card-title {
    font-size: .85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.task-tracker-task-details-card h4 {
    font-size: 17px;
}
.task-tracker-task-details-card-value-none {
    opacity: 0.7;
}
.task-tracker-task-details-card-value-due-crossed {
    color: #ea4c4cf0 !important;
}
.task-tracker-project-card-task-tracker-table-description-priority-root {
    margin-top: 6px;
    float: right;
}

.task-tracker-project-card-task-tracker-table-description-priority-root {
    margin-top: 6px;
    float: right;
}
.task-tracker-task-status-badge {
    float: left;
    margin-top: 9px;
    padding-top: 4px;
    font-size: 11px;
}
.desktop-home {
    height: calc(100vh - 55px - 32px);
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 1rem;
}

.desktop-home>*:last-child {
    flex: 1 1;
    flex-grow: 1;
    /* overflow: auto; */
}

.dh-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: 0.5rem;
    margin: 0.5rem 0 0.5rem;
}

.dh-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-gap: 1rem;
    flex: 1 1;
}

.dh-column {
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #dedede;
    height: 100%;
    padding: 1rem;
    padding-top: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.dh-column .dh-lane-header {
    background-color: #fff;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dh-column.from-widget {
    height: 100%;
    border-radius: 0;
    background-color: #fafafa;
    border: 1px solid #fafafa;
}

.dh-column.from-widget .dh-lane-header {
    background-color: #fafafa;
}

.dh-column.primary,
.dh-column.primary .dh-lane-header {
    background-color: #b0c2c2;
}

.dh-column .dh-lane-content {
    flex: 1 1;
    flex-grow: 1;
}

.dh-project-card {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    transition: all 100ms linear;
}

.dh-project-card:hover {
    background-color: #007bff10;
    border: 1px solid #007bff10;
}

.dh-project-card.selected {
    background-color: #007bff15;
    border: 1px solid #007bff80;
}

.dh-project-card .pc-avatar {
    width: 40px;
    height: 40px;
    background-color: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    line-height: 40px;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    margin-right: 10px;
    text-transform: uppercase;
}

.dh-project-card .pc-content {
    flex: 1 1;
    overflow: hidden;
}

.dh-project-card .pc-header {
    display: flex;
    align-items: center;
}

.dh-project-card .pc-project-name {
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.8;
    white-space: nowrap;
    flex: 1 1;
    margin: 0;
}

.dh-project-card .pc-worked-time {
    width: -moz-fit-content;
    width: fit-content;
    font-size: smaller;
}

.dh-project-card .pc-project-status {
    color: #333333;
    font-size: smaller;
    float: left;
}

.dh-project-card .pc-project-status.selected {
    color: #007bff;
}

.dh-project-card .pc-task-count {
    color: #333333;
    font-size: smaller;
    float: right;
    text-align: right;
}

.dh-project-card .pc-arrow {
    font-size: medium;
    margin-bottom: 1px;
    margin-right: -5px;
}

.dh-timeline-card {
    position: relative;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px 12px;
}

.dh-timeline-card:hover {
    background-color: #007bff10;
}

.dh-timeline-card.is-new {
    background-color: #ffffff70;
    border: 2px dashed rgba(35, 24, 24, 0.5);
}

.dh-timeline-card .tc-add-task,
.dh-timeline-card .tc-task-actions,
.tsk-new-actions {
    position: absolute;
    right: 0;
    z-index: 1;
}

.dh-timeline-card .tc-add-task {
    left: 0;
    width: 100%;
    bottom: -12.5px;
}

.dh-timeline-card .tc-add-task button {
    border: 1px solid #dedede;
    margin: auto;
    padding: 0.15rem;
}

.dh-timeline-card .tc-task-actions,
.tsk-new-actions {
    top: 0;
    height: 100%;
    right: -12.5px;
}

.dh-timeline-card .tc-task-actions>*,
.tsk-new-actions>* {
    display: flex;
    margin: 2.5px 0;
    padding: 0.15rem;
    border: 1px solid #dedede;
}

.dh-timeline-card .tc-task-actions>*,
.tc-task-actions.single>*:first-child {
    transform: translateY(calc(50% + 10px));
}

.dh-timeline-card .tc-data,
.dh-timeline-card .tc-meta {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    line-height: 1.8;
    flex: 1 1;
}

.dh-timeline-card .tc-meta {
    font-size: small;
    margin-bottom: 5px;
}

.dh-timeline-card .tc-meta>*:not(:first-child) {
    width: -moz-fit-content;
    width: fit-content;
    display: inline-flex;
    align-items: center;
}

.dh-timeline-card .tc-meta>*:not(:last-child):not(:first-child) {
    margin-right: 10px;
}

.dh-timeline-card .tc-meta>*>*:first-child {
    margin-right: 2px;
}

.dh-timeline-card .tc-task-count,
.dh-timeline-card .tc-task-name,
.dh-timeline-card .tc-project-name {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333333;
    margin: 0;
}

.dh-timeline-card .tc-project-name {
    text-align: right;
}

.dh-timeline-header {
    color: #333333;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 2;
    margin: 6px 0 6px;
}

.dh-timeline-header:not(:first-of-type) {
    margin-top: 12px;
}

.dh-lane-header {
    display: flex;
    flex-wrap: nowrap;
    color: #333333;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 3;
}

.dh-lane-header.my-timeline {
    padding: 1rem 1rem 0.5rem 1rem;
    margin: 0 -1rem;
}

.dh-lane-header .lh-main {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dh-lane-header>*:not(.lh-main) {
    width: -moz-fit-content;
    width: fit-content;
}

.lh-title,
.lh-subtitle {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lh-subtitle {
    color: #6c757d;
    font-size: 0.9rem;
}

.lh-title {
    font-weight: 500;
}

.lh-cta,
.lh-back {
    cursor: pointer;
}

.dh-lh-tasks-cta {
    border-radius: 5px;
    color: #333333;
    display: inline-flex;
    align-items: center;
}

.dh-lh-tasks-cta svg {
    margin: 0 2px 2px -2px;
    font-size: 1rem;
}

.dh-task-card {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px 12px;
    margin-bottom: 8px;
    position: relative;
    transition: all 200ms ease;
}

.dh-task-card-active-wrapper:not(.active) .dh-task-card:hover {
    background-color: #007bff10;
}

.dh-task-card.active {
    margin-bottom: 14px;
}

.dh-task-card .tsk-header {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: space-between;
}

.tsk-header .tsk-task-name {
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0.95 1;
    margin: 0;
}

.dh-task-card.active .tsk-task-name {
    font-weight: bold;
}

.tsk-header .tsk-task-status {
    background: none;
    cursor: pointer;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    font-size: smaller;
    margin: 0;
    padding: 0;
}

.dh-task-card .tsk-footer {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    margin-top: 5px;
}

.dh-task-card.active .tsk-footer {
    position: absolute;
    justify-content: center;
    left: 0;
    width: 100%;
    bottom: -14px;
}

.dh-task-card.active .tsk-footer>button {
    margin: 0 10px;
}

.dh-task-card-active-wrapper {
    transition: padding-top 0.2s linear, padding-bottom 0.2s linear;
}

.dh-task-card-active-wrapper.active {
    background-color: #c3e7b7;
    border: 2px solid #28a745;
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    transition: background-color 0.2s linear, padding-top 0.2s linear, padding-bottom 0.2s linear;
}

.dh-task-card-active-wrapper.active.start {
    background-color: #c3e7b7;
    border: 2px solid #28a745;
}

.dh-task-card-active-wrapper.active.break {
    background-color: #fff4d3;
    border: 2px solid #facb3d;
}

.tsk-content {
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    line-height: 2;
    margin-bottom: 0.75rem;
    color: #28a745;
}

.tsk-content.start {
    color: #28a745;
}

.tsk-content.break {
    color: #ffc107;
}

.tsk-footer>button {
    font-weight: bold;
    border-radius: 5px;
}

.tsk-footer .tsk-task-time {
    font-size: smaller;
}

.dh-add-task-message {
    text-align: center;
    border-radius: 5px;
    border-style: dashed;
    font-size: smaller;
}

.dh-task-breaks {
    background-color: #b0c2c2;
    padding: 12px;
    margin: 0 -1rem -1rem;
    /* position: absolute;
  bottom: 0;
  width: 100%; */
    position: sticky;
    bottom: -1rem;
    z-index: 3;
}

.dh-tb-header {
    font-weight: bold;
    color: #333333;
    margin: 0;
    margin-bottom: 8px;
}

.dh-tb-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    grid-gap: 8px;
}

.dh-tb-content .dh-tb-item {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 8px;
    color: #333333;
}

.dh-tb-item h6 {
    font-size: small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0.25rem;
    font-weight: bold;
}

.dh-tb-item p {
    font-size: smaller;
    margin: 0;
}

.dh-sg-item {
    text-align: center;
    padding: 16px 12px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 5px;
    height: -moz-fit-content;
    height: fit-content;
}

.dh-sg-item h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.dh-sg-item h6.small {
    font-size: 1.25rem;
}

.dh-sg-item p {
    font-size: smaller;
    margin: 0;
    margin-bottom: 0.25rem;
}

.dh-task-active-text {
    color: #009b37;
    margin: -7px 0 5px;
    text-align: center;
    font-size: smaller;
}

.tc-meta select {
    max-width: 40px;
    margin-left: 2px;
    display: inline;
    padding: 5px;
}

.desktop-app-header {
    position: sticky;
    top: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.5rem;
    z-index: 999;
    -webkit-user-select: none;
    border-bottom: 1px solid #00000005;
}

.desktop-app-header>*:nth-child(2) {
    flex: 1 1;
    color: #6c757d;
    font-size: small;
    text-align: center;
    text-transform: uppercase;
    /* -webkit-app-region: drag; */
}

.desktop-app-header>*:last-child {
    width: 50px;
    /* -webkit-app-region: drag; */
}

.draggable {
    -webkit-app-region: drag;
}

.desktop-app-header .mac-traffic-lights,
.desktop-app-header .traffic-lights {
    display: flex;
    align-items: center;
    width: 50px;
}

.desktop-app-header .traffic-lights {
    width: 40px;
}

.desktop-app-header .mac-traffic-lights {
    justify-content: space-between;
}

.desktop-app-header .traffic-lights {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    margin-right: -0.25rem;
}

.desktop-app-header .traffic-lights .light {
    width: calc(40px / 2);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #79797a;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    transition: all 0.5ms ease;
}

.desktop-app-header .traffic-lights .light svg {
    cursor: pointer;
}

.desktop-app-header .mac-traffic-lights .light {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 12px;
}

.desktop-app-header .traffic-lights .light:hover {
    background-color: #00000010;
    color: #6c757d;
}

.desktop-app-header .mac-traffic-lights .light#close {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAh1JREFUSA21lsFOwkAQhme2YElBExE0Gj2qD+LB4AlPXoyv4AN49gF8BePFk5wkHnwQ8ajRKIqJQkOBdp1/dYlWkCB1Lttud7+Z3Z39p0y/2G2ptKwpKHOkyzJsTWtdxHBmrktT04orTG5luVq9Rf8g40Gd9c3NxYA6h8S0k3Fd7WUy2XQqRY6jzPAwjKjb65HfbrfaQcCk6dSlqYPixcV9nPfDwV1poxzp8DjnZd2ZnDfF/AGNT7TvWkf02vQ7Tb8VKHb2lqqXFfsN7TcHN1sb+yrSh8X8bDadTn0dN/K52+1RvfHSihQfrJxfHtkJfQeIXPb4ZKGQzzqOY7+P1YZhSA9PjZac0a5diXFg9pyDq/n83PS4kccjwEoeG89vrnbXcSZmg3Gg2PNJ4XAGBlgmSeSdkYqk21dL8wVv1IHGox32joO/e3zyiTPrCnkuqSi5/Xu2DIMN6gcLTLAVh3pb8twbNHCSPjDBVpKoq7hESZthClvh+tsbmqQTMMFObuOHRKcgXNCWpA1MsGUddA3hSto+mTWlHT4TVfSTdgClhZwr6LlIrhxIctsEFmQcbGWKheg5JDepVRiWMME2WYRiAT2HUE1qYIAFJljGgVE9KRbQc0juXw1zwUDhsdWtfw+g3ygW0PO/rARzMBcMWwsQaL/g2Kj/tWRaJ7GiTxCuIUXfRwaOVfStE7T93xZRRYjit98WuaC4Q6N+W94ByM4xdG0oqUYAAAAASUVORK5CYII=')
}

.desktop-app-header .mac-traffic-lights:hover .light#close {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAvpJREFUSA29VkFPE1EQnrfbsrUtBYFCNFSIiXjxD0iMpiGxqSc8eTH+BQ9GL9UbxIQbifEHGC+e7EnSA1EucCCaeBQvAhWUCkKhdRe6fc638tbdpWtTE32HfbvvzXwzb+Z7MyvoD6Oczw9LsiZFU06y2JiUMg1xIUSFpxWpiaIgozg8N1fGeqshWi1WcrkzFh1Ok6BbMcOQ8VgsEY1ESNc1R9y2m3TUaFDdNGumZQmS9MKgrkK6VNoM4p0wsJGfmGxK+1kynjBSyXiXEL9Ag4rqW8omVQ/qhwf1mqUJ/c7Zufmi2sPsM7B+Y+Ku1pTT6b7TiWg04pVr+3501KDKzvdaUxOFzKv5WaXgGoDnHOPnQwN9CV3X1X5Hs23b9PXbTo1zdFudxDHgxFxYHwb7+rs79TzoAU6ytbO9b0jjInLiBBgJRcy94E8Wl+lR6TXtm1YQw/2umqYj83Rp2V0DBrAckvCqBiqCLUioK8UvG9V9ev9liwohRgBeKL1xZDaqB15VcrAYE9gaeM5UZG772fLg2jid60nRp929E0YU+CrvjfSm6P7Vyz4DwAImsPV750cfp7qTY+C5d8T4qOMjGXpb3qTVvSq9+7xJV0YzZDH/4bkCn7qepZ5TMa+q8y5IRM26GRPlXHZtaKA/E4m0Zs7uD5MecpjW2Mhobw97RW3BYaHRAKO21zVcf3VDHdOBRy97N5XLuuFq57lSByaw/YFXu4FZYzIzt91VPj5pnm93o8ULy4kKakvYCCY0LPFBfWACm89BH1G4Wo0gOBLqDVcYhYF1jLmiSV285KpYDxpoBQ62BHMSZgSVFuWcw2sUueRyQvxhmllYCmVL0MjMwqLPP2ChjANbc5oF13OUXK/UYDJBl4bSFMZzZQQykPUOB4sxgf1/ip1T9bhZoJ6j5P7tgC4w0HhUd3PvAeo3mgXqOUpupwM60AWG6gXA+H17jhH/actUXgeaPnHTj4c0/ToY2FHTV0Ywu78ttrzJZ73g+23hC4o71O635ScefdGiSCyMOgAAAABJRU5ErkJggg==')
}

.desktop-app-header .mac-traffic-lights .light#min {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAhxJREFUSA21ls9S01AUh++5SQtJBlmJoh11YWF8m7pyxgFfwQfomgfwFYRxxpV9GwdwoU4VhRUySaBNcrxfSjpQoBUaz6J/Jsn3u/fcc34nYqZEf+d5K1PbEaMdEV3TwtzndrHmSFX21EjPl6LX2vjSvwkj1134uv1i1RbDLWPlVRD4GoVe1GiI8bzR7XmuZpipieM8TtNMTKEfC9voPtv8fDDJuyLw/cNaRzN9f2/JX1hebjbdaqeG25U5Ph4M/pxkZ+LLmyev93oXH7gk8G27/dbxtlZWFqPmwgzyRYr7PTgrzOHhaez0uk83999Vl8cCrFxy3Vl9FERVKqqb/vWb1B38TGP1ZKPaSSlAzkWz3YcPFpduu/JJcXby6/fpiYq/zpmUeeBAyfm8cMRgwCqLxP0XSrFQ2W09DsNZBzq52pv+c/D9H0liRdctdR6EPrVdW8CCCduJFC+jwAtro5+DXO+EsK1Ta9NEdUfDF7LStrT/Xcty2qJgwq4x8xNy50khRUc0SN2RO6+CTYr2h8P6BTBDHNeq2E9xmid17wCnxc4tfp4mGQdSW8DCxmHbclg4P8dy61IoWY4Ju6wihgV+jlHNGzBgwYRVCuB6DAv8fJ6K4lkYsKrpNu4D/Nutv4uf32UnPMOzMKpZwA6ueMR/HZkoEpeGvnNFzPDaoZ/kCRV4q6E/khh9jl9bnCtiXHgLV+hQGpQemvXa8hdr6zhi7opTzQAAAABJRU5ErkJggg==')
}

.desktop-app-header .mac-traffic-lights:hover .light#min {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAkpJREFUSA21ls1S1EAQx6cnswtJCjiJoitglQvlk6jlZT1ZZYGv4APsmQfwFYSyypN7s3wSC/AA1CJfJ6CSwG4ybf8DSYXwsbAb+5Kv6V/P9Ez/O6TusO7ay0bMukWKW0S8wFY9wnDS6oiZNlhRx5DtNJb+dG/D0E0ftlZfzWjbX1GaPriuYd9z/FqNlONcDE8SVv2YVRAkQRTFpCx/t7rWnl/+vVfmXQuw822hxTF/nZwwY1NT9brM9k6TVanj417v5DQ+J0OfZj9udIoOVwJsrzY/C29lenrcr48NIBcpct87t+rw8CyQeO255c0v2ec8AGZOCa/NPHX9LBXZoPtekbq9v1HADi1lK0kDIOfE8fqTx+MTD515OThWsn9wdspkFrEnaR6wocj5qHAEAwOs9JDIM+EoWqb1xjPPG7Sh5dne9oyN7+6GoSZe1DjnrmdwtiszsMAEW4LY977reJXRL0FSOx7YWqI1UURVW80QstI0KP/isfz1c0vtbJ8MFW92blK9fjuf+oIJtimT2LLi8st7PsM3t8ukGFnGkRTIc6Mv3rx59yIfM8pNIloFtpZlbPb7hcijUAu+EEMormbSP4IoCQvfKrmF0kLONfQ8CmNsSGUGFmQcbJ02C9FzSG5VEVKWMMFO6xfNAnoOoRrVwAALTLDSAFA9NAvoOSR3WIMvGGBl3S1XIOi3zL8NPR9mJfCBLxhZL8BEr2nEf22ZWWquNH1RRYjhjU0/TEKcwAc1/SwIrvlvi6gihAvagveoUBQoamjQb8s/UsFGN/X8miYAAAAASUVORK5CYII=')
}

.desktop-app-header .mac-traffic-lights .light#fullscreen {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAjRJREFUSA21ls9u00AQxmfWdhxs/kQgVFVQuFB6ohfOvEOjHnpBvAIP0HMfgEcAcemhSl6ES7lQygUKigABUYudurZ32G+rjZK0IaRdVrKcdXZ/39i7880y/aU92Fm9WypZE1Lmkoek5bYdrvg7E79n0t1Ic/fD+u7naRg+74/7O48XdVBtkchGmMQSpXHKUUAqVHa4rjTpk5qqvMjMZbR4W9Xh5sf1N71J3hmBpc6jNRZ+FV1vxlErbbA6M2SMIVqo7Gcn5eFxISzPDtpvu6MDxmbf66w+J6at5kIrDeJwdNzM33VR0fHXfkZCm5/auy/chKGAjZz5dXLnZsrB6adwg/71LrWm/MvPTESeujexAvjmwuVec7F1bd7IJ8Xtm/T6RyzRCtbEhooFxTe/LBxiYIBlN4npM7ZioWgvXbqVzFrQyWin9bHw2cGPPNa0orDPwysx+YJDFCwwwVYmidrR1TiZFs1Fn4dpnIBtBGQZSeS7qUZgdqwsK6S/y1CfIpYJtk/oKEvktKfIGBe8xXdD0oFt1pv3pax9860ZwnGNgO6Uv4vctwKcFnau4OfVoCAkh68GFmwcbGWLhfFzWK4vAcsyTLDtLkKxgJ/DqC7bwAALTLCsAFwPxQJ+blf/giqYaxmG5arbMIUPt7+9u7GxkJVHgydBs9GYN/kQ+aD3yxYcUwteuhiHBcc9+K8l04mMFX3jijDDc4t+VuTYgXMVfSeC+8ixpQ3jmji27COHZh1b/gAKKDsWzDNC7QAAAABJRU5ErkJggg==')
}

.desktop-app-header .mac-traffic-lights:hover .light#fullscreen {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAq5JREFUSA21Vt1OE0EUPjP7V3arFJFgg6AxVEyM3BgSE2PiI1C5ICaGC1/AB+C6D+Aj+JMQTEj7HsSbeiNiDIraIApN6bZst7vHOYPTbLdbSlVO0s7O2TPftztzzneWwSk2uzF/1ee4iMDFD29CiBMynLN9BuwDg7BkhKz0can8tR8MS7pxbeNuNtTaBUBc1m0LDcdymKEB17kMD9shhK0A2g3PFT/BxdZ5oK9+XnpbieP1EEwX7ywyZC+NiynLyDgm4z0hXRgYIvhVt+XXjj1kuLKbf1eKBnStninOPwMGhdRkxtEsPRo38Drw2nC8V3UBYfVLvvxcLegQyCdn7LU9dclh2slWqKCzjhiE0Ph24CLiE/UmkoD2HJm/lcpmLgz75HFy+SaV6hFDY47ORO4DHaiZTllJ4Pubn+Dn5k4cpzPX0xZkH96C9My49BEGnV+r7hWE4ymjVPQ4bDnT43a/Az0LSW7lfoeUDt7d/dWwQpjjlOf6iAVxcAJVNrFwAy4vXFfTnrFd97p8hEWYhM1FEeWNtGV3RYgJbcswJPH1umPZhC0IMEdFlGT/QsJNTWQs5jiVv6rQ/0kiMQk7CTTu+5s3QTxB4SCEi7RlkA1LQkVH2OK82Tb6wSB8eX8YEhJDUlxdSG7Rr3v3NNvsyaQkViIho9SNjnIS+SOlJTnnpOftpgdUHFGjCu1nSW8SjSUsknHC1g7e7NVGH1+ZZWF4WxsRufXHrDEHGt+rUveVLzrSPRCJ6EyNRd3y2j90W4EfrO08Kr86d7GTaUqqR82C9Fyefs8znc1BayWGwFLdrbMltfUf70eXJ13/qPlAS5nmacWXREcy3awcyoYjesELFdNpOMpxri1TkXQ1faGKJIaJTd/1GpSBQzV9RUJj5LMlT8IV+2zZphoa9NnyG4cohRnw3rUiAAAAAElFTkSuQmCC')
}
.widget-container {
    height: calc(100vh - 55px - 32px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

.widget-container>* {
    height: -moz-fit-content;
    height: fit-content;
}

.widget-container>*:last-child {
    flex: 1 1;
    overflow: auto;
}

.w-header-avatar {
    max-width: 100%;
    max-height: 100%;
}

.w-header-name {
    max-width: 16ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
}

.w-header-avatar-wrapper {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
}

.w-header-avatar-wrapper .w-header-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    padding: 0;
    margin: 0;
}

.w-header-avatar-wrapper .w-header-badge.success {
    background-color: #28a745;
}

.w-header-avatar-wrapper .w-header-badge.warning {
    background-color: #ffc107;
}

.w-header-avatar-wrapper .w-header-badge.secondary {
    background-color: #b3b3b3;
}

.w-header-timer {
    color: #6c757d;
    font-weight: bold;
    margin: 0;
    font-size: 1.15rem;
}
.timesheet-root {
  padding: 1rem 2rem 2rem 2rem;
}

/* timesheet div css start */
.timesheet-row {
  margin: 0;
}
.timesheetLoader{
  border-right: 2px solid #8e8e8e80;
    border-left: 2px solid #8e8e8e80;
    height:400px;
    margin-left:200px;
    margin-right: 200px;
}
.timesheet-Loader-No-Result{
  text-align: center;
    color: gray;
    padding-top: 40px;
}

/* timesheet-header-row div css start */
.timesheet-header-row {
  height: 50px;
  line-height: 50px;
  font-size: 0.9rem;
  background-color: #fcfcfc;
  border-bottom: 2px solid #8e8e8e80;
  color: #00000085;
}
.timesheet-header-row *:nth-child(2) {
  border-right: 2px solid #8e8e8e80;
  border-left: 2px solid #8e8e8e80;
}
/* .timesheet-header-row *:nth-child(3) {
  border-right: 2px solid #8e8e8e80;
} */
/* timesheet-header-row div css end */

/* timesheet-content-row div css start */
.timesheet-content-row-border {
  border-right: 2px solid #8e8e8e80;
  border-left: 2px solid #8e8e8e80;
  background-color: #fcfcfc;
  overflow: overlay;
}
/* .timesheet-content-row-worked-hours-grid {
  border-right: 2px solid #8e8e8e80;
} */
.timesheet-content-row-member-grid {
  display: grid;
  grid-gap: 15px;
  background-color: #fff;
  /* border-bottom-left-radius: 10px; */
  background-color: #fcfcfc;
  padding: 20px 1px 30px 1px;
  grid-template-columns: repeat(1, auto),
  
}
.timesheet-content-row-member-grid-displayname-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  margin-left: 40px;
}

.timesheet-content-row-member-grid-displayname-profile-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
}
.timesheet-content-row-member-grid-displayname-profile-data a{
  color:#212529;
}
.timesheet-content-row-member-grid-displayname-data a{
  color:#212529;
}
/* timesheet-content-row-border-grid div css start */
.timesheet-content-row-border-grid {
  display: grid;
    padding: 20px 1px 30px 15px;
    grid-gap: 20px;
    overflow: auto;
  
}

.timesheet-content-row-border-grid-day-card {
  flex: 1 1;
  border: 2px dashed #eeeeee;
  padding: 5px 8px;
  border-top-right-radius: 3;
  border-top-left-radius: 3;
}

.timesheet-content-row-header {
  display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    margin-bottom: 7px;
}

.timesheet-content-row-day {
  color: #797979;
}
.timesheet-header{
  display: flex;
  margin-bottom: 2rem ;
  margin-top: 1rem ;
}
.timesheet-export-button-header {
  /* text-align: end; */
  /* margin-left: auto; */
  /* margin-left: 25px; */
  margin-bottom: 1rem;
}
.timesheet-checkbox-dropdown-label-checkbox{
  margin-left: auto;
  display: flex;
}
.timesheet-checkbox-dropdown {
  width: 200px;
  /* margin-left: auto; */
  margin-bottom: 1rem;
}
.dropdown-search-clear-icon{
  display: none !important;
}
.option { 
	color: #6c757d;
  background: #e9ecef !important;
}
.option:hover { 
	color: blue;
  background: #e9ecef !important;
}

.timesheet-content-row-date {
  color: #00000033;
}
.timesheet-content-row-content {
  background-color: #eeeeee;
  min-width: 8rem;
  padding: 0 11px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.timesheet-content-row-content:hover {
  background-color: white;
  transform: scale(1.1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.timesheet-content-row-member-grid-displayname-date:hover {
  background-color: #f2d06733;
  transform: scale(1.1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:first-child
  .timesheet-content-row-member-grid-worked-hours-label {
  color: #0065ff;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(2)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #e710cbfc;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(3)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #7454f5;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(4)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #07b4ef;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(5)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #28a745;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(6)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #f06d50;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(7)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #92929e;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:last-child
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #1abc9c;
}

.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:first-child
  .timesheet-content-row-content-line-category {
  color: #1abc9c;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(2)
  .timesheet-content-row-content-line-category {
    color: #dc3545a3;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(3)
  .timesheet-content-row-content-line-category {
  color: #0065ff;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(4)
  .timesheet-content-row-content-line-category {
    color: #e710cbfc;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(5)
  .timesheet-content-row-content-line-category {
    color: #7454f5;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(6)
  .timesheet-content-row-content-line-category {
  color: #07b4ef;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(7)
  .timesheet-content-row-content-line-category {
  color: #28a745;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(8)
  .timesheet-content-row-content-line-category {
    color: #f06d50;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(9)
  .timesheet-content-row-content-line-category {
    color: #92929e;
}


.timesheet-content-row-content-line * {
  display: inline-block;
  font-weight: 700;
}
.timesheet-content-row-content-line-time {
  font-size: 0.8em;
  width: 67%;
  color: #97a0afcf;
}
.timesheet-content-row-content-line-category {
  font-size: 0.6em;
  color: #d6d6d6;
}
/* timesheet-content-row-border-grid div css end */

/* timesheet-content-row div css end */
/* timesheet div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}
.timesheet-export-button-header {
  text-align: end;
  margin-bottom: 1rem;
}
.timesheet-export-button {
  /* font-size: 0.9rem;
  font-weight: 700;
  padding: 0.3rem 1.2rem; */
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
}
.timesheet-content-row-member-grid-worked-hours-label {
  font-weight: 600;
        font-size: 0.7em;
        color: #b9a28199;
        width: 53%;
}
.timesheet-content-row-member-grid-worked-hours-value{
  font-size: 0.8em;
        color: #97a0afcf;
        display: inline-block;
        font-weight: 700;
        width: 56%;
}
.timesheet-content-row-member-grid-worked-hours,
.timesheet-content-row-member-grid-worked-days {
  /* margin-top: 1.3rem; */
  display: flex;
  align-items: center;
  padding: 3px;
}
.disable-timesheet {
  padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #007bffa8;
    border-color: #007bff;
    /* line-height: 1.5; */
    border-radius: .25rem;
}
.timesheet-day {
  width: 38%;
  text-align: right;
}
.timesheet-day-label{
  margin-left: 27px;
}
.timesheet-data-header {
  text-align: center;
  display: block;
}
/* .timesheet-nav-left {
  float: left;
  position: relative;
  left: 10px;
  top: 16px;
  font-size: 20px;
  cursor: pointer;
  color: #bdbdbd;
} */
.timesheet-nav-left{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #007bff;
    background-color: #e9ecef;
    float: left;
    position: relative;
    left: 10px;
    top: 13px;
    font-size: 20px;
    cursor: pointer;
    /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.timesheet-nav-left-icon{
  margin-left: -1px;
}
.timesheet-nav-left-disable{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #718093;
    background-color: #e9ecef;
    float: left;
  position: relative;
  left: 10px;
  top: 13px;
  font-size: 20px;
  cursor: no-drop;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
/* .timesheet-nav-left-disable{
  float: left;
  position: relative;
  left: 10px;
  top: 16px;
  font-size: 20px;
  cursor: no-drop;
  color: #d8cfcf;
} */
.react-multi-carousel-list  li{
  width: 668px !important;
}
/* .timesheet-nav-right {
  float: right;
  position: relative;
  right: 10px;
  top: 16px;
  font-size: 20px;
  cursor: pointer;
  color: #bdbdbd;
} */
.timesheet-nav-right{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #007bff;
    background-color: #e9ecef;
    float: right;
  position: relative;
  right: 10px;
  top: 13px;
  font-size: 20px;
  cursor: pointer;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.timesheet-checkbox-dropdown-label{
  font-size: 15px;
    color: gray;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 8px;
}
.timesheet-nav-right-disable {
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #718093;
    background-color: #e9ecef;
    float: right;
  position: relative;
  right: 10px;
  top: 13px;
  font-size: 20px;
  cursor: no-drop;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
/* .timesheet-nav-right-disable {
  float: right;
  position: relative;
  right: 10px;
  top: 16px;
  font-size: 20px;
  cursor: no-drop;
  color: #d8cfcf;
} */
.timesheet-content-row-member-grid-displayname-date{
  /* text-align: center !important; */
  width: 11rem;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 1rem;
  padding: 11px 11px;
  border-radius: 5px;
  background-color: #f2d06733;
  transition: all .2s ease-in-out;
}
.timesheet-data-header-details{
  border-right: none !important;
  border-left: none !important;
}
label.timesheet-data-header-name.timesheet-data-header-details {
  text-transform: uppercase;
}
label.timesheet-data-header-date.timesheet-data-header-details {
  color: #0000007d;
}
.timesheet-work-summary-header{
  text-align: center;
}

.timesheet-content-row-content-absent{
  background-color: #f3d0d066 !important;

}

.timesheet-content-row-border-grid-day-card-absent{
  border: 2px dashed #f9b8b8ba !important;
}
.timesheet-content-display-name-header{
  display: flex;
  position: relative;
}
.timesheet-content-avatar-profile {
    width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
    margin: auto 0;
    margin-right: 12px;
}
.timesheet-content-data-details-avatar-circle{
  height: 28px;
  width: 28px;
  font-weight: bold;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
  margin: auto 0;
  margin-right: 12px;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
}
.download-dropdown{
  color: #1e1e1e;
    cursor: pointer;
    width: 100%;
    margin: 0;
}
.timesheet-export-button-header a:hover{
  text-decoration: none;
  color: #1e1e1e;
}
.timesheet-export-button-header .dropdown-toggle::after {
  display: none;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.timesheet-download-icon{
  color: white;
    font-size: 1.1rem;
    font-weight: 700;
}
.timesheet-checkbox-dropdown-label-link {
  color: gray;
}
.timesheet-checkbox-dropdown-label-link a {
  color: blue;
  text-decoration: underline;
}
.timesheet-checkbox-dropdown-label-link-footer{
  text-align: center;
  margin: 0rem 1rem 1rem 1rem;
}
.disable-edit-timesheet {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: #6c757d8f;
  border-color: #6c757d9c;
  border-radius: 0.25rem;
  margin-top: 2px;
}
.timesheet-content-row-day-edit-timesheet {
  font-size: 13px;
  margin-left: 2px;
  cursor: pointer;
}
.timesheet-content-row-day-edit-timesheet-label {
  color: #80808061;
  font-size: 11px;
}
.timesheet-content-row-border-grid-day-card-edited {
  border: 2px dashed #ff8c005e !important;
}
.timesheet-content-row-content-edited{
  background-color: #ff8c0024 !important;
}
.timesheet-export-button-header {
  margin-left: 15px;
}
.timeline-pagination-section-timesheet {
  display: grid;
  place-items: center;
  width: 78vw;
  overflow: scroll;
  margin: 0px 32px 0px 32px;
}
.timeline-pagination-section-label-timesheet {
  text-align: center;
  margin-bottom: 15px;
  /* width: 100vw; */
  display: grid;
}
.timeline-pagination-section-timesheet .pagination{
  /* display: flex;
  justify-content: center; */
}
.timesheet-content-row-member-grid-employee-id {
  color: gray;
  font-size: 13px;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 133px;
}
.edit-user-details-root {
    margin: 2rem;
}
.edit-user-details-list-time .rc-time-picker-input{
    height:39px;
}
.edit-employees-root-content-data-details-avatar {
    display: flex;
    align-items: center;
}
.edit-employees-root-content-data-details-avatar-circle {
    height: 30px;
    width: 30px;
    font-weight: bold;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
}
.timesheet-exclude-text {
    font-size: 12px;
    font-weight: 200;
    /* margin-top: 5px; */
}
.edit-employees-root-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    width: 166px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.edit-user-details-list-date {
    width: 8rem;
}
.edit-user-details-list-time{
    width: 6rem;
}
.edit-user-details-list-worked-time{
    width: 10rem;
}
.edit-user-details-div-root{
    /* width: 100% !important; */
    /* overflow-x: auto !important; */
    width: 100%;
    height: 80vh;
    overflow: auto;
}

.edit-user-details-root-content-table{
    /* border-collapse: separate;
    border-spacing: 0 8px; */
}
    
.edit-user-details-div-content{
    display: grid;
}
.edit-user-details-list-worked-time input::-moz-placeholder{
    font-size: 12px;
}
.edit-user-details-list-worked-time input::placeholder{
    font-size: 12px;
}

.edit-user-details-filter-content {
    display: flex;
    margin-bottom: 2rem;
}
.edit-user-details-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.edit-user-details-list-select-team-error-text {
    color: red;
    margin-top: 6px;
    font-size: 12px;
}
.edit-user-details-list-select-team-error {
    border: 1.5px solid red;
    border-radius: 4px;
}
.edit-user-details-date-picker {
    margin-right: 20px;
}
.edit-user-details-user-search::-moz-placeholder {
    color: #8080806b;
}
.edit-user-details-user-search::placeholder {
    color: #8080806b;
}
.edit-user-details-list-time .rc-time-picker-clear{
    margin-top: 4px !important;
}
.edit-user-details-root-content-data-details td:first-child {
    /* border-left: 1px solid #0000000d !important; */
    
}
.edit-user-details-root-content-data-details td:last-child{
    /* border-right: 1px solid #0000000d !important; */
}
.edit-user-details-root-content-data-header tr th{
    font-weight: 600 !important;
    color: rgba(0,0,0,.5803921568627451) !important;
    font-size: 0.9rem !important;
}
.edit-user-details-root-content-data-header {
    font-weight: 600 !important;
    color: rgba(0,0,0,.5803921568627451);
    font-size: 0.9rem;
}
.edit-user-details-root-content-data-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.06);
    font-size: 0.9rem;
}
.edit-user-details-root-table-body tr td {
    vertical-align: middle !important;
    font-size: 0.9rem;
}
.edit-user-details-root-content-data-header{
    position: sticky;
    top: 0;
    z-index: 1;
}
.edit-user-details-root-content-table thead>tr th:first-child, .edit-user-details-root-content-table thead>tr th:nth-child(2) {
    background-color: #f3f7fa!important;
    vertical-align: middle;
}
.edit-user-details-root-content-table tbody>tr td:first-child,.edit-user-details-root-content-table tbody>tr td:nth-child(2) {
    background-color: #f3f7fa !important;
}
.edit-user-details-root-content-th-sticky-col{
    position: sticky;
    background-color: #ffffff !important;
    min-width: 8rem ;
    max-width: 8rem ;
    text-align: left !important;
    z-index: 9 !important;
    
}
.edit-user-details-root-content-table tbody>tr td:first-child {
    background-color: #f3f7fa !important;
}
.first-col{
    left: 0; 
    min-width: 15rem !important; 
    max-width: 15rem !important; 
}
.second-column{
    left: 15rem !important; 
}
.edit-user-details-root-content-data-header th,.edit-user-details-root-table-body tr {
    border-bottom: 1px solid #dee2e6!important;
}
.edit-user-details-root-content-td-edit-button {
    border: 0px;
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 15px;
    color: gray;
    -webkit-text-decoration-color: gray !important;
            text-decoration-color: gray !important;
}
.edit-user-details-date-picker .react-datepicker__tab-loop .react-datepicker-popper{
    z-index: 9999 !important;
}
.edit-user-details-root-content-data-header{
    background-color: #f3f7fa!important;
    vertical-align: middle;
    z-index: 99 !important;
}

.edit-user-details-modal-label-name {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.edit-user-details-list-modal-input {
    font-size: .9rem!important;
}
.edit-user-details-list-modal-time {
    width: 100%;
}
.edit-user-details-modal-body {
    padding: 15px 30px;
    /* height: 100vh; */
    /* overflow-y: scroll !important; */
}
.edit-user-details-list-modal-time input{
    height: calc(1.5em + 0.75rem + 2px) !important;
    font-size: .9rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057 !important;
}
.medit-user-details-list-modal-content-name-field {
    flex: 1 1;
    margin-bottom: 0rem;
    margin-right: 16px;
    margin-bottom: 20px;
}
.edit-user-details-list-modal-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.edit-user-details-list-modal-content-name-field {
    margin-right: 0 !important;
}
.edit-user-details-list-modal-button {
    margin: auto;
    padding: 9px 0;
}
.edit-user-details-list-modal-close-button, .edit-user-details-list-modal-save-button {
    font-size: 0.9rem;
    padding: 0.3rem 1.3rem;
    margin-right: 1rem;
    font-weight: 500;
}
.edit-employees-root-content-data-details-name-data a{
    color: #212529;
}
.edit-user-details-list-modal-team-error {
    font-size: 12px;
    margin-bottom: 8px !important;
}
.edit-user-details-list-modal-select-team-error{
    float: left !important;
}
.edit-user-details-list-modal-input::-moz-placeholder, .edit-user-details-list-modal-input input::-moz-placeholder{
    font-size: 12px !important ;
    color: #0d0e104f !important;
    font-weight: 400 !important;
}
.edit-user-details-list-modal-input::placeholder,.edit-user-details-list-modal-input input::placeholder{
    font-size: 12px !important ;
    color: #0d0e104f !important;
    font-weight: 400 !important;
}
.edit-user-details-modal-body form .form-group {
    margin-bottom: 1.5rem;
}

@media screen and (min-width: 786px) {
    .row > * {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.edit-user-details-modal-text-label-name {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    /* margin-bottom: 8px; */
}
.edit-user-details-modal-text-label-wrapper {
    margin-bottom: 1rem;
}
.edit-user-details-modal-text-label-name {
    margin-bottom: 4px;
}
.edit-user-details-modal-text-label-value {
    color: gray;
    font-size: 14px;
    font-weight: 500;
}
.edit-user-details-div-root-windows-scroll::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
}

.edit-user-details-div-root-load-more-button {
    z-index: 100;
    left: 0;
    position: sticky;
    text-align: center;
}
.employee-edit-card-avatar-profile {
    width: 27px;
    height: 27px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
}
.edit-employees-root-content-data-details-edit-name-data a {
    color: gray !important;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
}
.edit-employees-root-content-data-details-edit-avatar-circle{
    height: 30px;
    width: 34px;
    font-weight: bold;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
}
.edit-employees-root-content-data-details-edit-edit-time-div {
    margin-bottom: 15px;
}
.edit-employees-root-content-data-details-edit-edit-task-date {
    display: flex;
    flex-direction: column;
}
.edit-employees-root-content-data-details-edit-edit-label {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.edit-employees-root-content-data-details-edit-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 7px;
    height: 37px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.edit-employees-root-content-data-details-edit-wrapper-filter-date-date-section {
    /* display: flex; */
    align-items: center;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.edit-employees-root-content-data-details-edit-calender-icon-div {
    display: flex;
    color: black;
}
.edit-employees-root-content-data-details-edit-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    /* margin-left: 5px; */
    cursor: pointer;
}
.edit-user-details-modal-text-label-name-edit {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px !important;
}
.task-details-root{
  padding: 2rem;
}
/* Task Details Card */
.task-details-summary {
  display: grid;
  /* grid-template-columns: repeat(4,1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 2rem; */
  grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
  grid-gap: 1.5rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.task-details-summary-name-header{
  grid-column: 1/3;
  display: flex;
  align-items: center;
}
.task-details-summary-name-header-avatar {
  display: inline-block;
  text-align: center;
  color: #333;
}
.task-details-summary-name-icon {
  display: inline-block;
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background: rgba(239,120,14,.5490196078431373);
  border: 1pxsolid rgba(0,0,0,.1);
  font-size: 40px;
  line-height: 69px;
  text-align: center;
  color: #fff;
  margin-right: 13px;
}
.task-details-summary-name-value {
  font-size: 25px;
  font-weight: 700;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-details-summary-name-edit-value {
  color: gray;
  text-decoration: underline;
  cursor: pointer;
}
.task-details-card {
  /* max-width: 255px;
  width: 100%;
   */
  /* padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3; */
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.06); */
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.task-details-card-title {
  font-size: .9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task-details-card-title-productivity {
  color: #007bff;
}
@media (min-width: 1400px) {
  .task-details-summary {
    max-width: none;
    flex: 1 1;
    margin: 0 2rem 2rem 2rem 2rem;
  }
  .task-details-summary.no-data {
    font-size: 2.5rem;
  }
}

.task-details-employee-list-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  /* margin-left: 2rem; */
  background-color: white;
}

.task-details-employee-list-card-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-employee-list-card-export-button {
  font-size: 0.9rem;
  font-weight: 600;
  float: right;
  margin-top: -5px;
}
.task-details-employee-list-card-title {
  font-weight: 700;
  color: #0000008c;
  font-size: 1rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}
.task-details-employee-list-card-assign-employee-button {
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: 0;
}
.task-details-employee-list-card-body {
  padding: 0 1.3rem 1rem;
}
.task-details-employee-list-card-searchbox-wrapper {
  position: relative;
}
.task-details-employee-list-card-searchbox:focus {
  border-color: #0747a699;
  box-shadow: 0 0 0 0.2rem #0747a699;
}
.task-details-employee-list-card-searchbox::-moz-placeholder {
  color: #cccccc;
}
.task-details-employee-list-card-searchbox::placeholder {
  color: #cccccc;
}

.task-details-employee-list-card-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.task-details-employee-list-card-filter {
  display: flex;
}
.task-details-employee-list-card-searchbox {
  float: right;
  font-size: 0.9rem;
}


/* Time On Task card  */

.task-details-time-on-task-card {
  margin: 2rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 30rem;
}

.task-details-time-on-task-card-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-time-on-task-card-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-time-on-task-card-body {
  padding: 1.3rem 1rem;
}

/* Screenshot Card  */

.task-details-screenshots-card {
  margin: 2rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 25rem;
}

.task-details-screenshots-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-screenshots-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-screenshots-body {
  padding: 1.3rem 1rem;
}
.task-details-screenshots-see-all-button {
  float: right;
  font-size: 0.85rem;
  font-weight: 700;
  margin-top: 0.26rem;
}

.screenshots-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 1rem;
}

.screenshots-list-img {
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.screenshot-display-name {
  text-align: center;
  font-size: 0.6rem;
  margin-top: 0.4rem;
}

.task-details-top-apps-and-websites-row {
  margin: 2rem;
  display: grid;
  grid-template-columns: 1.9fr 2fr;
  grid-gap: 2rem;
}
.task-details-top-apps-and-websites-list-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 26rem;
}
.task-details-top-apps-and-websites-chart-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 26rem;
}
.task-details-top-apps-and-websites-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-top-apps-and-websites-body {
  padding: 1.3rem 1rem;
}
.task-details-top-apps-and-websites-see-all-button {
  float: right;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 0.2rem;
}
.task-details-top-apps-and-websites-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}
.task-details-top-apps-and-websites-data {
  display: flex;
  justify-content: space-between;
  margin: 0.6rem 0;
}
.task-details-top-apps-and-websites-name {
  width: 80%;
  background-color: #8080801a;
  border-radius: 5px;
  padding: 0.1rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid #80808017;
}
.task-details-top-apps-and-websites-spending-hours {
  font-size: 0.8rem;
  vertical-align: middle !important;
  margin-top: 1%;
}
.task-details-top-apps-and-websites-icon {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem;
}
.task-details-top-apps-and-websites-details {
  overflow-y: scroll;
}

.task-details-employee-list-card-data {
  display: grid;
  grid-template-columns: 1fr;
  /* margin-top: 1rem; */
  font-size: 0.87rem;
  font-weight: 500;
  color: #000000b5;
}
.task-details-employee-list-card-data-header {
  color: #797777db;
  border: none;
  font-size: 14px;
}
.task-details-employee-list-card-data-table{
  border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.task-details-employee-list-card-data-table thead th {
  border: none;
}
.task-details-employee-list-card-data-table th,
.task-details-employee-list-card-data-table td {
  border: none;
  
  /* vertical-align: middle; */
}

.task-details-employee-list-card-details {
  margin-top: 1rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  font-size: 15px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
}
.task-details-employee-list-card-details p{
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}

.task-details-data {
  /* margin: 2rem; */
  margin-bottom: 2rem;
}
.task-details-data-task-back-button {
  font-size: 1.5rem;
  color: #007bff;
  margin-right: 1rem;
  cursor: pointer;
}
.task-details-data-task-back-button:hover{
  color: #266dfb;
}
.task-details-data-task-name {
  font-weight: bold;
  margin-right: 0.66rem;
  font-size: 21px !important;
}
.task-details-data-edit-task-button{
  margin-right:1%;
}

.task-details-data-edit-task-button, .task-details-data-delete-task-button{
  font-size: 0.9rem;
  font-weight: 600;
  padding: 2px 26px;
}
.task-details-data-task-details-content {
  /* display: flex; */
  grid-column: 1fr;
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 28px;
  gap: 28px;
  font-size: 0.86rem;
}
span.task-details-data-task-priority {
  color: rgb(218 93 19 / 74%);
}
span.task-details-data-task-created-at {
  /* margin-top: -1rem; */
  color: #000000ab;
}
.task-details-data-task-due-date {
  /* margin-top: -1rem; */
  color: #007bff;
}

span.task-details-data-task-created-at-data,
.task-details-data-task-due-date-data {
  font-weight: 600;
}
svg.task-details-data-task-created-at-icon {
  font-size: rem;
  margin-right: 0.2rem;
  font-size: 18px;
  margin-top: -2px;
}
.task-details-data-task-due-date-icon {
  font-size: rem;
  margin-right: 0.2rem;
  /* font-size: 18px; */
  margin-top: -2px;
}
.task-details-employee-list-card-details-avatar {
  display: flex;
}

.task-details-top-apps-and-websites-chart-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-top-apps-and-websites-chart-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-top-apps-and-websites-chart-body {
  /* padding: 1.3rem 1rem; */
  position: relative;
}
/* .task-card-chart-data-edit-menu-dropdown {
  top:-1px !important;
} */

.task-card-chart-data-edit-menu #dropdown-basic {
  top: -1px !important;
}
.employee-no-data{
  color: rgb(90 89 89);
  font-size: 1rem;
}
.task-time-no-data{
  margin-top: 53px;
  text-align: center;
}

/* ASSIGN EMPLOYEE MODAL */
.task-details-assign-employee-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -16px;
}
.task-details-assign-employee-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.task-details-assign-employee-modal-content-person-name-header-section {
  flex: 1 1;
}
.task-details-assign-employee-modal-content-person-name-header-section-content {
  display: flex;
}
.task-details-assign-employee-modal-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  margin-right: 16px;
}
.task-details-assign-employee-modal-searchbox {
  float: right;
  font-size: 0.9rem;
  background-color: #f5f3f6;
}
.task-details-assign-employee-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.task-details-assign-employee-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #007bff;
  color: #007bff;
  font-size: 0.9rem;
  /* transition: background .4s; */
}
.task-details-card-title-text{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-details-assign-employee-modal-content-person-name-listing {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: -16px; */
}
.task-details-assign-employee-modal-content-person-name-listing-card-content {
  margin-bottom: 10px;
  display: flex;
}
.task-details-assign-employee-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
}
.task-details-assign-employee-modal-content-person-name-listing-card-content-person-icon {
  height: 32px;
  width: 32px;
  line-height: 32px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #28a745;
  text-transform: uppercase;
}
.task-details-assign-employee-modal-content-header-team-name {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 14px;
  color: #62839b;
  cursor: pointer;
}
.access-control-checkbox {
  width: 0.9rem;
  height: 0.9rem;
  vertical-align: middle;
  cursor: pointer;
}
.task-details-assign-employee-modal-button-rows {
  margin-top: 40px;
  margin-bottom: 40px;
}
.projects-create-col {
  text-align: center !important;
}
.task-details-assign-employee-modal-button {
  margin: auto;
  padding: 9px 0;
}
.task-details-assign-employee-modal-close-button, .task-details-assign-employee-modal-save-button {
  font-size: 0.9rem;
  padding: 0.3rem 1.3rem;
  margin-right: 1rem;
  font-weight: 500;
}

.task-detail-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.task-detail-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.task-assign-card-avatar-profile {
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.task-assign-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.task-details-summary-name-edit-value-div{
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}
.task-details-summary-project-name-value {
  font-weight: bold;
  color: #313231a8;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-details-card-task-created-at {
  margin-left: 6px;
  color: gray;
  font-size: 15px;
}
.personal-statistics-root {
    padding: 2rem;
}

.desktop-personal-statistics-root {
    padding: 1.3rem;
    /* overflow-x: hidden;
    overflow-y: auto; */
    padding-top: 0em;
}


/* personal-header div css start */

.personal-header {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.personal-clockin-clockout-content-data-details-worked {
    /* color: green; */
    /* margin-left: 5px; */
    /* background-color: #f3f8fd; */
    margin-left: 5px;
    padding: 5px 5px;
    border-radius: 5px;
}

.personal-clockin-clockout-content-data-details-for {
    margin-left: 5px;
}

.personal-clockin-clockout-content-data-details-worked-hours {
    /* color: green;
    margin-left: 5px; */
    background-color: #f3f8fd;
    margin-left: 5px;
    padding: 5px 5px;
    border-radius: 5px;
}


/* personal-header-profile div css start */

.personal-header-profile {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
}

.personal-header-profile-multiple-date {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
}

.personal-header-avatar {
    display: inline-block;
    text-align: center;
    color: #333333;
}

.personal-header-avatar-image {
    height: 90px;
    width: 90px;
    cursor: pointer;
}

.personal-header-name {
    font-size: 25px;
    font-weight: 700;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.personal-header-checkin {
    font-size: 13px;
    color: #495057;
    font-weight: 500;
}

.personal-header-checkin-err {
    font-size: 13px;
    color: #495057;
    font-weight: 500;
    line-height: 25px;
}

.personal-header-email {
    font-size: 13px;
    color: #28a745;
    font-weight: 500;
}


/* personal-header-profile div css end */


/* personal-header-summary div css start */

.personal-header-summary-card {
    /* background-color: #efefefc4; */
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}

.personal-header-summary-card-text {
    /* font-size: 14px;
  text-align: center; */
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.personal-header-summary-card-text-ques {
    font-size: .9rem;
    color: #808080ba;
    float: right;
}


/* personal-header-summary div css end */


/* personal-header div css end */


/* personal-content div css start */

.personal-content {
    /* display: flex; */
}


/* personal-content div css end */


/* personal-footer div css end */

.personal-footer {
    /* display: flex; */
}

.personal-footer-section-top {
    height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    border: 0.5px solid #00000014;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; */
}

@media screen and (max-width: 768px) {
    .personal-footer-section-top {
        height: 40rem;
        /* grid-template-columns: 1fr; */
    }
}

@media screen and (max-width: 480px) {
    .personal-header {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    }
    .personal-header-profile {
        grid-column: 1/3 !important;
    }
    .personal-header-summary-card-text {
        font-size: 0.8rem;
    }
    .personal-header-email {
        width: 215px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .personal-footer-task-section {
        overflow-x: scroll !important;
    }
}

.personal-footer-section-top-scroll {
    height: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.personal-footer-section {
    height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-footer-section-progress-bar {
    min-height: 12rem;
    max-height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-footer-task-section {
    margin: 1rem;
    max-height: 22rem;
    min-height: 10rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.website-chart-personal {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 21px;
}

.personal-footer-head-website-list {
    padding-top: 0.2rem;
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1.3rem;
}

.personal-footer-section-date {
    padding-top: 0.2rem;
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    /* margin-bottom: 1.3rem; */
}

.personal-footer-section-checkin {
    /* margin-top: 15px; */
    font-size: 15px;
    line-height: 14px;
    color: #000000b5;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 4px;
}

.personal-footer-section-task-display {
    overflow-y: auto;
    overflow-x: hidden;
}

.personal-footer-section-task-display-card {
    display: flex;
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.personal-footer-section-task-display-card-content {
    display: flex;
    padding: 2%;
    margin: 0px 10px 0px 0px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    width: 85%;
}

.personal-footer-section-task-display-card-content-task {
    width: 85%;
}

.personal-footer-section-task-display-card-content-text-hour {
    font-weight: normal;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 2px;
}

.personal-footer-section-task-display-card-content-data-hour {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    flex-direction: row-reverse;
}

.personal-footer-section-task-display-card-content-text {
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 2px;
}

.personal-footer-section-task-display-card-content-data {
    font-weight: bold;
    font-size: 19px;
    color: #000000bd;
}

.personal-staticstic-size {
    font-size: 0.9rem;
}


/* personal-footer div css end */

.personal-statistic {
    margin: 1rem 0rem 1rem 0rem;
}

.personal-statistic-chart-title {
    display: grid;
    grid-template-columns: repeat(3, minmax(133px, 1fr));
    grid-gap: 1rem;
}

.personal-statistic-chart-title-progress {
    grid-column: 2 / 4;
}

.personal-statistic-col {
    padding-left: 0px;
    padding-right: 0px;
}

@media (max-width: 991px) {
    .single-menu {
        margin: 0px 0 10px 1px;
    }
}

.personal-header-profile-avatar {
    display: inline-block;
    margin-right: 10px;
    height: 60px;
    width: 60px;
    border-radius: 70px;
    background: #ef780e8c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 34px;
    line-height: 60px;
    text-align: center;
    color: white;
    margin-right: 13px;
    /* margin-top: 5px; */
}


.head-activities-data-list {
    font-size: 0.9rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1rem;
}

.personal-footer-section-task-display-card-image {
    display: flex;
}

.personal-screenshot-thumbnail-image {
    width: 96px;
    border: 2px solid #e2dfdffa;
}

.personal-screenshot-images-card {
    background-color: #d8d3d3d1;
    text-align: center;
    line-height: 78px;
    font-size: 0.8rem;
    cursor: pointer;
}

.personal-screenshot-thumbnail-image:nth-child(2),
.personal-screenshot-thumbnail-image:nth-child(3) {
    margin-left: -81px;
}

.personal-clockin-clockout-content-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 10px 10px 10px;
    margin-bottom: -0.5rem;
}

.personal-clockin-clockout-content-data-details {
    background-color: #ffffff;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .06);
    font-size: 0.9rem;
    font-weight: 400;
}

.personal-clockin-clockout-content-data-details-selected {
    background-color: #DBFBE7 !important;
}

.personal-clockin-clockout-content-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.personal-clockin-clockout-content-data-header {
    color: rgba(121, 119, 119, .8588235294117647);
    font-size: 0.9rem;
}

.personal-Task-root-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
}

.personal-Task-root-task-name {
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}

.personal-Task-root-project-name {
    margin: 0;
    margin-bottom: 3px;
    font-size: 13px;
}

.personal-Task-head-website-list {
    /* padding-top: 0.2rem; */
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    /* margin-bottom: 1.3rem; */
}

.personal-no-data-text {
    font-size: 14px;
    color: #2d3748;
    flex: 1 1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.personal-report-activity-log-link {
    font-size: 14px;
    color: #2d3748;
    flex: 1 1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.personal-date-headers-td {
    padding: 0px 0px 5px 0px;
}

.personal-date-header {
    margin: auto;
    padding: 2px 20px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
}

.personal-date-header-label-icon {
    margin-right: 10px;
    color: #495057eb;
}

.personal-date-header-label-icon svg {
    margin-top: -4px;
}

.personal-task-footer-section {
    height: 22rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-work-task-footer-section {
    max-height: 22rem;
    min-height: 10rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-date-headers-td {
    padding: 0px 0px 5px 0px !important;
}

.personal-card-avatar-profile {
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    margin-right: 13px;
    border-radius: 50%;
}

.personal-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.personal-header-checkin-err-not-accept {
    font-size: 13px;
    color: #dc3545;
    font-weight: 500;
    line-height: 25px;
}

.personal-statistics-task-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.personal-statistics-task-card-table-header {
    color: #868686;
    border: none;
    font-size: 16px;
}

.personal-statistics-task-card-table thead th {
    border: none;
}

.personal-statistics-task-card-table th,
.personal-statistics-task-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}

.personal-statistics-task-card-table-body {
    color: #333;
}

.personal-statistics-task-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
}

.personal-statistics-task-card-table th,
.personal-statistics-task-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}

.personal-statistics-task-card-table-description {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-statistics-task-card-table-name {
    font-size: 13px;
    color: gray;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-statistics-task-card-table-header {
    color: rgba(121, 119, 119, .8588235294117647);
    font-size: 0.9rem;
}

.personal-statistics-task-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.project-task-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 87%;
    display: initial;
    /* margin-bottom: 38px; */
    margin-top: 2px;
}

.project-task-list {
    /* border-radius: 1.2rem; */
    font-size: 14px;
    margin-bottom: 0.5rem;
    display: flex;
    /* border-bottom: 2px solid #00000014; */
    padding-bottom: 7px;
}


/* .project-task-name-bullet-list::before{
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #69b6d5;
    margin-right: 7px;
    margin-top: 9px;
 } */

.personal-project-and-task-section-top {
    height: 19rem;
    padding: 1rem;
    margin: 1rem 0rem;
    border-radius: 5px;
    border: 0.5px solid #00000014;
}

.personal-project-and-task-section {
    /* background-color: white; */
}

.project-task-worked-list {
    border: 1px solid black;
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
}

.personal-project-and-task-section-chart {
    margin-top: 1rem;
}

.project-task-list-root {
    width: 100%;
    margin-top: 1.5rem;
}

.project-task-worked-header-name {
    font-size: 0.9rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1rem;
}

.project-task-section-list-content {
    margin-top: 1rem;
}

.project-task-worked-list-header {
    font-size: 0.9rem;
    color: #000000de;
    /* font-weight: 600; */
    margin-bottom: 6px;
}

.project-task-worked-list-time {
    font-size: 24px;
    font-weight: 700;
}

.project-task-worked-data-list {
    border-color: #53bb53fa;
    background-color: #a4f1a42e;
}

.project-task-unorganized-data-list {
    border-color: #f78282;
    background-color: #f5bdbd2e;
}

.project-task-details-data {
    padding-bottom: 7px;
    border-bottom: 1px solid #00000014;
    display: flex;
    justify-content: space-between;
    width: 97%;
}

.website-chart-project-task {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    /* margin-top: 21px; */
}

.project-task-name-bullet-list {
    content: '';
    width: 3%;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    margin-right: 7px;
    margin-top: 12px;
}

.personal-statistics-project-task-name{
    font-weight: 500;
    font-size: 16px;
    color: #333131;
}
.personal-statistics-project-details-project-name{
    color: gray;
    font-size: 13px;
}
.project-task-worked-time{
    color: gray;
    font-size: 14px;
}
.personal-header-time-error{
    color: #ff00009e;
    font-size: 13px;
    font-weight: 500;
    margin-top: 3px;
}
.personal-header-emp-id {
    font-size: 13px;
    color: gray;
    font-weight: 500;
    margin-top: 5px;
}
.common-err-refresh-text-access{
    text-align: center;
    margin: 2rem;
    color: red;
}
.review-usage-root {
  padding: 2rem;
}

/* review-usage-root div css start*/

.review-usage-root-list {
  /* height: 400px; */
  height: calc(100vh - 185px);
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.review-usage-root-list-no-data {
  height: 200px;
}

.review-usage-root-data-item .nav-item,
.review-usage-root-list .nav-item {
  color: #6c757d;
  border: none;
  border-bottom: 1px solid #dee2e6 ;
}

.review-usage-root-data-item .nav-item {
  padding: 1.3rem 1rem 0.9rem;
}

.review-usage-root-data-item .nav-item.active,
.review-usage-root-list .nav-item.active {
  color: #007bff;
  border-bottom: 4px solid #007bff;
}

.review-usage-root-list-tab-bar {
  border-bottom: 1px solid #ccc;
  font-size: 0.8rem;
}
.review-usage-root-list-tab-bar a.active {
  border-bottom: 3px solid #695eee;
  color: #695eee;
}
.review-usage-root-list-tab-bar-link {
  color: #718093;
  font-weight: 600;
  font-size: 0.7rem;
}
.review-usage-root-list-tab-bar-link:hover {
  color: #695eee;
}

.review-usage-root-list-by-tabs {
  padding: 2%;
}

.review-usage-root-list-no-text{
  font-size: .9rem;
  color: #2d3748;
  padding: 1.5rem!important;
}
/* review-usage-root-listing div css start*/
.review-usage-root-listing {
  padding: 2% 0% 1% 0%;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 260px; */
  height: calc(100vh - 307px);
}
.review-usage-root-listing-card {
  display: flex;
  height: 48px;
  background-color: #f8f9fa;
}
.review-usage-root-listing-card-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  background: #dc4c35b5;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.review-usage-root-listing-card-content {
  flex: 1 1;
  display: flex;
  align-items: center;
}
.review-usage-root-listing-card-content-avatar {
  /* text-transform: uppercase; */
  height: 20px;
  width: 20px;
  /* line-height: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  background-color: #8394ab; */
  margin-right: 8px;
}
.review-usage-root-listing-card-content-name {
  font-size: 0.8rem;
  color: #0000009c;
}
.review-usage-root-listing-card-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.review-usage-root-listing-card-button-state {
  border: 1px solid #000000;
  border-radius: 25px;
  font-size: 13px;
  margin-right: 9%;
  padding: 1% 4%;
  cursor: pointer;
  font-weight: 700;
  background-color: transparent;
}

.review-usage-root-productive {
  color: green;
  border-color: green;
}
/* .review-usage-root-green:hover {
  background-color: darkolivegreen;
  color: white;
  border-color: darkolivegreen;
} */
.review-usage-root-neutral {
  color: #6c758a;
  border-color: #6c758a;
}
/* .review-usage-root-gray:hover {
  background-color: #718093;
  color: white;
  border-color: gray;
} */
.review-usage-root-unproductive {
  color: red;
  border-color: red;
}
.review-page-note-text{
  font-size: 13px;
  color:gray;
  /* margin:0; */
}
/* .review-usage-root-red:hover {
  background-color: #dc3545;
  color: white;
  border-color: #dc3545;
} */
/* review-usage-root div css end*/

/* review-usage-root-data div css start*/
.review-usage-root-data {
  width: 100%;
  display: grid;
  grid-template-columns: 370px minmax(0, 1fr);
  grid-gap: 16px;
  gap: 16px;
}

.review-usage-root-data-item {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

/* review-usage-root-data-app-listing div css start*/
.review-usage-root-data-app-listing-header {
  display: flex;
  padding: 0 24px;
  border-bottom: 1px solid #e8ebee;
}
.review-usage-root-data-app-listing-header-title {
  display: flex;
  align-items: center;
  height: 64px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #62839b;
}
.review-usage-root-data-app-listing-header-action {
  display: flex;
  flex: 1 1;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #695eee;
}
.review-usage-root-data-app-listing-header-action-button {
  cursor: pointer;
  overflow: hidden;
  margin-left: auto;
}
.review-usage-root-data-app-listing-content {
  height: 510px;
  overflow: auto;
}
.review-usage-root-data-app-listing-content-data-card {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 12px;
  padding-left: 12px;
  /* padding-right: 25px; */
  padding-right: 15px;
  background: #fff;
  border: 1px solid #e8ebee;
  box-shadow: 0 2px 6px rgba(53, 59, 72, 0.05);
  border-radius: 5px;
  cursor: pointer;
}
.review-usage-root-data-app-listing-content-data-card-icon {
  height: 24px;
  width: 24px;
}
.review-usage-root-data-app-listing-content-data-card-name {
  flex: 1 1;
  font-weight: 600;
  margin: 0 0px;
  font-size: 14px;
  color: #2c3e50;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.review-usage-root-data-app-listing-content-data-card-time {
  font-weight: 600;
  font-size: 12px;
  color: #718093;
  margin-bottom: 0;
}
/* review-usage-root-data-app-listing div css end*/

/* review-usage-root-data-app-listing-data div css start*/

.review-usage-website-usage-per-employee {
  padding: 24px 24px 0;
  height:500px;
  /* overflow: hidden; */
  overflow-y: scroll;
}
.review-usage-website-usage-per-employee-table-content {
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  color: #34495e;
  padding-top: 1px;

  border-left: 1px solid #e8ebee;
  border-top: 1px solid #e8ebee;
  border-bottom: 1px solid #e8ebee;
  border-right: 1px solid #e8ebee;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

  margin-bottom: 10px;
  box-shadow: 0 2px 5px 0 rgba(53, 59, 72, 0.05);
}
.review-usage-website-usage-per-employee-header {
  height: 38px;
  min-width: 100%;
  overflow-x: hidden;
  background: 0 0;
  border-bottom: 0px solid #e8ebee;
  border-top: 0px solid #e8ebee;

  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  color: #718093;

  /* display: flex;
    align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  min-width: 300px;
  max-width: 300px;
}
.review-usage-website-usage-per-employee-header-first {
  min-width: 300px;
  max-width: 300px;
}
.review-usage-website-usage-per-employee-table-content-avatar {
  display: inline-block;
  margin-right: 12px;
  height: 24px;
  width: 24px;
  border-radius: 25px;
  background-color: rgb(64, 186, 171);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.review-usage-website-usage-per-employee-avatar {
  display: inline-block;
  margin-right: 12px;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background-color: rgb(227, 117, 117);
}
.review-usage-website-chart {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  overflow: hidden;
  /* margin-top: 6%; */
}
/* review-usage-root-data-app-listing-data div css end*/

/* review-usage-root-data div css end*/
/* review-usage-root div css end*/
.review-usage-root-title {
  margin-bottom: 0.9rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000000b5;
}
.review-usage-apps-and-website {
  padding: 5% 0% 2% 0%;
}
.app-summary-status {
  font-size: 13px;
  font-weight: 600;
  color: #718093;
  margin: 0 0px;
  text-transform: capitalize;
}
.app-summary-neutral{
  color:#6c757d !important;
}
.app-summary-productive{
  color:#28A745 !important;
}
.app-summary-unproductive{
  color:#DC3545 !important;
}
.app-summary-listing-whole-div {
  flex:1 1;
  margin: 0 8px;
}
/* .review-usage-app-and-website-tab-bar-link {
  border-bottom: 3px solid #695eee;
  color: #695eee;
} */
.review-usage-apps-and-website-tab-bar-link {
  padding: 0.97rem 1rem;
  font-size: 0.78rem;
}
.review-usage-apps-and-website-tab-bar {
  padding: 0 0.8rem;
}
.review-usage-root-data-app-listing-content-data-card-selected {
  background-color: aliceblue;
  border: 1px solid #1abc9c5c;
}
.review-usage-root-data-app-listing-content-data-selected {
  /* color: #0000ff8c; */
}
.review-usage-root-productive {
  min-width: 100px !important;
}
.review-usage-root-neutral {
  min-width: 80px;
}
.review-usage-root-unproductive {
  min-width: 120px;
}
.review-usage-website-usage-per-employee table td, .review-usage-website-usage-per-employee table th {
  vertical-align: middle !important;
}

.review-usage-website-usage-per-employee-table-profile-content{
  display: flex;
  width:230px;
}

.review-usage-user-profile {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.review-usage-user-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.review-app-use-per-employee-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-top: 3px;
  color: #34495e !important;
}
.review-app-use-per-team-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  width: 135px;
}
.review-usage-root-listing-whole-div{
    display: flex;
}
.exclude-idle-time-app-listing-whole-div{
  border: 1px solid #8080802e;
  padding: 13px !important;
}
.review-usage-root-listing-reviewed-button{
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 480px) {
  .review-usage-root-listing-whole-div{
    display: block;
    margin-bottom: 1.1rem !important;
  }
  .review-usage-root-listing-whole-div-names{
    margin-bottom: 10px;
  }
  .review-usage-root-listing-reviewed-button{
    font-size: 12px;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .review-usage-root-data{
    grid-template-columns:1fr;
  }
  .tab-content>.active {
    display: grid !important;
  }
  .review-usage-root-listing{
    padding: 7% 0% 1% 0%;
  }
  .head-risk-user-history-list-heading{
    font-weight: 600 !important;
  }
}
.review-usage-root-listing-card-content-copy-button {
  margin-left: 13px;
  color: #3b68eb !important;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 13px;
}
.review-usage-root-listing-card-content-copy-button:hover {
  color: #3b68eb !important;
  text-decoration: underline !important;
}
.review-page-heading-text-div{
  display: flex;
}
.review-page-export-div{
  margin-left: auto;
    margin-right: 0;
}
.review-apps-load-more-button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.review-usage-search-root-div{
  display: flex;
  justify-content: space-around;
  grid-gap: 1%;
  gap: 1%;
}
.review-usage-search-root-search-button-div{
  width: 20%;
}
.review-usage-root-listing-card-content-copied-status{
  margin-left: 13px;
  color: green !important;
  font-size: 15px;
  font-weight: 600;
}
.fade.top-review-app-list-card-toolbar-alert-text.alert.alert-warning.show {
  height: 40px;
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #808080b3;
  margin-bottom: 0px;
}
.review-usage-light-color-no-data{
  color: #585757;
}
/* .review-usage-search-root-search-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8a8a8b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(154 155 157 / 25%);
} */
.review-usage-root-title{
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
}

.search-not-found-text {
  width: 700px;
  word-wrap: break-word;
}
.review-usage-light-color {
  color: rgb(0 0 0 / 30%);
}
.top-review-usage-root-note-text {
  font-size: 13px;
  color: grey;
  margin-left: 10px;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .review-usage-root-listing-card-content-title {
    width: 200px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  } }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
  .review-usage-root-listing-card-content-title {
    width: 250px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .review-usage-root-listing-card-content-title {
    width: 355px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  } }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .review-usage-root-listing-card-content-title {
    width: 308px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .review-usage-root-listing-card-content-title {
    width: 665px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:1281px) { /* hi-res laptops and desktops */
  .review-usage-root-listing-card-content-title {
    width: 417px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }

  .review-comman-div{
    /* width:76%; */
  }
  .review-comman-div-split{
    min-width: 21rem;
    max-width: 23rem;
  }

}

  .review-usage-search-root-div-mobile{
    flex-direction: column;
  }
  .review-usage-search-root-search-button-div-mobile{
    width:100%;
    margin-top:10px;
  }
  .review-usage-load-more-button{
    padding: 5px 14px;
    font-size: 12px;
  }
  
  .review-loading-svg{
    font-size: 1.6rem;
    color: #266dfb;
    animation: spin 0.5s linear infinite;
  }
  .review-header-datas {
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
    display: flex;
    margin-bottom: 1rem;
    width:100%
}
.review-header-datas-app {
  width: 445px;
  margin-left: 10px;
}

.review-header-datas-hours{
  width: 237px;
}
.review-usage-root-listing-card-content-title{

}
.exclude-idle-time-app-content-remove-button{
  cursor: pointer;
}
.exclude-idle-time-app-content-add-button {
  padding: 2px 10px;
  font-size: 14px;
  border: 1px solid #80808070;
  cursor: pointer;
}
.exclude-idle-time-app-modal-text-1,.exclude-idle-time-app-modal-text-2 {
  font-size: 15px;
  margin-bottom: 15px;
}
.exclude-idle-time-app-modal-footer {
  display: flex;
  justify-content: left;
}
.exclude-idle-time-app-content-idle-label {
  margin-bottom: 10px;
  color: #7c81d9;
  font-size: 14px;
}
.exclude-idle-app-search-not-found-text{
 word-wrap: break-word;
  color: gray;
}
.exclude-idle-app-root-list-no-text {
  font-size: .9rem;
  color: #2d3748;
  padding: 0.5rem 1rem 1rem 0rem !important;
}
.review-usage-root-app-listing-unselect-all-div {
    margin: 0 0 1rem;
    font-weight: 500;
    color: #646161;
    font-size: 15px;
}
.review-usage-root-app-listing-unselect-all-checkbox-label{
    margin-top: 1px;
    margin-left: 5px;
}
.review-usage-root-app-listing-unselect-all-checkbox,.review-usage-root-app-listing-unselect-all-checkbox-label{
    cursor: pointer;
}
.app-summary-usage-load-more-button{
    padding: 5px 14px;
    font-size: 12px;
    margin-left: 33px;
}
.app-summary-apps-load-more-button {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}
.app-summary-user-no-data{
    color:gray;
    text-align: center;
    font-size: 14px;
}
.timelapse-root {
  padding: 2rem;
}
.timelapse-cards-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.timelapse-card-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-height: 100%;
  min-height: 80%;
  /* border-radius: 5px; */
  opacity: 0.8;
  height: 128px;
}
.timelapse-image-card{
  /* padding: 5px;
  background-color: #eef3fc; */
}

.timelapse-footer-card{
  display: flex;
  justify-content: space-between;
  background-color: #c4c4c463;
  font-size: 12px;
  padding: .4rem .5rem;
  color: #343a40;
  /* font-weight: 800; */
}
/* .timelapse-card-date,
.timelapse-card-time {
  /* background-color: #0000004d; */
  /* font-size: 0.6rem;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 5px; */
  /* color: white; */
  /* color: black;
  font-weight: 800; */
/* } */ 
/* .timelapse-card-date {
  position: absolute;
  right: 0;
  bottom: 3%;
  left: 3%;
} */
.timelapse-card-time {
  position: absolute;
  right: 3%;
  bottom: 3%;
}
.timelapse-card-video-play {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  z-index: 1;
  border-radius: 5px;
}
.timelapse-card-play-icon {
  fill: #fff;
  font-size: 2rem;
  filter: drop-shadow(0 0 4px #00000040);
}
.timelapse-date-header-root {
  margin: 1rem 0 1.5rem;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 1;
}
.timelapse-date-header {
  /* font-weight: 600; */
  /* color: #000000bf;
  font-size: 0.9rem; */
  /* border-radius: 1.15em; */
  /* background-color: rgb(255, 255, 255); */
  /* width: fit-content; */
  padding: 2px 20px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #c5c5f1;
  width: -moz-fit-content;
  width: fit-content;
}
.timelapse-date-header-label-icon{
  margin-right: 10px;
  color: #495057eb;
}
.timelapse-date-header-label-icon svg{
  margin-top: -4px;
}
.timelapse-user-header-root {
  margin: 1rem 0 1.3rem;
  display: flex;
  justify-content: center;
  /* position: sticky; */
  top: 0px;
  z-index: 1;
}

.timelapse-user-header {
  /* font-weight: 600;
  color: #000000bf;
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 20px;
  border: 1px solid #0000001f;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 7px -1px; */


  border-radius: 3px;
    background-color: #fff;
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 20px;
    margin-left: auto;

}
.timelapse-user-header-icon{
  font-size: 13px;
  margin-right: 10px;
  color: rgb(73 80 87 / 69%);
}
.timelapse-user-header-icon svg {
  margin-top: -4px;
  font-size: 15px;
}
.timelapse-card {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  transition: all 200ms ease;
  cursor: pointer;
  /* height: 160px; */
}

.timelapse-card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: translateY(-4px);
}

.timelapse-card img {
  /* border-radius: 5px; */
}

.timelapse-video-modal-body video {
  outline: none;
  height: auto;
}

svg.timelapse-video-modal-left-nav,
svg.timelapse-video-modal-right-nav {
  position: absolute;
  top: 46%;
  bottom: 0;
  color: #696767db;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  z-index: 1;
}
svg.timelapse-video-modal-left-nav {
  left: 1%;
  right: auto;
}
svg.timelapse-video-modal-right-nav {
  right: 1%;
  left: auto;
}
.timelapse-video-modal-close {
  position: absolute;
  right: 0;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  color: #696767db;
  margin: 0.7rem;
  opacity: 0.6;
}
.timelapse-video-modal-close:hover {
  opacity: 0.9;
}
.timelapse-user-header-root-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
}
.timelapse-see-more-card{
  /* background: #fff; */
  /* border-radius: 5px; */
  margin-left: auto;
  margin-right: 0;
  /* height: 170px; */
  border-color: none !important;
}
.timelapse-card-see-more-button{
  /* position: absolute;
  top: 41%;
  left: 6%; */
  color: #57575afa;
  text-decoration: underline;
  cursor: pointer;
}
.timelapse-video-modal-body{
  padding: 1rem 3rem !important;
}
.timelapse-video-modal-personal-header {
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;
}

.timelapse-video-modal-personal-header-details {
  display: flex;
}
.timelapse-video-modal-site-icon-content {
  height: 47px;
  width: 47px;
  line-height: 21px;
  font-size: 23px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #8394ab;
}
.timelapse-video-modal-site-icon {
  height: 23px;
  vertical-align: initial;
  margin-top: 12px;
}
.timelapse-video-modal-site-icon-details {
  margin-top: -1px;
}
.timelapse-video-modal-site-name {
  font-weight: 600;
  font-size: 22px;
  color: #000000a6;
}
.timelapse-video-modal-user-name {
  font-size: 14px;
  float: left;
  color: #f1306f;
  margin-top: -3px;
}
.timelapse-video-modal-time-details {
  font-size: 15px;
  margin-right: 11px;
}
svg.timelapse-video-modal-time-icon {
  font-size: 14px;
  margin-right: 4px;
  margin-top: -2px;
}
.timelapse-modal-avatar-profile {
  width: 47px;
  height: 47px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.timelapse-modal-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .timelapse-user-header-root{
    display: inline;
  }
  .timelapse-video-modal-site-name{
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.PlanUpdateMsg-root{
  padding:2rem;
  display: flex;
  justify-content: center;
}
.PlanUpdateMsg-header-card {
  padding: 2rem;
  background: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 5%) 1px 1px 2px;
  color: rgb(51, 51, 51);
  font-family: LOGROCKET_AVENIR, sans-serif;
  font-size: 1.2rem;
}
.PlanUpdateMsg-text{
  margin-bottom: 1rem;
  color: #1e1e1e;
  font-weight: lighter;
  line-height: 135%;
  text-align: center;
}
.PlanUpdateMsg-button{
  font-weight: 700;
  font-size: 14px;
}
.PlanUpdateMsg-button-div {
  display: flex;
  justify-content: center;
}
.timelapse-warning-text {
  text-align: center;
  border: 0.5px solid #0000000f;
  padding: 24px;
}
.screenshot-card-warning-text-document-link{
  margin-top: 18px;
  color: gray;
  font-weight: bold;
  text-align: center;
}
.screenshot-card-warning-text-document-link a,.screenshot-card-warning-text-document-link-free-user a{
  color: #0000ffbd;
}
.screenshot-card-warning-text-document-link-free-user{
  margin-top: -18px;
  color: gray;
  font-weight: bold;
  text-align: center;
}
.timelapse-no-data-text{
  text-align: center;
}
.timelapse-maintenance-text {
  margin-top: 14px;
  font-size: 14px;
  color: orange;
  border: 1px solid #0000001a;
  border-radius: 3px;
  padding: 10px;
}
.timelapse-header-card {
  display: flex;
  justify-content: end;
  background-color: #c4c4c463;
  font-size: 12px;
  padding: 0.4rem 0.5rem;
  color: #343a40;
  /* font-weight: 800; */
}
.timelapse-card-no-timelapse {
  height: 194px !important;
  cursor: default;
}
.timelapse-card-img-no-timelapse {
  height: 163px;
}
.timelapse-card-no-screenshot {
  font-size: 13px;
  padding: 21px;
  cursor: default;
}
.timelapse-image-card-play-no-timelapse{
  cursor: default;
}
.timelapse-card-video-generate-loading{
  text-align: center !important;
}
svg.timelapse-card-no-screenshot-icon {
  margin:-17px 5px;
}
.timelapse-card-add-icon {
  fill: #504f4fa3;
  cursor: pointer !important;
}
/* .timelapse-image-card-play-icon-timelapse{
  cursor: pointer !important;
} */
.timelapse-card-video-generate-icon {
  font-size: 14px;
  color: #007bff;
}
.timelapse-card-video-generate-icon:hover{
color: #fff;
background-color: #007bff;
border-color: #007bff;
}
.timelapse-announcement-text {
  margin: 14px 0px 20px 0px;
  font-size: 14px;
  color: #4e4d4d;
  border: 1px solid #0000001a;
  border-radius: 3px;
  padding: 15px;
}
.timelapse-announcement-secondary-text {
  margin-bottom: 10px;
}
.timelapse-announcement-button-text {
  font-weight: 800;
}
.timelapse-card-deleted{
  color: #ff0000b5;
}
.timelapse-card-no-screenshot-estimated-time-label {
  background-color: #a9a9a942; 
  padding: 7px 10px;
  border-radius: 3px;
  margin: 3px 8px;
  color: #1d7918;
}
.timelapse-card-no-screenshot-estimated-time {
  margin-top: 4px;
  display: flex;
}
.timelapse-card-no-screenshot-estimated-icon{
  background-color: #a9a9a942;
  padding: 7px 10px;
  border-radius: 3px;
  margin-left: 2px;
  cursor: pointer !important;
  color: #1d7918;
  margin: 3px 8px 3px 0px;
}
.timelapse-card-refresh-icon {
  color: #1d7918;
  cursor: pointer !important;
  /* margin-top: -2px; */

}
.timelapse-card-refresh-icon-loading{
  font-size: 1.3rem;
  color: #266dfb;
  animation: spin 1.0s linear infinite;
}
.timelapse-card-export-div {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  width: -moz-fit-content !important;
  width: fit-content !important;
  border-radius: 5px;
  padding: 0.7rem;
  justify-content: space-between;
}
.scheduled-reports-card {
  margin: 2rem;
}
.scheduled-reports-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
}
.scheduled-typr-err{
  font-size: 13px;
    color: red;
    margin-top: 5px;
}
.scheduled-type-err-span{
  color: #495057;
}
.scheduled-type-err-a{
  color: #495057;
  text-decoration: underline;
}
.scheduled-type-err-list {
  color: #808080b3;
  text-decoration: underline;
}
.scheduled-typr-err-daily-enable-expected-clockin-track-settings {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px 0px 20px 0px;
}
.scheduled-reports-card-toolbar {
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
}
.scheduled-reports-card-create-report-button {
  font-size: 0.75rem;
  font-weight: 700;
}
.scheduled-reports-card-icon-wrapper {
  padding: 0.3rem 0 0.3rem 0.5rem;
  color: #49505752;
  display: inline-flex;
}
.scheduled-reports-card-searchbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.scheduled-reports-card-searchbox-wrapper > .form-control {
  border: none;
  border-radius: 0;
}
.scheduled-reports-card-content {
    /* border: 0.5px solid #0000001a; */
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.scheduled-reports-card-data {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #6c757d !important;
}
.scheduled-reports-card-table-header {
  color: #868686;
  border: none;
}
.scheduled-reports-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
}
/* .scheduled-reports-card-table tbody {
    border-spacing: 0 18px;
} */
.scheduled-reports-card-table thead th {
  border: none;
}
.scheduled-reports-card-table th,
.scheduled-reports-card-table td {
  border: none;
}
.scheduled-reports-card-table-data-details {
  padding: 1rem 0;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Report Modal CSS */
.scheduled-reports-new-report-modal-title {
  font-size: 1rem;
  font-weight: 700;
  color: #000000a8;
}
.scheduled-reports-new-report-modal-header .close {
  font-size: 1.2rem;
  margin-top: -3%;
}

.scheduled-reports-new-report-modal-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.scheduled-reports-new-report-modal-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000cf !important;
}
.scheduled-reports-new-report-modal-frequency {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 0.5rem 0;
}
.scheduled-reports-new-report-modal-frequency-radio {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}

.scheduled-reports-new-report-modal-frequency-label-name {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-checkbox-button > input[type="checkbox"],
.scheduled-reports-new-report-modal-radion-button > input[type="radio"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-checkbox-button > label,
.scheduled-reports-new-report-modal-radion-button > label {
  font-size: 0.95rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-divider {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.scheduled-reports-new-report-modal-content > * {
  width: 50%;
}
.scheduled-reports-new-report-modal-content {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}
.scheduled-reports-new-report-modal-filter {
  width: 100%;
  height: auto;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.scheduled-reports-new-report-modal-content-checkbox,
.scheduled-reports-new-report-modal-filter-radio {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal .modal-dialog {
  max-width: 45%;
}
.scheduled-reports-new-report-modal-filter-radio {
  margin-bottom: 0.5rem;
}
.scheduled-reports-new-report-modal-team {
  margin-bottom: 0rem !important;
}
/* .scheduled-reports-new-report-modal-cancel-button,
.scheduled-reports-new-report-modal-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
} */
.scheduled-reports-new-report-modal-button {
  margin-left: auto;
  margin-right: 0;
}
.scheduled-reports-new-report-modal-save-button {
  font-weight: bold;
}
.scheduled-reports-card-title {
  font-size: 1.2rem;
  color: #4155eae3;
  font-weight: 700;
  text-transform: capitalize;
}
.scheduled-reports-new-report-modal-body .form-label.error {
  font-size: 0.9rem;
  color: red;
  float: right;
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.scheduled-reports-new-report-modal-body .form-error {
  font-size: smaller;
  color: red;
  margin-bottom: 0.25rem;
  text-align: center;
}
.scheduled-reports-new-report-modal-body .form-label {
  font-size: smaller;
  font-weight: 500;
  color: #656565;
}
.scheduled-reports-card-table-body {
  color: #333;
}
.scheduled-reports-card-table-body .no-result {
  color: #868686;
  font-size: 1rem;
}
.action-menu #dropdown-basic {
  padding: 0;
  background: none;
  border: none;
  position: initial;
  top: auto;
  right: auto;
  color: black;
  font-size: 23px;
}
.action-menu #dropdown-basic::after{
  content: none;
}
.scheduled-reports-card-table-body td {
  vertical-align: middle;
}
.scheduled-reports-card-table-data-details-content{
  width:45% !important;
}
.scheduled-reports-card-table-data-details-report-name{
  width: 35% !important;
}
.scheduled-reports-card-table-data-details-report-type{
  width: 17% !important;
}
.schedule-report-modal-button{
  font-size: 1.2rem;
  padding: 0.4rem 2rem;
}
.api-tokens-list-card-toolbar-alert-text-new{
font-size: 14px;
}
.report-no-data{
  text-align: center;
  font-size: 1rem;
  color: black;
}
.scheduled-reports-new-report-modal-note-message{
  font-size: 12px;
  color: #000000b3;
  font-weight: 600;
  margin-bottom: 25px;
}
.scheduled-reports-new-report-modal-create-button{
  margin-bottom: 10px;
}
.scheduled-reports-card-table-data-edit-icon{
  font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
    
}
.scheduled-reports-card-table-data-edit-text{
  margin-bottom: 0;
}
.scheduled-reports-card-table-data-icon{
  /* float:right; */
}
.employee-report-text{
  font-size: 14px;
  color: #343a40;
  font-weight: 500;
}
.scheduled-reports-card-table-data-icon-whole-div{
  display: flex;
  justify-content: space-evenly;
}
.scheduled-reports-card-table-data-icon-div{
  display: flex;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  color: #6c757d;
}
.scheduled-reports-card-table-data-icon-div:hover{
  padding: 2px 10px 2px 10px;
  background-color: #e9ecef70;
  border-radius: 5px;
  color: #6c757d;
}
.access-control-content {
  padding: 2rem;
}
.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.access-control-content-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.access-control-tabs {
  color: #000000d4;
  font-size: 0.9rem;
}

.access-control-content-tabs-head.nav-tabs {
  border-bottom: none;
}
.access-control-content-tabs-head .nav-link.active {
  color: #007bff;
  margin-bottom: 0px;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}
.access-control-content-tabs-head .nav-link {
  color: #495057;
  font-size: 0.9rem;
}
.access-control-content-tabs-head .nav-link:hover {
  border-color: transparent !important;
}
.access-control-content-tabs-head .nav-link.active {
  border-bottom: 2px solid #007bff;
}
.access-control-content-tabs-head .nav-link.active:hover {
  border-bottom: 2px solid #007bff !important;
}
.access-control-content-tabs-head .nav-link:hover {
  color: blue;
}

/* Tab Content CSS */
.access-control-section {
  padding: 2rem;
  border: 1px solid #00000030;
  padding: 1rem;
}
.access-control-content-data-checkbox {
  width: 100%;
  height: auto;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.scheduled-reports-new-report-modal-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000cf !important;
}

.access-control-content-checkbox-button {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}

.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}
.access-control-content-checkbox-label-name {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
  cursor: pointer;
  font-weight: 600;
  color: #000000ab;
}
.access-control-content-organisation-data-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.access-control-content-sub-heading-name {
  margin-top: 1rem;
  margin-bottom: 0.9rem;
}
.access-control-content-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
  font-weight: bold;
}
.access-control-content-button {
  margin-left: 39%;
  margin-right: 0;
  margin-top: 5%;
  margin-bottom: 5%;
}

.access-control-checkbox {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}
.access-control-table td,
.access-control-table th,
.access-control-table thead th {
  border: none;
}
.access-control-tbody {
  font-size: 0.8rem;
}
.access-control-checkbox-td,
.access-control-checkbox-th {
  text-align: center;
}

.api-tokens-list-card {
  padding: 2rem;
    display: flex;
    flex-direction: column;
}
.api-tokens-list-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
}
.api-tokens-list-card-toolbar {
  /* margin: 2rem 0;
  display: flex; */
}
.api-tokens-list-card-create-new-token-button {
  font-size: 1rem;
  font-weight: 700;
  float: right;
  height: 40px;
}
.api-tokens-list-card-searchbox {
  float: right;
  font-size: 0.8rem;
  padding: 0.375rem 1.5rem !important;
}
.api-tokens-list-card-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0.5rem;
  color: #49505752;
  font-size: 0.9rem;
}
.api-tokens-list-card-searchbox-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: 0;
}

.api-tokens-list-card-data {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000b5;
}
.api-tokens-list-card-table-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.api-tokens-list-card-table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
/* .api-tokens-list-card-table tbody {
      border-spacing: 0 18px;
  } */
.api-tokens-list-card-table thead th {
  border: none;
}
.api-tokens-list-card-table th,
.api-tokens-list-card-table td {
  border: none;
}
.api-tokens-list-card-table-data-details {
  margin-bottom: 2rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
}

.warning {
  border: 0;
  border-radius: 5px;
  color: #a36200;
  padding: 13px 19px;
  font-size: 14px;
  background-color: #fff2c9;
  font-weight: 600;
  margin-top: 2rem;
}

.generateTokenHeader {
  text-align: center;
  margin-top: 3rem;
}
.generateTokenHeader img {
  width: 250px;
  height: 200px;
}

.generate-token-btn {
  border-width: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-color: #266dfb !important;
  background-color: #266dfb !important;
  color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  margin-bottom: 15px;
  font-weight: 500;
  opacity: 1;
  font-size: 0.9rem;
  float: center;
}

.generate-token-btn:hover:not(:disabled),
.generate-token-btn:active:not(:disabled),
.generate-token-btn:focus:not(:disabled) {
  border-color: #266dfb !important;
  background-color: #266dfb !important;
  color: #fff;
  opacity: 0.8;
}

.create-api-tokens-name-modal-header .close {
  font-size: 1.2rem;
  margin-top: -5%;
}
.create-api-tokens-name-modal-title {
  font-size: 1rem;
  font-weight: 700;
  color: #000000a8;
}

.create-api-tokens-modal-label-name {
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000ab;
}

.create-api-tokens-modal-label-input {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.api-tokens-list-card-table-body{
  font-size: 0.9rem;
}

/* .api-token-edit-menu #dropdown-basic {
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  margin-right: -0.5rem;
} */

.api-token-edit-menu #dropdown-basic {
  top: -9px;
  padding: 0.275rem .65rem;
}
.api-token-edit-menu-icon {
  float: right;
  color: #00000096;
  float: right;
  font-size: 1.3rem;
  margin-top: 8%;
}
.api-token-delete-task {
  color: red;
}
.api-token-edit-menu-td{
  vertical-align: middle;
}

.api-token-edit-menu #dropdown-basic {
  background-color: transparent;
  color: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  left: -35px;
}
/* #dropdown-basic{
  position: absolute;
  top: 31px;
  right: -22px;
} */
.api-token-no-data{
  font-size:1rem;
  text-align: center;
  font-weight: 600;
  margin: 2rem 0;
}
/* .create-api-tokens-name-modal-body{
  font-size: 1rem;
} */

.create-api-tokens-modal-label-data {
  background-color: #8080802e;
  padding: 12px;
  border-radius: 4px;
  vertical-align: middle;
  overflow-wrap: break-word;
  font-size: 14px;
}
.api-tokens-list-card-toolbar{
  display: flex;
  justify-content: space-between;
}
.fade.api-tokens-list-card-toolbar-alert-text.alert.alert-warning.show {
  height: 40px;
  /* vertical-align: middle; */
  /* text-align: center; */
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #808080b3;
}
.api-token-reference-link{
  font-size: 14px;
}
.api-token-reference-link-tag{
  color:blue;
}

.manage-teams-root {
  padding: 2rem;
}

/* Teams-root-header div css start*/
.error{
  color: red;
  font-size: 15px;
  float: right;
}
.error + .form-control {
  border-color: #F0624D;
}
.Teams-root-header {
  /* display: flex; */
  justify-content: space-between;
}
.Teams-root-header-left-side {
  display: flex;
  align-items: center;
}
.Teams-root-content-data-details-whole-div-employee-count{
  margin:0;
}
@media screen and (max-width: 480px) {
  .Teams-root-content-data-details-avatar-circle{
    margin-top: 11px;
  }
  .Teams-root-content{
    margin-top: -23px !important;
  }
}
.Teams-root-header-left-side-button {
  /* background: #695eee; */
  /* color: #fff; */
  margin-right: 24px;
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  margin-left: auto;
  margin-right: 0px;
}
.upgrade-plan-button-color{
  
    padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      background-color: #007bffa8;
      border-color: #007bff;
      /* line-height: 1.5; */
      border-radius: .25rem;
  
}
.Teams-root-header-left-side-searchbox-wrapper {
  /* position: relative;
  margin-left: auto;
  margin-right: 0px; */
}
.Teams-root-header-left-side-searchbox {
  float: right;
  font-size: 0.8rem;
}
.Teams-root-header-left-side-searchbox:focus {
  box-shadow: none;
}
.Teams-root-header-left-side-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}

.Teams-root-header-right-side {
  display: flex;
  align-items: center;
}
.Teams-root-header-right-side-download {
  font-weight: 700;
  font-size: 14px;
  margin-right: 16px;
  color: #695eee;
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #695eee;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
/* Teams-root-header div css end*/

/* Teams-root-content div css start*/
.Teams-root-content {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000b5;
}
.Teams-root-content-data-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.Teams-root-content-data-details {
  margin-top: 1rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.9rem;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.7rem; */
}
.Teams-root-content-data-details-avatar {
  display: flex;
}
.Teams-root-content-data-details-avatar-circle {
  height: 30px;
  width: 30px;
  background-color: rgb(64, 186, 171);
  color: rgb(244 245 249);
  font-weight: bold;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  margin-right: 0.75rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.Teams-root-content-data-details-name-data {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #718093;
  overflow: hidden;
}
.Teams-root-content-data-details-name-data-team-name-count {
  margin: 0;
  font-size: 13px;
}
.Teams-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
}
.Teams-root-content-data-details-name-data-line {
  border-right: 1px solid gray;
  margin-left: 10px;
}
.Teams-root-content-table tbody tr {
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
}
.Teams-root-content-data-details-productive {
  color: #3cd2b8;
}
.Teams-root-content-data-details-unproductive {
  color: #ff4757;
}
.Teams-root-content-data-details-name-data-team-name-width {
  width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .Teams-root-content-data-details-name-data-team-name-width-td{
  width: 520px;
} */
/* Teams-root-content div css end*/

/* Teams-root div css end*/
.manage-team-modal-title.modal-title.h4 {
  font-size: 1rem;
}
.manage-team-modal-button {
  margin: auto;
  padding: 9px 0;
}
.manage-team-modal-close-button,
.manage-team-modal-save-button {
  font-size: 0.9rem;
  padding: 0.3rem 1.3rem;
  margin-right: 1rem;
  font-weight: 500;
 
}

.Teams-root-table-body tr td {
  vertical-align: middle !important;
}
.manage-teams-edit-menus #dropdown-basic {
  top: -9px;
  padding: 0.275rem .65rem;
}
.manage-teams-edit-menus #dropdown-basic {
  background-color: transparent;
  color:transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  left: -35px;
  /* margin-right: 1.5rem; */
}
.manage-teams-edit-menus-icon {
  color: #000000c7;
  float: right;
  font-size: 1.1rem;
  margin-top: -16%;
}
.manage-teams-edit-menus-delete-icon {
  color: red;
}
.Teams-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}


 .manage-teams-edit-task-label-name {
  font-size: 0.95rem;
  font-weight: 500;
  color: #000000ab;
}
.manage-teams-edit-task-label-input {
  font-size: 0.86rem !important;
  font-weight: 600 !important;
}
.delete-team-user-already-exist-text,.delete-team-user-already-exist-secondary-text{
  font-size: 16px;
  font-weight: 500;
  color: #545353;
}
.delete-team-user-already-exist-secondary-text{
  margin-top: 20px;
}
.delete-team-user-already-exist-name-listing-text {
  margin-top: 20px;
  font-weight: 600;
}
.delete-team-user-already-exist-name-listing-secondary-text div {
  margin-top: 5px;
  margin-left: 15px;
  color: #636262;
}
.manage-teams-edit-task-label-name-error {
  font-size: 13px;
}
html { scroll-behavior: smooth; }
.timesheet-settings-card {
  padding: 2rem;
}
/* .timesheet-settings-row{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
} */
.timesheet-settings-timzone-search{
  width: 75%;
  font-size: .9rem;
}
.track-setting-info-text{
  display: inline-block;
  padding: 0.8em;
  font-size: 75%;
  font-weight: 700;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #00000096;
  background-color: #ff000036;
}
.timesheet-settings-row-grid,.timesheet-settings-row-grid-1,.timesheet-settings-row-grid-2 {
  /* display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 15px; */
  /* align-items: start; */
  /* gap: 30px; */
  -moz-columns: 2 calc((100vw - 420px - 8rem) / 2);
       columns: 2 calc((100vw - 420px - 8rem) / 2);
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.timesheet-settings-row-grid-card {
  /* height: auto; */
  display: inline-block;
  margin-bottom: 1rem;
  width: calc((100vw - 420px - 6rem) /2);
  color: rgb(74, 85, 104);
  /* overflow: auto; */
  padding: 16px;
  background:rgba(196, 196, 196, 0.25);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(211, 211, 211);
  -o-border-image: initial;
     border-image: initial;
}
.track-setting-task-time-mapping-formik-add-form-start-time .rc-time-picker-clear{
  /* display: none; */
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span{
  float: right;

}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span a{
  color: #035fcb;
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning{
  opacity: 0.5;
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning-text{
  font-size: .9rem;
  margin-top: 8px;
}
.time-sheet-project-formik-add-form-edit-end-time {
  font-size: 10px;
   margin-left: 20px; 
  color: red;
  width: 140px;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 23px;
}
.schedule-stealth-mode-start-p{
  font-size: 11px;
  margin-left: 23px;
  margin-bottom: 2px;
}

.track-setting-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-input {
  border: 1px solid #F0624D !important;
}
.schedule-stealth-mode-start-div{
  display: flex;
}
.schedule-stealth-mode-label{
  font-size: 0.9rem;
  margin-left: 12%;
}
.timesheet-settings-card input.rc-time-picker-input {
  font-size: .8rem;
  color: #495057;
  padding: .375rem .75rem;
  line-height: 1.5;
  height: 38px;
  width:80%;
  margin-left: 12%;
}

.timesheet-settings-card a.rc-time-picker-clear {
  top: 6px;
  width: 60px;
}
.checkbox-date{
  margin-left: 0.5rem;
}
.timesheet-settings-card .form-control {
  width: 75% ;
  font-size: 0.9rem;
}
.time-form{
  width: 45% !important ;
}
.track-setting-radion-button{
  margin-bottom: 1rem;
}
.timesheet-settings-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
  text-align: center;
}
.timesheet-settings-modal-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
  font-weight: bold;
}
.timesheet-settings-worked-days-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 13px;
}
.timesheet-settings-label-name {
  font-size: 14px;
  font-weight: 500;
  color: #000000ab;
}
.timesheet-settings-working-hours-label {
  margin-bottom: 0.3rem;
}
.timesheet-settings-header-label-name {
  font-size: 0.87rem;
  color: #2b2a2a;
}
.timesheet-settings-working-hours {
  display: flex;
}

.timesheet-settings-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.timesheet-settings-worked-days-data {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem;
  font-size: 1rem; */
  padding: 0.375rem 0.55rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}

.timesheet-settings-worked-days-data input[type="checkbox"] {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}

.timesheet-settings-worked-days-data label {
  cursor: pointer;
}

.timesheet-settings-row > * {
  margin-bottom: 1rem;
}
.timesheet-settings-worked-days-selected {
  background-color: #007bff12;
  border: 1px solid #007bff;
}
.timesheet-settings-row-grid-card h6{
  color:black;
}
.user-employee-idel-threshold-dropdown {
  width: -moz-fit-content !important;
  width: fit-content !important;
  display: inline;
  margin-left: 5px;
}
.react-tooltip-free-setting{
  /* min-width:150px !important; */
  width:250px;
}
.track-setting-text-ques {
  font-size: 1.2rem;
    color: #808080ba;
    /* float: right; */
    margin-left: 3px;
}
.track-setting-tooltip-start .tooltip-inner{
  min-width:300px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.track-setting-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-bottom-color: #535C68 !important;
}
@media screen and (max-width: 480px) {
  .timesheet-settings-row-grid, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2{
    -moz-columns: initial;
         columns: initial;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .timesheet-settings-row-grid-card{
    width: -webkit-fill-available;
  }
  .timesheet-settings-worked-days-list{
    grid-template-columns: 1fr 1fr;
  }
  .timesheet-setting-sub-button{
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .timesheet-settings-card {
    padding: 2rem 2rem 0rem 2rem;
  }
  .timesheet-setting-sub-button-div{
    display: flex;
    justify-content: center;
  }
}

.track-settingts-checkbox input {
  margin-right: 7px;
}
.timesheet-settings-row-grid-card-manual-time-card-header {
  display: flex;
  justify-content: flex-start;
}
.timesheet-settings-row-grid-card-manual-time-card-header span a{
  color: blue !important;
  font-size: 15px;
  margin-left: 10px;
}
.track-settings-formik-add-form-start-time input::-moz-placeholder{
  opacity: 1;
}
.track-settings-formik-add-form-start-time input::placeholder{
  opacity: 1;
}
.track-settings-formik-add-form-start-time .rc-time-picker-input{
  font-size: 0.9rem !important;
  margin-left: 0% !important;
  width: 88% !important;
}
.track-settings-alert-start-time .rc-time-picker-clear,.track-settings-alert-end-time .rc-time-picker-clear{
  display: none;
}
/* .timesheet-settings-row-grid-card-internet-interrupt-label {
  display: flex;
} */
.timesheet-settings-row-grid-card-internet-interrupt-label-include {
  margin-right: 13px;
}
.timesheet-settings-row-grid-card-internet-interrupt-label-include label input, .timesheet-settings-row-grid-card-internet-interrupt-label-exclude label input {
  margin-right: 4px;
}
.desktop-login {
  display: grid;
  place-items: center;
  height: 100%;
}

.login-field-error {
  border: 2px solid #ff6565;
}

.login-field-error-message {
  color: #ff6565;
  font-size: small;
  margin-bottom: 0.5rem;
}

.desktop-login-form {
  width: 300px;
}

.personal-dashboard {
  padding: 25px;
}

.pd-stats-grid {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}

.pd-sg-user-details {
  padding: 16px;
  border-radius: 5px;
}

.pd-sg-user-details .header {
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.pd-sg-user-details .content {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}

.pd-sg-item {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-sg-item .header {
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pd-sg-item .header img {
  max-width: 80%;
}

.pd-sg-item .content {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  margin: 0;
}

.pd-sg-item .content.disabled {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.pd-sg-item.good .content {
  color: #54985a;
}

.pd-app-usage {
  display: grid;
  margin: 30px 0;
}

.pd-app-usage > * {
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-tal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.pd-tal-header > * {
  margin: 0;
}

.pd-tal-content {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
}

.pd-tal-content.disabled {
  place-items: center;
  height: 100%;
}

.pd-tal-app {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.35rem 1rem;
  color: rgba(0, 0, 0, 0.8);
}

.pd-tal-app.productive {
  border: 1px solid #2ecc71;
  background-color: #2ecc7115;
}

.pd-tal-app.unproductive {
  border: 1px solid #e74c3c;
  background-color: #e74c3c15;
}

.pd-tal-app.neutral {
  border: 1px solid #bdc3c7;
  background-color: #bdc3c715;
}

.pd-tal-app-icon {
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 7px;
}

.pd-tal-app-name {
  flex: 1 1;
  margin: 0;
  font-size: 0.95rem;
  font-weight: 500;
  margin-right: 7px;
  max-width: 264px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pd-tal-app-status {
  font-size: smaller;
  text-transform: capitalize;
  width: 90px;
  text-align: left;
}

.pd-tal-app.productive .pd-tal-app-status {
  color: #2ecc71;
}

.pd-tal-app.unproductive .pd-tal-app-status {
  color: #e74c3c;
}

.pd-tal-app.neutral .pd-tal-app-status {
  color: #bdc3c7;
}

.pd-work-chart {
  height: 460px;
  padding: 16px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-top-apps-chart {
  height: 513px;
}

.pd-stats {
  margin-bottom: 30px;
}

.pd-chart-loader {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pd-greetings {
  background-color: #fff;
  padding: 0 16px;
  margin-bottom: 28px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 270px;
  border: 1px solid #d3d3d3;
}

.pd-greetings-name {
  text-align: center;
  margin: 0.5rem auto 0.5rem;
}

.pd-progress-chart-wrapper {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  position: relative;
}

/* https://stackoverflow.com/a/39775254/10805856 */
.pd-progress-chart-wrapper svg > g > g:last-child,
.pd-top-apps-chart svg > g > g:last-child,
.pd-work-chart svg > g > g:last-child {
  pointer-events: none;
}

.pd-progress-chart-wrapper > div > div > div > div:first-child {
  z-index: 1;
}

.pd-progress-chart-content {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 132px;
  height: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: #eff8f7; */
  border-radius: 50%;
  box-shadow: 0px 0px 15px 2px #2ecc7120;
}

.pd-progress-chart-content.disabled {
  box-shadow: none;
}

.pd-progress-chart-content > * {
  margin: 0;
}

.pd-progress-chart-content > p {
  font-size: smaller;
}

.pd-progress-chart-content > p:nth-child(2) {
  margin-bottom: 0.25rem;
}

.pd-progress-chart-content > h4 {
  font-weight: bold;
}

@media (min-width: 768px) {
  .pd-app-usage {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 30px;
    gap: 30px;
  }
}

@media (min-width: 1024px) {
  .pd-stats {
    display: flex;
  }

  .pd-greetings {
    margin-bottom: 0;
  }
}

.personal-dashboard-widget {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  color: rgba(0, 0, 0, 0.8);
}

.personal-dashboard-widget .pdw-first-section {
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 26px 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #fafafa;
}

.personal-dashboard-widget .pdw-second-section {
  padding: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.pdw-second-section .pdw-ss-header {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
}

.pdw-ss-header .pdw-ss-header-text {
  flex: 1 1;
  margin: 0;
  font-weight: 500;
  font-size: 1.15rem;
}

.pdw-ss-header > button {
  font-size: 0.8rem;
}

.pdw-greetings {
  text-align: center;
  margin: 0 auto 0.5rem;
}

.pdw-progress-chart-wrapper {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  position: relative;
}

.pdw-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin: 15px 0 0;
}

.pdw-history-item {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pdw-history-item .header {
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdw-history-item .header img {
  margin-left: 10px;
  max-width: 18px;
}

.pdw-history-item .content {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
}

.pdw-history-item.good .content {
  color: #54985a;
}

.pdw-ss-content {
  display: grid;
  max-width: 100%;
  grid-gap: 12px;
  gap: 12px;
}

.pdw-ss-app {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;
  opacity: 1;
  transition: all 200ms ease;
}

.pdw-ss-app.blur {
  opacity: 0.5;
}

.pdw-ss-app > *:not(:last-child) {
  margin-right: 4%;
}

.pdw-ss-app-icon {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}

.pdw-ss-app-name,
.pdw-ss-app-time {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: smaller;
  margin: 0;
  white-space: nowrap;
}

.pdw-ss-app-name {
  font-weight: bold;
  /* width: 100px; */
  flex: 0.6 1;
}

.pdw-ss-app.blur .pdw-ss-app-name {
  font-weight: normal;
}

.pdw-ss-app-time-progress {
  /* width: 110px; */
  flex: 0.6 1;
  height: 0.5rem;
}

.pdw-ss-app-time-progress.productive > .progress-bar {
  background-color: #2ecc71;
}

.pdw-ss-app-time-progress.unproductive > .progress-bar {
  background-color: #e74c3c;
}

.pdw-ss-app-time-progress.neutral > .progress-bar {
  background-color: #bdc3c7;
}

.pdw-ss-app-time {
  /* width: 55px; */
  flex: 0.3 1;
}

/* personal-app-review div css start*/
.personal-app-review {
  background-color: #fafafa;
}

.par-list {
  border-radius: 5px;
  background-color: #fff;
}

.par-list-tab-bar {
  position: relative;
  border-bottom: 1px solid #ccc;
}

.par-close-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #525252;
  top: 0;
  display: flex;
  align-items: center;
  padding: 5px 12px 10px;
  display: block;
}

.par-list-tab-bar a {
  color: gray;
}

.par-list-tab-bar a.active {
  border-bottom: 3px solid #007bff;
  color: #007bff;
}

.par-list-by-tabs {
  padding: 16px;
}

.par-list-by-tabs-search {
  margin-bottom: 2px;
}

/* par-listing div css start*/
.par-listing {
  height: calc(100vh - 165px - 32px - 55px);
  overflow: auto;
}

.par-listing-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #fafafa;
  border-radius: 5px;
}

.par-listing-card-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  background: #dc4c35b5;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.par-lc-content {
  flex: 1 1;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
}
.par-lc-content-avatar {
  display: inline-block;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: #fae5a5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  color: #515050;
}
.par-lc-content-icon {
  display: inline-block;
  margin-right: 10px;
  width: 32px;
  -o-object-fit: contain;
     object-fit: contain;
}
.par-lc-content-app-name {
  font-weight: bold;
  color: #4f4f4f;
}

.par-lc-content-app-category {
  font-size: 0.8rem;
  color: #ababab;
}

.par-lc-button {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5rem 0 0;
}

.par-lc-button-state {
  border: 1.5px solid #000000;
  height: 30px;
  width: 30px;
  line-height: 28.5px;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.par-lc-button-state:not(:last-child) {
  margin-right: 10px;
}

.par-lc-button-state.par-productive {
  color: #2ecc71;
  border-color: #2ecc71;
}

.par-lc-button-state.par-productive.selected {
  background-color: #2ecc71;
}

.par-lc-button-state.par-unproductive {
  color: #e74c3c;
  border-color: #e74c3c;
}

.par-lc-button-state.par-unproductive.selected {
  background-color: #e74c3c;
}

.par-lc-button-state.par-neutral {
  color: #bdc3c7;
  border-color: #bdc3c7;
}

.par-lc-button-state.par-neutral.selected {
  background-color: #bdc3c7;
}

.par-lc-button-state.selected {
  color: #fff !important;
  font-weight: bold;
}

.par-lc-button-state.loading {
  cursor: wait;
  opacity: 0.6;
}

.par-legend {
  padding: 8px 0;
  margin-bottom: 0.5rem;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.par-legend .par-legend-item {
  display: flex;
  align-items: center;
}

.par-legend .par-legend-item:not(:last-child) {
  margin-right: 10px;
}

.par-legend-name {
  font-size: smaller;
}

.par-legend-icon {
  color: #fff;
  height: 22px;
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-right: 4px;
}

.par-legend-item.pl-productive .par-legend-icon {
  background-color: #2ecc71;
}

.par-legend-item.pl-unproductive .par-legend-icon {
  background-color: #e74c3c;
}

.par-legend-item.pl-neutral .par-legend-icon {
  background-color: #bdc3c7;
}

.par-legend-item.pl-productive .par-legend-name {
  color: #2ecc71;
}

.par-legend-item.pl-unproductive .par-legend-name {
  color: #e74c3c;
}

.par-legend-item.pl-neutral .par-legend-name {
  color: #bdc3c7;
}

/* personal-app-review div css end*/

.personal-settings,
.personal-settings .tab-pane,
.personal-settings .tab-content {
  height: 100%;
}
@media screen and (max-width: 480px) {
  .personal-setting-root{
  padding: 2rem;
  }
}
.personal-settings .nav-tabs > a {
  color: #343a40;
}
.personal-setting-height{
  height:200px;
}

.root-layout-page .nav-link {
  background-color: #fff;
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.personal-settings .nav-tabs > a.active {
  border-bottom: 4px solid #007bff;
  color: #007bff;
}

.personal-settings-info-container,
.personal-settings-password-container {
  background: #fff;
  height: 100%;
}

.personal-settings-info-container > .form-wrapper,
.personal-settings-password-container > .form-wrapper {
  margin: 0 auto;
  max-width: 500px;
}
.personal-info-profile-photo-upload-card {
  clear: both;
  display: flex;
}
.personal-info-profile-photo-upload-icon-card{
  display: inline-block;
  margin-right: 10px;
  height: 55px;
  width: 55px;
  border-radius: 40px;
  background: #e3e3e3ba;
  border: 1px solid rgba(0,0,0,.1);
  font-size: 29px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: #33333394;
  position: relative;
  overflow: hidden;
}

.personal-info-profile-photo-upload-photo-card-content {
  cursor: pointer;
  margin-top: 2px;
}

#inputUpload {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
  font-size: inherit;
  color:#157AF6;
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 500;
}

.custom-file-upload:hover {
  text-decoration: underline;
}

.profile-info-saved-crop-image-button{
  font-weight: 700;
  /* float: right; */
  width: 200px;
  height: 38px;
  font-size: 15px;
}
.profile-info-saved-crop-image-button-header{
  margin-top: 14px;
  text-align: center;
}
.profile-info-cancel-crop-image-button{
  /* float: left; */
  width: 150px;
  height: 38px;
  font-size: 15px;
  font-weight: 700;
  margin-right: 14px;
}
.personal-info-profile-images{
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
.profile-info-saved-crop-image-modal-title{
  justify-content: center;
}
label.custom-file-upload-text {
  font-size: 14px;
  color: #0000005c;
}

label.profile-photo-remove {
  float: right;
  color: #ff0000b0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px !important;
  cursor: pointer;
}
.profile-info-saved-crop-image-modal-body{
  text-align: center;
}
label.profile-photo-remove:hover{
  text-decoration: underline;
}
.delete-account-wrapper {
  margin: 34px auto;
  max-width: 500px;
  border: 1px solid #00000030;
  padding: 20px;
}
.delete-account-wrapper-header{
  font-size: 1.5rem;
  color: #343a40!important;
  font-weight: 500;
  line-height: 1.2;
}
.delete-account-wrapper-content {
  color: #727272;
  font-weight: 500;
  margin-top: 12px;
}
.project-details-task-card-delete-body-text{
  margin-top: 20px;
}
.apps-and-websites-history-card {
    margin: 1rem;
}
.apps-and-websites-history-card-content {
    /* border: 0.5px solid #0000001a; */
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.apps-and-websites-history-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.apps-and-websites-history-card-export {
    margin-bottom: 1rem;
    display: flex;
    justify-content: end;
}
.disable-app-export {
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #007bffa8;
    border-color: #007bff;
    border-radius: .25rem;
}

    .enable-timesheet {
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
        background-color: #007bff;
    border-color: #007bff;
        /* line-height: 1.5; */
        border-radius: .25rem;
        cursor:pointer;
    }

.app-download-dropdown {
    cursor: pointer;
    width: 100%;
    margin: 0;
}
.apps-and-websites-history-card-table-header {
    color: #868686;
    border: none;
}
.apps-and-websites-history-card-table-body {
    color: #333;
}
.apps-and-websites-history-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.apps-and-websites-history-card-table-body td {
    vertical-align: middle;
}
.apps-and-websites-history-card-table th, .apps-and-websites-history-card-table td {
    border: none;
}
.apps-and-websites-history-card-table-data-details-content {
    /* width: 36% !important; */
    display: inline-block;
    height: 100%;
    vertical-align: middle;

}
.whole-history-title-div{
    display :flex;
}
.app-page-content-wrapper-menu-filter-overlay-datepicker {
    display: inline-block;
    width: 260px;
    vertical-align: top;
    /* border-right: 1px solid #e8ebee; */
    margin-left: 20px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .timesheet-export-button-header{

  }

.apps-and-websites-history-card-table td, .apps-and-websites-history-card-table th, .apps-and-websites-history-card-table thead th {
    border: none;
}
.apps-and-websites-history-card-table-data-productive-status{
    text-transform: capitalize;
}
.apps-and-websites-history-card-table-data-productive{
    color:#10b9a9;
}
.apps-and-websites-history-card-table-data-unproductive{
    color: #e74c3c80;
}
.apps-and-websites-history-card-table-data-neutral{
    color:#c0901d;
}
.website-app-icon {
    /* width: 1.6rem;
    height: 1.6rem; */
    display: block;
    margin: 0 auto;
    margin-top: 8px;
    width: 25px;
    /* padding: 0.3rem; */
}
.apps-website-load-more-button-td{
    text-align: center;
}

.apps-and-websites-history-card-table-title-section{
    display:flex;
}
.app-profile-avatar {
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #ffffff;
    font-size: 27px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    margin-right: 7px;
}
.apps-and-websites-history-card-table-title{
    max-width: 30ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px;
    margin-right: auto;
}
.apps-and-websites-history-card-title{
    text-align: center;
}
.app-website-whole-div{
    display:flex;
    margin:8px 0px 0px 0px;
}
.app-website-user-header{
    display:flex;
    margin-right: 10px;
}
.count-tooltip-user-content-app {
    max-width: 90px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.app-website-sub-div1{
    display:flex;
    margin-right: 10px;
    color: #8394ab;
}
.app-website-sub-div{
    display:flex;
    margin-right: 10px;
    color: #8394ab;
}
.app-website-sub-div1-icon{
    font-size: 14px;
    margin-top: 3px;
    margin-right: 4px;
}
.app-website-sub-div-icon{
    font-size: 13px;
    margin-top: 5px;
    margin-right: 4px;
}
.app-website-sub-div-value-hours{
    font-size: 14px;
    color: #8394ab;
}
.app-website-sub-div-value-status{
    font-size: 14px;
}
 .productive{
    color:#57a956;
}
 .neutral{
    color:#919791;
}
 .unproductive{
    color:#d2767f;
}
@media only screen and (min-width: 1170px){ 
.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 37px; 
    height: 37px;
    left: 51%;
    margin-left: -27px;
}
.vertical-timeline.vertical-timeline--two-columns {
    width: 100%;
    overflow-x: hidden;
    /* height:100vh; */
}
.vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1em;
    width: 45%;
}
}
.vertical-timeline-element-icon {
    position: absolute;
    top: 6px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fcfcfc, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
}
.vertical-timeline-element-content p {
    margin: 0 0 0;
    font-size: 12px;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
}
.vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -10px;
}

.apps-website-end-text{
    text-align: center;
    margin-top: 7px;
}

@media screen and (max-width: 480px) {
    .apps-and-websites-history-card-table-title{
        max-width: 15ch !important;
    }
}

.login-root {
    padding: 2rem;
}

/* login-root-header start */

.login-root-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.login-root-img-center {
    margin: 0 auto;
}
.login-root-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.login-root-title{
   font-weight: 400;
   font-size: 2rem;
}

/* login-root-header end */

/* login-root-content start */
.login-root-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 426px;
}
.web-login-form {
  /* display: grid;
  gap: 16px; */
  width: 100%;
}
.web-login-form input{
    margin-bottom: 20px;
}
.login-field-error-message-login{
    float:right;
    color:red;
    font-size: 14px;
}
.web-login-submit-button{
    /* background-color: #0135cd; */
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
/* .web-login-submit-button:hover{
    background-color: #6f0ca6;
} */
/* login-root-content end */

/* login-root-footer start */
.login-root-footer a {
    color: #035FCB;
    text-decoration: none;
}
/* login-root-footer end */
.login-root-member-permission{
    text-align: center;
    margin: 5rem 13rem;
}
.login-root-member-permission-icon{
    color: #3F38C8;
    font-size: 60px;
}
.login-root-member-permission-header-text {
    font-size: 24px;
    font-weight: 700;
    margin: 12px;
    color: #3f38c8db;
}
.login-root-member-permission-sub-text {
    font-size: 15px;
    margin-top: -7px;
    color: #3f38c896;
}

.change-password {
    padding: 2rem;
}

/* change-password-header start */

.change-password-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.change-password-img-center {
    margin: 0 auto;
}
.change-password-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.change-password-title{
   font-weight: 400;
   font-size: 2rem;
}

/* change-password-header end */

/* change-password-content start */
.change-password-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 426px;
}
.change-password-form {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}
.change-password-submit-button{
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
/* .change-password-submit-button:hover{
    background-color: #6f0ca6;
} */
/* change-password-content end */

/* change-password-footer start */
a {
    color:  #6f0ca6;
    text-decoration: none;
}
/* change-password-footer end */

.password-reset-success-msg1 {
    font-size: 21px;
    color: #000000bf;
    font-weight: 700;
}
.password-reset-login-button{
    color: blue;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}
.change-password-field-error-message {
    color: #ff6565;
    font-size: medium;
    margin-bottom: 0.5rem;
    font-weight: 800;
}
.change-password-content-success-icon{
    width: 85px;
    height: 85px;
    color: green;
    margin: 23px;
}
.change-password-error-message-label{
    color: red;
    text-align: left;
    margin-top: -12px;
    font-size: 13px;
}
.create-password-content-expire {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: -54px auto 24px;
    padding: 16px 30px 20px;
    max-width: 480px;
}
.create-password-reset-success-msg1-expire {
    font-size: 18px;
    color: #000000bf;
    font-weight: 700;
}
.forgot-password {
    padding: 2rem;
}

/* forgot-password-header start */

.forgot-password-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.forgot-password-img-center {
    margin: 0 auto;
}
.forgot-password-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.forgot-password-title{
   font-weight: 400;
   font-size: 2rem;
}
.span-forgot-content{
    color:red;
}

/* forgot-password-header end */

/* forgot-password-content start */
.forgot-password-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 426px;
}
.forgot-password-form {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}
.forgot-password-submit-button{
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
.forgot-password-submit-button:hover{
    background-color: #035FCB;
}
/* forgot-password-content end */

/* forgot-password-footer start */
a {
    color:  #035FCB;
    text-decoration: none;
}
/* forgot-password-footer end */

.password-reset-success-msg1 {
    font-size: 20px;
    color: #000000bf;
    font-weight: 700;
}
.password-reset-login-button{
    color: blue;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    color: #000000d6;
}
.forgot-password-field-error-message{
    color: #ff6565;
    font-size: medium;
    margin-bottom: 0.5rem;
    font-weight: 800;
    color: #000000d6;
}
.password-reset-success-msg3{
    font-weight: 500;
    color: #000000d6;
}
.forgot-password-content-success-icon{
    width: 85px;
    height: 85px;
    color: green;
    margin: 23px;
}
.forgot-password-error-message-label{
    color: red;
    text-align: left;
    margin-top: -12px;
    font-size: 13px;
}
.create-password {
    padding: 2rem;
}

/* create-password-header start */

.create-password-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.create-password-img-center {
    margin: 0 auto;
}
.create-password-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.create-password-title{
   font-weight: 400;
   font-size: 2rem;
}

/* create-password-header end */

/* create-password-content start */
.create-password-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 540px;
}
.create-password-form {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}
.create-password-submit-button{
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
.create-password-text{
    text-align: left;
    color: #000000ab;
    font-size: 11px;
    font-weight: 600;
}
/* .create-password-submit-button:hover{
    background-color: #035FCB;
} */
/* create-password-content end */
.on-boarding-next-step-admin-content-label-pass {
    font-size: 15px;
    font-weight: 500;
}
.on-boarding-next-step-admin-datas-pass {
    margin-bottom: 10px;
    text-align: left;
}
span.on-boarding-next-step-admin-content-label-count-pass {
    display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #333;
}
.on-boarding-next-step-admin-contents-pass {
    padding: 15px 0px 5px 0px;
}
.on-boarding-next-step-admin-headers-pass {
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecec;
    padding: 15px;
    color: #3341a7;
    font-size: 16px;
    font-weight: 700;
}


/* create-password-footer start */
a {
    color:  #6f0ca6;
    text-decoration: none;
}
/* create-password-footer end */

.create-password-reset-success-msg1 {
    font-size: 21px;
    color: #000000bf;
    font-weight: 700;
}
.create-password-reset-success-msg2{
    font-size: 14px;
}
.create-password-reset-success-msg3{
    font-weight: 600;
    font-size: 15px;
}
.create-password-field-error-message {
    color: #ff6565;
    font-size: medium;
    margin-bottom: 0.5rem;
    font-weight: 800;
}
.create-password-content-success-icon{
    width: 85px;
    height: 85px;
    color: green;
    margin: 23px;
}
.create-password-error-message-label{
    color: red;
    text-align: left;
    margin-top: -12px;
    font-size: 13px;
}
.create-password-download-file-header{
    display: flex;
    justify-content: center;
    margin: 25px 0;
}
.create-password-download-file-windows{
    margin-right: 20px;
    
}
.create-password-download-file-mac,.create-password-download-file-windows,.create-password-download-file-linux{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding: 16px 16px 0px;
}
.create-password-download-file-windows-icon,.create-password-download-file-mac-icon,.create-password-download-file-linux-icon{
    width: 40px;
    height: 40px;
    color: #000000c7;
}
.create-password-download-file-windows-text a,.create-password-download-file-mac-text a,.create-password-download-file-linux-text a{
    font-size: 13px;
    margin-top: 16px;
    color: #3341a7;
    font-weight: 600;
}
.create-password-download-file-windows-text,.create-password-download-file-mac-text,.create-password-download-file-linux-text{
    margin-top: 10px;  
}
.create-password-download-file-mac {
    margin-right: 20px;
}
.on-boarding-root {
    padding: 2rem;
}
.on-boarding-root-content {
    padding: 2rem 0;
}
.on-boarding-root-content-form-title{
    margin-top: 5%;
    text-align: center;
}
.on-boarding-root-content-form-title-muted{
    font-size: 12px;
    text-align: center;
}
.on-boarding-root-content-form-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8%;
}
@media screen and (max-width: 480px) {
    .on-boarding-root-content-form-settings {
        display: grid !important;
        grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    }
    .account-root-tick-icon-screen {
        position: initial !important;
        margin-right: 15px;
        margin-top: 4px;
    }
    .account-root-tick-icon-app{
        position: initial !important;
        margin-right: 15px;
        margin-top: 5px;
    }
    .account-root-tick-icon{
        position: initial !important;
        margin-right: 15px;
        margin-top: 4px;
    }
    .on-boarding-root-content-form-settings-box{
        margin-bottom: 0.9rem;
        display: flex;
    }
    .onboarding-remove-team {
        height: 35px;
        font-size: 10px !important;
    }
    .on-boarding-root-content-form-submit-div{
        display: flex;
        justify-content: center;
    }
    .on-boarding-root-content-form-title{
        text-align: left !important;
        margin-top: 37px !important;
        margin-bottom: 13px !important;
        font-size: 1.3rem !important;
    }
    .on-boarding-root-content-form-title-muted{
        text-align: left !important;
        font-size: 15px !important;
        color: gray !important;
        margin-bottom: 28px !important;
    }
    .on-boarding-root-header{
        margin-top: 10px !important;
        margin-bottom: 21px !important;
    }
    .on-boarding-root-header-color{
        color:gray;
    }
    .on-boarding-root-header-team{
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
    .on-boarding-root-content-form-save-button-team{
        font-size: 15px !important;
        /* height: 37px !important; */
    }
    
}
.on-boarding-root-content-form-settings-box {
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 30px;
    margin-right: 5px;
    cursor: pointer;
}
.on-boarding-settings-worked-days-selected {
    color: #427ff6;
    background-color: #eef3fc;
    border: 1px solid #ededed;
}
.onboarding-company-name-textbox{
    height: 50px;
}

.on-boarding-root-content-form-submit-button{
    float: right;
    background-color: #0135cd;
}
.on-boarding-root-content-form-settings-box:hover {
    transition: all .2s ease-out;
    box-shadow: 4px 4px 7px 1px #e9ecef;
    border: 1px solid #ededed;
}
.onboarding-remove-team{
    color: #dc3545ab;
    border-color: #dc35456e;
    font-size: 14px;
    /* float:right; */
    margin-left: 25%;
}
.onboarding-footer-button{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.onboard-add-new-team{
    margin-top: -13px;
}
.on-boarding-root-content-form-skip-button{
    float: left;
    color: #fff;
    background-color: #6c757d9c;
    border-color: #ffffff;
}
/* .on-boarding-root-content-form-skip-button:hover{
    float: left;
    color: #14429e;
    background-color: #ffffff;
    border-color: #ffffff;
    text-decoration: underline;
} */
.on-boarding-root-content-form-save-button{
    float: right;
    background-color: #0135cd;
}
.on-boarding-root-content-form-save-button-team{
    /* float: right; */
    margin-left: 26%;
    background-color: #0135cd;
}

.on-boarding-next-step-admin,.on-boarding-next-step-employee{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding:0 !important;
    margin: 0 auto;
    margin-bottom: 15px;
}
.on-boarding-next-step-admin-headers{
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecec;
    padding: 15px;
    color: #5296e4;
    font-size: 16px;
    font-weight: 700;
}
.on-boarding-next-step-admin-contents{
    padding: 15px 15px 5px 15px;
}
.on-boarding-next-step-admin-content-label{
    font-size: 16px;
    color: #000000ad;
    font-weight: 500;
    display: inline-block;
}
.on-boarding-next-step-admin-content-label a{
    color: #035FCB;
}
span.on-boarding-next-step-admin-content-label-count {
    display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #333;
}.on-boarding-next-step-admin-datas{
    margin-bottom: 10px;
    display: flex;
}
.step3-header{
    /* margin-top:2%; */
}
.on-boarding-next-step-admin-and-user{
    margin-top: 2rem;
}
.account-creation-logo-full{
    margin-bottom: 10px;
}
.account-root-img-responsive {
    margin-top: -7px !important;
}
.on-boarding-root-content-full {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 20px 30px 20px;
    max-width: 426px;
}
.account-root-footer{
    text-align: center;
    color:gray;
}
.account-root-footer a{
    color:#0135cd;
}
.account-root-tick-icon {
    color: #427ff6;
    /* position: absolute;
    right: 28px;
    top: 8px; */
    position: absolute;
    top: 5%;
    /* left: 94%; */
    right: 25px;
    display: none;
}
.account-root-tick-icon-screen {
    color: #427ff6;
    position: absolute;
    /* top: 8px;
    left: 143px;
    right: 0;  */
    position: absolute;
    top: 5%;
    left: 19%;
    display: none;  
}
.account-root-tick-icon-app {
    color: #427ff6;
    /* position: absolute;
    top: 8px;
    left: 480px;
    right: 0;   */
    position: absolute;
    top:5%;
    left: 64%;
    display: none;
}
.account-root-tick-icon-screen-none{
    display: inline;
}
.on-boarding-root-content-form-settings-box-text{
    font-weight: 700;
}
.account-creation-logo-text {
    font-size:20px;
    font-weight:600;
    color:#1862c4;
    margin-left:5px;
}
h1.on-boarding-root-header {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
h1.on-boarding-root-header-team {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
.on-boarding-root-content-form-account-submit-button {
    width: 100%;
    height: 40px;
    margin: 15px 0 8px;
    padding: 7px 16px;
    background-color: #0135cd;
}
.onboard-company-name-lable {
    font-size: 1.20rem;
    font-weight: 500;
    color: #000000ab; 
}
.on-boarding-root-content-form-invite-user{
    margin-top: 20px;
}
.on-boarding-root-content-form-invite-user-row{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    /* margin-left: 7%; */
}
.on-board-modal{
    display: flex;
}
.onboarding-step-badges {
    padding: 5px 10px 7px 10px;
    color: #fff;
    background-color: #6c757d7d;
    margin-bottom: 2%;
}
.onboard-side-text{
    background-color: #007bff;
}
.onboard-side-text-div-whole{
    position: relative;
    top: 35%;
    padding: 1%;
}
.onboard-side-text-div {
    color: white;
    font-size: 25px;
    font-weight: 700;
}
.onboarding2-free-plan-team-limit-label {
    font-size: 15px;
    color: #f16443b3;
    font-weight: 500;
}
.on-boarding-unauthorized-text{
    margin: 2rem;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    color: #ff0000ba;
}
.onboarding-footer-div {
    margin: 2rem;
    color: #ff0000d1;
}
.onboarding-footer-div-login {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
}
.on-boarding-root {
    padding: 2rem;
}
.account-creation-logo-full{
    margin-bottom: 10px;
}
.account-root-img-responsive {
    margin-top: -7px !important;
}
.account-creation-logo-text {
    font-size:20px;
    font-weight:600;
    /* color:#1862c4; */
    color:#035FCB;
    margin-left:5px;
}
h1.on-boarding-root-header {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
.on-boarding-root-content-full {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 20px 30px 20px;
    max-width: 426px;
}

.signup-user-limit-exceed-msg1{
    color: #f12a2aeb !important;
}


.on-boarding-root-content {
    padding: 2rem 0;
}
.on-boarding-root-content-form-title{
    margin-top: 5%;
}
.on-boarding-root-content-form-title-muted{
    font-size: 12px;
}
.on-boarding-root-content-form-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8%;
}

.on-boarding-root-content-form-save-button{
    float: right;
    background-color: #0135cd;
}

.on-boarding-next-step-admin,.on-boarding-next-step-employee{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding:0 !important;
    /* margin: 0 auto 24px; */
    /* padding: 16px 30px 20px; */
}
.on-boarding-next-step-admin-header{
    margin-bottom: 10px;
    border-bottom: 1px solid #ebecec;
    padding: 20px;
    color: #5296e4;
    font-size: 16px;
    font-weight: 700;
}
.on-boarding-next-step-admin-content{
    padding: 20px;
}
.on-boarding-next-step-admin-content-label{
    font-size: 16px;
    color: #000000ad;
    font-weight: 500;
}
span.on-boarding-next-step-admin-content-label-count {
    display: inline-block;
    margin-right: 10px;
    height: 27px;
    width: 27px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    color: #333;
}.on-boarding-next-step-admin-data{
    margin-bottom: 20px;
}


.account-root-footer{
    text-align: center;
    color:gray;
}
.account-root-footer a{
    color:#035FCB;
}

.on-boarding-root-content-form-settings-box-text{
    font-weight: 700;
}


.on-boarding-root-content-form-account-submit-button {
    width: 100%;
    height: 40px;
    margin: 15px 0 8px;
    padding: 7px 16px;
    background-color: #035FCB;
}

.on-boarding-root-content-form-invite-user{
    margin-top: 20px;
}
.on-board-modal{
    display: flex;
}
.onboard-side-text{
    background-color: #007bff;
}
.onboard-side-text-div-whole{
    position: relative;
    top: 35%;
    padding: 1%;
}
.onboard-side-text-div {
    color: white;
    font-size: 25px;
    font-weight: 700;
}
.error-link{
    color: red;
    font-size: 15px;
    /* float: right; */
    text-align: center;
    margin-top: 20px;
}
.error-link-admin-text {
    color: red;
    font-size: 15px;
    /* float: right; */
    text-align: center;
}
.error-signup{
    color: red;
    font-size: 14px;
    float: right;
}
.error-signup + .form-control {
    border-color: #F0624D;
}
.on-boarding-root-header-invite{
    margin-bottom: 4%;
    font-size: 18px;
}
.on-boarding-root-content-full-invite-err{
    color:green;
    font-weight: 600;
}
.on-boarding-root-content-full-icon-div{
    display: flex;
    justify-content: center;
}
.on-boarding-root-content-full-icon{
    width: 55px;
    height: 55px;
    margin-bottom: 10px;
    color: #464646;
}
.web-page-signup-terms-and-condition-text {
    font-size: 11px;
    color: #00000094;
    margin-bottom: -4px;
    margin-top: 5px;
}
.forms-pass{
    margin-bottom: 0.5rem !important;
}
.web-page-signup-password-text{
    color: #000000ab;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.member-overview{
    padding:40px;
}
.signup-recaptcha div div{
    /* width:auto !important;
    margin: 0 auto;
    display: table; */
}
.web-page-signup-employee-note-text{
    margin-top: 20px;
}
.web-page-signup-terms-and-condition-terms-text,.web-page-signup-terms-and-condition-condition-text{
    color:#00000094;
}
.on-boarding-root-content-full-invite-license-err{
    color:red;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}
.on-boarding-root-content-full-license-not-available{
    margin-top:30px;
}
.on-boarding-root-content-full-invite-invalid-err {
    color: red;
    font-weight: 600;
}
.web-page-signup-cloudflare-recaptcha iframe {
    width: 100% !important;
}


.risk-user-history-root {
    margin: 2rem;
}
.risk-user-history-root-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
}
.risk-user-history-root-header p{
  margin: 0px 14px;
}
.risk-user-history-root-header-rule {
    display: flex;
    justify-content: space-between;
}
.risk-user-history-root-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
}
@media screen and (max-width: 768px){
    .risk-user-history-root-content {
        grid-template-columns: 1fr;
    }
    .risk-rule-history-content-listing-content-card{
      display: block !important;
    }
}
@media screen and (max-width: 1024px) {
  .risk-user-history-root-content  {
      grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 480px) {
  .risk-rule-history-content-listing-content-card{
    display: block !important;
  }
  span.risk-user-history-filter-title{
    margin-top: 75px !important;
  }
}

.head-website-list-risk-history {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 1rem 5px 0;
}
.risk-user-history-root-section {
    /* height: 315px; */
    /* overflow: auto; */
    color: #4a5568;
    padding: 0 16px 16px;
    /* background: #fff; */
    background: #fff;
    border-radius: 5px;
    border: .5px solid rgba(0,0,0,.08);
}
.head-risk-user-history-card{
    display: flex;
    /* position: sticky;
    top: 0; */
    /* background-color: white; */
}
.head-risk-user-history-list-heading {
    font-size: 16px;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0 10px 0;
  }
  .risk-user-root-content-data-header {
    color: #797777db;
    border: none;
    font-size: 0.9rem;
  }
  
  .risk-user-root-content-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    /* background-color: #e9ecef; */
    padding: 0 10px;
  }
  .risk-user-root-content-table tbody tr {
    /* display: flex; */
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    padding: 3px 7px 9px 7px;
    /* margin-bottom: 10px;
    background-color: #e9ecef; */
    /* cursor: pointer; */
  }
  /* .risk-user-root-table-body{
    background-color: #e9e9e9;
    padding: 0 10px;
  } */

  .risk-root-content-data-details-avatar-profile {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
  }
  .risk-root-content-data-details-name-data-violation-name {
    margin-bottom: 6px;
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
}
  .risk-root-content-data-details-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
  }
  tbody.risk-user-root-table-body {
    height: 80px;
    overflow-y: auto;
  }
  .risk-user-root-content-table-wrapper {
    /* height: 335px; 
    overflow: scroll;
    overflow-x: hidden; */
  }
  .risk-history-root-content-data-details-name-data-team-name-rule-text {
    margin: 0;
    font-size: 12px;
    color: #dc3545;
    margin-top: -2px;
    margin-right: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 50ch;
}

  /* .risk-user-root-content-data-header tr {
      position: sticky;
      top: 0;
  } */
  .risk-user-history-root-button{
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}



.risk-user-history-content-listing {
  display: grid;
  /* grid-template-columns: repeat(auto-fill,minmax(235px,1fr)); */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 1rem; */
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: #e9ecef; */
  margin-top: 3%;
}
.risk-user-history-content-listing-content {
  display: flex;
  background-color: rgba(236,239,244,.45098039215686275);
  border-radius: 5px;
  padding: 3px 7px 9px 7px;
}

.risk-root-change {
  text-align: center;
  vertical-align: middle !important;
}

.risk-history-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 29px;
  font-size: 12px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
  margin-top: 8px;
}
.risk-history-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
}
.risk-rule-history-content-listing-content {
  background-color: rgba(236,239,244,.45098039215686275);
  border-radius: 5px;
  padding: 9px;
}
.risk-rule-history-content-listing-content-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.risk-rule-history-content-listing {
  display: grid;
  grid-gap: 1rem;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 3%;
  font-size: .9rem;
}
.risk-rule-history-content-rule-name{
  margin-bottom: 6px;
  font-weight: 600;
  color: #34495e;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  margin-top: 3px;
  font-size: .9rem;
  width: 450px;
  text-align: left;
  margin-left: 4px;
}
.risk-rule-history-content-rule-violations {
    margin-right: 49px;
    margin-top: 3px;
    margin-bottom: 6px;
    font-size: 0.9rem;
}
.risk-rule-history-content-listing-content button.btn.btn-link {
  width: 100%;
}

.risk-user-rule-history-content-listing-content {
  display: flex;
  /* background-color: rgba(236,239,244,.45098039215686275); */
  background-color: rgb(246 248 250);
  border-radius: 5px;
  padding: 3px 7px 9px 7px;
}
.risk-rule-history-content-data-details-avatar-circle {
  height: 24px;
  width: 24px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 24px;
  font-size: 11px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
  margin-top: 8px;
}
.risk-rule-history-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
}
.risk-history-card-avatar-profile {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  border-radius: 50%;
  margin-top: 8px;
}
.risk-history-card-avatar-profile-accordian {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  border-radius: 50%;
  margin-top: 8px;
}
span.risk-user-history-filter-title {
  font-size: 19px;
  font-weight: 600;
  color: #00000094;
  vertical-align: middle;
  margin-top: 10px;
}
.accordion button:hover{
 text-decoration: none;
}
.accordion button:focus{
  text-decoration: none;
 }
 .risk-history-rule-uparrow {
  font-size: 13px;
 }
.work-load-analysis-report-root {
    margin: 2rem;
}
.work-load-analysis-report-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.work-load-analysis-report-filter-content {
    display: flex;
    z-index: 50;
}
.work-load-analysis-report-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}



.work-load-analysis-report-user-table-view {
    /* width: 100vw; */
    display: grid;
}
.work-load-analysis-report-user-tablet-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}
.work-load-analysis-report-user-list-table-content {
    width: 100%;
    height: 67vh;
    overflow: auto;
}
.work-load-analysis-report-user-list-table-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
    font-size: 0.9rem !important;
}
.work-load-analysis-report-user-list-table-thead {
    position: sticky;
    top: 0px;
    z-index: 50;
}
.work-load-analysis-report-user-list-table-table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.work-load-analysis-report-user-list-table-table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    /* text-align: center; */
}
.work-load-analysis-report-user-list-table-table th:first-child {
    min-width: 16rem;
    max-width: 16rem;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
th.work-load-analysis-report-user-list-table-thead-tr-th {
    padding: 12px;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table th {
    /* font-size: 1rem; */
    cursor: default;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table td {
    min-width: 6rem;
    max-width: 6rem;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #E9ECEF;
}
.work-load-analysis-report-user-list-table-thead-tr-th {
    font-weight: 600 !important;
    color: #00000094;
}
.work-load-analysis-report-user-list-table-tbody {
    display: block;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    min-width: 14rem;
    max-width: 14rem;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table tbody th, .work-load-analysis-report-user-list-table-table tbody td {
    color: #000000d6;
}
.work-load-analysis-report-user-list-table-table td:not(:first-child) {
    text-align: center !important;
}
.work-load-analysis-report-user-list-table-table thead> tr th:nth-last-child(-n+3), .work-load-analysis-report-user-list-table-table tbody> tr td:nth-last-child(-n+3) {
    /* min-width: 10rem;
    max-width: 10rem; */
}
.monthly-aatendance-load-more-button-div {
    text-align: center;
    margin-top: 21px;
}

.work-load-analysis-report-root-content{
    display: grid;
    grid-template-columns: 1fr;
}
.work-load-analysis-report-chart-content{
    width: 100%;
    margin-bottom: 2rem;
    border: 0.5px solid rgba(0,0,0,.0784313725490196);
    height: 460px;
}
.work-load-analysis-report-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.work-load-analysis-report-page-content-wrapper-menu-filter-date{
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    padding: 6px 16px 6px 8px;
    cursor: pointer;
    transition: all .1s ease;
    width: 280px;
}
.work-load-analysis-report-page-content-wrapper-menu-filter-date-text{
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}
.work-load-analysis-report-user-list-table-thead-tr-th:not(:first-child) {
    text-align: center !important;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-over-worked{
    background-color: #F1C40F;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-healthy{
    background-color: #2ECC71;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-less-utilised{
    background-color: #E67E22;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-less-utilised-na{
    background-color: #F3F7FA;
}
.work-load-analysis-report-chart-content-col {
    padding: 20px 0px;
}
.work-load-analysis-report-chart-content-title {
    padding: 0px 25px;
}
.project-report-users-button .btn-primary:hover {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
}

.project-report-users-button .btn-primary:active {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}
.daily-attendance-table-load-more-td-load-more-btn{
    text-align: center;
}
.project-report-react-table-thead-sno{
    width:1%;
}
.project-report-react-table-thead-date{
    width:11%;
}
.project-report-react-table-thead-projectName{
    width:18%;
}
.project-report-react-table-thead-taskName{
    width:18%;
}
.project-report-react-table-thead-displayName{
    width:13%;
}
.project-report-react-table-thead-totalWorkHours{
    width:10%;
}
.project-report-react-table-thead-userBillableRate{
    width:5%;
}
.project-report-react-table-thead-userLabourCost{
    width:5%;
}
.project-report-react-table-thead-profit{
    width:5%;
}

.project-report-users-button .btn-primary:focus {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}
.project-report-apply-button {
    background: #266dfb !important;
    color: #fff !important;
        position: relative;
        float: right;
}
.project-report-users-button .btn-primary {
    border: 1px solid rgb(204, 204, 204);
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    margin-right: 1.3rem;
    width:245px;
    text-align: left;
}
.project-report-users-button-date .btn-primary {
    border: 1px solid rgb(204, 204, 204);
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    margin-right: 1.3rem;
    width: 258px;
    text-align: left;
}
.project-report-team-selection-control-checkbox {
    width: 0.9rem;
    height: 0.9rem;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
}

.project-report-users-button .dropdown-toggle::after {
    float: right;
    margin: 9px auto;
}
.project-report-total-tr td{
    border :none !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.project-report-total-td{
font-weight: 600;
}
.project-report-total-hours-td{
    font-weight: 600;
}
.project-report-total-sum-text {
    font-size: 14px;
    display: flex;
    justify-content: end;
    margin-right: 80px;
}
.project-report-dropdown-menu-whole-div{
    width:280px !important;
}
.project-report-filter-user{
    display: flex;
}
.projects-report-modal-content-header-team-name {
    font-size: 0.97rem !important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 175px;
    text-align: left;
}


.project-report-calender-text{
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.project-report-drop-text{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.project-report-filter-root {
    margin: 0 0 34px 0;
    /* padding: 10px; */
    /* border: 1px solid #00000030; */
}
.project-report-attendance-filter-content {
    display: flex;
    align-items: center;
}
.project-reports-filter-root-div {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706);
    justify-content: space-between;
}

.project-report-add-filter{
    /* margin: 0;
    color:gray;
    font-size: 10px;
    padding: 0 0.75rem; */

    margin: 0;
    color: gray;
    font-size: 10px;
    margin-top: -8px;
    margin-left: -5px;
    margin-bottom: -3px;
}
.project-report-no-data{
    text-align: center;
}
.risk-rule-setting-root {
    margin: 2rem;
}
.risk-rule-setting-root-rule-button {
    display: flex;
    justify-content: flex-end;
}

/* risk Modal CSS */
.risk-rule-modal .modal-dialog {
    max-width: 56%;
}
.risk-rule-modal-header .close {
    font-size: 1.2rem;
    margin-top: -3%;
}
.risk-ex-text{
    color:gray;
    font-size: 13px;
}
.no-data-risk-setting{
    text-align: center;
    margin-top: 5%;
    font-size: 16px;
}
.risk-setting-rule-type-color{
    color:black;
}
.risk-rule-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: #000000a8;
  }
.risk-modal-data-error{
    margin-top: 5px;
}
.risk-rule-modal-body .form-label.rule-setting-err {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}

.risk-rule-modal-body .form-error {
    font-size: smaller;
    color: red;
    margin-bottom: 0.25rem;
    text-align: center;
}
.risk-rule-modal-body .form-label {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.rule-sub-div-heading {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
    margin:0;
}
.risk-rule-condition-section {
    display:flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color:rgb(0 0 0 / 55%);
    font-weight: 600;
}
.risk-rule-condition-section-hourList {
    width: 140px;
    margin-bottom: 0px;
}
.risk-rule-condition-section-hourList .form-control{
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.risk-rule-condition-section-hourList-whole-div {
    margin: -6px 5px 0px 5px;
}
.risk-rule-modal-degree {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    /* padding: 0.5rem 0; */
}
.risk-setting-field-array{
    display: flex;
}
.risk-rule-condition-section-list-text{
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color: #1e1e1e!important;
    display: flex;
    font-size: 14px;
    margin: 10px auto 10px auto;
}
.risk-setting-and-text {
    font-size: 14px;
    /* margin-top: 0%; */
    color: rgb(0 0 0 / 55%);
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.risk-rule-modal-degree-radion-button > input[type="radio"] {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    cursor: pointer;
}
.risk-rule-condition-section-days {
    display: flex;
    margin-top: 1.3rem;
    margin-left: 2rem;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: rgb(0 0 0 / 55%);
    font-weight: 600;
}
.risk-setting-add-div {
    position: absolute;
    bottom: 0;
    right: 5px;
    margin: 0px 0px 8px 0px;
}
.risk-rule-modal-degree-radion-button > label {
    font-size: 0.95rem;
    cursor: pointer;
}

.risk-rule-modal-create-button {
    margin-bottom: 10px;
    margin-top: 1.5rem;
}
.risk-rule-modal-button {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.4rem 2rem;
}
.risk-rule-modal-button-clear {
    font-size: 1.2rem;
    padding: 0.4rem 2rem;
    background-color: #bdbdbd;
    border: #bdbdbd;
}

/* rule listing css */
.risk-rule-setting-root-card-data {
    /* display: grid; */
    grid-template-columns: 1fr;
    /* margin-top: 2rem; */
    font-size: 0.9rem;
    font-weight: 400;
    color: #6c757d !important;
}
.risk-rule-setting-root-card-content {
    border-radius: 5px;
    background: #fcfcfc;
    padding: .6rem;
    margin-bottom: 2rem;
}
.risk-rule-setting-root-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
}
.risk-rule-setting-root-card-table thead th {
    border: none;
}
.risk-rule-setting-root-card-table th,
  .risk-rule-setting-root-card-table td {
    border: none;
}
.risk-rule-setting-root-card-table-header {
    color: #868686;
    border: none;
}
.risk-rule-setting-card-table-body {
    color: #333;
}
.risk-rule-setting-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.risk-rule-setting-card-table-data-details-report-name{
    width:28% !important;
}
.risk-rule-setting-card-table-data-details-report-type{
    width:35% !important;
}
.risk-rule-setting-card-table-data-details-content{
    width:25% !important;
}
.risk-rule-card-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.risk-setting-remove-button {
    margin-top: 0px;
    color:#007bff;
    font-size: 19px;
    cursor: pointer;
}
.risk-setting-remove-button:hover {
    /* box-shadow: 0px 0px 0 2px #f8f9fa; */
    border: 1px solid #e9ecef;
    background-color: rgba(233,236,239,.4392156862745098);
}
.risk-setting-remove-button:focus{
    border:none;
    box-shadow: none;
}
.risk-setting-add-button:focus{
    border:none;
    box-shadow: none;
}
.risk-rule-condition-section-list{
    display: flex;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
}
.risk-rule-condition-section-list-1{
    margin-right: 4px;
}
.risk-rule-condition-section-list-2{
    margin-right: 4px;
}
tr.risk-rule-setting-card-table-data-details td {
    vertical-align: middle;
}


@media screen and (max-width: 480px) {
    .risk-rule-setting-card-table-data-details{
      font-size: 12px;
    }
    .risk-rule-setting-root-card-content{
        padding: 1rem 0;
    }
    .risk-setting-add-div {
        position: initial; 
        bottom: 0;
        right: 0;
        margin: 0px 0px 8px 0px;
    }
    .risk-setting-field-array {
        display: initial;
    }
    .risk-rule-condition-section{
        display: inline;
    }
    .rule-sub-div-heading{
        margin-bottom: 1rem;
    }
    .risk-rule-condition-section-mobile-view-div {
        display: flex;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 12px;
    }
    .risk-rule-condition-section-hourList .form-control{
        font-size: 11px;
    }
    .risk-rule-condition-section-hourList{
        width: 97px;
    }
    .risk-rule-condition-section-hourList-whole-div {
        margin: -6px 8px 0px 8px;
    }
    .risk-setting-and-text{
        margin-bottom: 0rem;
    }
    .risk-rule-condition-section-mobile-border{
        border: .5px solid rgba(0,0,0,.08);
        padding: 1rem;
    }
    .risk-rule-card-table-data-icon-div{
        display: inline;
        padding: 2px 0px 2px 10px;
    }
}

.WarningScreen-root{
    padding: 2rem;
    position: absolute;
    bottom: 33%;
}
.WarningScreen-text-sry {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 25px;
}
.WarningScreen-text{
    text-align: center;
    font-size: 20px;
    color: #333;
}
.WarningScreen-root-img {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.WarningScreen-text-header{
    font-size: 15px;
    color:#266dfb;
    font-size: 1.5rem;
    text-align: center;
}
.user-status{
    padding-bottom: 30px;
}

.user-status-header{
    text-align: center;
}

.user-status-title{
    font-size: 1.5rem;;
}

.user-status-app-status,.organisation-status{
    display: flex;
    margin: auto;
    justify-content: center;
}


.user-status-app-status-card{
    margin: 10px;
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width: 25vw;
    background: #ffffff;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}

.organisation-status-card{
    margin: 10px;
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width:38vw;
    background: #ffffff;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}


.user-status-work-version{
    margin: auto;
    justify-content: space-evenly;
}

.user-status-work-card,.user-status-version-card,.user-status-plan-card{
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width: 25vw;
    background: #ffffff;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}

.user-status-work-version{
    display:flex
}

.user-card-title{
    font-size: 17px;
}

.user-card-count{
    font-size: 30px;
    font-weight: bold;
    color: #545454;
}

.user-status-modal-title{
    font-size: 15px;
}

.user-status-password-div{
    width: 100%;
}

.user-status-password-error{
    font-size: 14px;
    color: #ea1414;
}

.user-status-submit-div{
    margin: 20px 0px;
    text-align: center;
}

.user-status-submit-button{
   width:80%;
}

.user-card-plan-details{
    height: 17vh;
    overflow: auto;
}

.user-card-plan-data{
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    margin: 10px;
    border-radius: 10px;
    padding: 2px 10px;
}


.user-card-plan-organisation{
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.user-card-plan-email{
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}


.user-card-plan-show{
    margin-right: -36px;
    font-size: 12px;
    text-decoration: underline;
    color: #0000ff82;
    cursor: pointer;
    padding-left: 10px;
}
.workfolio-thanku-root {
    margin: 9rem;
    text-align: center;
}
.workfolio-thanku-content-header {
    font-size: 22px;
    font-weight: 600;
    color: green
}
.workfolio-thanku-content-expiry {
    margin-top: 9px;
    margin-bottom: 9px;
}
.employee-timeline-page-wrapper{
    margin:2rem
}

svg g,svg g:first-of-type path {
    stroke: #cfcccc;
    stroke-width: 0.5;
}
.employee-timeline-page-wrapper svg g:first-of-type rect:not(:last-child) {
    fill: #ffffff;
}

.apps-tootlip-timeline{
    width: -moz-max-content;
    width: max-content;
    padding:15px;
    pointer-events: none;
  }
  .apps-tootlip-timeline-title{
    font-weight: bold;
    padding-bottom: 5px;
    /* width:100%;
    border-bottom: .5px solid rgba(0,0,0,.08); */
  }
  .apps-tootlip-timeline-time{
    font-weight: bold;
  }
  .timeline-no-data-found{
    text-align: center;
    margin-top: 40px;
  }
  .timeline-header-section{
      display: flex;
      margin-bottom: 20px;
  }
  .timeline-header-section-date {
    font-size: 19px;
    font-weight: 600;
    color: rgba(0,0,0,.5803921568627451);
    vertical-align: middle;
    /* margin-top: 10px; */
  }
  .timeline-header-section-show-text{
    /* margin-top: 10px; */
    margin-left: auto;
    margin-right: 0;
    display: flex;
  }
  .timeline-header-section-show-text-circle-start{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #7454f5;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-break{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #f1c40f;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-untracked{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #CDC2AE;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-stop{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #e67e22;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-neutral{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #40739e;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-idle{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #E67E22;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #E67E22;
  }
  .timeline-header-section-show-text-circle-prod{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #27ae60;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-unprodu{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #c0392b;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-whole{
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .timeline-header-section-show-text-letter{
    font-size: 13px;
  }
  @media screen and (max-width: 480px) {
    .timeline-header-section{
        display: initial;
    }
    .timeline-header-section-date {
      margin-bottom: 10px;
    }
    .timeline-header-section-show-text{
        margin-bottom: 10px;
    }
  }
  /* .timeline-pagination-section {
    display: flex;
    justify-content: center;
  } */
  .timeline-chart-data-root{
    height:72vh;
  }
  /* .timeline-pagination-section-label{
    text-align: center;
    margin-bottom: 15px;
  } */

  .timeline-pagination-section {
    display: grid;
    place-items: center;
    max-width: 69em;
    overflow: scroll;
    margin: 0px 32px 0px 32px;
  }
  .timeline-pagination-section-label {
    text-align: center;
    margin-bottom: 15px;
    /* width: 100vw; */
    display: grid;
  }
  .timeline-pagination-section .pagination{
    /* display: flex;
    justify-content: center; */
  }
.chargebee-subscription-root{
    margin:2rem;
}


  table.chargebee-subscription-root-table{
    border:1px solid #ccc;
    margin-top:20px;
  }
table.chargebee-subscription-root-table > thead > tr > th{
    border:1px solid #ccc;
}
table.chargebee-subscription-root-table > tbody > tr > td{
    border:1px solid #ccc;
}
.Manual-time-entries-root {
    padding: 2rem;
}
.Manual-time-entries-root-header{
    display: flex;
}
.Manual-time-entries-root-header-name{
    font-size: 19px;
    font-weight: 600;
    color: rgba(0,0,0,.6705882352941176);
}
.Manual-time-entries-root-header-button {
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    margin-left: auto;
    margin-right: 0px;
}
.Manual-time-entries-root-date-filter{
    font-size: 18px;
    margin-top: 20px;
}
.Manual-time-entries-root-body-listing-content {
    display: flex;
    align-items: center;
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    padding: 15px 15px 15px 15px;
    margin-top: 10px;
}
.Manual-time-entries-root-body-listing-content-status{
    padding: 6px 10px;
    text-transform: capitalize;
}
.Manual-time-entries-root-body-listing-content-text-delete{
    color :red;
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;
    padding-left: 5px;
}
/* .manual-timer-modal-body .react-datepicker-wrapper {
    width:30%;
} */
.manual-page-content-wrapper-menu-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    width:85%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 2%;
}
.Manual-time-entries-root-body-listing-content-text{
    margin-left: 1rem;
    font-size: 17px;
}
/* risk Modal CSS */
.manual-timer-modal .modal-dialog {
    max-width: 56%;
}
.manual-timer-modal-header .close {
    font-size: 1.2rem;
    margin-top: -3%;
}
.manual-timer-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: #000000a8;
}
.manual-timer-modal-body-formik{
    display: flex;
}
.manual-time-hour{
    width: 13%;
    margin-left: 2%;
    margin-right: 2%;
}
.manual-page-content-wrapper-menu-filter-date {
    /* display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease; */
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 2%;
}
.manual-time-add-button{
    margin-bottom: 1rem;
}
.manual-time-modal-list{
    margin-top:1rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 280px);
}
.Manual-time-entries-review-root {
    padding: 2rem;
}
.manual-review-usage-root-listing-card-content-avatar {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #fff;
    background-color: #8394ab;
    margin-right: 8px;
}
.manual-time-review-name {
    margin-right: 20px;
    font-size: 15px;
    width: 125px;
    /* color: #34495e; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.manual-time-review-text-hours {
    font-size: 15px;
    width: 315px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-review-usage-root-list {
    /* height: 400px; */
    height: calc(100vh - 185px);
    background: #fff;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}
.manual-time-review-usage-root-listing {
    padding: 0% 0% 1% 0%;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 260px; */
    height: calc(100vh - 294px);
}
.manual-time-review-usage-root-listing-whole-div {
    display: flex;
    padding: 0.9rem !important;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 0.9rem;
}
.manual-time-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 20px;
    margin-bottom: .9rem;
    margin-top: .5rem;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
    /* color: #495057eb; */
}
.manual-time-review-hours{
    margin-right: 25px;
    /* color: gray; */
    font-size: 15px;
    width: 115px;
}
.manual-time-review-task-name{
    display: flex;
    flex-direction: column;
    margin-left: 9px;
}
.manual-time-review-row{
    /* justify-content: space-between; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.manual-time-review-display-name{
    display: flex;
    align-items: center;
}
.manual-time-review-text-project-name{
    font-size: 13px;
    color: grey;
    width: 315px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}   
.manual-time-review-column{
    display: flex;
    /* flex-direction: column; */
    flex-basis: 100%;
    flex: 1 1;
}
.manual-time-review-button-div{
    visibility: hidden;
}
.manual-time-review-column-header {
    font-weight: 500;
    color: gray;
}
.manual-time-review-usage-search-root-search-button-div {
    width: 25% !important;
}
.project-details-task-card-delete-body-p-tag {
    color: gray;
    margin-top: 10px;
    font-size: 12px;
}
.review-usage-search-root-search-input::-moz-placeholder{
    color:#80808070;
}
.review-usage-search-root-search-input::placeholder{
    color:#80808070;
}
.manual-time-review-usage-root-list nav a{
    font-size: 0.9rem !important;
}
.manual-time-review-usage-root-listing p {
    text-align: center;
}
.manual-task-project-details-task-card-delete-cancel-button, .manual-task-project-details-task-card-delete-button {
    font-size: 0.9rem;
    padding: 4px 18px;
}
.manual-time-review-column-review-status{
    
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
    
}
@media only screen and (width : 820px) {
    .manual-time-review-usage-root-listing {
        width: 48vh;
        overflow: auto;
    }
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
}
@media only screen and (width : 768px){
    .manual-time-review-usage-root-listing {
        width: 68vh;
        overflow: auto;
    }
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
}

.organisation-settings-root{
    padding: 5rem;
}
.organisation-setting-root-text-box{
    width:500px;
}
.org-setting-root-card{
    max-width: 500px;
    margin: 0 auto;
}
.organisation-info-profile-img{
    display: inline;
    height: 100%;
    width: 100%;
}
.organisation-info-profile-photo-upload-icon-card {
    display: inline-block;
    margin-right: 10px;
    height: 80px;
    width: 80px;
    /* border-radius: 40px; */
    background: #e3e3e3ba;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 29px;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
    color: #33333394;
    position: relative;
    overflow: hidden;
}
.organisation-info-profile-photo-upload-card {
    clear: both;
    display: flex;
    align-items: center;
}
.organisation-info-profile-photo-upload-photo-card-data{
    width:135%;
}
label.custom-file-upload-text-p {
    font-size: 14px;
    color: #0000005c;
}
.organisation-setting-root-company-logo-label {
    margin-bottom: 0px !important;
}
.login-root {
    padding: 2rem;
}

/* login-root-header start */

.login-root-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.login-root-img-center {
    margin: 0 auto;
}
.login-root-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.login-root-title{
   font-weight: 400;
   font-size: 2rem;
}

/* login-root-header end */

/* login-root-content start */
.login-root-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 460px;
}
.web-login-form {
  /* display: grid;
  gap: 16px; */
  width: 100%;
}
.web-login-form input{
    margin-bottom: 20px;
}
.login-field-error-message-login{
    float:right;
    color:red;
    font-size: 14px;
}
.web-login-submit-button{
    /* background-color: #0135cd; */
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
/* .web-login-submit-button:hover{
    background-color: #6f0ca6;
} */
/* login-root-content end */

/* login-root-footer start */
.login-root-footer a {
    color: #035FCB;
    text-decoration: none;
}
/* login-root-footer end */
.login-root-member-permission{
    text-align: center;
    margin: 5rem 13rem;
}
.login-root-member-permission-icon{
    color: #3F38C8;
    font-size: 60px;
}
.login-root-member-permission-header-text {
    font-size: 24px;
    font-weight: 700;
    margin: 12px;
    color: #3f38c8db;
}
.login-root-member-permission-sub-text {
    font-size: 15px;
    margin-top: -7px;
    color: #3f38c896;
}
.login-root-title-non-workfolio{
    margin-top: 70px;
}

.login-root-content-member-error {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: initial;
    color: red;
}
.login-root-content-member-error-text {
    font-size: 17px;
    font-weight: 600;
    margin: 20px 0px 20px 0px;
}
.login-password-field-form-group.form-group {
    display: flex;
    width: 100%;
    height: calc(1.5em + 0.75rem + 4px);
    padding: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input.login-password-field-form-control.form-control {
    border: navajowhite;
    border: none;
}
span.login-password-field-label-text {
    vertical-align: middle;
    text-align: center;
    padding: 7px;
    color: #0000ffc7;
    font-size: 14px;
    cursor: pointer;
}
.login-root-content-member-error-text-header {
    text-align: initial;
    font-weight: 600;
    margin-top: 14px;
}
.login-root-content-member-error-text-header-steps div {
    text-align: initial;
    margin-left: 22px;
    font-weight: 500;
    margin-top: 10px;
}
.login-root-content-member-error-secondary-text {
    text-align: initial;
    font-weight: 500;
    margin-top: 10px;
}
.billing-plan-root {
    margin: 2rem;
}
.billing-plan-root-content {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.report-details-filter-root {
    /* margin: 0 0 34px 0; */
    display: flex;
    /* padding: 10px; */
    /* border: 1px solid #00000030; */
    justify-content: space-between;
}
.report-details-filter-content {
    display: flex;
}
.report-details-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
    display: flex;
}
.report-details-filter-user span{
    vertical-align: middle;
}
.report-details-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
    background-color: transparent;
}
.report-details-filter-download-icon {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
}
.report-details-content {
    width: 100%;
    /* height: 50vh; */
     /* overflow: auto; */
    border: 1px solid #dee2e6;
    padding: 26px;
}
.report-details-root{
    margin: 2rem;
}
label.report-details-label {
    font-size: 1rem;
    font-weight: 600;
}
.report-details-line{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
.report-details-content-report-type {
    margin-bottom: 35px;
}
.report-details-input-content span {
    margin-right: 30px;
    cursor: pointer;
}
.report-details-input-content input {
    margin-right: 4px;
    cursor: pointer;
}
.report-details-content-export-button{
    margin-bottom: 20px;
    margin-top: 30px;
}
.report-details-filter-user-select{
    width: 100%;
}
.report-details-time-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    margin-top: 2px;
    border: 1px solid #0000001a;
    padding: 20px;
}
.disable-custom-report-export{
    width: 156px;
}

.custom-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    /* border: 1px solid #ededed; */
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    max-width:-moz-max-content;
    max-width:max-content;
}
.custom-report-page-content-wrapper-menu-filter-date-text {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}
.report-details-time-label-div{
    display: flex;
    justify-content: space-between;
}
.report-details-select-all-label{
    font-size: 14px;
    margin-bottom: 0;
    color: grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
}

.employee-report-root-card{
    padding:2rem;
}

.monthly-attendence-root {
    display: grid;
    grid-template-columns: 1fr;
}
/* .monthly-attendence-container {
    width: 100%;
    overflow: auto;
    height: 100vh;
} */

.monthly-attendance-scroll-sticky {
    width: 100%;
    height: 40px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
}
.monthly-attendence-content {
    width: 100%;
    height: 67vh;
    overflow: auto;
  }

.monthly-attendence-table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #dee2e6;
  font-size: 0.9rem !important;
}

.monthly-attendence-table  thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.monthly-attendence-table  thead > tr  th:first-child {
  background-color: #F3F7FA !important;
    /* text-align: center; */
}

.monthly-attendence-table thead> tr th:nth-last-child(-n+3),.monthly-attendence-table tbody> tr td:nth-last-child(-n+3) {
    /* width:2rem; */
    /* min-width: 10rem;
    max-width: 10rem; */
  }

.monthly-attendence-tbody {
    display: block;
}

.monthly-attendence-table tr {
    height: 3rem;
    cursor: pointer;
}
.monthly-attendence-table tr:nth-child(even) {
    /* background-color: #F2F2F2; */
}
.monthly-attendence-table tr:nth-child(odd) {
    /* background-color: #FAFBFD; */
}
.monthly-attendence-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.monthly-attendence-table tr:hover {
    background-color: #F3F7FA;
}
.monthly-attendence-table tr:last-child td {
    border-bottom: 0;
}

.monthly-attendence-table th,
.monthly-attendence-table td {
    min-width: 7rem;
    max-width: 7rem;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #E9ECEF;
}
.monthly-attendence-table th:first-child{
    min-width: 16rem;
    max-width: 16rem;
}

.monthly-attendence-table td#selected {
    background-color: #419BDA;
}
.monthly-attendence-table th {
    /* font-size: 1rem; */
    cursor: default;
}
/* .monthly-attendence-table th,.monthly-attendence-table td {
text-align: center;
} */
.monthly-attendence-table th,.monthly-attendence-table tbody > tr th {
    background-color: #F3F7FA!important;
  
}
.monthly-attendence-table th:not(:first-child) {
    text-align: center !important;
    font-size: 0.9rem;
}
.monthly-attendence-table td:not(:first-child) {
    text-align: center !important;
}
.monthly-attendence-thead-tr-th{
    font-weight: 600 !important;
    color: #00000094;
}
.monthly-attendence-thead{
    position: sticky;
    top: 0px;
    z-index: 50;
}
.monthly-attendence-table tbody th,.monthly-attendence-table tbody td{
    color: #000000d6;
}
.monthly-attendence-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.monthly-attendence-date-picker .react-datepicker-wrapper .react-datepicker__input-container input{
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.monthly-attendence-date-picker .react-datepicker__tab-loop .react-datepicker-popper {
    z-index: 99;
}
.monthly-attendence-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.monthly-attendence-filter-user .monthly-attendence-filter-user-select .User__menu{
    z-index:99;
}
.monthly-attendence-download-icon {
    /* color: #2A8EF7; */
    font-size: 1.1rem;
    font-weight: 700;
}
.monthly-attendence-filter-content{
    display: flex;
}
.monthly-aatendance-load-more-button-div {
    text-align: center;
    margin-top: 21px;
}

.monthly-aatendance-load-more-button-div button{
   font-size: 14px; 
}
.monthly-attendence-download-export-button:hover .monthly-attendence-download-icon{
    color:white;
}
.monthly-attendence-tbody-tr-td-absent{
    color: gray !important;
    font-size: 13px;
}
th.monthly-attendence-thead-tr-th {
    padding: 12px;
}
.monthly-attendence-header-label-badge span{
    padding: 10px;
}
.monthly-attendence-header-label-badge-2 {
    /* padding: 10px; */
    margin-left: 5px;
}
.monthly-attendence-header-label {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
}
/* .monthly-attendence-tbody-tr-td-uninformed-leave {
    color: orange !important;
}
.monthly-attendence-tbody-tr-td-informed-leave {
    color: #ff00006e !important;
} */
td.monthly-attendence-tbody-tr-td.monthly-attendence-tbody-tr-td-non-working-day {
    color: #00000054;
    font-size: 13px;
}
.monthly-in-out-content-table-tbody-td-status {
    color: gray !important;
    min-width: 8rem;
    max-width: 8rem;
}
.monthly-in-out-content-table-tbody-td-status-present{
    background-color: #CDE9CD;
    /* color: green !important; */
}
.monthly-in-out-content-table-tbody-td-status-weekoff{
    background-color: antiquewhite;
}
.monthly-attendance-table-tbody tr{
    line-height: 1.4;
}
.monthly-in-out-content-table-tbody-td-status-half{
    background-color: #eded94ad;
}
.daily-attendance-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.daily-attendance-filter-new-root {
    margin: 2rem;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.daily-attendance-tr-table-grid-staus{
    width:30%;
}
.daily-attendance-tr-table-grid-leave-name{
    width:40%;
}
.daily-attendance-filter-root-tab{
    background-color: #fff;
}
.daily-attendance-filter-content {
    display: flex;
}
.daily-attendance-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.daily-attendance-filter-download-icon {
    /* color: #007bff; */
    font-size: 1.1rem;
    font-weight: 700;
}
/* .daily-attendance-filter-download-icon:hover {
    color: #fff !important;
} */
.daily-attendance-filter-download-icon-btn:hover {
    color: #fff !important;
}
.daily-attendance-content {
    width: 100%;
    height: calc(100vh - 215px);
    overflow: auto;
}
.daily-attendance-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin:2rem;
}
.daily-attendance-content-new th,.daily-attendance-content-new td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.daily-attendance-table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #dee2e6;
}
.daily-attendance-absent-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
}
.daily-attendance-table-load-more-btn{
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.daily-attendance-filter-user-tab-filter {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-filter-user-tab-filter .loading-wrapper .loading-svg {
    font-size: 35px;
}
.daily-enable-expected-clockin-track-settings {
    width: -moz-fit-content;
    width: fit-content;
    margin: 45px auto;
}
.daily-no-data-tab{
    text-align: center;
}
.daily-attendance-table-load-more-tr td{
    border :none !important;
}
.daily-attendance-table-load-more-td{
display: flex;
justify-content: center;
}
.daily-attendance-root{
    margin:2rem;
}
.daily-attendance-content th,
td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;

}
.daily-attendance-table thead tr th{
    background-color: #F3F7FA !important;
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
}
.daily-attendance-table tbody tr td{
    color: #000000d6 !important;
    font-size: 0.9rem;
}
.daily-attendance-table thead tr th,.daily-attendance-table tbody tr td{
    padding: 0.7rem !important;
}

.daily-attendance-absent-table thead tr th{
    background-color: #F3F7FA !important;
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
}
.daily-attendance-absent-table tbody tr td{
    color: #000000d6 !important;
    font-size: 0.9rem;
}
.daily-attendance-absent-table thead tr th,.daily-attendance-absent-table tbody tr td{
    padding: 0.7rem !important;
}
.daily-attendance-tr-table-leave-status-uninformed {
    /* font-size: 14px; */
    background-color: #ff000033;
    /* width: fit-content;
    padding: 3px 8px;
    border-radius: 4px; */
}
.daily-attendance-tr-table-leave-status-approved {
     background-color: #00800040;
}
.daily-attendance-tr-table-leave-status-rejected {
    background-color: rgba(255, 166, 0, 0.515);
}
.daily-attendance-tr-table-grid-employee-id {
    color: gray;
    font-size: 12px;
    margin-top: 2px;
}
.daily-attendance-tr-table-grid-employee-avatar-circle {
    height: 28px;
    width: 28px;
    font-weight: bold;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
    margin: auto 0;
    margin-right: 12px;
    /* position: absolute; */
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
}
.daily-attendance-tr-table-grid-employee-displayname-wrapper{
    display: flex;
}
.daily-attendance-tr-table-grid-employee-name {
    margin-top: 3px;
}


.monthly-in-out-root {
    margin: 2rem;
   
}
/* .monthly-in-out-content{
    width: 100%;
    height: vh;
    overflow: auto;
  
} */
.monthly-in-out-content-view {
    /* width: 100vw; */
    display: grid;
  }
  
  .monthly-in-out-content-wrapper {
    /* position: relative;
    overflow: auto;
     white-space: nowrap;
    display: grid;
    grid-template-columns: 1fr; */
    width: 100%;
    height: 68vh;
    overflow: auto;
  }
  
  .monthly-in-out-content-sticky-col {
    position: sticky;
    background-color: white !important;
    min-width: 19rem !important;
    max-width: 19rem !important;
    text-align:left  !important;
  }

  .first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
  }
  .monthly-in-out-content-table-thead th{
        border-bottom: 1px solid #dee2e6 !important; 
  }
  /* .monthly-in-out-content-table-thead-table-row {
    position: sticky;
    top: 0px;
    z-index: 50;
  } */
  .monthly-in-out-content-table-thead{
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .monthly-in-out-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    vertical-align: middle;
    /* text-align: left; */
}
.monthly-in-out-table thead > tr th,.monthly-in-out-table tbody > tr td:not(:first-child) {
     text-align: center; 
}
.monthly-in-out-table th, .monthly-in-out-table tbody > tr td:first-child{
    background-color: #F3F7FA!important;
}

.monthly-in-out-table thead tr th{
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
    min-width: 6rem;
    max-width: 6rem;
}
.monthly-in-out-table thead> tr th:nth-last-child(-n+3) {
    vertical-align: middle;
}
.monthly-in-out-table thead > tr th:first-child ,.monthly-in-out-table thead> tr th:nth-child(2) ,.monthly-in-out-table thead> tr th:nth-child(3){
    /* min-width: 10rem;
    max-width: 10rem; */
}
.monthly-in-out-table thead>tr th:nth-child(2) ,.monthly-in-out-table thead>tr th:nth-child(3) {
    vertical-align: middle;
}

.monthly-in-out-table tbody> tr td {
    border-right: 0px !important;
    font-size: 0.9rem;
}
.monthly-in-out-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.monthly-in-out-filter-content {
    display: flex;
    z-index:50;
}
.monthly-in-out-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.monthly-in-out-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.monthly-in-out-filter-download-icon {
    color: #2A8EF7;
    font-size: 1.1rem;
    font-weight: 700;
}
.monthly-in-out-load-more-button-div {
    text-align: center;
    margin-top: 21px;
    /* width: 14%; */
}
/* .monthly-in-out-table thead th{
    position: sticky; 
    top: 0; 
    z-index: 20;
} */
.monthly-in-out-content-table-tbody-td-absent{
    color: gray !important;
}
.monthly-in-out-header-label {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}
.monthly-attendence-header-label-badge-na {
    margin-right: 10px;
}
.monthly-in-out-table-avatar-profile {
    width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
    margin: auto 0;
    margin-right: 12px;
}
.monthly-in-out-table-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.offline-task-card {
  margin: 2rem;
}
.offline-task-list {
  border: 1px solid #00000014;
  width: 41rem;
  padding: 0.9rem;
  margin: 1rem;
}
span.offline-task-name {
  font-size: 0.8rem;
}
span.offline-task-time {
  float: right;
  font-size: 0.7rem;
}
.offline-task-user-details {
  display: flex;
}

.offline-task-user-details-avatar {
  height: 20px;
  width: 20px;
  background-color: rgb(241 122 52 / 83%);
  color: rgb(244 245 249);
  font-weight: bold;
  line-height: 20px;
  font-size: 11px;
  text-align: center;
  margin-right: 0.3rem;
  border-radius: 50%;
}
.offline-task-user-data {
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}
span.offline-task-user-details-user-name {
  font-size: 0.75rem;
  margin-top: -2%;
}
.offline-task-time-icon {
  font-size: 0.7rem;
  margin-top: -3%;
}
.offline-task-card-title{
    font-size: 1.1rem;
    font-weight: 600;
    color: #000000a6;
    margin: 1rem;
}
.performance-report-root {
    padding: 2rem;
  }
.report-app-usage-root {
    margin:2rem;
}
.app-usage-report-filter-root {
    margin: 0 0 34px 0;
    padding: 10px;
}
.app-usage-reports-filter-root-div {
    display: flex;
    justify-content: space-between;
}
.app-usage-report-filter-user{
    display: flex;
}
.app-site-report-align {
text-align: center !important;
}
.review-usage-root-listing-card-content-avatar-report {
    height: 20px;
    width: 20px;
    text-align: center !important;
    /* vertical-align: middle !important; */
}
.app-usage-report-users-button .btn-primary:hover {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
}

.app-usage-report-users-button .btn-primary:active {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}

.app-usage-report-users-button .btn-primary:focus {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}

.app-usage-report-users-button .btn-primary {
    border: none;
    color: #007bff;
    background-color: rgba(38,109,251,.06274509803921569);
    margin-right: 1.5rem;
}

.apps-usage-users-button .btn-primary {
    border: none;
    /* color: #007bff;
    background-color: rgba(38,109,251,.06274509803921569); */
    margin-right: 1.5rem;

    width: 280px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}
.project-report-apply-button {
    width:auto !important;
}
.apps-usage-users-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}
.daily-attendance-filter-download-icon-btn-disable {
    cursor: not-allowed;
}

.apps-usage-users-button .btn-primary:hover {
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.apps-usage-users-button .btn-primary:active {
    
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important; */
    /* border-color: none !important;
    box-shadow: none !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.apps-usage-users-button .btn-primary:focus {
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.app-usage-datas-thead-displayName{
    width:200px;
     max-width:200px; 
}
.app-usage-datas-thead-title{
    width:400px;
    max-width:500px;
    overflow: overlay;
}
.app-usage-datas-thead-productivityStatus{
    width:200px;
    max-width:200px;
}
.app-usage-datas-thead-totalSec{
    width:200px;
    max-width:200px;
}
.app-usage-datas-thead-sort-icon {
    font-size: 15px;
}
.report-activity-summary-root {
    margin:2rem;
}

.manual-time-report-filter-root{
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.manual-time-report-filter-content{
    display: flex;
}
.manual-time-report-filter-user {
    width: 240px;
    margin-bottom: 0;
    margin-right: 10px;
}

.manual-time-report-users-button .btn-primary {
    border: none;
    margin-right: 1.5rem;
    width: 240px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}
.manual-time-report-users-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.manual-time-report-users-button .btn-primary:hover {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.manual-time-report-users-button .btn-primary:active {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.manual-time-report-users-button .btn-primary:focus {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}
.manual-time-report-details-filter-user-select{
    width: 246px !important;
}
.workfolio-integration-content-root {
    margin: 2rem;
}
.workfolio-integration-content-root-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
}
.workfolio-integration-content-root-card-integration-icon img {
    /* width: 40px; */
    height: 28px;
}
.workfolio-integration-content-root-card-content {
    border: 0.5px solid #00000012;
    border-radius: 6px;
    /* display: flex; */
}
.workfolio-integration-content-root-card-integration-name {
    font-size: 22px;
    /* margin-top: 8px; */
    margin-left: 13px;
}
.workfolio-integration-content-root-card-integration-icon {
    text-align: center;
    /* margin: 20px; */
}
.workfolio-integration-content-root-card-integration-submit-root {
    margin: 25px 20px;
    text-align: center;
}
.workfolio-integration-content-root-card-integration-connect-button {
    padding: 4px 30px;
}
.workfolio-integration-content-root-card-integration-type {
    margin: 20px;
    /* margin-left: 20px; */
    /* display: flex; */
}
.workfolio-integration-content-root-card-integration-checkbox{
    text-align: left !important;
    color: #2c2b2bc9;
}
.workfolio-integration-content-root-card-integration-submit-connected {
    margin: 16px 24px;
}
.workfolio-integration-content-root-card-integration-checkbox-input label{
    margin-left: 8px;
}
.workfolio-integration-content-root-card-integration-save-button {
    margin-top: 10px;
    padding: 2px 15px;
    font-size: 15px;
}
.workfolio-integration-content-root-card-disconnect {
    font-size: 12px;
    color: red;
    margin-top: -7px;
    cursor: pointer;
}
.workfolio-integration-content-root-card-integration-name {
    display: flex;
    flex-direction: column;
}
.workfolio-integration-content-root-card-disconnect:hover{
    text-decoration: underline;
}

.workfolio-integration-content-root-card-integration-submit-connected {
    margin: -8px 24px 21px 24px;
}

.workfolio-integration-content-root-card-integration-disconnect {
    text-align: center;
    font-size: 12px;
    color: #ff000078;
    margin-top: 3px;
    cursor: pointer;
}

.workfolio-integration-content-root-card-integration-disconnect:hover {
    color: red;
    text-decoration: underline;
}
.shift-time-root {
    margin: 2rem;
}
.shift-time-card-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    grid-gap: 1.5rem;
}
.shift-time-div-wrapper {
    background-color: #fff!important;
}
.shift-time-card {
    width: 100%;
    padding: 1.3rem;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    transition: all .2s ease-out;
    
}
.shift-time-card:hover {
    transition: all .2s ease-out;
    box-shadow: 0 12px 19px rgb(38 38 38 / 20%);
}
.shift-time-name-div {
    width: 100%;
    margin-bottom: 3%;
}

.shift-time-card-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    color: #333;
    white-space: nowrap;
    width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    cursor: pointer;
}
.shift-time-card-avatar {
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    height: 35px;
    width: 35px;
    border-radius: 37px;
    background: #e3e3e3;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 17px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    color: rgba(51,51,51,.8392156862745098);
    margin-top: 0;
    cursor: pointer;
}

.shift-time-card-percentage {
    margin-left: auto;
    margin-right: 10px;
}
.shift-time-card-percent {
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
}
.shift-time-card-percent-text {
    font-size: 10px;
    line-height: 15px;
    color: #333333;
}
.shift-time-wrapper-div {
    cursor: pointer;
}
.shift-time-card-body-text{
    font-size: 15px;
    margin-bottom: 0.9rem;
    margin-top: 0.9rem;
}

.shift-time-card-footer-text{
    font-size: 15px;
    margin-bottom: 0.5rem;
    color: #008000d1;
}
.add-shift-time-button{
    display: flex;
    margin-bottom: 2rem;
}
.shift-time-button{
    position: relative;
    margin-left: auto;
    margin-right: 0;
}

.shift-start-time-formik-add-form {
    margin-bottom: 0 !important;
}

.shift-app-time-formik-add-form {
    margin-bottom: 0 !important;
}

.shift-time-edit-label-input {
    font-size: 0.9rem !important;
}

.shift-start-time-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 430px;
    font-size: 15px !important;
    font-size: 0.9rem !important;
}
.shift-app-time-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 390px;
    font-size: 15px !important;
    font-size: 0.9rem !important;
}


.error+.shift-start-time-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.shift-start-time-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.error+.shift-app-time-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.shift-app-time-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.shift-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    /* width: 140px; */
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.shift-start-time-modal-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}

.shift-time-modal-accordion-whole {
    margin-bottom: 1.5rem !important;
    width: 100%;
}

.shift-time-modal-content-header-team-name-acc {
    display: flex;
    margin-bottom: 0.5rem !important;
    /* text-decoration: underline !important; */
    justify-content: space-between;
    width: 100%;
}

.shift-time-details-modals-root {
    padding: 2rem;
}
.shift-time-details-modals-header {
    display: grid;
    /* grid-template-columns: repeat(auto-fill,minmax(195px,1fr)); */
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.shift-time-details-modals-table {


}
.shift-time-card-footer-text-gray{
    color: grey;
}
.shift-time-details-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 370px;
    /* border: 0.5px solid #00000014; */
}
.modal-90w {
    width: 95%;
    max-width: none!important;
}
.shift-time-details-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    font-size: .87rem;
    font-weight: 500;
    color: rgba(0,0,0,.7098039215686275);
    /* padding: 1rem 1.3rem 1rem; */
}
.shift-time-details-employee-list-card-data-table {
    /* border-collapse: separate; */
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
    border: 0.5px solid #00000014;
}
.shift-time-details-employee-list-card-data-header {
    color: rgba(121,119,119,.8588235294117647);
    /* border: none; */
    font-size: 14px;
}
.shift-time-details-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    font-size: 15px;
    /* box-shadow: 0 0 0 0 rgb(0 0 0 / 10%), 0 1px 0 0 rgb(0 0 0 / 6%); */
    text-align: center;
}

.shift-time-details-employee-list-card-data-table th{
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.shift-time-details-employee-list-card-data-table td {
    /* border: none; */
    vertical-align: middle;
    border-top: none;
    /* border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
}

.shift-time-details-employee-list-card-details p {
    margin-bottom: 0rem;
    /* display: flex; */
    align-items: center;
    color: #212529;
    
}
.shift-time-details-employee-list-card-details p:hover {
    color: #212529;
}
.shift-time-details-employee-list-card-data-table th, .shift-time-details-employee-list-card-data-table td {
    vertical-align: middle;
}
.shift-table-text-align{
    text-align: left !important;
}

.shift-detail-early-color {
    color: #008000d1;
}
.shift-detail-late-color{
    color: #ff0000cf;
}
.shift-time-details-employee-list-card-data-table th{
    background-color: #f3f7fa!important;
    font-weight: 600!important;
    color: rgba(0,0,0,.5803921568627451);
    text-align: center;
}
.shift-time-details-employee-list-card-heading{
    font-weight: 700;
    color: rgb(0 0 0 / 78%);
    font-size: 1rem;
    
}

.shift-time-card-root-list-section-body {
    margin: 2rem 1rem 0 1rem;
    min-height: 10vh;
}

.shift-time-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 240px;
}
.shift-detail-filter-download-icon-btn{
    margin-left: 35px;
}
.shift-time-date-picker {
    position: relative;
    margin-left: auto;
    margin-right: 0;
}
.shift-time-modal-header-date-div{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
.shift-details-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.shift-details-task-details-card h4{
    font-size: 17px;
}

.shift-details-summary-card-text-ques {
    /* font-size: 1rem;
    color: #808080ba; */
    color: #789;
    font-size: 15px;
    margin-left: 3px;
}
.shift-time-card-root-list-card-loading .loading-wrapper svg{
    height: 20px !important;
}
.shift-time-filter-user {
    width: 462px;
    margin-bottom: 30px;
}
.shift-time-filter-user .rmsc {
    color:#495057 !important;
}

.shift-time-detail-card-h4-text {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.shift-detail-start-width{
    width: 120px;
}
.shift-detail-early-width { 
    width: 95px;
}
.shift-details-employee-list-card-details-avatar {
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.shift-details-card-avatar {
    height: 25px;
    width: 25px;
    background-color: #ededed;
    color: #424242;
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 25px;
}
.shift-details-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.shift-details-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.shift-time-card-root-list-card-loading .loading-wrapper{
 text-align: left !important;
}
.shift-time-details-employee-list-card-details-working-on{
    background-color: #dbfbe791 !important;
}
.shift-time-details-employee-list-card-details-stop{
    background-color: rgb(220 53 69 / 6%) !important;
}
.shift-time-create-col {
    text-align: center !important;
}
.shift-time-modal-save-button {
    font-size: 0.9rem;
}
.shift-time-delete-cancel-button {
    font-size: 0.9rem;
    margin-right: 1rem;
}
.shift-detail-modal-time-div {
    font-size: 14px;
    font-weight: 500;
    min-width: 165px;
}
.shift-detail-modal-time-div-width {
    width: 115px;
}
.shift-detail-modal-total-time-width {
    width: 100px;
}
.error+.multi-select .dropdown-container {
    border-color: #F0624D !important;
}
.shift-detail-top-performer-span{
    color: #343a40a1!important;
}
.shift-time-modal-header-date-div-text-div{
text-align: center;
}
.shift-time-modal-header-date-div-text-div-head
{
    margin-bottom: 7px;
    font-size: 15px;
    font-weight: bold;
}
.shift-time-modal-header-date-div-text-div-head-mute{
    margin-bottom: 1rem;
    font-size: 13px;
    color: gray;
}

.shift-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    max-width: -moz-max-content;
    max-width: max-content;

    /* height: 36px; */
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    min-width: 260px;
}
.custom-report-page-content-wrapper-menu-filter-date-text{

}
.shift-detail-miled-text{
    color: #585757;
}
.shift-modal-title.modal-title.h4 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    align-items: center;
    text-align: center;
    width: 97%;
    flex-shrink: 0;
}

@media screen and (max-width: 480px) {
    .shift-time-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
        width: 140px !important;
    }
    .shift-table-text-align{
        width:93px;
    }
}
.shift-time-card-edit-menus #dropdown-basic {
    background-color: transparent;
    /* color: transparent; */
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    left: -35px;
    /* margin-right: 1.5rem; */
}
.shift-time-card-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}
.shift-time-card-edit-menus #dropdown-basic {
    right: 0px !important;
}
.shift-time-card-edit-menus-icon {
    color: #00000094;
    /* float: right; */
    font-size: 0.9rem;
    /* margin-top: -16%; */
}
.shift-time-details-employee-list-card-details-internet-interrupt {
    background-color: #ffa50038;
}
.shift-time-export-button-header {
    margin-left: 15px;
}
.project-settings-card {
    padding: 2rem;
}
.project-settings-row{
  display: initial !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.project-settings-row > * {
    margin-bottom: 1rem;
}
.project-settings-row-grid, .project-settings-row-grid-1, .project-settings-row-grid-2 {
    /* align-items: start; */
    /* gap: 30px; */
    -moz-columns: 2 calc((100vw - 420px - 8rem) / 2);
    columns: 2 calc((100vw - 420px - 8rem) / 2);
    display: grid !important;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
    padding-left: 0px !important;
    padding-right: 0px !important;
  
}
.project-settings-row-grid-card {
    /* height: auto; */
    display: inline-block;
    margin-bottom: 2rem;
    /* width: calc((100vw - 200px - 6rem) /2); */
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 20px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.project-settings-row-grid-card h6 {
    color: black;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}
.project-settings-radio-button-card label {
    margin-right: 16px;
}
/* .project-settings-project-management-card-root {
    margin-bottom: 22px;
} */
.project-settings-radio-button-card label input {
  margin-right: 4px;
}
.project-settings-radio-button-card.form-group {
  margin-bottom: 0rem !important;
}
.project-settings-project-management-new-project-card-root,.project-settings-project-management-enable-card-root{
  margin-bottom: 1rem;
}










.my-projects-project-settings-modal-root .modal-dialog .modal-content{
    padding: 0 16px;
    border-radius: 12px;
  }
  .my-projects-project-settings-modal-header{
    /* border-bottom: none !important; */
  }
  .my-projects-project-settings-modal-title.modal-title.h4 {
    /* font-size: 1.2rem; */
    /* font-size: 1.2rem; */
    color: #333333;
    /* font-family: "Helvetica Now Display"; */
    font-size: 20px;
    font-weight: 600;
    /* letter-spacing: .35px; */
    text-align: center;
    /* line-height: 32px; */
    margin: 0;
    /* display: flex; */
    align-items: center;
    text-align: center;
    width: 90%;
    flex-shrink: 0;
  }
  .my-projects-project-settings-modal-content-row.row {
    margin-top: 8px;
  }
  .project-settings-task-modal-private-toggle-div {
    display: flex;
    margin-bottom: 1.5rem !important;
    justify-content: space-between;
    width: 100%;
  }
  .project-settings-project-tasks-modal-content-header-setting-icon {
    font-size: 0.75rem;
    margin-top: -2px;
  }
  
  .project-settings-task-modal-private-toggle-div-command-line {
    color: #8c94a1;
    font-size: 13px;
    letter-spacing: -.2px;
    line-height: 17px;
    max-width: 385px;
    padding-top: 7px;
  }
  
  .project-settings-tasks-rule-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
  }
  
  .project-settings-toggle-switch label {
    cursor: pointer;
    margin-bottom: 0 !important;
  }
  
  .project-settings-toggle-switch label .project-settings-toggle-track {
    display: block;
    height: 20px;
    width: 35px;
    background: #e1e4e8;
    border-radius: 20px;
    position: relative;
    /* margin-bottom:15px; */
    border: 1px solid #ccc;
    transition: .4s;
  }
  
  .project-settings-toggle-track-nav-right-div {
    background: #7dd46e !important;
  }
  
  .project-settings-toggle-track-nav-right-div:before {
    transform: translateX(15px) !important;
  }
  
  .project-settings-toggle-switch .project-settings-toggle-track:before {
    content: '';
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: right .2s ease-in;
    left: 0px;
    bottom: 2px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
    transition: .4s;
  }
  
  .project-settings-tasks-modal-content-person-name-header-section-label {
    line-height: 18px;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
  }
  
  .project-settings-tasks-modal-content-person-name {
    overflow: auto;
    /* height: 100%; */
    flex-grow: 1;
  }
  
  .project-settings-tasks-modal-content-person-name-header {
    /* display: flex; */
    margin-bottom: 11px;
  }
  
  .project-settings-tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 1.5rem;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
  }
  .project-settings-my-projects-modal-content-person-name-header-section {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
  }
  .project-settings-my-projects-modal-content-person-name-header-section-label {
    /* font-size: 1rem;
    line-height: 18px;
    margin-bottom: 0.5rem;
    color: #62839b; */
    color: #535c68fc;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .project-settings-my-projects-modal-content-person-name-header-section-content {
    display: flex;
  }
  .project-settings-project-task-modals-searchbox-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .project-settings-project-task-modals-user-label-section-div {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #edeef2;
  }
  .project-settings-project-task-modals-user-label-section {
    height: 32px;
    color: #8c94a1;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .55px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* box-shadow: inset 0 -1px 0 0 #edeef2; */
    text-transform: uppercase;
    width: 265px;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
  }
  .project-settings-tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 1.5rem;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  
  .project-settings-project-task-modals-content-person-name-listing-card-content {
    /* margin-bottom: 2px; */
    display: flex;
    margin-top: 10px;
  }
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  .project-settings-task-card-avatar-profile {
    width: 22px;
    height: 22px;
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .project-settings-task-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
  }
  .project-settings-tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
  }
  .project-settings-my-projects-modal-content-header-team-name {
    font-size: 0.97rem !important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 355px;
      text-align: left;
  }
  .project-settings-my-projects-modal-content-header-team-name:hover {
    color: #62839b;
    /* text-decoration: underline !important; */
  }
  .project-settings-my-projects-modal-content-header-team-name-acc{
    display: flex;
    margin-top: 4px !important;
  }
  .project-settings-add-project-modal-button-rows {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .project-settings-my-projects-modal-button {
    margin: auto;
    text-align: center;
  }
  .project-settings-my-projects-modal-save-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
  }
  .project-settings-project-task-modals-searchbox-wrapper-input.form-control {
    font-size: 14px;
  }
  .my-project-summary-enable-project-management-enable-btn{
      margin-top: 43px;
      font-weight: 600;
  }
  .project-settings-project-management-card-title {
    /* font-size: 15px; */
    color: #000000ba;
  }
.project-settings-radio-button-card label {
  margin-right: 20px;
  /* font-size: 14px; */
  margin-top: 5px;
}
.project-settings-project-task-modals-searchbox-wrapper-input{
  flex-grow: 1;
  flex-basis: 50%;
}
.project-settings-tasks-modal-content-person-name-header-button{
  margin-left: 23px;
  flex-basis: 50%;
  cursor: pointer;
}

.manual-time-manual-card-list-section {
    margin: 2rem;
}
.manual-time-project-card-root {
    padding: 0px 15px 10px;
}
.manual-time-manual-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}
.manual-time-project-add-card-formik {
    display: flex;
    justify-content: space-around;
    grid-gap: 1%;
    grid-gap: 1%;
    gap: 1%;
}
.manual-time-project-add-card-formik .manual-time-project-add-card-formik-description {
    margin-bottom: 0rem !important;
    width: 30%;
}
.manual-time-project-add-card-formik-description-without-project{
    width: 40% !important;
}
.manual-time-project-add-card-formik-description .form-control {
    font-size: 18px !important;
    /* background-color: #f3f3f3; */
}
.manual-time-project-formik-add-project-button {
    font-size: 15px !important;
}
.manual-time-project-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-project-formik-add-project-button .dropdown-menu {
    min-width: 24rem !important;
}
.manual-time-project-formik-add-form {
    margin-bottom: 0 !important;
}
.manual-time-project-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
.workfolio-manual-pages-calender-div {
    display: flex;
}
.workfolio-manual-pages-calender-icon-div {
    display: flex;
}
svg.workfolio-manual-pages-calender-icon {
    font-size: 20px;
    text-align: center;
    margin-top: 4px;
    color: #87898b;
    cursor: pointer;
}
.workfolio-manual-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
}
.manual-time-project-formik-add-button {
    font-size: 15px !important;
}
.manual-time-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.manual-time-date-divider-label-icon svg {
    font-size: 15px;
    color: #000000a1;
    margin-top: -3px;
}
.manual-time-manual-card-table-body {
    color: #333;
}
.manual-time-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}
.manual-time-manual-card-table th, .manual-time-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.9rem;
}
.manual-time-manual-card-table-data-details {
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}
.manual-time-manual-card-table-description {
    width: 310px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-project-card-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.manual-time-load-more-button {
    display: flex;
    justify-content: center;
}
.add-manual-time-card-details {
    margin-top: 2rem;
}



.manual-time-dashboard-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.manual-time-dashboard-card {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
}
.manual-time-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
}
.manual-time-manual-card-list-section-header-div {
    display: block;
    padding: 0.5rem 1rem 0rem 1rem;
}
.manual-time-manual-card-list-section-header {
    margin-bottom: 0;
    font-size: 16px;
}
.manual-time-manual-card-list-section- {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-top: 0.5rem;
}
.manual-time-manual-card-table-whole-div-fixed {
    padding: 0rem 1rem 1rem 1rem;
}
.manual-time-manual-card-list-section-header-root{
    /* margin-top: 2rem; */
}
.manual-time-data-loader-div{
    height:100vh;
}
.manual-task-list-no-data-found{
    height:100vh;
}
.error+.manual-time-project-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}
.manual-time-project-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.workfolio-manual-pages-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 5px 7px;
    height: 39px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.manual-time-select-date-filter-icon{
    margin-top: -4px;
    margin-right: 5px;
}
.manual-time-select-date-filter-button{
    font-size: 14px !important;
}
.rc-time-picker-input:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
span.rc-time-picker:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.rc-time-picker-panel-input-wrap:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.manual-time-manual-card-table-data-review-status-approved,.manual-time-manual-card-table-data-review-status-rejected,.manual-time-manual-card-table-data-review-status-pending{
    font-weight: 500;
}
.manual-time-manual-card-table-data-review-status-approved {
    color: green;
}
.manual-time-manual-card-table-data-review-status-rejected {
    color: red;
}
.manual-time-manual-card-table-data-review-status-pending {
    color: #530453;
}
.manual-time-manual-card-table-data-details-approved {
    background-color: #00800012 !important;
}
.manual-time-manual-card-table-data-details-rejected {
    background-color: #ef676712 !important;
}
.manual-time-manual-card-table-data-details-pending {
    background-color: #53045312 !important;
}
.manual-time-manual-card-table-data-all-task-label {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    color: gray;
}
.manual-time-manual-table-data-icon {
    /* color: #00b9ff; */
    /* font-size: 17px; */
    cursor: pointer;
    text-decoration: underline;
}
.manual-time-delete-task-details-footer {
    display: flex !important;
    flex-flow: column wrap;
    align-items: end;
}
.project-details-task-card-delete-cancel-button {
    margin-right: 10px;
}
.manual-time-delete-task-details-review-status div {
    color: red;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .manual-time-manual-card-list-section {
        margin: 1rem;
    }
    .manual-time-dashboard-summary {
        grid-template-columns: 1fr!important;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 100% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-text{
        font-size: 15px !important;
    }
    .manual-time-project-formik-add-button {
        margin: 0px 45px;
    }
    .manual-time-project-add-card-formik-description .form-control{
        font-size: 15px !important;
    }
    .manual-time-manual-card-table-whole-div-fixed {
        width: 34vh;
        overflow: auto;
    }
    .manual-time-manual-card-table-description {
        width: 102px;
    }
    .manual-time-project-card-table-name {
        width: 102px;
    }
    .manual-time-manual-card-root-add-section {
        padding: 16px 19px;
    }
    .manual-time-review-usage-root-listing {
        width: 34vh;
        overflow: auto;
    }
    
}
@media only screen and (min-device-width : 412px) {
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 104% !important;
    }
}
@media only screen and (width : 820px) {
    .manual-time-manual-card-table-whole-div-fixed {
        width: 46vh;
        overflow: auto;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 204% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .manual-time-manual-card-root-add-section {
        padding: 25px 25px;
    }
    .manual-time-project-formik-add-button {
        margin: 0 180px;
    }
}
@media only screen and (width : 768px){
    .manual-time-manual-card-table-whole-div-fixed {
        width: 66vh;
        overflow: auto;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 260% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .manual-time-manual-card-root-add-section {
        padding: 25px 25px;
    }
    .manual-time-project-formik-add-button {
        margin: 0 250px;
    }
}
.project-task-time-mapping-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin: 20px 20px 0 20px !important;
}
.project-task-time-mapping-add-card-formik {
    display: flex;
    justify-content: space-evenly;
}
.stop-task-edit-start-time .rc-time-picker-input[disabled]{
    color: #000000e3;
    background-color: #fff
}
.project-task-time-mapping-add-card-formik .form-group {
    margin-bottom: 0rem !important;
}
.project-task-time-mapping-add-card-formik-text-box {
    width: 20%;
}
.project-task-time-mapping-add-card-formik .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-formik-add-project-button {
    font-size: 15px !important;
    /* width: 100% !important; */
}
.project-task-time-mapping-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-formik-add-form {
    margin-bottom: 0 !important;
}
.project-task-time-mapping-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
input.rc-time-picker-input {
    height: 1.9rem;
    font-size: 0.76rem;
    color: #000000e3;
}
.rc-time-picker-input {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-task-time-mapping-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.project-task-time-mapping-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 7px;
    height: 37px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-task-time-mapping-calender-icon-div {
    display: flex;
    color: black;
}
svg.project-task-time-mapping-section-calender-icon {
    font-size: 20px;
    text-align: center;
    color: #87898b;
    cursor: pointer;
}
.project-task-time-mapping-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}
.project-task-time-mapping-project-formik-add-button {
    font-size: 15px !important;
}
.desktop-home-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.desktop-home-manual-card-table th, .desktop-home-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.desktop-home-manual-card-table-body {
    color: #333;
}
.desktop-home-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}
.desktop-home-manual-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}
.desktop-home-manual-card-table-description {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.task-tracker-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}
.desktop-home-manual-card-list-section {
    margin: 1rem;
}
.project-task-time-mapping-content-wrapper-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.project-task-time-mapping-add-card-formik-responsive {
    display: flex;
    justify-content: space-evenly;
}
.project-task-time-mapping-add-card-formik-responsive .form-group {
    margin-bottom: 0rem !important;
}
.project-task-time-mapping-formik-add-form-text-box {
    max-width: 20%;
    width: 25%;
}
.project-task-time-mapping-add-card-formik-responsive .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-add-card-formik-responsive .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-formik-add-form {
    margin-bottom: 0 !important;
}

.project-task-time-mapping-root-list-section-body {
    margin: 0rem 1rem 0 1rem;
    min-height: 100vh;
}
.project-task-time-mapping-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem !important;
}
.project-task-time-mapping-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem  !important;
}
.project-task-time-mapping-table th, .project-task-time-mapping-table td {
    border: none;
    vertical-align: middle;
    padding: 0.7rem 0.7rem 0.7rem 0.9rem;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.project-task-time-mapping-table-header {
    color: gray;
    border: none;
    font-size: 15px;
}
.project-task-time-mapping-table th, .project-task-time-mapping-table td {
    border: none;
    vertical-align: middle;
    padding: 0.8rem;
}
.project-task-time-mapping-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: white !important;
    /* line-height: 43px; */
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 532px !important;
}
.project-task-time-mapping-table-list-timeline-task-name {
    width: 496px !important;
    /* cursor: pointer; */
}
.project-task-time-mapping-task-tracker-table-description-priority-date-data {
    display: flex;
    grid-gap: 18px;
}
.project-task-time-mapping-task-tracker-table-description-priority-root {
    margin-top: 1px;
}
.project-task-time-mapping-task-tracker-table-description-glass-icon {
    color: #666464d1;
    font-size: 10px;
}
.task-tracker-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}
.task-tracker-edit-menus #dropdown-basic {
    background-color: transparent;
    color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    right: 0px;
    /* margin-right: 1.5rem; */
}
.project-task-time-mapping-table-description {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}
.project-task-time-mapping-table-description span {
    cursor: pointer;
    color: rgba(24,24,24,.5215686274509804);
    font-size: 12px;
    letter-spacing: -.2px;
    line-height: 16px;
    margin-top: 3px;
    outline: none;
    width: 80%;
    border: none;
    resize: none;
    padding: 2px 4px;
    background-color: hsla(0,0%,75.3%,.27058823529411763);
    border-radius: 2px;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    text-transform: capitalize;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    font-size: 12px;
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
 .project-task-time-mapping-table-data-details td{
    border-top: 0.5px solid rgba(0, 0, 0, .08);
    border-bottom: 0.5px solid rgba(0, 0, 0, .08);
    border-right: none;
    border-left: none;
 }
 .project-task-time-mapping-table-data-details td:first-child{
    border-left: 0.5px solid rgba(0, 0, 0, .08);
 }
 .project-task-time-mapping-table-data-details td:last-child {
    border-right: 0.5px solid rgba(0, 0, 0, .08);
}
.project-task-time-mapping-task-tracker-table-description-priority-low {
    color: green !important;
}

.project-task-time-mapping-task-tracker-table-description-priority-medium {
    color: #eca90f !important;
}

.project-task-time-mapping-task-tracker-table-description-priority-high {
    color: #ed210e !important;
}
.project-task-time-mapping-task-tracker-table-description-priority-icon {
    color: grey;
    font-size: 11px;
    margin-top: -1px;
}
/* .project-task-time-mapping-formik-add-project-button .btn-primary{
    width: 220px !important;
}
.project-task-time-mapping-formik-add-project-button div{
    width: 350px !important;
} */
.project-task-time-mapping-formik-add-form {
    display: flex;
    justify-content: space-evenly;
}
.project-task-time-mapping-formik-add-form-text-box.form-group{
    margin-bottom: 0px !important;
}
.project-task-selection-list-name-content{
    font-size: 14px;
    color: #504f4f;
    display: -webkit-box;

}
.project-task-selection-list-task-content{
    font-size: 14px;
}
.error+.project-task-time-mapping-formik-add-form .rc-time-picker-input {
    border-color: #f0624d!important;
}
.edit-task-project-task-time-mapping-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-modal-edit-button-root{
    margin-top: 5px;
}
.edit-task-project-task-time-mapping-formik-add-form-start-time .rc-time-picker-input,.edit-task-project-task-time-mapping-formik-add-form-end-time .rc-time-picker-input{
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
.project-task-time-mapping-modal-edit-save-button {
    margin-right: 15px;
}
.project-task-time-mapping-modal-edit-save-button,.project-task-time-mapping-modal-edit-delete-button{
    font-size: 14px;
}

.project-task-tracket-all-task-label {
    margin-top: 2rem;
    color: gray;
}
.project-task-time-mapping-edit-time {
    display: flex;
}
.edit-task-project-task-time-mapping-formik-add-form-start-time .rc-time-picker-input, .edit-task-project-task-time-mapping-formik-add-form-end-time .rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 177px;
    font-size: 15px !important;
} 
.project-task-time-mapping-formik-add-form-edit.edit-task-project-task-time-mapping-formik-add-form-start-time {
    margin-right: 14px;
}
.project-task-time-mapping-edit-task-date {
    display: flex;
    flex-direction: column;
}
.project-task-time-mapping-edit-time-div {
    margin-bottom: 15px;
}
.project-task-time-mapping-modal-edit-body{
    padding: 19px;
}
.project-task-time-mapping-formik-add-form-edit .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-modal-edit-save-button-div{
    margin: 0 auto;
}
.project-task-time-mapping-project-formik-add-button {
    height: -moz-fit-content;
    height: fit-content;
}
.project-task-time-mapping-formik-add-form-start-time .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-clear,.project-task-time-mapping-formik-add-form-end-time-error .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-input {
    border: 1px solid #F0624D !important;
}
.project-task-time-mapping-formik-add-form-end-time-error .rc-time-picker-input {
    border: 1px solid #F0624D !important;
}
.project-task-time-mapping-formik-add-project-button-error .btn-primary {
    border: 1px solid red !important;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    font-size: 12px;
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
.project-task-time-mapping-task-tracker-table-description-details {
    font-size: 12px;
    /* padding-left: 6px; */
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
.project-task-time-mapping-task-tracker-table-description-details-root{
    width: 490px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project-task-time-mapping-task-list-start-end-time {
    width: 220px !important;
}
.project-task-time-mapping-task-list-tracking-status{
    background-color: #17a2b8;
    padding: 2px 6px 2px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
    margin-left: auto;
    margin-right: 0;
}
.project-task-time-mapping-task-list-untracking-status{
    background-color: #80808075;
    padding: 2px 6px 2px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
    margin-left: auto;
    margin-right: 0;
}
.project-task-time-mapping-formik-add-project-button .dropdown-menu{
    min-width: 22rem;
}
.edit-task-project-task-time-mapping-formik-add-project-button .dropdown-menu {
    min-width: 28.7rem;
}
.project-task-time-mapping-table-task-list-start-again-icon {
    font-size: 17px;
    margin-top: -3px;
    margin-right: 5px;
}
.project-task-time-mapping-table-task-list-stop-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #e92d2ddc;
    border-color: #e92d2ddc;
    font-size: 12px;
    font-weight: 600;
}
.project-task-time-mapping-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.project-task-time-mapping-project-formik-edit-save-button {
    margin-top: 17px;
    font-size: 13px !important;
    padding: 5px 9px;
}
.project-task-time-mapping-project-formik-edit-save-button-div{
    text-align: center;
}
.project-task-time-mapping-formik-edit-form-end-time .rc-time-picker-input{
    height: 2.2rem;
    font-size: 0.86rem;
}
.project-task-time-mapping-modal-edit-end-time-header-name.modal-title.h4 {
    font-size: 19px;
    color: black;
}
.project-task-time-mapping-formik-edit-form-end-time .rc-time-picker {
    width: 100%;
}
.project-task-time-mapping-formik-edit-form-end-time .project-task-time-mapping-formik-add-form-end-time .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-edit-end-form-end-time-error {
    font-size: 12px;
     color: red;
    width: 100%;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 2px;
}
.project-task-time-mapping-project-formik-add-button-div{
    margin-top: 27px;
}
.project-task-time-mapping-formik-add-form-label{
    color: #808080b3;
    margin-bottom: 5px;
    font-size: 13px;
}
.project-task-time-mapping-formik-edit-form-end-time {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}
.project-task-time-mapping-edit-end-time-div{
    width: 40%;
}
.project-task-time-mapping-formik-edit-form-edit-end-time {
    color: red;
    font-size: 13px;
    margin-top: -5px;
}
.project-task-time-mapping-formik-add-form-select-project-task-div{
    width: 340px;
}
.project-task-time-mapping-formik-add-project-overlay{
    font-weight: bold;
}
.task-overview-root {
    padding: 1.5rem 2rem 2rem 2rem;
}
.task-overview-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
}

.task-overview-grid-over-all-summary-card {
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 0.5rem;
}
.task-overview-grid-today-summary-card{
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 0.5rem;
}

.task-overview-grid-over-all-summary-card-head {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: #fff;
}
.task-overview-grid-over-all-summary-card-head-span {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 0 1rem 0;
}
.task-overview-grid-over-all-summary-card-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}
.task-overview-grid-over-all-summary-card-content-small-card {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    /* border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-image: initial; */
    display: flex;
    justify-content: space-between;
}
.task-overview-grid-over-all-summary-card-content-small-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.task-overview-grid-small-card-icon{
    display: flex;
    align-items: center;
}
.task-overview-grid-small-card-task-assigned-me{
    color: #1d53b7;
    background-color: #edf3fd;
}
.task-overview-grid-small-card-project-assigned-me{
    color: #12945e;
    background-color: #edfaf4;
}
.task-overview-grid-small-card-due-today{
    color: #dc3535;
    background-color: #fcefef;
}
.task-overview-grid-small-card-due-past{
    color: #c88e1b;
    background-color: #fff9ee;
}
.task-overview-grid-over-all-summary-card-content-combine{
    grid-row: 1/3;
    padding: 16px;
    border-radius: 5px;
    /* border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial; */
}
.task-overview-grid-over-all-summary-card-content-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    grid-template-rows: 176px;
}
.task-overview-grid-small-card-large-icon {
    font-size: 1.9rem;
    display: contents;
}
.task-overview-grid-today-summary-card-content-color{
    color: #34495ee0;
}
.task-overview-grid-today-summary-card-content-top{
    /* margin-top: 65px; */
    margin-bottom: 47px;
}
.task-overview-grid-today-summary-card-content-top-loader{
    /* margin-top: 65px; */
    margin-bottom: 55px;
    display: flex;
}
.task-overview-grid-today-summary-card-content-top h4{
    /* font-size: 2rem; */
}
.task-overview-grid-today-summary-card-content h4{
    /* font-size: 2rem; */
}
.task-overview-no-data-div {
    text-align: center;
    color: hsla(0,0%,50.2%,.6196078431372549);
    margin-top: 5%;
}
.task-overview-website-chart {
    margin-top: -10px;
}
.task-overview-load-more-button {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}
.task-overview-website-chart {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 13px;
}
.task-overview-website-chart-text-div-whole{
    justify-content: center;
    display: grid;
    margin-top: 1rem;
}
.task-overview-website-chart-text-divs {
    display: flex;
    align-items: center;
}
.task-overview-website-chart-text-div-letter {
    font-size: 16px;
}
.task-overview-website-chart-text-div {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    border: 1px solid rgba(66,58,58,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
}
.task-overview-website-chart-text-div-progress {
    background: #84a5e5;
}
.task-overview-website-chart-text-div-to-do{
    background: #81d1ac;
}
.task-overview-website-chart-text-div-completed{
    background: #e7bf78;
}
.task-overview-website-chart-text-div-hold{
    background: #e7ad9e;
}
.task-overview-website-chart-text-div-splits{
    display: flex;
    align-items:center;
}
.task-overview-website-chart-width{
    width: 132px;
}
.task-overview-detail-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 390px;
    overflow-y: scroll;
}
.task-overview-my-task-badge-primary{
  color: #007bffd1;
}
.task-overview-my-task-badge-secondary{
    color: #6c757dd9;
}
.task-overview-my-task-badge-hold{
    color: #6c757dbf;
}
.task-overview-my-task-badge-info{
    color: #17a2b8d9;
}

.task-overview-grid-three {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem; */
}
.task-overview-modal-body-div{
    padding: 2rem;
}
.task-overview-grid-task-list-per-employee-table-content-task-name{
    width: 345px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 600;
}
.task-overview-task-details-card-value-due-crossed{
    color: #ea4c4cb0 !important;
}
.task-overview-table-description-priority-medium {
    color: #eca90fd1 !important;
}
.task-overview-table-description-priority-high {
    color: #ed210ead !important;
}
.task-overview-table-description-priority-low {
    color: #008000cf !important;
}
.task-overview-grid-task-list-per-employee-table-content-project-name{
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 600;
}
.task-overview-grid-task-list-combine{
    grid-column: 1/3;
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}
.task-overview-grid-task-list-chart{
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.task-overview-grid-task-list-per-employee {
    height: 365px;
    overflow-y: scroll;
}
.task-overview-grid-task-list-per-employee .table-striped tbody tr:nth-of-type(odd) {
    background-color: #266dfb10!important;
    box-shadow:none;
}
.task-overview-grid-task-list-per-employee-header {
    height: 38px;
    min-width: 100%;
    overflow-x: hidden;
    background: 0 0;
    border-bottom: 0px solid #e8ebee;
    border-top: 0px solid #e8ebee;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 300px;
    max-width: 300px;
}
.task-overview-grid-task-list-per-employee table td, .task-overview-grid-task-list-per-employee table th {
    vertical-align: middle !important;
}
.task-overview-grid-task-list-per-employee-table-content {
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    color: #34495ee0;
    padding-top: 1px;
    border-left: 1px solid #e8ebee;
    border-top: 1px solid #e8ebee;
    border-bottom: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgb(53 59 72 / 5%);
}
.task-overview-grid-over-all-summary-card-head-span-drop{
    margin: 14px 0px 0px 0px;
}
#task-overview-grid-over-all-summary-card-head-span-drop-button{
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user {
    width: 155px;
    margin-top: 15px;
    margin-right: 0px;
}
.task-overview-my-task-filter-user .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-user .css-tlfecz-indicatorContainer{
   padding: 5px;
}

.task-overview-my-task-filter-project {
    width: 200px;
    margin-top: 15px;
    margin-right: 15px;
}
.task-overview-my-task-filter-project .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-project .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-project .css-tlfecz-indicatorContainer{
   padding: 5px;
}
.task-overview-my-task-filter-user-activity {
    width: 127px;
    margin-top: 12px;
    margin-right: 0px;
}
.task-overview-my-task-filter-user-activity .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user-activity .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-user-activity .css-tlfecz-indicatorContainer{
   padding: 5px;
}

@media only screen and (min-width: 1024px)  {
    
    .task-overview-grid-over-all-summary-card-head-span {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
}
@media only screen and (min-width : 1224px) and (max-width : 1400px) {
    
    .task-overview-grid-over-all-summary-card-head-span {
      font-size: 1.2rem;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0 1rem 0;
    }
    
}
.task-overview-team-my-view-toggle-toggle-details {
    margin-bottom: 1.5rem !important;
    width: 100%;
}
.task-overview-team-my-view-toggle-data{
    display: flex; 
   justify-content: center;
}
.tasks-overview-toggle-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
    margin-right: 5px;
}
.task-overview-team-my-view-toggle-toggle-team-view,.task-overview-team-my-view-toggle-toggle-my-view {
    font-size: 15px;
}
.task-overview-load-more-button .btn{
    font-size: 14px;
    padding: 4px 8px;
}


.task-overview-team-my-view-toggle-type {
    display: flex;
    justify-content: center;
}
.task-overview-team-my-view-toggle-div {
    margin-bottom: 1.5rem;
}
.task-overview-pill-button-input {
    display: none;
    visibility: hidden;
}
.task-overview-pill-button {
    display: inline-block;
    position: relative;
    padding: 2px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    background: #079058;
    border-radius: 28px;
    color: #fff;
    z-index: 0;
}
.task-overview-pill-button-selection_active {
    color: #079058;
}
.task-overview-pill-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.6em 1.2em;
    cursor: pointer;
    z-index: 2;
}
.task-overview-pill-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 37.25px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}
.holiday-import-root {
    margin: 2rem;
}
.holiday-import-date-picker {
    width: 200px;
    border: 1px solid #d3d3d3;
    padding: 7px;
    border-radius: 5px;
}
.holiday-import-root-header-section {
    display: flex;
    justify-content: space-between;
    /* padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706); */
}
.holiday-list-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin: 3rem 0rem;
}
.holiday-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-spacing: 0em 0.7em;
    
}
.holiday-list-table thead tr th {
    /* background-color: #f3f7fa!important; */
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
/* .holiday-list-content th,.holiday-list-content td {
    margin: 0;
     border-bottom: 1px solid #dee2e6 !important;
    border-right: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
} */
.holiday-list-content td {
    margin: 0;
    border-bottom: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
    border-top: 0px solid #dee2e6;
}
.holiday-list-table-tbody-tr td:first-child {
    border-left: 1px solid #dee2e6 !important;
}
.holiday-list-table-tbody-tr td:last-child {
    border-right: 1px solid #dee2e6 !important;   
}
.holiday-list-table thead tr th {
    padding: 0.1rem 0.7rem !important;
}
 .holiday-list-table tbody tr td {
    padding: 0.7rem !important;
}
.holiday-list-table tbody tr td {
    color: #34495e !important;
    font-size: 0.9rem;
}
.holiday-list-table tr:first-child td{
   width: 1%;
}
.holiday-import-root-header-filter-section{
    display: flex;
    grid-gap:10px;
    gap:10px;
}
.holiday-import-root-header-filter-type-select{
    width: 215px;
    /* margin-left: 25px; */
}

.holiday-list-import-old-data-text-div-add{
    margin: 30px auto 0px auto;
    font-size: 14px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 415px;
}
.holiday-list-import-old-data-text-div-add-import{
    margin: 30px auto 0px auto;
    font-size: 14px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 432px;
}
.holiday-list-import-old-data-text-div{
    margin: 14px auto 65px auto;
    font-size: 15px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 550px;
    text-align: center;
    background-color: #c3e7b7;
}
.holiday-list-import-old-data-text-span-yes{
    /* text-decoration: underline;
    color:#007bff;
    cursor: pointer; */
    margin-right: 25px;
    margin-top: 20px;
}
.holiday-list-import-old-data-text-span-no{
    margin-right: 25px;
    margin-top: 20px;
}
.holiday-list-import-old-data-text-span{
    text-decoration: underline;
    color:#007bff;
    cursor: pointer;
}
.holiday-list-import-old-data-text-span-icon{
    margin-bottom: 4px;
}



.holiday-import-add-modal-body-wrapper-form-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.9em + 0.75rem + 2px);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.add-holiday-import-modal-header-title {
    font-size: 18px;
}
.add-holiday-import-modal-body-wrapper-form-div-field-form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.add-holiday-import-modal-body-wrapper-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.add-holiday-import-modal-body-wrapper-form-div-group {
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 0rem;
    /* margin-right: 16px; */
    margin-bottom: 16px;
}

.add-holiday-import-modal-body-wrapper-form-div {
    margin-right: 0 !important;
}
.add-holiday-import-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
}
.add-holiday-import-footer-add-holiday-import-button,.add-holiday-import-footer-add-holiday-import-button button {
    text-align: center;
    margin: 8px 17px 3px 17px;
    font-size: 14px;
    font-weight: 600;
}
.add-holiday-import-modal-body {
    margin: 8px 16px;
}
.holiday-import-date-picker:focus-visible {
    outline: 2px gray;
  }
  .holiday-list-filter-upload-icon-btn {
    margin-right: 10px;
}
.holiday-list-table-tbody-tr{
    line-height: 18px;
    padding-top: 1px;
    margin-bottom: 10px;
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    background-color: white;
    /* font-weight: 600; */
    color: #34495e !important;
    font-size: 0.9rem;
}
.holiday-list-table thead th:first-child{
    width: 2%;
}
.holiday-list-table thead th:nth-child(2){
    width: 30%;
}
.holiday-list-table thead th:nth-child(3),.holiday-list-table thead th:nth-child(6),.holiday-list-table thead th:nth-child(7){
    width: 15%;
}
.holiday-list-table thead th:nth-child(4),.holiday-list-table thead th:nth-child(5){
    width: 10%;
}
.holiday-list-filter-download-icon-btn,.holiday-list-filter-upload-icon-btn {
    padding: 6px 19px;
    font-weight: 500;
}
.holiday-list-filter-download-icon-btn{
    margin-right: 10px;
}

.holiday-list-edit-profile-text-td {
    display: flex;
    font-size: 0.8rem;
}
.holiday-list-edit-profile-text-td-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.holiday-list-edit-profile-text-td-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 5px 11px 5px 11px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.holiday-list-edit-profile-text-td-edit-text {
    margin-bottom: 0;
}
.holiday-list-edit-profile-text-td-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.holiday-list-filter-upload-icon-import {
    margin: -6px 3px 0px 0px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .holiday-list-content {
        width: 100%;
        display: grid;
    }
    .holiday-import-root-header-section{
        display: inline;
    }
    .holiday-import-root-header-filter-section{
        display: inline;
    }
}
.add-holiday-import-modal-body-input-form-error {
    font-size: 12px;
}
.holiday-list-no-data-text{
    text-align: center !important;
    color: #808080a6;
}
.holiday-import-add-modal-body-wrapper-form-input::-moz-placeholder{
    color: gray;
}
.holiday-import-add-modal-body-wrapper-form-input::placeholder{
    color: gray;
}
.leave-settings-card {
    padding: 2rem;
}
.leave-settings-row > * {
    margin-bottom: 1rem;
}

.leave-settings-row-grid, .leave-settings-row-grid-1, .leave-settings-row-grid-2 {
    
    /* -moz-columns: 2 calc((100vw - 420px - 8rem) / 2);
    columns: 2 calc((100vw - 420px - 8rem) / 2); */
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.leave-settings-row-grid-card {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: calc((100vw - 417px - 7rem) /2);
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
    
}
.leave-settings-row-grid-card-list-div {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: 100%;
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.permission-row-grid-card-list-div {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: calc((100vw - 417px - 7rem) /2);
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.leave-setting-type-card{
    margin-right: 1rem;
}
.leave-setting-type-card-member{
    margin-right: 1rem;
}
.leave-setting-type-card-member-margin{
    margin-right: 0;
}
.leave-settings-row-grid-card h6 {
    color: black;
}
.leave-settings-worked-days-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 13px;
    margin: 15px;
}

.leave-settings-worked-days-data label {
    cursor: pointer;
}
.leave-settings-worked-days-data {
    width: 100%;
    /* height: calc(1.7em + 0.75rem + 2px); */
    padding: 0.55rem 0.55rem;
    /* font-size: 0.8rem; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 1rem;
    /* cursor: pointer; */
    /* text-align: center; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.leave-settings-worked-days-data-type-edit-icon {
    font-size: 14px;
    color: #ccc;
    margin-right: 8px;
    cursor: pointer;
}
.leave-settings-worked-days-data-type-delete-icon {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
} 
.leave-settings-worked-days-data-type-edit-icon .svg:focus {
    color: #007bff !important;
}
.leave-settings-worked-days-data-type-delete-icon:focus {
    color: #007bff !important;
} 
.leave-settings-worked-days-data-type-edit-icons {
    font-size: 14px;
    color: #ccc;
    margin-right: 6px;
    cursor: pointer;
}
.leave-settings-worked-days-data-type-edit-icons .svg:focus {
    color: #007bff !important;
}

.permissions-settings-worked-days-data-type-delete-icon {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 10px;
} 
.permissions-settings-worked-days-data-type-delete-icon:focus {
    color: #007bff !important;
} 

.leave-settings-filter-status-user-select {
    margin-bottom: 15px;
    font-size: 14px;
}
.leave-setting-filter-type-text{
    font-size: 12px;
    color: gray;
    /* margin-bottom: 20px; */
    margin-top:0px;
}
.leave-setting-filter-status-text{
    font-size: 12px; 
    color: #ff000082;
    margin-bottom: 14px;
    margin-top:-12px
}
.leave-status-err{
    color: #ff0000eb;
    font-size: 14px;
    text-align: center;
}
.leave-settings-worked-days-data label {
    cursor: pointer;
}
.leave-settings-row-leave-type-label {
    display: flex;
    justify-content: space-between;
}
.leave-settings-worked-days-list-form-group {
    margin-top: 14px;
}
.leave-settings-worked-days-list-form-group-type{
    max-height: calc(100vh - 251px);
    overflow-x: hidden;
    overflow-y: auto;
}
.leave-settings-worked-days-list-form-group-type-permission {
    max-height: calc(100vh - 251px);
    overflow-x: hidden;
    overflow-y: auto;
}
.leave-permission-worked-days-data-type-view-icon-span{
    color:gray;
    text-decoration: underline;
}
.leave-settings-worked-days-list-form-label {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    -webkit-text-decoration-color: #007bff;
            text-decoration-color: #007bff;
}
.leave-settings-worked-days-list-form-label-disable {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    -webkit-text-decoration-color: #007bff;
            text-decoration-color: #007bff;
}
.leave-settings-worked-days-list-form-label-archive {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    -webkit-text-decoration-color: #007bff;
            text-decoration-color: #007bff;
    margin-right: 20px;
}
.leave-settings-modal-button {
    margin: auto;
    padding: 9px 0;
}
.leave-settings-load-more-button {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}
.leave-type-setting-seen-all-data{
    margin: 0;
    font-size: 13px;
    color: #808080b8;
}
.leave-settings-modal-close-button, .leave-settings-modal-save-button {
    font-size: 0.9rem;
    padding: 0.4rem 1.3rem;
    margin-right: 1rem;
    font-weight: 500;
}
.leave-settings-modal-title.modal-title {
    font-size: 17px;
    text-align: center;
    width: 90%;
}
.leave-settings-edit-task-label-input {
    font-size: .86rem!important;
    font-weight: 600!important;
}
.leave-settings-edit-task-label-input::-moz-placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}
.leave-settings-edit-task-label-input::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}
.leave-settings-edit-task-label-name-label {
    /* font-size: .95rem;
    font-weight: 500;
    color: rgba(0,0,0,.6705882352941176); */
    margin-left:40px;

    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}

.leave-settings-edit-task-label-name {
    /* font-size: .95rem;
    font-weight: 500;
    color: rgba(0,0,0,.6705882352941176); */

    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.leave-settings-no-data-text {
    font-size: 14px;
    text-align: center;
    color: gray;
    margin: 15px;
}
.leave-settings-modal-update-settings {
    font-size: 0.86rem;
    padding: 0.3rem 0.7rem;
    margin-right: 1rem;
    font-weight: 500;
}
.leave-settings-modal-save-button-div {
    text-align: center;
    /* margin-bottom: 11px; */
}

.leave-setting-time-option-div {
    display: flex;
    /* width: 195px; */
}
.leave-type-edit-disable-button{
    font-size: .86rem!important;
    font-weight: 600!important;
    height: calc(1.7em + 0.75rem + 2px);
    background-color: #e9ecef;
    opacity: 1;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: not-allowed;
}
.leave-setting-time-option-div-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.35rem;
    font-size: 0.86rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.leave-setting-type-header-text{
    font-size: 13px;
    display: flex;
    margin-bottom: -5px;
}
.leave-setting-type-header-text-type{
    /* margin-right: 5px; */
    width: 38%;
    padding-left: 10px;
}
.leave-width-allowed{
    width: 20%;
}
.leave-width-cycle{
    width: 15%;
}
.leave-width-reset{
    width: 15%;
}
.leave-type-err {
    font-size: 14px;
    margin-top: 2px;
}
.leave-width-option{
    width: 12%;
    display: flex;
    justify-content: space-around;
}
.leave-settings-worked-days-data-type {
    margin-right: 5px;
    width: 38%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-setting-row-color{
    color: #000;
}

.leave-type-row{
    display: flex;
    justify-content: center;
}
.leave-setting-mute-text{
    color: #7788998c;
}

.leave-setting-modal-full-div .modal-dialog {
    max-width: 450px;
}
.leave-setting-modal-content-row.row {
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
}
.leave-setting-permission-modal-full-div .modal-dialog {
    max-width: 410px;
}
.short-leave-error {
    color: red;
    font-size: 13px;
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}
.leave-setting-span-help-text {
    font-size: 12px;
    color: gray;
    margin: 5px 0px 0px 5px;
}
.leave-type-settingts-checkbox label{
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
}
.leave-type-settingts-checkbox input {
    margin-right: 5px;
}
.leave-setting-type-wrapper-div {
    border: 1px solid #d3d3d3;
    padding: 17px 0px 3px 0;
    border-radius: 6px;
    background-color: hsla(0,0%,50.2%,.050980392156862744);
    margin: 0 40px 8px 40px;
}

.leave-settings-filter-status-user-select-wrapper {
    display: grid;
    grid-template-columns: 1fr 70px;
    grid-gap: 1rem;
}
.leave-settings-worked-days-data-type-archive-icon {
    margin-right: 7px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}
.leave-setting-filter-type-root-tab {
    background-color: transparent;
}
.leave-setting-filter-type-root-tab .nav-link.active,.leave-setting-filter-type-root-tab .nav-link {
    background-color: transparent;
    font-size: 13px;
}
.leave-settings-worked-days-data-type-unarchived {
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    color: gray;
}
.leave-setting-filter-year-type-text {
    font-size: 12px;
    color: gray;
    margin-bottom: 20px;
    margin-top: 0px;
}
.leave-settings-worked-year-list-form-group {
    /* margin-top: 14px; */
}
.leave-setting-filter-type-root-tab .nav-link.active, .leave-setting-filter-type-root-tab .nav-link {
    background-color: transparent;
    font-size: 13px;
  
}
.leave-setting-filter-type-root-tab .nav-link.active {
    border-bottom: 1px solid #007bff !important;
}



.leave-permission-modal-content-name {
    /* display: flex; */
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}
.leave-permission-label-input {
    font-size: 0.9rem !important;
}
.leave-setting-flef-row {
    /* display: flex; */
    justify-content: space-between;
}
.leave-permission-add-time-option-div {
    display: flex;
    width: 285px;
}
.leave-permission-total-allowed-request{
    width: 285px;
}
.leave-permission-per-name{
    width: 285px;
}
.leave-permission-width-option {
    /* width: 12%; */
    display: flex;
    /* justify-content: space-between; */
}
.leave-permission-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 4px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.leave-permission-label-input::-moz-placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}
.leave-permission-label-input::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}

.leave-permission-modal-radion-button > input[type="radio"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
}

.leave-permission-modal-radion-button > label {
  font-size: 0.9rem;
  cursor: pointer;
  color: #495057;
}

.leave-permission-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
}
.leave-permission-card-table thead th {
    border: none;
    font-weight: 500;
}
.leave-permission-card-table th,
.leave-permission-card-table td {
    border: none;
}
.leave-permission-card-table-header {
    color: rgb(74, 85, 104);
    border: none;
    font-size: 13px;
}
.leave-permission-card-table-data-details {
    padding: 0.7rem ;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    font-size: 14px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-bottom: 15px;
}
.edit-div-permission{
    cursor: pointer;
}
.task-overview-detail-modal-body-permission.modal-body {
    margin: 0;
    padding: 0;
    height: 245px;
    overflow-y: scroll;
}
.task-overview-detail-modal-body-archived-permission.modal-body {
    margin: 0;
    padding: 0;
    height: 245px;
    /* overflow-y: scroll; */
}
.scheduled-reports-card-table-header-permission {
    color: rgba(83,92,104,.6392156862745098);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
}
.permission-whole-div {
    display: flex;
    /* margin-bottom: 1rem; */
    justify-content: space-between;
}
.permission-card-table-data-details-report-name {
    width: 90% !important;
}
.permission-card-table-data-details-report-type {
    width: 35% !important;
    text-align: right;
}
.leave-permission-worked-days-data-type-view-icon{
    margin-right: 7px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}
.real-time-notification-modal-body-form-div-wrapper {
    display: flex;
    margin-top: 11px;
    /* justify-content: space-between; */
}
.real-time-condition-section-who-to-monitor{
    display: flex;
    justify-content: space-between;
}
.real-time-notification-modal .modal-dialog{
    max-width: 45%;
} 
 .real-time-notification-modal-body {
    margin: 13px;
}

.real-time-notification-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(0,0,0,.6588235294117647);
}
.real-time-notification-modal-body .form-label, .rule-sub-div-heading {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.real-time-notification-modal-body-form-start-time .rc-time-picker-input {
    height: 2.4rem;
    font-size: 0.9rem;
}
.real-time-condition-section-who-to-monitor-value .form-check-input{
cursor: pointer;
}
.real-time-condition-section-who-to-monitor-value .form-check-label {
    cursor: pointer;
    color: #495057;
    font-size: 0.9rem !important;
}
.real-time-notification-modal-body-form-div-type{
    margin-right: 10px;
}
.real-time-notification-modal-body .form-label.real-time-notification-err {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}
.real-time-notification-modal-body-form-control{
    font-size: 0.9rem !important;
}
.real-time-notification-modal-body-form-start-time .rc-time-picker-clear-icon{
    display: none;
}



.real-time-notification-root {
    margin: 2rem;
}
.real-time-notification-root-rule-button {
    display: flex;
    justify-content: space-between;
}
.real-time-notification-root-card-data {
    /* display: grid; */
    grid-template-columns: 1fr;
    /* margin-top: 2rem; */
    font-size: 0.9rem;
    font-weight: 400;
    color: #6c757d !important;
}
.real-time-notification-root-card-content {
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.real-time-notification-root-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.real-time-notification-root-card-table-header {
    color: #868686;
    border: none;
}
.real-time-notification-root-card-table thead th {
    border: none;
}
.real-time-notification-card-table-body {
    color: #333;
}
.real-time-notification-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
tr.real-time-notification-card-table-data-details td {
    vertical-align: middle;
}
.real-time-card-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.real-time-notification-card-table-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.real-time-notification-card-table-data-edit-text {
    margin-bottom: 0;
}
.real-time-card-table-data-action-wrapper{
    display: flex;
    justify-content: space-around;
}
.real-time-notification-root-card-table td, .real-time-notification-root-card-table th, .real-time-notification-root-card-table thead th {
    border: none;
}
.real-time-notification-card-table-loader{
    text-align: center;
}
.real-time-notification-modal-body-form-condition-text {
    font-size: 12.4px;
    color: gray;
    font-weight: 400;
    margin-bottom: 1rem;
}
.real-time-notification-modal-body-condition-form-group.form-group {
    margin-bottom: 3px;
}
.real-time-notification-modal-body .form-label.error {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}
.real-time-notification-modal-body .real-time-notification-modal-body-form-control.is-invalid div {
    border-color: #dc3545 !important;
}
.real-time-notification-coming-soon {
    text-align: center;
    margin-top: 42px;
    font-size: 19px;
    color: #007bff !important;
}
.real-time-notification-modal-body-condition-form-group .rc-time-picker-clear {
    display: none !important;
}

.real-time-notification-alert-time-duration-text {
    margin: 1rem;
    font-size: 15px;
    color: #ff0000b0;
}
.real-time-notification-alert-time-duration-text a{
    text-decoration: underline;
    cursor: pointer !important;
    color: blue;
}
.real-time-notification-alert-time-duration-text a:hover{
    text-decoration: underline;
    cursor: pointer !important;
    color: blue;
}


.manage-permission-td-date {
    width: 15%;
}
.manage-permission-td-duration {
    width: 25%;
    /* color: #007bff; */
}
.late-clock-in-content-table-tbody-td-late-by-data{
    background-color:#f0a3a394
}
.monthly-in-out-table thead > tr th, .monthly-in-out-table tbody > tr td:not(:first-child) {
    text-align: center;
    vertical-align: middle;
}
.late-clock-in-content-table-tbody-td-absent {
    color: #8080808a !important;
    font-size: 13px !important;
}
.late-clock-in-content-table-tbody-td-late-clockin-column{
    background-color:#f0a3a394
}
.late-clock-in-content-table-tbody-td-late-clockin-column-no-count {
    color: #8080808a !important;
}
.over-time-report-tbody-tr-td-absent{
    color: #8080808a !important;
    font-size: 13px !important;
}
.overtime-report-date-picker .react-datepicker__tab-loop .react-datepicker-popper{
    z-index: 999 !important;
}
.overtime-report-thead {
    position: sticky;
    top: 0px;
    z-index: 42;
}
.over-time-report-tbody-tr-td-over-time-data{
    background-color:#f0a3a394
}
.live-stats-page-root {
    margin: 2rem;
    background-color: #fff;
}
.live-stats-page-filter-root{
    display: flex;
    justify-content: center;
}
.live-stats-page-filter-dropdown{
    width: 280px;
    margin-bottom: 0;
    margin-right: 30px;
}
.live-stats-page-content-root {
    margin-top: 3rem;
    height: 400px;
    background-color: #8080801a;
}
.live-stats-page-content-filter-label{
    padding: 2rem 2rem 1rem 2rem;
    text-align: center;
    font-weight: 600;
}
.live-stats-page-filter-date-div{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.live-stats-page-date-filter-dropdown{
    width: 200px;
    margin-bottom: 0;
    /* margin-right: 30px; */
}.live-stats-page-content-filter-label
.live-stats-page-date-filter-text {
    margin: 8px 13px 0px 0px;
}
.live-stats-page-dashboard-card-root{
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem; */
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    margin-top: 3rem;
}
.live-stats-page-dashboard-card {
    padding: 15px 27px;
    background: #fff;
    /* border-radius: 5px; */
    border-width: 0.5px;
    border-style: solid;
    /* border-color: rgba(0, 0, 0, 0.08); */
    -o-border-image: initial;
    border-image: initial;
    /* width: 179px; */
    text-align: center;
    border-radius: 5px;
    border: 0.5px solid rgb(0 0 0 / 18%);
}
.live-stats-page-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
    margin-bottom: 10px;
}
.live-stats-page-dashboard-card-date-text {
    text-align: center;
    color: gray;
    font-size: 15px;
}
.live-stats-page-dashboard-card-root-2 {
    margin-bottom: 2rem !important;
    margin-top: 1rem !important;
}
.live-status-password-modal-body {
    padding: 24px 33px;
}
.live-status-password-modal-body-password {
    width: 100%;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.live-status-password-modal{
    background-color: rgb(27, 26, 26);
}
.user-details-page-root {
    margin: 2rem;
    background-color: #fff;
}
.user-details-page-wrapper-search-bar {
    width: 32% !important;
}

.user-details-page-wrapper-search-bar-div {
    display: flex;
    justify-content: center;
}
.user-details-page-wrapper-submit{
    width: 20%;
}
.user-details-page-wrapper-submit-button{
    padding: 7px 22px;
    margin-left: 14px;
}
.user-stats-page-dashboard-card-mail-div h4 {
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 7px !important;
}
.user-stats-page-content-root {
    margin-top: 3rem;
    height: 600px;
    background-color: #8080801a;
}
.user-stats-page-content-filter-label {
    padding: 2rem 2rem 1rem 2rem;
    text-align: center;
    font-weight: 600;
}
.user-stats-page-dashboard-card-root {
    display: flex;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    margin-top: 3rem;
}
.user-stats-page-dashboard-card {
    padding: 15px 27px;
    background: #fff;
    /* border-radius: 5px; */
    border-width: 0.5px;
    border-style: solid;
    /* border-color: rgba(0, 0, 0, 0.08); */
    -o-border-image: initial;
    border-image: initial;
    /* width: 179px; */
    text-align: center;
    border-radius: 5px;
    border: 0.5px solid rgb(0 0 0 / 18%);
}
.user-stats-page-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
    margin-bottom: 10px;
}
.user-stats-page-dashboard-card-mail-div {
    height: 120px !important;
    overflow-y: scroll;
}
.user-stats-page-dashboard-card-no-data{
    text-align: center;
}
.workfolio-affiliates-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}
.workfolio-affiliates-header {
    background-color: #0096FF;
    color: white;
    text-align: center;
    padding: 2rem 1rem;
}
.workfolio-affiliates-header img {
    width: 300px;
    margin-top: 1rem;
    border-radius: 50%;
}
.workfolio-affiliates-header h1 {
    margin: 0;
    font-size: 2.5rem;
}
.workfolio-affiliates-header p {
    margin: 0.5rem 0 0 0;
}
.workfolio-affiliates-content {
    padding: 2rem;
}
.workfolio-affiliates-section {
    margin-bottom: 2rem;
}
.workfolio-affiliates-section h2 {
    color: #0096FF;
    border-bottom: 2px solid #0096FF;
    padding-bottom: 0.5rem;
}
.workfolio-affiliates-section p {
    margin: 0.5rem 0;
    line-height: 1.6;
}
.workfolio-affiliates-list-item {
    margin: 1rem 0;
}
.workfolio-affiliates-apply-button {
    display: block;
    width: 200px;
    margin: 2rem auto;
    padding: 0.6rem 1rem !important;
    background-color: #0096FF;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
}
.workfolio-affiliates-queries {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e0e0e0;
}
.workfolio-affiliates-queries p {
    margin: 0.5rem 0;
}
.workfolio-affiliates-img-responsive {
    display: block;
    max-width: 65px;
    height: auto;
    /* margin-top: 33px !important; */
}
.workfolio-affiliates-img-center {
    margin: 0 auto;
}
.license-count-page-root {
    margin: 2rem;
    /* background-color: #fff; */
}
.license-count-page-wrapper-div {
    max-width: 780px;
    margin: 0 auto;
    padding: 30px;
}
.license-count-page-wrapper-div h5{
    text-align: center;
    margin-bottom: 3rem;
}
.license-count-page-wrapper-div-form-group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}
.license-count-page-wrapper-label-name {
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
    margin-right: 10px;
    margin-bottom: 1px;
}
.license-count-page-wrapper-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    width:350px !important;
}
.license-count-page-wrapper-div-whole{
    display: flex;
    justify-content: center;
    align-items: center;
}
.license-count-page-wrapper-div-whole-button {
    /* margin: auto; */
    padding: 9px 0;
    margin-bottom: 1rem;
}

.license-count-page-root-main-content {
    max-width: 780px;
    margin: 0 auto;
    padding: 30px;
    /* text-align: center; */
    font-weight: 500;
    /* border: 1px solid black; */
    border-radius: 6px;
    box-shadow: 0 0 0 .5px rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .06);
    font-size: .9rem;
    /* font-weight: 400; */
    background-color: #fff;
}
.license-count-page-root-main-content-license-data {
    margin-top: 1rem;
    /* text-align: left; */
}
.license-count-page-root-main-content-email-list-index {
    margin-right: 11px;
}
.license-count-page-wrapper-div-whole-button{
    text-align: center;
}
.license-count-page-wrapper-div-input-or-text{
    text-align: center;
    margin-bottom: 1rem;
}
.license-count-page-root-main-content-details-count {
    display: flex;
    justify-content: space-between;
}
.license-count-page-root-main-content-count-header {
    margin-right: 8px;
    font-weight: 600;
}
.workfolio-maintenance-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    margin: 160px auto;
    max-width: 750px;
}
.workfolio-maintenance-header {
    /* background-color: #0096FF; */
    color: #1e1e1e;
    text-align: center;
    padding: 2rem 1rem 0rem 1rem;

    display: flex;
    align-items: center;
}
.workfolio-maintenance-img-responsive {
    display: block;
    max-width: 50px;
    height: 50px;
}
.workfolio-maintenance-img-center {
    /* margin: 0 auto; */
    margin-right: 8px;
}
.workfolio-maintenance-body-content{
    margin-left: 16px;
    margin-right: 16px;
}
.workfolio-maintenance-body-p{
    font-weight:600;
}
.workfolio-maintenance-body-content-button {

    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #6c757d;
    border-color: #6c757d;

    margin-right:50px;
}
.workfolio-maintenance-body-content-button-1 {

    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #6c757d;
    border-color: #6c757d;
}
.workfolio-maintenance-body-content-button-div {
    margin: 37px auto;
    max-width: 340px;
    margin-bottom: 0px;
}

.workfolio-maintenance-header-time {
    font-size: 1rem;
    color: #28a745;
    text-align: center;
    margin-bottom: 1rem;
}
.workfolio-maintenance-header-h2{
    margin-bottom: 0;
}
.workfolio-maintenance-body-content-success-p{
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
}
.workfolio-maintenance-body-content-button-div-success {
    margin: 37px auto;
    max-width: 235px;
    margin-bottom: 0px;
}
.workfolio-maintenance-body-content-button-1-success{
    color: #035fcb;
    text-decoration: underline;
    margin-left: 5px;
}
.workfolio-maintenance-footer{
    text-align: center;
    margin-top: 0.5rem;
}
.summary-report-details-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.summary-report-details-body {
    margin: 2rem 0 2rem 0;
}
.summary-report-details-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
}
.summary-report-details-table thead tr th {
    background-color: #f3f7fa !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, .5803921568627451);
    font-size: .9rem;
}

.summary-report-details-table tbody tr td, .summary-report-details-table thead tr th {
    padding: .7rem !important;
}
.summary-report-details-table tbody tr td {
    color: rgba(0, 0, 0, .8392156862745098) !important;
    font-size: .9rem;
}
.summary-report-details-table thead tr th {
    margin: 0;
    padding: .5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.summary-report-detail-report-type {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}

.summary-report-details-content-report-type {
    margin-right: 20px;
}
.summary-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    /* border: 1px solid #ededed; */
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    /* max-width: -moz-max-content; */
    width: 250px;
    height: 39px;
    font-size: .9rem;
    /* max-width: max-content; */
}
label.summary-report-details-label {
    font-size: .9rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}
.summary-report-filter-report-type-select,.summary-report-details-filter-team-select{
    font-size: 0.9rem;
}
.summary-report-details-filter-team-select {
    width: 100%;
}
.summary-report-page-content-wrapper-menu-filter-date-text {
    /* font-weight: 500; */
    font-size: 0.9rem;
    margin: 0;
}
.summary-report-details-content-date-range-text{
    color: rgb(75 85 99 / var(--tw-text-opacity));
    font-size: 0.9rem;
}
.summary-report-details-content-export-button {
    float: right  !important;
    margin-bottom: 15px !important;
}
.summary-report-details-content-export-button button{
    font-size: 0.9rem !important;
}
.summary-report-details-content.summary-report-details-content-organisation {
    justify-content: flex-start  !important; 
}
.summary-report-table-avatar-profile {
    display: flex;
}
.summary-report-details-body-all-data-text {
    text-align: center;
    margin: 26px 0px 2rem 0px;
    color: #bdbbbb !important;
}
.summary-report-details-content-export-wrapper{
    margin: 40px 0px 0px 0px !important;
    /* display: flex; */
    /* justify-content: space-between; */
}
