/* Project Details Card */
.project-details-root {
  max-width: calc(100vw - 210px);
  overflow-x: auto;
  padding: 2rem;
}

.project-details-summary {
  /* display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 30px; */

  display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.project-details-card {
  /* padding: 1.5rem;
  text-align: center;
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3; */

  background: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.project-details-card > .title {
  /* font-size: 14px; */
  font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 175px;
}
.project-details-total-worked-hours-title{
  width: 190px !important;
}
.project-details-card > .text {
  margin: 0.1rem 0 0;
  font-weight: 500;
}

.project-details-chart-wrapper {
  margin: 1rem 0;
}
.project-details-chart {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.project-details-task-card-wrapper {
  /* margin: 1rem 0; */
}
.project-details-task-card {
  border: 1px solid #d3d3d3;;
  border-radius: 5px;
  background-color: #fff;
}
.project-details-task-card-heading{
  font-size: 17px;
}
.project-detail-badge{
  padding: 5px !important;
}
.project-details-card-estimat-hours-span{
  color: gray;
    /* text-decoration: underline; */
    cursor: pointer;
    /* padding-top: 30px; */
    text-align: center;
    font-size: 0.97rem;
}
.budget-na-text{
  opacity: 0.5;
}
.project-details-task-card-header {
  padding: 0.8rem 1.25rem;
  border-bottom: 1px solid #00000014;
}
.project-details-card-estimate-cardtext{
  color:gray;
}

.project-details-new-task-button {
  font-size: 0.7rem;
  font-weight: 600;
}
.project-details-task-card-details {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, calc(41% - 10rem));
  /* grid-template-rows: minmax(150px, 1fr); */
  grid-template-rows: 100%;
  padding: 0 20px;
  overflow-x: scroll;
  height: 26.5rem;
}
@media (min-width: 320px) {
  .project-details-task-card-details {
    grid-template-columns: 100%;
  }
}
@media (min-width: 481px) {
  .project-details-task-card-details {
    grid-template-columns: 100%;
  }
}
@media (min-width: 641px) {
  .project-details-task-card-details {
    grid-template-columns: repeat(4, calc(41% - 10rem));
  }
}

.project-details-task-card-status {
  background-color: #80808021;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  position: relative;
}
.project-details-task-card-title {
  font-weight: 700;
  color: #0000008c;
  font-size: 0.67rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}
.project-details-task-card-data {
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 12px;
  background-color: white;
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;;
  cursor: pointer;
}

.project-details-task-card-menu {
  float: right;
  font-size: 0.8rem;
  color: gray;
}
span.project-details-task-card-task-name {
  font-size: 0.7rem !important;
  font-weight: 600;
}
.project-details-task-card-task-date {
  font-size: 0.57rem;
  background-color: #e6b608;
  padding: 0.3rem;
  border-radius: 5px;
  font-weight: 600;
  margin-right: 1rem;
}
.project-details-task-card-task-time {
  display: inline-block;
}
.project-details-task-card-calender-icon {
  margin-top: -0.2rem;
}
span.project-details-task-card-task-worked-time {
  font-size: 0.6rem;
  margin-right: 1rem;
  font-weight: 700;
  color: #00000091;
}
span.project-details-task-card-task-arrow-up {
  padding: 0.1rem;
  background-color: rgb(241 122 52 / 30%);
  border-radius: 5px;
  font-size: 0.7rem;
}
.project-details-task-card-task-arrow-up-icon {
  margin-top: -0.2rem;
  color: rgb(218 93 19 / 74%);
}
.project-details-task-card-timer-icon {
  margin-top: -0.1rem;
}
.project-details-task-card-avatar {
  height: 25px;
  width: 25px;
  background-color: #ededed;
  color: #424242;
  font-weight: bold;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 25px;
}
.project-details-task-card-list {
  display: grid;
  grid-gap: 0.5rem;
  height: 19rem;
  overflow-y: scroll;
}

.project-details-user-worked-hours-wrapper {
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: calc(100vw - 210px - 60px);
  /* margin: 30px auto; */
  /* margin: 30px; */
}

.project-details-user-worked-hours-wrapper > *:not(:first-child) {
  margin-left: 30px;
}

.project-details-user-worked-hours-wrapper > .user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  color: #111111a8;
  border: 1px solid #d3d3d3;
  justify-content: center;
  font-size: 1.3rem;
  padding: 1rem;
  cursor: default;
}

.project-details-user-worked-hours-wrapper > .user-card .avatar {
  
  width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 18px;
    margin-right: 0.75rem;
    color: #333333c4;
    background-color: #e3e3e3;
    font-weight: 600;
}

.project-details-user-worked-hours {
  white-space: nowrap;
  font-size: 15px;
}

.project-details-chart-project-progress .progress {
  border-radius: 5px;
  height: 49%;
}
.project-details-chart-title {
  padding: 15px 15px 0;
  display: flex;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
}
.project-details-chart-project-name {
  font-weight: bold;
}
.project-details-chart-project-worked-hours {
  text-align: right;
}
.project-details-chart-project-progress .progress .bg-success {
  background-color: #28a74585 !important;
}
.project-details-chart-loader {
  width: 100;
  height: 300px;
  text-align: center;
  vertical-align: middle;
}
.project-details-user-name {
  font-size: 15px;
    max-width: 12ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-details-user-details {
  flex: 1;
  font-weight: 700;
}
.project-details-task-card-edit-menu #dropdown-basic {
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  margin-right: -0.5rem;
}
svg.project-details-task-card-edit-menu-icon {
  color: #00000094;
  float: right;
  font-size: 0.8rem;
  margin-top: -16%;
}
.project-details-task-card-edit-menu .dropdown-menu {
  font-size: 0.7rem;
}
.project-details-task-card-delete-task {
  color: red;
}
.project-details-task-card-edit-menu .dropdown-menu .dropdown-item {
  padding: 0 1.5rem;
}

/* Edit Modal CSS */
.project-details-task-card-edit-task-body.modal-body .react-datepicker-wrapper {
  display: block;
}
.project-details-task-card-edit-task-title {
  color: #000000b3;
  font-size: 1.1rem;
  font-weight: 700;
}
.project-details-task-card-edit-task-header .close {
  font-size: 1.3rem;
  margin-top: -1.3rem;
  font-weight: 400;
}
.project-details-edit-task-label-name.opacity-0 {
  opacity: 0;
}
.project-details-edit-task-label-name {
  font-size: 0.93rem;
  font-weight: 500;
  color: #000000ab;
}

.project-details-edit-task-label-due-date {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.project-details-edit-task-label-input {
  /* font-size: 0.84rem !important;
  font-weight: 600 !important;
  color: #495057d1; */
}
.project-details-edit-task-label-input.search-input {
  background-color: #ededed;
}
.project-details-edit-task-label-input.search-input:focus {
  background-color: #fff;
}
.project-details-edit-task-assing-all-button {
  display: inline-block;
  font-weight: 700;
  color: #6879eff2;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1.3px solid #6879eff2;
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  margin-top: 26%;
}
.project-details-edit-task-assing-all-button:hover {
  background-color: #bbc1e82b;
}
.project-details-edit-task-label-employee {
  float: left;
}
.project-details-edit-task-label-select-all {
  float: right;
  color: blue;
  cursor: pointer;
  font-weight: 600;
}
.project-details-edit-task-employee-list {
  padding: 0.5rem;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.project-details-edit-task-employee-list:hover {
  background-color: #007bff10;
}
.project-details-task-card-user-name {
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-details-task-card-cancel-button,
.project-details-task-card-save-button {
  font-size: 0.89rem;
  font-weight: 700;
}
.project-details-edit-task-employee-content,
.project-details-new-task-employee-content {
  width: 100%;
  height: 10rem;
  overflow-y: scroll;
}

/* Delete Task */

.project-details-task-card-delete-cancel-button,
.project-details-task-card-delete-button {
  font-size: 0.9rem;
}
.project-details-task-card-delete-body.modal-body {
  font-size: 0.96rem;
  font-weight: 500;
}
.project-details-task-card-delete-title.modal-title.h4 {
  color: #000000b3;
  font-size: 1rem;
  width: 100%;
}
.project-details-task-card-delete-task-name {
  font-weight: 700;
}

svg.project-details-task-card-delete-danger-icon {
  color: red;
  margin-top: -1%;
  margin-right: 2%;
  font-size: 0.8rem;
  /* padding: 0.4rem; */
}
.project-details-task-board {
  margin: 2rem;
  border: 1px solid #00000021;
}
.project-details-task-board-view {
  background-color: transparent !important;
}
.project-details-task-card-body .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: visible;
  display: block;
}
.project-details-task-card-body .react-trello-board {
  max-width: calc(100vw - 210px - 60px);
  background-color: #fff;
}
.project-details-task-card-body .react-trello-board .react-trello-card {
  transition: all 100ms ease-in-out;
}
.project-details-task-card-body .react-trello-board .react-trello-card:hover {
  background-color: #fbfbfb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.project-details-task-board-view .react-trello-lane {
  width: 18rem;
  font-size: 10px;
  background-color: rgba(128, 128, 128, 0.13);
  border-radius: 5px;
  position: relative;
}

.project-details-task-board-view .react-trello-lane header span {
  font-weight: 700;
  color: #0000008c;
  font-size: 0.67rem;
  margin-bottom: 1rem;
}

.project-details-task-board-view .smooth-dnd-container > *:last-child {
  margin-bottom: 2rem;
}

.project-details-task-card-body .react-trello-board .smooth-dnd-container.vertical {
  padding-bottom: 45px;
}

.project-details-task-card-add-new-task {
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #e3e3e3;
  padding: 10px;
  transition: all 100ms ease-in-out;
}

.project-details-task-card-add-new-task:hover {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}
.project-details-task-edit-wrapper{
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.project-details-task-edit{
  position: static!important;
}
.project-name-header{
  grid-column: 1/3;
  display: flex;
  align-items: center;
}
.project-name-header-avatar{
  display: inline-block;
  text-align: center;
  color: #333;
}
.project-name-icon{
  /* display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 70px;
  background: rgba(239,120,14,.5490196078431373);
  border: 1px solid rgba(0,0,0,.1);
  font-size: 23px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  margin-right: 13px;
  font-weight: bolder; */
  display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 70px;
    background: rgba(239,120,14,.5490196078431373);
    border: 1px solid rgba(0,0,0,.1);
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    color: #fff;
    margin-right: 13px;
}
.project-detail-tasks-modal-content-header-setting {
  font-size: 0.95rem;
    font-weight: 500;
  color: #000000ab;
}
.project-name-value{
  font-size: 22px;
    font-weight: 700;
    color: #333;

  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-name-edit-value{
  color: gray;
  text-decoration: underline;
  cursor:pointer;
}
.project-name-edit-disable-value {
  color: gray;
  text-decoration: underline;
  cursor: not-allowed;
}
.project-details-task-name{
  width:200px !important;
}
.project-details-task-card-avatar-profile {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: hidden;
  margin-right: 0.25rem;
  border-radius: 50%;
}
.project-details-task-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-worked-hours-avatar-profile {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  margin-right: 0.75rem;
  border-radius: 50%;
}
.project-details-worked-hours-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-task-card-body-filter-section{
  display: flex;
  padding: 1rem;
  border: 1px solid lightgray;
  /* margin: 0rem 1rem 0rem 1rem; */
  margin: 1rem;
  border-radius: 0.25rem;
}
.project-details-task-card-body-filter-section-buttions{
  margin-right: 20px;
  display: flex;
  justify-content: end;
}
.project-details-task-card-body-filter-section-taskDescription {
  margin-bottom: 0 !important;
  width: 283px;
}
.project-details-task-card-body-filter-section-search {
  margin-bottom: 0 !important;
  width: 43%;
  display: flex;
  gap: 3px;
}
.project-detail-task-data-td-task-name{
  width: 50%;
}
.edit-setting-card-table-data-mark-as-completed {
  width: 5%;
  cursor: pointer;
}
.edit-setting-card-table-data-whole-edit {
  width: 4%;
  cursor: pointer;
}
.project-task-tracker-undo-edit-menus-hide{
  
}
.project-detail-task-data-td-task-name-in-Progress{
  width: 45% !important;
}
.project-detail-edit-setting-card-table-data-mark-as-complete {
    width: 20%;
    cursor: pointer;
}
.project-details-task-card-body-table-data-details-hours{
  /* color: #28a745; */
  max-width: 15ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
}
.project-details-task-card-body-filter-section-buttions-filter{
  display: flex;
}
.project-details-task-card-body-filter-section-add-form {
  margin-bottom: 0 !important;
  margin-right: 15px;
}
.project-details-task-card-body-filter-section-buttions-add-button{
  /* margin-left: 15px; */
  font-weight: 500;
  padding: 4px 8px;
    font-size: 14px;
    margin-right: -26px;
}
.project-task-tracker-edit-menus #dropdown-basic{
  top:-17px !important;
}
/* .project-task-tracker-edit-menus-progess #dropdown-basic{
  top:-9px !important;
} */
.project-task-tracker-edit-menus-in-progess #dropdown-basic{
  top:-9px !important;
}

.project-chart-loader-head-div {
  height: 400px !important;
}

.project-details-task-card-body-filter-no-data-div {
  text-align: center;
    margin-top: 135px;
    height: 200px;
}
.project-details-task-card-body-filter-no-data-div-other {
  text-align: center;
  margin-top: 120px;
  color: #8080809e;
}
.project-details-task-card-body-table{
  padding: 1rem;
  max-height: 1315px;
  overflow-y: auto;
}
.project-details-task-card-body-table-member{
  padding: 1rem;
  height: 535px;
  overflow-y: auto;
}
.project-details-task-card-body-table-new{
  /* height: 535px; */
  /* max-height: 1315px;
  overflow-y: auto; */
  padding-top: 0rem;
}
.project-details-task-card-body-table-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
}
.project-details-user-work-body-table-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  padding-top: 0;
}
.employee-worked-name-td-div{
  display: flex;
  align-items: center;
}
.employee-worked-name-td-div p{
  /* width: 90%; */
  cursor:pointer;
  margin: 0;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    color: #333;
}
.employee-worked-name-td{
  width: 38%;
}
.employee-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.employee-card-avatar {
  display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background: #e3e3e3;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
    color: #333333;
    text-transform: uppercase;
}
.employee-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-details-task-card-body-table-header {
  color: gray;
  border: none;
  font-size: 15px;
}
.emplyees-data-no{
  text-align: center;
}
.project-details-user-work-body-table-header {
  color: gray;
  border: none;
  font-size: 15px;
}
.project-details-user-whole-div {
  height: 315px;
  overflow-y: auto;
}
/* .project-details-task-card-body-table-header tr th {
  font-weight: 100;
} */
.project-details-task-card-body-table-body {
  color: #333;
}
.project-details-user-work-body-table-body {
  color: #333;
}
.project-details-task-card-body-table-data-details {
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
  
}
.project-details-user-work-body-table-data-details {
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
  
}
.project-details-home-project-card-table-display-name {
  /* width: 50%; */
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-home-project-card-table-display-name-completed {
  /* width: 50%; */
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-task-card-body-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-user-work-body-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-task-card-body-table-card-table thead th {
  border: none;
  font-weight: 400;
  color: grey;
  font-size: 15px;
}
.project-details-user-work-body-table-card-table thead th {
  border: none;
  font-weight: 400;
  color: grey;
  font-size: 15px;
}
.project-details-user-work-body-table-card-table th,
.project-details-user-work-body-table-card-table td {
  border: none;
  vertical-align: middle;
  padding: .6rem;
}

.project-details-task-card-body-table-card-table th,
.project-details-task-card-body-table-card-table td {
  border: none;
  vertical-align: middle;
  padding: .6rem;
}
.project-details-task-card-edit-table-data-button {
  color: #1c4dbfc9;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
.project-details-task-card-edit-table-data-icon:hover {
  text-decoration: underline;
}
td.project-details-task-card-edit-table-data-icon {
  width: 15%;
}
.project-details-task-card-body-filter-section-search-input{
  margin-right: 2px;
}
.project-details-task-card-edit-table-data-status{
  color: #1c4dbfc9;
    font-weight: 500;
    padding: 0.25rem 1.5rem;
    cursor: default;
}
.project-details-task-card-body-filter-section-taskDescription-search{
  width:206px !important;
}

.project-name-edit-menu {
  display: flex;
  gap: 25px;
}
.project-details-task-card-save-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  margin-bottom: 1rem;
}

.project-details-offline-task-card {
  max-height: 22rem;
  min-height: 10rem;
  padding: 0 1rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.project-details-offline-task-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.project-details-offline-task-card-table-header {
  color: rgba(121, 119, 119, .8588235294117647);
  font-size: 15px;
}
.project-details-offline-task-card-table-body {
  color: #333;
}
.project-details-offline-task-card-table-data-details {
  /* padding: 1rem 0;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: -1px 4px 4px -6px #333333; */
  cursor: pointer;
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: -1px 4px 3px -6px #333;
  background-color: #f8f9fa !important;
}
.project-details-offline-task-card-table td{
  padding: 0.6rem !important;
  color: #000000c9;
}
.project-details-offline-task-card-table-data-details:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.project-details-offline-task-card-table th, .project-details-offline-task-card-table td {
  border: none;
  vertical-align: middle;
  padding: 0.5rem;
}
.project-details-offline-task-card-table-description {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-offline-task-card-table-name {
  font-size: 13px;
  color: gray;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-offline-review-status{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details-offline-task-card-table-header tr th{
  border-bottom: none;
}
.project-details-offline-task-card-body {
  height: 535px;
}
.project-details-calender-icon-div{
  display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}
.project-details-calender-icon{
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 15px;
  margin: 0 5px;
  opacity: 0.8;
  font-size: smaller;
}
.project-details-calender-text{
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
.project-details-chart-title-date {
  margin-left: auto;
    margin-right: 0;
}
.project-details-home-project-card-root-list-section-body {
  margin: 1rem 1rem 0 1rem;
  height: 535px;
  overflow-y: auto;
}
.project-details-table-card-table th, .project-details-table-card-table td {
  padding: 0.6rem !important;
}
.project-detail-loader-tab{
  height: 535px;
}
.project-detail-whole-div-for-exports{
  display: flex;
}
.project-detail-filter-download-icon-btn:hover {
  color: #fff !important;
  /* cursor: not-allowed !important; */
}
.disable-timesheet-project-detail {
  padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #007bff;
    border-radius: .25rem;
    border: 1px solid #007bff;
}

.my-projects-ondue-selected{
  color:red !important;
}
.project-detail-cursor{
  cursor: pointer;
}
.project-detail-cursor:hover {
  text-decoration: underline;
}
/* .task-filter-add-new-task-button-tab{
  margin-top: -6px;
}
.task-tracker-header-tab a:nth-last-child(-n+1){
  width: 25%;
  text-align: right;
} */
.desktop-home-project-card-task-tracker-table-description-glass-icon {
  color: #666464d1;
  font-size: 10px;
}

.task-priority-edit-whole-div-data {
  display: flex;
}
.task-edit-add-modal-priority-div {
  width: 100%;
}
.task-edit-modal-content-person-name-header-section {
  flex: 1 1;
  /* display: flex; */
  justify-content: space-between;
}
.project-detail-desktop-home-project-card-table-description {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 4px;
}

.project-detail-desktop-home-project-card-table-description span{
  width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  margin-top: 4px;
}
.desktop-home-project-card-table-description-priority{
  font-size: 12px;
  background-color: #e3e3e7;
  padding: 3px 10px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  color: gray;
}
.project-bugject-h4{
  font-size: 18px !important;
}
.project-details-budject-span-text{
  text-decoration: underline;
}
.desktop-home-project-card-table-description-priority-root {
  margin-top: 3px;
  margin-bottom: 3px;
}
.desktop-home-project-card-table-description-priority-date-data{
  display: flex;
  grid-gap: 10px;
  margin-top: 2px;
}
.desktop-home-project-card-table-description-priority-low {
  background-color: #57c11542;
}
.desktop-home-project-card-table-description-priority-medium {
  background-color: #e7e7a2;
}
.desktop-home-project-card-table-description-priority-high {
  background-color: #f1baba;
  color: #302f2f;
}
.desktop-home-project-card-table-description-priority {
  text-transform:capitalize;
}
.project-details-task-card-body-table-data-details-ongoing{
  background-color: #dbfbe7 !important;
}


.project-task-tracker-header-tab {
  font-size: 16px;
  color: #00000087;
  font-weight: 500;
  
  flex-wrap: initial;
}
.project-task-tracker-header-tab-whole{
  /* width: 95%; */
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: initial;
}
.project-nav-left {
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  background-color: #007bff;

  float: left;
  position: relative;
  left: 8px;
  top: 6px;
  font-size: 20px;
  cursor: pointer;
  z-index: 55;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.project-nav-left-div{
  /* margin-left:40px; */
}
.project-nav-right {
  
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  font-weight: 700;
  border-radius: 50%;
  color: white;
  background-color: #007bff;
  float: right;
  position: relative;
  right: 10px;
  top: 7px;
  font-size: 20px;
  cursor: pointer;
  z-index: 55;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}

/* .project-detail-badge-ongoing {
  background-color: #8a811b7d;
} */
.project-tooltip-priority,.project-tooltip-start-date{
  width: fit-content;
}
.project-tooltip-start-tooltip{
  width: fit-content !important;
}
.desktop-home-project-card-table-ongoing-status {
  font-size: 14px;
}

.project-task-edit-modals-root .modal-dialog .modal-content {
  padding: 0 16px;
  border-radius: 12px;
}
.project-task-edit-modals-root-title.modal-title.h4 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  width: 90%;
  flex-shrink: 0;
}

.project-details-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 150px;
  flex-grow: 1;
  border: 1px solid #d3d3d3;
  padding: 10px;
}

.projects-tasks-modal-content-person-name-header-button {
  font-size: .86rem;
    margin-bottom: 0;
    color: grey;
    cursor: pointer;
    margin-top: 6px;
    text-decoration: underline;
}
.projects-tasks-modal-content-person-name-header-button:hover {
  text-decoration: underline;
}
.my-projects-modal-content-name-field{
  margin-right:0 !important;
}
.my-projects-modal-content-person-name-header-section-label-edit{
  font-size: 14px !important;
}
.project-details-card-footer-add-estimation-time-input{
  display: table-cell;
  vertical-align: middle;
}
.project-details-card-footer-add-estimation-time-input-type {
  font-size: 13px;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.project-details-card-footer-add-estimation-time-input-label{
  padding: 8px;
  font-size: 15px;
}
.project-details-card-estimated-time-root{
  display: table;
}
.project-details-card-footer-add-estimation-time-button {
  width: 19%;
  padding: 1px;
  margin-left: 4px;
}
.project-details-card-footer-add-estimation-time-button-icon{
  margin-top:-4px;
}
.project-details-card-footer-add-estimation-time-button-loader{
  font-size: 1rem;
  color: white;
  animation: spin 0.5s linear infinite;
}
.my-projects-edit-modal-body{
  padding:2rem !important;
}
.project-details-task-card-body-filter-section-buttions-add-button-root{
    font-weight: bold;
    padding: 8px;
    font-size: 14px;
}
.project-details-button-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.project-details-button-section-wrapper{
  margin-right: 15px;
}
.project-details-import-task-modal{
  margin-bottom: 1.5rem;
}
.project-details-import-task-modal-example-div{
  margin-bottom: 1.5rem;
  
}
.task-import-filter-download-icon {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: -3px;
}
.task-import-download-icon-btn {
  cursor:pointer;
  color: blue;
  font-size: 0.9rem;
}
.tasks-import-modal-p{
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: gray;
}
.tasks-import-modal-p-export{
  font-size: 0.9rem;
  color: #8c94a1;
  margin-bottom: 0px;
}
.import-tasks-modal-error{
  color:red;
  font-size: 0.8rem;
  margin-bottom: 0.7rem;
  text-align: center;
}
.project-details-import-task-modal .custom-file-label{
  font-size: 0.9rem;
}
.import-tsk-modal-alert-text{
  padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #007bff17;
}
.tasks-import-modal-p-label{
  font-size: 0.9rem;
  font-weight: 600;
    color: rgba(0,0,0,.6705882352941176);
}

.project-detail-task-data-td-task-name-upcomingTasks {
  width: 645px !important;
}
.project-detail-desktop-home-project-card-table-description-upcomingTasks {
  width: 598px !important;
}
.project-detail-task-data-td-task-name-in-Progress {
  width: 515px !important;
}
.project-detail-desktop-home-project-card-table-description-inProgressTasks {
  width: 473px  !important;
}
.project-detail-task-data-td-task-name-onDue {
  width: 480px  !important;
}
.project-detail-desktop-home-project-card-table-description-onDue {
  width: 423px  !important;
}
.project-detail-task-data-td-task-name-completed {
  width: 519px  !important;
}
.project-detail-desktop-home-project-card-table-description-completed {
  width: 452px !important;
}
.project-detail-task-data-td-task-name-hold {
  width: 513px  !important;
}
.project-detail-desktop-home-project-card-table-description-hold {
  width: 491px  !important;
}
.project-detail-desktop-home-project-card-table-description-all-task-td {
  width: 318px !important;
}
.project-detail-desktop-home-project-card-table-description-all-task {
  width: 395px !important;
}
.project-details-button-section-root-toggle{
  display: flex;
  align-items: center;
}


.quick-edid-mode-tasks-rule-uparrow {
  font-size: 14px;
    margin-left: 5px;
    color: #62839b;
    margin-top: 6px;
    margin-right: 5px;
}

.toggle-switch label {
  cursor: pointer;
  margin-bottom: 0 !important;
}

.toggle-switch label .toggle-track {
  display: block;
  height: 20px;
  width: 35px;
  background: #e1e4e8;
  border-radius: 20px;
  position: relative;
  /* margin-bottom:15px; */
  border: 1px solid #ccc;
  transition: .4s;
}

.toggle-track-nav-right-div {
  background: #7dd46e !important;
}

.toggle-track-nav-right-div:before {
  transform: translateX(15px) !important;
}

.toggle-switch .toggle-track:before {
  content: '';
  display: inline-block;
  height: 17px;
  width: 17px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transition: right .2s ease-in;
  left: 0px;
  bottom: 2px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
  transition: .4s;
}
.quick-edid-mode-tasks-rule-uparrow-text{
  margin-right: 15px;
}

.quick-edit-project-details-td{
  display: flex;
  align-items: center;
}
.quick-edit-project-details-input-div{
  margin-right: 10px;
  margin-top: -21px;
}

.quick-edit-task-buttons-root{
  display: flex;
  align-items: center;
  background-color: #266dfb2b;
  margin-top: 1rem;
  padding: 11px 11px 1px 11px;
  border-radius: 5px;
}
.quick-edit-task-buttons-root-specify-button{
  margin-right: 8px;
  font-weight: 500;
  border: 1px solid #EDEDED;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 9px;
  color:#266dfbd6;
}
.project-details-quick-edit-selected{
  background-color: #266dfb10 !important;
}
.quick-edit-task-buttons-root-count{
  color: #266dfbd6;
    margin-top: -8px;
    margin-right: 8px;
}

.quick-edit-time-div {
  display: flex;
}
.quick-edit-time-div-modal-save-button {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
}

.quick-project-task-due-date {
  margin-bottom: 1.5rem;
}
.quick-project-task-section-whole-input-div {
  /* margin-bottom: 2.5rem; */
}
.quick-delete-text{
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.quick-task-details-due-date-date-picker {
  display: block;
  width: 435px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.quick-project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  /* box-shadow: inset 0 -1px 0 0 #edeef2; */
  text-transform: uppercase;
  width: 300px;
}
.quick-edit-tooltip-button{
  border-radius: 50rem;
  padding: 0.25rem 0.5rem;
padding-left: 1rem;
padding-right: 1rem;
  }
  .quick-edit-check-box-root {
  margin-top: -13px;
}
.project-details-all-task-label{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  color: #bdbbbb;
}
.screenshot-load-more-button {
  color: #bdbbbb !important;
}
.project-details-task-details-card-description-card h4{
  font-size: 16px!important;
  line-height: 22px;
  color: #000000d1!important;
  font-weight: 100 !important;
  margin-top: 8px!important;
}
.my-projects-modal-searchbox-wrapper div .form-control::placeholder { 
  color: rgb(199, 194, 194);
}
.my-projects-modal-searchbox-wrapper div .form-control { 
  font-size: 0.9rem;
}
.my-projects-modal-content-person-name-header-section-label-edit{
  margin-bottom: 0px !important;
}
.project-name-value-member{
  font-size: 25px !important;
}
.project-name-card-wrapper {
  display: flex;
}
.project-name-card-details-avatar-circle {
  height: 30px;
  width: 30px;
  font-weight: bold;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
  margin-top: 5px;
}