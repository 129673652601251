.task-details-root{
  padding: 2rem;
}
/* Task Details Card */
.task-details-summary {
  display: grid;
  /* grid-template-columns: repeat(4,1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 2rem; */
  grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.task-details-summary-name-header{
  grid-column: 1/3;
  display: flex;
  align-items: center;
}
.task-details-summary-name-header-avatar {
  display: inline-block;
  text-align: center;
  color: #333;
}
.task-details-summary-name-icon {
  display: inline-block;
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background: rgba(239,120,14,.5490196078431373);
  border: 1pxsolid rgba(0,0,0,.1);
  font-size: 40px;
  line-height: 69px;
  text-align: center;
  color: #fff;
  margin-right: 13px;
}
.task-details-summary-name-value {
  font-size: 25px;
  font-weight: 700;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-details-summary-name-edit-value {
  color: gray;
  text-decoration: underline;
  cursor: pointer;
}
.task-details-card {
  /* max-width: 255px;
  width: 100%;
   */
  /* padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3; */
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.06); */
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.task-details-card-title {
  font-size: .9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task-details-card-title-productivity {
  color: #007bff;
}
@media (min-width: 1400px) {
  .task-details-summary {
    max-width: none;
    flex: 1;
    margin: 0 2rem 2rem 2rem 2rem;
  }
  .task-details-summary.no-data {
    font-size: 2.5rem;
  }
}

.task-details-employee-list-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  /* margin-left: 2rem; */
  background-color: white;
}

.task-details-employee-list-card-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-employee-list-card-export-button {
  font-size: 0.9rem;
  font-weight: 600;
  float: right;
  margin-top: -5px;
}
.task-details-employee-list-card-title {
  font-weight: 700;
  color: #0000008c;
  font-size: 1rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}
.task-details-employee-list-card-assign-employee-button {
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: 0;
}
.task-details-employee-list-card-body {
  padding: 0 1.3rem 1rem;
}
.task-details-employee-list-card-searchbox-wrapper {
  position: relative;
}
.task-details-employee-list-card-searchbox:focus {
  border-color: #0747a699;
  box-shadow: 0 0 0 0.2rem #0747a699;
}
.task-details-employee-list-card-searchbox::placeholder {
  color: #cccccc;
}

.task-details-employee-list-card-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.task-details-employee-list-card-filter {
  display: flex;
}
.task-details-employee-list-card-searchbox {
  float: right;
  font-size: 0.9rem;
}


/* Time On Task card  */

.task-details-time-on-task-card {
  margin: 2rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 30rem;
}

.task-details-time-on-task-card-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-time-on-task-card-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-time-on-task-card-body {
  padding: 1.3rem 1rem;
}

/* Screenshot Card  */

.task-details-screenshots-card {
  margin: 2rem;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 25rem;
}

.task-details-screenshots-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-screenshots-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-screenshots-body {
  padding: 1.3rem 1rem;
}
.task-details-screenshots-see-all-button {
  float: right;
  font-size: 0.85rem;
  font-weight: 700;
  margin-top: 0.26rem;
}

.screenshots-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 1rem;
}

.screenshots-list-img {
  border-radius: 5px;
  object-fit: cover;
  max-width: 100%;
  cursor: pointer;
}
.screenshot-display-name {
  text-align: center;
  font-size: 0.6rem;
  margin-top: 0.4rem;
}

.task-details-top-apps-and-websites-row {
  margin: 2rem;
  display: grid;
  grid-template-columns: 1.9fr 2fr;
  grid-gap: 2rem;
}
.task-details-top-apps-and-websites-list-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 26rem;
}
.task-details-top-apps-and-websites-chart-card {
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #00000014;
  height: 26rem;
}
.task-details-top-apps-and-websites-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-top-apps-and-websites-body {
  padding: 1.3rem 1rem;
}
.task-details-top-apps-and-websites-see-all-button {
  float: right;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 0.2rem;
}
.task-details-top-apps-and-websites-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}
.task-details-top-apps-and-websites-data {
  display: flex;
  justify-content: space-between;
  margin: 0.6rem 0;
}
.task-details-top-apps-and-websites-name {
  width: 80%;
  background-color: #8080801a;
  border-radius: 5px;
  padding: 0.1rem;
  font-size: 0.7rem;
  font-weight: 500;
  border: 1px solid #80808017;
}
.task-details-top-apps-and-websites-spending-hours {
  font-size: 0.8rem;
  vertical-align: middle !important;
  margin-top: 1%;
}
.task-details-top-apps-and-websites-icon {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem;
}
.task-details-top-apps-and-websites-details {
  overflow-y: scroll;
}

.task-details-employee-list-card-data {
  display: grid;
  grid-template-columns: 1fr;
  /* margin-top: 1rem; */
  font-size: 0.87rem;
  font-weight: 500;
  color: #000000b5;
}
.task-details-employee-list-card-data-header {
  color: #797777db;
  border: none;
  font-size: 14px;
}
.task-details-employee-list-card-data-table{
  border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.task-details-employee-list-card-data-table thead th {
  border: none;
}
.task-details-employee-list-card-data-table th,
.task-details-employee-list-card-data-table td {
  border: none;
  
  /* vertical-align: middle; */
}

.task-details-employee-list-card-details {
  margin-top: 1rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  font-size: 15px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
}
.task-details-employee-list-card-details p{
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}

.task-details-data {
  /* margin: 2rem; */
  margin-bottom: 2rem;
}
.task-details-data-task-back-button {
  font-size: 1.5rem;
  color: #007bff;
  margin-right: 1rem;
  cursor: pointer;
}
.task-details-data-task-back-button:hover{
  color: #266dfb;
}
.task-details-data-task-name {
  font-weight: bold;
  margin-right: 0.66rem;
  font-size: 21px !important;
}
.task-details-data-edit-task-button{
  margin-right:1%;
}

.task-details-data-edit-task-button, .task-details-data-delete-task-button{
  font-size: 0.9rem;
  font-weight: 600;
  padding: 2px 26px;
}
.task-details-data-task-details-content {
  /* display: flex; */
  grid-column: 1fr;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 28px;
  font-size: 0.86rem;
}
span.task-details-data-task-priority {
  color: rgb(218 93 19 / 74%);
}
span.task-details-data-task-created-at {
  /* margin-top: -1rem; */
  color: #000000ab;
}
.task-details-data-task-due-date {
  /* margin-top: -1rem; */
  color: #007bff;
}

span.task-details-data-task-created-at-data,
.task-details-data-task-due-date-data {
  font-weight: 600;
}
svg.task-details-data-task-created-at-icon {
  font-size: rem;
  margin-right: 0.2rem;
  font-size: 18px;
  margin-top: -2px;
}
.task-details-data-task-due-date-icon {
  font-size: rem;
  margin-right: 0.2rem;
  /* font-size: 18px; */
  margin-top: -2px;
}
.task-details-employee-list-card-details-avatar {
  display: flex;
}

.task-details-top-apps-and-websites-chart-header {
  padding: 0.8rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
  border-bottom: 1px solid #00000014;
}

.task-details-top-apps-and-websites-chart-title {
  font-weight: 900;
  color: #0000008c;
  font-size: 0.9rem;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}

.task-details-top-apps-and-websites-chart-body {
  /* padding: 1.3rem 1rem; */
  position: relative;
}
/* .task-card-chart-data-edit-menu-dropdown {
  top:-1px !important;
} */

.task-card-chart-data-edit-menu #dropdown-basic {
  top: -1px !important;
}
.employee-no-data{
  color: rgb(90 89 89);
  font-size: 1rem;
}
.task-time-no-data{
  margin-top: 53px;
  text-align: center;
}

/* ASSIGN EMPLOYEE MODAL */
.task-details-assign-employee-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -16px;
}
.task-details-assign-employee-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.task-details-assign-employee-modal-content-person-name-header-section {
  flex: 1 1;
}
.task-details-assign-employee-modal-content-person-name-header-section-content {
  display: flex;
}
.task-details-assign-employee-modal-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  margin-right: 16px;
}
.task-details-assign-employee-modal-searchbox {
  float: right;
  font-size: 0.9rem;
  background-color: #f5f3f6;
}
.task-details-assign-employee-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.task-details-assign-employee-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #007bff;
  color: #007bff;
  font-size: 0.9rem;
  /* transition: background .4s; */
}
.task-details-card-title-text{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-details-assign-employee-modal-content-person-name-listing {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: -16px; */
}
.task-details-assign-employee-modal-content-person-name-listing-card-content {
  margin-bottom: 10px;
  display: flex;
}
.task-details-assign-employee-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
}
.task-details-assign-employee-modal-content-person-name-listing-card-content-person-icon {
  height: 32px;
  width: 32px;
  line-height: 32px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #28a745;
  text-transform: uppercase;
}
.task-details-assign-employee-modal-content-header-team-name {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 14px;
  color: #62839b;
  cursor: pointer;
}
.access-control-checkbox {
  width: 0.9rem;
  height: 0.9rem;
  vertical-align: middle;
  cursor: pointer;
}
.task-details-assign-employee-modal-button-rows {
  margin-top: 40px;
  margin-bottom: 40px;
}
.projects-create-col {
  text-align: center !important;
}
.task-details-assign-employee-modal-button {
  margin: auto;
  padding: 9px 0;
}
.task-details-assign-employee-modal-close-button, .task-details-assign-employee-modal-save-button {
  font-size: 0.9rem;
  padding: 0.3rem 1.3rem;
  margin-right: 1rem;
  font-weight: 500;
}

.task-detail-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.task-detail-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.task-assign-card-avatar-profile {
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.task-assign-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.task-details-summary-name-edit-value-div{
  display: flex;
  gap: 25px;
}
.task-details-summary-project-name-value {
  font-weight: bold;
  color: #313231a8;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-details-card-task-created-at {
  margin-left: 6px;
  color: gray;
  font-size: 15px;
}