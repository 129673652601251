.desktop-home-card {
    margin: 2rem;
    /* max-width: 500px; */
    /* height: calc(100vh - 20%); */
    /* background-color: white; */
}

.desktop-home-project-card-root {
    /* padding: 15px; */
    /* border: 1px solid #d3d3d3;
    border-radius: 3px; */
}

.desktop-home-project-card-root-add-section {
    padding: 20px 5px;
    background: #fff;
    /* border-radius: 5px; */
    border: .5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.edit-task-tracker-time-dropdown {
    margin-top: 2rem;
}

.edit-task-tracker-date-picker {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    /* width: 200px; */
    /* margin-top: 1rem; */
}

.workfolio-edit-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}

.edit-task-whole-div {
    display: flex;
    margin-top: 2rem;
}

.task-edit-home-project-add-card-formik {
    overflow-y: auto;
    overflow-x: hidden;
    /* height: calc(100vh - 280px); */
    padding: 1rem;
}

svg.workfolio-edit-pages-calender-icon {
    font-size: 16px;
    text-align: center;
    color: #87898b;
    margin-top: 3px;
    cursor: pointer;
}

.edit-setting-card-table-data-icon-space {
    display: none;
}

.edit-setting-card-table-data-icon {
    width: 4%;
    cursor: pointer;
}

.desktop-home-project-add-card-formik {
    display: flex;
    justify-content: space-around;
    gap: 1%;
}

.desktop-home-project-add-card-formik-timer {
    display: flex;
    justify-content: space-around;
    gap: 1%;
}

.workfolio-manual-pages-calender-div {
    display: flex;
}

.workfolio-manual-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 7px;
    margin-left: 5px;
    cursor: pointer;
}

.workfolio-manual-pages-toggle-div {
    display: grid;
    color: #868686;
    margin-top: 3px;
}

.desktop-home-project-add-card-formik-comman {
    cursor: pointer;
}

.workfolio-manual-pages-calender-icon-div {
    display: flex;
}

.desktop-home-project-add-card-formik .desktop-home-project-add-card-formik-description {
    margin-bottom: 0rem !important;
    width: 30%;
}

.desktop-home-project-formik-add-form {
    margin-bottom: 0 !important;
}

.desktop-home-project-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}

.error+.desktop-home-project-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.desktop-home-project-formik-add-form .rc-time-picker-clear {
    display: none !important;
}

.edit-task-tracker-date-picker-date {
    margin-left: 1rem;
}

.desktop-home-project-formik-add-form-width input.rc-time-picker-input {
    /* width: 200px !important; */
}

.desktop-home-project-formik-add-form-edit {
    margin-bottom: 0 !important;
}

.desktop-home-project-formik-add-form-edit input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    /* width: 220px; */
    font-size: 15px !important;
}

.error+.desktop-home-project-formik-add-form-edit .rc-time-picker-input {
    border-color: #F0624D !important;
}

.desktop-home-project-formik-add-form-edit .rc-time-picker-clear {
    display: none !important;
}

.rc-time-picker-panel {
    /* width: 140px; */
}

.desktop-home-project-add-card-formik-timer .form-group {
    margin-bottom: 0rem !important;
    width: 70%;
}

.desktop-home-project-add-card-formik .form-control {
    font-size: 15px !important;
}

.desktop-home-project-add-card-formik-timer .form-control {
    font-size: 15px !important;
    /* border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c5c5c5; */
}

.desktop-home-project-add-card-formik-description .form-control {
    font-size: 18px !important;
    /* border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #c5c5c5; */
    /* background-color: #f3f3f3; */
    /* padding: 40px;
    padding-top: 10px; */
}

input.desktop-text-input.form-control {
    font-size: 18px !important;
}

.desktop-home-project-formik-add-project-button {
    font-size: 15px !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:hover {
    color: #007bff !important;
    background-color: #f0efff !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:active {
    color: #007bff !important;
    background-color: #f0efff !important;
    border-color: #007bff !important;
    box-shadow: none !important;
}

.desktop-home-project-formik-add-project-button .btn-primary:focus {
    color: #007bff !important;
    background-color: #f0efff !important;
    border-color: #007bff !important;
    box-shadow: none !important;
}

.desktop-home-project-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desktop-home-project-formik-add-project-button-secondary .btn-primary {
    border: 1px solid #cacaca !important;
    color: #545454 !important;
    background-color: #fff !important;
    width: 185px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: none; */
    /* outline: none; */
    /* box-shadow: none; */
    text-align: left;
}

.desktop-home-project-formik-add-project-button-edit .btn-primary {
    /* width: 220px !important; */
    /* margin-top: 1rem; */
    text-align: left;
}

.desktop-home-project-formik-add-project-button .dropdown-toggle::after {
    /* display: none; */
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.desktop-home-project-formik-add-project-button .dropdown-menu.show {
    padding: 0 !important;
    width: 100%;
}

.desktop-home-project-formik-add-project-button-secondary .dropdown-toggle::after {
    /* display: none; */
    float: right;
    margin-top: 10px;
}

.desktop-home-project-formik-add-project-button-error .btn-primary {
    border: 1px solid red !important;
}

.desktop-home-project-formik-add-button {
    font-size: 15px !important;
}
.task-tracker-worktimeline-tracked{
    background-color: #17a2b8;
    padding: 2px 6px 2px;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
}
.task-tracker-worktimeline-untracked{
    background-color: #80808075;
    padding: 2px 6px 2px;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
}
.desktop-home-project-card-root-list-section-body-worktimeline {
    /* height: 340px;
    overflow-y: scroll; */
    margin: 0rem 0rem 0 0rem;
    min-height: 100vh;
}
.desktop-home-project-card-root-list-section-body {
    /* height: 340px;
    overflow-y: scroll; */
    margin: 2rem 1rem 0 1rem;
    min-height: 100vh;
}

.task-tracker-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}

.desktop-home-project-card-root-list-section {
    margin-top: 1rem;
    border: 0.5px solid rgba(0, 0, 0, .08);
    /* padding: 1rem; */
    background: #fff;
}

.task-tracker-date-td {
    padding-bottom: 0px !important;
}

.desktop-home-project-card-root-list-section-heading {
    color: darkgray;
    margin-bottom: 0;
    font-size: 17px;
}


/* .desktop-home-project-card-root-list-section-header {
    display: flex;
    align-items: center;
} */

.desktop-home-project-list-section-button {
    margin-left: auto;
    margin-right: 0;
    /* border: 1px solid #007bff;
    color:#007bff; */
    font-size: 15px;
}

.desktop-home-project-list-section-button:active {
    background-color: none;
}


/* .desktop-home-project-list-section-button:hover {
    box-shadow: 2px 2px 6px #6c757d1c;
    background-color: #6c757d1c;
    color: #6c757d;
} */

.task-tracker-export-header {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    margin-top: 35px;
    margin-bottom: -70px;
}
.task-tracker-filter-download-icon-btn{
    width: fit-content;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    margin-top: 35px;
    margin-bottom: -73px;
    align-items: center;
}

.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
    /* margin-bottom: 0.9rem; */
    /* margin-top: 0.5rem; */
}

.desktop-home-project-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    /* table-layout: fixed;
    width: 100%; */
}

.desktop-home-project-card-table-description {
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;

    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    /* cursor: pointer; */
    margin-top: 4px;
}
.task-tracker-data-td-descrption-head{
width:170px !important;
}
.desktop-home-project-card-table-description span {
    cursor: pointer;
    color: #18181885;
    font-size: 12px;
    letter-spacing: -.2px;
    line-height: 16px;
    margin-top: 3px;
    outline: none;
    width: 80%;
    border: none;
    resize: none;
    padding: 2px 4px;
    background-color: #c0c0c045;
    border-radius: 2px;
}

.desktop-home-project-card-table-description span:hover {
    text-decoration: underline;
}

.desktop-home-project-card-table thead th {
    border: none;
}

.desktop-home-project-card-table th,
.desktop-home-project-card-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem 0.7rem;
}

.desktop-home-project-card-table-header {
    color: gray;
    border: none;
    font-size: 15px;
}

.desktop-home-project-add-card-formik-timer-selected {
    color: #007bff;
}

.desktop-home-project-card-table-body {
    color: #333;
}

.desktop-home-project-card-table-data-details {
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 15px;
    /* box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important; */
    box-shadow: -1px 4px 3px -6px #333;
    background-color: #fff!important;
}

.desktop-home-project-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.desktop-home-project-card-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.desktop-home-project-card-table-name span {
    cursor: pointer;
}


/* 
.desktop-home-project-card-table-name span:hover {
    text-decoration: underline;
} */

.desktop-home-project-card-table-display-name {
    width: 15ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.desktop-home-project-card-table-ongoing {
    font-weight: 600;
    color: darkgreen;
}

.desktop-home-project-card-table-ongoing-others {
    color: darkgreen;
}

.desktop-home-project-card-table-data-details-selected-color {
    background-color: #dbfbe7!important;
}

.desktop-home-project-card-table-stop {
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.desktop-home-project-card-modal-edit-header-name {
    font-size: 1rem;
}

.desktop-home-project-card-table-modal-delete {
    color: red;
    margin-top: 9px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
}

.desktop-home-project-card-table-modal-delete:hover {
    text-decoration: underline;
}

.desktop-home-project-card-table-modal-delete:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-split {
    color: #007bff;
    font-size: 14px;
    margin-top: -15px;
    /* width: 93px; */
}

.desktop-home-project-card-table-modal-split-text {
    color: #007bff;
    font-size: 13px;
    margin-top: -23px;
    margin-left: -4px;
}

.desktop-home-project-card-table-modal-split-text:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-split:focus {
    box-shadow: none;
}

.desktop-home-project-card-table-modal-description {
    width: 45%;
    margin-right: 10px;
    font-size: 13px;
}

.desktop-home-project-card-table-modal-description .form-control {
    font-size: 16px;
}

.desktop-home-project-card-table-modal-dropdown {
    width: 16%;
    margin-right: 10px;
    font-size: 13px;
}

.desktop-home-project-card-table-modal-dropdown .form-control {
    font-size: 16px;
}

.desktop-home-project-card-modal-edit-save-button {
    margin-right: 10px;
    font-size: 14px;
}

.desktop-home-project-card-modal-edit-delete-button {
    font-size: 14px;
    /* float: right;
    margin-right: 47px; */
}

.desktop-home-project-card-modal-edit-body-header {
    color: #868686;
    font-size: 15px;
    display: flex;
    margin-bottom: 1rem;
}

.task-tracker-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}

.task-tracker-filter-user-select div {
    /* background-color: #fcfcfc !important; */
}

.task-tracker-filter-div {
    display: flex;
    align-items: center;
}

.task-tracker-edit-label {
    font-size: 14px;
    font-weight: 500;
    color: #656565;
}

.task-tracker-edit-label-date {
    font-size: 14px;
    font-weight: 500;
    color: #656565;
    margin-left: 1rem;
}

.task-tracker-filter-whole-div {
    display: flex;
    align-items: center;
}

.desktop-home-project-card-modal-edit-body-header-description {
    width: 46%;
    margin-right: 8px;
    margin-left: -4px;
}

.desktop-home-project-card-modal-edit-body-header-dropdown {
    width: 16%;
    margin-right: 10px;
}

.desktop-app-edit-task-modal-root .modal-dialog {
    /* max-width: 595px !important; */
}

.desktop-home-manual-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: .5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.desktop-home-manual-add-card-formik {
    display: flex;
    justify-content: space-evenly;
}

.desktop-home-manual-add-card-formik .form-group {
    margin-bottom: 0rem !important;
}

.desktop-home-manual-add-card-formik-text-box {
    width: 35%;
}

.desktop-home-project-formik-add-project-button-manual .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
}

.desktop-home-manual-add-card-formik .form-control {
    font-size: 15px !important;
}

.desktop-home-manual-card-table-whole-div-fixed {
    /* overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 307px); */
}

.manual-err {
    font-size: 10px;
    color: red;
    float: right;
}

.manual-page-content-wrapper-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.desktop-home-manual-card-list-section-header-div {
    display: block;
}

.manual-page-content-wrapper-dropdown-section {
    width: 132px;
}

svg.workfolio-manual-section-calender-icon {
    font-size: 20px;
    text-align: center;
    color: #87898b;
    cursor: pointer;
}

.workfolio-manual-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}

.project-tooltip-start-priority .tooltip-inner {
    width: fit-content;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: white;
    position: sticky;
    z-index: 1 !important;
    backdrop-filter: blur(6px);
    font-size: 14px;
}

.project-tooltip-start-priority .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}

.project-tooltip-start .tooltip-inner {
    min-width: 200px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: white;
    position: sticky;
    z-index: 1 !important;
    backdrop-filter: blur(6px);
    font-size: 14px;
}

.project-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}

.manual-page-content-wrapper-dropdown-section .form-control {
    color: #007bff;
}

.desktop-home-manual-card-list-section {
    margin-top: 1rem;
}

.desktop-home-manual-card-list-section-header {
    /* color: darkgray; */
    margin-bottom: 0;
    font-size: 16px;
}

.workfolio-manual-section-tablecalender-text {
    margin-bottom: 0;
    font-size: 14px;
    color: blue;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 2px;
    /* text-decoration: underline;
    font-weight: 600; */
}

.workfolio-manual-section-tablecalender-text:hover {
    text-decoration: underline;
}

.desktop-home-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.desktop-home-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}

.desktop-home-manual-card-table-body {
    color: #333;
}

.desktop-home-manual-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}

.desktop-home-manual-card-table-description {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.manual-table-data-icon {
    color: red;
    /* font-size: 17px; */
    cursor: pointer;
}

.manual-table-data-icon:hover {
    text-decoration: underline;
}

.desktop-home-manual-card-table thead th {
    border: none;
}

.desktop-home-manual-card-table th,
.desktop-home-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem;
}

.desktop-home-manual-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

svg.workfolio-manual-pages-calender-icon {
    font-size: 20px;
    text-align: center;
    margin-top: 8px;
    color: #87898b;
    cursor: pointer;
}

.project-and-task-section-second-menu-filter {
    /* position: relative; */
    /* display: flex; */
    /* flex-direction: column;
    min-width: 0; */
    /* word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;  */
    border: 1px solid #c6d2d9;
    border-radius: 2px;
    padding: 10px;
    margin: 0rem 0rem 0 0rem;
}

.project-and-task-section-second-menu-filter .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #fff;
    color: #266dfb;
}

.project-and-task-section-second-menu-filter-button {
    margin-left: auto;
    margin-right: 0;
    /* margin-top: 3px; */
    /* padding: 5px 5px !important;
    height: 35px; */
}

.project-and-task-section-second-menu-filter-user-select {
    width: 200px;
    /* border-right: 1px solid #c6d2d9; */
}

.project-and-task-section-second-menu-filter-user-select-split-div {
    border-right: 1px solid #c6d2d9;
    margin: 0 10px;
}

.project-and-task-section-second-menu-filter-user-select .dropdown-container {
    /* border: none !important; */
    background-color: #fcfcfc !important;
}

.project-and-task-section-second-menu-filter-user-select .rmsc .dropdown-container:focus-within {
    box-shadow: none !important;
}
/* .task-tracket-task-list-start-end-time{
    width: 172px !important;
} */

@media screen and (min-device-width: 1024px) and (max-device-width: 1350px) and (-webkit-min-device-pixel-ratio: 1) {
    .task-tracker-filter-whole-div {
        display: contents;
    }
    .task-tracker-filter-div {
        display: contents;
    }
    .task-tracker-filter-div-small-screen {
        display: flex;
    }
    .task-tracker-filter-div-small-screen-button {
        padding: 0.375rem 0.75rem 0.375rem 0.75rem;
        line-height: 19px;
        height: 38px;
        /* margin-left: 0; */
        margin-right: 10px;
    }
    .project-and-task-section-second-menu-filter {
        padding: 1rem;
        margin-top: 1rem;
    }
    .task-tracker-filter-div-small-screen-select {
        width: 318px !important;
        margin-bottom: 0px !important;
    }
    .task-tracker-filter-user {
        width: -webkit-fill-available;
        margin-bottom: 15px;
    }
    .desktop-home-project-add-card-formik-timer .form-group {
        margin-bottom: 0rem !important;
        width: 50%;
    }
    .task-tracket-task-list-start-end {
        max-width: 121px;
    }
    .task-tracket-task-list-start-end-time {
        max-width: 120px;
        line-height: 25px;
    }
    
    .desktop-home-project-card-table-name {
        max-width: 150px;
    }
    .desktop-home-project-card-table-description {
        max-width: 150px;
    }
    .desktop-home-manual-add-card-formik {
        display: initial;
        justify-content: space-evenly;
    }
    .desktop-home-manual-add-card-formik-responsive {
        display: flex;
    }
    .desktop-home-manual-add-card-formik-text-box {
        width: 55%;
        margin-right: 15px;
        margin-left: 9px;
    }
    .desktop-home-manual-add-card-formik .form-group {
        margin-bottom: 1rem !important;
    }
    .desktop-home-project-formik-add-project-button {
        margin-right: 15px;
    }
    .manual-page-content-wrapper-filter-date-date-section {
        width: 220px;
        margin-right: 15px;
    }
    .manual-page-end-time-responsive {
        width: 158px;
        margin-left: 9px;
    }
}

.task-tracker-header-tab {
    font-size: 16px;
    color: #00000087;
    font-weight: 500;
}

.edit-setting-card-table-data-mark-as-complete {
    width: 19%;
    cursor: pointer;
}

.edit-setting-card-table-data-mark-as-complete-button {
    color: rgba(28, 77, 191, .788235294117647);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
}

.task-tracker-edit-menus #dropdown-basic {
    background-color: transparent;
    color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    right: 0px;
    /* margin-right: 1.5rem; */
}

.task-tracker-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}

.task-tracker-edit-menus-icon {
    color: #00000096;
    float: right;
    font-size: 1rem;
    margin-top: -29%;
}

.desktop-home-project-card-table-upcoming-tasks-display-name {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desktop-home-project-card-table-display-name-not-assigned {
    opacity: 0.4;
}

.task-tracket-task-list-due-not-added {
    opacity: 0.4;
}

.project-details-task-card-delete-body-name {
    color: black;
}

.project-details-task-card-delete-body-text {
    color: #4e4d4d;
}

.desktop-home-project-card-table-data-details-ongoing {
    background-color: #dbfbe7 !important;
}

.desktop-home-project-card-task-tracker-table-description-priority {
    font-size: 12px;
    /* background-color: #e3e3e7; */
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: gray;
}
.work-timeline-view-detail-color{
    color:#80808094 !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-root {
    /* margin-top: 3px;
    margin-bottom: 3px; */
    margin-top: 1px;
}

.desktop-home-project-card-task-tracker-table-description-priority-date-data {
    display: flex;
    grid-gap: 18px;
}

.desktop-home-project-card-task-tracker-table-description-priority-low {
    color: green !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-medium {
    color: #eca90f !important;
}

.desktop-home-project-card-task-tracker-table-description-priority-high {
    color: #ed210e !important;
}

.desktop-home-project-card-task-tracker-table-description-priority {
    text-transform: capitalize;
}

.btn-outline-info:hover {
    color: #fd7e14;
    background-color: #fd7e142b;
    border-color: #fd7e14;
}

.btn-outline-danger:hover {
    color: #ea2d40;
    background-color: #dc35451f;
    border-color: #dc354585;
}

span.desktop-home-project-card-table-project-name {
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.desktop-home-project-card-table-task-name {
    font-weight: 500;
    font-size: 15px;
    color: #333c;
}

.desktop-home-project-card-task-tracker-table-description-priority-icon {
    color: gray;
    font-size: 13px;
   
}

svg.desktop-home-project-card-task-tracker-table-description-date-icon {
    color: #666464;
    font-size: 12px;
    /* margin-top: -1px; */
}

.task-tracket-task-list-start-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #15a915b0;
    border-color: #41B840;
}

.task-tracket-task-list-stop-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #e92d2ddc;
    border-color: #e92d2ddc;
}

.task-tracket-task-list-start-again-icon {
    font-size: 25px;
    margin-top: -1px;
    margin-right: 5px;
}

.manual-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.desktop-home-project-card-table-list-task-name {
    width: 357px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}

.desktop-home-project-card-table-list-task-name span {
    cursor: pointer;
}

.desktop-home-project-card-modal-edit-button-root {
    margin-top: 2rem;
}

.desktop-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    margin-left: 1rem;
    color: red;
}

.desktop-home-project-formik-edit-end-time .rc-time-picker-input {
    border-color: #dc3545;
}

.desktop-home-project-card-modal-edit-button-root {
    display: flex;
    justify-content: space-evenly;
}

.desktop-home-project-card-modal-edit-save-button-hide,
.desktop-home-project-card-modal-edit-delete-button-hide {
    visibility: hidden;
}

.task-tracker-edit-modal-row.row {
    margin-bottom: 16px;
}

.desktop-home-project-card-modal-edit-save-button-disable {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #17a2b8;
    border-color: #17a2b8;
    margin-right: 10px;
    font-size: 14px;
}

.desktop-home-project-formik-add-form-error-message-row {
    text-align: center;
}

.desktop-home-project-formik-add-form-error-message {
    color: red;
}

.desktop-home-project-card-modal-edit-save-button:hover {
    color: none !important;
    background-color: none !important;
    border-color: none !important;
}

a.edit-task-history.dropdown-item:active {
    background-color: #fff;
    color: #000;
}

.desktop-home-project-formik-add-project-button .dropdown-menu {
    min-width: 20rem !important;
}

.desktop-home-project-task-tracker-content-area {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 307px);
}

.task-tracker-offline-task-delete-modal {
    background-color: #000000a6;
}

.desktop-home-project-card-table-list-task-name span {
    cursor: pointer;
}

.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline-new {
    width: 300px !important;
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 500px !important;
}

.desktop-home-project-card-table-list-timeline-task-name {
    width: 455px !important;
}
.worktimelin-cursor{
    cursor: pointer;
}
td.task-tracker-data-td-task-name.task-tracker-data-td-task-name-upcomingTasks {
    width: 666px !important;
}

.desktop-home-project-card-table-list-task-name-upcomingTasks {
    width: 590px !important;
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 400px !important;
}

.desktop-home-project-card-table-name-upcomingTasks {
    width: 600px !important;
}

.task-tracker-data-td-task-name-inProgressTasks {
    width: 540px !important;
}

.desktop-home-project-card-table-list-task-name-inProgressTasks {
    width: 489px !important;
}
.task-tracker-header-tab-tooltip-name {
    color: #6a6565b8;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 13px;
}
.task-tracker-header-tab-tooltip-name-span{
    margin-right: 4px;
    margin-top: -6px;
    font-size: 13px;
}
.desktop-home-project-card-table-name-inProgressTasks {
    width: 473px !important;
}

.task-tracker-data-td-task-name-onDue {
    width: 606px !important;
}

.desktop-home-project-card-table-list-task-name-onDue {
    width: 533px !important;
}

.desktop-home-project-card-table-name-onDue {
    width: 535px !important;
}

.task-tracker-data-td-task-name-completed {
    width: 552px !important;
}

.desktop-home-project-card-table-list-task-name-completed {
    width: 490px !important;
}

.desktop-home-project-card-table-name-completed {
    width: 500px !important;
}

.task-tracker-data-td-task-name-hold {
    width: 612px !important;
}

.desktop-home-project-card-table-list-task-name-hold {
    width: 548px !important;
}

.desktop-home-project-card-table-name-hold {
    width: 550px !important;
}

.team-activites-root {
    padding: 1rem;
}

.team-activites-root-count-whole-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.team-activites-root-count-sub-div {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    text-align: center;
}

.team-activites-root-count-div-total-time-text {
    font-size: 1.1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #343a40!important;
}

.team-activites-root-table-text {
    margin-top: 1rem;
}

.team-activities-table-root-progress-bar {
    width: 500px;
}

.team-activities-table-root {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background-color: #fff;
    padding: 1rem 1.5rem;
}

.team-activites-root-filter {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
}

.team-activites-root-filter-button .btn-primary {
    border: none;
    margin-right: 0rem;
    width: auto;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}

.team-activites-root-filter-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.team-activites-root-filter-button .btn-primary:hover {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activites-root-filter-button .btn-primary:active {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activites-root-filter-button .btn-primary:focus {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.team-activity-project-details-chart-title {
    padding: 9px 0px 0 8px;
    display: flex;
    grid-gap: 1rem;
    grid-auto-flow: column;
    align-items: center;
}

.team-activity-project-details-chart-root {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background-color: #fff;
    padding: 1rem 1rem;
    margin-top: 1rem;
}

.team-activity-h6 {
    margin-bottom: 0;
}

.team-activites-root-count-div-total-time-text-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30ch;
}
.manual-time-project-formik-add-button{
    height: fit-content;
}
.task-tracker-filter-user-select .__menu,.task-tracker-filter-user-select .User__menu {
    color: hsl(0,0%,20%);
}