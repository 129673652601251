.live-stats-page-root {
    margin: 2rem;
    background-color: #fff;
}
.live-stats-page-filter-root{
    display: flex;
    justify-content: center;
}
.live-stats-page-filter-dropdown{
    width: 280px;
    margin-bottom: 0;
    margin-right: 30px;
}
.live-stats-page-content-root {
    margin-top: 3rem;
    height: 400px;
    background-color: #8080801a;
}
.live-stats-page-content-filter-label{
    padding: 2rem 2rem 1rem 2rem;
    text-align: center;
    font-weight: 600;
}
.live-stats-page-filter-date-div{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.live-stats-page-date-filter-dropdown{
    width: 200px;
    margin-bottom: 0;
    /* margin-right: 30px; */
}.live-stats-page-content-filter-label
.live-stats-page-date-filter-text {
    margin: 8px 13px 0px 0px;
}
.live-stats-page-dashboard-card-root{
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem; */
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 3rem;
}
.live-stats-page-dashboard-card {
    padding: 15px 27px;
    background: #fff;
    /* border-radius: 5px; */
    border-width: 0.5px;
    border-style: solid;
    /* border-color: rgba(0, 0, 0, 0.08); */
    -o-border-image: initial;
    border-image: initial;
    /* width: 179px; */
    text-align: center;
    border-radius: 5px;
    border: 0.5px solid rgb(0 0 0 / 18%);
}
.live-stats-page-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
    margin-bottom: 10px;
}
.live-stats-page-dashboard-card-date-text {
    text-align: center;
    color: gray;
    font-size: 15px;
}
.live-stats-page-dashboard-card-root-2 {
    margin-bottom: 2rem !important;
    margin-top: 1rem !important;
}
.live-status-password-modal-body {
    padding: 24px 33px;
}
.live-status-password-modal-body-password {
    width: 100%;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.live-status-password-modal{
    background-color: rgb(27, 26, 26);
}