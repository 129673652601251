.task-overview-root {
    padding: 1.5rem 2rem 2rem 2rem;
}
.task-overview-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
}

.task-overview-grid-over-all-summary-card {
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 0.5rem;
}
.task-overview-grid-today-summary-card{
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 0.5rem;
}

.task-overview-grid-over-all-summary-card-head {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: #fff;
}
.task-overview-grid-over-all-summary-card-head-span {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 0 1rem 0;
}
.task-overview-grid-over-all-summary-card-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}
.task-overview-grid-over-all-summary-card-content-small-card {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    /* border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-image: initial; */
    display: flex;
    justify-content: space-between;
}
.task-overview-grid-over-all-summary-card-content-small-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.task-overview-grid-small-card-icon{
    display: flex;
    align-items: center;
}
.task-overview-grid-small-card-task-assigned-me{
    color: #1d53b7;
    background-color: #edf3fd;
}
.task-overview-grid-small-card-project-assigned-me{
    color: #12945e;
    background-color: #edfaf4;
}
.task-overview-grid-small-card-due-today{
    color: #dc3535;
    background-color: #fcefef;
}
.task-overview-grid-small-card-due-past{
    color: #c88e1b;
    background-color: #fff9ee;
}
.task-overview-grid-over-all-summary-card-content-combine{
    grid-row: 1/3;
    padding: 16px;
    border-radius: 5px;
    /* border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial; */
}
.task-overview-grid-over-all-summary-card-content-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    grid-template-rows: 176px;
}
.task-overview-grid-small-card-large-icon {
    font-size: 1.9rem;
    display: contents;
}
.task-overview-grid-today-summary-card-content-color{
    color: #34495ee0;
}
.task-overview-grid-today-summary-card-content-top{
    /* margin-top: 65px; */
    margin-bottom: 47px;
}
.task-overview-grid-today-summary-card-content-top-loader{
    /* margin-top: 65px; */
    margin-bottom: 55px;
    display: flex;
}
.task-overview-grid-today-summary-card-content-top h4{
    /* font-size: 2rem; */
}
.task-overview-grid-today-summary-card-content h4{
    /* font-size: 2rem; */
}
.task-overview-no-data-div {
    text-align: center;
    color: hsla(0,0%,50.2%,.6196078431372549);
    margin-top: 5%;
}
.task-overview-website-chart {
    margin-top: -10px;
}
.task-overview-load-more-button {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}
.task-overview-website-chart {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 13px;
}
.task-overview-website-chart-text-div-whole{
    justify-content: center;
    display: grid;
    margin-top: 1rem;
}
.task-overview-website-chart-text-divs {
    display: flex;
    align-items: center;
}
.task-overview-website-chart-text-div-letter {
    font-size: 16px;
}
.task-overview-website-chart-text-div {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    border: 1px solid rgba(66,58,58,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
}
.task-overview-website-chart-text-div-progress {
    background: #84a5e5;
}
.task-overview-website-chart-text-div-to-do{
    background: #81d1ac;
}
.task-overview-website-chart-text-div-completed{
    background: #e7bf78;
}
.task-overview-website-chart-text-div-hold{
    background: #e7ad9e;
}
.task-overview-website-chart-text-div-splits{
    display: flex;
    align-items:center;
}
.task-overview-website-chart-width{
    width: 132px;
}
.task-overview-detail-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 390px;
    overflow-y: scroll;
}
.task-overview-my-task-badge-primary{
  color: #007bffd1;
}
.task-overview-my-task-badge-secondary{
    color: #6c757dd9;
}
.task-overview-my-task-badge-hold{
    color: #6c757dbf;
}
.task-overview-my-task-badge-info{
    color: #17a2b8d9;
}

.task-overview-grid-three {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem; */
}
.task-overview-modal-body-div{
    padding: 2rem;
}
.task-overview-grid-task-list-per-employee-table-content-task-name{
    width: 345px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 600;
}
.task-overview-task-details-card-value-due-crossed{
    color: #ea4c4cb0 !important;
}
.task-overview-table-description-priority-medium {
    color: #eca90fd1 !important;
}
.task-overview-table-description-priority-high {
    color: #ed210ead !important;
}
.task-overview-table-description-priority-low {
    color: #008000cf !important;
}
.task-overview-grid-task-list-per-employee-table-content-project-name{
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 600;
}
.task-overview-grid-task-list-combine{
    grid-column: 1/3;
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}
.task-overview-grid-task-list-chart{
    color: #4a5568;
    padding: 0 16px;
    background: #fff;
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0,.08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}

.task-overview-grid-task-list-per-employee {
    height: 365px;
    overflow-y: scroll;
}
.task-overview-grid-task-list-per-employee .table-striped tbody tr:nth-of-type(odd) {
    background-color: #266dfb10!important;
    box-shadow:none;
}
.task-overview-grid-task-list-per-employee-header {
    height: 38px;
    min-width: 100%;
    overflow-x: hidden;
    background: 0 0;
    border-bottom: 0px solid #e8ebee;
    border-top: 0px solid #e8ebee;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 300px;
    max-width: 300px;
}
.task-overview-grid-task-list-per-employee table td, .task-overview-grid-task-list-per-employee table th {
    vertical-align: middle !important;
}
.task-overview-grid-task-list-per-employee-table-content {
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    color: #34495ee0;
    padding-top: 1px;
    border-left: 1px solid #e8ebee;
    border-top: 1px solid #e8ebee;
    border-bottom: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgb(53 59 72 / 5%);
}
.task-overview-grid-over-all-summary-card-head-span-drop{
    margin: 14px 0px 0px 0px;
}
#task-overview-grid-over-all-summary-card-head-span-drop-button{
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user {
    width: 155px;
    margin-top: 15px;
    margin-right: 0px;
}
.task-overview-my-task-filter-user .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-user .css-tlfecz-indicatorContainer{
   padding: 5px;
}

.task-overview-my-task-filter-project {
    width: 200px;
    margin-top: 15px;
    margin-right: 15px;
}
.task-overview-my-task-filter-project .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-project .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-project .css-tlfecz-indicatorContainer{
   padding: 5px;
}
.task-overview-my-task-filter-user-activity {
    width: 127px;
    margin-top: 12px;
    margin-right: 0px;
}
.task-overview-my-task-filter-user-activity .css-yk16xz-control{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    border: 1px solid #ededed;
    background-color: #eddada17;
    color: #333;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    transition: all .1s ease;
}
.task-overview-my-task-filter-user-activity .css-1uccc91-singleValue{
    font-weight: 500;
    font-size: 15px;
}
.task-overview-my-task-filter-user-activity .css-tlfecz-indicatorContainer{
   padding: 5px;
}

@media only screen and (min-width: 1024px)  {
    
    .task-overview-grid-over-all-summary-card-head-span {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
}
@media only screen and (min-width : 1224px) and (max-width : 1400px) {
    
    .task-overview-grid-over-all-summary-card-head-span {
      font-size: 1.2rem;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0 1rem 0;
    }
    
}
.task-overview-team-my-view-toggle-toggle-details {
    margin-bottom: 1.5rem !important;
    width: 100%;
}
.task-overview-team-my-view-toggle-data{
    display: flex; 
   justify-content: center;
}
.tasks-overview-toggle-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
    margin-right: 5px;
}
.task-overview-team-my-view-toggle-toggle-team-view,.task-overview-team-my-view-toggle-toggle-my-view {
    font-size: 15px;
}
.task-overview-load-more-button .btn{
    font-size: 14px;
    padding: 4px 8px;
}


.task-overview-team-my-view-toggle-type {
    display: flex;
    justify-content: center;
}
.task-overview-team-my-view-toggle-div {
    margin-bottom: 1.5rem;
}
.task-overview-pill-button-input {
    display: none;
    visibility: hidden;
}
.task-overview-pill-button {
    display: inline-block;
    position: relative;
    padding: 2px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    background: #079058;
    border-radius: 28px;
    color: #fff;
    z-index: 0;
}
.task-overview-pill-button-selection_active {
    color: #079058;
}
.task-overview-pill-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.6em 1.2em;
    cursor: pointer;
    z-index: 2;
}
.task-overview-pill-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 37.25px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}