
.WarningScreen-root{
    padding: 2rem;
    position: absolute;
    bottom: 33%;
}
.WarningScreen-text-sry {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 25px;
}
.WarningScreen-text{
    text-align: center;
    font-size: 20px;
    color: #333;
}
.WarningScreen-root-img {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.WarningScreen-text-header{
    font-size: 15px;
    color:#266dfb;
    font-size: 1.5rem;
    text-align: center;
}