.timelapse-root {
  padding: 2rem;
}
.timelapse-cards-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1.5rem;
}
.timelapse-card-img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  min-height: 80%;
  /* border-radius: 5px; */
  opacity: 0.8;
  height: 128px;
}
.timelapse-image-card{
  /* padding: 5px;
  background-color: #eef3fc; */
}

.timelapse-footer-card{
  display: flex;
  justify-content: space-between;
  background-color: #c4c4c463;
  font-size: 12px;
  padding: .4rem .5rem;
  color: #343a40;
  /* font-weight: 800; */
}
/* .timelapse-card-date,
.timelapse-card-time {
  /* background-color: #0000004d; */
  /* font-size: 0.6rem;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 5px; */
  /* color: white; */
  /* color: black;
  font-weight: 800; */
/* } */ 
/* .timelapse-card-date {
  position: absolute;
  right: 0;
  bottom: 3%;
  left: 3%;
} */
.timelapse-card-time {
  position: absolute;
  right: 3%;
  bottom: 3%;
}
.timelapse-card-video-play {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  z-index: 1;
  border-radius: 5px;
}
.timelapse-card-play-icon {
  fill: #fff;
  font-size: 2rem;
  filter: drop-shadow(0 0 4px #00000040);
}
.timelapse-date-header-root {
  margin: 1rem 0 1.5rem;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 1;
}
.timelapse-date-header {
  /* font-weight: 600; */
  /* color: #000000bf;
  font-size: 0.9rem; */
  /* border-radius: 1.15em; */
  /* background-color: rgb(255, 255, 255); */
  /* width: fit-content; */
  padding: 2px 20px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #c5c5f1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.timelapse-date-header-label-icon{
  margin-right: 10px;
  color: #495057eb;
}
.timelapse-date-header-label-icon svg{
  margin-top: -4px;
}
.timelapse-user-header-root {
  margin: 1rem 0 1.3rem;
  display: flex;
  justify-content: center;
  /* position: sticky; */
  top: 0px;
  z-index: 1;
}

.timelapse-user-header {
  /* font-weight: 600;
  color: #000000bf;
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 20px;
  border: 1px solid #0000001f;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 7px -1px; */


  border-radius: 3px;
    background-color: #fff;
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 20px;
    margin-left: auto;

}
.timelapse-user-header-icon{
  font-size: 13px;
  margin-right: 10px;
  color: rgb(73 80 87 / 69%);
}
.timelapse-user-header-icon svg {
  margin-top: -4px;
  font-size: 15px;
}
.timelapse-card {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  transition: all 200ms ease;
  cursor: pointer;
  /* height: 160px; */
}

.timelapse-card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transform: translateY(-4px);
}

.timelapse-card img {
  /* border-radius: 5px; */
}

.timelapse-video-modal-body video {
  outline: none;
  height: auto;
}

svg.timelapse-video-modal-left-nav,
svg.timelapse-video-modal-right-nav {
  position: absolute;
  top: 46%;
  bottom: 0;
  color: #696767db;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  z-index: 1;
}
svg.timelapse-video-modal-left-nav {
  left: 1%;
  right: auto;
}
svg.timelapse-video-modal-right-nav {
  right: 1%;
  left: auto;
}
.timelapse-video-modal-close {
  position: absolute;
  right: 0;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  color: #696767db;
  margin: 0.7rem;
  opacity: 0.6;
}
.timelapse-video-modal-close:hover {
  opacity: 0.9;
}
.timelapse-user-header-root-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
}
.timelapse-see-more-card{
  /* background: #fff; */
  /* border-radius: 5px; */
  margin-left: auto;
  margin-right: 0;
  /* height: 170px; */
  border-color: none !important;
}
.timelapse-card-see-more-button{
  /* position: absolute;
  top: 41%;
  left: 6%; */
  color: #57575afa;
  text-decoration: underline;
  cursor: pointer;
}
.timelapse-video-modal-body{
  padding: 1rem 3rem !important;
}
.timelapse-video-modal-personal-header {
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;
}

.timelapse-video-modal-personal-header-details {
  display: flex;
}
.timelapse-video-modal-site-icon-content {
  height: 47px;
  width: 47px;
  line-height: 21px;
  font-size: 23px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: white;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #8394ab;
}
.timelapse-video-modal-site-icon {
  height: 23px;
  vertical-align: initial;
  margin-top: 12px;
}
.timelapse-video-modal-site-icon-details {
  margin-top: -1px;
}
.timelapse-video-modal-site-name {
  font-weight: 600;
  font-size: 22px;
  color: #000000a6;
}
.timelapse-video-modal-user-name {
  font-size: 14px;
  float: left;
  color: #f1306f;
  margin-top: -3px;
}
.timelapse-video-modal-time-details {
  font-size: 15px;
  margin-right: 11px;
}
svg.timelapse-video-modal-time-icon {
  font-size: 14px;
  margin-right: 4px;
  margin-top: -2px;
}
.timelapse-modal-avatar-profile {
  width: 47px;
  height: 47px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.timelapse-modal-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .timelapse-user-header-root{
    display: inline;
  }
  .timelapse-video-modal-site-name{
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.PlanUpdateMsg-root{
  padding:2rem;
  display: flex;
  justify-content: center;
}
.PlanUpdateMsg-header-card {
  padding: 2rem;
  background: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 5%) 1px 1px 2px;
  color: rgb(51, 51, 51);
  font-family: LOGROCKET_AVENIR, sans-serif;
  font-size: 1.2rem;
}
.PlanUpdateMsg-text{
  margin-bottom: 1rem;
  color: #1e1e1e;
  font-weight: lighter;
  line-height: 135%;
  text-align: center;
}
.PlanUpdateMsg-button{
  font-weight: 700;
  font-size: 14px;
}
.PlanUpdateMsg-button-div {
  display: flex;
  justify-content: center;
}
.timelapse-warning-text {
  text-align: center;
  border: 0.5px solid #0000000f;
  padding: 24px;
}
.screenshot-card-warning-text-document-link{
  margin-top: 18px;
  color: gray;
  font-weight: bold;
  text-align: center;
}
.screenshot-card-warning-text-document-link a,.screenshot-card-warning-text-document-link-free-user a{
  color: #0000ffbd;
}
.screenshot-card-warning-text-document-link-free-user{
  margin-top: -18px;
  color: gray;
  font-weight: bold;
  text-align: center;
}
.timelapse-no-data-text{
  text-align: center;
}
.timelapse-maintenance-text {
  margin-top: 14px;
  font-size: 14px;
  color: orange;
  border: 1px solid #0000001a;
  border-radius: 3px;
  padding: 10px;
}
.timelapse-header-card {
  display: flex;
  justify-content: end;
  background-color: #c4c4c463;
  font-size: 12px;
  padding: 0.4rem 0.5rem;
  color: #343a40;
  /* font-weight: 800; */
}
.timelapse-card-no-timelapse {
  height: 194px !important;
  cursor: default;
}
.timelapse-card-img-no-timelapse {
  height: 163px;
}
.timelapse-card-no-screenshot {
  font-size: 13px;
  padding: 21px;
  cursor: default;
}
.timelapse-image-card-play-no-timelapse{
  cursor: default;
}
.timelapse-card-video-generate-loading{
  text-align: center !important;
}
svg.timelapse-card-no-screenshot-icon {
  margin:-17px 5px;
}
.timelapse-card-add-icon {
  fill: #504f4fa3;
  cursor: pointer !important;
}
/* .timelapse-image-card-play-icon-timelapse{
  cursor: pointer !important;
} */
.timelapse-card-video-generate-icon {
  font-size: 14px;
  color: #007bff;
}
.timelapse-card-video-generate-icon:hover{
color: #fff;
background-color: #007bff;
border-color: #007bff;
}
.timelapse-announcement-text {
  margin: 14px 0px 20px 0px;
  font-size: 14px;
  color: #4e4d4d;
  border: 1px solid #0000001a;
  border-radius: 3px;
  padding: 15px;
}
.timelapse-announcement-secondary-text {
  margin-bottom: 10px;
}
.timelapse-announcement-button-text {
  font-weight: 800;
}
.timelapse-card-deleted{
  color: #ff0000b5;
}
.timelapse-card-no-screenshot-estimated-time-label {
  background-color: #a9a9a942; 
  padding: 7px 10px;
  border-radius: 3px;
  margin: 3px 8px;
  color: #1d7918;
}
.timelapse-card-no-screenshot-estimated-time {
  margin-top: 4px;
  display: flex;
}
.timelapse-card-no-screenshot-estimated-icon{
  background-color: #a9a9a942;
  padding: 7px 10px;
  border-radius: 3px;
  margin-left: 2px;
  cursor: pointer !important;
  color: #1d7918;
  margin: 3px 8px 3px 0px;
}
.timelapse-card-refresh-icon {
  color: #1d7918;
  cursor: pointer !important;
  /* margin-top: -2px; */

}
.timelapse-card-refresh-icon-loading{
  font-size: 1.3rem;
  color: #266dfb;
  animation: spin 1.0s linear infinite;
}
.timelapse-card-export-div {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  width: fit-content !important;
  border-radius: 5px;
  padding: 0.7rem;
  justify-content: space-between;
}