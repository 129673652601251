.late-clock-in-content-table-tbody-td-late-by-data{
    background-color:#f0a3a394
}
.monthly-in-out-table thead > tr th, .monthly-in-out-table tbody > tr td:not(:first-child) {
    text-align: center;
    vertical-align: middle;
}
.late-clock-in-content-table-tbody-td-absent {
    color: #8080808a !important;
    font-size: 13px !important;
}
.late-clock-in-content-table-tbody-td-late-clockin-column{
    background-color:#f0a3a394
}
.late-clock-in-content-table-tbody-td-late-clockin-column-no-count {
    color: #8080808a !important;
}