.manage-teams-root {
  padding: 2rem;
}

/* Teams-root-header div css start*/
.error{
  color: red;
  font-size: 15px;
  float: right;
}
.error + .form-control {
  border-color: #F0624D;
}
.Teams-root-header {
  /* display: flex; */
  justify-content: space-between;
}
.Teams-root-header-left-side {
  display: flex;
  align-items: center;
}
.Teams-root-content-data-details-whole-div-employee-count{
  margin:0;
}
@media screen and (max-width: 480px) {
  .Teams-root-content-data-details-avatar-circle{
    margin-top: 11px;
  }
  .Teams-root-content{
    margin-top: -23px !important;
  }
}
.Teams-root-header-left-side-button {
  /* background: #695eee; */
  /* color: #fff; */
  margin-right: 24px;
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  margin-left: auto;
  margin-right: 0px;
}
.upgrade-plan-button-color{
  
    padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      background-color: #007bffa8;
      border-color: #007bff;
      /* line-height: 1.5; */
      border-radius: .25rem;
  
}
.Teams-root-header-left-side-searchbox-wrapper {
  /* position: relative;
  margin-left: auto;
  margin-right: 0px; */
}
.Teams-root-header-left-side-searchbox {
  float: right;
  font-size: 0.8rem;
}
.Teams-root-header-left-side-searchbox:focus {
  box-shadow: none;
}
.Teams-root-header-left-side-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}

.Teams-root-header-right-side {
  display: flex;
  align-items: center;
}
.Teams-root-header-right-side-download {
  font-weight: 700;
  font-size: 14px;
  margin-right: 16px;
  color: #695eee;
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #695eee;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
/* Teams-root-header div css end*/

/* Teams-root-content div css start*/
.Teams-root-content {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000b5;
}
.Teams-root-content-data-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.Teams-root-content-data-details {
  margin-top: 1rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.9rem;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
  font-size: 0.7rem; */
}
.Teams-root-content-data-details-avatar {
  display: flex;
}
.Teams-root-content-data-details-avatar-circle {
  height: 30px;
  width: 30px;
  background-color: rgb(64, 186, 171);
  color: rgb(244 245 249);
  font-weight: bold;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  margin-right: 0.75rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.Teams-root-content-data-details-name-data {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #718093;
  overflow: hidden;
}
.Teams-root-content-data-details-name-data-team-name-count {
  margin: 0;
  font-size: 13px;
}
.Teams-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
}
.Teams-root-content-data-details-name-data-line {
  border-right: 1px solid gray;
  margin-left: 10px;
}
.Teams-root-content-table tbody tr {
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
}
.Teams-root-content-data-details-productive {
  color: #3cd2b8;
}
.Teams-root-content-data-details-unproductive {
  color: #ff4757;
}
.Teams-root-content-data-details-name-data-team-name-width {
  width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .Teams-root-content-data-details-name-data-team-name-width-td{
  width: 520px;
} */
/* Teams-root-content div css end*/

/* Teams-root div css end*/
.manage-team-modal-title.modal-title.h4 {
  font-size: 1rem;
}
.manage-team-modal-button {
  margin: auto;
  padding: 9px 0;
}
.manage-team-modal-close-button,
.manage-team-modal-save-button {
  font-size: 0.9rem;
  padding: 0.3rem 1.3rem;
  margin-right: 1rem;
  font-weight: 500;
 
}

.Teams-root-table-body tr td {
  vertical-align: middle !important;
}
.manage-teams-edit-menus #dropdown-basic {
  top: -9px;
  padding: 0.275rem .65rem;
}
.manage-teams-edit-menus #dropdown-basic {
  background-color: transparent;
  color:transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  left: -35px;
  /* margin-right: 1.5rem; */
}
.manage-teams-edit-menus-icon {
  color: #000000c7;
  float: right;
  font-size: 1.1rem;
  margin-top: -16%;
}
.manage-teams-edit-menus-delete-icon {
  color: red;
}
.Teams-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}


 .manage-teams-edit-task-label-name {
  font-size: 0.95rem;
  font-weight: 500;
  color: #000000ab;
}
.manage-teams-edit-task-label-input {
  font-size: 0.86rem !important;
  font-weight: 600 !important;
}
.delete-team-user-already-exist-text,.delete-team-user-already-exist-secondary-text{
  font-size: 16px;
  font-weight: 500;
  color: #545353;
}
.delete-team-user-already-exist-secondary-text{
  margin-top: 20px;
}
.delete-team-user-already-exist-name-listing-text {
  margin-top: 20px;
  font-weight: 600;
}
.delete-team-user-already-exist-name-listing-secondary-text div {
  margin-top: 5px;
  margin-left: 15px;
  color: #636262;
}
.manage-teams-edit-task-label-name-error {
  font-size: 13px;
}