/* Employees-root div css start*/
.manage-employee-root {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}


/* Employees-root-header div css start*/
.Employees-root-header {
  /* display: flex; */
  padding: 1rem 0;
  justify-content: space-between;
}

.Employees-root-content-table .Employees-root-content-data-details:hover{
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}
.employee-timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning-text{
  font-size: .9rem;
  margin-top: 8px;
  opacity: 0.5;
}

.Employees-root-table-email-name {
  width: 233px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Employees-root-header-left-side {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.Employees-root-header-left-side-button {
  /* background: #695eee; */
  /* color: #fff; */
  margin-right: 24px;
  font-size: 1rem;
  font-weight: 600;

  position: relative;
  margin-left: auto;
  margin-right: 0px;
}
.Employees-root-header-left-side-searchbox-wrapper {
  /* display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px; */
  
}


.Employees-root-header-left-side-searchbox {
  /* border: none; */
  float: right;
  font-size: .8rem;
}

.Employees-root-header-left-side-searchbox:focus {
  box-shadow: none;
}

.Employees-root-header-left-side-icon-wrapper {
  /* padding: 0.3rem 0.5rem 0.3rem 0;
  color: #49505752;
  display: inline-flex; */
  position: absolute;
    top: 0;
    bottom: 0;
    right: .4rem;
    padding: .3rem;
    color: #ccc;
    font-size: .9rem;
}
.add-employee-page-create-team-button{
  float:right;
  font-weight: 400;
    color: #007bff;
    text-decoration: none;
}
/* Employees-root-header div css end*/

/* Employees-root-content div css start*/
.Employees-root-content {
  flex: 1;
  margin-top: 1rem;
  color: #333;
  border-radius: 5px;
}
.Employees-root-content-table{
  border-collapse: separate;
  border-spacing: 0 8px;
  /* padding: 0 10px 10px 10px; */
}
/* .Employees-root-content-table>tbody>tr:nth-child(even)>td, 
.Employees-root-content-table>tbody>tr:nth-child(even)>th {
   background-color: #eeebeb !important; 
 } */

.Employees-root-content-data-header {
  color: rgba(121,119,119,.8588235294117647);
  font-size: 0.9rem;
}
.Employees-root-content-data-details {
  box-shadow: 0 0 0 0.5px rgba(0,0,0,.1), 0 0 0 0 rgba(0,0,0,.06);
  font-size: 0.9rem;
  font-weight: 400;
  background-color: #fff;
}
.Employees-root-content-data-details > td {
  vertical-align: middle;
}
.Employees-root-content-data-details-avatar {
  display: flex;
  align-items: center;
}
.Employees-root-content-data-details-avatar-circle {
  height: 30px;
  width: 30px;
  font-weight: bold;
  line-height: 30px;
  font-size: 15px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
}
.Employees-root-content-data-details-name-data {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Employees-root-content-data-details-name-data-team-name-count {
  margin: 0;
  font-size: 13px;
}
.Employees-root-content-data-name-div-width{
  width:fit-content;
}
.Employees-root-content-data-details-td-name{
  width:315px;
}
.Employees-root-content-data-details-td-email{
  /* width:155px; */
}
.Employees-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  
  
}
.Employees-root-content-data-details-name-data-team-name-whole{
  width: 214px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Employees-root-content-data-details-td-billable-rate{
  width: 102px;
}
.Employees-root-content-data-details-td-billable-div {
  display: flex;
  width: 102px;
}
.Employees-root-content-data-details-td-billable-div-uparrow {
  font-size: 13px;
  margin-left: 10px;
}
.Employees-root-content-data-details-td-billable {
  display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(73,80,87,.7803921568627451);
    background-color: #f8f9fa;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    text-align: center;
    /* border-radius: 0.25rem; */
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.Employees-root-content-data-details-td-billable-text {
  display: block;
    width: 60%;
    height: calc(1.2em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.7;
    color: gray;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border-left: none;
  text-align: center;
  cursor:pointer;
  text-decoration: underline;
}
.Employees-root-content-data-details-td-billable-text:hover{
  text-decoration: underline;
  color: #0000ffde;
}
.Teams-root-content-data-details-name-data-line {
  border-right: 1px solid gray;
  margin-left: 10px;
}
.employee-page-edit-profile-text-td{
  display: flex;
    font-size: 0.8rem;
}
.employee-page-edit-profile-text{
  cursor: pointer;
  text-decoration: overline;
  margin-right: 5px;
}
.employee-page-edit-profile-text-danger{
  cursor: pointer;
  text-decoration: overline;
}
.employee-page-edit-profile-text-danger:hover{
  color:red;
}
.employee-page-edit-profile-text:hover{
  color:#007bff;
}
.employee-page-accordion-div-collapse-whole-div{
  width:250px;
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
}

.employee-page-edit-profile-text-td-table-data-icon-div {
  display: flex;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  color: #6c757d;
  margin-right: 5px;
}
.employee-page-edit-profile-text-td-data-edit-icon {
  font-size: 15px;
  color: #007bff;
  margin-top: 3px;
  margin-right: 8px;
}
.employee-page-edit-profile-text-td-edit-text {
  margin-bottom: 0;
}

.employee-page-accordion-div-collapse{
    padding: 5px 15px;
}
.employee-teams-list-bullet{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.employee-teams-list-bullet-dot{
  display: inline-block;
    margin-right: 8px;
    height: 5px;
    width: 5px;
    border-radius: 40px;
    border: 1px solid rgba(66,58,58,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    background-color: white;
}
.employee-span {
  font-size: .9rem;
  font-weight: 400;
  color: #212529;
  margin-right: 5px;
}
.employee-span-no-project-team-add-more-div{
display: flex;
}
.employee-span-no-project-team-add-more{
  font-size: .9rem;
    font-weight: 400;
    color: #212529;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.employee-span-no-project-team-add-more-text{
  font-size: .9rem;
    font-weight: 400;
    color: #212529;
}
.employee-team-list-button-no-project .btn-primary {
  border: none;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  width: 230px;
  text-align: left;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).focus{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button-no-project.btn-primary:not(:disabled):not(.disabled).focus, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}


.Employees-root-content-data-name-div-width-no-project-team{
  width: 255px;
}
.employee-span-no-project-team {
  font-size: .9rem;
  font-weight: 400;
  color: #212529;
  margin-right: 5px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.employee-team-list-button .dropdown-toggle::after{
  position: relative;
  top: 2px;
}
.billable-rate-modal-heading-span{
  /* width: 233px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
.employee-team-list-button .btn-primary {
  border: none;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  width: 100px;
  text-align: left;
  box-shadow: none;
  padding: 0;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).focus{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}
.employee-team-list-button.btn-primary:not(:disabled):not(.disabled).focus, .show>.btn-primary.dropdown-toggle{
  border: none;
  color: rgb(51, 51, 51);
  background-color: #f8f9fa;
  box-shadow: none;
  outline: none !important;
  background-color: #fff;
}


.Employees-root-content-data-details-productive {
  color: #3cd2b8;
}
.Employees-root-content-data-details-unproductive {
  color: #ff4757;
}
/* Employees-root-content div css end*/
.Employees-root-modal-close {
  margin-left: 3px;
  font-size: 21px;
}
.Employees-root-modal-add-text {
  font-size: 14px;
  color: #695eee;
}
.Teams-root-content-data-details-avatar-circle-inside {
  position: absolute;
  right: 0;
  left: 41%;
  top: 70%;
  transform: translate(50%, 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #fdeedd;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #f0932b;
}

/* Employees-root div css end*/

.access-control-tabs {
  color: #000000d4;
  font-size: 0.9rem;
}

.employee-tabs-head.nav-tabs {
  padding-top: 2px;
  margin-bottom: 0.5rem;
}

.employee-tabs-head .nav-link {
  color: gray;
  font-size: 0.9rem;
  border: none;
  font-weight: 600;
}

.employee-tabs-head .nav-link.active {
  color: #007bff;
  margin-bottom: 0px;
  border-bottom: 2px solid #007bff;
  background: none;
}

.employee-tabs-head .nav-link:hover {
  color: #007bff;
}

.employee-edit-save-button {
  font-size: 0.8rem;
  padding: 0.3rem 1.3rem;
  float: right;
  margin-right: 1rem;
}
.employee-edit-close-button {
  font-size: 0.8rem;
  padding: 0.3rem 1.3rem;
  float: right;
}
.employee-edit-modal-header {
  font-size: 1rem;
}
.new-employee-modal .form-label.error {
  font-size: smaller;
  color: red;
  float: right;
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.new-employee-modal .form-error {
  font-size: smaller;
  color: red;
  margin-bottom: 0.25rem;
  text-align: center;
  margin-top: 3px;
}
.new-employee-modal .btn-link:focus,
.new-employee-modal .btn-link.focus {
  box-shadow: none;
}
/* .employee-row{
  border: 1px solid #dee2e6!important;
} */
.Employees-root-header-filter-name {
  margin-left: 15px;
  font-size: 19px;
  font-weight: 600;
  color: #000000ab;
}

.new-user-invite-modal-type-card {
  margin: 1rem 3rem 3rem;
}
.new-user-invite-modal-card-title {
  font-size: 21px;
  text-align: center;
  color: #000000ad;
  font-weight: 600;
}
.new-user-invite-modal-type {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr !important;
  margin-top: 1.4rem;
  text-align: center;
}

.employee-edit-password .nav-tabs > a {
  color: #343a40;
}
.employee-edit-password .nav-tabs > a.active {
  border-bottom: 4px solid #007bff;
  color: #007bff;
}
.personal-edit-settings-password-container {
  background: #fff;
  height: 100%;
}
.personal-edit-settings-password-container > .form-wrapper {
  margin: 11px auto 30px 35px;
  max-width: 500px;
}
.personal-edit-settings-password-container-empty{
  text-align: center;
    height: 240px;
    margin-top: 120px;
}
.new-employee-modal .nav-link {
  background-color: #fff;
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.new-user-invite-modal-type-content {
  border: 1px solid #00000036;
  padding: 0.5rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  border-radius: 5px;
  cursor: pointer;
}
.new-user-invite-modal-organisation-icon, .new-user-invite-modal-team-icon {
  font-size: 2rem;
  color: #0000004f;
  margin: 0.8rem;
}
.new-user-invite-modal-organisation-name, .new-user-invite-modal-team-name {
  font-size: 16.5px;
  font-weight: 700;
  color: #0000ffe0;
  margin-bottom: 0.3rem;
}
.new-user-invite-modal-footer-text-header {
  font-size: 12px;
  font-weight: 600;
  color: #000000bd;
  margin-bottom: -17px;
}
.new-user-invite-modal-footer-text-data {
  font-size: 13px;
  color: #000000ba;
  margin-top: -8px;
}
.new-user-invite-modal-footer-text {
  margin-bottom: 11px;
}
.invite-user-team-filter {
  width: auto;
  margin-right: 20px;
}
.invite-user-team-generate-link {
  display: flex;
  justify-content: center;
}
.invite-user-team-header {
  text-align: center;
}
.invite-user-generate-link-button{
  font-weight: bold;
}
.invite-user-generate-link-text{
  color:#0606bdf2;
  margin-top: 25px;
}
.invite-user-generate-link-text-link{
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
}
.invite-user-root{
  text-align: center;
}
.invite-user-generate-link-what-next {
  margin-top: 5px;
  font-size: 15px;
  color: #000000c4;
}
.invite-user-generate-link-history-table{
  font-size: 14px;
}
.invite-user-generate-link-history-table-content {
  text-align: center;
  padding: 30px 130px;
  margin-top: 14px;
}
.invite-user-generate-link-expire{
  text-align: left;
  font-size: 15px;
  color: #000000c4;
}
.page-content-wrapper-menu-filter-date ~ .dropdown-menu .dropdown-item {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 400;
}
.page-content-wrapper-menu-filter-date-text {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
.page-content-wrapper-menu-filter-date-text-desktop{
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.page-content-wrapper-menu-filter-dropdown-icon {
  font-size: medium;
  margin-left: 8px;
}
.invite-token-data{
  display: flex;
}
.invite-token-copy-button{
  margin-top: -4px;
}
.invite-token-link-text{
  margin-top: 4px;
}
table.invite-user-generate-link-history-table.table.table-bordered.table-hover tbody tr td {
  vertical-align: middle;
}
.manage-employee-resent-invite-link{
  font-size: 0.84rem;
  font-weight: 400;
}
.manage-employee-dollar-text-div{
  display: flex;
}
.manage-employee-dollar-text {
  display: block;
  width: 10%;
  height: calc(1.5em + 0.75rem + 6px);
  padding: 0.375rem 0.35rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.7;
  color: #495057c7;
  border: 1px solid #ced4da;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  background-color: #f8f9fa;
  background-clip: padding-box;
  border-left: none;
  text-align: center;
}
.employee-card-avatar-profile{
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.employee-page-password-status-resend-link {
  font-size: 12px;
  color: #706f6fc4;
  text-decoration: underline;
  cursor: pointer;
}
.employee-page-password-status {
  font-size: 12px;
  color: #F39C12;
}
.employee-page-track-set {
  font-size: 12px;
  color: #706f6fc4;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #706f6f73 !important;
}
.employee-page-track-set:hover {
  text-decoration: underline;
  color:black;
  text-decoration-color: black !important;
}
.Employees-root-content-data-details-passwors-status{
  color: #F39C12 !important;
  box-shadow: none;
}
.employee-page-name-password-status{
  color: #F39C12;
}
.add-another-team-button{
  font-size: 0.9rem;
}
.new-employee-modal-footer-help-text {
  font-size: 12px;
}
.new-employee-modal-footer{
  justify-content: space-between !important;
}
.new-employee-modal-footer-button .btn-light{
  margin-right:28px;
}
span.new-employee-modal-footer-help-text-role {
  font-weight: 600;
}
.invite-user-generate-link-what-next {
  margin-top: 19px;
  font-size: 15px;
  color: #000000c4;
  padding: 8px 235px;
  text-align: left !important;
  display: table;
  margin-right: auto;
  margin-left: auto;
}
label.invite-user-generate-link-what-next-title {
  color: #0088ff;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 2px;
  
}

.invite-user-generate-link-what-next-step {
   font-size: 13px;
  color: #808080cf;
  font-weight: 600;
}
.invite-user-generate-link-what-next-step-help {
  font-size: 13px;
  color: #808080cf;
  margin-top: 8px;
  font-weight: 600;
}
.employee-page-mobile-email-id {
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 170px;
}
.upgrade-plan-button-color-employee{
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: #007bffa8;
  border-color: #007bff;
  /* line-height: 1.5; */
  border-radius: .25rem;
}
/* .timesheet-settings-card-employee{
  padding: 1rem 1rem 1rem 1.3rem;
} */
.setting-user{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
}
/* .new-employee-modal-setting{
  width:auto;
} */
.timesheet-settings-row-employee > * {
  margin-bottom: 1rem;
}
.custom-dialog { max-width: 71%; }
.timesheet-settings-row-grid-employee, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2 {
  /* align-items: start; */
  /* gap: 30px; */
  -moz-columns: 2 calc((100vw - 375px - 8rem) / 2);
  columns: 2 calc((100vw - 375px - 8rem) / 2);
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
.user-employee-idel-dropdown{
  width: fit-content !important;
  display: inline;
  margin-left: 1px;
}
.timesheet-settings-row-grid-card-employee {
  /* height: auto; */
  display: inline-block;
  margin-bottom: 1rem;
  /* width: calc((100vw - 375px - 6rem) /2); */
  width:33vw;
  color: rgb(74, 85, 104);
  /* overflow: auto; */
  padding: 16px;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(211, 211, 211);
  -o-border-image: initial;
  border-image: initial;
}
.timesheet-settings-row-grid-card h6{
  color:black;
}

@media only screen and (min-width : 1200px) and (max-width : 1300px){
  .Employees-root-table-email-name{
    width:158px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:158px;
  }
}
@media only screen and (min-width : 1024px) and (max-width : 1200px){
  .Employees-root-table-email-name{
    width:148px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:148px;
  }
}

@media screen and (max-width: 480px) {
  .timesheet-settings-row-grid-employee, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2{
    columns: initial;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .timesheet-settings-row-grid-card-employee{
    width: -webkit-fill-available;
  }
  .timesheet-setting-sub-button{
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .timesheet-settings-card {
    padding: 2rem 2rem 0rem 2rem;
  }
  .timesheet-setting-sub-button-div{
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .new-employee-modal-footer-help-title{
    font-size: 12px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .setting-user{
    grid-template-columns: 1fr;
  }
  .new-user-invite-modal-type {
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1.4rem;
    text-align: center;
  }
  .new-user-invite-modal-type-content{
    margin-bottom: 25px;
  }
  .invite-user-generate-link-history-table-content {
    padding: 0px 0px;
  }
  .invite-user-team-filter {
    margin-right: 11px;
  }
  .invite-user-generate-link-expire{
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .invite-user-team-header{
    margin-bottom: 30px;
  }
  .invite-user-generate-link-what-next {
    padding: 0px 0px;
  }
  .invite-token-copy-button{
    font-size: 10px;
  }
  .Employees-root-content-data-details-name-data-team-name{
    width:160px;
  }

  .Employees-root-header-filter-name{
    font-size: 14px;
  }
  .Employees-root-header-left-side-button{
    font-size: 12px;
  }
  .new-employee-modal-footer-button .btn-light {
    margin-right: 20px;
  }
}

@media only screen and (min-width : 767px) and (max-width : 820px){
  .add-email-label{
    font-size: 13px;
  }
  .Employees-root-table-email-name{
    width:125px;
  }
  .invite-user-generate-link-history-table{
     font-size: 10px;
  }
  .Employees-root-content-data-details-name-data-team-name-whole{
    width:130px;
  }
  .new-user-invite-modal-type {
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1.4rem;
    text-align: center;
  }
  .new-user-invite-modal-type-content{
    margin-bottom: 25px;
  }
  .invite-user-generate-link-history-table-content {
    padding: 0px 0px;
  }
  .invite-user-team-filter {
    margin-right: 11px;
  }
  .invite-user-generate-link-expire{
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .invite-user-team-header{
    margin-bottom: 30px;
  }
  .invite-user-generate-link-what-next {
    padding: 0px 0px;
  }
  .invite-token-copy-button{
    font-size: 10px;
  }
}

.new-user-invite-modal-type-number-of-license {
  font-weight: 500;
    text-align: center;
    font-size: 19px;
    margin-bottom: 14px;
}
.new-user-invite-modal-type-number-of-title{
  font-size: 15px;
  color: #000000bf;
}
.new-user-invite-modal-type-number-of-license-button {
  font-size: 16px;
  font-weight: 600;
  padding: 6px 30px;
}
.new-user-invite-modal-type-number-of-license-label {
  font-size: 17px;
  font-weight: 500;
  color: rgba(0,0,0,.6705882352941176);
}
.new-user-invite-modal-card-title-license-text {
  color: red;
  font-size: 17px;
  font-weight: 600;
  margin-top: 24px;
  text-align: center;
}
.new-user-invite-modal-card-title-license-purchase-button-div{
  text-align: center;
}
.new-user-invite-modal-card-title-license-purchase-button{
  padding: 5px 16px;
  font-weight: 600;
  margin-top: 20px;
}
.new-user-invite-modal-type-number-of-license-header{
  color: red;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.new-user-invite-modal-type-number-of-license-label-error{
  color: red;
  font-size: 14px;
  margin-top: 9px;
}
.new-user-invite-modal-type-number-of-license-help{
  font-size: 14px;
  margin-top: 27px;
}
.new-user-invite-modal-type-number-of-license-input{
  text-align: center;
}
.employee-page-app-version {
  font-size: 12px;
  color: #80808085;
  font-weight: 600;
  margin-left: 5px;
}
.new-user-invite-modal-type-number-of-license-help-warning {
  font-size: 17px;
  color: red;
}

/* DEFAULT FORM CSS OVERWRITE */
.form-check-input {
  /* position: inherit !important; */
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.manage-employee-edit-track-settings-checkbox input {
  margin-right: 8px;
}
.employee-billable-form{
  padding: 10px 10px 0px 10px;
}
.employee-billable-form-whole-div{
  font-size: 13px;
  margin-bottom: 1.5rem;
}


.manage-billable-team-modal-button {
  margin: auto;
  padding: 9px 0;
  display: flex;
  justify-content: center;
}
.manage-billable-form{
  width:30%;
}
.manage-billable-form::placeholder{
  color:#bdbbbb!important;
}
.manage-billable-form-bill{
  width:23%;
}
.manage-billable-form-bill::placeholder{
  color:#bdbbbb!important;
}
.bilable-rate-time-whole-div{
  display: flex;
  margin-bottom: 0.7rem;
}

.bilable-rate-time-div-datepicker-text{
  margin-left: -12px;
  display: flex;
  align-items: center;
}
.billable-rate-label-div{
  color: rgba(83,92,104,.9882352941176471);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.bilable-rate-time-div-datepicker-inside{
  padding: 19px 17px !important;
}

.bilable-rate-time-div-datepicker .btn-primary {
  border: none;
  margin-right: 1.5rem;
  width: 145px;
  color: rgb(51, 51, 51);
  text-align: left !important;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  margin: 0 10px;
}

.bilable-rate-time-div-datepicker .dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  color: #ccc;
}

.bilable-rate-time-div-datepicker .btn-primary:hover {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}

.bilable-rate-time-div-datepicker .btn-primary:active {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}

.bilable-rate-time-div-datepicker .btn-primary:focus {
  
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: rgb(51, 51, 51) !important;
}
.employee-page-app-version:hover {
 text-decoration: none !important;
}
a:hover .employee-page-app-version {
  display: inline-block; 
  text-decoration:none !important;
}
.manage-teams-edit-menus #dropdown-basic{
  right: 0px !important;
}
.employee-page-app-version-icon {
  width: 11px;
  color: #80808026;
  margin-top: -3px;
  opacity: 0.3;
}
.employee-page-app-version-icon-hover {
 opacity: 0.7;
}


.timesheet-settings-row-grid-employee-start-time .rc-time-picker-input {
  font-size: 0.9rem !important;
  margin-left: 0% !important;
  width: 100% !important;
}
.new-employee-modal-setting-error {
    height: 83vh;
    overflow: scroll;
}
.new-employee-modal-setting {
    height: fit-content;
    overflow: scroll;
}
.new-user-invite-modal-type-loading-body{
  height: 300px;
}
.manage-employee-team-details-loading{
  height: 345px !important;
}
.new-user-invite-modal-type-number-of-license-help1 {
  font-size: 16px;
  margin-top: 5px;
}
.new-user-invite-modal-type-number-of-license-help2 {
  font-size: 14px;
  margin-top: 10px;
}
.add-employee-modal-form-employee-id-label{
  color: gray;
  font-size: 15px;
}
.add-employee-modal-form-employee-label-name-required {
  color: #ff0000b0;
}
.Employees-root-content-data-details-td-employee-id-no-data {
  font-size: 12px;
  color: #80808085;
  font-weight: 600;
}
.Employees-root-content-data-details-td-employee-id {
  width: 90px;
}
.Employees-root-content-data-details-td-employee-id-data {
  color: gray;
  font-size: 12px;
  margin-bottom: 2px;
}
.new-user-invite-modal-type-number-of-license-text{
  font-size: 16px;
}
.timesheet-settings-row-grid-card-organisation-timezone {
  margin-bottom: 2rem !important;
}
.timesheet-settings-row-grid-card-employee-timezone-select {
  font-size: 15px;
}
.employee-add-modal-error{
  width: 100%;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px !important;
}