.my-projects-root {
  padding: 2rem;
}
.project-card-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 0.5rem;
}
.project-card-root a {
  color: #212529;
}
.project-card-root a:hover {
  text-decoration: none;
}
.my-project-center-no-data p{
  display: flex;
  justify-content: center;
  margin-top: 17%;
}
.my-project-center-no-data-div{
  display: flex;
  justify-content: center;
}

/* project-summary div css start */
.project-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.project-summary-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.project-summary-card-text {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-summary-card-count {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
/* project-summary div css end */

/* project-card div css start*/
.project-card {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid lightgray;
  transition: all 0.2s ease-out;
}
.project-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
}
.project-card-white {
  /* background-color: #fdffe0; */
  background-color: "white";
}
.project-card-yellow {
  /* background-color: #fdffe0; */
  background-color: #fdffe0;
}
.project-card-green {
  background-color: #c3e7b7;
}
.project-card-gray {
  background-color: #b0c2c2;
}

/* project-card-header-split div css start*/

.project-card-header-split {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
  justify-content: space-between;
}
.project-card-header {
  display: flex;
  align-items: center;
}
.project-card-avatar {
  text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    height: 40px;
    width: 40px;
    width: 40px;
    border-radius: 37px;
    background: #e3e3e3;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333333d6;
    margin-top: 0px;
}
.project-card-projectname {
  color: #333;
  white-space: nowrap;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;
}
.project-management-miled-color {
  color: #808080c4;
}
.project-card-projectname {
  font-size: 17px;
    font-weight: 700;
    line-height: 25px;
}
.project-card-companyname {
  font-size: 12px;
  margin-top: 3px;
}

.project-card-percentage {
  margin-left: auto;
}
.project-card-percent {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.project-card-percent-text {
  font-size: 10px;
    line-height: 15px;
    color: #009b37;
}

.project-card-percent-text-black {
   color:#000000bf;
}
.project-card-chart {
  position: relative;
  /* margin: 0px 0px 0px 15px; */
}
.project-card-chart-percent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}
.project-card-chart-data {
  font-size: 12px;
  text-align: center;
}

.project-card-content-card {
  background-color: bisque;
  color: #212529;
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}
.project-high-percent{
  color: #009b37;
}
.project-card-content-card p{
  margin-bottom: 0;
  font-size: 15px;
  text-align:center;
}
.project-card-chart-data-edit-menu {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.project-card-chart-data-edit-menu .dropdown-menu {
  font-size: 0.7rem;
}
.project-card-chart-data-edit-menu #dropdown-basic {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  position: initial !important;
}
.project-card-chart-data-edit-menu .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  background-color: transparent;
}
.project-card-delete-task {
  color: red !important;
}

.editmodal {
  position: relative;
}
#dropdown-basic {
  position: absolute;
  top: 31px;
  right: 10px;
}

.project-member-tasks-modal-content-person-name-header {
  margin-bottom: 5px;
}
.project-member-project-task-modals-user-label-section-div {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #edeef2;
  justify-content: space-between;
}
.project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  /* box-shadow: inset 0 -1px 0 0 #edeef2; */
  text-transform: uppercase;
  width: 265px;
}
.create-project-team-selection-error-message-member-modal {
    color: red;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 5px;
}
.project-member-tasks-modal-content-person-name-header-button {
  font-size: 13px;
  margin-bottom: 0;
  color: grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  text-decoration: underline;
}
.project-member-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 280px;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 1.5rem;
}
.project-member-project-task-modals-content-person-name-listing-card-content {
  display: flex;
  margin-top: 10px;
}
.project-member-tasks-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  cursor: pointer;
}
.project-member-task-card-avatar-profile {
    width: 27px;
    height: 27px;
    position: relative;
    overflow: hidden;
    margin-right: 11px;
    border-radius: 50%;
}
.project-member-task-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.project-member-my-projects-modal-content-header-team-name {
    font-size: .91rem!important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 355px;
    text-align: left;
}

.project-member-my-projects-modal-content-team-name {
  margin-right: 10px;
}
.project-member-project-task-modals-user-label-section {
  height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 265px;
}

/* project-card-header-split div css end*/

/* project-card-content div css start*/
.project-card-content {
  display: flex;
  padding: 20px 0 10px 0;
}

.project-card-split-content-align{
  margin-left: auto;
    margin-right: 0;
}
.project-card-split-content-limits {
  margin: 0;
}
img.project-card-split-content-limits-member-img {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 1.7rem;
}
.project-card-split-content-limits-member-img:nth-child(2),
.project-card-split-content-limits-member-img:nth-child(3) {
  margin-left: -1rem;
}
.project-card-split-content-limits-member {
  display: flex;
  justify-content: right;
}
.project-card-split-content-limits-member-other {
  background-color: #f8f9f9;
  height: 1.7rem;
  width: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  font-size: 0.7rem;
  margin-left: -1rem;
}
.project-card-split-content-limits-member-number {
  font-weight: 600;
  color: #000000b0;
}
/* project-card-content div css end*/

/* project-card div css start*/

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}

.add-project-modal-button-rows {
  margin-bottom: 10px;
}
.projects-create-col {
  text-align: center !important;
}
.project-card-chart-data-total-time {
  font-size: 0.67rem;
}
.project-card-chart-data-total-time-data {
  margin-right: 3px;
  font-weight: 600;
}
span.project-card-chart-data-total-time-title {
  font-size: 0.58rem;
}
.project-card-total-time {
  display: flex;
  margin-right: 32px;
}
.no-data {
  text-align: center;
}
.error{
  color: red;
  font-size: 15px;
  float: right;
}
.error + .form-control {
  border-color: #f0624d;
}
.my-projects-root-header{
  display: flex;
  margin: 0px 0px 20px 0;
}
.add-projects-search-input{
  width:45%;
}
.add-projects-button{
  margin-left: auto;
}
.my-projects-modal-content-person-name-listing-card-content-person{
  cursor: pointer;
}
.my-projects-modal-content-team-name {
  margin-right: 10px;
}
.create-project-team-selection-control-checkbox{
  width: 0.85rem;
height: 0.85rem;
vertical-align: middle;
cursor: pointer;
}



/***** MODAL CSS *****/
.my-projects-modal-root .modal-dialog .modal-content{
  padding: 0 16px;
  border-radius: 12px;
}
.my-projects-modal-header{
  /* border-bottom: none !important; */
}
.my-projects-modal-title.modal-title.h4 {
  /* font-size: 1.2rem; */
  /* font-size: 1.2rem; */
  color: #333333;
  /* font-family: "Helvetica Now Display"; */
  font-size: 20px;
  font-weight: 600;
  /* letter-spacing: .35px; */
  text-align: center;
  /* line-height: 32px; */
  margin: 0;
  /* display: flex; */
  align-items: center;
  text-align: center;
  width: 90%;
  flex-shrink: 0;
}
.my-projects-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.my-projects-modal-content-name-field {
  flex: 1 1;
  /* margin-right: 16px; */
  margin-bottom: 0rem;
  margin-right: 16px;
  margin-bottom: 16px;
}
.my-projects-modal-label-name {
  /* font-size: 1rem;
  color: #718093; */
  color: #535c68fc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  /* display: block; */
}
.my-projects-edit-project-label-input::placeholder{
  color: rgb(212, 209, 209);
}
.my-projects-edit-project-label-select{
  color: rgb(212, 209, 209);
}
.my-project-add-new-project-hours-text{
  display: block;
    width: 30%;
    height: calc(1.3em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.my-project-add-new-project-option-div{
  display: flex;
}
.my-project-add-new-project-option{
  font-size: 15px;
  float:right;
  color: #718093;
}
.my-projects-edit-project-label-input {
  font-size: 0.9rem !important;
}
.my-projects-modal-content-person-name-headers {
  display: flex;
  padding-right: 16px;
}
.my-projects-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  /* padding-right: 16px; */
  /* margin-right: -16px; */
  margin-bottom: 26px;
}
.my-projects-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.my-projects-modal-content-person-name-header-section {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
}
.my-projects-modal-content-person-name-header-section-label {
  /* font-size: 1rem;
  line-height: 18px;
  margin-bottom: 0.5rem;
  color: #62839b; */
  color: #535c68fc;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.my-projects-modal-content-person-name-header-section-content {
  display: flex;
}
.my-projects-modal-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  margin-right: 16px;
}
.my-projects-modal-searchbox {
  float: right;
  font-size: 0.9rem;
  background-color: #f5f3f6;
}
.my-projects-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
.my-projects-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #695eee;
  color: #695eee;
  font-size: 0.98rem;
 }
.my-projects-modal-content-person-name-listing {
  overflow: auto;
  height: 250px;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: 16px; */
  border: 1px solid lightgrey;
  padding: 10px 10px 10px 10px;
}
.projects-modal-cols {
  padding: 0 20px !important;
}
.project-card-content-card-info-icon{
  margin-right: 7px;
}
.my-projects-modal-content-person-name-listing-card-content {
  margin-bottom: 2px;
  display: flex;
}
.my-projects-modal-content-name-field .css-1wa3eu0-placeholder{
  font-size: 0.9rem !important;
}
.my-projects-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
  cursor: pointer;
}
.my-projects-modal-content-person-name-listing-card-content-person-icon {
    height: 27px;
    width: 27px;
    line-height: 27px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
}
.my-project-user-selection-user-list{
  margin-left: 2opx;
  display: flex;
}
.my-projects-modal-content-person-name-listing-card-icon{
  height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.6rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #808080c2;
    text-transform: uppercase;
}
.my-projects-selection-task-list-name{
  margin-left: 0px;
  font-size: 0.87rem;
  color: gray;
  width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top:2px;
}
.my-projects-modal-content-person-name-whole-div{
  
  display: flex;
    align-items: center;
    flex: 1 1;
    cursor: pointer;
    margin-left: 40px;
    /* margin-bottom: 5px; */
}
.my-projects-modal-content-header-team-name {
  font-size: 0.97rem !important;
  line-height: 18px;
  color: #0d66a7;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 355px;
    text-align: left;
}
.my-projects-modal-content-header-team-name:hover {
  color: #62839b;
  /* text-decoration: underline !important; */
}
.my-projects-modal-content-header-team-name-acc{
  display: flex;
  margin-top: 4px !important;
}
.my-projects-modal-content-team-name-user {
  margin-right: 10px;
}
.my-project-user-selection-user-list-collapse{
  margin-bottom: 11px;
  /* background-color: aliceblue; */
    padding: 7px 0px;
    border-radius: 5px
}
.my-project-rule-uparrow {
  font-size: 14px;
  color: #0d66a7;
}
.my-projects-modal-content-team-name {
  margin-right: 10px;
}
.create-project-team-selection-control-checkbox {
  width: 0.9rem;
  height: 0.9rem;
  vertical-align: middle;
  cursor: pointer;
}
.my-projects-modal-button {
  margin: auto;
}
.my-projects-modal-save-button {
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
}
.my-projects-modal-searchbox{
  font-size: 12px;
}
.my-projects-root-searchbox-wrapper {
  position: relative;
  margin-right: 0;
  width: 67%;
}
.my-projects-root-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0.5rem;
  color: #000000bd;
  font-size: 0.9rem;
}
.create-project-team-selection-error-message{
  color: red;
  font-size: 0.9rem;
  text-align: center;
}
.disable-button{
  cursor: not-allowed !important;
 }
.project-card-project-name-div{
  width: 100%;
  cursor: pointer;
}
.project-card-created-content {
  display: flex;
  padding: 10px 0;
 }
.project-card-split-content-created-by {
  width: 132px;
}
.project-card-exceed-estimated-time-value{
  color:red;
}
.project-card-worked-time-text {
  text-align: center;
}
.project-card-worked-time-number {
  font-size: 18px;
}
.project-card-exceed-estimated-time-number {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.project-card-exceed-estimated-time-text {
  font-size: 10px;
  text-align: center;
}
.project-card.green{
  background-color: #c3e7b7 !important;
}

.project-card-footer-chart {
  width: 96%;
  margin: 30px 9px 5px 9px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: gray;
  font-size: 14px;
}
.project-card-footer-chart .progress{
  height: 0.5rem !important;
}
.project-card-footer-chart-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 500;
  color: #0000008c;
}
.project-card-footer-add-estimation-time {
  text-align: center;
  margin: 16px 0px 6px 0;
}
.project-card-footer-estimated-time-button {
  font-size: 14px;
  background-color: #3e3d3d0f;
  border-radius: 10px;
  font-weight: 500;
  color: #0000007a;
}
.project-card-footer-estimated-time-button.start{
 background-color: #ffffffb5 !important;
}
.project-card-footer-estimated-time-button-icon {
  margin-top: -3px;
}
.project-card-div-wrapper{
  background-color: white !important;
}
.project-card.start {
  background-color: #c3e7b7  !important;
}
.bg-progress-not-exceed{
  background-color: #40bb4d !important;
}
.bg-progress-exceed{
  background-color: #f1596852;
}

.project-card-total-worked-time {
  color: #00000094;
  font-weight: 700;
  margin-left: 13px;
}
.project-card-total-worked-time-icon {
  margin-right: 2px;
  font-size: 18px;
}
.project-card-footer-add-estimation-time-input{
  margin:0 auto;
  width: 66%;
  display: flex;
}
.project-card-footer-add-estimation-time-input-type{
  font-size: 13px;
}
.my-projects-modal-content-person-name-header-button-select-all{
  color: #195ab1d1;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
}
.project-card-footer-add-estimation-time-input-label{
  padding: 8px;
  font-size: 15px;
}
.my-projects-modal-content-row.row {
  margin-top: 8px;
}

.my-project-status-filter {
  margin-right: 8px;
  font-weight: 500;
  border: 1px solid #ededed;
  font-size: 14px;
  margin-bottom: 9px;
  color: #427ff6;
  background-color: #eef3fc;
}
.my-projects-status-filter-selected {
  background-color: #fff;
  color: #212529;
}
.project-card-footer-add-estimation-time-button {
  width: 20%;
  padding: 2px;
  /* height: 29px; */
  margin-left: 4px;
  height: calc(1.5em + 0.6rem + 0px);
}
.project-card-footer-add-estimation-time-button-icon {
  margin-top: -4px;
}
.project-card-footer-add-estimation-time-button-loader{
  font-size: 1rem;
  color: white;
  animation: spin 0.5s linear infinite;
}

.my-project-card-badges {
  color: #212529;
  text-align: center;
  margin-top: 10px;
}
.my-project-card-badges-no-data-badge {
  vertical-align: middle;
  margin-top: 7px;
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.my-project-card-badges-working {
  background: #d8f6f6;
  border: 1px solid rgba(0,0,0,.1);
    padding: 5px;
    margin-left: 5%;
    margin-bottom: 7px;
    color: #212529;
    text-align: center;
}
.my-project-curently-work{
  width: 225px;
    color: #212529;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.my-project-herder-whole-div{
  width: 270px;
}
.my-projects-modal-label-name-required{
  color:red;
}
.project-card-div-wrapper-today{
  background-color: #e5df5933 !important;
}

.my-project-summary {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(225px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.my-project-dashboard-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.my-project-dashboard-card-title {
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1E1E1E !important;
}
.myproject-icon-div{
  display: flex;
}
.myproject-icon-h4{
  /* width:211px; */
}
.myproject-icon{
  margin-top: 3px;
  font-size: 1.6rem;
  margin-left: auto;
    margin-right: 0;
}
.myproject-add-projects-enabled-to{
  font-weight: 700!important;
  color: #343a40!important;
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;
}
.my-project-add-member-card-root{
    padding: 13px;
    background: #fff;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
    margin-bottom: 3rem;
    background-color: #e615153d;
    margin-top: -14px;
}

.my-project-add-member-card-redirection-link{
  font-weight: 600!important;
  color: #343a40!important;
  font-size: 0.95rem;
  cursor: pointer;
}
.my-project-add-member-card-redirection-link:hover{
 text-decoration: underline;
  
}

.my-project-task-modals-searchbox-wrapper {
  position: relative;
  flex: 1 1;
  /* margin-right: 16px; */
  /* margin-bottom: 12px; */
}
.my-project-member-tasks-modal-content-person-name-listing {
  overflow: auto;
  height: 280px;
  flex-grow: 1;
  padding: 0px 10px 10px 10px;
  margin-bottom: 1.5rem;
}
.my-project-task-modals-searchbox-wrapper div .form-control{
  border: 1px solid #ced4dac2 !important;
  font-size: 15px;
}
.my-project-member-project-task-modals-user-label-section-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-project-member-project-task-modals-user-label-section {
  /* height: 32px;
  color: #8c94a1;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 15px; */
  display: flex;
  align-items: center;
  width: 275px;
}
.my-project-task-modals-searchbox-wrapper div .form-control::placeholder { /* Most modern browsers support this now. */
  color: rgb(199, 194, 194);
}
.my-project-task-modals-searchbox-wrapper div div{
  color: rgb(199, 194, 194) !important;
}
.add-project-member-modal-loading{
  width: 100%;
  text-align: center;
}
.add-project-modal-button-rows-error{
  color: red;
    font-size: 14px;
}
.project-card-footer-yet-to-start-project {
  text-align: center;
  margin: 30px 9px 5px 9px;
  color: gray;
  font-size: 14px;
}
.project-card-footer-yet-to-start-project{
  cursor: pointer;
}