.widget-container {
    height: calc(100vh - 55px - 32px);
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

.widget-container>* {
    height: fit-content;
}

.widget-container>*:last-child {
    flex: 1;
    overflow: auto;
}

.w-header-avatar {
    max-width: 100%;
    max-height: 100%;
}

.w-header-name {
    max-width: 16ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
}

.w-header-avatar-wrapper {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
}

.w-header-avatar-wrapper .w-header-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    padding: 0;
    margin: 0;
}

.w-header-avatar-wrapper .w-header-badge.success {
    background-color: #28a745;
}

.w-header-avatar-wrapper .w-header-badge.warning {
    background-color: #ffc107;
}

.w-header-avatar-wrapper .w-header-badge.secondary {
    background-color: #b3b3b3;
}

.w-header-timer {
    color: #6c757d;
    font-weight: bold;
    margin: 0;
    font-size: 1.15rem;
}