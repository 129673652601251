.view-leave-root{
    padding: 2rem;
    color: #131313;
}
.view-leave-chart-div{
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 0.5px solid rgba(0,0,0,.08);
    height: 301px;
}
.leave-listing-filter-div-field-input-apply {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width:260px;
    text-decoration: underline;
}
.leave-listing-filter-date-picker-filter-date-text-apply {
    font-size: 15px;
    margin: 0;
    font-weight: 600 !important;
    color: rgba(0,0,0,.8);
}
.leave-listing-filter-date-picker-filter-date-icon-apply {
    display: inline;
    height: 100%;
    margin-right: 5px;
    color: rgba(0,0,0,.8);
}
.leave-listing-filter-date-picker-filter-date-apply {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    /* transition: all 100ms ease;
    border: 1px solid #ced4da; */
    /* border-radius: 0.25rem; */
}
.leave-type-text-header-apply{
    display: flex;
    align-items: center;
}
.view-leave-chart-div-whole-header{
    display: flex;
    align-items: center;
}
.leave-listing-root{
    border: 0.5px solid rgba(0,0,0,.08);
    border-radius: 5px;
    background-color: #fff;
}
.leave-listing-tab-whole-div{
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    min-height: 450px;
}
.leave-listing-tab-header-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    display: flex;
    /* justify-content: flex-end; */
    margin: 8px 0px;
    margin-right: 2px;
    /* border-bottom: none; */
    border-bottom: 1px solid #dee2e6;
}
.leave-listing-tab-header-tab .nav .nav-tabs .nav-link{  
    border-bottom: none;
}
.leave-listing-tab-header-tab .nav-item:first-child {
    margin-right: auto;
}
.leave-listing-tab-whole-div-span {
    float: left;
  position: relative;
  left: 23px;
  top: 20px;
  z-index: 55;
}
.leave-listing-tab-tab-Content{
    padding: 1rem;
}
.leave-listing-tab-tab-Content-list{
    display: flex;
    align-items: center;
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    font-size: 14px;
    /* box-shadow: -1px 4px 3px -6px #333;
    background-color: #fff!important; */
    margin-bottom: 1rem;
    border: 1px solid rgba(0,0,0,.050980392156862744);
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
}
.leave-listing-tab-tab-Content-list-dates-text {
    color: #212529;
    margin-right: 5px;
    width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.apply-leave-footer-apply-leave-button-text {
    color: #ff00009c;
}
.leave-listing-tab-tab-Content-list-status-badge {
    padding: 6px !important;
    letter-spacing: 0.3px;
    font-size: 87%;
    font-weight: 500;
    /* text-transform: capitalize; */
    width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-listing-tab-tab-Content-list-status-badge-approved-color
{
    background-color: #27ae60 !important;
}
.leave-listing-tab-tab-Content-list-status-badge-pending-color{
    background-color: #f1c40f !important;
}
.leave-listing-tab-tab-Content-list-status-badge-rejected-color{
    background-color: #E67E22 !important;
}
.leave-listing-tab-tab-Content-list-date{
    width: 30%;
    color:#007bff;
}

.leave-listing-tab-tab-Content-list-dates-count{
    width: 15%;
}
.leave-listing-tab-tab-Content-list-type{
    width:20%;
    text-transform: capitalize;
}
.leave-listing-tab-tab-Content-list-type-div-text{
    width: 200px;
    /* cursor:pointer; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-listing-tab-tab-Content-list-status{
    width: 26%;
    display: flex;
    align-items: center;
}

.leave-listing-tab-tab-Content-list-edits{
    width: 20%;
}
.leave-listing-tab-tab-Content-list-dates-text svg{
    margin-top: -2px;
}
/* .view-leave-add-button-div {
    display: flex;
    margin-bottom: 2rem;
} */
.view-leave-add-button {
    position: relative;
    margin-left: auto;
    margin-right: 0;
}
.leave-listing-tab-tab-Content-notext{
    text-align: center;
    margin: 100px auto;
    color:gray;
}
.view-leave-chart-demo-div{
text-align: center;
}
.view-leave-chart-demo-div img{
    width:300px;
    height: 200px;
}
.view-leave-chart-demo-div-text{
    color: gray;
    margin-top: 105px;
}






.apply-leave-modal-body-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;
}
.apply-leave-modal-body-wrapper-form-name {
    display: flex;
}

.apply-leave-modal-body-wrapper-form-name-card-avatar {
    display: inline-block;
    margin-right: 10px;
    height: 35px;
    width: 35px;
    border-radius: 40px;
    background: #48cfd6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 20px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    color: white;
}
.apply-leave-modal-body-wrapper-form-display-name {
    font-size: 21px;
    font-weight: 600;
}
.manage-teams-edit-task-label-name {
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
}
.apply-leave-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
}
.my-projects-modal-content-name-field {
    margin-right: 0 !important;
}
.my-projects-modal-content-name-field {
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 0rem;
    margin-right: 16px;
    margin-bottom: 16px;
}
.apply-leave-modal-body-wrapper-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.apply-leave-modal-body-wrapper-form-div-field-input > div{
    border: 1px solid #ced4da;
    height: calc(1.5em + 0.75rem + 2px);
}
.apply-leave-modal-body-wrapper-form-div-field-date-picker {
    border: 1px solid #ced4da;
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0.25rem;
    width: 100%;
}
.apply-leave-modal-body-wrapper-form-div-field-date-picker-div .react-datepicker-wrapper .react-datepicker__input-container,.apply-leave-modal-body-wrapper-form-div-field-date-picker-div .react-datepicker-wrapper{
    max-width: 100% !important;
}

.apply-leave-modal-body-wrapper-form-div-field-form-group {
   display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.apply-leave-modal-body-wrapper-form-div-field-input::placeholder{
    color: rgb(212, 209, 209);
} 
.apply-leave-modal-body-wrapper-summary-content-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #535c68fc;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -.22px;
    margin-bottom: 5px;
}
.apply-leave-modal-body-wrapper-summary-content-row-day-select {
    border: none;
    margin-top: -5px;
    color: #0091ff;
    font-size: 14px;
    padding: 0px;
    background: transparent;
}
.apply-leave-modal-body-wrapper-summary-content-row .form-group{
    margin-bottom: 0rem !important;
}
.apply-leave-modal-body-wrapper-summary-content-non-working {
    margin-bottom: 15px;
}
.apply-leave-modal-body-wrapper-summary-content {
    padding: 3px;
    margin-top: 10px;
    height: 215px;
    overflow: scroll;
}
.permission-modal-body-wrapper-summary-content {
    padding: 3px;
    display: flex;
    justify-content: space-between;
}
.apply-leave-modal-body-wrapper-summary {
    display: flex;
    flex-direction: column;
    background-color: #00a3ff12;
    padding: 20px;
    border-radius: 4px;
    margin: 17px 0px;
}
.apply-leave-modal-body-wrapper-summary-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    color: #535c68fc;
    font-weight: 600;
    font-size: 15px;
}
.apply-leave-modal-body-wrapper-summary-footer > div {
    /* margin-bottom: 10px; */
}
.apply-leave-modal-title {
    margin: 1.5rem 0rem 0rem 1.5rem;
    font-weight: 600;
    color: #000000d4;
    font-size: 17px;
}
.apply-leave-modal-header-title {
    font-size: 18px;
}
.react-datepicker__input-container .apply-leave-modal-body-wrapper-form-div-field-input{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: calc(1.7em + 0.75rem + 2px);
    width: 100%;
}
.apply-leave-modal-body-wrapper-summary-content-non-working-text {
    color: #808080a3;
    font-weight: 500;
    font-size: 13px;
    float: right;
    padding: 8px;
}
.apply-leave-modal-body-wrapper-summary-content-select-dates{
    color: #808080a3;
    font-weight: 500;
    font-size: 13px;
   padding: 8px;
   text-align: center;
}
.apply-leave-footer-apply-leave-button {
    text-align: center;
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 600;
}

.apply-leave-modal-body-wrapper-form-div-field-input-date {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date-icon {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    height: 15px;
    margin: 0 5px;
    opacity: 0.8;
    font-size: smaller;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date-text {
    font-size: 0.86rem;
    margin: 0;
    font-weight: 600 !important;
    color: #495057;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    border-color: #00000017;
    display: block;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger .arrow::before {
    border-bottom-color: #00000017;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-trigger .popover-body {
    overflow: hidden;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay {
    /* display: flex; */
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-datepicker {
    display: inline-block;
    width: 260px !important;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 35px;
    margin-bottom: 30px;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-button {
    margin-right: 1.7rem;
    position: relative;
    float: right;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-date {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.apply-leave-modal-body-wrapper-summary-content-row-day-select div{
    border: none !important;
    color: #0091ff;
    background: transparent;
}

.leave-listing-load-more-button {
    display: flex;
    justify-content: center;
}

.leave-list-text-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    color: #bdbbbb !important;
    margin-bottom: 2rem;
}
.view-leave-chart-demo-div-temp-div{
    margin-top: 5rem;
}

.leave-listing-tab-tab-Content-loader{
    margin: 100px auto;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .leave-listing-tab-whole-div-span {
        float: unset;
    }
    .leave-listing-tab-tab-Content{
        overflow: auto;
        white-space: nowrap;
    flex-wrap: nowrap;
    }

    .leave-listing-tab-tab-Content-list-whole{
        display: grid;
    }
    .leave-listing-tab-tab-Content-list-date {
        width: 205px;
    }
    .leave-listing-tab-tab-Content-list-dates-count {
        width: 65px;
    }
    .leave-listing-tab-tab-Content-list-type {
        width: 125px;
    }
    .leave-listing-tab-tab-Content-list-status {
        width: 65px;
    }
    .leave-listing-tab-tab-Content-list-edits {
        width: 200px;
    }
    .apply-leave-modal-body-wrapper{
        display: grid;
        grid-template-columns: 1fr !important;
        grid-gap: 0rem !important;
    }
}
.error + .apply-leave-modal-body-wrapper-form-date-picker-filter-date, .error + .apply-leave-modal-body-wrapper-form-field-text-area{
    border-color: #F0624D;
}
.apply-leave-modal-error{
    font-size: 13px;
    margin-top: 5px;
}
svg g, svg g:first-of-type path {
    stroke: none !important;
    stroke-width: 0  !important;
}
.apply-leave-modal-body-wrapper-form-field-text-area {
    font-size: 0.86rem;
    margin: 0;
    font-weight: 600 !important;
    color: #495057;
}
.apply-leave-modal-body-wrapper-summary-content-member {
    padding: 3px;
    margin: 2px 0px 10px 0px;
    height: 150px;
    overflow: scroll;
}
.permission-modal-body-wrapper-summary-content{
    display: flex;
    justify-content: space-around;
}
.leave-listing-tab-tab-Content-list-status-badge-permission {
    padding: 6px !important;
    letter-spacing: 0.3px;
    font-size: 87%;
    font-weight: 500;
    /* text-transform: capitalize; */
    width: 90px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    background-color: #BDC3C7 !important;
}

svg g, svg g:first-of-type text {
   font-style: normal !important;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-close-icon-div {
    float: right;
    margin-top: 5px;
    cursor: pointer;
}
.apply-leave-modal-body-wrapper-form-date-picker-filter-overlay-close-icon {
    font-size: 20px !important;
    color: black !important;
}

.leave-type-card-root-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 20px 0px 20px;
    margin-bottom: -0.5rem;
}
.leave-type-card-root {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 0.5px solid rgba(0,0,0,.08);
    height: 315px;
}
.leave-type-text-header {
    padding: 20px 20px 0px 20px;
}
.leave-type-table-whole-div {
    width: 76vw;
    overflow: auto;
    color: #4a5568;
}
.leave-type-content-data-header {
    color: rgb(121 119 119 / 35%);
    font-size: 0.9rem;
}
.leave-type-card-apply-leave-page-card-p-icon{
    font-size: 35px;
    margin-bottom: 10px;
}
.leave-type-card-root-whole-div{
    background-color: #f8f9fa!important;
    box-shadow: 0px -1px 3px -6px #333;
    font-size: .9rem;
    font-weight: 400;
}
.leave-type-card-root-whole-div-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
    margin-bottom: 10px;
}
.leave-type-card-root-whole-div-availale{
    font-size: .8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
    margin-bottom: 5px;
}
.leave-type-card-root-whole-div-booked{
    font-size: .8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1e1e1e!important;
}
.leave-setting-mute-text{
    color: #7788998c;
}
.view-leave-whole-div {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem; */
}
.leave-type-card-apply-leave-page{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
    grid-gap: 0.8rem;
    margin: 20px;

    grid-auto-flow: column;
    /* grid-auto-columns: minmax(160px,1fr);
    overflow-x: auto; */
}
.leave-type-card-apply-leave-page-card {
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.04);
    text-align: center;
    /* width: 187px; */
    min-width: 186px;
    max-width: 200px;
    height: 210px;
    float: left;
    margin-left: 10px;
    margin-bottom: 0;
    padding: 20px 20px;
    overflow: hidden;
    transition: all 0.3s;
    border-radius: 4px;
}
.leave-type-card-apply-leave-page-card-p{
    margin-bottom: 0px;
    padding-top: 10px;
    font-size: 14px;
}
.apply-leave-footer-apply-leave-button-extra-box {
    width: 95%;
    padding: 15px 15px 15px 15px;
    /* margin-right: 21px; */
    margin: 0px auto 20px 20px;
    background: #fff;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
    /* display: flex;
    flex-direction: column; */
    
}
.leave-type-card-apply-leave-page-card-header-icon{
    color: #96bc42 !important;
    background: transparent !important;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 3px;
    display: inline-block;
/* 
    display: inline-block;
    height: 45px;
    width: 45px;
    border-radius: 70px;
    background: rgba(239,120,14,.5490196078431373);
    border: 1px solid rgba(0,0,0,.1);
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin-right: 13px; */

    /* display: inline-block;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #8394ab;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 22px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    margin-right: 13px; */
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-sub{
    width:545px;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-sub-2{
    border-left: 1px solid #cccccc47;
    padding-left: 25px;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-label {
    color: gray;
}

.apply-leave-footer-apply-leave-button-extra-box-text {
    /* margin-bottom: 5px; */
    font-size: 13px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    grid-gap: 1.5rem;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header {
    /* margin-bottom: 12px; */
    font-size: 14px;
    display: flex;
}
.apply-leave-footer-apply-leave-button-extra-box-text-header-span{
    font-weight: 600;
    margin-bottom: 10px;
}
.leave-type-card-apply-leave-page-card-span {
    color: gray;
}
.leave-type-card-apply-leave-page-card-p-header {
    margin-bottom: 10px;
    font-weight: 600;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.leave-settings-no-data-text-view {
    font-size: 14px;
    text-align: center;
    color: gray;
    margin-top: 95px;
}
.apply-leave-modal-body-wrapper-summary-content-row-date-span {
    margin-right: 10;
    margin-left: 7px;
    color: #808080b5;
}
svg.apply-leave-button-icon {
    margin: -2px 2px 0px 0px;
    /* width: 11px;
    height: 10px; */
    color: white !important;
}

.view-leave-balanace-count-tooltip-user-content{
    margin-bottom: 2px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 500;
}
.leave-listing-tab-tab-Content-list-dates-count-span {
    margin-right: 8px;
}
.leave-listing-tab-tab-Content-list-dates-count-span-icon{
    color: gray;
}
.view-leave-balanace-tooltip-start .tooltip-inner {
    min-width: 250px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: #dee2e6;
    position: sticky;
    z-index: 1 !important;
    backdrop-filter: blur(6px);
    font-size: 15px;
}
.view-leave-balanace-tooltip-start-span{
    color:white !important;
    font-size: 14px;
    margin-left: 5px;
}
.view-leave-balanace-count-tooltip-user-content-div{
    display: flex;
    justify-content:  left;
    padding: 3px;
}

.leave-listing-filter-new-root {
    margin-bottom: 1rem;
}
.leave-listing-filter-content {
    display: flex;
    justify-content: end;
}
.leave-listing-filter-user {
    width: 220px;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 15px;
}
.leave-listing-filter-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width: 225px;
}
.leave-listing-filter-date-picker-filter-date {
    display: flex !important;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    justify-content: space-between;
}
.leave-listing-filter-date-picker-filter-date-icon {
    display: inline;
    height: 100%;
    /* width: 100%; */
    margin-right: 5px;
}
.leave-listing-filter-date-picker-filter-date-text {
    font-size: 14px;
    margin: 0;
    font-weight: 400 !important;
    color: hsl(0, 0%, 20%);
}

.apply-leave-page-wrapper-form-date-picker-filter-filter {
    display: inline-block;
    width: 226px !important;
    vertical-align: top;
    margin-left: 8px;
    margin-top: 13px;
    margin-bottom: 8px;
}
.leave-listing-filter-date-picker-filter-date-label{
    display: flex;
    align-items: center;
}
.leave-listing-filter-date-picker-filter-date-clear-icon {
    font-size: 17px;
}
.permission-modal-body-wrapper-summary-content-member {
    display: flex;
    justify-content: space-around;
}