.apps-and-websites-history-card {
    margin: 1rem;
}
.apps-and-websites-history-card-content {
    /* border: 0.5px solid #0000001a; */
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.apps-and-websites-history-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.apps-and-websites-history-card-export {
    margin-bottom: 1rem;
    display: flex;
    justify-content: end;
}
.disable-app-export {
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #007bffa8;
    border-color: #007bff;
    border-radius: .25rem;
}

    .enable-timesheet {
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
        background-color: #007bff;
    border-color: #007bff;
        /* line-height: 1.5; */
        border-radius: .25rem;
        cursor:pointer;
    }

.app-download-dropdown {
    cursor: pointer;
    width: 100%;
    margin: 0;
}
.apps-and-websites-history-card-table-header {
    color: #868686;
    border: none;
}
.apps-and-websites-history-card-table-body {
    color: #333;
}
.apps-and-websites-history-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.apps-and-websites-history-card-table-body td {
    vertical-align: middle;
}
.apps-and-websites-history-card-table th, .apps-and-websites-history-card-table td {
    border: none;
}
.apps-and-websites-history-card-table-data-details-content {
    /* width: 36% !important; */
    display: inline-block;
    height: 100%;
    vertical-align: middle;

}
.whole-history-title-div{
    display :flex;
}
.app-page-content-wrapper-menu-filter-overlay-datepicker {
    display: inline-block;
    width: 260px;
    vertical-align: top;
    /* border-right: 1px solid #e8ebee; */
    margin-left: 20px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .timesheet-export-button-header{

  }

.apps-and-websites-history-card-table td, .apps-and-websites-history-card-table th, .apps-and-websites-history-card-table thead th {
    border: none;
}
.apps-and-websites-history-card-table-data-productive-status{
    text-transform: capitalize;
}
.apps-and-websites-history-card-table-data-productive{
    color:#10b9a9;
}
.apps-and-websites-history-card-table-data-unproductive{
    color: #e74c3c80;
}
.apps-and-websites-history-card-table-data-neutral{
    color:#c0901d;
}
.website-app-icon {
    /* width: 1.6rem;
    height: 1.6rem; */
    display: block;
    margin: 0 auto;
    margin-top: 8px;
    width: 25px;
    /* padding: 0.3rem; */
}
.apps-website-load-more-button-td{
    text-align: center;
}

.apps-and-websites-history-card-table-title-section{
    display:flex;
}
.app-profile-avatar {
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: #ffffff;
    border: 1px solid #ffffff;
    font-size: 27px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    margin-right: 7px;
}
.apps-and-websites-history-card-table-title{
    max-width: 30ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px;
    margin-right: auto;
}
.apps-and-websites-history-card-title{
    text-align: center;
}
.app-website-whole-div{
    display:flex;
    margin:8px 0px 0px 0px;
}
.app-website-user-header{
    display:flex;
    margin-right: 10px;
}
.count-tooltip-user-content-app {
    max-width: 90px;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.app-website-sub-div1{
    display:flex;
    margin-right: 10px;
    color: #8394ab;
}
.app-website-sub-div{
    display:flex;
    margin-right: 10px;
    color: #8394ab;
}
.app-website-sub-div1-icon{
    font-size: 14px;
    margin-top: 3px;
    margin-right: 4px;
}
.app-website-sub-div-icon{
    font-size: 13px;
    margin-top: 5px;
    margin-right: 4px;
}
.app-website-sub-div-value-hours{
    font-size: 14px;
    color: #8394ab;
}
.app-website-sub-div-value-status{
    font-size: 14px;
}
 .productive{
    color:#57a956;
}
 .neutral{
    color:#919791;
}
 .unproductive{
    color:#d2767f;
}
@media only screen and (min-width: 1170px){ 
.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 37px; 
    height: 37px;
    left: 51%;
    margin-left: -27px;
}
.vertical-timeline.vertical-timeline--two-columns {
    width: 100%;
    overflow-x: hidden;
    /* height:100vh; */
}
.vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1em;
    width: 45%;
}
}
.vertical-timeline-element-icon {
    position: absolute;
    top: 6px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 4px #fcfcfc, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
    box-shadow: 0 0 0 4px #fcfcfc, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
}
.vertical-timeline-element-content p {
    margin: 0 0 0;
    font-size: 12px;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
}
.vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -10px;
}

.apps-website-end-text{
    text-align: center;
    margin-top: 7px;
}

@media screen and (max-width: 480px) {
    .apps-and-websites-history-card-table-title{
        max-width: 15ch !important;
    }
}
