.shift-time-root {
    margin: 2rem;
}
.shift-time-card-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    grid-gap: 1.5rem;
}
.shift-time-div-wrapper {
    background-color: #fff!important;
}
.shift-time-card {
    width: 100%;
    padding: 1.3rem;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    transition: all .2s ease-out;
    
}
.shift-time-card:hover {
    transition: all .2s ease-out;
    box-shadow: 0 12px 19px rgb(38 38 38 / 20%);
}
.shift-time-name-div {
    width: 100%;
    margin-bottom: 3%;
}

.shift-time-card-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    color: #333;
    white-space: nowrap;
    width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    cursor: pointer;
}
.shift-time-card-avatar {
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
    height: 35px;
    width: 35px;
    border-radius: 37px;
    background: #e3e3e3;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 17px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    color: rgba(51,51,51,.8392156862745098);
    margin-top: 0;
    cursor: pointer;
}

.shift-time-card-percentage {
    margin-left: auto;
    margin-right: 10px;
}
.shift-time-card-percent {
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
}
.shift-time-card-percent-text {
    font-size: 10px;
    line-height: 15px;
    color: #333333;
}
.shift-time-wrapper-div {
    cursor: pointer;
}
.shift-time-card-body-text{
    font-size: 15px;
    margin-bottom: 0.9rem;
    margin-top: 0.9rem;
}

.shift-time-card-footer-text{
    font-size: 15px;
    margin-bottom: 0.5rem;
    color: #008000d1;
}
.add-shift-time-button{
    display: flex;
    margin-bottom: 2rem;
}
.shift-time-button{
    position: relative;
    margin-left: auto;
    margin-right: 0;
}

.shift-start-time-formik-add-form {
    margin-bottom: 0 !important;
}

.shift-app-time-formik-add-form {
    margin-bottom: 0 !important;
}

.shift-time-edit-label-input {
    font-size: 0.9rem !important;
}

.shift-start-time-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 430px;
    font-size: 15px !important;
    font-size: 0.9rem !important;
}
.shift-app-time-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 390px;
    font-size: 15px !important;
    font-size: 0.9rem !important;
}


.error+.shift-start-time-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.shift-start-time-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.error+.shift-app-time-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}

.shift-app-time-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.shift-home-project-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    /* width: 140px; */
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.shift-start-time-modal-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}

.shift-time-modal-accordion-whole {
    margin-bottom: 1.5rem !important;
    width: 100%;
}

.shift-time-modal-content-header-team-name-acc {
    display: flex;
    margin-bottom: 0.5rem !important;
    /* text-decoration: underline !important; */
    justify-content: space-between;
    width: 100%;
}

.shift-time-details-modals-root {
    padding: 2rem;
}
.shift-time-details-modals-header {
    display: grid;
    /* grid-template-columns: repeat(auto-fill,minmax(195px,1fr)); */
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.shift-time-details-modals-table {


}
.shift-time-card-footer-text-gray{
    color: grey;
}
.shift-time-details-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 370px;
    /* border: 0.5px solid #00000014; */
}
.modal-90w {
    width: 95%;
    max-width: none!important;
}
.shift-time-details-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    font-size: .87rem;
    font-weight: 500;
    color: rgba(0,0,0,.7098039215686275);
    /* padding: 1rem 1.3rem 1rem; */
}
.shift-time-details-employee-list-card-data-table {
    /* border-collapse: separate; */
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
    border: 0.5px solid #00000014;
}
.shift-time-details-employee-list-card-data-header {
    color: rgba(121,119,119,.8588235294117647);
    /* border: none; */
    font-size: 14px;
}
.shift-time-details-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    font-size: 15px;
    /* box-shadow: 0 0 0 0 rgb(0 0 0 / 10%), 0 1px 0 0 rgb(0 0 0 / 6%); */
    text-align: center;
}

.shift-time-details-employee-list-card-data-table th{
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.shift-time-details-employee-list-card-data-table td {
    /* border: none; */
    vertical-align: middle;
    border-top: none;
    /* border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
}

.shift-time-details-employee-list-card-details p {
    margin-bottom: 0rem;
    /* display: flex; */
    align-items: center;
    color: #212529;
    
}
.shift-time-details-employee-list-card-details p:hover {
    color: #212529;
}
.shift-time-details-employee-list-card-data-table th, .shift-time-details-employee-list-card-data-table td {
    vertical-align: middle;
}
.shift-table-text-align{
    text-align: left !important;
}

.shift-detail-early-color {
    color: #008000d1;
}
.shift-detail-late-color{
    color: #ff0000cf;
}
.shift-time-details-employee-list-card-data-table th{
    background-color: #f3f7fa!important;
    font-weight: 600!important;
    color: rgba(0,0,0,.5803921568627451);
    text-align: center;
}
.shift-time-details-employee-list-card-heading{
    font-weight: 700;
    color: rgb(0 0 0 / 78%);
    font-size: 1rem;
    
}

.shift-time-card-root-list-section-body {
    margin: 2rem 1rem 0 1rem;
    min-height: 10vh;
}

.shift-time-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 240px;
}
.shift-detail-filter-download-icon-btn{
    margin-left: 35px;
}
.shift-time-date-picker {
    position: relative;
    margin-left: auto;
    margin-right: 0;
}
.shift-time-modal-header-date-div{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
.shift-details-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.shift-details-task-details-card h4{
    font-size: 17px;
}

.shift-details-summary-card-text-ques {
    /* font-size: 1rem;
    color: #808080ba; */
    color: #789;
    font-size: 15px;
    margin-left: 3px;
}
.shift-time-card-root-list-card-loading .loading-wrapper svg{
    height: 20px !important;
}
.shift-time-filter-user {
    width: 462px;
    margin-bottom: 30px;
}
.shift-time-filter-user .rmsc {
    color:#495057 !important;
}

.shift-time-detail-card-h4-text {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.shift-detail-start-width{
    width: 120px;
}
.shift-detail-early-width { 
    width: 95px;
}
.shift-details-employee-list-card-details-avatar {
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.shift-details-card-avatar {
    height: 25px;
    width: 25px;
    background-color: #ededed;
    color: #424242;
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 25px;
}
.shift-details-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.shift-details-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.shift-time-card-root-list-card-loading .loading-wrapper{
 text-align: left !important;
}
.shift-time-details-employee-list-card-details-working-on{
    background-color: #dbfbe791 !important;
}
.shift-time-details-employee-list-card-details-stop{
    background-color: rgb(220 53 69 / 6%) !important;
}
.shift-time-create-col {
    text-align: center !important;
}
.shift-time-modal-save-button {
    font-size: 0.9rem;
}
.shift-time-delete-cancel-button {
    font-size: 0.9rem;
    margin-right: 1rem;
}
.shift-detail-modal-time-div {
    font-size: 14px;
    font-weight: 500;
    min-width: 165px;
}
.shift-detail-modal-time-div-width {
    width: 115px;
}
.shift-detail-modal-total-time-width {
    width: 100px;
}
.error+.multi-select .dropdown-container {
    border-color: #F0624D !important;
}
.shift-detail-top-performer-span{
    color: #343a40a1!important;
}
.shift-time-modal-header-date-div-text-div{
text-align: center;
}
.shift-time-modal-header-date-div-text-div-head
{
    margin-bottom: 7px;
    font-size: 15px;
    font-weight: bold;
}
.shift-time-modal-header-date-div-text-div-head-mute{
    margin-bottom: 1rem;
    font-size: 13px;
    color: gray;
}

.shift-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;

    /* height: 36px; */
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    min-width: 260px;
}
.custom-report-page-content-wrapper-menu-filter-date-text{

}
.shift-detail-miled-text{
    color: #585757;
}
.shift-modal-title.modal-title.h4 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    align-items: center;
    text-align: center;
    width: 97%;
    flex-shrink: 0;
}

@media screen and (max-width: 480px) {
    .shift-time-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
        width: 140px !important;
    }
    .shift-table-text-align{
        width:93px;
    }
}
.shift-time-card-edit-menus #dropdown-basic {
    background-color: transparent;
    /* color: transparent; */
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    left: -35px;
    /* margin-right: 1.5rem; */
}
.shift-time-card-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}
.shift-time-card-edit-menus #dropdown-basic {
    right: 0px !important;
}
.shift-time-card-edit-menus-icon {
    color: #00000094;
    /* float: right; */
    font-size: 0.9rem;
    /* margin-top: -16%; */
}
.shift-time-details-employee-list-card-details-internet-interrupt {
    background-color: #ffa50038;
}
.shift-time-export-button-header {
    margin-left: 15px;
}