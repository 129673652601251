.desktop-login {
  display: grid;
  place-items: center;
  height: 100%;
}

.login-field-error {
  border: 2px solid #ff6565;
}

.login-field-error-message {
  color: #ff6565;
  font-size: small;
  margin-bottom: 0.5rem;
}

.desktop-login-form {
  width: 300px;
}
