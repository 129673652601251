.project-report-users-button .btn-primary:hover {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
}

.project-report-users-button .btn-primary:active {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}
.daily-attendance-table-load-more-td-load-more-btn{
    text-align: center;
}
.project-report-react-table-thead-sno{
    width:1%;
}
.project-report-react-table-thead-date{
    width:11%;
}
.project-report-react-table-thead-projectName{
    width:18%;
}
.project-report-react-table-thead-taskName{
    width:18%;
}
.project-report-react-table-thead-displayName{
    width:13%;
}
.project-report-react-table-thead-totalWorkHours{
    width:10%;
}
.project-report-react-table-thead-userBillableRate{
    width:5%;
}
.project-report-react-table-thead-userLabourCost{
    width:5%;
}
.project-report-react-table-thead-profit{
    width:5%;
}

.project-report-users-button .btn-primary:focus {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}
.project-report-apply-button {
    background: #266dfb !important;
    color: #fff !important;
        position: relative;
        float: right;
}
.project-report-users-button .btn-primary {
    border: 1px solid rgb(204, 204, 204);
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    margin-right: 1.3rem;
    width:245px;
    text-align: left;
}
.project-report-users-button-date .btn-primary {
    border: 1px solid rgb(204, 204, 204);
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    margin-right: 1.3rem;
    width: 258px;
    text-align: left;
}
.project-report-team-selection-control-checkbox {
    width: 0.9rem;
    height: 0.9rem;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
}

.project-report-users-button .dropdown-toggle::after {
    float: right;
    margin: 9px auto;
}
.project-report-total-tr td{
    border :none !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.project-report-total-td{
font-weight: 600;
}
.project-report-total-hours-td{
    font-weight: 600;
}
.project-report-total-sum-text {
    font-size: 14px;
    display: flex;
    justify-content: end;
    margin-right: 80px;
}
.project-report-dropdown-menu-whole-div{
    width:280px !important;
}
.project-report-filter-user{
    display: flex;
}
.projects-report-modal-content-header-team-name {
    font-size: 0.97rem !important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 175px;
    text-align: left;
}


.project-report-calender-text{
    margin-left: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.project-report-drop-text{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.project-report-filter-root {
    margin: 0 0 34px 0;
    /* padding: 10px; */
    /* border: 1px solid #00000030; */
}
.project-report-attendance-filter-content {
    display: flex;
    align-items: center;
}
.project-reports-filter-root-div {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706);
    justify-content: space-between;
}

.project-report-add-filter{
    /* margin: 0;
    color:gray;
    font-size: 10px;
    padding: 0 0.75rem; */

    margin: 0;
    color: gray;
    font-size: 10px;
    margin-top: -8px;
    margin-left: -5px;
    margin-bottom: -3px;
}
.project-report-no-data{
    text-align: center;
}