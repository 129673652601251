.project-task-time-mapping-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin: 20px 20px 0 20px !important;
}
.project-task-time-mapping-add-card-formik {
    display: flex;
    justify-content: space-evenly;
}
.stop-task-edit-start-time .rc-time-picker-input[disabled]{
    color: #000000e3;
    background-color: #fff
}
.project-task-time-mapping-add-card-formik .form-group {
    margin-bottom: 0rem !important;
}
.project-task-time-mapping-add-card-formik-text-box {
    width: 20%;
}
.project-task-time-mapping-add-card-formik .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-formik-add-project-button {
    font-size: 15px !important;
    /* width: 100% !important; */
}
.project-task-time-mapping-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-formik-add-form {
    margin-bottom: 0 !important;
}
.project-task-time-mapping-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
input.rc-time-picker-input {
    height: 1.9rem;
    font-size: 0.76rem;
    color: #000000e3;
}
.rc-time-picker-input {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 28px;
    cursor: text;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-task-time-mapping-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.project-task-time-mapping-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 7px;
    height: 37px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-task-time-mapping-calender-icon-div {
    display: flex;
    color: black;
}
svg.project-task-time-mapping-section-calender-icon {
    font-size: 20px;
    text-align: center;
    color: #87898b;
    cursor: pointer;
}
.project-task-time-mapping-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
}
.project-task-time-mapping-project-formik-add-button {
    font-size: 15px !important;
}
.desktop-home-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.desktop-home-manual-card-table th, .desktop-home-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.desktop-home-manual-card-table-body {
    color: #333;
}
.desktop-home-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}
.desktop-home-manual-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}
.desktop-home-manual-card-table-description {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.task-tracker-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}
.desktop-home-manual-card-list-section {
    margin: 1rem;
}
.project-task-time-mapping-content-wrapper-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.project-task-time-mapping-add-card-formik-responsive {
    display: flex;
    justify-content: space-evenly;
}
.project-task-time-mapping-add-card-formik-responsive .form-group {
    margin-bottom: 0rem !important;
}
.project-task-time-mapping-formik-add-form-text-box {
    max-width: 20%;
    width: 25%;
}
.project-task-time-mapping-add-card-formik-responsive .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-add-card-formik-responsive .form-control {
    font-size: 15px !important;
}
.project-task-time-mapping-formik-add-form {
    margin-bottom: 0 !important;
}

.project-task-time-mapping-root-list-section-body {
    margin: 0rem 1rem 0 1rem;
    min-height: 100vh;
}
.project-task-time-mapping-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem !important;
}
.project-task-time-mapping-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0rem  !important;
}
.project-task-time-mapping-table th, .project-task-time-mapping-table td {
    border: none;
    vertical-align: middle;
    padding: 0.7rem 0.7rem 0.7rem 0.9rem;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.project-task-time-mapping-table-header {
    color: gray;
    border: none;
    font-size: 15px;
}
.project-task-time-mapping-table th, .project-task-time-mapping-table td {
    border: none;
    vertical-align: middle;
    padding: 0.8rem;
}
.project-task-time-mapping-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: white !important;
    /* line-height: 43px; */
}
.task-tracker-data-td-task-name.task-tracker-data-td-task-name-timeline {
    width: 532px !important;
}
.project-task-time-mapping-table-list-timeline-task-name {
    width: 496px !important;
    /* cursor: pointer; */
}
.project-task-time-mapping-task-tracker-table-description-priority-date-data {
    display: flex;
    grid-gap: 18px;
}
.project-task-time-mapping-task-tracker-table-description-priority-root {
    margin-top: 1px;
}
.project-task-time-mapping-task-tracker-table-description-glass-icon {
    color: #666464d1;
    font-size: 10px;
}
.task-tracker-edit-menus #dropdown-basic {
    top: -9px;
    padding: 0.275rem 0.65rem;
}
.task-tracker-edit-menus #dropdown-basic {
    background-color: transparent;
    color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
    right: 0px;
    /* margin-right: 1.5rem; */
}
.project-task-time-mapping-table-description {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}
.project-task-time-mapping-table-description span {
    cursor: pointer;
    color: rgba(24,24,24,.5215686274509804);
    font-size: 12px;
    letter-spacing: -.2px;
    line-height: 16px;
    margin-top: 3px;
    outline: none;
    width: 80%;
    border: none;
    resize: none;
    padding: 2px 4px;
    background-color: hsla(0,0%,75.3%,.27058823529411763);
    border-radius: 2px;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    text-transform: capitalize;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    font-size: 12px;
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
 .project-task-time-mapping-table-data-details td{
    border-top: 0.5px solid rgba(0, 0, 0, .08);
    border-bottom: 0.5px solid rgba(0, 0, 0, .08);
    border-right: none;
    border-left: none;
 }
 .project-task-time-mapping-table-data-details td:first-child{
    border-left: 0.5px solid rgba(0, 0, 0, .08);
 }
 .project-task-time-mapping-table-data-details td:last-child {
    border-right: 0.5px solid rgba(0, 0, 0, .08);
}
.project-task-time-mapping-task-tracker-table-description-priority-low {
    color: green !important;
}

.project-task-time-mapping-task-tracker-table-description-priority-medium {
    color: #eca90f !important;
}

.project-task-time-mapping-task-tracker-table-description-priority-high {
    color: #ed210e !important;
}
.project-task-time-mapping-task-tracker-table-description-priority-icon {
    color: grey;
    font-size: 11px;
    margin-top: -1px;
}
/* .project-task-time-mapping-formik-add-project-button .btn-primary{
    width: 220px !important;
}
.project-task-time-mapping-formik-add-project-button div{
    width: 350px !important;
} */
.project-task-time-mapping-formik-add-form {
    display: flex;
    justify-content: space-evenly;
}
.project-task-time-mapping-formik-add-form-text-box.form-group{
    margin-bottom: 0px !important;
}
.project-task-selection-list-name-content{
    font-size: 14px;
    color: #504f4f;
    display: -webkit-box;

}
.project-task-selection-list-task-content{
    font-size: 14px;
}
.error+.project-task-time-mapping-formik-add-form .rc-time-picker-input {
    border-color: #f0624d!important;
}
.edit-task-project-task-time-mapping-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project-task-time-mapping-modal-edit-button-root{
    margin-top: 5px;
}
.edit-task-project-task-time-mapping-formik-add-form-start-time .rc-time-picker-input,.edit-task-project-task-time-mapping-formik-add-form-end-time .rc-time-picker-input{
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
.project-task-time-mapping-modal-edit-save-button {
    margin-right: 15px;
}
.project-task-time-mapping-modal-edit-save-button,.project-task-time-mapping-modal-edit-delete-button{
    font-size: 14px;
}

.project-task-tracket-all-task-label {
    margin-top: 2rem;
    color: gray;
}
.project-task-time-mapping-edit-time {
    display: flex;
}
.edit-task-project-task-time-mapping-formik-add-form-start-time .rc-time-picker-input, .edit-task-project-task-time-mapping-formik-add-form-end-time .rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 177px;
    font-size: 15px !important;
} 
.project-task-time-mapping-formik-add-form-edit.edit-task-project-task-time-mapping-formik-add-form-start-time {
    margin-right: 14px;
}
.project-task-time-mapping-edit-task-date {
    display: flex;
    flex-direction: column;
}
.project-task-time-mapping-edit-time-div {
    margin-bottom: 15px;
}
.project-task-time-mapping-modal-edit-body{
    padding: 19px;
}
.project-task-time-mapping-formik-add-form-edit .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-modal-edit-save-button-div{
    margin: 0 auto;
}
.project-task-time-mapping-project-formik-add-button {
    height: fit-content;
}
.project-task-time-mapping-formik-add-form-start-time .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-clear,.project-task-time-mapping-formik-add-form-end-time-error .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-input {
    border: 1px solid #F0624D !important;
}
.project-task-time-mapping-formik-add-form-end-time-error .rc-time-picker-input {
    border: 1px solid #F0624D !important;
}
.project-task-time-mapping-formik-add-project-button-error .btn-primary {
    border: 1px solid red !important;
}
.project-task-time-mapping-task-tracker-table-description-priority {
    font-size: 12px;
    padding-left: 6px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
.project-task-time-mapping-task-tracker-table-description-details {
    font-size: 12px;
    /* padding-left: 6px; */
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    color: grey;
}
.project-task-time-mapping-task-tracker-table-description-details-root{
    width: 490px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project-task-time-mapping-task-list-start-end-time {
    width: 220px !important;
}
.project-task-time-mapping-task-list-tracking-status{
    background-color: #17a2b8;
    padding: 2px 6px 2px;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
    margin-left: auto;
    margin-right: 0;
}
.project-task-time-mapping-task-list-untracking-status{
    background-color: #80808075;
    padding: 2px 6px 2px;
    width: fit-content;
    border-radius: 4px;
    font-size: 13px;
    color: white;
    margin-left: auto;
    margin-right: 0;
}
.project-task-time-mapping-formik-add-project-button .dropdown-menu{
    min-width: 22rem;
}
.edit-task-project-task-time-mapping-formik-add-project-button .dropdown-menu {
    min-width: 28.7rem;
}
.project-task-time-mapping-table-task-list-start-again-icon {
    font-size: 17px;
    margin-top: -3px;
    margin-right: 5px;
}
.project-task-time-mapping-table-task-list-stop-again-button {
    padding: 6px 12px;
    border-radius: 21px;
    background-color: #e92d2ddc;
    border-color: #e92d2ddc;
    font-size: 12px;
    font-weight: 600;
}
.project-task-time-mapping-formik-add-form-edit-end-time {
    font-size: 10px;
    /* margin-left: 1rem; */
    color: red;
    width: 140px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.project-task-time-mapping-project-formik-edit-save-button {
    margin-top: 17px;
    font-size: 13px !important;
    padding: 5px 9px;
}
.project-task-time-mapping-project-formik-edit-save-button-div{
    text-align: center;
}
.project-task-time-mapping-formik-edit-form-end-time .rc-time-picker-input{
    height: 2.2rem;
    font-size: 0.86rem;
}
.project-task-time-mapping-modal-edit-end-time-header-name.modal-title.h4 {
    font-size: 19px;
    color: black;
}
.project-task-time-mapping-formik-edit-form-end-time .rc-time-picker {
    width: 100%;
}
.project-task-time-mapping-formik-edit-form-end-time .project-task-time-mapping-formik-add-form-end-time .rc-time-picker-clear{
    display: none;
}
.project-task-time-mapping-formik-edit-end-form-end-time-error {
    font-size: 12px;
     color: red;
    width: 100%;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 2px;
}
.project-task-time-mapping-project-formik-add-button-div{
    margin-top: 27px;
}
.project-task-time-mapping-formik-add-form-label{
    color: #808080b3;
    margin-bottom: 5px;
    font-size: 13px;
}
.project-task-time-mapping-formik-edit-form-end-time {
    display: flex;
    gap: 16px;
}
.project-task-time-mapping-edit-end-time-div{
    width: 40%;
}
.project-task-time-mapping-formik-edit-form-edit-end-time {
    color: red;
    font-size: 13px;
    margin-top: -5px;
}
.project-task-time-mapping-formik-add-form-select-project-task-div{
    width: 340px;
}
.project-task-time-mapping-formik-add-project-overlay{
    font-weight: bold;
}