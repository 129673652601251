.create-password {
    padding: 2rem;
}

/* create-password-header start */

.create-password-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.create-password-img-center {
    margin: 0 auto;
}
.create-password-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.create-password-title{
   font-weight: 400;
   font-size: 2rem;
}

/* create-password-header end */

/* create-password-content start */
.create-password-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 540px;
}
.create-password-form {
  display: grid;
  gap: 16px;
  width: 100%;
}
.create-password-submit-button{
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
.create-password-text{
    text-align: left;
    color: #000000ab;
    font-size: 11px;
    font-weight: 600;
}
/* .create-password-submit-button:hover{
    background-color: #035FCB;
} */
/* create-password-content end */
.on-boarding-next-step-admin-content-label-pass {
    font-size: 15px;
    font-weight: 500;
}
.on-boarding-next-step-admin-datas-pass {
    margin-bottom: 10px;
    text-align: left;
}
span.on-boarding-next-step-admin-content-label-count-pass {
    display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #333;
}
.on-boarding-next-step-admin-contents-pass {
    padding: 15px 0px 5px 0px;
}
.on-boarding-next-step-admin-headers-pass {
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecec;
    padding: 15px;
    color: #3341a7;
    font-size: 16px;
    font-weight: 700;
}


/* create-password-footer start */
a {
    color:  #6f0ca6;
    text-decoration: none;
}
/* create-password-footer end */

.create-password-reset-success-msg1 {
    font-size: 21px;
    color: #000000bf;
    font-weight: 700;
}
.create-password-reset-success-msg2{
    font-size: 14px;
}
.create-password-reset-success-msg3{
    font-weight: 600;
    font-size: 15px;
}
.create-password-field-error-message {
    color: #ff6565;
    font-size: medium;
    margin-bottom: 0.5rem;
    font-weight: 800;
}
.create-password-content-success-icon{
    width: 85px;
    height: 85px;
    color: green;
    margin: 23px;
}
.create-password-error-message-label{
    color: red;
    text-align: left;
    margin-top: -12px;
    font-size: 13px;
}
.create-password-download-file-header{
    display: flex;
    justify-content: center;
    margin: 25px 0;
}
.create-password-download-file-windows{
    margin-right: 20px;
    
}
.create-password-download-file-mac,.create-password-download-file-windows,.create-password-download-file-linux{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding: 16px 16px 0px;
}
.create-password-download-file-windows-icon,.create-password-download-file-mac-icon,.create-password-download-file-linux-icon{
    width: 40px;
    height: 40px;
    color: #000000c7;
}
.create-password-download-file-windows-text a,.create-password-download-file-mac-text a,.create-password-download-file-linux-text a{
    font-size: 13px;
    margin-top: 16px;
    color: #3341a7;
    font-weight: 600;
}
.create-password-download-file-windows-text,.create-password-download-file-mac-text,.create-password-download-file-linux-text{
    margin-top: 10px;  
}
.create-password-download-file-mac {
    margin-right: 20px;
}