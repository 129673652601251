.workfolio-thanku-root {
    margin: 9rem;
    text-align: center;
}
.workfolio-thanku-content-header {
    font-size: 22px;
    font-weight: 600;
    color: green
}
.workfolio-thanku-content-expiry {
    margin-top: 9px;
    margin-bottom: 9px;
}