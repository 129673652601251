.dashboard-root {
  padding: 2rem;
  color: #131313;
}

.dashboard-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.common-err-refresh-text{
  padding:2rem;
}
.dashboard-card {
  padding: 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-image: initial;

}

.dashboard-card-title {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1E1E1E !important;

}

.dashboard-card-text {
  font-size: x-large;
}

.dashboard-charts{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  
}
.dashboard-card-opacity{
  opacity: 0.5;
}

/* .dashboard-chart,
.dashboard-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.dashboard-chart,
.dashboard-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
} */

/* For mobile : like samsung grand(480 * 800): */
@media screen and (max-width: 480px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }

  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }

  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

/* For iphone: */
@media screen and (max-width: 320px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }

  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

/* For ipad: */
@media screen and (max-width: 768px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }


  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}
/* For ipad: pro */
@media screen and (min-height: 1366px) {
  .dashboard-chart,
  .dashboard-table {
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section {
    grid-template-columns: 1fr !important;
  }


  .dashboard-summary,
  .dashboard-charts {
    grid-template-columns: 1fr !important;
  }
  .overview-risk-listing{
    grid-template-columns: 1fr !important;
  }
  .dashboard-chart-section-top {
    grid-template-columns: 1fr !important;
  }
  .recent-apps-card-content{
    grid-template-columns: 1fr !important;
  }
}

.dashboard-chart-section,.team-work-summary-chart-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.dashboard-website-chart-section {
  height: 300px;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background-color: #f7f9fb;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboard-chart-section-top {
    height: 260px;
    overflow: auto;
    color: #4a5568;
    
    /* border-radius: 5px;
    border: 1px solid #d3d3d3; */
    
      display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  
}
.dashboard-chart-section,
.project-progress-section,
.dashboard-table-section,
.recent-screenshot-section,.team-work-summary-chart-section {
  height: 380px;
  overflow: auto;
  color: #4a5568;
  padding: 0 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-image: initial;
}

.recent-screenshot-section {
  height: 380px;
  overflow: auto;
  color: #4a5568;
  padding: 0 16px;
  background: #fff;
  /* border-radius: 5px;
  border: 1px solid #d3d3d3; */
  border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    border-image: initial;
}

.section-header {
  display: flex;
}

.head-website-list,
.head-website-chart,.head-website-lists {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 0 1rem 0;
}
.head-team-work-summary-list {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
}

.dashboard-website-list,.team-work-summary-name-list {
  width: 100%;
}
.project-progress {
  width: 100%;
}
.website-list {
  border-radius: 1.2rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
}
.website-list.productive {
  border: 1px solid #10b9a9;
  background-color: #30a59f2b;
}
.website-list.unproductive {
  border: 1px solid #e74c3c80;
  background-color: #e74c3c1c;
}
.website-list.neutral {
  border: 1px solid #c0901d;
  background-color: #c0901d45;
}
.website-list.other-website {
  border: 1px solid #29282880;
  background-color: #7373731c;
  height: 1.7rem;
  padding: 0 0.3rem;
}

.website-name.other-website {
  margin-left: 1rem;
}
.website-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  display: initial;
  margin-bottom: -6px;
  margin-top: 2px;
}
.other-website-title{
  line-height: 16px !important;
}
.website-icon {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0.3rem;
}
.website-type {
  margin-right: 0.5rem;
  padding: 0.2rem;
  float: right;
  margin-top: 0.1rem;
  font-size: 0.67rem;
  margin-left: auto;
}
.website-type-productive {
  color: #10b9a9;
}
.website-type-unproductive {
  color: #e74c3c80;
}
.website-type-neutral {
  color: #c0901d;
}
@media (min-width: 768px) {
  .section .content {
    padding: 0 15px;
    overflow-x: scroll;
  }

  .head {
    padding-left: 1.5rem;
  }

  .row > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.donutchart-innertext {
  display: none !important;
}
.website-chart,.team-summary-chart {
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 13px;
}
.website-chart{
  margin-top: -12px !important;
}
.project-name-list,.team-name-list {
  font-size: 14px;
  padding: 0.3rem 1rem 0 1rem;
  width: 100%;
  color: #0000009c;
  font-weight: 500;
}
span.project-percentage {
  float: right;
}
span.project-color,.team-color {
  background-color: green;
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
}

.project-color-success,.project-progress .progress .bg-success {
  background-color: #50B9A9 !important;
  }
.project-color-warning,.project-progress .progress .bg-warning {
  background-color: #F5EF93 !important;
 }
.project-color-danger,.project-progress .progress .bg-danger {
  background-color: #F29492 !important;
}
.project-color-primary,.project-progress .progress .bg-primary {
  background-color: #7BD8E6 !important;
}
.project-color-info,.project-progress .progress .bg-info {
  background-color: #42A2B9 !important;
}
.project-color-secondary,.project-progress .progress .bg-secondary {
  background-color: #6C757D !important;
}
.project-name-row {
  margin-top: 0.5rem;
}
.screenshots-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 1rem;
}
.overview-no-data-text {
  font-size: 14px;
  color: #1E1E1E;
  flex: 1 1;
  margin-bottom: 1rem;
  text-align: center;
}
.overview-no-data-grid{
  grid-column: 1/3;
}
.overview-no-data-grid-top-team{
  grid-column: 1/3;
  margin: 1rem 0 1rem 0;
}
.overview-screenshot-link-text {
  font-size: 14px;
  color: #13171d;
  flex: 1 1;
  margin-bottom: 1rem;
  text-decoration: underline;
  font-size: 20px;
    text-align: center;
    margin-top: 15%;
}

.screenshots-list-img {
  border-radius: 5px;
  object-fit: cover;
  max-width: 100%;
  height:70px;
  cursor: default !important;
}
.screenshot-overview-display-name {
  text-align: center;
  font-size: 14px;
  margin-top: 0.4rem;
  margin-bottom: 0;
  color:#1E1E1E;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    
}

.dashboard-hours-table {
  font-size: 14px;
  border-collapse: separate;
  /* width: 38rem; */
  color:#1E1E1E;
}
.hours-worked-td {
  width: 10rem;
  vertical-align: middle !important;
}
.hours-worked-th {
  text-align: center;
}
.hours-worked-table-progressbar {
  height: 0.5rem;
  width: 4rem;
}
.dashboard-hours-table thead tr th {
  background-color: #8080802b;
}
.dashboard-hours-table thead tr th:last-child {
  width: 3%;
}
.dashboard-hours-table thead tr th:first-child {
  width: 30%;
}
.dashboard-hours-table thead tr th:nth-child(3) { 
  width: 14%;
}
.dashboard-hours-table thead tr th:nth-child(2) { 
  width: 2%;
}
.hours-worked-table-progressbar-root {
  display: flex;
  justify-content: space-between;
}
.worked-data-td {
  background-color: #eff17dcc;
}
.hours-worked-table-worked-time {
  margin-top: -5px;
  width: 60px;
}
/* .progress-bar.bg-custom {
  background-color: #eff17dcc !important;
} */

.tableWrap {
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.dashboard-hours-table-wrap {
  /* Make sure the inner table is always as wide as needed */
  /* width: 34rem; */
  border-spacing: 0;
  overflow-x: scroll;
}

.dashboard-hours-table th {
  font-weight: 500 !important;
  padding-bottom: 12px !important;
}

.dashboard-hours-table th,
.dashboard-hours-table td {
  margin: 0;
  padding: 1rem;
  text-transform: none !important;
  color: #1E1E1E !important;
}

.sort-icon {
  color: #d4d4d4;
  float: right;
  position: relative;
  float: right;
  bottom: -2px;
}
.sort-icon.sorted {
  color: #9c9c9c;
}
.dashboard-hours-table th {
  width: fit-content;
}

.hours-worked-table-progressbar-highest .bg-custom,
.worked-data-td-highest {
  background-color: #187936f2 !important;
}
.hours-worked-table-progressbar-medium .bg-custom,
.worked-data-td-medium {
  background-color: #4ca964 !important;
}
.hours-worked-table-progressbar-average .bg-custom,
.worked-data-td-average {
  background-color: #6cd599 !important;
}
.hours-worked-table-progressbar-low .bg-custom,
.worked-data-td-low {
  background-color: #cff1dd !important;
}
.worked-data-hours-worked-td {
  width: 19%;
}
/* .worked-data-td {
  background-color: #eff17dcc;
} */


/* https://stackoverflow.com/a/39775254/10805856 */
.website-chart svg > g > g:last-child,.team-summary-chart svg > g > g:last-child {
  pointer-events: none;
}

.head-screenshot-card{
  display: flex;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: white;
}
.head-screenshot-card-link{
  color: blue;
}
.website-list-selected{
  background-color: red !important;
}
.apps-tootlip{
  width: max-content;
  padding:5px;
  pointer-events: none;
}
.apps-tootlip-time{
  font-weight: bold;
  margin-left:5px;
}
div.google-visualization-tooltip { pointer-events: none } 
.team-work-summary-name-list {
  margin-top: 40px;
}

.count-tooltip .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68 !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
  
}
.count-tooltip-stop .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
  
}
.count-tooltip-start .tooltip-inner{
  min-width:200px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
}
.app-count-tooltip-start .tooltip-inner{
  min-width:150px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
}

.count-tooltip .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: #535C68 !important;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: #535C68 !important;
}
.count-tooltip-whole{
  text-align: left;
  padding: 10px;
}
.count-tooltip-header{
  display:flex;
}
.count-tooltip-user-content{
  margin-bottom: 2px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-weight: 500;
}
.count-tooltip-user-content-index{
  margin-bottom: 2px;
  margin-right: 4px;
}
.count-tooltip-user-content-index:before {
  content: "•"; 
  color: white;
  width: 1em;
}
.count-tooltip-user-content-img{
  margin-right: 8px;
  margin-top:5px;
  border-radius:10px;
}
.count-tooltip-user-content-profile-img{
  margin-right: 8px;
  margin-top:5px;
  border-radius: 10px;
}
.summary-hover-card:hover{
  box-shadow: 1px -1px 19px rgb(38 38 38 / 20%);
}

/* .tooltip-inner {
  background-color: #b7b4b4 ;
  color: #000;
} */

.recent-apps-card-content {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
  grid-template-columns:1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.recent-apps-card {
  background-color: #28a7451a;
  border-radius: 5px;
  padding: 10px;
  color: #34495e !important;
  display: flex;
}
.recent-apps-card-load-more {
  border-radius: 5px;
  padding: 10px;
  color: #34495e !important;
  display: flex;
}
img.recent-apps-card-website-icon {
  width: 30px;
    height: 30px;
    margin-top: 6px;
}
.recent-apps-card-display-name {
  /* margin-top: 6px; */
  margin-left: 10px;
}
.recent-apps-card-display-name a{
  text-decoration: none;
}
.recent-apps-card-display-name a:hover {
  text-decoration: underline;
}
.recent-apps-card-display-name-value {
  color: #34495e !important;
  font-size: 15px;
  font-weight: 600;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recent-apps-card-display-app-name-value {
  font-size: 12px;
  color: #1e1e1eb5 !important;
  width: 160px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-apps-card-display-name-icon{
  margin-right: 11px;
}
.risk-detection-content-card {
  display: flex;
  background-color: #eceff473;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 11px;
}
.risk-detection-content-card-display-name {
  font-size: 12px;
  color: #1c73dc;
}
.risk-detection-content-card-reason {
  font-size: 14px;
}
.team-work-summary-list {
  margin-top: 16px;
}

.head-website-list-risk{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 1rem 5px 0;
}
.head-website-list-heading {
  font-size: 16px;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0;
    display: flex;
    align-items: center;
}
@media only screen and (min-width: 1024px)  {
  .head-website-list-heading {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }
  .head-website-list,
  .head-website-chart,.head-website-lists {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
  .head-team-work-summary-list {
    font-size: 1.3rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
  }
}
@media only screen and (min-width : 1224px) and (max-width : 1400px) {
  .head-website-list-heading {
    font-size: 1.2rem;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0;
      display: flex;
      align-items: center;
  }
  .head-website-list,
  .head-website-chart,.head-website-lists {
    font-size: 1.2rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
    margin: 1rem 0 1rem 0;
  }
  .head-team-work-summary-list {
    font-size: 1.2rem;
    color: #1E1E1E;
    font-weight: 500;
    flex: 1 1;
  }
}

.head-website-list-hr{
  margin: 0;
  border-top: 2px solid #eceff473;
}
.head-website-list-user-count-header{
  font-size: 22px;
  color:red;
}
.head-website-list-user-new-count-header{
  font-size: 22px;
  color:red;
}
.risk-root-content-data-details-avatar a:hover{
  /* text-decoration: none; */
  color:#212529;
}
.dashboard-hours-table a{
  color: #1E1E1E ;
}
.head-website-list-user-drop-count-header{
  font-size: 22px;
  color:green;
}
.head-website-list-user-small-header{
  font-size: 14px;
}
.overview-risk-root-content-data-details {
  margin-top: 1rem;
  padding: 1rem 0;
  /* border: 1px solid #0000000d; */
  /* box-shadow: 0 0px 0px 0.5px rgb(0 0 0 / 10%), 0 0px 0px 0 rgb(0 0 0 / 6%); */
  font-size: 0.9rem;
}
.risk-user-FaCaretUp{
  font-size: 22px;
  color: green;
}
.risk-user-FaCaretDown{
  font-size: 22px;
  color: red;
}
.risk-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.risk-root-content-data-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.risk-root-content-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.risk-root-content-table tbody tr {
  margin-bottom: 10px;
  background-color: #fff;
  /* cursor: pointer; */
}
.risk-root-content-data-details-avatar {
  display: flex;
}
.risk-card-avatar-profile{
  width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: .5rem;
    border-radius: 50%;
    /* margin-top: 8px; */
}
.overview-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.risk-root-content-data-details-name-data-team-name {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 160px;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.risk-root-change{
  text-align: center;
}
.overview-risk-root-content-data-details td{
  padding: .30rem;
}
.risk-root-content-data-details-name-data-team-name-rule-text {
  margin: 0;
  font-size: 12px;
  color: #dc3545;
}

.risk-root-content-more-css-class{
  color: #6c757dc9;
  float: right;
  margin-right: 3px;
  margin-left:2px;
}
.overview-risk-listing-content-text{
  flex:1;
}
.risk-count-tooltip-start .tooltip-inner{
  max-width:250px;
  /* max-width:fit-content; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
}
.risk-root-content-data-details-name-data-team-name-rule-text-accordance{
  font-size: 12px;
  display: flex;
  text-align: left;
  margin: 0;
}
.header{
  grid-area: header;
  height:auto;
}
.risk-root-rule-key {margin-right: 4px;}
.accordion button{
  font-size: 11px;
  color: gray;
  margin-top: -2px;
  padding: 0;
}
.risk-root-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: #f4f5f9;
  font-weight: 700;
  line-height: 28px;
  font-size: 12px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}

.accordion button:focus{
  box-shadow: none;
}
.overview-risk-root-content-data-details th{
  padding: .10rem .30rem .30rem .30rem;
  width: 235px;
}
.risk-root-change-a{
  color: #0056b3 !important;
  text-decoration: underline !important;
  cursor:pointer !important;
}
.overview-risk-factor-no-data-text{
  text-align: center;
}
.overview-risk-listing{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}
.overview-risk-listing-content{
  display:flex;
  align-items: center;
  background-color:  #f0958863;
  border-radius: 5px;
  padding: 10px;
}
.overview-risk-listing-content-violation-text-div{
  font-size: 14px;
  display:flex;
}
.overview-risk-listing-content-violation-text-span{
  display:inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 16ch;
  margin:0;
  color: #4a5568;
}
.overview-risk-listing-content-violation-number-span{
  margin-left:4px;
}

.waring-dashboard-card{ 
  /* width:auto !important;   
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
  margin-bottom: 9px; */

  /* height: 380px; */
    overflow: auto;
    color: #4a5568;
    padding:16px;
    /* background: #8ef1760f; */
    background: #d1714236;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
}

.waring-dashboard-card-pro {
  /* height: 380px; */
  overflow: auto;
  color: #4a5568;
  padding: 16px;
  /* background: #8ef1760f; */
  background: #0080002b;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.waring-dashboard-card-pro-maintain{
  overflow: auto;
  color: #4a5568;
  padding: 16px;
  background: #f8aa1b3d;
  /* background: #0080002b; */
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.warning-dashboard-card-title-3rd-text{
  margin-top: 12px;
}
.warning-dashboard-card-title {
  /* font-size: 0.87rem; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-size: 0.98rem;
  color: #1E1E1E !important;
  /* margin-bottom: 20px; */
}
.warning-card-dashboard-link {
  color: #035fcb;
}
.warning-billing-dashboard-card-title-2nd-text-new-font{
  font-size: 0.9rem;
}
.warning-card-dashboard-link-span {
  color: #035fcb;
}
.warning-dashboard-card-title-2nd-text-new-font{
  font-size: 1rem;
}
.warning-dashboard-card-title-2nd-text-new-font-weight{
  font-weight: 600;
}
.warning-dashboard-card-title-2nd-text-new{
  /* margin-bottom: 10px !important; */
}
.warning-dashboard-card-title-2nd-text{
  margin-bottom: 0px !important;
}
.warning-dashboard-card-title-bold{
  margin-bottom: 20px !important;
}
.warning-dashboard-card-title-error{
  color:red !important;
}
.warning-dashboard-card-title-example{
  margin-bottom:38px !important;
}
.waring-dashboard-summary-billing{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
    padding: 2rem 2rem 0rem 2rem;
}
.waring-dashboard-summary{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
}
.warning-dashboard-card-title-next{
  margin-bottom: 10px !important;
  color: green !important;
  font-weight: 700;
}
.warning-dashboard-card-title-next-text{
  margin-bottom: 10px !important;
}
.warning-dashboard-card-title-success{
  font-size: 1.3rem;
    margin-bottom: 0px;
}
.work-load-analysis-list {
  border-radius: 1.2rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
  border: 1px solid #80808052;
  padding: 6px 10px;
}
.work-load-analysis-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  display: initial;
}
.work-load-analysis-member {
  text-align: right;
}
.work-load-analysis-member-list {
  width: 100%;
  margin-top: 50px !important;
}
.work-load-analysis-member-chart-section-top {
  height: 260px;
  overflow: auto;
  color: #4a5568;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 1rem; */
  margin-top: 10px;
}

svg.work-load-analysis-head-title-con {
  color: lightslategray;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}
.warning-dashboard-card-title-text1 {
  /* font-weight: bold; */
}
.warning-dashboard-card-title-status {
  font-weight: 600;
  color: #8e4456;
}
.warning-dashboard-card-title-div {
  margin:9px 0px 0px;
  font-size: 15px;
}
.warning-dashboard-card-title1-div {
  margin: 4px 0px;
  font-size: 15px;
}
.warning-dashboard-card-title-status-link{
  color:#0909a9cf;
}
.warning-dashboard-card-title-text1-span{
  font-weight: bold;
  /* margin-top: 19px; */
}
.warning-dashboard-card-title-text1-value{
  font-weight: 600;
    color: #000000c9;
}
  /* 
  .overview-late-clockin-details-table-thead-tr th{
    border:none !important;
  }
  .overview-late-clockin-details-table-tbody-tr td{
    border:none !important;
  } */
.overview-late-clockin-details-table-thead-tr {
  background-color: #f9f9f9;
}
.overview-late-clockin-details-table-thead-tr {
  background-color: #f9f9f9;
  font-size: 14px;
  /* float: left; */
  /* text-align: center; */
  color: #666;
  font-size: 14.5px;
  line-height: 18px;
  cursor: pointer;
  /* width: 14%; */
  padding-bottom: 4px;
}
.overview-late-clockin-details-table-tbody-tr td {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 14.5px;
  color: #000;
}
.overview-late-clockin-details-display-name-avatar-circle {
  display: block;
  height: 23px;
  width: 23px;
  font-weight: bold;
  line-height: 23px;
  font-size: 11px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
}
.overview-late-clockin-details-table-tbody-display-name-div {
  display: flex;
  width: 165px;
}
/* .overview-late-clockin-details-display-name-text{
  margin-top: 2px;
} */

.overview-late-clockin-details-table-thead-tr:first-child,.overview-late-clockin-details-table-tbody-tr:first-child{
  width: 200px;
}
.overview-late-clockin-details-table-tbody tr td:first-child{
  background-color: white;

}
.overview-late-clockin-details-table-tbody tr td{
  font-size: 14px;
}
.overview-late-clockin-details-table-tbody tr td:first-child{
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 0px solid #dee2e6 !important;
  border-right: 0px solid #dee2e6 !important;
}

/* .overview-late-clockin-details-table thead,.overview-late-clockin-details-table td,.overview-late-clockin-details-table th{
  border-top: 2px solid white !important;
  border-bottom: 2px solid white !important;
  border-right: 2px solid white !important;
  border-left: 2px solid white !important;
} */

/* .overview-late-clockin-details-table-tbody-tr td {
  background-color: #ff000014;
} */
.overview-late-clockin-details-table-thead-tr th{
  /* background-color: hsla(0,0%,50.2%,.16862745098039217); */
  background-color: white !important;
  color: #1e1e1e9c !important;
  margin: 0;
  padding: 0.8rem;
  font-weight: 600 !important;
  font-size: 14px;
}
.overview-content-data-details-avatar-circle{
  height: 25px;
  width: 25px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.overview-absent-details-content {
  display: flex;
  align-items: center;
  background-color: #ff000014;
  border-radius: 5px;
  padding: 10px;
}
.daily-attendance-content th,.daily-attendance-content td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  /* border-right: 1px solid #dee2e6; */
}
.overview-late-clockin-details-table thead th {
  border-bottom: 0px solid #dee2e6 !important;
}
.overview-late-clockin-details-table th {
  border-top: 0px solid #dee2e6 !important;
}
.overview-late-clockin-details-table td {
  padding: 0.8rem !important;
  border-right: 0px solid #dee2e6 !important;
}

.overview-late-clockin-details-display-name-text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  display: block;
}
.overview-late-clockin-details-table-tbody-avatar-profile {
  width: 23px;
  height: 23px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.overview-late-clockin-details-table-tbody-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.overview-absent-details-table-tbody-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.overview-late-clockin-header-details {
  display: flex;
}
.overview-late-clockin-header-details-number-text{
  text-align: right;
  color: red;
  font-size: 16px;
}
.overview-late-clockin-header-details-number-sub-text{
  font-size: 14px;
  color: #ff0000bf;
}
.overview-late-clockin-details-section-top{
  margin-top: 0px !important;
}
.overview-late-clockin-details-table-load-more-btn-div{
  text-align: center;
}
.overview-late-clockin-details-table-load-more-btn {
  font-size: 14px;
  margin-top: 0.4rem;
}
.overview-late-clockin-details-table-load-more-btn-risk {
  font-size: 14px;
  margin-top: 1rem;
}
.overview-work-hour-data-load-more{
  width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.recent-apps-card-load-more-p-div {
  border-radius: 5px;
  padding: 10px;
  color: hsla(0,0%,50.2%,.5215686274509804) !important;
  display: flex;
  justify-content: center;
}
.overview-enable-expected-clockin-track-settings{
  width: fit-content;
  margin-top: 30px;
  margin: 30px 63px 44px 63px;
  justify-content: center;
  grid: none;
  grid-template-columns: 1fr !important;
  display: block;
}
.overview-enable-expected-clockin-track-settings-text {
  margin-bottom: 14px;
  font-weight: 600;
}
.overview-enable-expected-clockin-track-settings-steps-title {
  margin-bottom: 11px;
}
.overview-enable-expected-clockin-track-settings-steps-title-1 {
  margin-bottom: 1px;
  margin-left: 13px;
}
.overview-enable-expected-clockin-track-settings-steps-title-2 {
  margin-left: 13px;
}
.overview-risk-listing-content-violation-text-span:hover{
  text-decoration: underline;
}
.overview-enable-expected-clockin-track-settings-no-data {
  text-align: center;
}
.overview-enable-expected-clockin-track-settings-no-data-late-clockin{
  margin-top: 16px;
}
span.warning-dashboard-card-title-2nd-span {
  text-decoration: underline;
  color: #0000ffad;
}




.dashboard-tax-modal-title.modal-title.h4 {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  width: 90%;
  flex-shrink: 0;
}
.dashboard-tax-modal-content-row.row {
  margin-top: 8px;
}
.dashboard-tax-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.dashboard-tax-modal-content-name-field {
  flex: 1 1;
  /* margin-right: 16px; */
  margin-bottom: 0rem;
  margin-right: 16px;
  margin-bottom: 16px;
}
.dashboard-tax-modal-content-name-field {
  margin-right: 0 !important;
}
.dashboard-tax-modal-label-name {
  color: #535c68fc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -.22px;
  line-height: 22px;
  margin-bottom: 8px;
  /* display: block; */
}
.dashboard-tax-modal-label-name-required {
  color: red;
}
.dashboard-tax-edit-project-label-input {
  font-size: 0.9rem !important;
}
.dashboard-tax-modal-button-rows {
  margin-bottom: 10px;
}
.dashboard-tax-col {
  text-align: center !important;
}
.dashboard-tax-modal-button {
  margin: auto;
}
.dashboard-tax-modal-save-button {
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
}
.warning-dashboard-card-title-3rd-div {
  margin-top: 20px;
}
.overview-enable-expected-clockin-track-settings-steps-title-3 {
  margin-left: 13px;
}
.overview-enable-expected-clockin-track-settings-know-more-link {
  color: #7a7ae8;
}

.upcoming-holiday-details-section-body{
  margin-top: 0px !important;
  height: 288px;
  overflow: auto;
  /* color: #4a5568; */
 margin-top: 10px;
 /* padding: 0px 10px 10px 10px; */
}
.upcoming-holiday-details-content {
  /* display: flex; */
  align-items: center;
  border-radius: 5px;
  padding: 11px;
  border: 1px solid #00000012;
 color: #1e1e1e !important;
}
.upcoming-holiday-listing-content-text {
  flex: 1 1;
}
.upcoming-holiday-listing-content-violation-text-div {
  font-size: 14px;
  /* display: flex;
  flex-direction: column; */
}
.upcoming-holiday-listing-content-violation-text-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  margin: 0;
  color: #000000b3;
  font-size: 14px;
}
.upcoming-holiday-listing-content-violation-text-span-date {
  text-align: right;
  font-size: 12px;
  margin-bottom: -3px !important;
  color: goldenrod;
}
.upcoming-holiday-listing-content-violation-text-span-day {
  color: #00000063;
  font-size: 12px;
}
.upcoming-holiday-list{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.2rem;
  margin: 0.4rem 0rem 1.3rem 0rem;
}
.upcoming-holiday-listing-content-violation-date-span {
 margin: 0;
  /* color: #4a5568; */
}
.upcoming-holiday-listing-content-violation-date-div {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}



.upcoming-leaves-details-section-body {
  margin-top: 0px !important;
  height: 286px;
  overflow: auto;
  /* color: #4a5568; */
  margin-top: 10px;
}
/* .upcoming-leaves-list {
  display: grid;
   grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
} */
.upcoming-leaves-details-content {
  /* display: flex; */
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #00000012;
  color: #1e1e1e !important;
  /* justify-content: space-between; */
}
.upcoming-leaves-details-content-date {
  font-size: 14px;
  color: #266dfb;
}
.upcoming-leaves-details-content-to-text {
  text-align: center;
  margin: 4px 0px;
  color: gray;
}
.upcoming-leaves-details-content-leave-types {
  font-size: 14px;
}
.upcoming-leaves-details-content-leave-days{
  margin-top: 2px;
  color: #4a5568;
  font-size: 14px;
}
.upcoming-leaves-details-content-display-name {
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* padding: 10px; */
}
.upcoming-leaves-data-details-avatar-circle {
  height: 22px;
  width: 22px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 25px;
  line-height: 22px;
  font-size: 11px;
  text-align: center;
  margin-right: 0.5rem;
  border-radius: 50%;
  text-transform: uppercase;
}
.upcoming-leaves-content-text {
  flex: 1 1;
}
.upcoming-leaves-content-violation-text-div {
  font-size: 14px;
  display: flex;
}
.upcoming-leaves-content-violation-text-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  color: #4a5568;
  margin-top: -2px;
  width: 120px;
}
.upcoming-leave-details-content-violation-text-div {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-details-content-date-details {
  /* margin-top: 3px; */
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-details-content-leave-type {
  color: #00000063;
  font-size: 12px;
}
.upcoming-leaves-details-content-start-date {
  font-size: 12px;
  color: goldenrod;
}

.upcoming-holiday-details-section-date-dot{
  background-color: #5895ff;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 2px;
  margin-top: 6px;
}
.upcoming-holiday-details-section-date-content-section {
  display: flex;
  justify-content: center;
}
.upcoming-holiday-details-section-date-text {
  font-size: 14px;
  font-weight: 600;
  /* color: #00000075; */
  background-color: #266dfb2b;
  padding: 4px 13px;
  color: #266dfbbd;
  border-radius: 7px;
}
.upcoming-holiday-listing-content-holiday-details-icon {
  margin-top: -2px;
}
.upcoming-holiday-listing-content-holiday-details {
  margin-left: 15px;
  display: flex;
  margin: 0.2rem;
}
.upcoming-holiday-listing-content-holiday-details-icon {
  /* margin-top: -2px;
  margin-right: 8px; */
 
}
.upcoming-holiday-listing-content-holiday-details-icon-div {
  display: inline-block;
  margin-right: 10px;
  height: 25px;
  /* height: 39px; */
  width: 25px;
  border-radius: 40px;
  /* background: #e3e3e3; */
  /* border: 1px solid rgba(0,0,0,.1); */
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  /* color: #333; */
  background-color: #40baab;
  color: rgb(244 245 249);
}


.upcoming-leaves-details-section-date-content-section {
  display: flex;
  justify-content: center;
}
.upcoming-leaves-details-section-date-text {
  font-size: 14px;
    font-weight: 500;
    background-color: #266dfb12;
    padding: 4px 8px;
    color: #266dfb8c;
    border-radius: 7px;
}
.upcoming-leaves-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin: 0.5rem 0rem 1.2rem 0rem;
}

.upcoming-leaves-listing-content-holiday-details {
  border: 1px solid #0000002b;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.upcoming-leaves-listing-content-holiday-details-user {
  display: flex;
} 

.upcoming-leaves-listing-content-holiday-details-icon-div {
  display: inline-block;
  margin-right: 10px;
  height: 23px;
  width: 23px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  background-color: #40baab;
  color: rgb(244 245 249);
}



/* .upcoming-leaves-listing-content-holiday-details-icon {
  margin-top: -2px;
} */
.upcoming-leaves-listing-content-violation-text-span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 151px;
  margin: 0;
  color: #000000b3;
  font-size: 15px;
  /* margin-top: -1px; */
  color: #34495e !important;
  font-size: 15px;
  font-weight: 600;
}
.upcoming-leaves-details-content-user-details {
  display: flex;
}
.upcoming-leaves-details-content-leave-status {
  margin-top: 1px;
  font-size: 12px;
  text-transform: capitalize;
}
.upcoming-leaves-details-content-leave-status-pending{
  color: #f1c40f !important;
}
.upcoming-leaves-details-content-leave-status-approved{
  color: #27ae60 !important;
}
.upcoming-list-no-data{
  text-align: center;
}
.warning-dashboard-card-title-div-link {
  margin-top: 9px;
}
span.warning-dashboard-card-title-div-label {
  color: #1e1e1eba !important;
}
.warning-dashboard-card-title-div-label-end{
  margin-left: 15px;
}
.warning-dashboard-card-title-div-link-tag{
  margin-left: 6px;
  color: #125eede0;
}


.waring-dashboard-timezone-summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 25px;
}
.waring-dashboard-timezone-card-pro {
  overflow: auto;
  color: #4a5568;
  padding: 17px;
  background: #DFE6E9;
  border-radius: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  -o-border-image: initial;
  border-image: initial;
}
.warning-dashboard-timezone-card-title {
  font-size: 0.98rem;
  color: #1E1E1E !important;
  
}
.warning-dashboard-timezone-card-title-2nd-text-new-font {
  font-size: 0.89rem;
  /* font-weight: 500; */
}
.warning-dashboard-timezone-card-text-sub{
  font-weight: 600;
}
.warning-dashboard-timezone-card-icon {
  margin-top: -2px;
  margin-right: 6px;
  color: #1e1e1ec7;
}
.warning-dashboard-timezone-card-text-help{
  color:blue
}