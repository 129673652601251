.Manual-time-entries-root {
    padding: 2rem;
}
.Manual-time-entries-root-header{
    display: flex;
}
.Manual-time-entries-root-header-name{
    font-size: 19px;
    font-weight: 600;
    color: rgba(0,0,0,.6705882352941176);
}
.Manual-time-entries-root-header-button {
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    margin-left: auto;
    margin-right: 0px;
}
.Manual-time-entries-root-date-filter{
    font-size: 18px;
    margin-top: 20px;
}
.Manual-time-entries-root-body-listing-content {
    display: flex;
    align-items: center;
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    padding: 15px 15px 15px 15px;
    margin-top: 10px;
}
.Manual-time-entries-root-body-listing-content-status{
    padding: 6px 10px;
    text-transform: capitalize;
}
.Manual-time-entries-root-body-listing-content-text-delete{
    color :red;
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;
    padding-left: 5px;
}
/* .manual-timer-modal-body .react-datepicker-wrapper {
    width:30%;
} */
.manual-page-content-wrapper-menu-filter-date-date-section {
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    width:85%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 2%;
}
.Manual-time-entries-root-body-listing-content-text{
    margin-left: 1rem;
    font-size: 17px;
}
/* risk Modal CSS */
.manual-timer-modal .modal-dialog {
    max-width: 56%;
}
.manual-timer-modal-header .close {
    font-size: 1.2rem;
    margin-top: -3%;
}
.manual-timer-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: #000000a8;
}
.manual-timer-modal-body-formik{
    display: flex;
}
.manual-time-hour{
    width: 13%;
    margin-left: 2%;
    margin-right: 2%;
}
.manual-page-content-wrapper-menu-filter-date {
    /* display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease; */
    display: flex;
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 2%;
}
.manual-time-add-button{
    margin-bottom: 1rem;
}
.manual-time-modal-list{
    margin-top:1rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 280px);
}