.workfolio-integration-content-root {
    margin: 2rem;
}
.workfolio-integration-content-root-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
}
.workfolio-integration-content-root-card-integration-icon img {
    /* width: 40px; */
    height: 28px;
}
.workfolio-integration-content-root-card-content {
    border: 0.5px solid #00000012;
    border-radius: 6px;
    /* display: flex; */
}
.workfolio-integration-content-root-card-integration-name {
    font-size: 22px;
    /* margin-top: 8px; */
    margin-left: 13px;
}
.workfolio-integration-content-root-card-integration-icon {
    text-align: center;
    /* margin: 20px; */
}
.workfolio-integration-content-root-card-integration-submit-root {
    margin: 25px 20px;
    text-align: center;
}
.workfolio-integration-content-root-card-integration-connect-button {
    padding: 4px 30px;
}
.workfolio-integration-content-root-card-integration-type {
    margin: 20px;
    /* margin-left: 20px; */
    /* display: flex; */
}
.workfolio-integration-content-root-card-integration-checkbox{
    text-align: left !important;
    color: #2c2b2bc9;
}
.workfolio-integration-content-root-card-integration-submit-connected {
    margin: 16px 24px;
}
.workfolio-integration-content-root-card-integration-checkbox-input label{
    margin-left: 8px;
}
.workfolio-integration-content-root-card-integration-save-button {
    margin-top: 10px;
    padding: 2px 15px;
    font-size: 15px;
}
.workfolio-integration-content-root-card-disconnect {
    font-size: 12px;
    color: red;
    margin-top: -7px;
    cursor: pointer;
}
.workfolio-integration-content-root-card-integration-name {
    display: flex;
    flex-direction: column;
}
.workfolio-integration-content-root-card-disconnect:hover{
    text-decoration: underline;
}

.workfolio-integration-content-root-card-integration-submit-connected {
    margin: -8px 24px 21px 24px;
}

.workfolio-integration-content-root-card-integration-disconnect {
    text-align: center;
    font-size: 12px;
    color: #ff000078;
    margin-top: 3px;
    cursor: pointer;
}

.workfolio-integration-content-root-card-integration-disconnect:hover {
    color: red;
    text-decoration: underline;
}