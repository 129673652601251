.report-app-usage-root {
    margin:2rem;
}
.app-usage-report-filter-root {
    margin: 0 0 34px 0;
    padding: 10px;
}
.app-usage-reports-filter-root-div {
    display: flex;
    justify-content: space-between;
}
.app-usage-report-filter-user{
    display: flex;
}
.app-site-report-align {
text-align: center !important;
}
.review-usage-root-listing-card-content-avatar-report {
    height: 20px;
    width: 20px;
    text-align: center !important;
    /* vertical-align: middle !important; */
}
.app-usage-report-users-button .btn-primary:hover {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
}

.app-usage-report-users-button .btn-primary:active {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}

.app-usage-report-users-button .btn-primary:focus {
    color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important;
}

.app-usage-report-users-button .btn-primary {
    border: none;
    color: #007bff;
    background-color: rgba(38,109,251,.06274509803921569);
    margin-right: 1.5rem;
}

.apps-usage-users-button .btn-primary {
    border: none;
    /* color: #007bff;
    background-color: rgba(38,109,251,.06274509803921569); */
    margin-right: 1.5rem;

    width: 280px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}
.project-report-apply-button {
    width:auto !important;
}
.apps-usage-users-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}
.daily-attendance-filter-download-icon-btn-disable {
    cursor: not-allowed;
}

.apps-usage-users-button .btn-primary:hover {
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.apps-usage-users-button .btn-primary:active {
    
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important; */
    /* border-color: none !important;
    box-shadow: none !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.apps-usage-users-button .btn-primary:focus {
    /* color: #007bff !important;
    background-color: rgba(38,109,251,.06274509803921569) !important;
    border-color: none !important;
    box-shadow: none !important; */

    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.app-usage-datas-thead-displayName{
    width:200px;
     max-width:200px; 
}
.app-usage-datas-thead-title{
    width:400px;
    max-width:500px;
    overflow: overlay;
}
.app-usage-datas-thead-productivityStatus{
    width:200px;
    max-width:200px;
}
.app-usage-datas-thead-totalSec{
    width:200px;
    max-width:200px;
}
.app-usage-datas-thead-sort-icon {
    font-size: 15px;
}