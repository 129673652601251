.project-settings-card {
    padding: 2rem;
}
.project-settings-row{
  display: initial !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.project-settings-row > * {
    margin-bottom: 1rem;
}
.project-settings-row-grid, .project-settings-row-grid-1, .project-settings-row-grid-2 {
    /* align-items: start; */
    /* gap: 30px; */
    -moz-columns: 2 calc((100vw - 420px - 8rem) / 2);
    columns: 2 calc((100vw - 420px - 8rem) / 2);
    display: grid !important;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    padding-left: 0px !important;
    padding-right: 0px !important;
  
}
.project-settings-row-grid-card {
    /* height: auto; */
    display: inline-block;
    margin-bottom: 2rem;
    /* width: calc((100vw - 200px - 6rem) /2); */
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 20px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.project-settings-row-grid-card h6 {
    color: black;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}
.project-settings-radio-button-card label {
    margin-right: 16px;
}
/* .project-settings-project-management-card-root {
    margin-bottom: 22px;
} */
.project-settings-radio-button-card label input {
  margin-right: 4px;
}
.project-settings-radio-button-card.form-group {
  margin-bottom: 0rem !important;
}
.project-settings-project-management-new-project-card-root,.project-settings-project-management-enable-card-root{
  margin-bottom: 1rem;
}










.my-projects-project-settings-modal-root .modal-dialog .modal-content{
    padding: 0 16px;
    border-radius: 12px;
  }
  .my-projects-project-settings-modal-header{
    /* border-bottom: none !important; */
  }
  .my-projects-project-settings-modal-title.modal-title.h4 {
    /* font-size: 1.2rem; */
    /* font-size: 1.2rem; */
    color: #333333;
    /* font-family: "Helvetica Now Display"; */
    font-size: 20px;
    font-weight: 600;
    /* letter-spacing: .35px; */
    text-align: center;
    /* line-height: 32px; */
    margin: 0;
    /* display: flex; */
    align-items: center;
    text-align: center;
    width: 90%;
    flex-shrink: 0;
  }
  .my-projects-project-settings-modal-content-row.row {
    margin-top: 8px;
  }
  .project-settings-task-modal-private-toggle-div {
    display: flex;
    margin-bottom: 1.5rem !important;
    justify-content: space-between;
    width: 100%;
  }
  .project-settings-project-tasks-modal-content-header-setting-icon {
    font-size: 0.75rem;
    margin-top: -2px;
  }
  
  .project-settings-task-modal-private-toggle-div-command-line {
    color: #8c94a1;
    font-size: 13px;
    letter-spacing: -.2px;
    line-height: 17px;
    max-width: 385px;
    padding-top: 7px;
  }
  
  .project-settings-tasks-rule-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
  }
  
  .project-settings-toggle-switch label {
    cursor: pointer;
    margin-bottom: 0 !important;
  }
  
  .project-settings-toggle-switch label .project-settings-toggle-track {
    display: block;
    height: 20px;
    width: 35px;
    background: #e1e4e8;
    border-radius: 20px;
    position: relative;
    /* margin-bottom:15px; */
    border: 1px solid #ccc;
    transition: .4s;
  }
  
  .project-settings-toggle-track-nav-right-div {
    background: #7dd46e !important;
  }
  
  .project-settings-toggle-track-nav-right-div:before {
    transform: translateX(15px) !important;
  }
  
  .project-settings-toggle-switch .project-settings-toggle-track:before {
    content: '';
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: right .2s ease-in;
    left: 0px;
    bottom: 2px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
    transition: .4s;
  }
  
  .project-settings-tasks-modal-content-person-name-header-section-label {
    line-height: 18px;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
  }
  
  .project-settings-tasks-modal-content-person-name {
    overflow: auto;
    /* height: 100%; */
    flex-grow: 1;
  }
  
  .project-settings-tasks-modal-content-person-name-header {
    /* display: flex; */
    margin-bottom: 11px;
  }
  
  .project-settings-tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 1.5rem;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
  }
  .project-settings-my-projects-modal-content-person-name-header-section {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
  }
  .project-settings-my-projects-modal-content-person-name-header-section-label {
    /* font-size: 1rem;
    line-height: 18px;
    margin-bottom: 0.5rem;
    color: #62839b; */
    color: #535c68fc;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .project-settings-my-projects-modal-content-person-name-header-section-content {
    display: flex;
  }
  .project-settings-project-task-modals-searchbox-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .project-settings-project-task-modals-user-label-section-div {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #edeef2;
  }
  .project-settings-project-task-modals-user-label-section {
    height: 32px;
    color: #8c94a1;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .55px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* box-shadow: inset 0 -1px 0 0 #edeef2; */
    text-transform: uppercase;
    width: 265px;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
  }
  
  .project-settings-tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
  }
  .project-settings-tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 0px 10px 10px 10px;
    margin-bottom: 1.5rem;
  }
  
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  
  .project-settings-project-task-modals-content-person-name-listing-card-content {
    /* margin-bottom: 2px; */
    display: flex;
    margin-top: 10px;
  }
  .project-settings-tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
  }
  .project-settings-task-card-avatar-profile {
    width: 22px;
    height: 22px;
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .project-settings-task-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
  }
  .project-settings-tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
  }
  .project-settings-my-projects-modal-content-header-team-name {
    font-size: 0.97rem !important;
    line-height: 18px;
    color: #0d66a7;
    overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 355px;
      text-align: left;
  }
  .project-settings-my-projects-modal-content-header-team-name:hover {
    color: #62839b;
    /* text-decoration: underline !important; */
  }
  .project-settings-my-projects-modal-content-header-team-name-acc{
    display: flex;
    margin-top: 4px !important;
  }
  .project-settings-add-project-modal-button-rows {
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .project-settings-my-projects-modal-button {
    margin: auto;
    text-align: center;
  }
  .project-settings-my-projects-modal-save-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
  }
  .project-settings-project-task-modals-searchbox-wrapper-input.form-control {
    font-size: 14px;
  }
  .my-project-summary-enable-project-management-enable-btn{
      margin-top: 43px;
      font-weight: 600;
  }
  .project-settings-project-management-card-title {
    /* font-size: 15px; */
    color: #000000ba;
  }
.project-settings-radio-button-card label {
  margin-right: 20px;
  /* font-size: 14px; */
  margin-top: 5px;
}
.project-settings-project-task-modals-searchbox-wrapper-input{
  flex-grow: 1;
  flex-basis: 50%;
}
.project-settings-tasks-modal-content-person-name-header-button{
  margin-left: 23px;
  flex-basis: 50%;
  cursor: pointer;
}
