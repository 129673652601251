.root-layout {
    display: grid;
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
    /* grid-template-columns: 0px minmax(0, 1fr); */
    grid-template-columns: 217px minmax(0, 1fr);
}

.root-layout-mobile-with-menu {
    grid-template-columns: 185px minmax(0, 1fr);
}

.root-layout-mobile-without-menu {
    grid-template-columns: 1fr !important;
}

.root-layout-menu {
    height: 100vh;
}

.sidebar-wrapper-user-profile {
    text-align: center;
    background-color: #fcfcfc;
    /* width: 210px;
  border-right: 2px solid #ededed; */
}

.root-layout-page {
    height: 100vh;
    background-color: #fcfcfc;
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    grid-template-rows: 55px minmax(0, 1fr);
}

.root-layout.desktop,
.root-layout.desktop .root-layout-page,
.root-layout.desktop .root-layout-menu {
    height: calc(100vh - 32px);
}

.root-layout-page .nav-link {
    background-color: #fff;
    border: none;
    color: #6c757d;
}

.root-layout-page .nav-link.active {
    color: #007bff;
    border-bottom: 4px solid #007bff;
}

.sidebar-wrapper-content {
    border-right: 2px solid #ededed;
    height: calc(100vh - 55px);
    padding: 8px 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fcfcfc;
}

.sidebar-wrapper-content-trial {
    height: calc(100vh - 100px) !important;
}

.sidebar-wrapper-content.has-footer {
    height: calc(100vh - 55px - 94px);
}

.root-layout.desktop .sidebar-wrapper-content {
    height: calc(100vh - 55px - 32px);
}

.root-layout.desktop .sidebar-wrapper-content.has-footer {
    height: calc(100vh - 55px - 94px - 32px);
}

.sidebar-wrapper-content-parent:not(:last-child) {
    margin-bottom: 8px;
}

.sidebar-wrapper-content-parent-name {
    font-size: smaller;
    padding: 5px 13px 0;
    opacity: 0.5;
}

.sidebar-wrapper-content-navbar-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    transition: background-color 100ms ease;
    cursor: pointer;
    text-decoration: none !important;
    color: #333 !important;
    border-left: 4px solid transparent;
}

.sidebar-wrapper-content-navbar-list:hover {
    background-color: #266dfb10;
}

.sidebar-wrapper-content-navbar-list.selected {
    background-color: #266dfb10;
    border-color: #266dfb;
    padding: 9px 12px;
}

.sidebar-wrapper-content-navbar-list-item-icon {
    margin-left: 0px;
    margin-right: 8px;
    font-size: 18px;
    opacity: 0.7;
}

.sidebar-wrapper-content-navbar-list-item-icon-Videos {
    font-size: 19px !important;
}

.sidebar-wrapper-content-navbar-list-item-name {
    font-size: 15.5px;
    text-decoration: none;
    margin: 0;
    margin-right: 1px;
}

.sidebar-wrapper-content-navbar-list.selected .sidebar-wrapper-content-navbar-list-item-name,
.sidebar-wrapper-content-navbar-list.selected .sidebar-wrapper-content-navbar-list-item-icon {
    opacity: 1;
    color: #266dfb;
    font-weight: 600;
}

.sidebar-wrapper-header>* {
    height: 55px;
    line-height: 55px;
    font-weight: 600;
    padding: 0 16px;
    margin: 0;
}

.sidebar-wrapper-header-logo {
    color: #266dfb !important;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none !important;
}

.organisation-info-profile-images {
    display: inline;
    margin: 0 auto;
    height: 90%;
    width: auto;
    margin-top: -13px;
}

.sidebar-wrapper-header,
.page-content-wrapper-header {
    border-bottom: 1px solid #ededed;
    background-color: #fcfcfc;
    position: sticky;
    top: 0;
    width: 100%;
    height: 55px;
    z-index: 60;
}

.page-content-wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    /* display: grid;
  grid-template-columns: 1fr 1fr; */
}

.page-content-wrapper-header-name-section>*:not(:last-child) {
    margin-right: 15px;
}

.sidemenu-header-comman-div>*:not(:last-child) {
    margin-right: 15px;
}

.page-content-wrapper-header-name {
    flex: 1;
    line-height: 55px;
    font-weight: 600;
    margin: 0;
}


/* sidebar-wrapper-user-profile css div start */

.sidebar-wrapper-user-profile .rc-menu {
    /* display: none; */
    box-shadow: none;
    border: none;
}

.user-profile-submenu {
    z-index: 50;
}

.user-profile-submenu.rc-menu-submenu>.rc-menu {
    background-color: #fcfcfc;
}

.user-profile-submenu-item {
    padding: 8px 12px !important;
    position: initial !important;
}

.user-profile-submenu-item a {
    color: #035FCB !important;
}

.user-profile-submenu-item:hover {
    background: #266dfb05;
}

.user-profile-submenu-item>a {
    text-decoration: none !important;
}

.sidebar-wrapper-user-profile .rc-menu-item-active,
.sidebar-wrapper-user-profile .rc-menu-submenu-selected,
.sidebar-wrapper-user-profile .rc-menu-submenu-active>.rc-menu-submenu-title {
    background-color: #fcfcfc;
}

.sidebar-wrapper-user-profile .rc-menu-submenu-title {
    padding: 0px 0px !important;
}

.sidebar-wrapper-user-profile .rc-menu-submenu-arrow {
    display: none !important;
}

.sidebar-wrapper-user-profile-avatar {
    background-color: #266dfb;
    height: 29px;
    width: 29px;
    text-align: center;
    border-radius: 40px;
    margin: 0 auto;
    line-height: 29px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.sidebar-wrapper-user-profile-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #333;
    margin: 6px 0 0;
}

.sidebar-wrapper-user-profile-dropdown-item-button {
    position: absolute;
    z-index: 0;
    top: 25px;
    cursor: pointer;
}

.opacitiy-remove {
    position: unset !important;
    z-index: 0;
    top: 25px !important;
}

.opacitiy-remove>button:first-child {
    opacity: 1 !important;
}

.sidebar-wrapper-user-profile-dropdown-item-button>button:first-child {
    opacity: 0;
}

.sidebar-wrapper-user-profile-dropdown-item-button .dropdown-toggle {
    color: #266dfb;
    box-shadow: none !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.sidebar-wrapper-user-profile-dropdown-item-button .dropdown-toggle:hover {
    color: #266dfb;
}


/* sidebar-wrapper-user-profile css div end */

.page-content-wrapper-menu-filter-overlay-trigger {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-color: #00000017;
    display: block;
}

.page-content-wrapper-menu-filter-overlay-trigger .arrow::before {
    border-bottom-color: #00000017;
}

.page-content-wrapper-menu-filter-overlay-trigger {
    max-width: fit-content;
}

.page-content-wrapper-menu-filter-overlay-trigger .popover-body {
    overflow: hidden;
}

.page-content-wrapper-menu-filter-date~.dropdown-menu .dropdown-item {
    color: rgba(0, 0, 0, 0.75);
    font-weight: 400;
}

.page-content-wrapper-menu-filter-overlay {
    display: flex;
}

.page-content-wrapper-menu-filter-overlay-button {
    margin-right: 1.7rem;
    position: relative;
    float: right;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.page-content-wrapper-menu-filter-overlay-button-cancel {
    font-weight: 700;
    font-size: 14px;
    margin-right: 16px;
    color: #266dfb;
    cursor: pointer;
    padding: 11px 16px;
    line-height: 14px;
    border-radius: 5px;
    border: 1px solid #266dfb;
    transition-property: background;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

.page-content-wrapper-menu-filter-overlay-button-apply {
    font-weight: 700;
    font-size: 14px;
    /* margin-right: 16px; */
    cursor: pointer;
    padding: 11px 16px;
    line-height: 14px;
    border-radius: 5px;
    border: 1px solid #266dfb;
    transition-property: background;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    background: #266dfb;
    color: #fff;
}

.page-content-wrapper-menu-filter-overlay-datepicker {
    display: inline-block;
    width: 275px;
    vertical-align: top;
    border-right: 1px solid #e8ebee;
    margin-left: 20px;
    margin-top: 24px;
}

.page-content-wrapper-menu-filter-overlay-list {
    display: inline-block;
    width: 245px;
    margin-left: 20px;
    margin-top: 24px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-left: 15px;
    margin-top: 5px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier p {
    display: block;
    font-size: 14px;
    line-height: 14px;
    color: #34485e;
    font-weight: 600;
    margin-bottom: 20px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier-listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;
}

.page-content-wrapper-menu-filter-overlay-list-contanier-listing-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 29px;
    background: #f0f0f0;
    color: #718093;
    box-shadow: unset;
    border-radius: 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    margin: 4px;
    border: 1px solid #b1ade1;
}

button:focus {
    outline: none !important;
}

.filter-menu-active {
    color: #266dfb;
    border: 1px solid #266dfb;
    background: #e7e5fc;
}

.page-content-wrapper-menu-filter-date-icon {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    height: 15px;
    margin: 0 5px;
    /* opacity: 0.8; */
    font-size: medium !important; 
}

.page-content-wrapper-menu-filter-dropdown-icon {
    font-size: medium;
    margin-left: 8px;
    margin-top: 2px;
}

#date-filter {
    display: none;
}

.page-content-wrapper-menu-filter-date-text {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}

.page-content-wrapper-menu-filter-date-text-desktop {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #ededed;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
}

.page-content-wrapper-menu-filter-date:hover {
    background-color: #266dfb10;
    color: #266dfb;
}

.sidemenu-header-comman-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.page-content-wrapper-header-name-section {
    display: flex;
    align-items: center;
}

@media (min-width: 1024px) {
    .root-layout {
        /* grid-template-columns: 217px minmax(0, 1fr); */
    }
    .root-layout.has-second-menu {
        grid-template-columns: 217px 217px minmax(0, 1fr);
    }
    .sidebar-wrapper-user-profile {
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
    .sidebar-wrapper-user-profile .rc-menu {
        display: block;
        padding: 3px 0;
        width: fit-content;
        z-index: 50;
        margin: 0 auto;
    }
    .sidebar-wrapper-user-profile-avatar:hover {
        border: 1px solid #ededed !important;
        background-color: #dae5f9f0 !important;
        color: #266dfb !important;
        line-height: 26px;
        font-size: 17px;
    }
    .rc-menu-submenu-placement-leftTop {
        top: 45px !important;
        /* left: 1270px !important; 
    right:10px !important; */
        left: auto !important;
        right: 0 !important;
        z-index: 1000;
    }
    #date-filter {
        display: flex;
    }
}

@media screen and (width: 411px) {
    .page-content-wrapper-header-name {
        width: 72px !important;
    }
}

@media screen and (max-width: 480px) {
    .page-content-wrapper-header-name-section>*:not(:last-child) {
        margin-right: 9px;
    }
    #date-filter {
        display: flex;
    }
    .page-content-wrapper-menu-filter-date-text {
        display: none;
    }
    .page-content-wrapper-menu-filter-overlay {
        display: initial;
    }
    .page-content-wrapper-menu-filter-overlay-datepicker {
        border-right: 0px solid #e8ebee;
        margin-left: 35px;
    }
    .page-content-wrapper-menu-filter-overlay-list {
        margin-left: 20px;
        margin-top: 12px;
    }
    .page-content-wrapper-menu-filter-date {
        padding: 4px;
    }
    .page-content-wrapper-menu-filter-overlay-button {
        float: left;
        margin-left: 70px;
        margin-top: 25px;
    }
    .page-content-wrapper-menu-filter-overlay-list-contanier p {
        text-align: center;
    }
    .page-content-wrapper-menu-filter-overlay-list-contanier-listing {
        justify-content: space-around;
    }
    .page-content-wrapper-menu-filter-overlay-trigger .popover-body {
        width: min-content;
    }
    .sidemenu-header-comman-div>*:not(:last-child) {
        margin-right: 9px;
    }
    .page-content-wrapper-header {
        justify-content: space-between;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .sidemenu-header-fixes {
        float: right;
        display: flex;
        align-items: center;
    }
    .page-content-wrapper-header-name {
        flex: 1;
        font-size: 15px;
        width: 55px;
        /* min-width:55px; */
        /* max-width:60%; */
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .page-content-wrapper-menu-add-user-icon {
        margin-right: 3px !important;
        margin-left: 1px;
        /* margin-top: 1px !important; */
    }
    .page-content-wrapper-menu-add-user {
        padding-left: 0 !important;
        padding-right: 0!important;
        height: 27px;
        width: 60px;
        /* text-align: center; */
        /* line-height: 19px; */
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-top: 4px; */
    }
    .page-content-wrapper-menu-filter-date-text {
        width: 7ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .page-content-wrapper-menu-search-bar {
        display: none !important;
    }
    .second-sidebar-searchbox-wrapper-root {
        width: 166px !important;
    }
    .second-sidebar-searchbox {
        font-size: 0.8rem !important;
    }
    .page-content-wrapper-menu-add-user {
        font-weight: 700;
        font-size: 10px !important;
    }
}

.page-content-wrapper-menu-search-bar {
    /* width: 32% !important; */
}

.page-content-wrapper-menu-search-bar div input {
    /* height: 10% !important;
  border-radius: 24px;
  border: 1px solid #ededed; */
    height: 32px !important;
    border-radius: 24px;
    border: 1px solid #ededed;
    font-size: 15px !important;
}

.sidebar-wrapper-content-navbar-list-item-name-review-badge {
    background-color: #fa3e3e;
    border-radius: 5px;
    color: white;
    font-weight: bolder;
    padding: 0px 3px;
    font-size: 14px;
    margin-left: 3px;
}

.page-content-wrapper-menu-add-user {
    font-weight: 700;
    font-size: 14px;
}

.page-content-wrapper-menu-add-user-icon {
    margin-top: -2px;
    margin-right: 7px;
}

.workfolio-react-tour {
    border-radius: 3px;
}


/* 
.sidebar-wrapper-content-parent:empty{
  display:none;
}
.sidebar-wrapper-content-parent{
  visibility:hidden;
}
.sidebar-wrapper-content-parent .sidebar-wrapper-content-navbar-list{
  visibility:visible;
} */

.sidebar-wrapper-user-profile-avatar {
    text-transform: capitalize;
}

.header-menu-card-avatar-profile {
    width: 29px;
    height: 29px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
}

.header-menu-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper-content-trial-content {
    font-size: 13px;
    background-color: #f756561a;
    padding: 11px 15px;
    margin: 9px;
    border-radius: 3px;
    text-align: center;
    bottom: 0;
    position: absolute;
}

.sidebar-wrapper-content-trial-content label {
    color: #de0f0f;
    margin-bottom: 0rem;
    font-weight: 500;
    position: relative;
}

.workfolio-release-notes {
    display: flex;
}

.workfolio-release-notes-label {
    margin-top: 5px;
    font-size: 15px;
    cursor: pointer;
}

.workfolio-release-notes-label:hover {
    text-decoration: underline !important;
}

.page-content-wrapper-header-name-sup {
    font-size: 12px;
    font-weight: normal;
}
.page-content-wrapper-header-name-sup-project {
    font-size: smaller;
    font-weight: normal;
    padding-left: 3px;
    opacity: 1;
    color:red;
}

.review-apps-sidemenu-count {
    font-size: 15.5px;
    color: #ff0000b5;
    /* color:red; */
    margin-top: 3px;
}

.workfolio-header-page-content-wrapper-menu-filter-team-menu {
    max-height: 526px;
    overflow-y: scroll;
}

.app-reload-button {
    opacity: 0;
    animation: fadeIn 3s;
    animation-delay: 80s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.filter-menu-disabled {
    cursor: default !important;
    color: #80808099 !important;
}
.add-user-disabled-div{
    display: inline-block;
   color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 700;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
}
.page-content-wrapper-menu-filter-user-group-icon {
    display: flex;
    margin: -2px 5px 0px 5px;
    font-size: small !important;
}
.sidebar-wrapper-content-navbar-list-item-icon-over-time{
    color: gray;
}