.review-usage-root-app-listing-unselect-all-div {
    margin: 0 0 1rem;
    font-weight: 500;
    color: #646161;
    font-size: 15px;
}
.review-usage-root-app-listing-unselect-all-checkbox-label{
    margin-top: 1px;
    margin-left: 5px;
}
.review-usage-root-app-listing-unselect-all-checkbox,.review-usage-root-app-listing-unselect-all-checkbox-label{
    cursor: pointer;
}
.app-summary-usage-load-more-button{
    padding: 5px 14px;
    font-size: 12px;
    margin-left: 33px;
}
.app-summary-apps-load-more-button {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}
.app-summary-user-no-data{
    color:gray;
    text-align: center;
    font-size: 14px;
}