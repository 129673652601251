.personal-dashboard {
  padding: 25px;
}

.pd-stats-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
}

.pd-sg-user-details {
  padding: 16px;
  border-radius: 5px;
}

.pd-sg-user-details .header {
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.pd-sg-user-details .content {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}

.pd-sg-item {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-sg-item .header {
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pd-sg-item .header img {
  max-width: 80%;
}

.pd-sg-item .content {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  margin: 0;
}

.pd-sg-item .content.disabled {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.pd-sg-item.good .content {
  color: #54985a;
}

.pd-app-usage {
  display: grid;
  margin: 30px 0;
}

.pd-app-usage > * {
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-tal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.pd-tal-header > * {
  margin: 0;
}

.pd-tal-content {
  display: grid;
  gap: 10px;
}

.pd-tal-content.disabled {
  place-items: center;
  height: 100%;
}

.pd-tal-app {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.35rem 1rem;
  color: rgba(0, 0, 0, 0.8);
}

.pd-tal-app.productive {
  border: 1px solid #2ecc71;
  background-color: #2ecc7115;
}

.pd-tal-app.unproductive {
  border: 1px solid #e74c3c;
  background-color: #e74c3c15;
}

.pd-tal-app.neutral {
  border: 1px solid #bdc3c7;
  background-color: #bdc3c715;
}

.pd-tal-app-icon {
  width: 20px;
  object-fit: contain;
  margin-right: 7px;
}

.pd-tal-app-name {
  flex: 1;
  margin: 0;
  font-size: 0.95rem;
  font-weight: 500;
  margin-right: 7px;
  max-width: 264px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pd-tal-app-status {
  font-size: smaller;
  text-transform: capitalize;
  width: 90px;
  text-align: left;
}

.pd-tal-app.productive .pd-tal-app-status {
  color: #2ecc71;
}

.pd-tal-app.unproductive .pd-tal-app-status {
  color: #e74c3c;
}

.pd-tal-app.neutral .pd-tal-app-status {
  color: #bdc3c7;
}

.pd-work-chart {
  height: 460px;
  padding: 16px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pd-top-apps-chart {
  height: 513px;
}

.pd-stats {
  margin-bottom: 30px;
}

.pd-chart-loader {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pd-greetings {
  background-color: #fff;
  padding: 0 16px;
  margin-bottom: 28px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 270px;
  border: 1px solid #d3d3d3;
}

.pd-greetings-name {
  text-align: center;
  margin: 0.5rem auto 0.5rem;
}

.pd-progress-chart-wrapper {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  position: relative;
}

/* https://stackoverflow.com/a/39775254/10805856 */
.pd-progress-chart-wrapper svg > g > g:last-child,
.pd-top-apps-chart svg > g > g:last-child,
.pd-work-chart svg > g > g:last-child {
  pointer-events: none;
}

.pd-progress-chart-wrapper > div > div > div > div:first-child {
  z-index: 1;
}

.pd-progress-chart-content {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 132px;
  height: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: #eff8f7; */
  border-radius: 50%;
  box-shadow: 0px 0px 15px 2px #2ecc7120;
}

.pd-progress-chart-content.disabled {
  box-shadow: none;
}

.pd-progress-chart-content > * {
  margin: 0;
}

.pd-progress-chart-content > p {
  font-size: smaller;
}

.pd-progress-chart-content > p:nth-child(2) {
  margin-bottom: 0.25rem;
}

.pd-progress-chart-content > h4 {
  font-weight: bold;
}

@media (min-width: 768px) {
  .pd-app-usage {
    display: grid;
    grid-template-columns: 450px 1fr;
    gap: 30px;
  }
}

@media (min-width: 1024px) {
  .pd-stats {
    display: flex;
  }

  .pd-greetings {
    margin-bottom: 0;
  }
}
