.forgot-password {
    padding: 2rem;
}

/* forgot-password-header start */

.forgot-password-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.forgot-password-img-center {
    margin: 0 auto;
}
.forgot-password-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.forgot-password-title{
   font-weight: 400;
   font-size: 2rem;
}
.span-forgot-content{
    color:red;
}

/* forgot-password-header end */

/* forgot-password-content start */
.forgot-password-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 426px;
}
.forgot-password-form {
  display: grid;
  gap: 16px;
  width: 100%;
}
.forgot-password-submit-button{
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
.forgot-password-submit-button:hover{
    background-color: #035FCB;
}
/* forgot-password-content end */

/* forgot-password-footer start */
a {
    color:  #035FCB;
    text-decoration: none;
}
/* forgot-password-footer end */

.password-reset-success-msg1 {
    font-size: 20px;
    color: #000000bf;
    font-weight: 700;
}
.password-reset-login-button{
    color: blue;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
    color: #000000d6;
}
.forgot-password-field-error-message{
    color: #ff6565;
    font-size: medium;
    margin-bottom: 0.5rem;
    font-weight: 800;
    color: #000000d6;
}
.password-reset-success-msg3{
    font-weight: 500;
    color: #000000d6;
}
.forgot-password-content-success-icon{
    width: 85px;
    height: 85px;
    color: green;
    margin: 23px;
}
.forgot-password-error-message-label{
    color: red;
    text-align: left;
    margin-top: -12px;
    font-size: 13px;
}