.timesheet-root {
  padding: 1rem 2rem 2rem 2rem;
}

/* timesheet div css start */
.timesheet-row {
  margin: 0;
}
.timesheetLoader{
  border-right: 2px solid #8e8e8e80;
    border-left: 2px solid #8e8e8e80;
    height:400px;
    margin-left:200px;
    margin-right: 200px;
}
.timesheet-Loader-No-Result{
  text-align: center;
    color: gray;
    padding-top: 40px;
}

/* timesheet-header-row div css start */
.timesheet-header-row {
  height: 50px;
  line-height: 50px;
  font-size: 0.9rem;
  background-color: #fcfcfc;
  border-bottom: 2px solid #8e8e8e80;
  color: #00000085;
}
.timesheet-header-row *:nth-child(2) {
  border-right: 2px solid #8e8e8e80;
  border-left: 2px solid #8e8e8e80;
}
/* .timesheet-header-row *:nth-child(3) {
  border-right: 2px solid #8e8e8e80;
} */
/* timesheet-header-row div css end */

/* timesheet-content-row div css start */
.timesheet-content-row-border {
  border-right: 2px solid #8e8e8e80;
  border-left: 2px solid #8e8e8e80;
  background-color: #fcfcfc;
  overflow: overlay;
}
/* .timesheet-content-row-worked-hours-grid {
  border-right: 2px solid #8e8e8e80;
} */
.timesheet-content-row-member-grid {
  display: grid;
  grid-gap: 15px;
  background-color: #fff;
  /* border-bottom-left-radius: 10px; */
  background-color: #fcfcfc;
  padding: 20px 1px 30px 1px;
  grid-template-columns: repeat(1, auto),
  
}
.timesheet-content-row-member-grid-displayname-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  margin-left: 40px;
}

.timesheet-content-row-member-grid-displayname-profile-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
}
.timesheet-content-row-member-grid-displayname-profile-data a{
  color:#212529;
}
.timesheet-content-row-member-grid-displayname-data a{
  color:#212529;
}
/* timesheet-content-row-border-grid div css start */
.timesheet-content-row-border-grid {
  display: grid;
    padding: 20px 1px 30px 15px;
    grid-gap: 20px;
    overflow: auto;
  
}

.timesheet-content-row-border-grid-day-card {
  flex: 1;
  border: 2px dashed #eeeeee;
  padding: 5px 8px;
  border-top-right-radius: 3;
  border-top-left-radius: 3;
}

.timesheet-content-row-header {
  display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    margin-bottom: 7px;
}

.timesheet-content-row-day {
  color: #797979;
}
.timesheet-header{
  display: flex;
  margin-bottom: 2rem ;
  margin-top: 1rem ;
}
.timesheet-export-button-header {
  /* text-align: end; */
  /* margin-left: auto; */
  /* margin-left: 25px; */
  margin-bottom: 1rem;
}
.timesheet-checkbox-dropdown-label-checkbox{
  margin-left: auto;
  display: flex;
}
.timesheet-checkbox-dropdown {
  width: 200px;
  /* margin-left: auto; */
  margin-bottom: 1rem;
}
.dropdown-search-clear-icon{
  display: none !important;
}
.option { 
	color: #6c757d;
  background: #e9ecef !important;
}
.option:hover { 
	color: blue;
  background: #e9ecef !important;
}

.timesheet-content-row-date {
  color: #00000033;
}
.timesheet-content-row-content {
  background-color: #eeeeee;
  min-width: 8rem;
  padding: 0 11px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.timesheet-content-row-content:hover {
  background-color: white;
  transform: scale(1.1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.timesheet-content-row-member-grid-displayname-date:hover {
  background-color: #f2d06733;
  transform: scale(1.1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:first-child
  .timesheet-content-row-member-grid-worked-hours-label {
  color: #0065ff;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(2)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #e710cbfc;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(3)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #7454f5;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(4)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #07b4ef;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(5)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #28a745;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(6)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #f06d50;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:nth-child(7)
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #92929e;
}
.timesheet-content-row-member-grid-displayname-date:hover
  .timesheet-content-row-member-grid-worked-hours:last-child
  .timesheet-content-row-member-grid-worked-hours-label {
    color: #1abc9c;
}

.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:first-child
  .timesheet-content-row-content-line-category {
  color: #1abc9c;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(2)
  .timesheet-content-row-content-line-category {
    color: #dc3545a3;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(3)
  .timesheet-content-row-content-line-category {
  color: #0065ff;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(4)
  .timesheet-content-row-content-line-category {
    color: #e710cbfc;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(5)
  .timesheet-content-row-content-line-category {
    color: #7454f5;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(6)
  .timesheet-content-row-content-line-category {
  color: #07b4ef;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(7)
  .timesheet-content-row-content-line-category {
  color: #28a745;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(8)
  .timesheet-content-row-content-line-category {
    color: #f06d50;
}
.timesheet-content-row-content:hover
  .timesheet-content-row-content-line:nth-child(9)
  .timesheet-content-row-content-line-category {
    color: #92929e;
}


.timesheet-content-row-content-line * {
  display: inline-block;
  font-weight: 700;
}
.timesheet-content-row-content-line-time {
  font-size: 0.8em;
  width: 67%;
  color: #97a0afcf;
}
.timesheet-content-row-content-line-category {
  font-size: 0.6em;
  color: #d6d6d6;
}
/* timesheet-content-row-border-grid div css end */

/* timesheet-content-row div css end */
/* timesheet div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}
.timesheet-export-button-header {
  text-align: end;
  margin-bottom: 1rem;
}
.timesheet-export-button {
  /* font-size: 0.9rem;
  font-weight: 700;
  padding: 0.3rem 1.2rem; */
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
}
.timesheet-content-row-member-grid-worked-hours-label {
  font-weight: 600;
        font-size: 0.7em;
        color: #b9a28199;
        width: 53%;
}
.timesheet-content-row-member-grid-worked-hours-value{
  font-size: 0.8em;
        color: #97a0afcf;
        display: inline-block;
        font-weight: 700;
        width: 56%;
}
.timesheet-content-row-member-grid-worked-hours,
.timesheet-content-row-member-grid-worked-days {
  /* margin-top: 1.3rem; */
  display: flex;
  align-items: center;
  padding: 3px;
}
.disable-timesheet {
  padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background-color: #007bffa8;
    border-color: #007bff;
    /* line-height: 1.5; */
    border-radius: .25rem;
}
.timesheet-day {
  width: 38%;
  text-align: right;
}
.timesheet-day-label{
  margin-left: 27px;
}
.timesheet-data-header {
  text-align: center;
  display: block;
}
/* .timesheet-nav-left {
  float: left;
  position: relative;
  left: 10px;
  top: 16px;
  font-size: 20px;
  cursor: pointer;
  color: #bdbdbd;
} */
.timesheet-nav-left{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #007bff;
    background-color: #e9ecef;
    float: left;
    position: relative;
    left: 10px;
    top: 13px;
    font-size: 20px;
    cursor: pointer;
    /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.timesheet-nav-left-icon{
  margin-left: -1px;
}
.timesheet-nav-left-disable{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #718093;
    background-color: #e9ecef;
    float: left;
  position: relative;
  left: 10px;
  top: 13px;
  font-size: 20px;
  cursor: no-drop;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
/* .timesheet-nav-left-disable{
  float: left;
  position: relative;
  left: 10px;
  top: 16px;
  font-size: 20px;
  cursor: no-drop;
  color: #d8cfcf;
} */
.react-multi-carousel-list  li{
  width: 668px !important;
}
/* .timesheet-nav-right {
  float: right;
  position: relative;
  right: 10px;
  top: 16px;
  font-size: 20px;
  cursor: pointer;
  color: #bdbdbd;
} */
.timesheet-nav-right{
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #007bff;
    background-color: #e9ecef;
    float: right;
  position: relative;
  right: 10px;
  top: 13px;
  font-size: 20px;
  cursor: pointer;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
.timesheet-checkbox-dropdown-label{
  font-size: 15px;
    color: gray;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 8px;
}
.timesheet-nav-right-disable {
  height: 25px;
    width: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #718093;
    background-color: #e9ecef;
    float: right;
  position: relative;
  right: 10px;
  top: 13px;
  font-size: 20px;
  cursor: no-drop;
  /* box-shadow: -1px 2px 0px 0px #e9e9e9; */
}
/* .timesheet-nav-right-disable {
  float: right;
  position: relative;
  right: 10px;
  top: 16px;
  font-size: 20px;
  cursor: no-drop;
  color: #d8cfcf;
} */
.timesheet-content-row-member-grid-displayname-date{
  /* text-align: center !important; */
  width: 11rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 1rem;
  padding: 11px 11px;
  border-radius: 5px;
  background-color: #f2d06733;
  transition: all .2s ease-in-out;
}
.timesheet-data-header-details{
  border-right: none !important;
  border-left: none !important;
}
label.timesheet-data-header-name.timesheet-data-header-details {
  text-transform: uppercase;
}
label.timesheet-data-header-date.timesheet-data-header-details {
  color: #0000007d;
}
.timesheet-work-summary-header{
  text-align: center;
}

.timesheet-content-row-content-absent{
  background-color: #f3d0d066 !important;

}

.timesheet-content-row-border-grid-day-card-absent{
  border: 2px dashed #f9b8b8ba !important;
}
.timesheet-content-display-name-header{
  display: flex;
  position: relative;
}
.timesheet-content-avatar-profile {
    width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
    margin: auto 0;
    margin-right: 12px;
}
.timesheet-content-data-details-avatar-circle{
  height: 28px;
  width: 28px;
  font-weight: bold;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  background-color: rgb(128, 179, 255);
  margin-right: 0.75rem;
  border-radius: 30px;
  text-transform: uppercase;
  margin: auto 0;
  margin-right: 12px;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 0;
}
.download-dropdown{
  color: #1e1e1e;
    cursor: pointer;
    width: 100%;
    margin: 0;
}
.timesheet-export-button-header a:hover{
  text-decoration: none;
  color: #1e1e1e;
}
.timesheet-export-button-header .dropdown-toggle::after {
  display: none;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.timesheet-download-icon{
  color: white;
    font-size: 1.1rem;
    font-weight: 700;
}
.timesheet-checkbox-dropdown-label-link {
  color: gray;
}
.timesheet-checkbox-dropdown-label-link a {
  color: blue;
  text-decoration: underline;
}
.timesheet-checkbox-dropdown-label-link-footer{
  text-align: center;
  margin: 0rem 1rem 1rem 1rem;
}
.disable-edit-timesheet {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: #6c757d8f;
  border-color: #6c757d9c;
  border-radius: 0.25rem;
  margin-top: 2px;
}
.timesheet-content-row-day-edit-timesheet {
  font-size: 13px;
  margin-left: 2px;
  cursor: pointer;
}
.timesheet-content-row-day-edit-timesheet-label {
  color: #80808061;
  font-size: 11px;
}
.timesheet-content-row-border-grid-day-card-edited {
  border: 2px dashed #ff8c005e !important;
}
.timesheet-content-row-content-edited{
  background-color: #ff8c0024 !important;
}
.timesheet-export-button-header {
  margin-left: 15px;
}
.timeline-pagination-section-timesheet {
  display: grid;
  place-items: center;
  width: 78vw;
  overflow: scroll;
  margin: 0px 32px 0px 32px;
}
.timeline-pagination-section-label-timesheet {
  text-align: center;
  margin-bottom: 15px;
  /* width: 100vw; */
  display: grid;
}
.timeline-pagination-section-timesheet .pagination{
  /* display: flex;
  justify-content: center; */
}
.timesheet-content-row-member-grid-employee-id {
  color: gray;
  font-size: 13px;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 133px;
}