.work-load-analysis-report-root {
    margin: 2rem;
}
.work-load-analysis-report-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.work-load-analysis-report-filter-content {
    display: flex;
    z-index: 50;
}
.work-load-analysis-report-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}



.work-load-analysis-report-user-table-view {
    /* width: 100vw; */
    display: grid;
}
.work-load-analysis-report-user-tablet-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}
.work-load-analysis-report-user-list-table-content {
    width: 100%;
    height: 67vh;
    overflow: auto;
}
.work-load-analysis-report-user-list-table-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
    font-size: 0.9rem !important;
}
.work-load-analysis-report-user-list-table-thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 50;
}
.work-load-analysis-report-user-list-table-table thead > tr {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.work-load-analysis-report-user-list-table-table thead > tr {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    /* text-align: center; */
}
.work-load-analysis-report-user-list-table-table th:first-child {
    min-width: 16rem;
    max-width: 16rem;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
th.work-load-analysis-report-user-list-table-thead-tr-th {
    padding: 12px;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table th {
    /* font-size: 1rem; */
    cursor: default;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table td {
    min-width: 6rem;
    max-width: 6rem;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #E9ECEF;
}
.work-load-analysis-report-user-list-table-thead-tr-th {
    font-weight: 600 !important;
    color: #00000094;
}
.work-load-analysis-report-user-list-table-tbody {
    display: block;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table tr {
    height: 3rem;
    cursor: pointer;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    min-width: 14rem;
    max-width: 14rem;
}
.work-load-analysis-report-user-list-table-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table th, .work-load-analysis-report-user-list-table-table tbody > tr th {
    background-color: #F3F7FA!important;
}
.work-load-analysis-report-user-list-table-table tbody th, .work-load-analysis-report-user-list-table-table tbody td {
    color: #000000d6;
}
.work-load-analysis-report-user-list-table-table td:not(:first-child) {
    text-align: center !important;
}
.work-load-analysis-report-user-list-table-table thead> tr th:nth-last-child(-n+3), .work-load-analysis-report-user-list-table-table tbody> tr td:nth-last-child(-n+3) {
    /* min-width: 10rem;
    max-width: 10rem; */
}
.monthly-aatendance-load-more-button-div {
    text-align: center;
    margin-top: 21px;
}

.work-load-analysis-report-root-content{
    display: grid;
    grid-template-columns: 1fr;
}
.work-load-analysis-report-chart-content{
    width: 100%;
    margin-bottom: 2rem;
    border: 0.5px solid rgba(0,0,0,.0784313725490196);
    height: 460px;
}
.work-load-analysis-report-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.work-load-analysis-report-page-content-wrapper-menu-filter-date{
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    padding: 6px 16px 6px 8px;
    cursor: pointer;
    transition: all .1s ease;
    width: 280px;
}
.work-load-analysis-report-page-content-wrapper-menu-filter-date-text{
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}
.work-load-analysis-report-user-list-table-thead-tr-th:not(:first-child) {
    text-align: center !important;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-over-worked{
    background-color: #F1C40F;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-healthy{
    background-color: #2ECC71;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-less-utilised{
    background-color: #E67E22;
}
.work-load-analysis-report-user-list-table-tbody-tr-td-less-utilised-na{
    background-color: #F3F7FA;
}
.work-load-analysis-report-chart-content-col {
    padding: 20px 0px;
}
.work-load-analysis-report-chart-content-title {
    padding: 0px 25px;
}