.access-control-content {
  padding: 2rem;
}
.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.access-control-content-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.access-control-tabs {
  color: #000000d4;
  font-size: 0.9rem;
}

.access-control-content-tabs-head.nav-tabs {
  border-bottom: none;
}
.access-control-content-tabs-head .nav-link.active {
  color: #007bff;
  margin-bottom: 0px;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}
.access-control-content-tabs-head .nav-link {
  color: #495057;
  font-size: 0.9rem;
}
.access-control-content-tabs-head .nav-link:hover {
  border-color: transparent !important;
}
.access-control-content-tabs-head .nav-link.active {
  border-bottom: 2px solid #007bff;
}
.access-control-content-tabs-head .nav-link.active:hover {
  border-bottom: 2px solid #007bff !important;
}
.access-control-content-tabs-head .nav-link:hover {
  color: blue;
}

/* Tab Content CSS */
.access-control-section {
  padding: 2rem;
  border: 1px solid #00000030;
  padding: 1rem;
}
.access-control-content-data-checkbox {
  width: 100%;
  height: auto;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.scheduled-reports-new-report-modal-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000cf !important;
}

.access-control-content-checkbox-button {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}

.access-control-content-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}
.access-control-content-checkbox-label-name {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
  cursor: pointer;
  font-weight: 600;
  color: #000000ab;
}
.access-control-content-organisation-data-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.access-control-content-sub-heading-name {
  margin-top: 1rem;
  margin-bottom: 0.9rem;
}
.access-control-content-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
  font-weight: bold;
}
.access-control-content-button {
  margin-left: 39%;
  margin-right: 0;
  margin-top: 5%;
  margin-bottom: 5%;
}

.access-control-checkbox {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}
.access-control-table td,
.access-control-table th,
.access-control-table thead th {
  border: none;
}
.access-control-tbody {
  font-size: 0.8rem;
}
.access-control-checkbox-td,
.access-control-checkbox-th {
  text-align: center;
}
