
.employee-report-root-card{
    padding:2rem;
}

.monthly-attendence-root {
    display: grid;
    grid-template-columns: 1fr;
}
/* .monthly-attendence-container {
    width: 100%;
    overflow: auto;
    height: 100vh;
} */

.monthly-attendance-scroll-sticky {
    width: 100%;
    height: 40px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
}
.monthly-attendence-content {
    width: 100%;
    height: 67vh;
    overflow: auto;
  }

.monthly-attendence-table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #dee2e6;
  font-size: 0.9rem !important;
}

.monthly-attendence-table  thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    height: auto;
    display: block;
}
.monthly-attendence-table  thead > tr  th:first-child {
  background-color: #F3F7FA !important;
    /* text-align: center; */
}

.monthly-attendence-table thead> tr th:nth-last-child(-n+3),.monthly-attendence-table tbody> tr td:nth-last-child(-n+3) {
    /* width:2rem; */
    /* min-width: 10rem;
    max-width: 10rem; */
  }

.monthly-attendence-tbody {
    display: block;
}

.monthly-attendence-table tr {
    height: 3rem;
    cursor: pointer;
}
.monthly-attendence-table tr:nth-child(even) {
    /* background-color: #F2F2F2; */
}
.monthly-attendence-table tr:nth-child(odd) {
    /* background-color: #FAFBFD; */
}
.monthly-attendence-table th:first-child {
    text-align: left;
    font-weight: normal;
    position: sticky;
    left: 0px;
    z-index: 40;
    background-color: inherit;
}
.monthly-attendence-table tr:hover {
    background-color: #F3F7FA;
}
.monthly-attendence-table tr:last-child td {
    border-bottom: 0;
}

.monthly-attendence-table th,
.monthly-attendence-table td {
    min-width: 7rem;
    max-width: 7rem;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #E9ECEF;
}
.monthly-attendence-table th:first-child{
    min-width: 16rem;
    max-width: 16rem;
}

.monthly-attendence-table td#selected {
    background-color: #419BDA;
}
.monthly-attendence-table th {
    /* font-size: 1rem; */
    cursor: default;
}
/* .monthly-attendence-table th,.monthly-attendence-table td {
text-align: center;
} */
.monthly-attendence-table th,.monthly-attendence-table tbody > tr th {
    background-color: #F3F7FA!important;
  
}
.monthly-attendence-table th:not(:first-child) {
    text-align: center !important;
    font-size: 0.9rem;
}
.monthly-attendence-table td:not(:first-child) {
    text-align: center !important;
}
.monthly-attendence-thead-tr-th{
    font-weight: 600 !important;
    color: #00000094;
}
.monthly-attendence-thead{
    position: sticky;
    top: 0px;
    z-index: 50;
}
.monthly-attendence-table tbody th,.monthly-attendence-table tbody td{
    color: #000000d6;
}
.monthly-attendence-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.monthly-attendence-date-picker .react-datepicker-wrapper .react-datepicker__input-container input{
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.monthly-attendence-date-picker .react-datepicker__tab-loop .react-datepicker-popper {
    z-index: 99;
}
.monthly-attendence-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.monthly-attendence-filter-user .monthly-attendence-filter-user-select .User__menu{
    z-index:99;
}
.monthly-attendence-download-icon {
    /* color: #2A8EF7; */
    font-size: 1.1rem;
    font-weight: 700;
}
.monthly-attendence-filter-content{
    display: flex;
}
.monthly-aatendance-load-more-button-div {
    text-align: center;
    margin-top: 21px;
}

.monthly-aatendance-load-more-button-div button{
   font-size: 14px; 
}
.monthly-attendence-download-export-button:hover .monthly-attendence-download-icon{
    color:white;
}
.monthly-attendence-tbody-tr-td-absent{
    color: gray !important;
    font-size: 13px;
}
th.monthly-attendence-thead-tr-th {
    padding: 12px;
}
.monthly-attendence-header-label-badge span{
    padding: 10px;
}
.monthly-attendence-header-label-badge-2 {
    /* padding: 10px; */
    margin-left: 5px;
}
.monthly-attendence-header-label {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
}
/* .monthly-attendence-tbody-tr-td-uninformed-leave {
    color: orange !important;
}
.monthly-attendence-tbody-tr-td-informed-leave {
    color: #ff00006e !important;
} */
td.monthly-attendence-tbody-tr-td.monthly-attendence-tbody-tr-td-non-working-day {
    color: #00000054;
    font-size: 13px;
}
.monthly-in-out-content-table-tbody-td-status {
    color: gray !important;
    min-width: 8rem;
    max-width: 8rem;
}
.monthly-in-out-content-table-tbody-td-status-present{
    background-color: #CDE9CD;
    /* color: green !important; */
}
.monthly-in-out-content-table-tbody-td-status-weekoff{
    background-color: antiquewhite;
}
.monthly-attendance-table-tbody tr{
    line-height: 1.4;
}
.monthly-in-out-content-table-tbody-td-status-half{
    background-color: #eded94ad;
}