.personal-statistics-root {
    padding: 2rem;
}

.desktop-personal-statistics-root {
    padding: 1.3rem;
    /* overflow-x: hidden;
    overflow-y: auto; */
    padding-top: 0em;
}


/* personal-header div css start */

.personal-header {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.personal-clockin-clockout-content-data-details-worked {
    /* color: green; */
    /* margin-left: 5px; */
    /* background-color: #f3f8fd; */
    margin-left: 5px;
    padding: 5px 5px;
    border-radius: 5px;
}

.personal-clockin-clockout-content-data-details-for {
    margin-left: 5px;
}

.personal-clockin-clockout-content-data-details-worked-hours {
    /* color: green;
    margin-left: 5px; */
    background-color: #f3f8fd;
    margin-left: 5px;
    padding: 5px 5px;
    border-radius: 5px;
}


/* personal-header-profile div css start */

.personal-header-profile {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
}

.personal-header-profile-multiple-date {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
}

.personal-header-avatar {
    display: inline-block;
    text-align: center;
    color: #333333;
}

.personal-header-avatar-image {
    height: 90px;
    width: 90px;
    cursor: pointer;
}

.personal-header-name {
    font-size: 25px;
    font-weight: 700;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.personal-header-checkin {
    font-size: 13px;
    color: #495057;
    font-weight: 500;
}

.personal-header-checkin-err {
    font-size: 13px;
    color: #495057;
    font-weight: 500;
    line-height: 25px;
}

.personal-header-email {
    font-size: 13px;
    color: #28a745;
    font-weight: 500;
}


/* personal-header-profile div css end */


/* personal-header-summary div css start */

.personal-header-summary-card {
    /* background-color: #efefefc4; */
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}

.personal-header-summary-card-text {
    /* font-size: 14px;
  text-align: center; */
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.personal-header-summary-card-text-ques {
    font-size: .9rem;
    color: #808080ba;
    float: right;
}


/* personal-header-summary div css end */


/* personal-header div css end */


/* personal-content div css start */

.personal-content {
    /* display: flex; */
}


/* personal-content div css end */


/* personal-footer div css end */

.personal-footer {
    /* display: flex; */
}

.personal-footer-section-top {
    height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    border: 0.5px solid #00000014;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem; */
}

@media screen and (max-width: 768px) {
    .personal-footer-section-top {
        height: 40rem;
        /* grid-template-columns: 1fr; */
    }
}

@media screen and (max-width: 480px) {
    .personal-header {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    }
    .personal-header-profile {
        grid-column: 1/3 !important;
    }
    .personal-header-summary-card-text {
        font-size: 0.8rem;
    }
    .personal-header-email {
        width: 215px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .personal-footer-task-section {
        overflow-x: scroll !important;
    }
}

.personal-footer-section-top-scroll {
    height: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.personal-footer-section {
    height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-footer-section-progress-bar {
    min-height: 12rem;
    max-height: 19rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-footer-task-section {
    margin: 1rem;
    max-height: 22rem;
    min-height: 10rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.website-chart-personal {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 21px;
}

.personal-footer-head-website-list {
    padding-top: 0.2rem;
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1.3rem;
}

.personal-footer-section-date {
    padding-top: 0.2rem;
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    /* margin-bottom: 1.3rem; */
}

.personal-footer-section-checkin {
    /* margin-top: 15px; */
    font-size: 15px;
    line-height: 14px;
    color: #000000b5;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 4px;
}

.personal-footer-section-task-display {
    overflow-y: auto;
    overflow-x: hidden;
}

.personal-footer-section-task-display-card {
    display: flex;
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.personal-footer-section-task-display-card-content {
    display: flex;
    padding: 2%;
    margin: 0px 10px 0px 0px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    width: 85%;
}

.personal-footer-section-task-display-card-content-task {
    width: 85%;
}

.personal-footer-section-task-display-card-content-text-hour {
    font-weight: normal;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 2px;
}

.personal-footer-section-task-display-card-content-data-hour {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    flex-direction: row-reverse;
}

.personal-footer-section-task-display-card-content-text {
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 2px;
}

.personal-footer-section-task-display-card-content-data {
    font-weight: bold;
    font-size: 19px;
    color: #000000bd;
}

.personal-staticstic-size {
    font-size: 0.9rem;
}


/* personal-footer div css end */

.personal-statistic {
    margin: 1rem 0rem 1rem 0rem;
}

.personal-statistic-chart-title {
    display: grid;
    grid-template-columns: repeat(3, minmax(133px, 1fr));
    grid-gap: 1rem;
}

.personal-statistic-chart-title-progress {
    grid-column: 2 / 4;
}

.personal-statistic-col {
    padding-left: 0px;
    padding-right: 0px;
}

@media (max-width: 991px) {
    .single-menu {
        margin: 0px 0 10px 1px;
    }
}

.personal-header-profile-avatar {
    display: inline-block;
    margin-right: 10px;
    height: 60px;
    width: 60px;
    border-radius: 70px;
    background: #ef780e8c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 34px;
    line-height: 60px;
    text-align: center;
    color: white;
    margin-right: 13px;
    /* margin-top: 5px; */
}


.head-activities-data-list {
    font-size: 0.9rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1rem;
}

.personal-footer-section-task-display-card-image {
    display: flex;
}

.personal-screenshot-thumbnail-image {
    width: 96px;
    border: 2px solid #e2dfdffa;
}

.personal-screenshot-images-card {
    background-color: #d8d3d3d1;
    text-align: center;
    line-height: 78px;
    font-size: 0.8rem;
    cursor: pointer;
}

.personal-screenshot-thumbnail-image:nth-child(2),
.personal-screenshot-thumbnail-image:nth-child(3) {
    margin-left: -81px;
}

.personal-clockin-clockout-content-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 10px 10px 10px;
    margin-bottom: -0.5rem;
}

.personal-clockin-clockout-content-data-details {
    background-color: #ffffff;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .06);
    font-size: 0.9rem;
    font-weight: 400;
}

.personal-clockin-clockout-content-data-details-selected {
    background-color: #DBFBE7 !important;
}

.personal-clockin-clockout-content-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.personal-clockin-clockout-content-data-header {
    color: rgba(121, 119, 119, .8588235294117647);
    font-size: 0.9rem;
}

.personal-Task-root-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
}

.personal-Task-root-task-name {
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}

.personal-Task-root-project-name {
    margin: 0;
    margin-bottom: 3px;
    font-size: 13px;
}

.personal-Task-head-website-list {
    /* padding-top: 0.2rem; */
    font-size: 1rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    /* margin-bottom: 1.3rem; */
}

.personal-no-data-text {
    font-size: 14px;
    color: #2d3748;
    flex: 1 1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.personal-report-activity-log-link {
    font-size: 14px;
    color: #2d3748;
    flex: 1 1;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.personal-date-headers-td {
    padding: 0px 0px 5px 0px;
}

.personal-date-header {
    margin: auto;
    padding: 2px 20px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.personal-date-header-label-icon {
    margin-right: 10px;
    color: #495057eb;
}

.personal-date-header-label-icon svg {
    margin-top: -4px;
}

.personal-task-footer-section {
    height: 22rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-work-task-footer-section {
    max-height: 22rem;
    min-height: 10rem;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
}

.personal-date-headers-td {
    padding: 0px 0px 5px 0px !important;
}

.personal-card-avatar-profile {
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    margin-right: 13px;
    border-radius: 50%;
}

.personal-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.personal-header-checkin-err-not-accept {
    font-size: 13px;
    color: #dc3545;
    font-weight: 500;
    line-height: 25px;
}

.personal-statistics-task-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.personal-statistics-task-card-table-header {
    color: #868686;
    border: none;
    font-size: 16px;
}

.personal-statistics-task-card-table thead th {
    border: none;
}

.personal-statistics-task-card-table th,
.personal-statistics-task-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}

.personal-statistics-task-card-table-body {
    color: #333;
}

.personal-statistics-task-card-table-data-details {
    padding: 1rem 0;
    /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 10%); */
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
}

.personal-statistics-task-card-table th,
.personal-statistics-task-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.5rem;
}

.personal-statistics-task-card-table-description {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-statistics-task-card-table-name {
    font-size: 13px;
    color: gray;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personal-statistics-task-card-table-header {
    color: rgba(121, 119, 119, .8588235294117647);
    font-size: 0.9rem;
}

.personal-statistics-task-card-table-data-details:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.project-task-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 87%;
    display: initial;
    /* margin-bottom: 38px; */
    margin-top: 2px;
}

.project-task-list {
    /* border-radius: 1.2rem; */
    font-size: 14px;
    margin-bottom: 0.5rem;
    display: flex;
    /* border-bottom: 2px solid #00000014; */
    padding-bottom: 7px;
}


/* .project-task-name-bullet-list::before{
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #69b6d5;
    margin-right: 7px;
    margin-top: 9px;
 } */

.personal-project-and-task-section-top {
    height: 19rem;
    padding: 1rem;
    margin: 1rem 0rem;
    border-radius: 5px;
    border: 0.5px solid #00000014;
}

.personal-project-and-task-section {
    /* background-color: white; */
}

.project-task-worked-list {
    border: 1px solid black;
    padding: 1rem;
    text-align: center;
    border-radius: 5px;
}

.personal-project-and-task-section-chart {
    margin-top: 1rem;
}

.project-task-list-root {
    width: 100%;
    margin-top: 1.5rem;
}

.project-task-worked-header-name {
    font-size: 0.9rem;
    color: #2d3748;
    font-weight: 500;
    flex: 1 1;
    margin-bottom: 1rem;
}

.project-task-section-list-content {
    margin-top: 1rem;
}

.project-task-worked-list-header {
    font-size: 0.9rem;
    color: #000000de;
    /* font-weight: 600; */
    margin-bottom: 6px;
}

.project-task-worked-list-time {
    font-size: 24px;
    font-weight: 700;
}

.project-task-worked-data-list {
    border-color: #53bb53fa;
    background-color: #a4f1a42e;
}

.project-task-unorganized-data-list {
    border-color: #f78282;
    background-color: #f5bdbd2e;
}

.project-task-details-data {
    padding-bottom: 7px;
    border-bottom: 1px solid #00000014;
    display: flex;
    justify-content: space-between;
    width: 97%;
}

.website-chart-project-task {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    /* margin-top: 21px; */
}

.project-task-name-bullet-list {
    content: '';
    width: 3%;
    width: 6px;
    height: 6px;
    background: red;
    border-radius: 50%;
    margin-right: 7px;
    margin-top: 12px;
}

.personal-statistics-project-task-name{
    font-weight: 500;
    font-size: 16px;
    color: #333131;
}
.personal-statistics-project-details-project-name{
    color: gray;
    font-size: 13px;
}
.project-task-worked-time{
    color: gray;
    font-size: 14px;
}
.personal-header-time-error{
    color: #ff00009e;
    font-size: 13px;
    font-weight: 500;
    margin-top: 3px;
}
.personal-header-emp-id {
    font-size: 13px;
    color: gray;
    font-weight: 500;
    margin-top: 5px;
}
.common-err-refresh-text-access{
    text-align: center;
    margin: 2rem;
    color: red;
}