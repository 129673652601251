/* personal-app-review div css start*/
.personal-app-review {
  background-color: #fafafa;
}

.par-list {
  border-radius: 5px;
  background-color: #fff;
}

.par-list-tab-bar {
  position: relative;
  border-bottom: 1px solid #ccc;
}

.par-close-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #525252;
  top: 0;
  display: flex;
  align-items: center;
  padding: 5px 12px 10px;
  display: block;
}

.par-list-tab-bar a {
  color: gray;
}

.par-list-tab-bar a.active {
  border-bottom: 3px solid #007bff;
  color: #007bff;
}

.par-list-by-tabs {
  padding: 16px;
}

.par-list-by-tabs-search {
  margin-bottom: 2px;
}

/* par-listing div css start*/
.par-listing {
  height: calc(100vh - 165px - 32px - 55px);
  overflow: auto;
}

.par-listing-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #fafafa;
  border-radius: 5px;
}

.par-listing-card-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  background: #dc4c35b5;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.par-lc-content {
  flex: 1;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
}
.par-lc-content-avatar {
  display: inline-block;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: #fae5a5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  color: #515050;
}
.par-lc-content-icon {
  display: inline-block;
  margin-right: 10px;
  width: 32px;
  object-fit: contain;
}
.par-lc-content-app-name {
  font-weight: bold;
  color: #4f4f4f;
}

.par-lc-content-app-category {
  font-size: 0.8rem;
  color: #ababab;
}

.par-lc-button {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0.5rem 0 0;
}

.par-lc-button-state {
  border: 1.5px solid #000000;
  height: 30px;
  width: 30px;
  line-height: 28.5px;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.par-lc-button-state:not(:last-child) {
  margin-right: 10px;
}

.par-lc-button-state.par-productive {
  color: #2ecc71;
  border-color: #2ecc71;
}

.par-lc-button-state.par-productive.selected {
  background-color: #2ecc71;
}

.par-lc-button-state.par-unproductive {
  color: #e74c3c;
  border-color: #e74c3c;
}

.par-lc-button-state.par-unproductive.selected {
  background-color: #e74c3c;
}

.par-lc-button-state.par-neutral {
  color: #bdc3c7;
  border-color: #bdc3c7;
}

.par-lc-button-state.par-neutral.selected {
  background-color: #bdc3c7;
}

.par-lc-button-state.selected {
  color: #fff !important;
  font-weight: bold;
}

.par-lc-button-state.loading {
  cursor: wait;
  opacity: 0.6;
}

.par-legend {
  padding: 8px 0;
  margin-bottom: 0.5rem;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.par-legend .par-legend-item {
  display: flex;
  align-items: center;
}

.par-legend .par-legend-item:not(:last-child) {
  margin-right: 10px;
}

.par-legend-name {
  font-size: smaller;
}

.par-legend-icon {
  color: #fff;
  height: 22px;
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-right: 4px;
}

.par-legend-item.pl-productive .par-legend-icon {
  background-color: #2ecc71;
}

.par-legend-item.pl-unproductive .par-legend-icon {
  background-color: #e74c3c;
}

.par-legend-item.pl-neutral .par-legend-icon {
  background-color: #bdc3c7;
}

.par-legend-item.pl-productive .par-legend-name {
  color: #2ecc71;
}

.par-legend-item.pl-unproductive .par-legend-name {
  color: #e74c3c;
}

.par-legend-item.pl-neutral .par-legend-name {
  color: #bdc3c7;
}

/* personal-app-review div css end*/
