.create-project-root {
  display: flex;
  justify-content: flex-end;
}
.create-project-root-button {
  background: #695eee;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 5px;
}

/* create-project-root-modal start*/

.create-project-root-modal-content-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
}
.create-project-root-modal-content-name-field {
  flex: 1;
  margin-right: 16px;
}
.create-project-root-modal-content-bill-field {
  width: 124px;
  position: relative;
}
.create-project-root-modal-content-bill-field-icon {
  position: absolute;
  top: 30px;
  bottom: 0;
  padding: 0.3rem;
  color: #495057;
  font-size: 0.7rem;
}
.create-project-root-modal-label-name {
  font-size: 0.7rem;
  font-weight: 600;
  color: #718093;
}

.create-project-root-modal-content-person-name {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -16px;
}
/* create-project-root-modal-content-person-name-header start */
.create-project-root-modal-content-person-name-header {
  display: flex;
  margin-bottom: 16px;
  padding-right: 16px;
}
.create-project-root-modal-content-person-name-header-button {
  background: #fff;
  border: 1px solid #695eee;
  color: #695eee;
  font-size: 0.8rem;
  /* transition: background .4s; */
}
.create-project-root-modal-content-person-name-header-section {
  flex: 1;
}
.create-project-root-modal-content-person-name-header-section-label {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 18px;
  margin-bottom: 6px;
  color: #62839b;
}
.create-project-root-modal-content-person-name-header-section-content {
  display: flex;
}
.create-project-root-modal-searchbox-wrapper {
  position: relative;
  flex: 1;
  margin-right: 16px;
}

.create-project-root-modal-searchbox {
  float: right;
  font-size: 0.8rem;
  background-color: #f5f3f6;
}
.create-project-root-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}
/* create-project-root-modal-content-person-name-header end */

/* create-project-root-modal-content-person-name-listing start */
.create-project-root-modal-content-person-name-listing {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  padding-right: 16px;
  /* margin-right: -16px; */
}
.create-project-root-modal-content-person-name-listing-card {
  margin-bottom: 20px;
}
.create-project-root-modal-content-person-name-listing-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  margin-bottom: 10px;
}
.create-project-root-modal-content-header-team-name {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 14px;
  color: #62839b;
}
.create-project-root-modal-content-header-team-name-select {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 14px;
  align-items: center;
  color: #695eee;
  cursor: pointer;
}
.create-project-root-modal-content-person-name-listing-card-content {
  margin-bottom: 10px;
  display: flex;
}
.create-project-root-modal-content-person-name-listing-card-content-person {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
}
.create-project-root-modal-content-person-name-listing-card-content-person-icon {
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 0.6rem;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #28a745;
  text-transform: uppercase;
}
.create-project-root-modal-content-person-name-listing-card-content-person-icon-name {
  flex: 1 1;
  font-size: 0.8rem;
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* create-project-root-modal-content-person-name-listing end */

/* create-project-root-modal end */
