html { scroll-behavior: smooth; }
.timesheet-settings-card {
  padding: 2rem;
}
/* .timesheet-settings-row{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
} */
.timesheet-settings-timzone-search{
  width: 75%;
  font-size: .9rem;
}
.track-setting-info-text{
  display: inline-block;
  padding: 0.8em;
  font-size: 75%;
  font-weight: 700;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #00000096;
  background-color: #ff000036;
}
.timesheet-settings-row-grid,.timesheet-settings-row-grid-1,.timesheet-settings-row-grid-2 {
  /* display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 15px; */
  /* align-items: start; */
  /* gap: 30px; */
  columns: 2 calc((100vw - 420px - 8rem) / 2);
  column-gap: 0.5rem;
}
.timesheet-settings-row-grid-card {
  /* height: auto; */
  display: inline-block;
  margin-bottom: 1rem;
  width: calc((100vw - 420px - 6rem) /2);
  color: rgb(74, 85, 104);
  /* overflow: auto; */
  padding: 16px;
  background:rgba(196, 196, 196, 0.25);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(211, 211, 211);
  border-image: initial;
}
.track-setting-task-time-mapping-formik-add-form-start-time .rc-time-picker-clear{
  /* display: none; */
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span{
  float: right;

}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span a{
  color: #035fcb;
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning{
  opacity: 0.5;
}
.timesheet-settings-row-grid-card-auto-clock-in-card-header-span-warning-text{
  font-size: .9rem;
  margin-top: 8px;
}
.time-sheet-project-formik-add-form-edit-end-time {
  font-size: 10px;
   margin-left: 20px; 
  color: red;
  width: 140px;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 23px;
}
.schedule-stealth-mode-start-p{
  font-size: 11px;
  margin-left: 23px;
  margin-bottom: 2px;
}

.track-setting-task-time-mapping-formik-add-form-start-time-error .rc-time-picker-input {
  border: 1px solid #F0624D !important;
}
.schedule-stealth-mode-start-div{
  display: flex;
}
.schedule-stealth-mode-label{
  font-size: 0.9rem;
  margin-left: 12%;
}
.timesheet-settings-card input.rc-time-picker-input {
  font-size: .8rem;
  color: #495057;
  padding: .375rem .75rem;
  line-height: 1.5;
  height: 38px;
  width:80%;
  margin-left: 12%;
}

.timesheet-settings-card a.rc-time-picker-clear {
  top: 6px;
  width: 60px;
}
.checkbox-date{
  margin-left: 0.5rem;
}
.timesheet-settings-card .form-control {
  width: 75% ;
  font-size: 0.9rem;
}
.time-form{
  width: 45% !important ;
}
.track-setting-radion-button{
  margin-bottom: 1rem;
}
.timesheet-settings-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
  text-align: center;
}
.timesheet-settings-modal-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
  font-weight: bold;
}
.timesheet-settings-worked-days-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 13px;
}
.timesheet-settings-label-name {
  font-size: 14px;
  font-weight: 500;
  color: #000000ab;
}
.timesheet-settings-working-hours-label {
  margin-bottom: 0.3rem;
}
.timesheet-settings-header-label-name {
  font-size: 0.87rem;
  color: #2b2a2a;
}
.timesheet-settings-working-hours {
  display: flex;
}

.timesheet-settings-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.timesheet-settings-worked-days-data {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem;
  font-size: 1rem; */
  padding: 0.375rem 0.55rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}

.timesheet-settings-worked-days-data input[type="checkbox"] {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
  cursor: pointer;
}

.timesheet-settings-worked-days-data label {
  cursor: pointer;
}

.timesheet-settings-row > * {
  margin-bottom: 1rem;
}
.timesheet-settings-worked-days-selected {
  background-color: #007bff12;
  border: 1px solid #007bff;
}
.timesheet-settings-row-grid-card h6{
  color:black;
}
.user-employee-idel-threshold-dropdown {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  display: inline;
  margin-left: 5px;
}
.react-tooltip-free-setting{
  /* min-width:150px !important; */
  width:250px;
}
.track-setting-text-ques {
  font-size: 1.2rem;
    color: #808080ba;
    /* float: right; */
    margin-left: 3px;
}
.track-setting-tooltip-start .tooltip-inner{
  min-width:300px;
  /* background-color: #c4dbf3ba !important;
  border: 2px solid #c4dbf3ba; */
  background-color: #535C68  !important;
  border: 1px solid #535C68;
  color:white;
  
  position: sticky;
  z-index: 1 !important;
  backdrop-filter: blur(6px);
}
.track-setting-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-bottom-color: #535C68 !important;
}
@media screen and (max-width: 480px) {
  .timesheet-settings-row-grid, .timesheet-settings-row-grid-1, .timesheet-settings-row-grid-2{
    columns: initial;
    height: calc(100vh - 30vh);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .timesheet-settings-row-grid-card{
    width: -webkit-fill-available;
  }
  .timesheet-settings-worked-days-list{
    grid-template-columns: 1fr 1fr;
  }
  .timesheet-setting-sub-button{
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .timesheet-settings-card {
    padding: 2rem 2rem 0rem 2rem;
  }
  .timesheet-setting-sub-button-div{
    display: flex;
    justify-content: center;
  }
}

.track-settingts-checkbox input {
  margin-right: 7px;
}
.timesheet-settings-row-grid-card-manual-time-card-header {
  display: flex;
  justify-content: flex-start;
}
.timesheet-settings-row-grid-card-manual-time-card-header span a{
  color: blue !important;
  font-size: 15px;
  margin-left: 10px;
}
.track-settings-formik-add-form-start-time input::placeholder{
  opacity: 1;
}
.track-settings-formik-add-form-start-time .rc-time-picker-input{
  font-size: 0.9rem !important;
  margin-left: 0% !important;
  width: 88% !important;
}
.track-settings-alert-start-time .rc-time-picker-clear,.track-settings-alert-end-time .rc-time-picker-clear{
  display: none;
}
/* .timesheet-settings-row-grid-card-internet-interrupt-label {
  display: flex;
} */
.timesheet-settings-row-grid-card-internet-interrupt-label-include {
  margin-right: 13px;
}
.timesheet-settings-row-grid-card-internet-interrupt-label-include label input, .timesheet-settings-row-grid-card-internet-interrupt-label-exclude label input {
  margin-right: 4px;
}