.on-boarding-root {
    padding: 2rem;
}
.account-creation-logo-full{
    margin-bottom: 10px;
}
.account-root-img-responsive {
    margin-top: -7px !important;
}
.account-creation-logo-text {
    font-size:20px;
    font-weight:600;
    /* color:#1862c4; */
    color:#035FCB;
    margin-left:5px;
}
h1.on-boarding-root-header {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
.on-boarding-root-content-full {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 20px 30px 20px;
    max-width: 426px;
}

.signup-user-limit-exceed-msg1{
    color: #f12a2aeb !important;
}


.on-boarding-root-content {
    padding: 2rem 0;
}
.on-boarding-root-content-form-title{
    margin-top: 5%;
}
.on-boarding-root-content-form-title-muted{
    font-size: 12px;
}
.on-boarding-root-content-form-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8%;
}

.on-boarding-root-content-form-save-button{
    float: right;
    background-color: #0135cd;
}

.on-boarding-next-step-admin,.on-boarding-next-step-employee{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding:0 !important;
    /* margin: 0 auto 24px; */
    /* padding: 16px 30px 20px; */
}
.on-boarding-next-step-admin-header{
    margin-bottom: 10px;
    border-bottom: 1px solid #ebecec;
    padding: 20px;
    color: #5296e4;
    font-size: 16px;
    font-weight: 700;
}
.on-boarding-next-step-admin-content{
    padding: 20px;
}
.on-boarding-next-step-admin-content-label{
    font-size: 16px;
    color: #000000ad;
    font-weight: 500;
}
span.on-boarding-next-step-admin-content-label-count {
    display: inline-block;
    margin-right: 10px;
    height: 27px;
    width: 27px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    color: #333;
}.on-boarding-next-step-admin-data{
    margin-bottom: 20px;
}


.account-root-footer{
    text-align: center;
    color:gray;
}
.account-root-footer a{
    color:#035FCB;
}

.on-boarding-root-content-form-settings-box-text{
    font-weight: 700;
}


.on-boarding-root-content-form-account-submit-button {
    width: 100%;
    height: 40px;
    margin: 15px 0 8px;
    padding: 7px 16px;
    background-color: #035FCB;
}

.on-boarding-root-content-form-invite-user{
    margin-top: 20px;
}
.on-board-modal{
    display: flex;
}
.onboard-side-text{
    background-color: #007bff;
}
.onboard-side-text-div-whole{
    position: relative;
    top: 35%;
    padding: 1%;
}
.onboard-side-text-div {
    color: white;
    font-size: 25px;
    font-weight: 700;
}
.error-link{
    color: red;
    font-size: 15px;
    /* float: right; */
    text-align: center;
    margin-top: 20px;
}
.error-link-admin-text {
    color: red;
    font-size: 15px;
    /* float: right; */
    text-align: center;
}
.error-signup{
    color: red;
    font-size: 14px;
    float: right;
}
.error-signup + .form-control {
    border-color: #F0624D;
}
.on-boarding-root-header-invite{
    margin-bottom: 4%;
    font-size: 18px;
}
.on-boarding-root-content-full-invite-err{
    color:green;
    font-weight: 600;
}
.on-boarding-root-content-full-icon-div{
    display: flex;
    justify-content: center;
}
.on-boarding-root-content-full-icon{
    width: 55px;
    height: 55px;
    margin-bottom: 10px;
    color: #464646;
}
.web-page-signup-terms-and-condition-text {
    font-size: 11px;
    color: #00000094;
    margin-bottom: -4px;
    margin-top: 5px;
}
.forms-pass{
    margin-bottom: 0.5rem !important;
}
.web-page-signup-password-text{
    color: #000000ab;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.member-overview{
    padding:40px;
}
.signup-recaptcha div div{
    /* width:auto !important;
    margin: 0 auto;
    display: table; */
}
.web-page-signup-employee-note-text{
    margin-top: 20px;
}
.web-page-signup-terms-and-condition-terms-text,.web-page-signup-terms-and-condition-condition-text{
    color:#00000094;
}
.on-boarding-root-content-full-invite-license-err{
    color:red;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}
.on-boarding-root-content-full-license-not-available{
    margin-top:30px;
}
.on-boarding-root-content-full-invite-invalid-err {
    color: red;
    font-weight: 600;
}
.web-page-signup-cloudflare-recaptcha iframe {
    width: 100% !important;
}