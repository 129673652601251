.review-usage-root {
  padding: 2rem;
}

/* review-usage-root div css start*/

.review-usage-root-list {
  /* height: 400px; */
  height: calc(100vh - 185px);
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.review-usage-root-list-no-data {
  height: 200px;
}

.review-usage-root-data-item .nav-item,
.review-usage-root-list .nav-item {
  color: #6c757d;
  border: none;
  border-bottom: 1px solid #dee2e6 ;
}

.review-usage-root-data-item .nav-item {
  padding: 1.3rem 1rem 0.9rem;
}

.review-usage-root-data-item .nav-item.active,
.review-usage-root-list .nav-item.active {
  color: #007bff;
  border-bottom: 4px solid #007bff;
}

.review-usage-root-list-tab-bar {
  border-bottom: 1px solid #ccc;
  font-size: 0.8rem;
}
.review-usage-root-list-tab-bar a.active {
  border-bottom: 3px solid #695eee;
  color: #695eee;
}
.review-usage-root-list-tab-bar-link {
  color: #718093;
  font-weight: 600;
  font-size: 0.7rem;
}
.review-usage-root-list-tab-bar-link:hover {
  color: #695eee;
}

.review-usage-root-list-by-tabs {
  padding: 2%;
}

.review-usage-root-list-no-text{
  font-size: .9rem;
  color: #2d3748;
  padding: 1.5rem!important;
}
/* review-usage-root-listing div css start*/
.review-usage-root-listing {
  padding: 2% 0% 1% 0%;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 260px; */
  height: calc(100vh - 307px);
}
.review-usage-root-listing-card {
  display: flex;
  height: 48px;
  background-color: #f8f9fa;
}
.review-usage-root-listing-card-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  background: #dc4c35b5;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.review-usage-root-listing-card-content {
  flex: 1;
  display: flex;
  align-items: center;
}
.review-usage-root-listing-card-content-avatar {
  /* text-transform: uppercase; */
  height: 20px;
  width: 20px;
  /* line-height: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  background-color: #8394ab; */
  margin-right: 8px;
}
.review-usage-root-listing-card-content-name {
  font-size: 0.8rem;
  color: #0000009c;
}
.review-usage-root-listing-card-button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.75rem;
}
.review-usage-root-listing-card-button-state {
  border: 1px solid #000000;
  border-radius: 25px;
  font-size: 13px;
  margin-right: 9%;
  padding: 1% 4%;
  cursor: pointer;
  font-weight: 700;
  background-color: transparent;
}

.review-usage-root-productive {
  color: green;
  border-color: green;
}
/* .review-usage-root-green:hover {
  background-color: darkolivegreen;
  color: white;
  border-color: darkolivegreen;
} */
.review-usage-root-neutral {
  color: #6c758a;
  border-color: #6c758a;
}
/* .review-usage-root-gray:hover {
  background-color: #718093;
  color: white;
  border-color: gray;
} */
.review-usage-root-unproductive {
  color: red;
  border-color: red;
}
.review-page-note-text{
  font-size: 13px;
  color:gray;
  /* margin:0; */
}
/* .review-usage-root-red:hover {
  background-color: #dc3545;
  color: white;
  border-color: #dc3545;
} */
/* review-usage-root div css end*/

/* review-usage-root-data div css start*/
.review-usage-root-data {
  width: 100%;
  display: grid;
  grid-template-columns: 370px minmax(0, 1fr);
  gap: 16px;
}

.review-usage-root-data-item {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

/* review-usage-root-data-app-listing div css start*/
.review-usage-root-data-app-listing-header {
  display: flex;
  padding: 0 24px;
  border-bottom: 1px solid #e8ebee;
}
.review-usage-root-data-app-listing-header-title {
  display: flex;
  align-items: center;
  height: 64px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #62839b;
}
.review-usage-root-data-app-listing-header-action {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #695eee;
}
.review-usage-root-data-app-listing-header-action-button {
  cursor: pointer;
  overflow: hidden;
  margin-left: auto;
}
.review-usage-root-data-app-listing-content {
  height: 510px;
  overflow: auto;
}
.review-usage-root-data-app-listing-content-data-card {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 12px;
  padding-left: 12px;
  /* padding-right: 25px; */
  padding-right: 15px;
  background: #fff;
  border: 1px solid #e8ebee;
  box-shadow: 0 2px 6px rgba(53, 59, 72, 0.05);
  border-radius: 5px;
  cursor: pointer;
}
.review-usage-root-data-app-listing-content-data-card-icon {
  height: 24px;
  width: 24px;
}
.review-usage-root-data-app-listing-content-data-card-name {
  flex: 1;
  font-weight: 600;
  margin: 0 0px;
  font-size: 14px;
  color: #2c3e50;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.review-usage-root-data-app-listing-content-data-card-time {
  font-weight: 600;
  font-size: 12px;
  color: #718093;
  margin-bottom: 0;
}
/* review-usage-root-data-app-listing div css end*/

/* review-usage-root-data-app-listing-data div css start*/

.review-usage-website-usage-per-employee {
  padding: 24px 24px 0;
  height:500px;
  /* overflow: hidden; */
  overflow-y: scroll;
}
.review-usage-website-usage-per-employee-table-content {
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  color: #34495e;
  padding-top: 1px;

  border-left: 1px solid #e8ebee;
  border-top: 1px solid #e8ebee;
  border-bottom: 1px solid #e8ebee;
  border-right: 1px solid #e8ebee;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

  margin-bottom: 10px;
  box-shadow: 0 2px 5px 0 rgba(53, 59, 72, 0.05);
}
.review-usage-website-usage-per-employee-header {
  height: 38px;
  min-width: 100%;
  overflow-x: hidden;
  background: 0 0;
  border-bottom: 0px solid #e8ebee;
  border-top: 0px solid #e8ebee;

  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  color: #718093;

  /* display: flex;
    align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  min-width: 300px;
  max-width: 300px;
}
.review-usage-website-usage-per-employee-header-first {
  min-width: 300px;
  max-width: 300px;
}
.review-usage-website-usage-per-employee-table-content-avatar {
  display: inline-block;
  margin-right: 12px;
  height: 24px;
  width: 24px;
  border-radius: 25px;
  background-color: rgb(64, 186, 171);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.review-usage-website-usage-per-employee-avatar {
  display: inline-block;
  margin-right: 12px;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background-color: rgb(227, 117, 117);
}
.review-usage-website-chart {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  overflow: hidden;
  /* margin-top: 6%; */
}
/* review-usage-root-data-app-listing-data div css end*/

/* review-usage-root-data div css end*/
/* review-usage-root div css end*/
.review-usage-root-title {
  margin-bottom: 0.9rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000000b5;
}
.review-usage-apps-and-website {
  padding: 5% 0% 2% 0%;
}
.app-summary-status {
  font-size: 13px;
  font-weight: 600;
  color: #718093;
  margin: 0 0px;
  text-transform: capitalize;
}
.app-summary-neutral{
  color:#6c757d !important;
}
.app-summary-productive{
  color:#28A745 !important;
}
.app-summary-unproductive{
  color:#DC3545 !important;
}
.app-summary-listing-whole-div {
  flex:1 1;
  margin: 0 8px;
}
/* .review-usage-app-and-website-tab-bar-link {
  border-bottom: 3px solid #695eee;
  color: #695eee;
} */
.review-usage-apps-and-website-tab-bar-link {
  padding: 0.97rem 1rem;
  font-size: 0.78rem;
}
.review-usage-apps-and-website-tab-bar {
  padding: 0 0.8rem;
}
.review-usage-root-data-app-listing-content-data-card-selected {
  background-color: aliceblue;
  border: 1px solid #1abc9c5c;
}
.review-usage-root-data-app-listing-content-data-selected {
  /* color: #0000ff8c; */
}
.review-usage-root-productive {
  min-width: 100px !important;
}
.review-usage-root-neutral {
  min-width: 80px;
}
.review-usage-root-unproductive {
  min-width: 120px;
}
.review-usage-website-usage-per-employee table td, .review-usage-website-usage-per-employee table th {
  vertical-align: middle !important;
}

.review-usage-website-usage-per-employee-table-profile-content{
  display: flex;
  width:230px;
}

.review-usage-user-profile {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.review-usage-user-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.review-app-use-per-employee-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-top: 3px;
  color: #34495e !important;
}
.review-app-use-per-team-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  width: 135px;
}
.review-usage-root-listing-whole-div{
    display: flex;
}
.exclude-idle-time-app-listing-whole-div{
  border: 1px solid #8080802e;
  padding: 13px !important;
}
.review-usage-root-listing-reviewed-button{
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 480px) {
  .review-usage-root-listing-whole-div{
    display: block;
    margin-bottom: 1.1rem !important;
  }
  .review-usage-root-listing-whole-div-names{
    margin-bottom: 10px;
  }
  .review-usage-root-listing-reviewed-button{
    font-size: 12px;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .review-usage-root-data{
    grid-template-columns:1fr;
  }
  .tab-content>.active {
    display: grid !important;
  }
  .review-usage-root-listing{
    padding: 7% 0% 1% 0%;
  }
  .head-risk-user-history-list-heading{
    font-weight: 600 !important;
  }
}
.review-usage-root-listing-card-content-copy-button {
  margin-left: 13px;
  color: #3b68eb !important;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 13px;
}
.review-usage-root-listing-card-content-copy-button:hover {
  color: #3b68eb !important;
  text-decoration: underline !important;
}
.review-page-heading-text-div{
  display: flex;
}
.review-page-export-div{
  margin-left: auto;
    margin-right: 0;
}
.review-apps-load-more-button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.review-usage-search-root-div{
  display: flex;
  justify-content: space-around;
  gap: 1%;
}
.review-usage-search-root-search-button-div{
  width: 20%;
}
.review-usage-root-listing-card-content-copied-status{
  margin-left: 13px;
  color: green !important;
  font-size: 15px;
  font-weight: 600;
}
.fade.top-review-app-list-card-toolbar-alert-text.alert.alert-warning.show {
  height: 40px;
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #808080b3;
  margin-bottom: 0px;
}
.review-usage-light-color-no-data{
  color: #585757;
}
/* .review-usage-search-root-search-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8a8a8b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(154 155 157 / 25%);
} */
.review-usage-root-title{
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
}

.search-not-found-text {
  width: 700px;
  word-wrap: break-word;
}
.review-usage-light-color {
  color: rgb(0 0 0 / 30%);
}
.top-review-usage-root-note-text {
  font-size: 13px;
  color: grey;
  margin-left: 10px;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .review-usage-root-listing-card-content-title {
    width: 200px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  } }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
  .review-usage-root-listing-card-content-title {
    width: 250px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .review-usage-root-listing-card-content-title {
    width: 355px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  } }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .review-usage-root-listing-card-content-title {
    width: 308px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .review-usage-root-listing-card-content-title {
    width: 665px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }}
@media (min-width:1281px) { /* hi-res laptops and desktops */
  .review-usage-root-listing-card-content-title {
    width: 417px;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
  }

  .review-comman-div{
    /* width:76%; */
  }
  .review-comman-div-split{
    min-width: 21rem;
    max-width: 23rem;
  }

}

  .review-usage-search-root-div-mobile{
    flex-direction: column;
  }
  .review-usage-search-root-search-button-div-mobile{
    width:100%;
    margin-top:10px;
  }
  .review-usage-load-more-button{
    padding: 5px 14px;
    font-size: 12px;
  }
  
  .review-loading-svg{
    font-size: 1.6rem;
    color: #266dfb;
    animation: spin 0.5s linear infinite;
  }
  .review-header-datas {
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
    display: flex;
    margin-bottom: 1rem;
    width:100%
}
.review-header-datas-app {
  width: 445px;
  margin-left: 10px;
}

.review-header-datas-hours{
  width: 237px;
}
.review-usage-root-listing-card-content-title{

}
.exclude-idle-time-app-content-remove-button{
  cursor: pointer;
}
.exclude-idle-time-app-content-add-button {
  padding: 2px 10px;
  font-size: 14px;
  border: 1px solid #80808070;
  cursor: pointer;
}
.exclude-idle-time-app-modal-text-1,.exclude-idle-time-app-modal-text-2 {
  font-size: 15px;
  margin-bottom: 15px;
}
.exclude-idle-time-app-modal-footer {
  display: flex;
  justify-content: left;
}
.exclude-idle-time-app-content-idle-label {
  margin-bottom: 10px;
  color: #7c81d9;
  font-size: 14px;
}
.exclude-idle-app-search-not-found-text{
 word-wrap: break-word;
  color: gray;
}
.exclude-idle-app-root-list-no-text {
  font-size: .9rem;
  color: #2d3748;
  padding: 0.5rem 1rem 1rem 0rem !important;
}