.user-details-page-root {
    margin: 2rem;
    background-color: #fff;
}
.user-details-page-wrapper-search-bar {
    width: 32% !important;
}

.user-details-page-wrapper-search-bar-div {
    display: flex;
    justify-content: center;
}
.user-details-page-wrapper-submit{
    width: 20%;
}
.user-details-page-wrapper-submit-button{
    padding: 7px 22px;
    margin-left: 14px;
}
.user-stats-page-dashboard-card-mail-div h4 {
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 7px !important;
}
.user-stats-page-content-root {
    margin-top: 3rem;
    height: 600px;
    background-color: #8080801a;
}
.user-stats-page-content-filter-label {
    padding: 2rem 2rem 1rem 2rem;
    text-align: center;
    font-weight: 600;
}
.user-stats-page-dashboard-card-root {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    margin-top: 3rem;
}
.user-stats-page-dashboard-card {
    padding: 15px 27px;
    background: #fff;
    /* border-radius: 5px; */
    border-width: 0.5px;
    border-style: solid;
    /* border-color: rgba(0, 0, 0, 0.08); */
    -o-border-image: initial;
    border-image: initial;
    /* width: 179px; */
    text-align: center;
    border-radius: 5px;
    border: 0.5px solid rgb(0 0 0 / 18%);
}
.user-stats-page-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
    margin-bottom: 10px;
}
.user-stats-page-dashboard-card-mail-div {
    height: 120px !important;
    overflow-y: scroll;
}
.user-stats-page-dashboard-card-no-data{
    text-align: center;
}