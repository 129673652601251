@keyframes spin {
    100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
  }

.loading-wrapper{
    text-align: center;
}
.loading-svg{
    font-size: 3rem;
    color: #266dfb;
    animation: spin 1.0s linear infinite;
}