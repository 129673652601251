.on-boarding-root {
    padding: 2rem;
}
.on-boarding-root-content {
    padding: 2rem 0;
}
.on-boarding-root-content-form-title{
    margin-top: 5%;
    text-align: center;
}
.on-boarding-root-content-form-title-muted{
    font-size: 12px;
    text-align: center;
}
.on-boarding-root-content-form-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8%;
}
@media screen and (max-width: 480px) {
    .on-boarding-root-content-form-settings {
        display: grid !important;
        grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    }
    .account-root-tick-icon-screen {
        position: initial !important;
        margin-right: 15px;
        margin-top: 4px;
    }
    .account-root-tick-icon-app{
        position: initial !important;
        margin-right: 15px;
        margin-top: 5px;
    }
    .account-root-tick-icon{
        position: initial !important;
        margin-right: 15px;
        margin-top: 4px;
    }
    .on-boarding-root-content-form-settings-box{
        margin-bottom: 0.9rem;
        display: flex;
    }
    .onboarding-remove-team {
        height: 35px;
        font-size: 10px !important;
    }
    .on-boarding-root-content-form-submit-div{
        display: flex;
        justify-content: center;
    }
    .on-boarding-root-content-form-title{
        text-align: left !important;
        margin-top: 37px !important;
        margin-bottom: 13px !important;
        font-size: 1.3rem !important;
    }
    .on-boarding-root-content-form-title-muted{
        text-align: left !important;
        font-size: 15px !important;
        color: gray !important;
        margin-bottom: 28px !important;
    }
    .on-boarding-root-header{
        margin-top: 10px !important;
        margin-bottom: 21px !important;
    }
    .on-boarding-root-header-color{
        color:gray;
    }
    .on-boarding-root-header-team{
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
    .on-boarding-root-content-form-save-button-team{
        font-size: 15px !important;
        /* height: 37px !important; */
    }
    
}
.on-boarding-root-content-form-settings-box {
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 30px;
    margin-right: 5px;
    cursor: pointer;
}
.on-boarding-settings-worked-days-selected {
    color: #427ff6;
    background-color: #eef3fc;
    border: 1px solid #ededed;
}
.onboarding-company-name-textbox{
    height: 50px;
}

.on-boarding-root-content-form-submit-button{
    float: right;
    background-color: #0135cd;
}
.on-boarding-root-content-form-settings-box:hover {
    transition: all .2s ease-out;
    box-shadow: 4px 4px 7px 1px #e9ecef;
    border: 1px solid #ededed;
}
.onboarding-remove-team{
    color: #dc3545ab;
    border-color: #dc35456e;
    font-size: 14px;
    /* float:right; */
    margin-left: 25%;
}
.onboarding-footer-button{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.onboard-add-new-team{
    margin-top: -13px;
}
.on-boarding-root-content-form-skip-button{
    float: left;
    color: #fff;
    background-color: #6c757d9c;
    border-color: #ffffff;
}
/* .on-boarding-root-content-form-skip-button:hover{
    float: left;
    color: #14429e;
    background-color: #ffffff;
    border-color: #ffffff;
    text-decoration: underline;
} */
.on-boarding-root-content-form-save-button{
    float: right;
    background-color: #0135cd;
}
.on-boarding-root-content-form-save-button-team{
    /* float: right; */
    margin-left: 26%;
    background-color: #0135cd;
}

.on-boarding-next-step-admin,.on-boarding-next-step-employee{
    border: 1px solid #ebecec;
    border-radius: 2px;
    padding:0 !important;
    margin: 0 auto;
    margin-bottom: 15px;
}
.on-boarding-next-step-admin-headers{
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecec;
    padding: 15px;
    color: #5296e4;
    font-size: 16px;
    font-weight: 700;
}
.on-boarding-next-step-admin-contents{
    padding: 15px 15px 5px 15px;
}
.on-boarding-next-step-admin-content-label{
    font-size: 16px;
    color: #000000ad;
    font-weight: 500;
    display: inline-block;
}
.on-boarding-next-step-admin-content-label a{
    color: #035FCB;
}
span.on-boarding-next-step-admin-content-label-count {
    display: inline-block;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    /* background: #e3e3e3; */
    border: 2px solid rgba(0,0,0,.1);
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #333;
}.on-boarding-next-step-admin-datas{
    margin-bottom: 10px;
    display: flex;
}
.step3-header{
    /* margin-top:2%; */
}
.on-boarding-next-step-admin-and-user{
    margin-top: 2rem;
}
.account-creation-logo-full{
    margin-bottom: 10px;
}
.account-root-img-responsive {
    margin-top: -7px !important;
}
.on-boarding-root-content-full {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 20px 30px 20px;
    max-width: 426px;
}
.account-root-footer{
    text-align: center;
    color:gray;
}
.account-root-footer a{
    color:#0135cd;
}
.account-root-tick-icon {
    color: #427ff6;
    /* position: absolute;
    right: 28px;
    top: 8px; */
    position: absolute;
    top: 5%;
    /* left: 94%; */
    right: 25px;
    display: none;
}
.account-root-tick-icon-screen {
    color: #427ff6;
    position: absolute;
    /* top: 8px;
    left: 143px;
    right: 0;  */
    position: absolute;
    top: 5%;
    left: 19%;
    display: none;  
}
.account-root-tick-icon-app {
    color: #427ff6;
    /* position: absolute;
    top: 8px;
    left: 480px;
    right: 0;   */
    position: absolute;
    top:5%;
    left: 64%;
    display: none;
}
.account-root-tick-icon-screen-none{
    display: inline;
}
.on-boarding-root-content-form-settings-box-text{
    font-weight: 700;
}
.account-creation-logo-text {
    font-size:20px;
    font-weight:600;
    color:#1862c4;
    margin-left:5px;
}
h1.on-boarding-root-header {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
h1.on-boarding-root-header-team {
    font-weight: 400 !important;
    font-size: 2rem !important;
    padding-bottom: 20px;
}
.on-boarding-root-content-form-account-submit-button {
    width: 100%;
    height: 40px;
    margin: 15px 0 8px;
    padding: 7px 16px;
    background-color: #0135cd;
}
.onboard-company-name-lable {
    font-size: 1.20rem;
    font-weight: 500;
    color: #000000ab; 
}
.on-boarding-root-content-form-invite-user{
    margin-top: 20px;
}
.on-boarding-root-content-form-invite-user-row{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    /* margin-left: 7%; */
}
.on-board-modal{
    display: flex;
}
.onboarding-step-badges {
    padding: 5px 10px 7px 10px;
    color: #fff;
    background-color: #6c757d7d;
    margin-bottom: 2%;
}
.onboard-side-text{
    background-color: #007bff;
}
.onboard-side-text-div-whole{
    position: relative;
    top: 35%;
    padding: 1%;
}
.onboard-side-text-div {
    color: white;
    font-size: 25px;
    font-weight: 700;
}
.onboarding2-free-plan-team-limit-label {
    font-size: 15px;
    color: #f16443b3;
    font-weight: 500;
}
.on-boarding-unauthorized-text{
    margin: 2rem;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    color: #ff0000ba;
}
.onboarding-footer-div {
    margin: 2rem;
    color: #ff0000d1;
}
.onboarding-footer-div-login {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
}