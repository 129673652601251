.leave-settings-card {
    padding: 2rem;
}
.leave-settings-row > * {
    margin-bottom: 1rem;
}

.leave-settings-row-grid, .leave-settings-row-grid-1, .leave-settings-row-grid-2 {
    
    /* -moz-columns: 2 calc((100vw - 420px - 8rem) / 2);
    columns: 2 calc((100vw - 420px - 8rem) / 2); */
    grid-column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.leave-settings-row-grid-card {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: calc((100vw - 417px - 7rem) /2);
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
    
}
.leave-settings-row-grid-card-list-div {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: 100%;
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.permission-row-grid-card-list-div {
    /* height: auto; */
    display: inline-table;
    margin-bottom: 1rem;
    width: calc((100vw - 417px - 7rem) /2);
    color: rgb(74, 85, 104);
    /* overflow: auto; */
    padding: 16px;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    -o-border-image: initial;
    border-image: initial;
}
.leave-setting-type-card{
    margin-right: 1rem;
}
.leave-setting-type-card-member{
    margin-right: 1rem;
}
.leave-setting-type-card-member-margin{
    margin-right: 0;
}
.leave-settings-row-grid-card h6 {
    color: black;
}
.leave-settings-worked-days-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 13px;
    margin: 15px;
}

.leave-settings-worked-days-data label {
    cursor: pointer;
}
.leave-settings-worked-days-data {
    width: 100%;
    /* height: calc(1.7em + 0.75rem + 2px); */
    padding: 0.55rem 0.55rem;
    /* font-size: 0.8rem; */
    font-size: 14px;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 1rem;
    /* cursor: pointer; */
    /* text-align: center; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.leave-settings-worked-days-data-type-edit-icon {
    font-size: 14px;
    color: #ccc;
    margin-right: 8px;
    cursor: pointer;
}
.leave-settings-worked-days-data-type-delete-icon {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
} 
.leave-settings-worked-days-data-type-edit-icon .svg:focus {
    color: #007bff !important;
}
.leave-settings-worked-days-data-type-delete-icon:focus {
    color: #007bff !important;
} 
.leave-settings-worked-days-data-type-edit-icons {
    font-size: 14px;
    color: #ccc;
    margin-right: 6px;
    cursor: pointer;
}
.leave-settings-worked-days-data-type-edit-icons .svg:focus {
    color: #007bff !important;
}

.permissions-settings-worked-days-data-type-delete-icon {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 10px;
} 
.permissions-settings-worked-days-data-type-delete-icon:focus {
    color: #007bff !important;
} 

.leave-settings-filter-status-user-select {
    margin-bottom: 15px;
    font-size: 14px;
}
.leave-setting-filter-type-text{
    font-size: 12px;
    color: gray;
    /* margin-bottom: 20px; */
    margin-top:0px;
}
.leave-setting-filter-status-text{
    font-size: 12px; 
    color: #ff000082;
    margin-bottom: 14px;
    margin-top:-12px
}
.leave-status-err{
    color: #ff0000eb;
    font-size: 14px;
    text-align: center;
}
.leave-settings-worked-days-data label {
    cursor: pointer;
}
.leave-settings-row-leave-type-label {
    display: flex;
    justify-content: space-between;
}
.leave-settings-worked-days-list-form-group {
    margin-top: 14px;
}
.leave-settings-worked-days-list-form-group-type{
    max-height: calc(100vh - 251px);
    overflow-x: hidden;
    overflow-y: auto;
}
.leave-settings-worked-days-list-form-group-type-permission {
    max-height: calc(100vh - 251px);
    overflow-x: hidden;
    overflow-y: auto;
}
.leave-permission-worked-days-data-type-view-icon-span{
    color:gray;
    text-decoration: underline;
}
.leave-settings-worked-days-list-form-label {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    text-decoration-color: #007bff;
}
.leave-settings-worked-days-list-form-label-disable {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    text-decoration-color: #007bff;
}
.leave-settings-worked-days-list-form-label-archive {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #007bff;
    font-weight: 500;
    text-decoration-color: #007bff;
    margin-right: 20px;
}
.leave-settings-modal-button {
    margin: auto;
    padding: 9px 0;
}
.leave-settings-load-more-button {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}
.leave-type-setting-seen-all-data{
    margin: 0;
    font-size: 13px;
    color: #808080b8;
}
.leave-settings-modal-close-button, .leave-settings-modal-save-button {
    font-size: 0.9rem;
    padding: 0.4rem 1.3rem;
    margin-right: 1rem;
    font-weight: 500;
}
.leave-settings-modal-title.modal-title {
    font-size: 17px;
    text-align: center;
    width: 90%;
}
.leave-settings-edit-task-label-input {
    font-size: .86rem!important;
    font-weight: 600!important;
}
.leave-settings-edit-task-label-input::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}
.leave-settings-edit-task-label-name-label {
    /* font-size: .95rem;
    font-weight: 500;
    color: rgba(0,0,0,.6705882352941176); */
    margin-left:40px;

    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}

.leave-settings-edit-task-label-name {
    /* font-size: .95rem;
    font-weight: 500;
    color: rgba(0,0,0,.6705882352941176); */

    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.leave-settings-no-data-text {
    font-size: 14px;
    text-align: center;
    color: gray;
    margin: 15px;
}
.leave-settings-modal-update-settings {
    font-size: 0.86rem;
    padding: 0.3rem 0.7rem;
    margin-right: 1rem;
    font-weight: 500;
}
.leave-settings-modal-save-button-div {
    text-align: center;
    /* margin-bottom: 11px; */
}

.leave-setting-time-option-div {
    display: flex;
    /* width: 195px; */
}
.leave-type-edit-disable-button{
    font-size: .86rem!important;
    font-weight: 600!important;
    height: calc(1.7em + 0.75rem + 2px);
    background-color: #e9ecef;
    opacity: 1;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: not-allowed;
}
.leave-setting-time-option-div-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.35rem;
    font-size: 0.86rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.leave-setting-type-header-text{
    font-size: 13px;
    display: flex;
    margin-bottom: -5px;
}
.leave-setting-type-header-text-type{
    /* margin-right: 5px; */
    width: 38%;
    padding-left: 10px;
}
.leave-width-allowed{
    width: 20%;
}
.leave-width-cycle{
    width: 15%;
}
.leave-width-reset{
    width: 15%;
}
.leave-type-err {
    font-size: 14px;
    margin-top: 2px;
}
.leave-width-option{
    width: 12%;
    display: flex;
    justify-content: space-around;
}
.leave-settings-worked-days-data-type {
    margin-right: 5px;
    width: 38%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.leave-setting-row-color{
    color: #000;
}

.leave-type-row{
    display: flex;
    justify-content: center;
}
.leave-setting-mute-text{
    color: #7788998c;
}

.leave-setting-modal-full-div .modal-dialog {
    max-width: 450px;
}
.leave-setting-modal-content-row.row {
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
}
.leave-setting-permission-modal-full-div .modal-dialog {
    max-width: 410px;
}
.short-leave-error {
    color: red;
    font-size: 13px;
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}
.leave-setting-span-help-text {
    font-size: 12px;
    color: gray;
    margin: 5px 0px 0px 5px;
}
.leave-type-settingts-checkbox label{
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
}
.leave-type-settingts-checkbox input {
    margin-right: 5px;
}
.leave-setting-type-wrapper-div {
    border: 1px solid #d3d3d3;
    padding: 17px 0px 3px 0;
    border-radius: 6px;
    background-color: hsla(0,0%,50.2%,.050980392156862744);
    margin: 0 40px 8px 40px;
}

.leave-settings-filter-status-user-select-wrapper {
    display: grid;
    grid-template-columns: 1fr 70px;
    grid-gap: 1rem;
}
.leave-settings-worked-days-data-type-archive-icon {
    margin-right: 7px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}
.leave-setting-filter-type-root-tab {
    background-color: transparent;
}
.leave-setting-filter-type-root-tab .nav-link.active,.leave-setting-filter-type-root-tab .nav-link {
    background-color: transparent;
    font-size: 13px;
}
.leave-settings-worked-days-data-type-unarchived {
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    color: gray;
}
.leave-setting-filter-year-type-text {
    font-size: 12px;
    color: gray;
    margin-bottom: 20px;
    margin-top: 0px;
}
.leave-settings-worked-year-list-form-group {
    /* margin-top: 14px; */
}
.leave-setting-filter-type-root-tab .nav-link.active, .leave-setting-filter-type-root-tab .nav-link {
    background-color: transparent;
    font-size: 13px;
  
}
.leave-setting-filter-type-root-tab .nav-link.active {
    border-bottom: 1px solid #007bff !important;
}



.leave-permission-modal-content-name {
    /* display: flex; */
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}
.leave-permission-label-input {
    font-size: 0.9rem !important;
}
.leave-setting-flef-row {
    /* display: flex; */
    justify-content: space-between;
}
.leave-permission-add-time-option-div {
    display: flex;
    width: 285px;
}
.leave-permission-total-allowed-request{
    width: 285px;
}
.leave-permission-per-name{
    width: 285px;
}
.leave-permission-width-option {
    /* width: 12%; */
    display: flex;
    /* justify-content: space-between; */
}
.leave-permission-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 4px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}
.leave-permission-label-input::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}

.leave-permission-modal-radion-button > input[type="radio"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
}

.leave-permission-modal-radion-button > label {
  font-size: 0.9rem;
  cursor: pointer;
  color: #495057;
}

.leave-permission-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
}
.leave-permission-card-table thead th {
    border: none;
    font-weight: 500;
}
.leave-permission-card-table th,
.leave-permission-card-table td {
    border: none;
}
.leave-permission-card-table-header {
    color: rgb(74, 85, 104);
    border: none;
    font-size: 13px;
}
.leave-permission-card-table-data-details {
    padding: 0.7rem ;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    font-size: 14px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-bottom: 15px;
}
.edit-div-permission{
    cursor: pointer;
}
.task-overview-detail-modal-body-permission.modal-body {
    margin: 0;
    padding: 0;
    height: 245px;
    overflow-y: scroll;
}
.task-overview-detail-modal-body-archived-permission.modal-body {
    margin: 0;
    padding: 0;
    height: 245px;
    /* overflow-y: scroll; */
}
.scheduled-reports-card-table-header-permission {
    color: rgba(83,92,104,.6392156862745098);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
}
.permission-whole-div {
    display: flex;
    /* margin-bottom: 1rem; */
    justify-content: space-between;
}
.permission-card-table-data-details-report-name {
    width: 90% !important;
}
.permission-card-table-data-details-report-type {
    width: 35% !important;
    text-align: right;
}
.leave-permission-worked-days-data-type-view-icon{
    margin-right: 7px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}