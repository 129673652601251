.edit-user-details-root {
    margin: 2rem;
}
.edit-user-details-list-time .rc-time-picker-input{
    height:39px;
}
.edit-employees-root-content-data-details-avatar {
    display: flex;
    align-items: center;
}
.edit-employees-root-content-data-details-avatar-circle {
    height: 30px;
    width: 30px;
    font-weight: bold;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
}
.timesheet-exclude-text {
    font-size: 12px;
    font-weight: 200;
    /* margin-top: 5px; */
}
.edit-employees-root-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    width: 166px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.edit-user-details-list-date {
    width: 8rem;
}
.edit-user-details-list-time{
    width: 6rem;
}
.edit-user-details-list-worked-time{
    width: 10rem;
}
.edit-user-details-div-root{
    /* width: 100% !important; */
    /* overflow-x: auto !important; */
    width: 100%;
    height: 80vh;
    overflow: auto;
}

.edit-user-details-root-content-table{
    /* border-collapse: separate;
    border-spacing: 0 8px; */
}
    
.edit-user-details-div-content{
    display: grid;
}
.edit-user-details-list-worked-time input::placeholder{
    font-size: 12px;
}

.edit-user-details-filter-content {
    display: flex;
    margin-bottom: 2rem;
}
.edit-user-details-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.edit-user-details-list-select-team-error-text {
    color: red;
    margin-top: 6px;
    font-size: 12px;
}
.edit-user-details-list-select-team-error {
    border: 1.5px solid red;
    border-radius: 4px;
}
.edit-user-details-date-picker {
    margin-right: 20px;
}
.edit-user-details-user-search::placeholder {
    color: #8080806b;
}
.edit-user-details-list-time .rc-time-picker-clear{
    margin-top: 4px !important;
}
.edit-user-details-root-content-data-details td:first-child {
    /* border-left: 1px solid #0000000d !important; */
    
}
.edit-user-details-root-content-data-details td:last-child{
    /* border-right: 1px solid #0000000d !important; */
}
.edit-user-details-root-content-data-header tr th{
    font-weight: 600 !important;
    color: rgba(0,0,0,.5803921568627451) !important;
    font-size: 0.9rem !important;
}
.edit-user-details-root-content-data-header {
    font-weight: 600 !important;
    color: rgba(0,0,0,.5803921568627451);
    font-size: 0.9rem;
}
.edit-user-details-root-content-data-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.06);
    font-size: 0.9rem;
}
.edit-user-details-root-table-body tr td {
    vertical-align: middle !important;
    font-size: 0.9rem;
}
.edit-user-details-root-content-data-header{
    position: sticky;
    top: 0;
    z-index: 1;
}
.edit-user-details-root-content-table thead>tr th:first-child, .edit-user-details-root-content-table thead>tr th:nth-child(2) {
    background-color: #f3f7fa!important;
    vertical-align: middle;
}
.edit-user-details-root-content-table tbody>tr td:first-child,.edit-user-details-root-content-table tbody>tr td:nth-child(2) {
    background-color: #f3f7fa !important;
}
.edit-user-details-root-content-th-sticky-col{
    position: sticky;
    background-color: #ffffff !important;
    min-width: 8rem ;
    max-width: 8rem ;
    text-align: left !important;
    z-index: 9 !important;
    
}
.edit-user-details-root-content-table tbody>tr td:first-child {
    background-color: #f3f7fa !important;
}
.first-col{
    left: 0; 
    min-width: 15rem !important; 
    max-width: 15rem !important; 
}
.second-column{
    left: 15rem !important; 
}
.edit-user-details-root-content-data-header th,.edit-user-details-root-table-body tr {
    border-bottom: 1px solid #dee2e6!important;
}
.edit-user-details-root-content-td-edit-button {
    border: 0px;
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 15px;
    color: gray;
    text-decoration-color: gray !important;
}
.edit-user-details-date-picker .react-datepicker__tab-loop .react-datepicker-popper{
    z-index: 9999 !important;
}
.edit-user-details-root-content-data-header{
    background-color: #f3f7fa!important;
    vertical-align: middle;
    z-index: 99 !important;
}

.edit-user-details-modal-label-name {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.edit-user-details-list-modal-input {
    font-size: .9rem!important;
}
.edit-user-details-list-modal-time {
    width: 100%;
}
.edit-user-details-modal-body {
    padding: 15px 30px;
    /* height: 100vh; */
    /* overflow-y: scroll !important; */
}
.edit-user-details-list-modal-time input{
    height: calc(1.5em + 0.75rem + 2px) !important;
    font-size: .9rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057 !important;
}
.medit-user-details-list-modal-content-name-field {
    flex: 1 1;
    margin-bottom: 0rem;
    margin-right: 16px;
    margin-bottom: 20px;
}
.edit-user-details-list-modal-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.edit-user-details-list-modal-content-name-field {
    margin-right: 0 !important;
}
.edit-user-details-list-modal-button {
    margin: auto;
    padding: 9px 0;
}
.edit-user-details-list-modal-close-button, .edit-user-details-list-modal-save-button {
    font-size: 0.9rem;
    padding: 0.3rem 1.3rem;
    margin-right: 1rem;
    font-weight: 500;
}
.edit-employees-root-content-data-details-name-data a{
    color: #212529;
}
.edit-user-details-list-modal-team-error {
    font-size: 12px;
    margin-bottom: 8px !important;
}
.edit-user-details-list-modal-select-team-error{
    float: left !important;
}
.edit-user-details-list-modal-input::placeholder,.edit-user-details-list-modal-input input::placeholder{
    font-size: 12px !important ;
    color: #0d0e104f !important;
    font-weight: 400 !important;
}
.edit-user-details-modal-body form .form-group {
    margin-bottom: 1.5rem;
}

@media screen and (min-width: 786px) {
    .row > * {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.edit-user-details-modal-text-label-name {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    /* margin-bottom: 8px; */
}
.edit-user-details-modal-text-label-wrapper {
    margin-bottom: 1rem;
}
.edit-user-details-modal-text-label-name {
    margin-bottom: 4px;
}
.edit-user-details-modal-text-label-value {
    color: gray;
    font-size: 14px;
    font-weight: 500;
}
.edit-user-details-div-root-windows-scroll::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
}

.edit-user-details-div-root-load-more-button {
    z-index: 100;
    left: 0;
    position: sticky;
    text-align: center;
}
.employee-edit-card-avatar-profile {
    width: 27px;
    height: 27px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
}
.edit-employees-root-content-data-details-edit-name-data a {
    color: gray !important;
    width: fit-content;
    font-size: 14px;
}
.edit-employees-root-content-data-details-edit-avatar-circle{
    height: 30px;
    width: 34px;
    font-weight: bold;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
}
.edit-employees-root-content-data-details-edit-edit-time-div {
    margin-bottom: 15px;
}
.edit-employees-root-content-data-details-edit-edit-task-date {
    display: flex;
    flex-direction: column;
}
.edit-employees-root-content-data-details-edit-edit-label {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
.edit-employees-root-content-data-details-edit-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 7px;
    height: 37px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.edit-employees-root-content-data-details-edit-wrapper-filter-date-date-section {
    /* display: flex; */
    align-items: center;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.edit-employees-root-content-data-details-edit-calender-icon-div {
    display: flex;
    color: black;
}
.edit-employees-root-content-data-details-edit-section-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    /* margin-left: 5px; */
    cursor: pointer;
}
.edit-user-details-modal-text-label-name-edit {
    color: rgba(83,92,104,.9882352941176471);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px !important;
}