.project-detail-task-detail-modal .modal-content{
    /* background-color: rgb(252, 252, 252); */
    height: 680px;
    overflow-x: hidden;
}
.project-detail-task-detail-modal-title.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.project-detail-task-detail-modal-title-project-name {
    font-size: 13px;
    color: gray;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 1rem;
}
.project-details-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.project-details-task-details-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.5px solid #00000014;
    /* margin-left: 2rem; */
    /* background-color: white; */
    background-color: rgb(252, 252, 252);
}
.project-details-task-details-employee-list-card-body {
    padding: 0 1.3rem 1rem;
}
.project-details-task-details-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-details-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-details-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-details-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 1px 0px 0 rgb(0 0 0 / 6%);
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-details-employee-list-card-details p {
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
}
.project-details-task-details-employee-list-card-data-table th, .project-details-task-details-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-detail-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-details-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-detail-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-detail-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 670px;
    overflow-y: scroll;
}
.project-detail-task-detail-tab .nav-tabs > a {
    color: #212529;
    font-size: 14px;
    padding: 7px 8px;
    font-weight: 500;
}
.project-detail-task-detail-tab .nav-tabs > a.active {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs .nav-link:hover {
    border: none;
    color: #157af6b5;
}
.project-detail-task-detail-tab .nav-tabs .nav-link.active:hover {
    border-bottom: 4px solid #007bff;
    color: #007bff;
    border-right: none;
    border-left: none;
    border-top: none;
}
.project-detail-task-detail-tab .nav-tabs{
    padding: 0 1rem;
}
.project-detail-task-detail-modal-header{
    padding: 1rem;
    /* border-bottom: none !important; */
    margin: 0 0 0.1rem;
}
.project-detail-task-detail-tab{
    /* margin: 0rem 1rem 1rem 1rem; */
}
.project-detail-task-detail-edit-modal-body-container {
    margin: 2rem 1rem;
}



.project-detail-task-detail-edit-modal-body-container-timeline {
    margin: 0rem 1rem;
}
.project-details-task-timeline-employee-list-card {
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 0.5px solid #00000014; */
    /* margin-left: 2rem; */
    /* background-color: white; */
    /* background-color: rgb(252, 252, 252); */
}
.project-details-task-timeline-employee-list-card-body {
    /* padding: 0 1.3rem 1rem; */
}
.project-details-task-timeline-employee-list-card-data {
    display: grid;
    grid-template-columns: 1fr;
    /* margin-top: 1rem; */
    font-size: 0.87rem;
    font-weight: 500;
    color: #000000b5;
}
.project-details-task-timeline-employee-list-card-data-table {
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    margin-bottom: 0.5rem;
}
.project-details-task-timeline-employee-list-card-data-table th, .project-details-task-timeline-employee-list-card-data-table td {
    border: none;
    /* vertical-align: middle; */
}
.project-details-task-timeline-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
    /* margin-bottom: 0.9rem; */
    /* margin-top: 0.5rem; */
}
.project-details-task-timeline-employee-list-card-data-header {
    color: #797777db;
    border: none;
    font-size: 14px;
}
.project-details-task-timeline-employee-list-card-details {
    margin-top: 1rem;
    padding: 1rem 0;
    border: 1px solid #0000000d;
    font-size: 15px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%), 0 1px 0px 0 rgb(0 0 0 / 6%);
}
.project-details-task-timeline-employee-list-card-details-avatar {
    display: flex;
}
.project-details-task-timeline-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-timeline-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-timeline-load-more-button {
    /* margin-top: 2rem; */
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.project-details-task-timeline-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.project-details-task-timeline-date-divider-label-icon {
    margin-right: 10px;
    /* color: #495057eb; */
}
.project-details-task-timeline-employee-list-card-details-avatar p{
    margin-bottom: 0rem;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
    align-items: center;
}

.timeline-container::after {
    background-color: #80808030;
    content: '';
    position: absolute;
    left: calc(23% - (-4px));
    width: 4px;
    height: 100%;
}
.timeline-item {
    /* display: flex;
    justify-content: flex-end; */
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 53%;
}

.timeline-item:nth-child(odd),.timeline-item:nth-child(even) {
    /* align-self: flex-end;
    justify-content: flex-start; */
    padding-left: 30px;
    padding-right: 0;
}
.timeline-item-content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(252, 252, 252);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 13px;
    position: relative;
    width: 430px;
    max-width: 100%;
    text-align: right;
}

.timeline-item-content::after {
    content: ' ';
    background-color: rgb(252, 252, 252);
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}
.timeline-item-content .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item:nth-child(even) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}
.timeline-item-content time {
    color: #777;
    font-size: 12px;
    font-weight: bold;
}

.timeline-item-content p {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0;
    max-width: fit-content;
}

.timeline-item-content a {
    font-size: 14px;
    font-weight: bold;
}

.timeline-item-content a::after {
    content: ' ►';
    font-size: 12px;
}

.timeline-item-content .circle {
    background-color: #fff;
    border: 3px solid #80808030;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
.timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -38px;
}
@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content,.timeline-item:nth-child(even) .timeline-item-content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    }

    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
    }

    .timeline-item-content time {
        margin-top: 20px;
    }

    .timeline-item-content a {
        text-decoration: underline;
    }

    .timeline-item-content a::after {
        display: none;
    }
}
p.project-details-task-activity-history-event-text {
    display: flex;
}
.project-details-task-activity-history-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.project-details-task-activity-history-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.project-details-task-activity-history-card-avatar {
    height: 25px;
    width: 25px;
    background-color: #ededed;
    color: #424242;
    font-weight: bold;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.5rem;
    border-radius: 25px;
}
.task-tracker-date-header-tr{
    margin-top: 20px;
    margin-bottom: 10px;
}
.task-tracker-date-header-first-tr{
    margin: 10px 0px;
}
.project-details-task-details-card h4 {
    font-size: 17px;
}


.project-detail-manual-task-detail-modal .modal-content {
    /* background-color: rgb(252, 252, 252); */
    /* height: 680px; */
    overflow-x: hidden;
}
.project-detail-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 300px;
    overflow-y: scroll;
}
/* .project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
   margin: 2rem;
} */
.project-details-manual-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.project-details-manual-task-details-card-title {
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project-details-manual-task-details-card h4 {
    font-size: 20px;
}
.project-details-manual-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 250px;
    overflow-y: scroll;
}
.project-details-manual-task-detail-edit-modal-body-container {
    margin: 2rem;
}
.project-details-manual-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.project-details-manual-task-detail-edit-modal-body-reviewed{
    height:365px !important;
}
.project-task-detail-track-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 600px;
    overflow-y: scroll;
}
.add-task-task-details-load-more-button{
    text-align: center;
}

.project-task-time-mapping-modal-app-website-usage {
    padding: 25px 30px 0;
    height: 500px;
    /* overflow: hidden; */
    overflow-y: scroll;
}
.project-task-time-mapping-modal-app-website-usage-header {
    height: 38px;
    min-width: 100%;
    overflow-x: hidden;
    background: 0 0;
    border-bottom: 0px solid #e8ebee;
    border-top: 0px solid #e8ebee;
    line-height: 18px;
    font-weight: 600;
    font-size: 14px;
    color: #718093;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 300px;
    max-width: 300px;
}
.project-task-time-mapping-modal-app-website-usage table td, .project-task-time-mapping-modal-app-website-usage table th {
    vertical-align: middle !important;
}
.project-task-time-mapping-modal-app-website-usage-table-content {
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    color: #34495e;
    padding-top: 1px;
    border-left: 1px solid #e8ebee;
    border-top: 1px solid #e8ebee;
    border-bottom: 1px solid #e8ebee;
    border-right: 1px solid #e8ebee;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgb(53 59 72 / 5%);
}
.project-task-time-mapping-modal-app-website-usage-card-icon {
    height: 24px;
    width: 24px;
}

.project-task-time-mapping-modal-ScreenShots-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    grid-gap: 0.8rem;
}
.project-task-time-mapping-modal-screenshot-card {
    /* margin: 1rem auto; */
    margin-bottom: -1%;
    /* max-width: 245px; */
    width: 100%;
    padding: 1rem;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    border: 1px solid #d3d3d3;
    /* border: 1px solid #17b5ab; */
    border-radius: 3px;
}
.project-task-time-mapping-modal-screenshot-card-header {
    display: flex;
    align-items: center;
}
.project-task-time-mapping-modal-screenshot-card-header-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}
.project-task-time-mapping-modal-screenshot-card-header-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* color: #34495e; */
    font-weight: 400;
    flex-grow: 1;
    font-size: 12px;
}
.project-task-time-mapping-modal-screenshot-card-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    margin: 12px 0px;
}
.project-task-time-mapping-modal-screenshot-card-thumbnail-image {
    width: 100%;
    height: 73px;
    background-color: #e8ebee;
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
    font-weight: 600;
    font-size: 14px;
    /* line-height: 16px; */
    /* color: #718093; */
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar-timer {
    margin-right: 12px;
    display: flex;
    align-items: center;
    /* flex-shrink: 0; */
    /* color: #718093; */
    font-size: 10px;
    /* margin-left: auto; */
    margin-right: 0px;
}
.project-task-time-mapping-modal-screenshot-card-footer-information-bar-timer-time {
    font-size: 10px;
    margin-left: 4px;
}

.project-task-time-mapping-modal-screenshot {
    background-color: #00000099;
}
.project-task-time-mapping-modal-screenshot .modal-dialog {
    max-width: 50% !important;
    margin-top: 4rem;
}
.project-task-time-mapping-modal-screenshot .modal-dialog .modal-content {
    background-color: white;
}
.project-task-time-mapping-modal-screenshot-close {
    position: absolute;
    right: -88px;
    padding: 0 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    color: white;
    margin: 0.2rem;
    opacity: 0.6;
    top: -77px;
}
svg.project-task-time-mapping-modal-screenshot-right-nav {
    position: absolute;
    right: 0;
    top: 46%;
    bottom: 0;
    color: white;
    right: -10%;
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0.4;
    cursor: pointer;
    z-index: 1;
}
.project-task-time-mapping-modal-screenshot-body {
    text-align: center;
    margin: 1.5rem;
}
.project-task-time-mapping-modal-screenshot-personal-header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}
.project-task-time-mapping-modal-screenshot-personal-header-details {
    display: flex;
}
.project-task-time-mapping-modal-screenshot-site-icon-content {
    height: 30px;
    width:30px;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    border-radius: 50%;
    color: #fff;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #5b5c5f21;
}
.project-task-time-mapping-modal-screenshot-site-icon {
    height: 14px;
    vertical-align: initial;
    margin-top: 8px;
}
.project-task-time-mapping-modal-screenshot-site-icon-details {
    margin-top: 2px;
    text-align: left;
}
.project-task-time-mapping-modal-screenshot-site-name {
    font-weight: 600;
    font-size: 15px;
    color: #000000a6;
}
.project-task-time-mapping-modal-screenshot-user-name {
    font-size: 14px;
    float: left;
    color: #f1306f;
    margin-top: -3px;
}
.project-task-time-mapping-modal-screenshot-time-details {
    font-size: 15px;
    margin-right: 11px;
}
svg.project-task-time-mapping-modal-screenshot-time-icon {
    font-size: 14px;
    margin-right: 4px;
    margin-top: -2px;
}

.project-task-time-mapping-modal-screenshot-screenshot-image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
}
svg.project-task-time-mapping-modal-screenshot-left-nav {
    position: absolute;
    color: #fff;
    top: 46%;
    left: -10%;
    bottom: 0;
    width: 1.2rem;
    height: 1.2rem;
    opacity: .4;
    cursor: pointer;
    z-index: 1;
}
.project-task-time-mapping-modal-screenshot-load-more-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
.project-task-time-mapping-modal-screenshot-load-more-button button {
    font-size: 13px;
}
.task-tracker-task-details-card-description{
    margin-bottom: 1.5rem;
}