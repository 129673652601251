.workfolio-affiliates-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}
.workfolio-affiliates-header {
    background-color: #0096FF;
    color: white;
    text-align: center;
    padding: 2rem 1rem;
}
.workfolio-affiliates-header img {
    width: 300px;
    margin-top: 1rem;
    border-radius: 50%;
}
.workfolio-affiliates-header h1 {
    margin: 0;
    font-size: 2.5rem;
}
.workfolio-affiliates-header p {
    margin: 0.5rem 0 0 0;
}
.workfolio-affiliates-content {
    padding: 2rem;
}
.workfolio-affiliates-section {
    margin-bottom: 2rem;
}
.workfolio-affiliates-section h2 {
    color: #0096FF;
    border-bottom: 2px solid #0096FF;
    padding-bottom: 0.5rem;
}
.workfolio-affiliates-section p {
    margin: 0.5rem 0;
    line-height: 1.6;
}
.workfolio-affiliates-list-item {
    margin: 1rem 0;
}
.workfolio-affiliates-apply-button {
    display: block;
    width: 200px;
    margin: 2rem auto;
    padding: 0.6rem 1rem !important;
    background-color: #0096FF;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
}
.workfolio-affiliates-queries {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e0e0e0;
}
.workfolio-affiliates-queries p {
    margin: 0.5rem 0;
}
.workfolio-affiliates-img-responsive {
    display: block;
    max-width: 65px;
    height: auto;
    /* margin-top: 33px !important; */
}
.workfolio-affiliates-img-center {
    margin: 0 auto;
}