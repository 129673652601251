.login-root {
    padding: 2rem;
}

/* login-root-header start */

.login-root-header{
    /* padding-top: 60px; */
    padding-bottom: 35px;
}
img.login-root-img-center {
    margin: 0 auto;
}
.login-root-img-responsive {
    display: block;
    max-width: 45px;
    height: auto;
    margin-top: 33px !important;
}
.login-root-title{
   font-weight: 400;
   font-size: 2rem;
}

/* login-root-header end */

/* login-root-content start */
.login-root-content {
    border: 1px solid #ebecec;
    border-radius: 2px;
    margin: 0 auto 24px;
    padding: 16px 30px 20px;
    max-width: 426px;
}
.web-login-form {
  /* display: grid;
  gap: 16px; */
  width: 100%;
}
.web-login-form input{
    margin-bottom: 20px;
}
.login-field-error-message-login{
    float:right;
    color:red;
    font-size: 14px;
}
.web-login-submit-button{
    /* background-color: #0135cd; */
    background-color: #035FCB;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    /* font: 600 23px/1.42 Geogrotesque,sans-serif; */
    height: 48px;
    margin: 0 0 24px;
    padding: 7px 16px;
    width: 100%;
}
/* .web-login-submit-button:hover{
    background-color: #6f0ca6;
} */
/* login-root-content end */

/* login-root-footer start */
.login-root-footer a {
    color: #035FCB;
    text-decoration: none;
}
/* login-root-footer end */
.login-root-member-permission{
    text-align: center;
    margin: 5rem 13rem;
}
.login-root-member-permission-icon{
    color: #3F38C8;
    font-size: 60px;
}
.login-root-member-permission-header-text {
    font-size: 24px;
    font-weight: 700;
    margin: 12px;
    color: #3f38c8db;
}
.login-root-member-permission-sub-text {
    font-size: 15px;
    margin-top: -7px;
    color: #3f38c896;
}
