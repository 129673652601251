.offline-task-card {
  margin: 2rem;
}
.offline-task-list {
  border: 1px solid #00000014;
  width: 41rem;
  padding: 0.9rem;
  margin: 1rem;
}
span.offline-task-name {
  font-size: 0.8rem;
}
span.offline-task-time {
  float: right;
  font-size: 0.7rem;
}
.offline-task-user-details {
  display: flex;
}

.offline-task-user-details-avatar {
  height: 20px;
  width: 20px;
  background-color: rgb(241 122 52 / 83%);
  color: rgb(244 245 249);
  font-weight: bold;
  line-height: 20px;
  font-size: 11px;
  text-align: center;
  margin-right: 0.3rem;
  border-radius: 50%;
}
.offline-task-user-data {
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}
span.offline-task-user-details-user-name {
  font-size: 0.75rem;
  margin-top: -2%;
}
.offline-task-time-icon {
  font-size: 0.7rem;
  margin-top: -3%;
}
.offline-task-card-title{
    font-size: 1.1rem;
    font-weight: 600;
    color: #000000a6;
    margin: 1rem;
}