.risk-rule-setting-root {
    margin: 2rem;
}
.risk-rule-setting-root-rule-button {
    display: flex;
    justify-content: flex-end;
}

/* risk Modal CSS */
.risk-rule-modal .modal-dialog {
    max-width: 56%;
}
.risk-rule-modal-header .close {
    font-size: 1.2rem;
    margin-top: -3%;
}
.risk-ex-text{
    color:gray;
    font-size: 13px;
}
.no-data-risk-setting{
    text-align: center;
    margin-top: 5%;
    font-size: 16px;
}
.risk-setting-rule-type-color{
    color:black;
}
.risk-rule-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: #000000a8;
  }
.risk-modal-data-error{
    margin-top: 5px;
}
.risk-rule-modal-body .form-label.rule-setting-err {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}

.risk-rule-modal-body .form-error {
    font-size: smaller;
    color: red;
    margin-bottom: 0.25rem;
    text-align: center;
}
.risk-rule-modal-body .form-label {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.rule-sub-div-heading {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
    margin:0;
}
.risk-rule-condition-section {
    display:flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color:rgb(0 0 0 / 55%);
    font-weight: 600;
}
.risk-rule-condition-section-hourList {
    width: 140px;
    margin-bottom: 0px;
}
.risk-rule-condition-section-hourList .form-control{
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.risk-rule-condition-section-hourList-whole-div {
    margin: -6px 5px 0px 5px;
}
.risk-rule-modal-degree {
    display: flex;
    gap: 0.75rem;
    /* padding: 0.5rem 0; */
}
.risk-setting-field-array{
    display: flex;
}
.risk-rule-condition-section-list-text{
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color: #1e1e1e!important;
    display: flex;
    font-size: 14px;
    margin: 10px auto 10px auto;
}
.risk-setting-and-text {
    font-size: 14px;
    /* margin-top: 0%; */
    color: rgb(0 0 0 / 55%);
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.risk-rule-modal-degree-radion-button > input[type="radio"] {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    cursor: pointer;
}
.risk-rule-condition-section-days {
    display: flex;
    margin-top: 1.3rem;
    margin-left: 2rem;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: rgb(0 0 0 / 55%);
    font-weight: 600;
}
.risk-setting-add-div {
    position: absolute;
    bottom: 0;
    right: 5px;
    margin: 0px 0px 8px 0px;
}
.risk-rule-modal-degree-radion-button > label {
    font-size: 0.95rem;
    cursor: pointer;
}

.risk-rule-modal-create-button {
    margin-bottom: 10px;
    margin-top: 1.5rem;
}
.risk-rule-modal-button {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.4rem 2rem;
}
.risk-rule-modal-button-clear {
    font-size: 1.2rem;
    padding: 0.4rem 2rem;
    background-color: #bdbdbd;
    border: #bdbdbd;
}

/* rule listing css */
.risk-rule-setting-root-card-data {
    /* display: grid; */
    grid-template-columns: 1fr;
    /* margin-top: 2rem; */
    font-size: 0.9rem;
    font-weight: 400;
    color: #6c757d !important;
}
.risk-rule-setting-root-card-content {
    border-radius: 5px;
    background: #fcfcfc;
    padding: .6rem;
    margin-bottom: 2rem;
}
.risk-rule-setting-root-card-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
}
.risk-rule-setting-root-card-table thead th {
    border: none;
}
.risk-rule-setting-root-card-table th,
  .risk-rule-setting-root-card-table td {
    border: none;
}
.risk-rule-setting-root-card-table-header {
    color: #868686;
    border: none;
}
.risk-rule-setting-card-table-body {
    color: #333;
}
.risk-rule-setting-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.risk-rule-setting-card-table-data-details-report-name{
    width:28% !important;
}
.risk-rule-setting-card-table-data-details-report-type{
    width:35% !important;
}
.risk-rule-setting-card-table-data-details-content{
    width:25% !important;
}
.risk-rule-card-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.risk-setting-remove-button {
    margin-top: 0px;
    color:#007bff;
    font-size: 19px;
    cursor: pointer;
}
.risk-setting-remove-button:hover {
    /* box-shadow: 0px 0px 0 2px #f8f9fa; */
    border: 1px solid #e9ecef;
    background-color: rgba(233,236,239,.4392156862745098);
}
.risk-setting-remove-button:focus{
    border:none;
    box-shadow: none;
}
.risk-setting-add-button:focus{
    border:none;
    box-shadow: none;
}
.risk-rule-condition-section-list{
    display: flex;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
}
.risk-rule-condition-section-list-1{
    margin-right: 4px;
}
.risk-rule-condition-section-list-2{
    margin-right: 4px;
}
tr.risk-rule-setting-card-table-data-details td {
    vertical-align: middle;
}


@media screen and (max-width: 480px) {
    .risk-rule-setting-card-table-data-details{
      font-size: 12px;
    }
    .risk-rule-setting-root-card-content{
        padding: 1rem 0;
    }
    .risk-setting-add-div {
        position: initial; 
        bottom: 0;
        right: 0;
        margin: 0px 0px 8px 0px;
    }
    .risk-setting-field-array {
        display: initial;
    }
    .risk-rule-condition-section{
        display: inline;
    }
    .rule-sub-div-heading{
        margin-bottom: 1rem;
    }
    .risk-rule-condition-section-mobile-view-div {
        display: flex;
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 12px;
    }
    .risk-rule-condition-section-hourList .form-control{
        font-size: 11px;
    }
    .risk-rule-condition-section-hourList{
        width: 97px;
    }
    .risk-rule-condition-section-hourList-whole-div {
        margin: -6px 8px 0px 8px;
    }
    .risk-setting-and-text{
        margin-bottom: 0rem;
    }
    .risk-rule-condition-section-mobile-border{
        border: .5px solid rgba(0,0,0,.08);
        padding: 1rem;
    }
    .risk-rule-card-table-data-icon-div{
        display: inline;
        padding: 2px 0px 2px 10px;
    }
}