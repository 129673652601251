.workfolio-maintenance-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    margin: 160px auto;
    max-width: 750px;
}
.workfolio-maintenance-header {
    /* background-color: #0096FF; */
    color: #1e1e1e;
    text-align: center;
    padding: 2rem 1rem 0rem 1rem;

    display: flex;
    align-items: center;
}
.workfolio-maintenance-img-responsive {
    display: block;
    max-width: 50px;
    height: 50px;
}
.workfolio-maintenance-img-center {
    /* margin: 0 auto; */
    margin-right: 8px;
}
.workfolio-maintenance-body-content{
    margin-left: 16px;
    margin-right: 16px;
}
.workfolio-maintenance-body-p{
    font-weight:600;
}
.workfolio-maintenance-body-content-button {

    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #6c757d;
    border-color: #6c757d;

    margin-right:50px;
}
.workfolio-maintenance-body-content-button-1 {

    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #6c757d;
    border-color: #6c757d;
}
.workfolio-maintenance-body-content-button-div {
    margin: 37px auto;
    max-width: 340px;
    margin-bottom: 0px;
}

.workfolio-maintenance-header-time {
    font-size: 1rem;
    color: #28a745;
    text-align: center;
    margin-bottom: 1rem;
}
.workfolio-maintenance-header-h2{
    margin-bottom: 0;
}
.workfolio-maintenance-body-content-success-p{
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
}
.workfolio-maintenance-body-content-button-div-success {
    margin: 37px auto;
    max-width: 235px;
    margin-bottom: 0px;
}
.workfolio-maintenance-body-content-button-1-success{
    color: #035fcb;
    text-decoration: underline;
    margin-left: 5px;
}
.workfolio-maintenance-footer{
    text-align: center;
    margin-top: 0.5rem;
}