.task-tracker-task-detail-modal-header {
    padding: 1rem;
    /* border-bottom: none !important; */
    margin: 0 0 0.1rem;
}
.task-tracker-task-detail-modal-title.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.task-tracker-task-detail-modal-title-new.modal-title {
    font-size: 18px;
    font-weight: 600;
}
.task-tracker-task-detail-modal-title-project-name {
    font-size: 13px;
    color: gray;
}
.task-tracker-task-detail-edit-modal-body.modal-body {
    margin: 0;
    padding: 0;
    height: 360px;
    overflow-y: scroll;
}
.task-tracker-task-detail-edit-modal-body-completed{
    height: 360px !important;
}
.task-tracker-task-detail-tab {
    /* margin: 0rem 1rem 1rem 1rem; */
}
.task-tracker-task-detail-edit-modal-body-container {
    margin: 2rem;
}
.task-tracker-task-details-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(195px,1fr));
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.task-tracker-task-details-card {
    /* background: #fff; */
    background-color: rgb(252, 252, 252);
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.task-tracker-task-details-card-title {
    font-size: .85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.task-tracker-task-details-card h4 {
    font-size: 17px;
}
.task-tracker-task-details-card-value-none {
    opacity: 0.7;
}
.task-tracker-task-details-card-value-due-crossed {
    color: #ea4c4cf0 !important;
}
.task-tracker-project-card-task-tracker-table-description-priority-root {
    margin-top: 6px;
    float: right;
}

.task-tracker-project-card-task-tracker-table-description-priority-root {
    margin-top: 6px;
    float: right;
}
.task-tracker-task-status-badge {
    float: left;
    margin-top: 9px;
    padding-top: 4px;
    font-size: 11px;
}