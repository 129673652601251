.chargebee-subscription-root{
    margin:2rem;
}


  table.chargebee-subscription-root-table{
    border:1px solid #ccc;
    margin-top:20px;
  }
table.chargebee-subscription-root-table > thead > tr > th{
    border:1px solid #ccc;
}
table.chargebee-subscription-root-table > tbody > tr > td{
    border:1px solid #ccc;
}