.real-time-notification-modal-body-form-div-wrapper {
    display: flex;
    margin-top: 11px;
    /* justify-content: space-between; */
}
.real-time-condition-section-who-to-monitor{
    display: flex;
    justify-content: space-between;
}
.real-time-notification-modal .modal-dialog{
    max-width: 45%;
} 
 .real-time-notification-modal-body {
    margin: 13px;
}

.real-time-notification-modal-title {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(0,0,0,.6588235294117647);
}
.real-time-notification-modal-body .form-label, .rule-sub-div-heading {
    font-size: 14px;
    color: rgba(0,0,0,.7019607843137254);
    font-weight: 600;
}
.real-time-notification-modal-body-form-start-time .rc-time-picker-input {
    height: 2.4rem;
    font-size: 0.9rem;
}
.real-time-condition-section-who-to-monitor-value .form-check-input{
cursor: pointer;
}
.real-time-condition-section-who-to-monitor-value .form-check-label {
    cursor: pointer;
    color: #495057;
    font-size: 0.9rem !important;
}
.real-time-notification-modal-body-form-div-type{
    margin-right: 10px;
}
.real-time-notification-modal-body .form-label.real-time-notification-err {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}
.real-time-notification-modal-body-form-control{
    font-size: 0.9rem !important;
}
.real-time-notification-modal-body-form-start-time .rc-time-picker-clear-icon{
    display: none;
}



.real-time-notification-root {
    margin: 2rem;
}
.real-time-notification-root-rule-button {
    display: flex;
    justify-content: space-between;
}
.real-time-notification-root-card-data {
    /* display: grid; */
    grid-template-columns: 1fr;
    /* margin-top: 2rem; */
    font-size: 0.9rem;
    font-weight: 400;
    color: #6c757d !important;
}
.real-time-notification-root-card-content {
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.real-time-notification-root-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
}
.real-time-notification-root-card-table-header {
    color: #868686;
    border: none;
}
.real-time-notification-root-card-table thead th {
    border: none;
}
.real-time-notification-card-table-body {
    color: #333;
}
.real-time-notification-card-table-data-details {
    padding: 1rem 0;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
tr.real-time-notification-card-table-data-details td {
    vertical-align: middle;
}
.real-time-card-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.real-time-notification-card-table-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.real-time-notification-card-table-data-edit-text {
    margin-bottom: 0;
}
.real-time-card-table-data-action-wrapper{
    display: flex;
    justify-content: space-around;
}
.real-time-notification-root-card-table td, .real-time-notification-root-card-table th, .real-time-notification-root-card-table thead th {
    border: none;
}
.real-time-notification-card-table-loader{
    text-align: center;
}
.real-time-notification-modal-body-form-condition-text {
    font-size: 12.4px;
    color: gray;
    font-weight: 400;
    margin-bottom: 1rem;
}
.real-time-notification-modal-body-condition-form-group.form-group {
    margin-bottom: 3px;
}
.real-time-notification-modal-body .form-label.error {
    font-size: 14px;
    color: red;
    float: right;
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-weight: 500;
}
.real-time-notification-modal-body .real-time-notification-modal-body-form-control.is-invalid div {
    border-color: #dc3545 !important;
}
.real-time-notification-coming-soon {
    text-align: center;
    margin-top: 42px;
    font-size: 19px;
    color: #007bff !important;
}
.real-time-notification-modal-body-condition-form-group .rc-time-picker-clear {
    display: none !important;
}

.real-time-notification-alert-time-duration-text {
    margin: 1rem;
    font-size: 15px;
    color: #ff0000b0;
}
.real-time-notification-alert-time-duration-text a{
    text-decoration: underline;
    cursor: pointer !important;
    color: blue;
}
.real-time-notification-alert-time-duration-text a:hover{
    text-decoration: underline;
    cursor: pointer !important;
    color: blue;
}