.over-time-report-tbody-tr-td-absent{
    color: #8080808a !important;
    font-size: 13px !important;
}
.overtime-report-date-picker .react-datepicker__tab-loop .react-datepicker-popper{
    z-index: 999 !important;
}
.overtime-report-thead {
    position: sticky;
    top: 0px;
    z-index: 42;
}
.over-time-report-tbody-tr-td-over-time-data{
    background-color:#f0a3a394
}