.daily-attendance-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.daily-attendance-filter-new-root {
    margin: 2rem;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.daily-attendance-tr-table-grid-staus{
    width:30%;
}
.daily-attendance-tr-table-grid-leave-name{
    width:40%;
}
.daily-attendance-filter-root-tab{
    background-color: #fff;
}
.daily-attendance-filter-content {
    display: flex;
}
.daily-attendance-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.daily-attendance-filter-download-icon {
    /* color: #007bff; */
    font-size: 1.1rem;
    font-weight: 700;
}
/* .daily-attendance-filter-download-icon:hover {
    color: #fff !important;
} */
.daily-attendance-filter-download-icon-btn:hover {
    color: #fff !important;
}
.daily-attendance-content {
    width: 100%;
    height: calc(100vh - 215px);
    overflow: auto;
}
.daily-attendance-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin:2rem;
}
.daily-attendance-content-new th,.daily-attendance-content-new td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.daily-attendance-table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #dee2e6;
}
.daily-attendance-absent-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
}
.daily-attendance-table-load-more-btn{
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.daily-attendance-filter-user-tab-filter {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-filter-user-tab-filter .loading-wrapper .loading-svg {
    font-size: 35px;
}
.daily-enable-expected-clockin-track-settings {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 45px auto;
}
.daily-no-data-tab{
    text-align: center;
}
.daily-attendance-table-load-more-tr td{
    border :none !important;
}
.daily-attendance-table-load-more-td{
display: flex;
justify-content: center;
}
.daily-attendance-root{
    margin:2rem;
}
.daily-attendance-content th,
td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;

}
.daily-attendance-table thead tr th{
    background-color: #F3F7FA !important;
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
}
.daily-attendance-table tbody tr td{
    color: #000000d6 !important;
    font-size: 0.9rem;
}
.daily-attendance-table thead tr th,.daily-attendance-table tbody tr td{
    padding: 0.7rem !important;
}

.daily-attendance-absent-table thead tr th{
    background-color: #F3F7FA !important;
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
}
.daily-attendance-absent-table tbody tr td{
    color: #000000d6 !important;
    font-size: 0.9rem;
}
.daily-attendance-absent-table thead tr th,.daily-attendance-absent-table tbody tr td{
    padding: 0.7rem !important;
}
.daily-attendance-tr-table-leave-status-uninformed {
    /* font-size: 14px; */
    background-color: #ff000033;
    /* width: fit-content;
    padding: 3px 8px;
    border-radius: 4px; */
}
.daily-attendance-tr-table-leave-status-approved {
     background-color: #00800040;
}
.daily-attendance-tr-table-leave-status-rejected {
    background-color: rgba(255, 166, 0, 0.515);
}
.daily-attendance-tr-table-grid-employee-id {
    color: gray;
    font-size: 12px;
    margin-top: 2px;
}
.daily-attendance-tr-table-grid-employee-avatar-circle {
    height: 28px;
    width: 28px;
    font-weight: bold;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
    background-color: rgb(128, 179, 255);
    margin-right: 0.75rem;
    border-radius: 30px;
    text-transform: uppercase;
    margin: auto 0;
    margin-right: 12px;
    /* position: absolute; */
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
}
.daily-attendance-tr-table-grid-employee-displayname-wrapper{
    display: flex;
}
.daily-attendance-tr-table-grid-employee-name {
    margin-top: 3px;
}

