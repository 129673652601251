
.billing-root{
    padding: 2rem;
}
.billing-root-header{
    /* display:flex;
    border: none; */
    /* margin-bottom: 40px; */
}
.billing-root-header-flex{
    display: flex;
    justify-content: space-between;
}
.billing-root-header-expire-date{
    color: #ff4000;
    font-size: 14px;
    line-height: normal;
}
.billing-root-header-add-payment-button{
    /* display: flex;    
    flex-flow: column wrap;
    align-content: space-between; */
}
.billing-root-header-add-payment-button{
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-between; 
}
.billing-root-header-add-new-card-button{
    /* margin-left: auto;
    margin-right: 0; */
    margin-left: auto;
    order: 2;
}  

.billing-root-content{
    display: grid;
    /* grid-template-columns: repeat(auto-fill,minmax(210px,1fr)); */
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 20px;
}
.billing-root-content-card{
    padding: 20px;
    background-color: #f8f9fa;
    border: .5px solid rgba(0,0,0,.08);
    margin-bottom: 20px;
}
.billing-root-content-card-dark-header{
    color: #404751;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 56px;
}
.billing-root-content-card-dark-header-add-margin{
    margin-bottom: 56px;
}
.billing-root-content-card-header{
    color: #252d33;
    font-size: 13px;
    line-height: 16px;
    margin-top: 0;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
}
.billing-root-content-card-muted-header{
    color: #6e747a;
    font-size: 14px;
    line-height: normal;
    margin: 0 0 10px;
}
.billing-root-content-card-price {
    font: 600 32px/normal Geogrotesque, sans-serif;
    color: #404751;
}
.billing-root-content-button {
    border: 2px solid #007bff;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 16px;
    text-transform: uppercase;
}
.change-plan-modal-modal-close{
    position: absolute;
    right: -88px;
    padding: 0 0.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    color: white;
    margin: 0.5rem;
    opacity: 0.6;
    top: -65px;
}
.change-plan-modal-body {
    /* text-align: center; */
    /* margin: 1.5rem; */
    margin: 1.5rem 0;
}
.change-plan-modal-body-content-current-plan{
    font-size: 15px;
}

.change-plan-modal-body-content-card-groups{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 40px;
}
.change-plan-modal-body-content-card {
    border-radius: 3px;
    cursor: pointer;
    padding: 37px;
    /* width: 400px; */
    width: 370px;
    display: flex;
    flex-direction: column;
}
.change-plan-modal-body-content-card-selected{
    background-color: #2B405A;
    border: solid 1px #5792e154;
    /* box-shadow: 0 0 12px 0 rgb(0 0 0 / 25%); */
    color: white;
}
.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-header h3 {
    color: white;
}
.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-content .change-plan-modal-body-content-card-content-list .plan-check-icon {
    color: white;
}
.change-plan-modal-body-content-card:hover{
    /* background-color: #5792e105; */
    border: solid 1px #5792e154;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.change-plan-modal-body-content-card-header h3{
    color: #404751;
    margin: 0 0 8px;
    margin-bottom: 18px;
    font-size: 13px;
    font-weight: 700;
}
.change-plan-modal-body-content-card-price-value{
    font-size: 50px;
}
.change-plan-modal-body-content-card-header h2{
    margin-top: 0;
    /* color: #fff; */
    font-size: 17px;
    text-align: left;
}
.free-text{
    color: #266dfb;
    font-size: 1.8rem;
}
.length-text{
    color: #848199;
    font-size: 16px;
}
.change-plan-modal-body-content-card-content{
    /* margin-top: 25px; */
}
.change-plan-modal-body-content-card-content-list {
    display: flex;
    font-size: 0.9rem;
    text-align: left;
    font-weight: 500;
    margin-bottom: 20px;
}
.change-plan-modal-body-content-card-content-list p{
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 17px;
    line-height: 30px;
}
.plan-check-icon{
    margin-top: 4px;
    margin-right: 10px;
    font-size: 19px;
}
.plan-check-icon-cross{
    color: red;
    margin-top: 4px;
    margin-right: 10px;
}
.change-plan-modal-body-footer{
    margin-top: 3%;
}
.change-plan-modal-body-footer-close{
    margin-right: 3%;
    margin-left: 2%;
}
.change-plan-modal-body-footer-submit{
    /* margin-right: 1%; */
}
.add-card-payment-button{
    font-size: 14px;
    font-weight: 600;
}
.change-plan-modal-body-content-card-header {
    /* margin-bottom: 40px; */
}
.change-plan-modal-body-content-card-button {
    margin: 24px 0px;
    margin-top: auto;
}
.billing-root-content-subscribe-button{
    /* border: 2px solid #007bff; */
    font-size: 18px;
    font-weight: bold;
    padding: 8px 23px;
    /* text-transform: uppercase; */
}
.billing-root-content-subscribe-button.btn-danger{
   background-color: #EE5738;
   
}
.billing-add-card-payment-modal-title.modal-title.h4 {
    font-size: 1rem;
    color: black;
}
.billing-add-card-payment-button-icon{
    margin-top: -2px;
    font-size: 11px;
    margin-right: 5px;
}
.billing-add-card-payment-modal-card-details {
    width: fit-content;
    padding: 12px 26px;
    background-color: #3668f3d6;
    color: white;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
}

.billing-root-header-back-nav-icon {
    color: blue;
    font-size: 19px;
    margin-left: 35px;
    cursor: pointer;
}

.change-plan-modal-body-content-card-content-list-strike-out{
    text-decoration:line-through;
    text-decoration-color: #fcfcfc8f;
}

.change-plan-modal-body-content-card-selected .change-plan-modal-body-content-card-content .change-plan-modal-body-content-card-content-list-strike-out{
    text-decoration:line-through;
    text-decoration-color: #fcfcfc8f;
}
.change-plan-modal-body-content-card-content-list-strike-out p{
    margin-left: 22px;
    font-weight: 300;
}
.change-plan-modal-body-content-card-price-details {
    margin-bottom: 30px;
    line-height: 21px;
    margin-top: 19px;
    font-weight: 300;
    font-size: 13px;
}
.change-plan-modal-body-content-card-price-symbol{
    position: relative;
    top: -16px;
    right: 3px;
    font-size: 26px;
}

.change-plan-modal-body-content-card-price-details-current-plan {
    color: #e67511;
    font-size: 20px;
    font-weight: 700;
}
.change-plan-modal-body-content-card-price-details-current-plan-period{
    font-size: 12px;
    margin-top: 5px;
    color: #F1C40F;
    font-weight: 700;
}
.billing-root-header-add-payment-status {
    background-color: #e615153d;
    padding: 15px;
    font-size: 17px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
}
.billing-root-header-add-payment-status div{
    margin-top: 9px;
}
.billing-root-header-add-payment-success{
    background-color: #0080004d;
}
.billing-root-header-add-payment-in_progress{
    background-color: #8047004d;
}
.billing-root-header-add-payment-failure,.billing-root-header-add-payment-timeout{
    background-color: #ef51414d;
    color: #ec7255;
}
@media screen and (max-width: 480px) {
    .change-plan-modal-body-content-card-groups{
        display: inline;
    }
    .change-plan-modal-body{
        margin: 0;
    }
    .change-plan-modal-body-content-card{
        width: auto;
    }
}



.content {
    display: flex;
    justify-content: center;
    padding: 0.7rem 2rem;
    /* width: 400px; */
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #217f94;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(14, 14, 172);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 0 4px #96d8e7;
    outline: none;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:checked + .slider {
    /* box-shadow: 0 0 0 4px rgb(154, 154, 236); */
    outline: none;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  span.pricing-inr,.pricing-usd {
    padding: 5px 13px;
    font-weight: 700;
}
.billing-currency-filter-label {
    text-align: center;
    font-weight: 500;
    color: #656464;
    margin-top: 10px;
}
.pricing-currency-inr-selected{
    color: #217f94;
}

.pricing-currency-usd-selected{
    color:rgb(14, 14, 172);
}


.billing-wrapper,
.billing-wrapper .tab-pane,
.billing-wrapper .tab-content {
  height: 100%;
}
@media screen and (max-width: 480px) {
  .billing-wrapper-root{
  padding: 2rem;
  }
}
.billing-wrapper .nav-tabs > a {
  color: #343a40;
}

.root-layout-page .nav-link {
  background-color: rgb(114, 110, 110);
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.billing-wrapper .nav-tabs > a.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}
.billing-plan-downgrade-confirmation-modal {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #2c3e50;
}
.billing-plan-downgrade-confirmation-modal-text{
    font-size: 15px;
    color:#222223;
    margin-top: 30px;
    font-weight: 500;
}
.billing-plan-downgrade-confirmation-modal-cancel-button, .billing-plan-downgrade-confirmation-modal-delete-button {
    font-size: 0.9rem;
}
.billing-add-card-payment-modal:before{
    opacity:0.8 !important;
 }
 .change-plan-modal-body-content-card-footer {
    margin-top: 40px;
    color: gray;
    text-align: center;
    font-size: 15px;
}
.billing-root-billing-notification {
    margin: 32px;
}
.billing-root-billing-notification-button {
    font-size: 15px;
    font-weight: 500;
}
.billing-root-billing-notification-content{
    color: #4a5568;
    padding: 16px;
    background:white;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}
.billing-root-billing-notification-button-div{
    margin-top:10px;
}
.change-plan-modal-body-content-card-footer-notes {
    margin-top: 16px;
    /* color: gray; */
    font-weight: bold;
    /* text-align: center; */
    padding: 15px;
    border: 1px solid #0000001c;
    margin: 2rem;
}
.change-plan-modal-body-content-card-footer-notes-text{
    padding: 0 0 0 20px;
    margin: 25px;
    text-align: center;
}
.change-plan-modal-body-content-card-footer-notes-text-li-tag{
    margin-bottom: 7px;
}
.billing-filter-root {
    display: flex;
    justify-content: space-around;
}
.pricing-currency-monthly-selected {
    color: #16680d;
}
.pricing-currency-anually-selected {
    color: rgb(172 123 14);
}
.plan-type-slider-round{
    background-color: #16680d;
}
input:checked + .plan-type-slider-round {
    background-color: rgb(172 123 14);
}
input:focus + .plan-type-slider-round {
    box-shadow: 0 0 0 4px #a8bcc1;
    outline: none;
}


.pill-button {
    display: inline-block;
    position: relative;
    padding: 3px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    background: #157AF6;
    border-radius: 28px;
    color: #fff;
    z-index: 0;
    margin-left: 114px;
}

.pill-button-input {
    display: none;
    visibility: hidden;
}

.pill-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 38px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}

.pill-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.4em 0.9em;
    cursor: pointer;
    z-index: 2;
}

.pill-button-selection_active {
    color: #157AF6;
}
 
.pill-currency-button {
    display: inline-block;
    position: relative;
    padding: 3px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    background: #8b8d8f7d;
    border-radius: 28px;
    color: white;
    z-index: 0;
}

.pill-currency-button-input {
    display: none;
    visibility: hidden;
}

.pill-currency-button-highlight {
    transform: translate(0,-50%);
    transition: all 0.2s;
    position: absolute;
    top: 50%;
    left: 4px;
    height: 38px;
    width: 90px;
    background: #fff;
    border-radius: 22px;
    z-index: 1;
}

.pill-currency-button-selection {
    transition: all 0.2s;
    display: inline-block;
    position: relative;
    padding: 0.4em 0.9em;
    cursor: pointer;
    z-index: 2;
}

.pill-currency-button-selection_active {
    color: #090a0ac7;
    font-weight: 600;
}
.billing-plan-type {
    width: 79%;
    text-align: center;
}
.billing-currency-type{
    width: 22%;
}
.plan-head-stopped-warning-div {
    width: 700px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 18px;
}
.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-name-text{
    font-weight: 600;
    margin-bottom: 11px;
}
.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-main-text,.plan-head-stopped-warning-div .plan-warning-modal-body-div .plan-warning-modal-body-div-secondary-text{
    font-weight: 500;
    margin-bottom: 11px;
}
h2.change-plan-modal-body-content-card-price-yearly-summary {
    display: flex;
    font-size: 14px;
}
.change-plan-modal-body-content-card-price-yearly-summary .length-text{
    font-size: 14px;
}
.change-plan-modal-body-content-card-price-root {
    margin-bottom: -2px !important;
}
.billing-help-text {
    text-align: center;
    margin: 0.8rem 1rem 2rem 1rem;
    color: green;
    font-weight: 600;
    font-size: 18px;
}
.billing-plan-downgrade-confirmation-modal-project-selection-error{
    color: red !important;
    font-size:13px !important;
}
.billing-plan-downgrade-confirmation-modal-project-select{
    margin:20px 0 0px 0;
}
.billing-plan-downgrade-confirmation-modal-project-select-label{
    margin-bottom:10px
}
.billing-plan-downgrade-confirmation-modal-project-warning-text {
    margin-top: 25px;
    font-size: 15px;
    line-height: 21px;
    color: #2c3e50;
}
.billing-plan-downgrade-confirmation-modal-project-select div{
    /* font-size: 15px; */
    line-height: 21px;
    /* color: #2c3e50; */
}
.form-control-text-area-form-div-field-textarea-input::placeholder{
    color: #aaa;
    /* var(--rmsc-gray); */
}
.form-control-text-area-form-div-field-textarea-input{
    font-size: 15.5px;
}
.billing-root-header-failed-extended-status {
    background-color: #e6151512;
    padding: 12px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
    color: red;
    border: 1px solid red;
    display: flex;
    justify-content: space-between;
}
.billing-root-header-failed-extended-status-warning-icon{
    margin: -3px 6px 0px 0px;
}
.billing-root-content-paynow-button {
    font-size: 13px;
    font-weight: bold;
    padding: 8px 17px;
}
.billing-root-header-failed-extended-status-div{
    padding: 6px 5px;
}
.billing-root-header-in-progress-payment-status {
    background-color: #ffa50063;
    padding: 15px;
    font-size: 17px;
    border-radius: 3px;
    font-weight: 500;
    margin: 0px 5px 33px;
}
.change-plan-modal-body-content-card-footer-faq {
    margin: 2rem;
    border: 1px solid #0000001c;
}
.change-plan-modal-body-content-card-footer-faq-text {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: 2rem 0rem 2rem 0rem;
}
.change-plan-modal-body-content-card-footer-faq-question-toggle {
    font-size: 16px !important;
    color: black !important;
    margin-top: -2px;
    padding: 0;
    font-weight: 500  !important;
}
.change-plan-modal-body-content-card-footer-faq-question.card-header {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.25rem !important;
}
.change-plan-modal-body-content-card-footer-faq-card {
    border-top: 0px !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    border-right:0px !important;
    border-left: 0px !important;
    background: none !important;
}
.change-plan-modal-body-content-card-footer-faq-card .card-header{
    background: none !important;
    border: none !important;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body {
    margin-top: -19px;
    color: gray;
    font-size: 14px;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body-div2{
    margin-top: 15px;
}
.change-plan-modal-body-content-card-footer-faq-question-icon{
cursor: pointer;
}
.change-plan-modal-body-content-card-footer-faq-question-toggle {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}
.change-plan-modal-body-content-card-footer-faq-wrapper {
    margin: 2rem;
}
.change-plan-modal-body-content-card-footer-notes-text-li-tag{
    font-weight: 500;
    margin: 14px;
    font-size: 15px;
}
.billing-root-header-storage-completed-link {
    color: #0000ffa8 !important;
}
.change-plan-modal-body-content-card-footer-faq-question-card-body-link{
    color: blue;
}
.billing-count-tooltip-start{
    /* min-width:500px; */
    height:400px !important;
    overflow-y: scroll;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68  !important;
    /* border: 1px solid #535C68; */
    color:white;
    
    position: sticky;
    z-index: 1 !important;
    backdrop-filter: blur(6px);
  }
  .billing-payment-terms-popover{
    max-width: 500px;
    background-color: #535C68  !important;
  }
.billing-payment-terms-popover-pay-now-text{
    /* text-decoration: underline; */
    cursor: pointer;
    font-style: italic !important;
}
.change-plan-modal-body-content-card-billing-text {
    margin-bottom: 8px;
}
.billing-payment-terms-popover .arrow::before {
    border-top-color: #4f5864 !important; /* Change this to your desired color */
  }
  
  .billing-payment-terms-popover .arrow::after {
    border-top-color: #4f5864 !important; /* Match the color with your popover */
  }
  .billing-payment-refund-terms-a-tag{
    color: gray;
    font-style: italic !important;
  }