.user-sidemenu-root {
  display: grid;
  grid-template-columns: 210px minmax(0, 1fr);
  /* height: calc(100vh - 55px); */
  margin: 0;
}
.user-sidemenu-root-mobile-with-menu {
  grid-template-columns: 225px minmax(0, 1fr);
}
.user-sidemenu-root-mobile-without-menu {
  grid-template-columns: 1fr;
}

.user-sidemenu-page {
  height: calc(100vh - 55px);
  overflow: auto;
}

.user-sidemenu {
  border-right: 2px solid #ededed;
  /* height: calc(100vh - 55px); */
  /* padding: 8px 0; */
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* background-color: #fff; */
  background-color: #fcfcfc;
}

/* second-sidebar css div start */
.second-sidebar-searchbox-wrapper {
  height: 32px;
  margin: 0.5rem 0.5rem 0.8rem;
}
.second-sidebar-searchbox-wrapper-root{
  position: fixed;
  padding: 4px 0;
  width: 208px;
  background-color: #FCFCFC;
  z-index:10;
}

.second-sidebar-searchbox {
  font-size: 0.9rem;
  border: none;
  background-color: #f5f3f6;
}

.second-sidebar-icon-wrapper {
  position: absolute;
  top: 13px;
  bottom: 0;
  right: 0.8rem;
  padding: 0.3rem;
  color: #cccccc;
  font-size: 0.9rem;
}

.second-sidebar-content-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #718093;
  padding: 8px 16px 9px 20px;
  display: flex;
}

.second-sidebar-content-listing-profile {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: all 100ms ease;
  cursor: pointer;
  text-decoration: none !important;
  color: #333 !important;
  border-left: 4px solid transparent;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.second-sidebar-content-listing-profile:hover {
  background-color: #266dfb05;
}

.second-sidebar-content-listing-profile.active {
  background-color: #266dfb10;
  border-color: #266dfb;
}

.second-sidebar-content-listing-profile-icon {
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  background-color: #8394ab;
  margin-left: 4px;
  margin-right: 8px;
}

.second-sidebar-content-listing-profile.active .second-sidebar-content-listing-profile-icon {
  background-color: #266dfb;
}

.second-sidebar-content-listing-profile-icon > svg {
  height: 13px;
  vertical-align: initial;
}

.second-sidebar-content-listing-profile-name {
  flex: 1;
  font-size: 15.5px;
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.second-sidebar-content-listing-profile-name-active {
  color: #266dfb;
  font-weight: 600;
}

.second-sidebar-header {
  display: flex;
  height: 55px;
  border-bottom: 1px solid #e8ebee;
  align-items: center;
  overflow: hidden;
}
.second-sidebar-header h5 {
  /* padding: .5rem 1rem; */
  padding: 0.5rem 1rem;
  margin: 0px;
}
.second-sidebar-box {
  border-right: 1px solid #e8ebee;
}
.second-sidebar-settings {
  height: calc(100vh - 65px);
  border-left: 1px solid #e8ebee;
  border-right: 1px solid #e8ebee;
  overflow-y: auto;
}
/* second-sidebar css div end */
.member-not-found{
  font-size: 14px;
}
.second-sidebar-content {
  margin-top: 62px;
  height: calc(100vh - 137px);
  overflow-x: hidden;
  overflow-y: auto;
}
.usermenu-card-avatar-profile {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 50%;
}
.usermenu-card-avatar-profile-img{
  display: inline;
  height: 100%;
  width: 100%;
}
.user-secondmenu-load-more-button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
p.user-secondmenu-load-more-end-text {
    color: #7a7979;
    font-size: 13px;
    font-weight: 400;
}