.personal-dashboard-widget {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  color: rgba(0, 0, 0, 0.8);
}

.personal-dashboard-widget .pdw-first-section {
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 26px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #fafafa;
}

.personal-dashboard-widget .pdw-second-section {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.pdw-second-section .pdw-ss-header {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
}

.pdw-ss-header .pdw-ss-header-text {
  flex: 1;
  margin: 0;
  font-weight: 500;
  font-size: 1.15rem;
}

.pdw-ss-header > button {
  font-size: 0.8rem;
}

.pdw-greetings {
  text-align: center;
  margin: 0 auto 0.5rem;
}

.pdw-progress-chart-wrapper {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  position: relative;
}

.pdw-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 15px 0 0;
}

.pdw-history-item {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.pdw-history-item .header {
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdw-history-item .header img {
  margin-left: 10px;
  max-width: 18px;
}

.pdw-history-item .content {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
}

.pdw-history-item.good .content {
  color: #54985a;
}

.pdw-ss-content {
  display: grid;
  max-width: 100%;
  gap: 12px;
}

.pdw-ss-app {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;
  opacity: 1;
  transition: all 200ms ease;
}

.pdw-ss-app.blur {
  opacity: 0.5;
}

.pdw-ss-app > *:not(:last-child) {
  margin-right: 4%;
}

.pdw-ss-app-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.pdw-ss-app-name,
.pdw-ss-app-time {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: smaller;
  margin: 0;
  white-space: nowrap;
}

.pdw-ss-app-name {
  font-weight: bold;
  /* width: 100px; */
  flex: 0.6;
}

.pdw-ss-app.blur .pdw-ss-app-name {
  font-weight: normal;
}

.pdw-ss-app-time-progress {
  /* width: 110px; */
  flex: 0.6;
  height: 0.5rem;
}

.pdw-ss-app-time-progress.productive > .progress-bar {
  background-color: #2ecc71;
}

.pdw-ss-app-time-progress.unproductive > .progress-bar {
  background-color: #e74c3c;
}

.pdw-ss-app-time-progress.neutral > .progress-bar {
  background-color: #bdc3c7;
}

.pdw-ss-app-time {
  /* width: 55px; */
  flex: 0.3;
}
