
.manual-time-report-filter-root{
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.manual-time-report-filter-content{
    display: flex;
}
.manual-time-report-filter-user {
    width: 240px;
    margin-bottom: 0;
    margin-right: 10px;
}

.manual-time-report-users-button .btn-primary {
    border: none;
    margin-right: 1.5rem;
    width: 240px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(51, 51, 51);
    text-align: left !important;
}
.manual-time-report-users-button .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
    color: #ccc;
}

.manual-time-report-users-button .btn-primary:hover {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.manual-time-report-users-button .btn-primary:active {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}

.manual-time-report-users-button .btn-primary:focus {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(204, 204, 204) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: rgb(51, 51, 51) !important;
}
.manual-time-report-details-filter-user-select{
    width: 246px !important;
}