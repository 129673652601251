.admin-view-leave-root{
    /* margin: 2rem;
    border: 0.5px solid rgba(0,0,0,.08);
    border-radius: 5px; */
    background-color: #fff;
}
.admin-view-leave-root-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}
.admin-view-leave-root-tab .nav .nav-tabs .nav-link{  
    /* border-bottom: none; */
}
.timelapse-warning-text-p-a{
    color: #266dfb;
    cursor: pointer;
}
.admin-view-leave-filter-root {
    display: flex;
    margin: 2rem 2rem 1rem 2rem;
    justify-content: space-between;
}
.admin-view-leave-filter-content {
    display: flex;
}
.admin-view-leave-filter-user-tab-filter {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.daily-attendance-filter-user-tab-filter .loading-wrapper .loading-svg {
    font-size: 35px;
}
.admin-view-leave-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 30px;
}
.admin-view-leave-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.admin-view-leave-table {
    border-collapse: separate;
    border-spacing: 0 .75rem;
    margin-bottom: .5rem;
    /* margin-top: .5rem; */
}
.admin-view-leave-table thead th {
    border: none;
}

.admin-view-leave-table th,
.admin-view-leave-table td {
    border: none;
    vertical-align: middle;
    padding: .5rem 0.7rem;
}

.admin-view-leave-table-body {
    color: #333;
}
.admin-view-leave-card-table-list-task-name {
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
}
.admin-view-leave-card-table-list-task-name-td {
    width: 20%;
}
.admin-view-leave-card-table-list-type-td{
    width: 20%;
}
.admin-view-leave-card-table-list-date-td{
    width: 25%;
}
.admin-view-leave-card-table-list-count-td{
    width: 10%;
}

.admin-view-leave-reviewed-button{
    margin-right: 20px;
    padding: 0.2rem 0.8rem;
}

.admin-view-leave-listing-tab-Content {
    padding: 0rem 2rem 2rem 2rem;
}
.admin-view-leave-listing-tab-Content-list {
    /* display: flex;
    align-items: center; */
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 1rem;
    border: 1px solid rgba(0,0,0,.050980392156862744);
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
}
.admin-view-leave-listing-tab-Content-list-displayName {
    width: 30%;
}
.admin-view-leave-card-table-list-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
    color: #34495e;
}
.admin-view-leave-listing-tab-Content-list-date {
    width: 35%;
    /* color: #007bff; */
}
.admin-view-leave-listing-tab-Content-list-dates-count {
    width: 15%;
}
.admin-view-leave-listing-tab-Content-list-dates-text {
    color: #808080eb;
    cursor: pointer;
}
.admin-view-leave-listing-tab-Content-list-type {
    width: 25%;
}
.admin-view-leave-listing-tab-Content-list-info{
    width:10%;
}
.admin-view-leave-listing-tab-Content-list-on{
    width: 20%;
}
.admin-view-leave-card-table-list-status-td{
    display: flex;
    width: 17%;
}
.admin-view-leave-card-table-list-status-td-text{
    width:10%;
    /* text-transform: capitalize; */
}
.admin-view-leave-ta-contents-header {
    color: gray;
    border: none;
    font-size: 14px;
    /* display: flex;
    align-items: center; */
    padding: 0.6rem 0.8rem;
    margin-bottom: 0.4rem;
}
.admin-view-leave-listing-tab-Content-list-date-header {
    width: 35%;
}

.admin-view-leave-listing-tab-Content-list-dates-count-header {
    width: 15%;
}
.admin-view-leave-listing-tab-Content-list-type-header {
    width: 20%;
}
.admin-view-leave-listing-tab-Content-list-on-header{
    width: 25%;
}
.admin-view-leave-card-table-list-status-td-header{
    width: 20%;
}

.admin-view-leave-listing-tab-whole-div {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    white-space: nowrap;
    flex-wrap: nowrap;
    /* overflow: scroll; */
}

.react-datepicker__close-icon::after{
    background-color: #00000047 !important;
}



@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .admin-view-leave-root-tab{
        flex-wrap: wrap;
    }
    .admin-view-leave-filter-root{
        display: unset;
    }
    .admin-view-leave-filter-content {
        display: unset;
    }
    .admin-view-leave-filter-user{
        margin-bottom: 1rem;
    }
    .admin-view-leave-date-picker{
        margin-bottom: 1rem;
    }
    .admin-view-leave-listing-tab-whole-div{
        overflow-x: auto;
    }
    .admin-view-leave-listing-tab-Content{
        overflow: auto;
    }
}
.admin-view-leave-content-data-details-avatar {
    display: flex;
    align-items: center;
}
.employee-card-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
}
.admin-view-leave-team-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.admin-view-leave-content-data-details-avatar-circle {
    height: 25px;
    width: 25px;
    background-color: #40baab;
    color: #f4f5f9;
    font-weight: 700;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.75rem;
    border-radius: 50%;
    text-transform: uppercase;
}
.admin-view-leave-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.admin-view-leave-content-data-details-name-data-team-name-whole {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: -3px;
}
.admin-view-leave-content-data-details-name-data-team-name-whole a{
    cursor: pointer;
}
.admin-view-leave-content-data-details-name-data-team-name-whole a:hover{
    text-decoration: underline;
}
.admin-view-leave-card-table-list-status-td-text-pending{
    color: orange !important;
}
.admin-view-leave-card-table-list-status-td-text-approved{
    color: green !important;
}
.admin-view-leave-card-table-list-status-td-text-rejected{
    color: red !important;
}
.admin-view-leave-card-table-all-by-text{
    display: block;
    width: 108px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.admin-view-leave-table-body tr td{
    /* font-weight: 600; */
    color: #34495e;
    font-size: .9rem;
    background-color: #fff;
    padding: 0.7rem!important;
}
.admin-view-listing-tab-tab-Content-list-dates-text {
    display: block;
   width: 70px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.admin-view-leave-card-table-all-by-text-all{
    display: block;
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.view-leave-list-status-approved-modal-body {
    margin: 10px;
}
.form-control-text-area-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 16px;
}
.view-leave-list-status-approved-modal-body p{
    margin-bottom: 0px;
}
.view-leave-list-status-approved-modal-body h6{
    margin-bottom: 11px !important;
}
.leave-listing-tab-tab-Content-notext-free-text {
    margin-top: 19px;
    color: gray;
    text-align: center;
}

.admin-view-listing-tab-tab-Content-table-data-icon-whole-div {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 10px 10px 0px;
}
.admin-view-listing-tab-tab-Content-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
}
.admin-view-listing-tab-tab-Content-table-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.admin-view-listing-tab-tab-Content-table-data-edit-text {
    margin-bottom: 0;
}


