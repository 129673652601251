
.risk-user-history-root {
    margin: 2rem;
}
.risk-user-history-root-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
}
.risk-user-history-root-header p{
  margin: 0px 14px;
}
.risk-user-history-root-header-rule {
    display: flex;
    justify-content: space-between;
}
.risk-user-history-root-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
}
@media screen and (max-width: 768px){
    .risk-user-history-root-content {
        grid-template-columns: 1fr;
    }
    .risk-rule-history-content-listing-content-card{
      display: block !important;
    }
}
@media screen and (max-width: 1024px) {
  .risk-user-history-root-content  {
      grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 480px) {
  .risk-rule-history-content-listing-content-card{
    display: block !important;
  }
  span.risk-user-history-filter-title{
    margin-top: 75px !important;
  }
}

.head-website-list-risk-history {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: #1E1E1E;
  font-weight: 500;
  flex: 1 1;
  margin: 1rem 1rem 5px 0;
}
.risk-user-history-root-section {
    /* height: 315px; */
    /* overflow: auto; */
    color: #4a5568;
    padding: 0 16px 16px;
    /* background: #fff; */
    background: #fff;
    border-radius: 5px;
    border: .5px solid rgba(0,0,0,.08);
}
.head-risk-user-history-card{
    display: flex;
    /* position: sticky;
    top: 0; */
    /* background-color: white; */
}
.head-risk-user-history-list-heading {
    font-size: 16px;
      color: #1E1E1E;
      font-weight: 500;
      flex: 1 1;
      margin: 1rem 0 10px 0;
  }
  .risk-user-root-content-data-header {
    color: #797777db;
    border: none;
    font-size: 0.9rem;
  }
  
  .risk-user-root-content-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    /* background-color: #e9ecef; */
    padding: 0 10px;
  }
  .risk-user-root-content-table tbody tr {
    /* display: flex; */
    background-color: rgba(236,239,244,.45098039215686275);
    border-radius: 5px;
    padding: 3px 7px 9px 7px;
    /* margin-bottom: 10px;
    background-color: #e9ecef; */
    /* cursor: pointer; */
  }
  /* .risk-user-root-table-body{
    background-color: #e9e9e9;
    padding: 0 10px;
  } */

  .risk-root-content-data-details-avatar-profile {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
  }
  .risk-root-content-data-details-name-data-violation-name {
    margin-bottom: 6px;
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
}
  .risk-root-content-data-details-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
  }
  tbody.risk-user-root-table-body {
    height: 80px;
    overflow-y: auto;
  }
  .risk-user-root-content-table-wrapper {
    /* height: 335px; 
    overflow: scroll;
    overflow-x: hidden; */
  }
  .risk-history-root-content-data-details-name-data-team-name-rule-text {
    margin: 0;
    font-size: 12px;
    color: #dc3545;
    margin-top: -2px;
    margin-right: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 50ch;
}

  /* .risk-user-root-content-data-header tr {
      position: sticky;
      top: 0;
  } */
  .risk-user-history-root-button{
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}



.risk-user-history-content-listing {
  display: grid;
  /* grid-template-columns: repeat(auto-fill,minmax(235px,1fr)); */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 1rem; */
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: #e9ecef; */
  margin-top: 3%;
}
.risk-user-history-content-listing-content {
  display: flex;
  background-color: rgba(236,239,244,.45098039215686275);
  border-radius: 5px;
  padding: 3px 7px 9px 7px;
}

.risk-root-change {
  text-align: center;
  vertical-align: middle !important;
}

.risk-history-content-data-details-avatar-circle {
  height: 29px;
  width: 29px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 29px;
  font-size: 12px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
  margin-top: 8px;
}
.risk-history-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 3px;
}
.risk-rule-history-content-listing-content {
  background-color: rgba(236,239,244,.45098039215686275);
  border-radius: 5px;
  padding: 9px;
}
.risk-rule-history-content-listing-content-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.risk-rule-history-content-listing {
  display: grid;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 3%;
  font-size: .9rem;
}
.risk-rule-history-content-rule-name{
  margin-bottom: 6px;
  font-weight: 600;
  color: #34495e;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  margin-top: 3px;
  font-size: .9rem;
  width: 450px;
  text-align: left;
  margin-left: 4px;
}
.risk-rule-history-content-rule-violations {
    margin-right: 49px;
    margin-top: 3px;
    margin-bottom: 6px;
    font-size: 0.9rem;
}
.risk-rule-history-content-listing-content button.btn.btn-link {
  width: 100%;
}

.risk-user-rule-history-content-listing-content {
  display: flex;
  /* background-color: rgba(236,239,244,.45098039215686275); */
  background-color: rgb(246 248 250);
  border-radius: 5px;
  padding: 3px 7px 9px 7px;
}
.risk-rule-history-content-data-details-avatar-circle {
  height: 24px;
  width: 24px;
  background-color: #40baab;
  color: rgb(244 245 249);
  font-weight: 700;
  line-height: 24px;
  font-size: 11px;
  text-align: center;
  margin-right: .5rem;
  border-radius: 50%;
  text-transform: uppercase;
  margin-top: 8px;
}
.risk-rule-history-root-content-data-details-name-data-team-name {
  margin-bottom: 3px;
  font-weight: 600;
  color: #34495e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
}
.risk-history-card-avatar-profile {
  width: 28px;
  height: 28px;
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  border-radius: 50%;
  margin-top: 8px;
}
.risk-history-card-avatar-profile-accordian {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  margin-right: .5rem;
  border-radius: 50%;
  margin-top: 8px;
}
span.risk-user-history-filter-title {
  font-size: 19px;
  font-weight: 600;
  color: #00000094;
  vertical-align: middle;
  margin-top: 10px;
}
.accordion button:hover{
 text-decoration: none;
}
.accordion button:focus{
  text-decoration: none;
 }
 .risk-history-rule-uparrow {
  font-size: 13px;
 }