.Manual-time-entries-review-root {
    padding: 2rem;
}
.manual-review-usage-root-listing-card-content-avatar {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #fff;
    background-color: #8394ab;
    margin-right: 8px;
}
.manual-time-review-name {
    margin-right: 20px;
    font-size: 15px;
    width: 125px;
    /* color: #34495e; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.manual-time-review-text-hours {
    font-size: 15px;
    width: 315px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-review-usage-root-list {
    /* height: 400px; */
    height: calc(100vh - 185px);
    background: #fff;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}
.manual-time-review-usage-root-listing {
    padding: 0% 0% 1% 0%;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 260px; */
    height: calc(100vh - 294px);
}
.manual-time-review-usage-root-listing-whole-div {
    display: flex;
    padding: 0.9rem !important;
}
.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 0.9rem;
}
.manual-time-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 20px;
    margin-bottom: .9rem;
    margin-top: .5rem;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
    /* color: #495057eb; */
}
.manual-time-review-hours{
    margin-right: 25px;
    /* color: gray; */
    font-size: 15px;
    width: 115px;
}
.manual-time-review-task-name{
    display: flex;
    flex-direction: column;
    margin-left: 9px;
}
.manual-time-review-row{
    /* justify-content: space-between; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.manual-time-review-display-name{
    display: flex;
    align-items: center;
}
.manual-time-review-text-project-name{
    font-size: 13px;
    color: grey;
    width: 315px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}   
.manual-time-review-column{
    display: flex;
    /* flex-direction: column; */
    flex-basis: 100%;
    flex: 1;
}
.manual-time-review-button-div{
    visibility: hidden;
}
.manual-time-review-column-header {
    font-weight: 500;
    color: gray;
}
.manual-time-review-usage-search-root-search-button-div {
    width: 25% !important;
}
.project-details-task-card-delete-body-p-tag {
    color: gray;
    margin-top: 10px;
    font-size: 12px;
}
.review-usage-search-root-search-input::placeholder{
    color:#80808070;
}
.manual-time-review-usage-root-list nav a{
    font-size: 0.9rem !important;
}
.manual-time-review-usage-root-listing p {
    text-align: center;
}
.manual-task-project-details-task-card-delete-cancel-button, .manual-task-project-details-task-card-delete-button {
    font-size: 0.9rem;
    padding: 4px 18px;
}
.manual-time-review-column-review-status{
    
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
    
}
@media only screen and (width : 820px) {
    .manual-time-review-usage-root-listing {
        width: 48vh;
        overflow: auto;
    }
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
}
@media only screen and (width : 768px){
    .manual-time-review-usage-root-listing {
        width: 68vh;
        overflow: auto;
    }
    .Manual-time-entries-review-root {
        padding: 1rem 0rem;
    }
    .manual-time-review-row {
        flex-wrap: initial;
        width: auto;
    }
}
