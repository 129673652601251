.api-tokens-list-card {
  padding: 2rem;
    display: flex;
    flex-direction: column;
}
.api-tokens-list-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
}
.api-tokens-list-card-toolbar {
  /* margin: 2rem 0;
  display: flex; */
}
.api-tokens-list-card-create-new-token-button {
  font-size: 1rem;
  font-weight: 700;
  float: right;
  height: 40px;
}
.api-tokens-list-card-searchbox {
  float: right;
  font-size: 0.8rem;
  padding: 0.375rem 1.5rem !important;
}
.api-tokens-list-card-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0.5rem;
  color: #49505752;
  font-size: 0.9rem;
}
.api-tokens-list-card-searchbox-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: 0;
}

.api-tokens-list-card-data {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000b5;
}
.api-tokens-list-card-table-header {
  color: #797777db;
  border: none;
  font-size: 0.9rem;
}
.api-tokens-list-card-table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
/* .api-tokens-list-card-table tbody {
      border-spacing: 0 18px;
  } */
.api-tokens-list-card-table thead th {
  border: none;
}
.api-tokens-list-card-table th,
.api-tokens-list-card-table td {
  border: none;
}
.api-tokens-list-card-table-data-details {
  margin-bottom: 2rem;
  padding: 1rem 0;
  border: 1px solid #0000000d;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 0px 0 rgba(0, 0, 0, 0.06);
}

.warning {
  border: 0;
  border-radius: 5px;
  color: #a36200;
  padding: 13px 19px;
  font-size: 14px;
  background-color: #fff2c9;
  font-weight: 600;
  margin-top: 2rem;
}

.generateTokenHeader {
  text-align: center;
  margin-top: 3rem;
}
.generateTokenHeader img {
  width: 250px;
  height: 200px;
}

.generate-token-btn {
  border-width: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-color: #266dfb !important;
  background-color: #266dfb !important;
  color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  margin-bottom: 15px;
  font-weight: 500;
  opacity: 1;
  font-size: 0.9rem;
  float: center;
}

.generate-token-btn:hover:not(:disabled),
.generate-token-btn:active:not(:disabled),
.generate-token-btn:focus:not(:disabled) {
  border-color: #266dfb !important;
  background-color: #266dfb !important;
  color: #fff;
  opacity: 0.8;
}

.create-api-tokens-name-modal-header .close {
  font-size: 1.2rem;
  margin-top: -5%;
}
.create-api-tokens-name-modal-title {
  font-size: 1rem;
  font-weight: 700;
  color: #000000a8;
}

.create-api-tokens-modal-label-name {
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000ab;
}

.create-api-tokens-modal-label-input {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #000000a1 !important;
}
.api-tokens-list-card-table-body{
  font-size: 0.9rem;
}

/* .api-token-edit-menu #dropdown-basic {
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  margin-right: -0.5rem;
} */

.api-token-edit-menu #dropdown-basic {
  top: -9px;
  padding: 0.275rem .65rem;
}
.api-token-edit-menu-icon {
  float: right;
  color: #00000096;
  float: right;
  font-size: 1.3rem;
  margin-top: 8%;
}
.api-token-delete-task {
  color: red;
}
.api-token-edit-menu-td{
  vertical-align: middle;
}

.api-token-edit-menu #dropdown-basic {
  background-color: transparent;
  color: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  height: 1.3rem;
  left: -35px;
}
/* #dropdown-basic{
  position: absolute;
  top: 31px;
  right: -22px;
} */
.api-token-no-data{
  font-size:1rem;
  text-align: center;
  font-weight: 600;
  margin: 2rem 0;
}
/* .create-api-tokens-name-modal-body{
  font-size: 1rem;
} */

.create-api-tokens-modal-label-data {
  background-color: #8080802e;
  padding: 12px;
  border-radius: 4px;
  vertical-align: middle;
  overflow-wrap: break-word;
  font-size: 14px;
}
.api-tokens-list-card-toolbar{
  display: flex;
  justify-content: space-between;
}
.fade.api-tokens-list-card-toolbar-alert-text.alert.alert-warning.show {
  height: 40px;
  /* vertical-align: middle; */
  /* text-align: center; */
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #808080b3;
}
.api-token-reference-link{
  font-size: 14px;
}
.api-token-reference-link-tag{
  color:blue;
}
