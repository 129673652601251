.monthly-in-out-root {
    margin: 2rem;
   
}
/* .monthly-in-out-content{
    width: 100%;
    height: vh;
    overflow: auto;
  
} */
.monthly-in-out-content-view {
    /* width: 100vw; */
    display: grid;
  }
  
  .monthly-in-out-content-wrapper {
    /* position: relative;
    overflow: auto;
     white-space: nowrap;
    display: grid;
    grid-template-columns: 1fr; */
    width: 100%;
    height: 68vh;
    overflow: auto;
  }
  
  .monthly-in-out-content-sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white !important;
    min-width: 19rem !important;
    max-width: 19rem !important;
    text-align:left  !important;
  }

  .first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
  }
  .monthly-in-out-content-table-thead th{
        border-bottom: 1px solid #dee2e6 !important; 
  }
  /* .monthly-in-out-content-table-thead-table-row {
    position: sticky;
    top: 0px;
    z-index: 50;
  } */
  .monthly-in-out-content-table-thead{
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .monthly-in-out-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    vertical-align: middle;
    /* text-align: left; */
}
.monthly-in-out-table thead > tr th,.monthly-in-out-table tbody > tr td:not(:first-child) {
     text-align: center; 
}
.monthly-in-out-table th, .monthly-in-out-table tbody > tr td:first-child{
    background-color: #F3F7FA!important;
}

.monthly-in-out-table thead tr th{
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
    min-width: 6rem;
    max-width: 6rem;
}
.monthly-in-out-table thead> tr th:nth-last-child(-n+3) {
    vertical-align: middle;
}
.monthly-in-out-table thead > tr th:first-child ,.monthly-in-out-table thead> tr th:nth-child(2) ,.monthly-in-out-table thead> tr th:nth-child(3){
    /* min-width: 10rem;
    max-width: 10rem; */
}
.monthly-in-out-table thead>tr th:nth-child(2) ,.monthly-in-out-table thead>tr th:nth-child(3) {
    vertical-align: middle;
}

.monthly-in-out-table tbody> tr td {
    border-right: 0px !important;
    font-size: 0.9rem;
}
.monthly-in-out-filter-root {
    margin: 0 0 34px 0;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.monthly-in-out-filter-content {
    display: flex;
    z-index:50;
}
.monthly-in-out-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.monthly-in-out-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
}
.monthly-in-out-filter-download-icon {
    color: #2A8EF7;
    font-size: 1.1rem;
    font-weight: 700;
}
.monthly-in-out-load-more-button-div {
    text-align: center;
    margin-top: 21px;
    /* width: 14%; */
}
/* .monthly-in-out-table thead th{
    position: sticky; 
    top: 0; 
    z-index: 20;
} */
.monthly-in-out-content-table-tbody-td-absent{
    color: gray !important;
}
.monthly-in-out-header-label {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}
.monthly-attendence-header-label-badge-na {
    margin-right: 10px;
}
.monthly-in-out-table-avatar-profile {
    width: 28px;
    height: 28px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;
    margin: auto 0;
    margin-right: 12px;
}
.monthly-in-out-table-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}