.user-status{
    padding-bottom: 30px;
}

.user-status-header{
    text-align: center;
}

.user-status-title{
    font-size: 1.5rem;;
}

.user-status-app-status,.organisation-status{
    display: flex;
    margin: auto;
    justify-content: center;
}


.user-status-app-status-card{
    margin: 10px;
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width: 25vw;
    background: #ffffff;
    -webkit-box-shadow: 1px 3px  5px 2px #ccc;  
    -moz-box-shadow:    1px 3px  5px 2px #ccc;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}

.organisation-status-card{
    margin: 10px;
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width:38vw;
    background: #ffffff;
    -webkit-box-shadow: 1px 3px  5px 2px #ccc;  
    -moz-box-shadow:    1px 3px  5px 2px #ccc;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}


.user-status-work-version{
    margin: auto;
    justify-content: space-evenly;
}

.user-status-work-card,.user-status-version-card,.user-status-plan-card{
    margin-top: 20px;
    padding:10px;
    text-align: center;
    width: 25vw;
    background: #ffffff;
    -webkit-box-shadow: 1px 3px  5px 2px #ccc;  
    -moz-box-shadow:    1px 3px  5px 2px #ccc;  
    box-shadow:         1px 3px  5px 2px #ccc; 
}

.user-status-work-version{
    display:flex
}

.user-card-title{
    font-size: 17px;
}

.user-card-count{
    font-size: 30px;
    font-weight: bold;
    color: #545454;
}

.user-status-modal-title{
    font-size: 15px;
}

.user-status-password-div{
    width: 100%;
}

.user-status-password-error{
    font-size: 14px;
    color: #ea1414;
}

.user-status-submit-div{
    margin: 20px 0px;
    text-align: center;
}

.user-status-submit-button{
   width:80%;
}

.user-card-plan-details{
    height: 17vh;
    overflow: auto;
}

.user-card-plan-data{
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    margin: 10px;
    border-radius: 10px;
    padding: 2px 10px;
}


.user-card-plan-organisation{
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.user-card-plan-email{
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}


.user-card-plan-show{
    margin-right: -36px;
    font-size: 12px;
    text-decoration: underline;
    color: #0000ff82;
    cursor: pointer;
    padding-left: 10px;
}