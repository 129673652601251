.summary-report-details-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.summary-report-details-body {
    margin: 2rem 0 2rem 0;
}
.summary-report-details-table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #dee2e6;
}
.summary-report-details-table thead tr th {
    background-color: #f3f7fa !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, .5803921568627451);
    font-size: .9rem;
}

.summary-report-details-table tbody tr td, .summary-report-details-table thead tr th {
    padding: .7rem !important;
}
.summary-report-details-table tbody tr td {
    color: rgba(0, 0, 0, .8392156862745098) !important;
    font-size: .9rem;
}
.summary-report-details-table thead tr th {
    margin: 0;
    padding: .5rem;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.summary-report-detail-report-type {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}

.summary-report-details-content-report-type {
    margin-right: 20px;
}
.summary-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    /* border: 1px solid #ededed; */
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    /* max-width: -moz-max-content; */
    width: 250px;
    height: 39px;
    font-size: .9rem;
    /* max-width: max-content; */
}
label.summary-report-details-label {
    font-size: .9rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}
.summary-report-filter-report-type-select,.summary-report-details-filter-team-select{
    font-size: 0.9rem;
}
.summary-report-details-filter-team-select {
    width: 100%;
}
.summary-report-page-content-wrapper-menu-filter-date-text {
    /* font-weight: 500; */
    font-size: 0.9rem;
    margin: 0;
}
.summary-report-details-content-date-range-text{
    color: rgb(75 85 99 / var(--tw-text-opacity));
    font-size: 0.9rem;
}
.summary-report-details-content-export-button {
    float: right  !important;
    margin-bottom: 15px !important;
}
.summary-report-details-content-export-button button{
    font-size: 0.9rem !important;
}
.summary-report-details-content.summary-report-details-content-organisation {
    justify-content: flex-start  !important; 
}
.summary-report-table-avatar-profile {
    display: flex;
}
.summary-report-details-body-all-data-text {
    text-align: center;
    margin: 26px 0px 2rem 0px;
    color: #bdbbbb !important;
}
.summary-report-details-content-export-wrapper{
    margin: 40px 0px 0px 0px !important;
    /* display: flex; */
    /* justify-content: space-between; */
}