.organisation-settings-root{
    padding: 5rem;
}
.organisation-setting-root-text-box{
    width:500px;
}
.org-setting-root-card{
    max-width: 500px;
    margin: 0 auto;
}
.organisation-info-profile-img{
    display: inline;
    height: 100%;
    width: 100%;
}
.organisation-info-profile-photo-upload-icon-card {
    display: inline-block;
    margin-right: 10px;
    height: 80px;
    width: 80px;
    /* border-radius: 40px; */
    background: #e3e3e3ba;
    border: 1px solid rgba(0,0,0,.1);
    font-size: 29px;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
    color: #33333394;
    position: relative;
    overflow: hidden;
}
.organisation-info-profile-photo-upload-card {
    clear: both;
    display: flex;
    align-items: center;
}
.organisation-info-profile-photo-upload-photo-card-data{
    width:135%;
}
label.custom-file-upload-text-p {
    font-size: 14px;
    color: #0000005c;
}
.organisation-setting-root-company-logo-label {
    margin-bottom: 0px !important;
}