.holiday-import-root {
    margin: 2rem;
}
.holiday-import-date-picker {
    width: 200px;
    border: 1px solid #d3d3d3;
    padding: 7px;
    border-radius: 5px;
}
.holiday-import-root-header-section {
    display: flex;
    justify-content: space-between;
    /* padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706); */
}
.holiday-list-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin: 3rem 0rem;
}
.holiday-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-spacing: 0em 0.7em;
    
}
.holiday-list-table thead tr th {
    /* background-color: #f3f7fa!important; */
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
/* .holiday-list-content th,.holiday-list-content td {
    margin: 0;
     border-bottom: 1px solid #dee2e6 !important;
    border-right: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
} */
.holiday-list-content td {
    margin: 0;
    border-bottom: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
    border-top: 0px solid #dee2e6;
}
.holiday-list-table-tbody-tr td:first-child {
    border-left: 1px solid #dee2e6 !important;
}
.holiday-list-table-tbody-tr td:last-child {
    border-right: 1px solid #dee2e6 !important;   
}
.holiday-list-table thead tr th {
    padding: 0.1rem 0.7rem !important;
}
 .holiday-list-table tbody tr td {
    padding: 0.7rem !important;
}
.holiday-list-table tbody tr td {
    color: #34495e !important;
    font-size: 0.9rem;
}
.holiday-list-table tr:first-child td{
   width: 1%;
}
.holiday-import-root-header-filter-section{
    display: flex;
    gap:10px;
}
.holiday-import-root-header-filter-type-select{
    width: 215px;
    /* margin-left: 25px; */
}

.holiday-list-import-old-data-text-div-add{
    margin: 30px auto 0px auto;
    font-size: 14px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 415px;
}
.holiday-list-import-old-data-text-div-add-import{
    margin: 30px auto 0px auto;
    font-size: 14px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 432px;
}
.holiday-list-import-old-data-text-div{
    margin: 14px auto 65px auto;
    font-size: 15px;
    color: #4e4d4d;
    border: 1px solid rgba(0,0,0,.10196078431372549);
    border-radius: 3px;
    padding: 15px;
    width: 550px;
    text-align: center;
    background-color: #c3e7b7;
}
.holiday-list-import-old-data-text-span-yes{
    /* text-decoration: underline;
    color:#007bff;
    cursor: pointer; */
    margin-right: 25px;
    margin-top: 20px;
}
.holiday-list-import-old-data-text-span-no{
    margin-right: 25px;
    margin-top: 20px;
}
.holiday-list-import-old-data-text-span{
    text-decoration: underline;
    color:#007bff;
    cursor: pointer;
}
.holiday-list-import-old-data-text-span-icon{
    margin-bottom: 4px;
}



.holiday-import-add-modal-body-wrapper-form-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.9em + 0.75rem + 2px);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.add-holiday-import-modal-header-title {
    font-size: 18px;
}
.add-holiday-import-modal-body-wrapper-form-div-field-form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.add-holiday-import-modal-body-wrapper-form-div-field-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
    /* display: block; */
}
.add-holiday-import-modal-body-wrapper-form-div-group {
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 0rem;
    /* margin-right: 16px; */
    margin-bottom: 16px;
}

.add-holiday-import-modal-body-wrapper-form-div {
    margin-right: 0 !important;
}
.add-holiday-import-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
}
.add-holiday-import-footer-add-holiday-import-button,.add-holiday-import-footer-add-holiday-import-button button {
    text-align: center;
    margin: 8px 17px 3px 17px;
    font-size: 14px;
    font-weight: 600;
}
.add-holiday-import-modal-body {
    margin: 8px 16px;
}
.holiday-import-date-picker:focus-visible {
    outline: 2px gray;
  }
  .holiday-list-filter-upload-icon-btn {
    margin-right: 10px;
}
.holiday-list-table-tbody-tr{
    line-height: 18px;
    padding-top: 1px;
    margin-bottom: 10px;
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    background-color: white;
    /* font-weight: 600; */
    color: #34495e !important;
    font-size: 0.9rem;
}
.holiday-list-table thead th:first-child{
    width: 2%;
}
.holiday-list-table thead th:nth-child(2){
    width: 30%;
}
.holiday-list-table thead th:nth-child(3),.holiday-list-table thead th:nth-child(6),.holiday-list-table thead th:nth-child(7){
    width: 15%;
}
.holiday-list-table thead th:nth-child(4),.holiday-list-table thead th:nth-child(5){
    width: 10%;
}
.holiday-list-filter-download-icon-btn,.holiday-list-filter-upload-icon-btn {
    padding: 6px 19px;
    font-weight: 500;
}
.holiday-list-filter-download-icon-btn{
    margin-right: 10px;
}

.holiday-list-edit-profile-text-td {
    display: flex;
    font-size: 0.8rem;
}
.holiday-list-edit-profile-text-td-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.holiday-list-edit-profile-text-td-table-data-icon-div {
    display: flex;
    cursor: pointer;
    padding: 5px 11px 5px 11px;
    background-color: #f8f9fa;
    border-radius: 5px;
    color: #6c757d;
    margin-right: 5px;
}
.holiday-list-edit-profile-text-td-edit-text {
    margin-bottom: 0;
}
.holiday-list-edit-profile-text-td-data-edit-icon {
    font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
}
.holiday-list-filter-upload-icon-import {
    margin: -6px 3px 0px 0px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {

    .holiday-list-content {
        width: 100%;
        display: grid;
    }
    .holiday-import-root-header-section{
        display: inline;
    }
    .holiday-import-root-header-filter-section{
        display: inline;
    }
}
.add-holiday-import-modal-body-input-form-error {
    font-size: 12px;
}
.holiday-list-no-data-text{
    text-align: center !important;
    color: #808080a6;
}
.holiday-import-add-modal-body-wrapper-form-input::placeholder{
    color: gray;
}