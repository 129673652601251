.manual-time-manual-card-list-section {
    margin: 2rem;
}
.manual-time-project-card-root {
    padding: 0px 15px 10px;
}
.manual-time-manual-card-root-add-section {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
}
.manual-time-project-add-card-formik {
    display: flex;
    justify-content: space-around;
    grid-gap: 1%;
    gap: 1%;
}
.manual-time-project-add-card-formik .manual-time-project-add-card-formik-description {
    margin-bottom: 0rem !important;
    width: 30%;
}
.manual-time-project-add-card-formik-description-without-project{
    width: 40% !important;
}
.manual-time-project-add-card-formik-description .form-control {
    font-size: 18px !important;
    /* background-color: #f3f3f3; */
}
.manual-time-project-formik-add-project-button {
    font-size: 15px !important;
}
.manual-time-project-formik-add-project-button .btn-primary {
    border: 1px solid #007bff !important;
    color: #007bff !important;
    background-color: #fff !important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-project-formik-add-project-button .dropdown-menu {
    min-width: 24rem !important;
}
.manual-time-project-formik-add-form {
    margin-bottom: 0 !important;
}
.manual-time-project-formik-add-form input.rc-time-picker-input {
    height: calc(1.5em + 0.8rem + 2px);
    width: 140px;
    font-size: 15px !important;
}
.workfolio-manual-pages-calender-div {
    display: flex;
}
.workfolio-manual-pages-calender-icon-div {
    display: flex;
}
svg.workfolio-manual-pages-calender-icon {
    font-size: 20px;
    text-align: center;
    margin-top: 4px;
    color: #87898b;
    cursor: pointer;
}
.workfolio-manual-pages-calender-text {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
}
.manual-time-project-formik-add-button {
    font-size: 15px !important;
}
.manual-time-manual-card-table {
    border-collapse: separate;
    border-spacing: 0 0.75rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.manual-time-date-divider-label-div {
    display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.task-tracker-list-date-divider-label {
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #c5c5f1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 20px;
    font-size: 0.8rem;
}
.manual-time-date-divider-label-icon {
    margin-right: 10px;
}
.manual-time-date-divider-label-icon svg {
    font-size: 15px;
    color: #000000a1;
    margin-top: -3px;
}
.manual-time-manual-card-table-body {
    color: #333;
}
.manual-time-manual-card-table-header {
    color: gray;
    border: none;
    font-size: 14px;
}
.manual-time-manual-card-table th, .manual-time-manual-card-table td {
    border: none;
    vertical-align: middle;
    padding: 0.9rem;
}
.manual-time-manual-card-table-data-details {
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 15px;
    box-shadow: -1px 4px 3px -6px #333333;
    background-color: #f8f9fa!important;
}
.manual-time-manual-card-table-description {
    width: 310px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.manual-time-project-card-table-name {
    font-size: 13px;
    color: gray;
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.manual-time-load-more-button {
    display: flex;
    justify-content: center;
}
.add-manual-time-card-details {
    margin-top: 2rem;
}



.manual-time-dashboard-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.manual-time-dashboard-card {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
}
.manual-time-dashboard-card-title {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1E1E1E !important;
}
.manual-time-manual-card-list-section-header-div {
    display: block;
    padding: 0.5rem 1rem 0rem 1rem;
}
.manual-time-manual-card-list-section-header {
    margin-bottom: 0;
    font-size: 16px;
}
.manual-time-manual-card-list-section- {
    padding: 16px 5px;
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, .08);
    -o-border-image: none;
    border-image: none;
    -o-border-image: initial;
    border-image: initial;
    margin-top: 0.5rem;
}
.manual-time-manual-card-table-whole-div-fixed {
    padding: 0rem 1rem 1rem 1rem;
}
.manual-time-manual-card-list-section-header-root{
    /* margin-top: 2rem; */
}
.manual-time-data-loader-div{
    height:100vh;
}
.manual-task-list-no-data-found{
    height:100vh;
}
.error+.manual-time-project-formik-add-form .rc-time-picker-input {
    border-color: #F0624D !important;
}
.manual-time-project-formik-add-form .rc-time-picker-clear {
    display: none !important;
}
.workfolio-manual-pages-calender-div {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 5px 7px;
    height: 39px;
    cursor: text;
    font-size: 12px;
    /* line-height: 1.5; */
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.manual-time-select-date-filter-icon{
    margin-top: -4px;
    margin-right: 5px;
}
.manual-time-select-date-filter-button{
    font-size: 14px !important;
}
.rc-time-picker-input:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
span.rc-time-picker:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.rc-time-picker-panel-input-wrap:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.manual-time-manual-card-table-data-review-status-approved,.manual-time-manual-card-table-data-review-status-rejected,.manual-time-manual-card-table-data-review-status-pending{
    font-weight: 500;
}
.manual-time-manual-card-table-data-review-status-approved {
    color: green;
}
.manual-time-manual-card-table-data-review-status-rejected {
    color: red;
}
.manual-time-manual-card-table-data-review-status-pending {
    color: #530453;
}
.manual-time-manual-card-table-data-details-approved {
    background-color: #00800012 !important;
}
.manual-time-manual-card-table-data-details-rejected {
    background-color: #ef676712 !important;
}
.manual-time-manual-card-table-data-details-pending {
    background-color: #53045312 !important;
}
.manual-time-manual-card-table-data-all-task-label {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    color: gray;
}
.manual-time-manual-table-data-icon {
    /* color: #00b9ff; */
    /* font-size: 17px; */
    cursor: pointer;
    text-decoration: underline;
}
.manual-time-delete-task-details-footer {
    display: flex !important;
    flex-flow: column wrap;
    align-items: end;
}
.project-details-task-card-delete-cancel-button {
    margin-right: 10px;
}
.manual-time-delete-task-details-review-status div {
    color: red;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .manual-time-manual-card-list-section {
        margin: 1rem;
    }
    .manual-time-dashboard-summary {
        grid-template-columns: 1fr!important;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 100% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-text{
        font-size: 15px !important;
    }
    .manual-time-project-formik-add-button {
        margin: 0px 45px;
    }
    .manual-time-project-add-card-formik-description .form-control{
        font-size: 15px !important;
    }
    .manual-time-manual-card-table-whole-div-fixed {
        width: 34vh;
        overflow: auto;
    }
    .manual-time-manual-card-table-description {
        width: 102px;
    }
    .manual-time-project-card-table-name {
        width: 102px;
    }
    .manual-time-manual-card-root-add-section {
        padding: 16px 19px;
    }
    .manual-time-review-usage-root-listing {
        width: 34vh;
        overflow: auto;
    }
    
}
@media only screen and (min-device-width : 412px) {
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 104% !important;
    }
}
@media only screen and (width : 820px) {
    .manual-time-manual-card-table-whole-div-fixed {
        width: 46vh;
        overflow: auto;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 204% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .manual-time-manual-card-root-add-section {
        padding: 25px 25px;
    }
    .manual-time-project-formik-add-button {
        margin: 0 180px;
    }
}
@media only screen and (width : 768px){
    .manual-time-manual-card-table-whole-div-fixed {
        width: 66vh;
        overflow: auto;
    }
    .manual-time-project-add-card-formik {
        display: inline;
    }
    .manual-time-project-add-card-formik .manual-time-project-add-card-formik-description{
        margin-bottom:1rem !important;
        width: 100% !important;
    }
    .manual-time-project-formik-add-form input.rc-time-picker-input{
        width: 260% !important;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .workfolio-manual-pages-calender-div{
        width:100%;
        margin-bottom:1rem !important;
        padding: 0.375rem 0.75rem;
    }
    .manual-time-manual-card-root-add-section {
        padding: 25px 25px;
    }
    .manual-time-project-formik-add-button {
        margin: 0 250px;
    }
}