.leave-history-filter-new-root {
    margin: 2rem;
    display: flex;
    padding: 10px;
    border: 1px solid #00000030;
    justify-content: space-between;
}
.leave-history-filter-content {
    display: flex;
}
.leave-history-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.balance-leave-setting-modal-full-div .modal-dialog {
    max-width: 615px;
}
.add-user-leave-balace-flex{
    display: flex;
    justify-content: space-around;
}
.add-user-leave-balace-flex-label{
    font-size: 14px;
}
/* edit userwise css*/
.edit-user-wise-Leave-type-modal-grid{
    display: inline;
    /* grid-template-columns: 1fr 1fr;
    gap: 1rem; */
    padding: 0.5rem;
}
.edit-user-wise-Leave-type-modal-grid-right-header-p{
    font-size: 18px;
}
.edit-user-wise-leave-settings-modal-title.modal-title {
    font-size: 20px;
    text-align: center;
    width: 90%;
}
.apply-leave-modal .modal-content {
    /* background-color: rgb(252, 252, 252);    ; */
}
.apply-leave-new-modal .modal-dialog {
    max-width: 590px;
}
.edit-user-wise-Leave-type-modal-grid-right{
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px 2px;
    padding: 1rem;
}
.edit-user-wise-Leave-type-modal-grid-left{
    /* background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px 2px; */
    padding: 0 1rem;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p{
    color: rgba(83, 92, 104, 0.64);
    font-size: 14px;
    margin-bottom: 0;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p-days{
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    color: #007bff;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-p-days-balance{
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
}
.edit-user-wise-Leave-type-modal-grid-right-content-1-progess{
    color: rgba(83, 92, 104, 0.64);
    font-size: 14px;
    margin-bottom: 5px;
}
.edit-user-wise-leave-history-tab-leave-root-tab {
    font-size: 16px;
    color: rgba(0, 0, 0, .5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link {
    color: #343a40;
  }
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link.active{
    color: #007bff;
}
.edit-user-wise-leave-history-tab-leave-root-tab .nav-link:hover{
    border: none;
}
.user-wise-leave-balance-month-settings {
    margin-bottom: 0px;
    font-size: 14px;
}
.user-wise-leave-balance-month-settings input{
    margin-right:5px;
}
.user-wise-leave-balance-month-settings label{
   display: flex;
   align-items: center;
}
.edit-user-wise-leave-history-tab-available {
    padding: 15px 0px 0px 0px;
}
.edit-user-wise-leave-history-tab-future-whole-div {
    margin-bottom: 5px;
}
.edit-user-wise-leave-history-tab-future-whole-div {
    margin-bottom:10px;
    margin-top:10px;
}
.user-wise-leave-balance-month-settings-span{
    font-size: 12px;
    color: rgb(140, 148, 161);
    margin: 0px 5px 5px 5px;
}
.user-wise-leave-balance-month-settings-label{
    font-size: 12px;
}
.user-wise-leave-balance-label-name {
    color: #535c68a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.22px;
    line-height: 22px;
    margin-bottom: 8px;
}
/* .edit-user-wise-leave-history-tab-leave-root-tab.a{
    color: #495057;
    background-color: #fff;
}
.edit-user-wise-leave-history-tab-leave-root-tab:active {
    color: rgb(0, 123, 255);
    border-bottom: 4px solid rgb(0, 123, 255);
} */

.user-wise-leave-balance-month-settings-span-p {
    font-size: smaller;
    font-weight: 500;
    color: #656565;
    margin: 15px 0px 5px 0px;
}








.leave-setting-type-wrapper-div-balance {
    border: 1px solid #d3d3d3;
    padding: 17px 0px 3px 0;
    border-radius: 6px;
    background-color: hsla(0, 0%, 50.2%, .050980392156862744);
    margin: 0 8px 8px 8px;
}
.leave-setting-time-option-div-balance {
    display: flex;
    width: 285px;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}
.short-leave-history-time{
    color:gray;
    font-size: 0.8rem;
}
.leave-history-tab-whole-div {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    white-space: nowrap;
    flex-wrap: nowrap;
}
.Leave-edit-balance-modal-notes-header {
    color: grey;
    font-size: 12px;
    padding: 10px;
}
.Leave-edit-balance-modal-notes-header-note-text-span
{
    font-weight: bold;
}
.Leave-edit-balance-modal-notes-header-note-text{
    margin-bottom: 5px;
}
.Leave-edit-balance-modal-notes-header-button
{
    margin:5px;
}
.Leave-edit-balance-modal-notes-header-note-text-p a{
    color:#035fcb;
    font-weight: unset !important;
}
.Leave-edit-balance-modal-notes-header-note{
    font-weight: bold;
    color: #2d3748;
}
.leave-history-tab-leave-root-tab {
    font-size: 16px;
    color: rgba(0,0,0,.5294117647058824);
    font-weight: 500;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-right: 25px;
}


.leave-summary-leave-balance-content-view {
    /* width: 100vw; */
    display: grid;
    margin: 0 35px 0 35px;
}
.monthly-in-out-content-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}
.leave-summary-leave-balance-table-thead {
    position: sticky;
    top: 0px;
    /* z-index: 1; */
}
.page-content-wrapper-header-name-sup-leave {
    font-size: 10px;
    /* font-weight: normal;
    color: rgba(112, 111, 111, .7686274509803922); */
    margin-left: 2px;
}
.leave-summary-leave-balance-table-thead th{
    border-bottom: 1px solid #dee2e6 !important; 
}
.leave-summary-leave-balance-content-wrapper {
    width: 100%;
    height: 68vh;
    overflow: auto;
}

.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.summary-leave-balanace-modal-body-wrapper-form-div-field-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    height: calc(1.7em + 0.75rem + 2px);
    width:225px;
}
.leave-history-date-picker-finacial {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
}
.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date-text {
    font-size: 15px;
    margin: 0;
    font-weight: 500 !important;
    color: rgba(0,0,0,.8);
}
.summary-leave-balanace-modal-avartar-emp{
    display: flex;
    align-items: center;
}
.summary-leave-balanace-modal-header-avatar {
    display: inline-block;
    text-align: center;
    color: #333333;
}
.summary-leave-balanace-modal-avartar{
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 11px;
    text-align: center;
    font-weight: 700;
    border-radius: 50%;
    color: #fff;
    background-color: #8394ab;
    margin-left: 4px;
    margin-right: 8px;
}
.summary-leave-balanace-modal-avartar-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.summary-leave-balanace-modal-avatar-profile {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-left: 4px;
    margin-right: 8px;
    border-radius: 50%;
}
.summary-leave-balanace-modal-body-wrapper-form-date-picker-filter-date-icon {
    display: inline;
    height: 100%;
    /* width: 100%; */
    margin-right: 5px;
}
.summary-leave-balanace-modal-avatar-profile-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #212529;
    width: 150px;
}
.summary-leave-balanace-header-summary-card-text-ques {
    font-size: .9rem;
    color: #808080ba;
    float: right;
}

.summary-leave-balanace-tooltip-start .tooltip-inner {
    min-width: 330px;
    /* background-color: #c4dbf3ba !important;
    border: 2px solid #c4dbf3ba; */
    background-color: #535C68 !important;
    border: 1px solid #535C68;
    color: #dee2e6;
    position: sticky;
    z-index: 1 !important;
    backdrop-filter: blur(6px);
    font-size: 15px;
}


.summary-leave-balanace-tooltip-start .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: #535C68 !important;
}
.summary-leave-balanace-count-tooltip-whole{
    text-align: left;
    padding: 10px;
  }
.summary-leave-balanace-tooltip-start-span{
    color:white !important;
    font-size: 14px;
}
.summary-leave-balanace-count-tooltip-user-content{
    margin-bottom: 2px;
    max-width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 500;
}
.summary-leave-balanace-modal-header-avatar-edit {
    font-size: 12px;
    color: rgba(112,111,111,.7686274509803922);
    cursor: pointer;
    text-decoration: underline;
    -webkit-text-decoration-color: rgba(112,111,111,.45098039215686275)!important;
    text-decoration-color: rgba(112,111,111,.45098039215686275)!important;
}
.summary-leave-balanace-modal-header-label {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-right: 35px;
}

.leave-history-filter-download-icon {
    /* color: #007bff; */
    font-size: 1.1rem;
    font-weight: 700;
}
.leave-history-content-new {
    height: calc(100vh - 215px);
    overflow: auto;
    margin: 2rem;
}
.leave-history-table {
    width: 100%;
    border-spacing: 0;
    /* border: 1px solid #dee2e6; */
    border-collapse: separate;
    border-spacing: 0em 0.7em;
}
.leave-history-table tbody tr td {
    /* font-weight: 600; */
    color: #34495e;
    font-size: 0.9rem;
    background-color: white;
    padding: 0.7rem !important;
}
.leave-history-table thead tr th {
    padding: 0.2rem 0.7rem !important;
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
.leave-history-content td {
    margin: 0;
    border-top:0px solid rgba(0,0,0,.050980392156862744) !important ;
    border-bottom:0px solid rgba(0,0,0,.050980392156862744) !important;
    border-right: 0px;
}
.leave-history-table tbody tr td:first-child {
    border-left: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-table tbody tr td:last-child {
    border-right: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid rgba(0,0,0,.25098039215686274);
    border-radius: 4px;
    color: rgba(0,0,0,.8);
    font-size: 15px;
    padding: 8px;
    width: 240px;
}

 .leave-history-filter-root {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.18823529411764706);
    justify-content: space-between;
}
.leave-history-table tbody tr {
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    font-size: .9rem;
}
.leave-history-table-content-data-details-avatar {
    display: flex;
}
.leave-history-table-content-data-details-avatar-circle {
    height: 25px;
    width: 25px;
    background-color: rgb(64, 186, 171);
    color: rgb(244 245 249);
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    margin-right: 0.75rem;
    border-radius: 50%;
    text-transform: uppercase;
}
.leave-history-table-content-data-details-name-data-team-name {
    margin-bottom: 3px;
    font-weight: 600;
    color: #34495e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
}
.view-user-leave-modal-header-title {
    font-size: 18px;
}
.view-leave-history-content-new {
    height: 100vh ;
    overflow: auto;
    /* margin: 2rem; */
}
.view-user-leave-history-table-approved-status{
    color: green;
}
.view-user-leave-history-table-rejected-status{
    color: red;
}
.view-user-leave-history-table-pending-status{
    color: orange;
}
.view-user-leave-history-table {
    width: 100%;
    border-spacing: 0;
    /* border: 1px solid #dee2e6; */
    border-collapse: separate;
    border-spacing: 0em 0.7em;
}
.view-user-leave-history-table thead tr th {
    padding: 0.2rem 0.7rem !important;
    font-weight: bold !important;
    color: rgba(121,119,119,.8588235294117647);
    font-size: .9rem;
}
.view-user-leave-history-table tbody tr {
    /* border: 1px solid rgba(0,0,0,.050980392156862744); */
    box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
    font-size: .9rem;
}
.view-user-leave-history-table tbody tr td {
    font-weight: 600;
    color: #34495e;
    font-size: 0.9rem;
    background-color: white;
    padding: 0.7rem !important;
    border-right: 0px;
}
.view-user-leave-history-table tbody tr td:first-child {
    border-left: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.view-user-leave-history-table tbody tr td:last-child {
    border-right: 1px solid rgba(0,0,0,.050980392156862744) !important;
}
.leave-history-table tbody{
    cursor: pointer;
}
.leave-history-table-content-view-leave-button {
    text-decoration: underline;
    color: #363c42b0;
    cursor: pointer;
}
.leave-history-table-content-view-leave-button:hover {
    color: #0000ffb3;
    
}

.leave-summary-leave-balance-content-table thead > tr th:first-child {
    background-color: #F3F7FA !important;
    vertical-align: middle;
    /* text-align: left; */
}
.leave-summary-leave-balance-content-table thead > tr th,.leave-summary-leave-balance-content-table tbody > tr td:not(:first-child) {
     text-align: center; 
}
.leave-summary-leave-balance-content-table th, .leave-summary-leave-balance-content-table tbody > tr td:first-child{
    background-color: #F3F7FA!important;
}

.leave-summary-leave-balance-content-table thead tr th{
    font-weight: 600 !important;
    color: #00000094;
    font-size: 0.9rem;
    /* min-width: 6rem;
    max-width: 6rem; */
    /* min-width: 150px;
    max-width: 300px; */
    min-width: 8rem;
    max-width: 11rem;
}
.leave-summary-leave-balance-content-table thead> tr th:nth-last-child(-n+3) {
    vertical-align: middle;
}
.leave-summary-leave-balance-content-table thead > tr th:first-child ,.leave-summary-leave-balance-content-table thead> tr th:nth-child(2) ,.leave-summary-leave-balance-content-table thead> tr th:nth-child(3){
    /* min-width: 10rem;
    max-width: 10rem; */
}
.leave-summary-leave-balance-content-table thead>tr th:nth-child(2) ,.leave-summary-leave-balance-content-table thead>tr th:nth-child(3) {
    vertical-align: middle;
}

.leave-summary-leave-balance-content-table tbody> tr td {
    border-right: 0px !important;
    font-size: 0.9rem;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
    .leave-history-filter-new-root{
        display: inherit;
    }
    .leave-history-filter-content{
        display: inherit;
    }
    .leave-history-content {
        width: 100%;
        display: grid;
    }
    
}
.leave-history-content-data-details-name-data {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.leave-history-content-data-details-name-data-team-name-whole {
    width: 214px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 0px;
}

.leave-history-team-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}
.leave-history-card-table-list-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin-top: 4px;
    color: #333;
}

.view-user-leave-history-table-leave-reason {
    width: 96px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-tracker-load-more-button button {
    font-size: 14px;
    padding: 7px 12px;
    font-weight: 500;
}

.leave-history-table-content-no-data-text {
    text-align: center;
    color: gray;
}
.leave-summary-user-leave-details-leave-type{
    text-transform: capitalize;
}
.view-user-leave-history-table-status{
    text-transform: capitalize;
}
.user-leave-listing-tab-tab-Content-list-dates-count-span {
    margin-right: 4px;
}
.leave-listing-tab-tab-Content-list-dates-count-span-icon{
    cursor: pointer;
}
.user-leave-listing-tab-tab-Content-list-dates-count-wrapper{
    display: flex;
}