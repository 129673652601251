/* * {
  color:  #1E1E1E;
} */
.screenshots-card {
  margin: 2rem;
}

.screenshot-load-more-button{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.screenshots-card-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 55px);
}
.screenshots-card-note-text {
  font-size: 14px;
  color: #ff00008c;
  margin-bottom: 1rem;
  /* margin-top: 2rem; */
}


.screenshots-card-sort-dropdown {
  text-align: end;
    margin-bottom: -30px;
    display: flex;
    align-items: c;
    justify-content: flex-end;
}
.screenshots-card-sort-dropdown p{
  margin: 0;
    color: #808080d1;
    margin-right: 5px;
    margin-top: 2px;
}
.screenshots-card-sort-dropdown-icon {
  margin-top: -1px;
  font-size: 0.9rem !important;
  margin-left: 4px;
}
.screenshots-card-sort-dropdown-icon-extra {
  font-size: x-small;
  margin-top: -1px;
}
.screenshots-card-sort-dropdown .dropdown-toggle::after {
  display: none;
}
.screenshots-card-sort-dropdown button{
  padding: 2px 13px;
  border: 1px solid #ededed;
  /* background-color: #fff;
  color: #6c757d; */
  background-color: #266dfb10;
  color: #266dfbc2;
}
.screenshots-card-sort-dropdown button:hover{
  background-color: #266dfb10;
  color: #266dfbc2;
  border-color:#ededed;
}
.screenshots-card-sort-dropdown button.active{
  background-color: #266dfb10;
  color: #266dfbc2;
  border-color:#ededed;
}
.no-screenshot-found {
  text-align: center;
  margin-top: 5rem;
}
.ScreenShots-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));
  grid-gap: 0.8rem;
}
.ScreenShots-root-date-divider-label-div{
  display: grid;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: -1;
}
.ScreenShots-root-date-divider-label{
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 20px;
}
.ScreenShots-root-date-divider-label-icon {
  margin-right: 10px;
  color: #000000ab;
}
.ScreenShots-root-date-divider-label-icon svg {
  margin-top: -4px;
}
/* screenshot-card div css start */
.screenshot-card {
  /* margin: 1rem auto; */
  margin-bottom: -1%;
  /* max-width: 245px; */
  width: 100%;
  padding: 1rem;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid #d3d3d3;
  /* border: 1px solid #17b5ab; */
  border-radius: 3px;
}
.screenshot-card-non-idle{
  border: 1px solid #dc3545;
}
.screenshot-card-modal-non-idle {
  border: 2px solid #dc3545;
}
.screenshot-card-selected {
  background-color: #266dfb10;
  /* border: 1px solid #007bff; */
}
.screenshots-card-export-div {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  /* width: fit-content; */
  border-radius: 5px;
  padding: 0.7rem;
  justify-content: space-between;
}
.screenshots-card-export-div-select-all{
  /* width: 100%;
    height: calc(1.7em + .75rem + 2px); */
    padding: 0.3rem .55rem;
    font-size: .8rem;
    font-weight: 400;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-right: 20px;
    background-color: #fcfcfc;
    cursor: pointer;
}
.disable-screeshots-export {
  padding: .375rem .75rem;
  background-color: #dee2e638 !important;
  border-radius: .25rem;
}
.disable-screeshots-select-all{
  background-color: #dee2e638;
  border-radius: .25rem;
}
.image-heckbox input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: 1px 3px 5px rgb(38 38 38 / 20%);
}
.image-heckbox .form-check-inline{
  margin-right: -2px;
}
.screenshot-download-icon{
  /* font-size: 12px; */
    font-size: 0.8rem;
    margin-right: 20px;
    border: 1px solid #ced4da;
    color: #495057;
    background-color: #fcfcfc;
}
.screenshot-download-delete-icon{
  font-size: 0.8rem;
    /* margin-right: 20px; */
    border: 1px solid #ced4da;
    color: #495057;
    background-color: #fcfcfc;
}

/* screenshot-card-header div css start */
.screenshot-card-header {
  display: flex;
  align-items: center;
}
.screenshot-card-header-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.screenshot-card-header-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* color: #34495e; */
  font-weight: 400;
  flex-grow: 1;
  font-size: 14px;
}
.screenshot-card-header-menu {
  flex-shrink: 0;
  /* padding-right: 12px; */
}
/* screenshot-card-header div css end */

/* screenshot-card-image div css start */
.screenshot-card-image {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  margin: 12px 0px;
}
.screenshot-card-thumbnail-image {
  width: 100%;
  height: 120px;
  background-color: #e8ebee;
}
/* screenshot-card-image div css end */

/* screenshot-card-footer div css start */
.screenshot-card-footer {
}
.screenshot-card-footer-information-bar {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  font-weight: 600;
  font-size: 14px;
  /* line-height: 16px; */
  /* color: #718093; */
}
.screenshot-card-footer-information-bar a{
  text-decoration: none;
  color: #34495e;
}
.screenshot-card-footer-information-bar a:hover{
  text-decoration: underline;
}
.screenshot-card-footer-information-bar-timer {
  margin-right: 12px;
  display: flex;
  align-items: center;
  /* flex-shrink: 0; */

  /* color: #718093; */
  font-size: 12px;
  margin-left: auto;
  margin-right: 0px;
}
.screenshot-card-footer-information-bar-timer-time {
  font-size: 12px;
  margin-left: 4px;
}
.screenshot-card-footer-information-bar-app-type {
  display: flex;
  align-items: center;
  overflow: hidden;
  /* flex-shrink: 0; */

  /* color: #718093; */
  font-size: 12px;
  margin-left: auto;
  margin-right: 0px;
}
.screenshot-card-footer-information-bar-app-type-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
}
.screenshot-card-footer-name {
  /* font-weight: 600;
  font-size: 14px; */
  /* color: #718093; */
  font-size: 12px;

  /* line-height: 14px; */
  /* color: #34495e; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  width: 77px;
  margin-bottom: 0px;
}
.screenshot-card-footer-information-bar-timer-key-count{
  font-size: 12px;
  margin-right: 5px;
}
.screenshot-card-footer-information-bar-timer-key-count-icon{
  margin-bottom:2px;
  font-size: 13px;
}
.screenshot-card-footer-information-bar-timer-key-count span{
  font-size: 11px;
  margin-left: 3px;
}
.screenshot-card-footer-teamname {
  float: right;
  font-size: 14px;
  line-height: 22px;
  /* color: #718093; */
  font-weight: 600;
  margin-top: 5px;
  cursor: pointer;
}

/* screenshot-card-footer div css end */

/* screenshot-card div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}

@media screen and (max-width: 480px) {
  .screenshot-card-image-modal .modal-dialog {
    min-width: 75% !important;
    margin-top: 0rem !important;
    margin-left: 3rem;
  }
  .screenshot-card-image-modal-close{
    right: -11px;
  }
  .screenshot-card-image-modal-personal-header {
    display: block !important;
    margin-bottom: 1rem !important;
  }
  .screenshot-card-image-modal-personal-header-details{
    margin-bottom: 1rem !important;
  }
  .screenshot-card-image-modal-site-name {
    font-size: 16px !important;
  }
  .screenshot-card-image-modal-site-icon-content{
    height: 40px !important;
    width: 40px !important;
  }
  .screenshot-card-image-modal-site-icon{
    height: 18px !important;
  }
  .screenshot-card-image-modal-body{
    padding:0;
  }
  .screenshot-card-image-modal-time-details{
    font-size: 12px;
  }
  .screenshot-card-image-modal-time{
    font-size: 12px;
    float: right;
  }
  
  .screenshot-card-image-modal-user-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 143px;
  }
  }

.screenshot-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0 12px 19px rgba(38, 38, 38, 0.2);
  top: -7px;
}

.screenshot-card-image-modal-body {
  text-align: center;
  margin: 1.5rem;
}

.screenshot-card-image-modal .modal-dialog {
  max-width: 64% !important;
  margin-top: 4rem;
}
svg.screenshot-card-image-modal-left-nav {
  position: absolute;
  color: white;
  top: 46%;
  left: -10%;
  bottom: 0;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1;
}
.screenshot-card-image-modal-body .screenshot-card-image-modal-left-nav {
  cursor: pointer !important;
}
.screenshot-card-image-modal-left-nav:hover,
.screenshot-card-image-modal-right-nav:hover {
  opacity: 0.9;
}
svg.screenshot-card-image-modal-right-nav {
  position: absolute;
  right: 0;
  top: 46%;
  bottom: 0;
  color: white;
  right: -10%;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1;
}

.screenshot-card-image-modal-close {
  position: absolute;
  right: -88px;
  padding: 0 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  color: white;
  margin: 0.5rem;
  opacity: 0.6;
  top: -77px;
}
.screenshot-card-image-modal-close:hover {
  opacity: 0.9;
}

.screenshot-card-image-modal .modal-dialog .modal-content {
  background-color: white;
}
.screenshot-card-image-modal {
  background-color: #00000099;
}

.screenshot-card-image-modal-personal-header {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.screenshot-card-image-modal-site-icon-content {
  height: 47px;
  width: 47px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  color: #fff;
  margin-left: 4px;
  margin-right: 8px;
  background-color: #5b5c5f21;
}
.screenshot-card-image-modal-site-icon {
  height: 23px;
  vertical-align: initial;
  margin-top: 12px;
}
.screenshot-card-image-modal-site-name {
  font-weight: 600;
  font-size: 22px;
  color: #000000a6;
}
.screenshot-card-image-modal-user-name {
  font-size: 14px;
  float: left;
  color: #f1306f;
  margin-top: -3px;
}
.screenshot-card-image-modal-site-icon-details {
  margin-top: -3px;
  text-align: left;
}
.screenshot-card-image-modal-personal-header-details {
  display: flex;
}
.screenshot-card-image-modal-time-details {
  font-size: 15px;
  margin-right: 11px;
}
svg.screenshot-card-image-modal-time-icon {
  font-size: 14px;
  margin-right: 4px;
  margin-top: -2px;
}
svg.screenshot-card-image-modal-time-icon-key-board {
  font-size: 16px;
  margin-right: 4px;
  margin-top: -2px;
}
.screenshot-card-image-modal-screenshot-image img{
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
}

/* Edit Menu */

.screenshot-image-edit-menu #dropdown-basic {
  /* top: -11px !important; */
  top: -8px !important;
  left: -20px;
} 
.screenshot-image-edit-menu #dropdown-basic {
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align: right;
    height: 1.3rem;
}
#dropdown-basic {
    position: absolute;
    top: 31px;
    right: -22px;
}
svg.screenshot-image-edit-menu-icon {
  color: #000000cf;
  float: right;
  font-size: 0.9rem;
  margin-top: -29%;
}
.dropdown-menu{
  font-size: 0.8rem !important;
}

.waring-screenshot-summary{
  display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 9px;
}
.waring-screenshot-card{ 
  
    overflow: auto;
    color: #4a5568;
    padding:16px;
    /* background: #8ef1760f; */
    background: #f194763d;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    -o-border-image: initial;
    border-image: initial;
    margin: 1rem;
}
.warning-screenshot-card-title {
  /* font-size: 0.87rem; */
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-size: 0.98rem;
  color: #1E1E1E !important;
  /* margin-bottom: 20px; */
}
.screenshot-enble-page {
  /* color: #266dfb;
  text-decoration: underline; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.timelapse-warning-text-p{
  margin-top: 1rem;
  font-size: 18px;
}
.screenshot-enble-page-loading{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.screenshot-card-warning-text {
  text-align: center;
  border: 0.5px solid #0000000f;
  padding: 24px;
}
.screenshot-card-warning-text-whole-div{
  margin: 3rem;
}
.project-details-task-card-delete-task-name-text {
  margin-top: 11px;
  color: #0000007a;
}
.screenshots-card-export-wrapper-div {
  display: flex;
}
.ScreenShots-root-data-loader{
  text-align: center;
  display: flex;
  justify-content: center;
}
.screenshots-card-searchbar {
  font-size: 14px;
}
.screenshots-card-searchbar-div {
  width: 330px;
}
.screenshots-card-searchbar::placeholder {
  color: rgb(214, 210, 210);
  opacity: 1; 
}
.screenshot-page-content-wrapper-menu-filter-date {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  color: #333;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  transition: all 100ms ease;
  width: fit-content;
}
.screenshot-page-content-wrapper-menu-filter-overlay-trigger {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.screenshot-page-content-wrapper-menu-filter-overlay-trigger {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-color: #00000017;
  display: block;
}
.screenshot-page-content-wrapper-menu-filter-overlay {
  display: flex;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker {
  display: inline-block;
  width: fit-content;
  vertical-align: top;
  margin: 17px 20px 20px 20px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10%;
  margin-top: 7px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 6px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-label {
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
  line-height: 14px;
  color: #34485e;
  font-weight: 600;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button {
  margin-right: 1.7rem;
  position: relative;
  text-align: center;
  margin-top: 1.8rem;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button-cancel {
  font-weight: 700;
  font-size: 14px;
  margin-right: 16px;
  color: #266dfb;
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #266dfb;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
.screenshot-page-content-wrapper-menu-filter-overlay-button-apply {
  font-weight: 700;
  font-size: 14px;
  /* margin-right: 16px; */
  cursor: pointer;
  padding: 11px 16px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #266dfb;
  transition-property: background;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0s;
  background: #266dfb;
  color: #fff;
}
.screenshots-card-filter-div {
  display: flex;
  gap: 16px;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-header {
  display: flex;
  justify-content: space-between;
}
.screenshot-page-content-wrapper-menu-filter-overlay-datepicker-close-icon {
  font-size: 17px;
  margin-top: -11px;
  cursor: pointer;
}
.screenshots-card-filter-screenshot-div {
  width: 160px;
}
.screenshots-card-filter-screenshot-div{
  margin-bottom: -16px !important;
  z-index: 9999 !important;
  margin-right: 16px;
}
.screenshots-card-filter-screenshot-div .form-control {
  cursor: pointer !important;
}
.screenshot-page-content-wrapper-menu-filter-overlay-start-timepicker .rc-time-picker .rc-time-picker-clear,.screenshot-page-content-wrapper-menu-filter-overlay-end-timepicker .rc-time-picker .rc-time-picker-clear{
  display: none;
}
.screenshot-page-toggle-div {
  display: flex;
  margin: 0.2rem 0.6rem 0 0 !important;
  width: 100%;
}
.screenshot-page-setting {
  font-size: .9rem;
  font-weight: 500;
  color: #495057;
  margin-top: -1px;
}
.screenshot-page-rule-uparrow {
  font-size: 14px;
  margin-left: 5px;
  color: #62839b;
}
.screenshot-page-toggle-wrapper-div {
  margin-right: 18px;
}
.screenshot-page-end-time {
  font-size: 13px;
  /* margin-left: 1rem; */
  color: red;
  width: 100%;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.screenshot-page-content-wrapper-menu-filter-overlay-timepicker-end-error{
  border: 1.5px solid red;
  border-radius: 5px;
}
.screenshots-card-export-div-select-all-form-check{
  margin-top: 2px !important;
}