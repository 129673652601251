.personal-settings,
.personal-settings .tab-pane,
.personal-settings .tab-content {
  height: 100%;
}
@media screen and (max-width: 480px) {
  .personal-setting-root{
  padding: 2rem;
  }
}
.personal-settings .nav-tabs > a {
  color: #343a40;
}
.personal-setting-height{
  height:200px;
}

.root-layout-page .nav-link {
  background-color: #fff;
  border: none;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
}

.personal-settings .nav-tabs > a.active {
  border-bottom: 4px solid #007bff;
  color: #007bff;
}

.personal-settings-info-container,
.personal-settings-password-container {
  background: #fff;
  height: 100%;
}

.personal-settings-info-container > .form-wrapper,
.personal-settings-password-container > .form-wrapper {
  margin: 0 auto;
  max-width: 500px;
}
.personal-info-profile-photo-upload-card {
  clear: both;
  display: flex;
}
.personal-info-profile-photo-upload-icon-card{
  display: inline-block;
  margin-right: 10px;
  height: 55px;
  width: 55px;
  border-radius: 40px;
  background: #e3e3e3ba;
  border: 1px solid rgba(0,0,0,.1);
  font-size: 29px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: #33333394;
  position: relative;
  overflow: hidden;
}

.personal-info-profile-photo-upload-photo-card-content {
  cursor: pointer;
  margin-top: 2px;
}

#inputUpload {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
  font-size: inherit;
  color:#157AF6;
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 500;
}

.custom-file-upload:hover {
  text-decoration: underline;
}

.profile-info-saved-crop-image-button{
  font-weight: 700;
  /* float: right; */
  width: 200px;
  height: 38px;
  font-size: 15px;
}
.profile-info-saved-crop-image-button-header{
  margin-top: 14px;
  text-align: center;
}
.profile-info-cancel-crop-image-button{
  /* float: left; */
  width: 150px;
  height: 38px;
  font-size: 15px;
  font-weight: 700;
  margin-right: 14px;
}
.personal-info-profile-images{
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
.profile-info-saved-crop-image-modal-title{
  justify-content: center;
}
label.custom-file-upload-text {
  font-size: 14px;
  color: #0000005c;
}

label.profile-photo-remove {
  float: right;
  color: #ff0000b0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px !important;
  cursor: pointer;
}
.profile-info-saved-crop-image-modal-body{
  text-align: center;
}
label.profile-photo-remove:hover{
  text-decoration: underline;
}
.delete-account-wrapper {
  margin: 34px auto;
  max-width: 500px;
  border: 1px solid #00000030;
  padding: 20px;
}
.delete-account-wrapper-header{
  font-size: 1.5rem;
  color: #343a40!important;
  font-weight: 500;
  line-height: 1.2;
}
.delete-account-wrapper-content {
  color: #727272;
  font-weight: 500;
  margin-top: 12px;
}
.project-details-task-card-delete-body-text{
  margin-top: 20px;
}