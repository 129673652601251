@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} 
::-webkit-scrollbar-thumb {
 /* background: #939393; */
 background: #7F7F7F;
 border-radius: 5px;
}
@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  } 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
