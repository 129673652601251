.scheduled-reports-card {
  margin: 2rem;
}
.scheduled-reports-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000000a6;
}
.scheduled-typr-err{
  font-size: 13px;
    color: red;
    margin-top: 5px;
}
.scheduled-type-err-span{
  color: #495057;
}
.scheduled-type-err-a{
  color: #495057;
  text-decoration: underline;
}
.scheduled-type-err-list {
  color: #808080b3;
  text-decoration: underline;
}
.scheduled-typr-err-daily-enable-expected-clockin-track-settings {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px 0px 20px 0px;
}
.scheduled-reports-card-toolbar {
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
}
.scheduled-reports-card-create-report-button {
  font-size: 0.75rem;
  font-weight: 700;
}
.scheduled-reports-card-icon-wrapper {
  padding: 0.3rem 0 0.3rem 0.5rem;
  color: #49505752;
  display: inline-flex;
}
.scheduled-reports-card-searchbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.scheduled-reports-card-searchbox-wrapper > .form-control {
  border: none;
  border-radius: 0;
}
.scheduled-reports-card-content {
    /* border: 0.5px solid #0000001a; */
    border-radius: 5px;
    background: #fcfcfc;
    padding: 0.6rem;
    margin-bottom: 2rem;
}
.scheduled-reports-card-data {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #6c757d !important;
}
.scheduled-reports-card-table-header {
  color: #868686;
  border: none;
}
.scheduled-reports-card-table {
  border-collapse: separate;
  border-spacing: 0 0.75rem;
  margin-bottom: 0.5rem;
}
/* .scheduled-reports-card-table tbody {
    border-spacing: 0 18px;
} */
.scheduled-reports-card-table thead th {
  border: none;
}
.scheduled-reports-card-table th,
.scheduled-reports-card-table td {
  border: none;
}
.scheduled-reports-card-table-data-details {
  padding: 1rem 0;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Report Modal CSS */
.scheduled-reports-new-report-modal-title {
  font-size: 1rem;
  font-weight: 700;
  color: #000000a8;
}
.scheduled-reports-new-report-modal-header .close {
  font-size: 1.2rem;
  margin-top: -3%;
}

.scheduled-reports-new-report-modal-label-name {
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000ab;
}

.scheduled-reports-new-report-modal-label-input {
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  color: #000000cf !important;
}
.scheduled-reports-new-report-modal-frequency {
  display: flex;
  gap: 0.75rem;
  padding: 0.5rem 0;
}
.scheduled-reports-new-report-modal-frequency-radio {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}

.scheduled-reports-new-report-modal-frequency-label-name {
  margin-left: 0.5rem;
  margin-bottom: 0rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-checkbox-button > input[type="checkbox"],
.scheduled-reports-new-report-modal-radion-button > input[type="radio"] {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-checkbox-button > label,
.scheduled-reports-new-report-modal-radion-button > label {
  font-size: 0.95rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal-divider {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.scheduled-reports-new-report-modal-content > * {
  width: 50%;
}
.scheduled-reports-new-report-modal-content {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}
.scheduled-reports-new-report-modal-filter {
  width: 100%;
  height: auto;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.scheduled-reports-new-report-modal-content-checkbox,
.scheduled-reports-new-report-modal-filter-radio {
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-right: 1rem;
  cursor: pointer;
}
.scheduled-reports-new-report-modal .modal-dialog {
  max-width: 45%;
}
.scheduled-reports-new-report-modal-filter-radio {
  margin-bottom: 0.5rem;
}
.scheduled-reports-new-report-modal-team {
  margin-bottom: 0rem !important;
}
/* .scheduled-reports-new-report-modal-cancel-button,
.scheduled-reports-new-report-modal-save-button {
  font-size: 0.7rem;
  padding: 0.4rem 2rem;
} */
.scheduled-reports-new-report-modal-button {
  margin-left: auto;
  margin-right: 0;
}
.scheduled-reports-new-report-modal-save-button {
  font-weight: bold;
}
.scheduled-reports-card-title {
  font-size: 1.2rem;
  color: #4155eae3;
  font-weight: 700;
  text-transform: capitalize;
}
.scheduled-reports-new-report-modal-body .form-label.error {
  font-size: 0.9rem;
  color: red;
  float: right;
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.scheduled-reports-new-report-modal-body .form-error {
  font-size: smaller;
  color: red;
  margin-bottom: 0.25rem;
  text-align: center;
}
.scheduled-reports-new-report-modal-body .form-label {
  font-size: smaller;
  font-weight: 500;
  color: #656565;
}
.scheduled-reports-card-table-body {
  color: #333;
}
.scheduled-reports-card-table-body .no-result {
  color: #868686;
  font-size: 1rem;
}
.action-menu #dropdown-basic {
  padding: 0;
  background: none;
  border: none;
  position: initial;
  top: auto;
  right: auto;
  color: black;
  font-size: 23px;
}
.action-menu #dropdown-basic::after{
  content: none;
}
.scheduled-reports-card-table-body td {
  vertical-align: middle;
}
.scheduled-reports-card-table-data-details-content{
  width:45% !important;
}
.scheduled-reports-card-table-data-details-report-name{
  width: 35% !important;
}
.scheduled-reports-card-table-data-details-report-type{
  width: 17% !important;
}
.schedule-report-modal-button{
  font-size: 1.2rem;
  padding: 0.4rem 2rem;
}
.api-tokens-list-card-toolbar-alert-text-new{
font-size: 14px;
}
.report-no-data{
  text-align: center;
  font-size: 1rem;
  color: black;
}
.scheduled-reports-new-report-modal-note-message{
  font-size: 12px;
  color: #000000b3;
  font-weight: 600;
  margin-bottom: 25px;
}
.scheduled-reports-new-report-modal-create-button{
  margin-bottom: 10px;
}
.scheduled-reports-card-table-data-edit-icon{
  font-size: 15px;
    color: #007bff;
    margin-top: 3px;
    margin-right: 8px;
    
}
.scheduled-reports-card-table-data-edit-text{
  margin-bottom: 0;
}
.scheduled-reports-card-table-data-icon{
  /* float:right; */
}
.employee-report-text{
  font-size: 14px;
  color: #343a40;
  font-weight: 500;
}
.scheduled-reports-card-table-data-icon-whole-div{
  display: flex;
  justify-content: space-evenly;
}
.scheduled-reports-card-table-data-icon-div{
  display: flex;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  color: #6c757d;
}
.scheduled-reports-card-table-data-icon-div:hover{
  padding: 2px 10px 2px 10px;
  background-color: #e9ecef70;
  border-radius: 5px;
  color: #6c757d;
}