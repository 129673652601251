.report-details-filter-root {
    /* margin: 0 0 34px 0; */
    display: flex;
    /* padding: 10px; */
    /* border: 1px solid #00000030; */
    justify-content: space-between;
}
.report-details-filter-content {
    display: flex;
}
.report-details-filter-user {
    width: 280px;
    margin-bottom: 0;
    margin-right: 10px;
    display: flex;
}
.report-details-filter-user span{
    vertical-align: middle;
}
.report-details-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #00000040;
    border-radius: 4px;
    color: #000000cc;
    font-size: 15px;
    padding: 8px;
    width: 280px;
    background-color: transparent;
}
.report-details-filter-download-icon {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
}
.report-details-content {
    width: 100%;
    /* height: 50vh; */
     /* overflow: auto; */
    border: 1px solid #dee2e6;
    padding: 26px;
}
.report-details-root{
    margin: 2rem;
}
label.report-details-label {
    font-size: 1rem;
    font-weight: 600;
}
.report-details-line{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
.report-details-content-report-type {
    margin-bottom: 35px;
}
.report-details-input-content span {
    margin-right: 30px;
    cursor: pointer;
}
.report-details-input-content input {
    margin-right: 4px;
    cursor: pointer;
}
.report-details-content-export-button{
    margin-bottom: 20px;
    margin-top: 30px;
}
.report-details-filter-user-select{
    width: 100%;
}
.report-details-time-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    margin-top: 2px;
    border: 1px solid #0000001a;
    padding: 20px;
}
.disable-custom-report-export{
    width: 156px;
}

.custom-report-page-content-wrapper-menu-filter-date {
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    /* border: 1px solid #ededed; */
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    background-color: #fff;
    color: #333;
    padding: 4px 16px 4px 8px;
    cursor: pointer;
    transition: all 100ms ease;
    max-width:max-content;
}
.custom-report-page-content-wrapper-menu-filter-date-text {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}
.report-details-time-label-div{
    display: flex;
    justify-content: space-between;
}
.report-details-select-all-label{
    font-size: 14px;
    margin-bottom: 0;
    color: grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
}