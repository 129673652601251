.license-count-page-root {
    margin: 2rem;
    /* background-color: #fff; */
}
.license-count-page-wrapper-div {
    max-width: 780px;
    margin: 0 auto;
    padding: 30px;
}
.license-count-page-wrapper-div h5{
    text-align: center;
    margin-bottom: 3rem;
}
.license-count-page-wrapper-div-form-group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}
.license-count-page-wrapper-label-name {
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
    margin-right: 10px;
    margin-bottom: 1px;
}
.license-count-page-wrapper-input {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    width:350px !important;
}
.license-count-page-wrapper-div-whole{
    display: flex;
    justify-content: center;
    align-items: center;
}
.license-count-page-wrapper-div-whole-button {
    /* margin: auto; */
    padding: 9px 0;
    margin-bottom: 1rem;
}

.license-count-page-root-main-content {
    max-width: 780px;
    margin: 0 auto;
    padding: 30px;
    /* text-align: center; */
    font-weight: 500;
    /* border: 1px solid black; */
    border-radius: 6px;
    box-shadow: 0 0 0 .5px rgba(0, 0, 0, .1), 0 0 0 0 rgba(0, 0, 0, .06);
    font-size: .9rem;
    /* font-weight: 400; */
    background-color: #fff;
}
.license-count-page-root-main-content-license-data {
    margin-top: 1rem;
    /* text-align: left; */
}
.license-count-page-root-main-content-email-list-index {
    margin-right: 11px;
}
.license-count-page-wrapper-div-whole-button{
    text-align: center;
}
.license-count-page-wrapper-div-input-or-text{
    text-align: center;
    margin-bottom: 1rem;
}
.license-count-page-root-main-content-details-count {
    display: flex;
    justify-content: space-between;
}
.license-count-page-root-main-content-count-header {
    margin-right: 8px;
    font-weight: 600;
}