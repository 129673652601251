.my-team-root {
  padding: 2rem;
}
.team-card-root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.5rem;
}
.team-card-root a:hover {
  text-decoration: none;
}
.my-team-root-filter-button {
  margin: 0 0 25px;
}
.my-team-root-filter-button-dropdown
{
  float:right;
}
/* team-summary div css start */
.team-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.team-summary-card {
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}
.team-summary-card-text {
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.team-summary-card-count {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
/* team-summary div css end */

/* team-card div css start */
.team-card {
  width: 100%;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  min-height: 158px;
  max-height: 158px;
}

.team-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
}

.team-card.yet_to_start,.team-status-filter.yet_to_start,.team-card.app_not_installed,.team-status-filter.app_not_installed {
  background-color: #fdffe0;
}
.team-card.stop,.team-status-filter.stop{
  background-color: #dc35452e;
}
.team-card.online,.team-card.start,.team-status-filter.start {
  background-color: #c3e7b7;
}
.team-card.break,.team-status-filter.break {
  background-color: #b0c2c2;
}

/* team-card-header div css start */
.team-card-header {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
}
.team-card-avatar {
  display: inline-block;
  margin-right: 10px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #e3e3e3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  color: #333333;
}
.team-card-avatar-profile {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}
.team-card-avatar-profile-img {
  display: inline;
  height: 100%;
  width: 100%;
}
.team-card-name {
  width: 60%;
}
.team-card-teammembername {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-top: 3px; */
}
.team-card-teammemberstatus {
  color: #212529;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
  /* margin-top: 3px; */
}
.team-card-teammemberstatus:first-letter {
  text-transform: uppercase;
}
.team-card-percent {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}
.high-percent {
  color: #009b37;
}
.low-percent {
  color: #757575;
}
.team-card-percent-text {
  font-size: 10px;
  line-height: 15px;
  color: #333333;
  text-align: center;
}
/* team-card-header div css end */

/* team-card-badges div css end */
.team-card-badges{
 color: #212529;
  text-align:center;
  margin-top: 10px;
}
.team-card-badges-yet-to-start{
  color: #212529;
   text-align:center;
   /* margin-top: 10px; */
 }
.team-card-badges-projectname {
  background: #d8f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 5px 5px 5px;
  margin-left: 5%;
}
.team-card-badges-yet-to-start {
  /* background: #d8f6f6; */
  background: #ffc107;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 5%;
  /* margin-bottom: 7px; */
}
.team-card-badges-desktop-not-installed{
    background: #b4c5e8;
    /* background: #ff7107bd; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin-left: 5%;
    margin-bottom: 7px;
    color: #212529;
   text-align:center;
}
/* team-card-badges div css end */

/* team-card-footer div css start */
.team-card-footer {
  display: flex;
  margin-top: 10px;
  color: #212529;
  justify-content: space-between;
}
.team-card-footer-checkin-time {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}
.team-card-footer-chart {
  width: 63%;
  /* margin: 0px 9px 0px 9px; */
}

.team-card-footer-stop {
  background: #e5e5e5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.team-card-footer-online {
  background: #b5ff92;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.team-card-footer-break {
  background: #d8f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.team-card-footer-hourworked-time {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}
/* team-card-footer div css end */

/* team-card-footer-split div css start */
.team-card-footer-split {
  font-size: 12px;
  line-height: 15px;
  display: flex;
  color: #212529;
  justify-content: space-between;
}
.team-card-footer-split-paragraph,.team-card-footer-split-paragraph-hours-worked {
  margin-bottom: 3px !important;
}

/* team-card-footer-split div css end */

/* team-card div css end */

@media (max-width: 991px) {
  .single-menu {
    margin: 0px 0 10px 1px;
  }
}
.team-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 12px 19px rgba(38, 38, 38, 0.2);
  top: -7px;
}
.team-card-percentage {
  margin-left: auto;
}
.team-card-badges-app-icon{
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-top: -2px;
}
.team-card-badges-app-title{
  margin-top: 2px;
}
.team-card-badges-projectname-badge,.team-card-badges-app-name-badge{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500 !important;
  vertical-align: middle;
}
.team-card-badges-app-name-badge,.team-card-badges-projectname-badge{
  max-width: 17ch;
  margin-top: 7px;
}
/* #tooltip-top > .tooltip-inner {
  background-color: #b7b4b4;
  color: #000;
  /* border: 1px solid #062e56; */
/* }
#tooltip-top > .tooltip-arrow {
  background-color: #b7b4b4;
}  */
.tooltip-inner {
  background-color: #b7b4b4 ;
  color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: #b7b4b4;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color:#b7b4b4 ;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
	border-left-color: #b7b4b4;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: #fcd308;
}
.team-card-badges-no-task-badge,.team-card-badges-no-data-badge{
  vertical-align: middle;
  margin-top: 7px;
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.team-status-filter{
  margin-right: 8px;
  /* background-color: #a5a1a13b;
  color: #000000b0; */
  font-weight: 500;
  border: 1px solid #EDEDED;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 9px;
}
.team-status-filter:last-child{
  margin-right: 0px !important;
}
.team-status-filter-selected{
  color: #427ff6;
  background-color: #eef3fc;
  font-weight: bold;
}
@media screen and (min-width: 1400px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
@media screen and (min-width: 1600px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
@media screen and (min-width: 1900px) {
  .team-card-footer-chart {
    width: 61%;
    /* margin: 0px 9px 0px 9px; */
  }
  .team-card-teammemberstatus {
    color: #212529;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
    /* margin-top: 3px; */
  }
}
.team-card-badges-no-data-badge-hidden{
  visibility: hidden !important;
}
.team-card.leave {
  background-color: #ffbb5c85;
  /* background-color: #ffbb5ca3; */
  
}
.team-card-badges-leave {
  /* background: #d8f6f6; */
  background: #ff7107bd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-left: 5%;
  /* margin-bottom: 7px; */
  font-weight: 500!important;
}
.team-card-badges-start-leave-data-badge {
  background-color: #c3e7b7;
}
.team-card-badges-break-leave-data-badge {
  background-color: #b0c2c2;
}
.team-card-badges-stop-leave-data-badge {
  background-color: rgba(220,53,69,.1803921568627451);
}
.my-team-members-card-leave-label-date-display {
  text-transform: lowercase;
}
.team-card-badges-leave:first-letter {
  text-transform: capitalize;
}
.team-card-root-leave-details-refresh-root {
  margin-bottom: 14px;
  display: flex;
  justify-content: end;
}
.team-card-root-leave-details-refresh-text {
  margin-right: 10px;
  font-size: 15px;
  color: black;
}
.team-card-root-leave-details-refresh-button {
  padding: 4px 18px;
  font-size: 14px;
}
.team-card-root-leave-details-refresh-icon {
  margin: -1px 7px 0px 0px;
}
.team-card-root-leave-details-refresh-button-no-member{
  margin-top: 17px;
}