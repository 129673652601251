.project-task-selection-nav {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: initial;
}

.project-and-task-selection-navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
    flex-wrap: initial;
}

.project-and-task-selection-navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.project-and-task-selection-navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.project-and-task-selection-navbar .container,
.project-and-task-selection-navbar .project-and-task-selection-container-fluid,
.project-and-task-selection-navbar .container-lg,
.project-and-task-selection-navbar .container-md,
.project-and-task-selection-navbar .container-sm,
.project-and-task-selection-navbar .container-xl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.project-and-task-selection-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.project-and-task-selection-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.project-and-task-selection-container-fluid>.project-and-task-selection-navbar-collapse,
.project-and-task-selection-container-fluid>.project-and-task-selection-navbar-header,
.container>.project-and-task-selection-navbar-collapse,
.container>.project-and-task-selection-navbar-header {
    margin-right: -15px;
    margin-left: -15px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-toggle {
    border-color: #ddd;
}

.project-and-task-selection-navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.project-and-task-selection-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar {
    background-color: #888;
}

.project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.project-and-task-selection-navbar-toggle .project-and-task-selection-icon-bar+.project-and-task-selection-icon-bar {
    margin-top: 4px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-brand {
    color: #777;
}

.project-and-task-selection-navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.project-and-task-selection-navbar-default .project-and-task-selection-navbar-collapse,
.project-and-task-selection-navbar-default .navbar-form {
    border-color: #e7e7e7;
}

.project-and-task-selection-navbar-collapse.in {
    overflow-y: auto;
}

.project-and-task-selection-collapse.in {
    display: block;
}

.project-and-task-selection-navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
}

.project-and-task-selection-navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.project-task-selection-navbar-nav {
    margin: 7.5px -15px;
}

.project-task-selection-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.project-task-selection-nav>li {
    position: relative;
    display: block;
}

.project-task-selection-navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a:focus,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.active>a:hover {
    color: #555;
    background-color: #e7e7e7;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>li>a {
    color: #777;
}

.project-task-selection-navbar-nav>li>a {
    line-height: 20px;
}

.project-task-selection-nav>li>a {
    position: relative;
    display: block;
}

.project-and-task-selection-dropdown,
.dropup {
    position: relative;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a:focus,
.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>.project-and-task-selection-open>a:hover {
    color: #555;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav>li>a {
    color: #777;
}

.project-task-selection-nav .project-and-task-selection-open>a,
.project-task-selection-nav .project-and-task-selection-open>a:focus,
.project-task-selection-nav .project-and-task-selection-open>a:hover {
    border-color: #337ab7;
}

.project-task-selection-navbar-nav>li>a {
    line-height: 20px;
}

.project-task-selection-nav>li>a {
    position: relative;
    display: block;
    cursor: pointer;
}

.project-and-task-selection-open>a {
    outline: 0;
}

@media (max-width: 767px) {
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.project-task-selection-navbar-nav>li>.project-and-task-selection-dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


/* .project-and-task-selection-dropdown-menu {
    display: block;
} */

.project-and-task-selection-open>.project-and-task-selection-dropdown-menu {
    display: block !important;
}


/* .project-and-task-selection-open>.project-task-selection-list>.project-and-task-selection-dropdown-menu{
    display: block !important;
} */

.project-and-task-selection-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.project-and-task-selection-dropdown-menu>li {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
}

@media (max-width: 767px) {
    .project-and-task-selection-navbar-default .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        color: #777;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        line-height: 20px;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu .dropdown-header,
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
        padding: 5px 15px 5px 25px;
    }
    .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.project-and-task-selection-dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.project-and-task-selection-dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    display: none;
    margin-top: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: #fff;
    box-shadow: none;
}

.project-and-task-selection-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.project-and-task-selection-dropdown-menu .project-and-task-selection-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
}

.project-and-task-selection-navbar-default .project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    color: #777;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    line-height: 20px;
}

.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu .dropdown-header,
.project-task-selection-navbar-nav .project-and-task-selection-open .project-and-task-selection-dropdown-menu>li>a {
    padding: 5px 15px 5px 25px;
}

.project-and-task-root {
    /* width:40%; */
}

.navbar-collapse-project-task {
    background-color: white;
    /* box-shadow: 3px 3px 3px -1px #33333354; */
    font-size: 15px;
    border-radius: 4px;
    width: 100%;
    height: 300px;
    /* height: calc(100vh - 260px); */
    overflow-x: hidden;
    overflow-y: auto;
}

.project-task-selection-list-success {
    color: #333 !important;
    font-weight: 600;
}

.project-task-selection-list-secondary1 {
    color: rgb(160, 209, 25) !important;
    font-weight: 600;
}

.project-task-selection-list-secondary {
    color: orange !important;
    font-weight: 600;
}

.project-task-selection-list-circle {
    /* width: 6px;
    height: 6px;
    border-radius: 70%;
    display: inline-block; */
    margin-right: 10px;
    font-size: 14px;
    display: flex;
    margin-top: 3px;
}

.project-task-list-modal-save-button {
    display: flex;
    justify-content: end;
    /* flex-direction: column; */
    justify-content: center;
    /* margin-bottom: 11px; */
}

.project-task-selection-list-success-circle {
    background-color: #333333c4 !important;
}

.project-task-selection-list-secondary1-circle {
    background-color: rgb(160, 209, 25) !important;
}

.project-task-selection-list-secondary-circle {
    background-color: orange !important;
}

.project-task-selection-list,
.project-task-selection-task-list {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.project-task-selection-task-list {
    align-items: center;
    justify-content: space-between;
    padding: 9px 15px !important;
}

.project-task-selection-list-task-dropdown-menu {
    background-color: #bacdf921 !important;
}

.project-task-selection-list-name-content {
    width: 70%;
    padding: 9px 15px 9px 9px;
    /* margin: 0 0 0 8px; */
}

.project-task-selection-list-name-content:hover,
.project-task-selection-list-name-content-add-project:hover,
.project-task-selection-task-list:hover {
    background-color: #e7e7e782;
    border-radius: 4px;
}

.project-task-selection-list-name-content-selected {
    background-color: #eef3fc;
    border-radius: 4px;
    color: #007bff !important;
}

.task-selection-list-name-content-selected {
    background-color: #e3eaf6;
    border-radius: 4px;
    color: #266dfb !important;
    font-weight: 600 !important;
}

.project-task-selection-nav.project-task-selection-navbar-nav {
    margin: 7.5px 0;
}

.project-task-selection-list-add-project-icon {
    font-size: 17px;
    margin-right: 8px;
}

.project-task-selection-list-name-content-add-project {
    width: 100%;
    padding: 9px 15px 9px 4px;
    /* margin: 0 0 0 8px; */
    font-weight: 400;
    font-size: 14px;
}

.project-task-selection-list-name-content-add-task {
    width: 100%;
    padding: 9px 6px;
    /* margin: 0 0 0 8px; */
    font-weight: 400;
    font-size: 14px;
}

.project-task-selection-list-add-project {
    color: #034aef !important;
}

.project-task-selection-list:hover,
.project-task-selection-task-list:hover {
    text-decoration: none;
    font-weight: bold;
}

.project-task-selection-list-task-content {
    color: gray;
}

.project-task-selection-list-task-content:hover {
    text-decoration: underline;
}

.project-task-selection-task-list-name {
    margin-left: 16px;
}

.project-task-selection-task-list-divider.project-and-task-selection-divider {
    height: 1px;
    margin: 9px 0 0 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.project-task-selection-list-task-content-create-task-button {
    color: gray;
    font-size: 12px;
    text-decoration: underline;
}

.project-task-selection-list-add-task {
    font-size: 13px;
}

.project-task-selection-list-task-content-arrow-down {
    margin-left: 5px;
    vertical-align: middle;
}

.project-task-content-modal-header {
    font-size: 18px;
}

.project-task-content-modal-button {
    font-size: 14px;
    font-weight: 600;
}

.my-projects-modal-form-error {
    text-align: center;
    color: red;
}

.task-add-new-project-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.7;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}

.tasks-modal-content-person-name-header-section-label {
    line-height: 18px;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab;
}

.tasks-modal-content-person-name {
    overflow: auto;
    /* height: 100%; */
    flex-grow: 1;
}

.tasks-modal-content-person-name-header {
    display: flex;
    margin-bottom: 5px;
}

.tasks-modal-content-person-name-listing {
    overflow: auto;
    height: 280px;
    flex-grow: 1;
    /* border: 1px solid lightgrey; */
    padding: 10px 10px 10px 10px;
    margin-bottom: 1.5rem;
}

.tasks-modal-content-person-name-listing-card-content-person {
    display: flex;
    align-items: center;
    flex: 1 1;
    /* padding: 0 10px; */
    cursor: pointer;
}

.tasks-modal-content-person-name-header-button {
    font-size: 14px;
    margin-bottom: 0;
    color: gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    text-decoration: underline;
}

.tasks-modal-content-person-name-header-button:hover {
    text-decoration: underline;
}

.tasks-modal-content-person-name-listing-card-content-person-icon {
    height: 20px;
    width: 20px;
    line-height: 21px;
    font-size: 0.7rem;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    /* margin-left: 4px; */
    margin-right: 8px;
    background-color: #80b3ff;
    text-transform: uppercase;
}

.task-card-avatar-profile {
    width: 22px;
    height: 22px;
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
}

.task-card-avatar-profile-img {
    display: inline;
    height: 100%;
    width: 100%;
}

.task-due-date {
    margin-bottom: 1rem;
    /* width: 200px; */
    margin-left: 1rem;
}

.task-date-whole-div-data {
    display: flex;
}

.my-tasks-add-new-project-option-div {
    display: flex;
    width: 260px;
}

*/ .project-tasks-add-time-option-div {
    display: flex;
    width: 227px;
}

.task-section-edit-task-label-input {
    margin-bottom: 1.5rem;
}

.task-add-placeholder::placeholder {
    opacity: 1;
    color: #abab9ddb !important;
}

.task-add-due-date-span {
    opacity: 1;
    color: #abab9ddb !important;
}

.navbar-collapse-project-task-list-card-searchbox-wrapper {
    position: relative;
    width: 95%;
    margin: auto;
    padding: 10px 0;
    /* border-bottom: 1px solid #e5e5e5; */
}

.navbar-collapse-project-task-list-card-searchbox-wrapper-div {
    border-bottom: 1px solid #e5e5e5;
    margin: 0 10px;
}

.task-details-due-date-date-picker {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.task-details-due-date-date-picker::placeholder {
    /* Most modern browsers support this now. */
    color: #c4c1c1;
}

.task-priority-whole-div-data {
    display: flex;
}

.task-add-modal-priority-div {
    width: 100%;
}

.task-modal-content-header-team-name-acc {
    display: flex;
    margin-bottom: 0.5rem !important;
    /* text-decoration: underline !important; */
    justify-content: space-between;
    width: 100%;
}

.task-modal-accordion-whole {
    margin-bottom: 1.5rem !important;
}

.tasks-modal-cols {
    padding: 0 20px !important;
}

.project-tasks-modal-content-header-setting {
    /* font-size: 0.97rem !important;
    line-height: 18px;
    color: #62839b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left; */
    /* font-size: 0.95rem;
    font-weight: 500;
    color: #000000ab; */
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, .6705882352941176);
}

.project-tasks-modal-content-header-setting:hover {
    /* color: #62839b;
    text-decoration: underline !important; */
}

.tasks-rule-uparrow {
    font-size: 14px;
    margin-left: 5px;
    color: #62839b;
}

.toggle-switch label {
    cursor: pointer;
    margin-bottom: 0 !important;
}

.toggle-switch label .toggle-track {
    display: block;
    height: 20px;
    width: 35px;
    background: #e1e4e8;
    border-radius: 20px;
    position: relative;
    /* margin-bottom:15px; */
    border: 1px solid #ccc;
    transition: .4s;
}

.toggle-track-nav-right-div {
    background: #7dd46e !important;
}

.toggle-track-nav-right-div:before {
    transform: translateX(15px) !important;
}

.toggle-switch .toggle-track:before {
    content: '';
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: right .2s ease-in;
    left: 0px;
    bottom: 2px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(59 66 78 / 12%);
    transition: .4s;
}

.task-modal-private-toggle-div {
    display: flex;
    margin-bottom: 1.5rem !important;
    justify-content: space-between;
    width: 100%;
}

.task-modal-private-toggle-div-command-line {
    color: #8c94a1;
    font-size: 13px;
    letter-spacing: -.2px;
    line-height: 17px;
    max-width: 385px;
    padding-top: 7px;
}

.project-tasks-modal-content-header-setting-icon {
    font-size: 0.75rem;
    margin-top: -2px;
}

.project-task-modals-root .modal-dialog .modal-content {
    padding: 0 16px;
    border-radius: 12px;
}

.project-task-modals-root-title.modal-title.h4 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    width: 90%;
    flex-shrink: 0;
}

.project-task-modals-edit-task-label-name {
    color: #535c68fc;
    font-size: 14px;
    font-weight: 600;
    /* letter-spacing: -.22px; */
    line-height: 22px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .6705882352941176);
}

.project-task-modals-edit-task-label-name::placeholder {
    color: rgb(212, 209, 209);
}

.project-task-acc-collapse-whole-div-data {
    border: 1px solid #d3d3d3;
    padding: 20px;
    border-radius: 6px;
    background-color: #8080800d;
}

.project-tasks-modals-add-time-option-div {
    display: flex;
    width: 195px;
}

.project-task-modal-add-new-project-hours-text {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 6px);
    padding: 0.375rem 0.35rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.7;
    color: #495057c7;
    border: 1px solid #ced4da;
    border-radius: 0rem 0.25rem 0.25rem 0rem;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border-left: none;
    text-align: center;
}

.project-task-section-whole-input-div {
    margin-bottom: 0.5rem;
}

.project-task-section-whole-input-div {
    margin-bottom: 0.5rem;
}

.project-task-due-date {
    /* margin-bottom: 1rem; */
    width: 200px;
    margin-left: 1rem;
}

.project-task-modals-user-label-section {
    height: 32px;
    color: #8c94a1;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .55px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* box-shadow: inset 0 -1px 0 0 #edeef2; */
    text-transform: uppercase;
    width: 265px;
}

.project-task-modals-searchbox-wrapper {
    position: relative;
    flex: 1 1;
    /* margin-right: 16px; */
    margin-bottom: 12px;
}

.project-task-modals-content-person-name-listing-card-content {
    /* margin-bottom: 2px; */
    display: flex;
    margin-top: 10px;
}

.project-task-modals-save-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
}

.project-task-modals-user-label-section-div {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #edeef2;
}

.my-tasks-add-new-project-option-div {
    display: flex;
    width: 210px;
}

.project-task-modals-edit-task-label-name-star {
    font-size: smaller;
    font-weight: 400;
    color: red;
    top: -0.3em;
}
.create-new-task-whole-div-new{
    display: flex;
}
.create-new-task-whole-div-new-textbox{
    width: 390px;
}
.create-new-task-whole-div-new-button{
    margin-left: 15px;
}
.create-new-task-whole-div-add-new-team {
    margin-top: -13px;
    padding-left: 0px;
    margin-bottom: 10px;
}
.create-new-task-whole-div-add-new-team:focus{
    box-shadow: none;
}
.project-task-modals-add-task-label-name-task-description label {
    color: rgba(0, 0, 0, .6705882352941176);
    font-size: 14px;
    font-weight: 600;
    /* letter-spacing: -.22px; */
    line-height: 22px;
    margin-bottom: 8px;
}
.project-task-modals-add-task-label-name-task-description {
    margin-bottom: 1.5rem;
}
/* textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    color: var(--gray-700);
    border-radius: 10px;
    resize: vertical;
    background-color: var(--gray-100);
    box-sizing: border-box;
    padding: 0.65rem 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
      "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
      sans-serif;
  }
  textarea:invalid {
    border: 2px solid #ff7d87;
    box-shadow: none;
  }
  textarea:focus {
    outline: none;
    border: 2px solid var(--focus-ring-color);
  } */

  textarea{
    display: block;
    width: 100%;
    height: calc(4em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}