.employee-timeline-page-wrapper{
    margin:2rem
}

svg g,svg g:first-of-type path {
    stroke: #cfcccc;
    stroke-width: 0.5;
}
.employee-timeline-page-wrapper svg g:first-of-type rect:not(:last-child) {
    fill: #ffffff;
}

.apps-tootlip-timeline{
    width: max-content;
    padding:15px;
    pointer-events: none;
  }
  .apps-tootlip-timeline-title{
    font-weight: bold;
    padding-bottom: 5px;
    /* width:100%;
    border-bottom: .5px solid rgba(0,0,0,.08); */
  }
  .apps-tootlip-timeline-time{
    font-weight: bold;
  }
  .timeline-no-data-found{
    text-align: center;
    margin-top: 40px;
  }
  .timeline-header-section{
      display: flex;
      margin-bottom: 20px;
  }
  .timeline-header-section-date {
    font-size: 19px;
    font-weight: 600;
    color: rgba(0,0,0,.5803921568627451);
    vertical-align: middle;
    /* margin-top: 10px; */
  }
  .timeline-header-section-show-text{
    /* margin-top: 10px; */
    margin-left: auto;
    margin-right: 0;
    display: flex;
  }
  .timeline-header-section-show-text-circle-start{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #7454f5;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-break{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #f1c40f;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-untracked{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #CDC2AE;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-stop{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #e67e22;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-neutral{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #40739e;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-idle{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #E67E22;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #E67E22;
  }
  .timeline-header-section-show-text-circle-prod{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #27ae60;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-circle-unprodu{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 40px;
    background: #c0392b;
    border: 1px solid rgba(66, 58, 58, 0.1);
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #333;
  }
  .timeline-header-section-show-text-whole{
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .timeline-header-section-show-text-letter{
    font-size: 13px;
  }
  @media screen and (max-width: 480px) {
    .timeline-header-section{
        display: initial;
    }
    .timeline-header-section-date {
      margin-bottom: 10px;
    }
    .timeline-header-section-show-text{
        margin-bottom: 10px;
    }
  }
  /* .timeline-pagination-section {
    display: flex;
    justify-content: center;
  } */
  .timeline-chart-data-root{
    height:72vh;
  }
  /* .timeline-pagination-section-label{
    text-align: center;
    margin-bottom: 15px;
  } */

  .timeline-pagination-section {
    display: grid;
    place-items: center;
    max-width: 69em;
    overflow: scroll;
    margin: 0px 32px 0px 32px;
  }
  .timeline-pagination-section-label {
    text-align: center;
    margin-bottom: 15px;
    /* width: 100vw; */
    display: grid;
  }
  .timeline-pagination-section .pagination{
    /* display: flex;
    justify-content: center; */
  }